import { LicenseType } from "../licensing.types";

export interface LicenseConfig {
  hasLicense: boolean;
  validFrom: Date;
  validTo: Date;
  licenseType: LicenseType;
}

export class License implements LicenseConfig {
  hasLicense: boolean;
  validFrom: Date;
  validTo: Date;
  licenseType: LicenseType;

  protected constructor() { }

  public static createInstance(licenseConfig: LicenseConfig): License {
    const license = new License();
    license.hasLicense = licenseConfig.hasLicense;
    license.validFrom = licenseConfig.validFrom;
    license.validTo = licenseConfig.validTo;
    license.licenseType = licenseConfig.licenseType;

    return license;
  }

  public getDaysLeft(): number {
    if (!this.validTo) {
      return -1;
    } else {
      let dayInMiliseconds = 1000 * 60 * 60 * 24;
      let now = new Date();
      return Math.floor((this.validTo.getTime() + dayInMiliseconds - now.getTime()) / dayInMiliseconds);
    }
  }
}
