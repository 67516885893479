import { ChartData, ChartLayout } from "./../interfaces";
import { ChartSettings } from "./../settings/chartSettings";
import { BOLD, CENTER, END, FONT_WEIGHT, MIDDLE, NORMAL, RIGHT, SEGOE_UI, SEGOE_UI_BOLD, START } from "./../library/constants";
import * as helpers from "./../helpers";
import * as d3 from "../d3";

export function plotRowHeaders(container: d3.Selection<SVGElement, any, any, any>, chartData: ChartData[], chartLayouts: ChartLayout[], settings: ChartSettings) {
    const plottedRows = [];
    let fontFamily: string, fontWeight: string;
    if (settings.groupTitleFontFamily === SEGOE_UI_BOLD) {
        fontFamily = SEGOE_UI;
        fontWeight = BOLD;
    }
    else {
        fontFamily = settings.groupTitleFontFamily;
        fontWeight = NORMAL;
    }

    chartLayouts.forEach((c, i) => {
        const headerText = chartData[i] ? chartData[i].rowHeader : "";
        if (plottedRows.indexOf(headerText) === -1) {
            const headerTextEl = helpers.drawTextWithOptions({
                container: container,
                text: headerText,
                x: 0,
                y: c.position.y + c.height / 2,
                color: settings.groupTitleFontColor,
                fontSize: settings.groupTitleFontSize,
                textAnchor: START,
                fontFamily: fontFamily,
                textClass: "row-header",
            });
            headerTextEl.style(FONT_WEIGHT, fontWeight);
            plottedRows.push(headerText);
        }
    });
}

export function plotColumnHeaders(container: d3.Selection<SVGElement, any, any, any>, chartData: ChartData[], chartLayouts: ChartLayout[], settings: ChartSettings, y: number) {
    const plottedCols = [];
    let fontFamily: string, fontWeight: string;
    if (settings.groupTitleFontFamily === SEGOE_UI_BOLD) {
        fontFamily = SEGOE_UI;
        fontWeight = BOLD;
    }
    else {
        fontFamily = settings.groupTitleFontFamily;
        fontWeight = NORMAL;
    }

    chartLayouts.forEach((c, i) => {
        const headerText = chartData[i].columnHeader;
        if (plottedCols.indexOf(headerText) === -1) {
            let x = c.position.x;
            const width = settings.differenceHighlight ? c.width - settings.differenceHighlightWidth : c.width;
            let anchor = START;
            if (settings.groupTitleAlignment === CENTER) {
                x += width / 2;
                anchor = MIDDLE;
            }
            else if (settings.groupTitleAlignment === RIGHT) {
                x += width;
                anchor = END;
            }

            const headerTextEl = helpers.drawTextWithOptions({
                container: container,
                text: headerText,
                x: x,
                y: y,
                color: settings.groupTitleFontColor,
                fontSize: settings.groupTitleFontSize,
                textAnchor: anchor,
                fontFamily: fontFamily,
                textClass: "column-header",
            });
            headerTextEl.style(FONT_WEIGHT, fontWeight);
            plottedCols.push(headerText);
        }
    });
}
