import BaseObserver from "@zebrabi/global-toolbar/dist/lib/interface/BaseObserver";

class ActionObserver implements BaseObserver<string> {
  public static readonly CLASS_NAME = "ActionObserver";
  getClassName() {
    return ActionObserver.CLASS_NAME;
  }

  update: (message: string, eventName?: string) => void;
}

export default ActionObserver;
