
import { ViewModel, ChartLayout } from "./../interfaces";
import { ChartType, ComboChartUnderlyingChart, GridlineStyle, MultiplesAxisLabelsOptions } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { Visual } from "./../visual";
import { SliderHandler } from "./../ui/sliderHandler";
import LayoutAttributes from "./../multiples/LayoutAttributes";
import { getVarianceViewModel } from "./../viewModel/viewModels";

import {
    GRIDLINE, STROKE_DASHARRAY, DifferenceLabel, RIGHT, LEFT, TEXT, AXIS_SCENARIO_DELIMITER, MOUSEOVER, FILL, OPACITY,
    WIDTH, HEIGHT, RECT, X, Y, LINE, MOUSEOUT, WHITE, GRAY, EMPTY, POINTER_EVENTS, DRAG, DRAGSTART, DRAGEND, Y1, Y2, FONT_SIZE_UNIT, Scenario, ZEBRABI_CHARTS_VISUAL,
} from "./../library/constants";
import {
    MULTIPLE_MARGIN, CHART_CONTAINER, ABSOLUTE_RELATIVE, ABSOLUTE, RELATIVE, ACTUAL, ACTUAL_RELATIVE, ACTUAL_ABSOLUTE, RESPONSIVE, USEDEBUGGING
} from "./../consts";

import * as d3 from "../d3";
import * as drawing from "./../library/drawing";
import * as charting from "./../charting/chart";
import * as viewModels from "./../viewModel/viewModelHelperFunctions";
import * as helpers from "./../helpers";

import areaChart from "./../charting/areaChart";
import columnChart from "./../charting/columnChart";
import dotChart from "./../charting/dotChart";
import lineChart from "./../charting/lineChart";
import pinChart from "./../charting/pinChart";
import plusMinusChart from "./../charting/plusMinusChart";
import plusMinusDotChart from "./../charting/plusMinusDotChart";
import varianceChart from "./../charting/varianceChart";
import verticalBarChart from "./../charting/verticalBarChart";
import verticalPinChart from "./../charting/verticalPinChart";
import verticalVarianceChart from "./../charting/verticalVarianceChart";
import waterfallChart from "./../charting/waterfallChart";
import verticalWaterfallChart from "./../charting/verticalWaterfallChart";
import { plotFreezedCategories } from "./../charting/chart";
import { CategoryConfig, DataLabelsConfig, DesignConfig, DifferenceHighlightConfig, flagHandler, GroupTitleConfig, HighlightingConfig, LegendConfig, PinChart, SmallMultiplesConfig, VisualConfig } from "@zebrabi/zebrabi-core";
import { invertResultAndHighlightContextMenu, invertResultAndHighlightContextMenuWrapper } from "../ui/contextMenu";
import { ChartData, ChartConfig, Chart, PersistObserver, ChartEventObserver, ACTION_CHART_FOCUS_POPUP, ACTION_COMMENT_MARKER_TOOLTIP, ACTION_INVERT_MENU, ACTION_TOOLTIP } from "@zebrabi/zebrabi-core";
import { ChartStyle } from "@zebrabi/zebrabi-core/dist/lib/enums/ChartStyle";

// import { viewModelFromMatrix } from "../viewModel/viewModelFromMatrix";

export default abstract class Layout {
    protected slider: HTMLElement;
    // public reportArea : d3.Selection<SVGElement, any, any, any>;
    constructor(protected settings: ChartSettings, protected viewModel: ViewModel, protected reportArea: d3.Selection<SVGElement, any, any, any>,
        protected svg: d3.Selection<SVGElement, any, any, any>, protected slideHandler: SliderHandler, protected width: number,
        protected layoutAttributes: LayoutAttributes) {
        this.slider = slideHandler ? slideHandler.slider : null;
    }

    public abstract getChartLayouts(rowCount: number, maxChartsPerRow: number, height: number, visualTitleHeight: number, minChartHeight: number, minChartWidth: number,
        plotChartWidth: number, bottomMargin: number, topMargin: number, rowMargin: number, columnMargin: number, labelFontSize: number, xPosition: number, leftMargin: number): ChartLayout[];

    public plot(rowCount: number, maxChartsPerRow: number, height: number, titleHeight: number, minChartHeight: number, minChartWidth: number, plotChartWidth: number, bottomMargin: number,
        topMargin: number, rowMargin: number, columnMargin: number) {
        const xPosition = this.settings.showLegend && this.settings.chartType !== ChartType.Waterfall ? this.settings.getLegendWidth() : 0;
        let leftMargin = 0;
        const plotOuterBorders = this.settings.showMultiplesGrid && this.settings.showOuterBorders;
        const multiplesGridMargin = MULTIPLE_MARGIN / 2;

        if (plotOuterBorders) {
            height -= MULTIPLE_MARGIN;
            leftMargin = multiplesGridMargin;
        }

        if (this.settings.showCommentMarkers() && !this.settings.shouldPlotVerticalCharts()) {
            topMargin += this.layoutAttributes.topCommentMarkerMargin;
            bottomMargin += this.layoutAttributes.bottomCommentMarkerMargin;
        }

        const chartLayouts = this.getChartLayouts(rowCount, maxChartsPerRow, height, titleHeight, minChartHeight, minChartWidth, plotChartWidth, bottomMargin,
            topMargin, rowMargin, columnMargin, this.settings.labelFontSize, xPosition, leftMargin);

        this.viewModel.chartData.forEach((cd, i) => {
            cd.showCategoryLabels = this.settings.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.AllCharts
                || (this.settings.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.FirstChart || this.settings.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.FirstChartLastRow && chartLayouts[chartLayouts.length - 1].rowIndex > 0) && i === 0
                || this.settings.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.FirstRow && (chartLayouts[i].rowIndex === 0);
        });
        const maxColHeight = Math.max(...chartLayouts.map((layout) => layout.height + layout.position.y));
        const svgHeight = maxColHeight + (plotOuterBorders ? multiplesGridMargin + 1 : 0) + (this.settings.showVerticalCharts ? topMargin : 0);

        this.svg
            .attr(WIDTH, this.width + xPosition)
            .attr(HEIGHT, svgHeight);
        this.plotMultiples(this.viewModel.chartData, chartLayouts, topMargin, bottomMargin);
    }

    protected plotMultiples(chartDataArray: ChartData[], chartLayouts: ChartLayout[], topMargin: number, bottomMargin: number, plotTitle: boolean = true) {
        const plotVertical = this.settings.shouldPlotVerticalCharts();
        const plotSecondActualChart = this.settings.scenarioOptions.secondActualValueIndex !== null && !plotVertical && this.settings.showDotChart;
        const firstActualMin = Math.min(0, ...chartDataArray.map(c => c.min));
        const firstActualMax = Math.max(0, ...chartDataArray.map(c => c.max));
        const secondActualMin = plotSecondActualChart ? Math.min(0, ...chartDataArray.map(c => c.minSecondActualValue)) : 0;
        const secondActualMax = plotSecondActualChart ? Math.max(0, ...chartDataArray.map(c => c.maxSecondActualValue)) : 0;

        if (this.settings.showMultiplesGrid) {
            this.plotMultiplesGrid(chartLayouts);
        }

        for (let i = 0, len = chartDataArray.length; i < len; i++) {
            const chartData = chartDataArray[i];
            const chartLayout = chartLayouts[i];
            const realBottomMargin = chartLayout.bottomMargin != null ? chartLayout.bottomMargin : bottomMargin;
            const plotLegend = this.settings.showLegend && (plotVertical && chartLayout.rowIndex === 0 || !plotVertical && chartLayout.columnIndex === 0);
            if (this.settings.shouldPlotExtendedChartsMultiples()) {
                this.plotResponsiveChart(chartLayout.position.x, chartLayout.position.y, chartLayout.height, chartLayout.width, chartData,
                    topMargin, bottomMargin, i, 1, plotLegend, chartLayout.min, chartLayout.max,
                    chartLayout.extendedChartMin, chartLayout.extendedChartMax, chartLayout.extendedRelativeChartHeight);
            }
            else {
                const plotVerticalCategories = plotVertical && (chartLayout.columnIndex === 0 || this.settings.chartType === ChartType.Waterfall && this.settings.showTopNCategories);
                const titleHeight = plotTitle ? chartLayout.position.y : 0;
                const yChart = titleHeight;

                if (plotSecondActualChart) {
                    this.plotComboChart(chartData, chartLayout.height, chartLayout.width, bottomMargin,
                        titleHeight, topMargin, chartLayout.position.x, chartLayout.position.x, yChart, plotTitle,
                        ComboChartUnderlyingChart.plotChart, i, plotLegend, undefined, undefined, true, firstActualMin, firstActualMax, secondActualMin, secondActualMax);
                }
                else {
                    this.plotChart(chartLayout.position.x, chartLayout.position.y, chartLayout.height, chartLayout.width, chartData,
                        topMargin, realBottomMargin, i, plotLegend, chartLayout.min, chartLayout.max, plotTitle, plotVerticalCategories);
                }
            }
        }
        if (!plotVertical) {
            this.addResizeChartHeightUI(chartLayouts);
        }

        if (this.settings.shouldFreezeCategories()) {
            plotFreezedCategories(chartDataArray, chartLayouts, this.layoutAttributes.freezedCategoriesMargin, this.settings);
        }
    }

    public shouldBlurAChart(): boolean {
        return false;
    }

    private plotMultiplesGrid(chartLayouts: ChartLayout[]) {
        const lineWidth = 1;
        const color = this.settings.multiplesGridlinesColor;
        const margin = MULTIPLE_MARGIN / 2;
        const showOuterBorders = this.settings.showOuterBorders;

        const minXPos = Math.round(Math.min(...chartLayouts.map(c => c.position.x))) - margin;
        const maxXPos = Math.round(Math.max(...chartLayouts.map(c => c.position.x + c.width))) + margin;
        const minYPos = Math.round(Math.min(...chartLayouts.map(c => c.position.y))) - margin;
        const maxYPos = Math.round(Math.max(...chartLayouts.map(c => c.position.y + c.height))) + margin;
        chartLayouts.forEach((c, i) => {
            const startXPos = Math.round(c.position.x - margin + 0.5);
            const endXPos = Math.round(c.position.x + c.width + margin);
            const startyPos = Math.round(c.position.y - margin);
            const endYPos = Math.round(c.position.y + c.height + margin);

            if ((showOuterBorders || startyPos > minYPos)) {
                drawing.drawLine(this.reportArea, startXPos, endXPos, startyPos, startyPos, lineWidth, color, GRIDLINE);
            }
            if (showOuterBorders || endYPos < maxYPos) {
                drawing.drawLine(this.reportArea, startXPos, endXPos, endYPos, endYPos, lineWidth, color, GRIDLINE);
            }
            if (showOuterBorders || startXPos > minXPos + 1) {
                drawing.drawLine(this.reportArea, startXPos, startXPos, startyPos, endYPos, lineWidth, color, GRIDLINE);
            }
            if (showOuterBorders || endXPos < maxXPos) {
                drawing.drawLine(this.reportArea, endXPos, endXPos, startyPos, endYPos, lineWidth, color, GRIDLINE);
            }
        });

        if (this.settings.gridlineStyle === GridlineStyle.Dotted) {
            this.reportArea.selectAll("." + GRIDLINE).attr(STROKE_DASHARRAY, "2,2");
        }
        else if (this.settings.gridlineStyle === GridlineStyle.Dashed) {
            this.reportArea.selectAll("." + GRIDLINE).attr(STROKE_DASHARRAY, "5,5");
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public plotComboChart(chartData: any, chartHeight: number, chartWidth: number, bottomMargin: number,
        titleHeight: number, topMargin: number, xDotChart: number, xChart: number, yChart: number, plotTitle: boolean,
        comboChartType: ComboChartUnderlyingChart, index: number, plotLegend: boolean, minDataValue?: number, maxDataValue?: number, plotMultiples?: boolean,
        multiplesChartDataMin?: number, multiplesChartDataMax?: number, multiplesDotChartDataMin?: number, multiplesDotChartDataMax?: number) {

        let dotChartHeight = chartHeight * (this.settings.dotChartMaxHeightPercent / 100);
        let yDotChart = yChart;
        let dotChartTopMargin = topMargin;
        let dotChartBottomMargin = bottomMargin;

        let dataMin = chartData.min;
        let dataMax = chartData.max;
        let dotDataMin = chartData.minSecondActualValue;
        let dotDataMax = chartData.maxSecondActualValue;

        let global_dataMin = chartData.min;
        let global_dataMax = chartData.max;
        let global_dotDataMin = chartData.minSecondActualValue;
        let global_dotDataMax = chartData.maxSecondActualValue;

        if (plotMultiples) {
            dataMin = multiplesChartDataMin;
            dataMax = multiplesChartDataMax;
            dotDataMin = multiplesDotChartDataMin;
            dotDataMax = multiplesDotChartDataMax;

            global_dataMin = multiplesChartDataMin;
            global_dataMax = multiplesChartDataMax;
            global_dotDataMin = multiplesDotChartDataMin;
            global_dotDataMax = multiplesDotChartDataMax;
        }

        // CASE:0_0 - if dotchart and chart have only positives, no need to adjust
        if (dotDataMin >= 0 && dataMin >= 0) {
            yDotChart = yChart + chartHeight * (1 - this.settings.dotChartMaxHeightPercent / 100);
        }

        // CASE:0_1 - if combo values positive and chart negative
        else if (dotDataMin >= 0 && dataMax <= 0) {
            yChart += titleHeight;
            yDotChart += titleHeight + ((chartHeight / 2 - dotChartHeight / 2));
            dotChartHeight += (chartHeight / 2 - dotChartHeight / 2);
            yChart += chartHeight / 2 + titleHeight;
            chartHeight = chartHeight / 2;
            dotChartBottomMargin = chartHeight;
            topMargin = 0;
        }

        // CASE:0_2 - dotchart has only positives and chart positives and negatives
        else if (dotDataMin >= 0 && (dataMin < 0 && dataMax > 0)) {
            const yScaleBaseChart = charting.getYScale(dataMin, dataMax, chartHeight, dotChartTopMargin, bottomMargin);
            const newDotChartHeight = yScaleBaseChart(0) * (dotChartHeight / chartHeight);
            yDotChart = yScaleBaseChart(0) - newDotChartHeight + titleHeight;
            dotChartHeight = newDotChartHeight;
            dotChartBottomMargin = 0;
        }

        // CASE: 1_0 - if chart has only positives and dotchart negatives
        else if (dataMin >= 0 && dotDataMax <= 0) {
            yChart = titleHeight;
            yDotChart = chartHeight / 2 + titleHeight;
            dotChartHeight = dotChartHeight / 2 - dotChartBottomMargin;
            dotChartTopMargin = 0;
            dotChartBottomMargin = 15;
            bottomMargin = chartHeight / 2;
        }

        // CASE: 1_1 - if columnchart and dotchart have only negatives
        else if (dataMax <= 0 && dotDataMax <= 0) {
            yDotChart = titleHeight;
            yChart = titleHeight;
        }

        // CASE: 1_2 - if dotchart has only negatives and chart has positives and negatives
        else if (dotDataMax <= 0 && (dataMin < 0 && dataMax > 0)) {
            const yScaleBaseChart = charting.getYScale(global_dataMin, global_dataMax, chartHeight, dotChartTopMargin, bottomMargin);
            yDotChart = yScaleBaseChart(0) + titleHeight - dotChartTopMargin;
            const new_chartHeight = chartHeight - yScaleBaseChart(0) + dotChartTopMargin;
            dotChartHeight = (new_chartHeight) * (dotChartHeight / chartHeight);
        }

        // CASE: 2_0 - if chart has only positives and dotchart negatives and positives
        else if (dataMin >= 0 && (dotDataMin <= 0 && dotDataMax >= 0)) {
            const yScaleDotChart = charting.getYScale(global_dotDataMin, global_dotDataMax, chartHeight, dotChartTopMargin, bottomMargin);
            bottomMargin = chartHeight - yScaleDotChart(0);
            yDotChart = yChart + ((1 - dotChartHeight / chartHeight) * yScaleDotChart(0));
        }

        // CASE: 2_1 - if chart has only negatives and dotchart negatives and positives
        else if (dataMax <= 0 && (dotDataMin <= 0 && dotDataMax >= 0)) {
            const yScaleDotChart = charting.getYScale(global_dotDataMin, global_dotDataMax, chartHeight, dotChartTopMargin, bottomMargin);
            topMargin = 0;
            yDotChart = yChart + ((1 - dotChartHeight / chartHeight) * yScaleDotChart(0));
            yDotChart -= ((1 - dotChartHeight / chartHeight) * (yChart - topMargin));
            yChart = yScaleDotChart(0) + titleHeight;
            chartHeight -= yScaleDotChart(0);
        }

        // CASE 2_2 - if both charts positives and negatives
        else if ((dotDataMin <= 0 && dotDataMax > 0) && (dataMin <= 0 && dataMax > 0)) {
            const yScaleBaseChart = charting.getYScale(global_dataMin, global_dataMax, chartHeight, topMargin, bottomMargin);
            const yScaleDotChart = charting.getYScale(global_dotDataMin, global_dotDataMax, dotChartHeight, dotChartTopMargin, dotChartBottomMargin);
            const scaleDotChart_0 = yScaleDotChart(0);
            const scaleChart_0 = yScaleBaseChart(0);
            let diff = 0;

            yDotChart = yChart;
            if (scaleDotChart_0 < scaleChart_0) {
                diff = scaleChart_0 - scaleDotChart_0;
                topMargin = dotChartTopMargin;
                yDotChart += diff;
            }
            else {
                diff = scaleDotChart_0 - scaleChart_0;
                yDotChart -= diff;
            }
        }

        if (comboChartType === ComboChartUnderlyingChart.plotChart) {
            this.plotChart(xChart, yChart, chartHeight, chartWidth, chartData, topMargin, bottomMargin, 0, this.settings.showLegend, multiplesChartDataMin, multiplesChartDataMax, undefined, plotTitle);
        }
        else if (comboChartType === ComboChartUnderlyingChart.waterfallChart) {
            waterfallChart(this.reportArea, this.svg, this.settings, this.slider, xChart, yChart,
                chartHeight, chartWidth, chartData, topMargin, bottomMargin, index, chartData.min, chartData.max, false, plotTitle, plotLegend);
        }
        else if (comboChartType === ComboChartUnderlyingChart.columnChart) {
            columnChart(this.reportArea, this.svg, this.settings, this.slider, xChart, yChart, chartHeight, chartWidth,
                chartData, topMargin, bottomMargin, index, minDataValue || chartData.min, maxDataValue || chartData.max, plotTitle, this.settings.plotOverlappedReference, plotLegend);
        }
        else if (comboChartType === ComboChartUnderlyingChart.varianceChart) {
            varianceChart(this.reportArea, this.svg, this.settings, this.slider, xChart, yChart, chartHeight, chartWidth, chartData,
                topMargin, bottomMargin, index, chartData.min, chartData.max, plotTitle, false, plotLegend);
        }
        else if (comboChartType === ComboChartUnderlyingChart.plusMinusChart) {
            plusMinusChart(this.reportArea, this.settings, xChart, yChart, chartHeight, chartWidth, chartData,
                topMargin, bottomMargin, index, minDataValue, maxDataValue, true, plotTitle, plotLegend);
        }
        else if (comboChartType === ComboChartUnderlyingChart.plusMinusDotChart) {
            plusMinusDotChart(this.reportArea, this.settings, xChart, yChart, chartHeight, chartWidth, chartData,
                topMargin, bottomMargin, index, minDataValue, maxDataValue, true, plotTitle, plotLegend);
        }

        dotChart(this.reportArea, this.settings, xDotChart, yDotChart, dotChartHeight, chartWidth, chartData, dotChartTopMargin, dotChartBottomMargin, index, dotDataMin, dotDataMax,
            this.settings.showLegend && this.settings.chartType !== ChartType.Waterfall, this.viewModel.isSingleSeriesViewModel);
    }

    private addResizeChartHeightUI(chartLayouts: ChartLayout[]) {
        const margin = MULTIPLE_MARGIN / 2;
        const minXPos = Math.round(Math.min(...chartLayouts.map(c => c.position.x))) - margin;
        const maxXPos = Math.round(Math.max(...chartLayouts.map(c => c.position.x + c.width))) + margin;
        const maxYPos = Math.round(Math.max(...chartLayouts.map(c => c.position.y + c.height))) + margin;
        const resizeLineYPos = Math.round(chartLayouts[0].position.y + chartLayouts[0].height + margin);
        if (resizeLineYPos < maxYPos) {
            const resizeRect = this.reportArea.append(RECT).classed("resize-line", true);
            resizeRect.attr(X, minXPos);
            resizeRect.attr(Y, resizeLineYPos - 5);
            resizeRect.attr(WIDTH, maxXPos - minXPos);
            resizeRect.attr(HEIGHT, 10);
            resizeRect.attr(FILL, WHITE);
            resizeRect.attr(OPACITY, 0);
            const resizeLine = drawing.drawLine(this.reportArea, minXPos, maxXPos, resizeLineYPos, resizeLineYPos, 1, GRAY, "resize-line");
            resizeLine.attr(OPACITY, 0);
            resizeLine.attr(STROKE_DASHARRAY, "5,3");
            resizeRect.style("cursor", "ns-resize");
            resizeLine.style(POINTER_EVENTS, "none");
            resizeRect.on(MOUSEOVER, () => {
                resizeRect.attr(OPACITY, 0.01);
                resizeLine.transition().duration(200).attr(OPACITY, 1);
            });
            resizeRect.on(MOUSEOUT, () => {
                resizeRect.attr(OPACITY, 0);
                resizeLine.transition().duration(200).attr(OPACITY, 0);
            });
            this.addResizeChartHeightDragDropHandlers(resizeRect, resizeLine);
        }
    }

    private addResizeChartHeightDragDropHandlers(resizeRect: d3.Selection<SVGElement, any, any, any>, resizeLine: d3.Selection<SVGElement, any, any, any>) {
        let startYPosition: number;
        const draggable = d3.drag()
            .on(DRAG, (event) => {
                const yPosition = (<any>event).y;
                resizeRect
                    .attr(Y, yPosition);
                resizeLine
                    .attr(Y1, yPosition + 5)
                    .attr(Y2, yPosition + 5);
            })
            .on(DRAGSTART, (event) => {
                resizeRect.on(MOUSEOUT, null);
                startYPosition = (<any>event).sourceEvent.y;
            })
            .on(DRAGEND, (event) => {
                const yEnd = (<any>event).sourceEvent.y;
                const deltaY = yEnd - startYPosition;
                this.settings.minChartHeight += deltaY;
                Visual.getInstance().constructViewModelAndVisualUpdate(this.settings);
            });

        resizeRect.call(draggable);
    }

    public static getGroupTitleConfig(settings: ChartSettings): GroupTitleConfig {
        return {
            groupTitleFontColor: settings.groupTitleFontColor,
            groupTitleFontFamily: settings.groupTitleFontFamily,
            groupTitleFontSize: settings.groupTitleFontSize,
            groupTitleAlignment: settings.groupTitleAlignment,
            groupTitleVerticalAlignment: settings.groupTitleVerticalAlignment
        }
    }

    public static getDifferenceHighlightConfig(settings: ChartSettings): DifferenceHighlightConfig {
        return {
            differenceHighlight: settings.differenceHighlight,
            differenceHighlightWidth: settings.differenceHighlightWidth,
            differenceHighlightEllipseBorderWidth: settings.differenceHighlightEllipseBorderWidth,
            differenceHighlightEllipseFillOpacity: settings.differenceHighlightEllipseFillOpacity,
            differenceHighlightEllipseBorderPadding: settings.differenceHighlightEllipseBorderPadding,
            differenceHighlightFontFamily: settings.differenceHighlightFontFamily,
            differenceHighlightFontSize: settings.differenceHighlightFontSize,
            differenceHighlightEllipse: settings.differenceHighlightEllipse,
            differenceHighlightConnectingLineColor: settings.differenceHighlightConnectingLineColor,
            differenceHighlightConnectingLineStyle: settings.differenceHighlightConnectingLineStyle,
            differenceHighlightLineWidth: settings.differenceHighlightLineWidth,
            differenceHighlightArrowStyle: settings.differenceHighlightArrowStyle,
            differenceHighlightMargin: settings.differenceHighlightMargin,
            differenceHighlightFromTo: settings.differenceHighlightFromTo,
            allowDifferenceHighlightChange: settings.getRealInteractionSettingValue(settings.allowDifferenceHighlightChange),
            differenceLabel: settings.differenceLabel
        }
    }

    public static getSmallMultiplesConfig(settings: ChartSettings): SmallMultiplesConfig {
        return {
            topNChartsToKeep: settings.topNChartsToKeep,
            showTopNChartsOptions: settings.showTopNChartsOptions
        }
    }

    public static getCategoryConfig(settings: ChartSettings): CategoryConfig {
        return {
            showCategoriesFontSettings: settings.showCategoriesFontSettings,
            categoriesFontSize: settings.categoriesFontSize,
            categoriesFontColor: settings.categoriesFontColor,
            categoriesFontFamily: settings.categoriesFontFamily,
            categoryLabelsOptions: settings.categoryLabelsOptions,
            categoryRotateAngle: settings.categoryRotateAngle,
            categoryRotateAngleLimit: settings.categoryRotateAngleLimit,
            rotatedCartegoriesHeight: settings.rotatedCartegoriesHeight,
            showCategories: settings.showCategories,
            topNCategoriesToKeep: settings.topNCategoriesToKeep,
            showTopNCategories: settings.showTopNCategories,
            topNOtherLabel: settings.topNOtherLabel,
            axisLabelDensity: settings.axisLabelDensity,
            axisLabelDensityEveryNthLabel: settings.axisLabelDensityEveryNthLabel,
            invertedCategories: settings.invertedCategories,
            highlightedCategories: settings.highlightedCategories,
            highlightedCategoriesCustomColors: settings.highlightedCategoriesCustomColors,
            resultCategories: settings.resultCategories,
        }
    }

    public static getHighlightingConfig(settings: ChartSettings): HighlightingConfig {
        return {
            highlightedCategories: settings.highlightedCategories,
            highlightedGroups: settings.highlightedGroups,
            highlightedGroupsCustomColors: settings.highlightedGroupsCustomColors,
            highlightedCategoriesCustomColors: settings.highlightedCategoriesCustomColors
        }
    }

    public static getDesignConfig(settings: ChartSettings) {
        return {
            colorScheme: settings.colorScheme,
            chartStyle: (settings.chartStyle as unknown) as ChartStyle
        }
    }

    public static getDataLabelsConfig(settings: ChartSettings): DataLabelsConfig {
        return {
            labelFontFamily: settings.labelFontFamily,
            labelFontColor: settings.labelFontColor,
            labelFontSize: settings.labelFontSize,
            labelDensity: settings.labelDensity,
            showDataLabels: settings.showDataLabels,
            displayUnits: settings.displayUnits,
            decimalPlacesPercentage: settings.decimalPlacesPercentage,
            showPercentageInLabel: settings.showPercentageInLabel,
            labelPercentagePointUnit: settings.labelPercentagePointUnit,
            decimalPlaces: settings.decimalPlaces,
            isPercentageData: settings.isPercentageData,
            showNegativeValuesInParenthesis: settings.showNegativeValuesInParenthesis(),
            shouldHideDataLabelUnits: settings.shouldHideDataLabelUnits()
        }
    }

    public static getLegendConfig(settings: ChartSettings): LegendConfig {
        return {
            legendItemsMargin: settings.legendItemsMargin,
            valueHeader: settings.valueHeader,
            legendWidth: settings.getLegendWidth(),
            legendScenarioKV: [],
            legendColors: Layout.prepareLegendColorMap(settings),
            defaultScenarioHeaders: settings.defaultScenarioHeaders,
        }
    }

    public static getVisualConfig(): VisualConfig {
        return {
            titleMenuSettings: Visual.titleMenuSettings,
            textSettings: Visual.textSettings,
            visualElement: Visual.element,
            visualViewportWidth: Visual.visualViewPort.width,
            formatterMap: Visual.formatterMap,
            isChartFocusPopupShown: Visual.isChartFocusPopupShown,
            animateDiffHighlightLabel: Visual.animateDiffHighlightLabel
        }
    }

    public static prepareLegendColorMap(settings: ChartSettings): object {
        let legendColors = { "true": {}, "false": {} }
        let scenarios = <Scenario[]>Object.values(Scenario);
        for (let scenario of scenarios) {
            legendColors.true[scenario] = settings.getLegendColor(true, scenario);
            legendColors.false[scenario] = settings.getLegendColor(false, scenario);
        }
        return legendColors;
    }


    public static getDefaultChartConfig(x: number, y: number, height: number, width: number, chartData: ChartData,
        topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number, plotCategories: boolean,
        plotTitle: boolean, plotLegend: boolean, settings: ChartSettings, viewModel: ViewModel, slider: HTMLElement) {
        let chartConfig: ChartConfig = {
            x: x,
            y: y,
            height: height,
            width: width,
            chartData: chartData,
            topMargin: topMargin,
            bottomMargin: bottomMargin,
            chartIndex: chartIndex,
            min: min,
            max: max,
            shouldPlotCategories: plotCategories,
            plotTitle: plotTitle,
            plotLegend: plotLegend,
            fontSizeUnit: FONT_SIZE_UNIT,

            groupTitleConfig: Layout.getGroupTitleConfig(settings),
            differenceHighlightConfig: Layout.getDifferenceHighlightConfig(settings),
            smallMultiplesConfig: Layout.getSmallMultiplesConfig(settings),
            categoryConfig: Layout.getCategoryConfig(settings),
            highlightingConfig: Layout.getHighlightingConfig(settings),
            designConfig: Layout.getDesignConfig(settings),
            dataLabelsConfig: Layout.getDataLabelsConfig(settings),
            legendConfig: Layout.getLegendConfig(settings),
            visualConfig: Layout.getVisualConfig(),

            useSecondReferenceVariance: false,

            chartType: settings.chartType,
            scenarioOptions: settings.scenarioOptions,
            multilineCategories: settings.multilineCategories,
            scenarioCategories: settings.scenarioCategories,
            showCommentMarkers: settings.showCommentMarkers(),
            // ViewModel properties
            is2dMultiples: viewModel.is2dMultiples,
            isSingleSeriesViewModel: viewModel.isSingleSeriesViewModel,
            grandTotalLabel: settings.grandTotalLabel,

            locale: settings.locale,
            showVerticalCharts: settings.showVerticalCharts,
            shouldPlotVerticalCharts: settings.shouldPlotVerticalCharts(),

            proVersionActive: settings.proVersionActive(),
            visualSelector: ZEBRABI_CHARTS_VISUAL,

            slider: slider,
            viewMode: settings.viewMode
        }
        return chartConfig;
    }

    public persistCallbackHandler(val: any, message: string) {
        switch (message) {
            case "topNChartsToKeep":
                this.settings.topNChartsToKeep = val;
                this.settings.persistTopNSettings();
                break;
            case "topNCategorySettings":
                this.settings.topNCategoriesToKeep = val;
                this.settings.persistTopNCategorySettings();
                break;
            case "invertedCategories":
                this.settings.persistInvertedCategoriesArray(val);
                break;
            case "highlightedCategories":
                this.settings.persistHighlightedCategoriesArray(val);
                break;
            case "highlightedCategoriesCustomColors":
                this.settings.highlightedCategoriesCustomColors = val;
                this.settings.persistCustomHighlightColorObject();
                break;
            case "legendScenarioKV":
                this.settings.persistLegendEntries(val[0], val[1]);
                break;
            case "scenarioCategories":
                this.settings.persistScenarioCategoriesObject(val);
                break;
            case "resultCategories":
                this.settings.persistIsResultCategoriesObject(val);
                break;
            case "animateDiffHighlightLabel":
                Visual.animateDiffHighlightLabel = <boolean>val;
                break;
            case "differenceLabel":
                this.settings.differenceLabel = val;
                this.settings.persistDiffHighlightChange();
                break;
        }
    }

    public eventCallbackHandler(args: any[], val: any, message: string) {
        const chart = <Chart>args[0];
        const chartConfig = <ChartConfig>args[1];
        switch (message) {
            case ACTION_CHART_FOCUS_POPUP:
                charting.createChartFocusPopup(this.settings, chart.chartData, chartConfig.topMargin + 10, chartConfig.bottomMargin + 10, val);
                break;
            case ACTION_COMMENT_MARKER_TOOLTIP:
                break;
            case ACTION_INVERT_MENU:
                invertResultAndHighlightContextMenu(this.settings, val[0])(val[1], val[2]);
                break;
            case ACTION_TOOLTIP:
                break;
        }
    }

    public initObservers(chart: Chart, chartConfig: ChartConfig) {
        const persistObserver = new PersistObserver();
        persistObserver.update = this.persistCallbackHandler.bind(this);
        const eventObserver = new ChartEventObserver();
        eventObserver.update = this.eventCallbackHandler.bind(this, [chart, chartConfig]);
        chart.attachObservers(persistObserver, eventObserver);
    }

    public plotChart(x: number, y: number, height: number, width: number, chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number,
        plotLegend: boolean, minDatavalue?: number, maxDataValue?: number, plotTitle: boolean = true, plotVerticalCategories?: boolean) {
        this.plotDebuggingLines(x, y, width, height, topMargin, bottomMargin);
        const min = minDatavalue != null ? minDatavalue : chartData.min;
        const max = maxDataValue != null ? maxDataValue : chartData.max;
        const chartType = this.settings.chartType;
        const layoutAttrs = this.layoutAttributes;
        const plotCategories = plotVerticalCategories === undefined ? this.settings.showCategories : plotVerticalCategories;

        let chartConfig = Layout.getDefaultChartConfig(x, y, height, width, chartData, topMargin, bottomMargin, chartIndex, min, max, plotCategories, plotTitle, plotLegend, this.settings, this.viewModel, this.slider);

        if (this.viewModel.isCategoryOnlyViewModel) {
            this.plotCategoriesOnly(x, y, height, width, chartData, topMargin, bottomMargin, min, max, this.reportArea, this.settings);
            return;
        }
        if (chartType === ChartType.Waterfall) {
            if (this.settings.showVerticalCharts) {
                verticalWaterfallChart(this.reportArea, this.svg, this.settings, this.slider, x, y, height, width, chartData, topMargin, bottomMargin,
                    chartIndex, min, max, this.viewModel.isSingleSeriesViewModel, plotTitle, false, plotCategories, layoutAttrs.leftMargin, layoutAttrs.rightMargin, layoutAttrs.leftMarginCategories);
            }
            else {
                waterfallChart(this.reportArea, this.svg, this.settings, this.slider, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex, min, max,
                    this.viewModel.isSingleSeriesViewModel, plotTitle, false);
            }
        }
        else if (chartType === ChartType.Area) {
            areaChart(this.reportArea, this.settings, this.slider, this.viewModel, x, y, height, width, chartData, topMargin, bottomMargin,
                chartIndex, min, max, this.viewModel.isSingleSeriesViewModel, plotTitle, plotLegend);
        }
        else if (chartType === ChartType.Variance) {
            if (this.settings.chartLayout === ABSOLUTE) {
                plusMinusChart(this.reportArea, this.settings, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex,
                    min, max, true, plotTitle, plotLegend);
            }
            else if (this.settings.chartLayout === RELATIVE) {
                plusMinusDotChart(this.reportArea, this.settings, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex,
                    min, max, true, plotTitle, plotLegend);
            }
            else if (this.settings.chartLayout === ACTUAL) {
                chartData.dataPoints.map(dp => {
                    dp.isNegative = viewModels.getDataPointNonNullValue(dp) < 0;
                });
                if (this.settings.showVerticalCharts) {
                    verticalBarChart(this.reportArea, this.svg, this.settings, this.slider, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex,
                        min, max, plotTitle, this.settings.plotOverlappedReference, plotLegend, plotCategories, layoutAttrs.leftMargin, layoutAttrs.rightMargin, layoutAttrs.leftMarginCategories);
                }
                else {
                    columnChart(this.reportArea, this.svg, this.settings, this.slider, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex, min, max, plotTitle, this.settings.plotOverlappedReference, plotLegend);
                }
            }
            else {
                this.settings.valueChartIntegrated = true;
                if (this.settings.showVerticalCharts) {
                    verticalVarianceChart(this.reportArea, this.svg, this.settings, this.slider, x, y, height, width, chartData,
                        topMargin, bottomMargin, chartIndex, min, max, plotTitle, true, plotLegend, plotCategories, layoutAttrs.leftMargin, layoutAttrs.rightMargin, layoutAttrs.leftMarginCategories);
                }
                else {
                    varianceChart(this.reportArea, this.svg, this.settings, this.slider, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex, min, max, plotTitle, true, plotLegend);
                }
            }
        }
        else if (chartType === ChartType.PlusMinusDot) {
            plusMinusDotChart(this.reportArea, this.settings, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex, min, max, true, plotTitle, plotLegend);
        }
        else if (chartType === ChartType.PlusMinus) {
            plusMinusChart(this.reportArea, this.settings, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex,
                min, max, true, plotTitle, plotLegend);
        }
        else if (chartType === ChartType.Bar) {
            if (this.settings.showVerticalCharts) {
                verticalBarChart(this.reportArea, this.svg, this.settings, this.slider, x, y, height, width, chartData, topMargin, bottomMargin,
                    chartIndex, min, max, plotTitle, false, plotLegend, plotCategories, layoutAttrs.leftMargin, layoutAttrs.rightMargin, layoutAttrs.leftMarginCategories);
            }
            else {
                columnChart(this.reportArea, this.svg, this.settings, this.slider, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex, min, max, plotTitle, false, plotLegend);
            }
        }
        else if (chartType === ChartType.Pin) {
            if (this.settings.showVerticalCharts) {
                verticalPinChart(this.reportArea, this.settings, this.slider, x, y, height, width, chartData, topMargin, bottomMargin,
                    chartIndex, min, max, plotCategories, plotTitle, plotLegend, layoutAttrs.leftMargin, layoutAttrs.rightMargin, layoutAttrs.leftMarginCategories);
            }
            else {
                if (flagHandler.has("core-pin-chart")) {
                    console.log("NEW pinchart")
                    chartConfig.shouldPlotCategories = true;
                    let pinChart = new PinChart(chartConfig, this.reportArea);
                    this.initObservers(<any>pinChart, chartConfig);
                    pinChart.render();
                } else {
                    pinChart(this.reportArea, this.settings, this.slider, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex, min, max, true, plotTitle, plotLegend);
                }
            }
        }
        else {
            lineChart(this.reportArea, this.settings, this.slider, this.viewModel, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex,
                min, max, this.viewModel.isSingleSeriesViewModel, plotTitle, plotLegend);
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public plotResponsiveChart(x: number, y: number, height: number, width: number, chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, minChartHeight: number,
        plotLegend: boolean, minDataValue?: number, maxDataValue?: number,
        extendedChartMinDataValue?: number, extendedChartMaxDataValue?: number, extendedRelativeChartheight?: number) {
        const topCommentMarkerMargin = this.layoutAttributes.topCommentMarkerMargin || 0;
        const bottomCommentMarkerMargin = this.layoutAttributes.bottomCommentMarkerMargin || 0;
        const plotComboChart = this.settings.scenarioOptions.secondActualValueIndex !== null && this.settings.showDotChart;

        if (this.settings.chartLayout === RESPONSIVE && height < minChartHeight * 1.5) {    // 350 ?
            if (this.settings.showCommentMarkers() && !this.settings.shouldPlotVerticalCharts()) {
                topMargin += topCommentMarkerMargin;
                bottomMargin += bottomCommentMarkerMargin;
            }
            if (plotComboChart) {
                this.plotComboChart(chartData, height, width, bottomMargin, 0, topMargin, x,
                    x, y, true, ComboChartUnderlyingChart.plotChart, 0, plotLegend);
            }
            else {
                this.plotChart(x, y, height, width, chartData, topMargin, bottomMargin, chartIndex, plotLegend, minDataValue, maxDataValue);
            }
            return;
        }
        else if (this.settings.chartType === ChartType.Waterfall) {
            this.plotDebuggingLines(x, y, width, height, topMargin, bottomMargin);
            let vm: ViewModel = {
                chartData: [],
                settings: this.settings,
                title: EMPTY,
                valuesFieldName: EMPTY,
                isSingleValueViewModel: false,
                isSingleSeriesViewModel: false,
                isCategoryOnlyViewModel: false,
                isMultiples: false,
                is2dMultiples: false,
                isDateCategories: this.viewModel.isDateCategories,
            };
            vm = getVarianceViewModel(vm, Visual.dataView, false, false, this.settings.locale, true);
            const varianceChartData = vm.chartData[0];
            const wfChartWidth = this.settings.differenceHighlight ? width - this.settings.differenceHighlightWidth : width;
            let plusMinusDotX = x + wfChartWidth / (chartData.dataPoints.length);
            let plusMinusDotWidth = width - 2 * (wfChartWidth / (chartData.dataPoints.length));

            const totalRelativeChartsHeight = height * 0.333;
            if (this.settings.scenarioOptions.secondReferenceScenario !== null && height > minChartHeight * 2) {
                this.plotDoubleReferencePlusMinusDotCharts(varianceChartData, totalRelativeChartsHeight, topMargin, plusMinusDotX, y, plusMinusDotWidth, false, plotLegend);
            }
            else {
                plusMinusDotX -= plotLegend ? this.settings.getLegendWidth() : 0;
                plusMinusDotWidth += plotLegend ? this.settings.getLegendWidth() : 0;
                plusMinusDotChart(this.reportArea, this.settings, plusMinusDotX < 0 ? 0 : plusMinusDotX, y, totalRelativeChartsHeight, plusMinusDotWidth, varianceChartData, topMargin, 0, 0,
                    varianceChartData.minOutlierValue, varianceChartData.maxOutlierValue, false, true, plotLegend);
            }

            // fix horizontal title position
            if (this.settings.groupTitleAlignment === LEFT) {
                d3.select(TEXT + "." + "group-chart-title").attr(X, x);
            }
            else if (this.settings.groupTitleAlignment === RIGHT) {
                d3.select(TEXT + "." + "group-chart-title").attr(X, x + wfChartWidth);
            }

            let waterfallChartTopMargin = this.getChartLabelsTopMargin(chartData.max);
            if (this.settings.showDataLabels && this.settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute) {
                waterfallChartTopMargin += this.settings.labelFontSize;
            }

            if (this.settings.showCommentMarkers() && !this.settings.showVerticalCharts) {
                waterfallChartTopMargin += this.layoutAttributes.topCommentMarkerMargin;
            }

            if (this.settings.scenarioOptions.secondReferenceScenario !== null && height > minChartHeight * 2 &&
                (chartData.max - chartData.min) + (varianceChartData.maxSecondReferenceVariance - varianceChartData.minSecondReferenceVariance) !== 0) {
                const waterfallChartSpan = chartData.max - chartData.min;
                const seconfReferenceVarianceChartSpan = varianceChartData.maxSecondReferenceVariance - varianceChartData.minSecondReferenceVariance;
                const absChartTopMargin = this.getChartLabelsTopMargin(chartData.maxSecondReferenceVariance);
                const absChartBottomMargin = this.getChartLabelsBottomMargin(chartData.minSecondReferenceVariance);

                const availableHeight = height - (totalRelativeChartsHeight + absChartTopMargin + absChartBottomMargin + waterfallChartTopMargin + bottomMargin);
                const waterfallChartHeight = availableHeight * waterfallChartSpan / (waterfallChartSpan + seconfReferenceVarianceChartSpan)
                    + waterfallChartTopMargin + bottomMargin;
                const absVariance2ChartHeight = availableHeight * seconfReferenceVarianceChartSpan / (waterfallChartSpan + seconfReferenceVarianceChartSpan)
                    + absChartTopMargin + absChartBottomMargin;

                plusMinusChart(this.reportArea, this.settings, plusMinusDotX, y + totalRelativeChartsHeight, absVariance2ChartHeight, plusMinusDotWidth,
                    varianceChartData, absChartTopMargin, absChartBottomMargin, chartIndex, varianceChartData.minSecondReferenceVariance, varianceChartData.maxSecondReferenceVariance,
                    false, false, plotLegend, true);

                if (plotComboChart) {
                    this.plotComboChart(chartData, waterfallChartHeight, width, bottomMargin, 0, topMargin, x,
                        x, y + totalRelativeChartsHeight + absVariance2ChartHeight, false, ComboChartUnderlyingChart.waterfallChart, 0, plotLegend);
                }
                else {
                    waterfallChart(this.reportArea, this.svg, this.settings, this.slider, x, y + totalRelativeChartsHeight + absVariance2ChartHeight,
                        waterfallChartHeight, width, chartData, waterfallChartTopMargin, bottomMargin, chartIndex, chartData.min, chartData.max, false, false, plotLegend);
                }
            }
            else {
                if (this.settings.showVerticalCharts) {
                    verticalWaterfallChart(this.reportArea, this.svg, this.settings, this.slider, x, y + totalRelativeChartsHeight,
                        height - totalRelativeChartsHeight, width, chartData, waterfallChartTopMargin, bottomMargin, chartIndex, chartData.min, chartData.max,
                        false, false, false, true, this.layoutAttributes.leftMargin, this.layoutAttributes.rightMargin, this.layoutAttributes.leftMarginCategories);
                }
                else {
                    if (plotComboChart) {
                        this.plotComboChart(chartData, height - totalRelativeChartsHeight, width, bottomMargin, 0,
                            topMargin, x, x, y + totalRelativeChartsHeight, false, ComboChartUnderlyingChart.waterfallChart, 0, false);
                    }
                    else {
                        waterfallChart(this.reportArea, this.svg, this.settings, this.slider, x, y + totalRelativeChartsHeight, height - totalRelativeChartsHeight,
                            width, chartData, waterfallChartTopMargin, bottomMargin, chartIndex, chartData.min, chartData.max, false, false, false);
                    }
                }
            }
        }
        else if (this.settings.chartLayout === ABSOLUTE) {
            if (this.settings.scenarioOptions.secondReferenceScenario !== null) {
                this.plotDoubleReferencePlusMinusCharts(height, chartData, bottomMargin, x, y, width, chartIndex, true, plotLegend);
            }
            else {
                if (plotComboChart) {
                    this.plotComboChart(chartData, height, width, bottomMargin + bottomCommentMarkerMargin, 0,
                        topMargin + topCommentMarkerMargin, x, x, y, true, ComboChartUnderlyingChart.plusMinusChart,
                        chartIndex, plotLegend, chartData.minVariance, chartData.maxVariance);
                }
                else {
                    plusMinusChart(this.reportArea, this.settings, x, y, height, width, chartData, topMargin + topCommentMarkerMargin, bottomMargin + bottomCommentMarkerMargin, chartIndex,
                        chartData.minVariance, chartData.maxVariance, true, true, plotLegend);
                }
            }
        }
        else if (this.settings.chartLayout === RELATIVE) {
            if (this.settings.scenarioOptions.secondReferenceScenario !== null) {
                this.plotDoubleReferencePlusMinusDotCharts(chartData, height, topMargin, x, y, width, true, plotLegend);
            }
            else {
                if (plotComboChart) {
                    this.plotComboChart(chartData, height, width, bottomMargin + bottomCommentMarkerMargin, 0,
                        topMargin + topCommentMarkerMargin, x, x, y, true, ComboChartUnderlyingChart.plusMinusDotChart,
                        chartIndex, plotLegend, chartData.minOutlierValue, chartData.maxOutlierValue);
                }
                else {
                    plusMinusDotChart(this.reportArea, this.settings, x, y, height, width, chartData, topMargin + topCommentMarkerMargin, bottomMargin + bottomCommentMarkerMargin, chartIndex,
                        chartData.minOutlierValue, chartData.maxOutlierValue, true, true, plotLegend);
                }
            }
        }
        else if (this.settings.chartLayout === ABSOLUTE_RELATIVE) {
            const totalRelativeChartsHeight = this.settings.shouldPlotExtendedChartsMultiples() && extendedRelativeChartheight ? extendedRelativeChartheight : height * 0.333;
            const totalAbsChartHeight = height - totalRelativeChartsHeight;
            if (this.settings.scenarioOptions.secondReferenceScenario !== null) {
                this.plotDoubleReferencePlusMinusDotCharts(chartData, totalRelativeChartsHeight, topMargin, x, y, width, false, plotLegend);
                this.plotDoubleReferencePlusMinusCharts(totalAbsChartHeight, chartData, bottomMargin, x, y + totalRelativeChartsHeight, width, chartIndex, false, plotLegend);
            }
            else {
                plusMinusDotChart(this.reportArea, this.settings, x, y, totalRelativeChartsHeight, width, chartData, topMargin, 0, 0,
                    extendedChartMinDataValue || chartData.minOutlierValue, extendedChartMaxDataValue || chartData.maxOutlierValue, false, true, plotLegend);
                const bottomChartTopMargin = this.getChartLabelsTopMargin(chartData.maxVariance) + topCommentMarkerMargin;
                if (this.settings.showDataLabels && chartData.minVariance < 0) {
                    bottomMargin += this.settings.labelFontSize + 5;
                }
                bottomMargin += bottomCommentMarkerMargin;
                if (plotComboChart) {
                    this.plotComboChart(chartData, totalAbsChartHeight, width, bottomMargin, 0, bottomChartTopMargin, x,
                        x, y + totalRelativeChartsHeight, false, ComboChartUnderlyingChart.plusMinusChart, chartIndex, plotLegend,
                        minDataValue || chartData.minVariance, maxDataValue || chartData.maxVariance);
                }
                else {
                    plusMinusChart(this.reportArea, this.settings, x, y + totalRelativeChartsHeight, totalAbsChartHeight, width, chartData, bottomChartTopMargin, bottomMargin,
                        chartIndex, minDataValue || chartData.minVariance, maxDataValue || chartData.maxVariance, true, false, plotLegend);
                }
            }
        }
        else if (this.settings.chartLayout === ACTUAL_ABSOLUTE) {
            this.plotDebuggingLines(x, y, width, height, topMargin, bottomMargin);
            if (this.settings.scenarioOptions.secondReferenceScenario !== null) {
                this.plotDoubleReferenceActualAbsoluteCharts(chartData, height, bottomMargin, topMargin, x, y, width, chartIndex, true, plotLegend);
            }
            else {
                let absChartSpan = (extendedChartMaxDataValue || chartData.maxVariance) - (extendedChartMinDataValue || chartData.minVariance);
                let barChartSpan = (maxDataValue || chartData.max) - (minDataValue || chartData.min);
                if (absChartSpan === 0 && barChartSpan === 0 || barChartSpan + absChartSpan === 0) {
                    absChartSpan = barChartSpan = 1;
                }
                const absChartBottomMargin = this.getChartLabelsBottomMargin(extendedChartMinDataValue || chartData.minVariance) + bottomCommentMarkerMargin;
                const absChartTopMargin = topMargin + topCommentMarkerMargin;
                const barChartTopMargin = this.settings.labelFontSize + 5;
                const newHeight = height - (bottomMargin + absChartTopMargin + absChartBottomMargin + barChartTopMargin);
                const absChartHeight = newHeight * absChartSpan / (barChartSpan + absChartSpan);
                const barChartHeight = newHeight * barChartSpan / (barChartSpan + absChartSpan);
                const absChartTotalHeight = absChartHeight + absChartTopMargin + absChartBottomMargin;
                plusMinusChart(this.reportArea, this.settings, x, y, absChartTotalHeight, width, chartData, absChartTopMargin, absChartBottomMargin, chartIndex,
                    extendedChartMinDataValue || chartData.minVariance, extendedChartMaxDataValue || chartData.maxVariance, false, true, plotLegend);
                if (plotComboChart) {
                    this.plotComboChart(chartData, barChartHeight + bottomMargin + barChartTopMargin, width, bottomMargin, 0, barChartTopMargin, x,
                        x, y + absChartTotalHeight, false, ComboChartUnderlyingChart.columnChart, chartIndex, plotLegend,
                        minDataValue || chartData.min, maxDataValue || chartData.max);
                }
                else {
                    columnChart(this.reportArea, this.svg, this.settings, this.slider, x, y + absChartTotalHeight, barChartHeight + bottomMargin + barChartTopMargin, width,
                        chartData, barChartTopMargin, bottomMargin, chartIndex, minDataValue || chartData.min, maxDataValue || chartData.max, false, this.settings.plotOverlappedReference, plotLegend);
                }

            }
        }
        else if (this.settings.chartLayout === ACTUAL_RELATIVE) {
            const totalRelativeChartsHeight = this.settings.shouldPlotExtendedChartsMultiples() && extendedRelativeChartheight ?
                extendedRelativeChartheight : height * (this.settings.scenarioOptions.secondReferenceScenario !== null ? 0.5 : 0.333);
            const totalAbsChartHeight = height - totalRelativeChartsHeight;
            if (this.settings.scenarioOptions.secondReferenceScenario !== null) {
                this.plotDoubleReferencePlusMinusDotCharts(chartData, totalRelativeChartsHeight, topMargin, x, y, width, false, plotLegend);
            }
            else {
                plusMinusDotChart(this.reportArea, this.settings, x, y, totalRelativeChartsHeight, width, chartData, topMargin, 0, 0,
                    extendedChartMinDataValue || chartData.minOutlierValue, extendedChartMaxDataValue || chartData.maxOutlierValue, false, true, plotLegend);
            }
            const bottomChartTopMargin = this.getChartLabelsTopMargin(maxDataValue || chartData.max) + topCommentMarkerMargin;
            if (this.settings.showDataLabels && chartData.min < 0) {
                bottomMargin += this.settings.labelFontSize + 5;
            }

            if (plotComboChart) {
                this.plotComboChart(chartData, totalAbsChartHeight, width, bottomMargin, 0, topMargin, x,
                    x, y + totalRelativeChartsHeight, false, ComboChartUnderlyingChart.columnChart, chartIndex, plotLegend,
                    minDataValue || chartData.min, maxDataValue || chartData.max);
            }
            else {
                columnChart(this.reportArea, this.svg, this.settings, this.slider, x, y + totalRelativeChartsHeight,
                    totalAbsChartHeight, width, chartData, bottomChartTopMargin, bottomMargin + bottomCommentMarkerMargin, chartIndex,
                    minDataValue || chartData.min, maxDataValue || chartData.max,
                    false, this.settings.plotOverlappedReference, plotLegend);
            }
        }
        else if (this.settings.chartLayout === ACTUAL) {
            if (this.settings.showVerticalCharts) {
                verticalBarChart(this.reportArea, this.svg, this.settings, this.slider, x, y, height, width, chartData, topMargin, bottomMargin, chartIndex,
                    chartData.min, chartData.max, true, this.settings.plotOverlappedReference, plotLegend, true, this.layoutAttributes.leftMargin, this.layoutAttributes.rightMargin, this.layoutAttributes.leftMarginCategories);
            }
            else {
                if (plotComboChart) {
                    this.plotComboChart(chartData, height, width, bottomMargin + bottomCommentMarkerMargin, 0,
                        topMargin + topCommentMarkerMargin, x, x, y, false, ComboChartUnderlyingChart.columnChart, chartIndex, plotLegend,
                        chartData.min, chartData.max);
                }
                else {
                    columnChart(this.reportArea, this.svg, this.settings, this.slider, x, y, height, width, chartData,
                        topMargin + topCommentMarkerMargin, bottomMargin + bottomCommentMarkerMargin, chartIndex,
                        chartData.min, chartData.max, true, this.settings.plotOverlappedReference, plotLegend);
                }
            }
        }
        else if (height < minChartHeight * 2.5) {   // 500 ?
            this.plotDebuggingLines(x, y, width, height, topMargin, bottomMargin);
            plusMinusDotChart(this.reportArea, this.settings, x, y, height * 0.33, width, chartData, topMargin, 0, 0,
                chartData.minOutlierValue, chartData.maxOutlierValue, false, true, plotLegend);
            const bottomChartTopMargin = this.getChartLabelsTopMargin(chartData.max) + topCommentMarkerMargin;
            if (plotComboChart) {
                this.plotComboChart(chartData, height * 0.67, width, bottomMargin + bottomCommentMarkerMargin, 0, bottomChartTopMargin, x, x,
                    y + height * 0.33, false, ComboChartUnderlyingChart.varianceChart, chartIndex, plotLegend);
            }
            else {
                varianceChart(this.reportArea, this.svg, this.settings, this.slider, x, y + height * 0.33, height * 0.67,
                    width, chartData, bottomChartTopMargin, bottomMargin + bottomCommentMarkerMargin, chartIndex, chartData.min, chartData.max, false, false, plotLegend);
            }

            this.settings.valueChartIntegrated = true;
        }
        else {
            this.plotDebuggingLines(x, y, width, height, topMargin, bottomMargin);
            if (this.settings.scenarioOptions.secondReferenceScenario !== null && height > minChartHeight * 3) {
                const totalRelativeChartsHeight = height * 0.333;
                this.plotDoubleReferencePlusMinusDotCharts(chartData, totalRelativeChartsHeight, topMargin, x, y, width, false, plotLegend);
                const absChartTopMargin = this.getChartLabelsTopMargin(chartData.maxVariance);
                this.plotDoubleReferenceActualAbsoluteCharts(chartData, height - totalRelativeChartsHeight, bottomMargin, absChartTopMargin,
                    x, y + totalRelativeChartsHeight, width, chartIndex, false, plotLegend);
            }
            else {
                // column + absolute + relative
                let newHeight = height * 0.67;
                const absChartSpan = chartData.maxVariance - chartData.minVariance;
                const barChartPlotValues = chartData.dataPoints.map(d => viewModels.getDataPointNonNullValue(d));
                const barChartMinValue = Math.min(0, ...barChartPlotValues);
                const barChartMaxValue = Math.max(0, ...barChartPlotValues);
                let barChartMin = barChartMinValue;
                let barChartMax = barChartMaxValue;
                if (this.settings.plotOverlappedReference) {
                    const referenceValues = chartData.dataPoints.map(d => d.reference);
                    barChartMin = Math.min(barChartMinValue, ...referenceValues);
                    barChartMax = Math.max(barChartMaxValue, ...referenceValues);
                }

                const barChartSpan = barChartMax - barChartMin;
                const absChartBottomMargin = this.getChartLabelsBottomMargin(chartData.minVariance) + bottomCommentMarkerMargin;
                const absChartTopMargin = this.getChartLabelsTopMargin(chartData.maxVariance) + topCommentMarkerMargin;
                const barChartTopMargin = this.settings.labelFontSize + (this.settings.showDataLabels && barChartMax > 0 && barChartMaxValue + this.settings.labelFontSize > barChartMax ? this.settings.labelFontSize : 0);

                newHeight -= (bottomMargin + absChartTopMargin + absChartBottomMargin + barChartTopMargin);
                const absChartHeight = newHeight * absChartSpan / (barChartSpan + absChartSpan);
                const barChartHeight = newHeight * barChartSpan / (barChartSpan + absChartSpan);
                const absChartTotalHeight = absChartHeight + absChartTopMargin + absChartBottomMargin;

                plusMinusDotChart(this.reportArea, this.settings, x, y, height * 0.33, width, chartData, topMargin, 0, chartIndex,
                    chartData.minOutlierValue, chartData.maxOutlierValue, false, true, plotLegend);
                plusMinusChart(this.reportArea, this.settings, x, y + height * 0.33, absChartTotalHeight, width, chartData, absChartTopMargin, absChartBottomMargin, chartIndex,
                    chartData.minVariance, chartData.maxVariance, false, false, plotLegend);

                if (plotComboChart) {
                    this.plotComboChart(chartData, barChartHeight + bottomMargin + barChartTopMargin, width, bottomMargin, 0, topMargin, x,
                        x, y + height * 0.33 + absChartTotalHeight, false, ComboChartUnderlyingChart.columnChart, chartIndex, plotLegend);
                }
                else {
                    columnChart(this.reportArea, this.svg, this.settings, this.slider, x, y + height * 0.33 + absChartTotalHeight, barChartHeight + bottomMargin + barChartTopMargin, width, chartData, barChartTopMargin, bottomMargin, chartIndex,
                        barChartMin, barChartMax, false, this.settings.plotOverlappedReference, plotLegend);
                }
            }
        }

        if (this.settings.scenarioOptions.secondValueScenario !== null && !this.settings.shouldPlotVerticalCharts()) {
            const delimitersGroup = this.reportArea.selectAll(`${LINE}.${AXIS_SCENARIO_DELIMITER}`);
            const delimitersArray = delimitersGroup.nodes();
            const firstDelimiter = <SVGLineElement>delimitersArray[0];
            const lastDelimiter = <SVGLineElement>delimitersArray[delimitersArray.length - 1];
            delimitersGroup.remove();
            if (firstDelimiter && lastDelimiter) {
                drawing.plotAxisScenarioDelimiter(this.reportArea, firstDelimiter.x1.baseVal.value, firstDelimiter.y1.baseVal.value, lastDelimiter.y2.baseVal.value);
            }
        }
    }

    private plotDoubleReferencePlusMinusCharts(height: number, chartData: ChartData, bottomMargin: number, x: number, y: number, width: number,
        chartIndex: number, plotChart1Title: boolean, plotLegend: boolean) {
        const absVarianceChartSpan = chartData.maxVariance - chartData.minVariance;
        const absVariance2ChartSpan = chartData.maxSecondReferenceVariance - chartData.minSecondReferenceVariance;
        const absChartTopMargin = this.getChartLabelsTopMargin(chartData.maxVariance) + this.layoutAttributes.topCommentMarkerMargin;
        const absChartBottomMargin = this.getChartLabelsBottomMargin(chartData.minVariance) + this.layoutAttributes.bottomCommentMarkerMargin;
        const absChart2TopMargin = this.getChartLabelsTopMargin(chartData.maxSecondReferenceVariance);
        if (this.settings.showDataLabels && chartData.minVariance < 0) {
            bottomMargin += this.settings.labelFontSize + 5;
        }
        const absChartsAvailableHeight = height - (absChartTopMargin + absChartBottomMargin + absChart2TopMargin + bottomMargin);
        const absVarianceChartHeight = absChartsAvailableHeight * absVarianceChartSpan / (absVarianceChartSpan + absVariance2ChartSpan)
            + absChartTopMargin + absChartBottomMargin;
        const absVariance2ChartHeight = absChartsAvailableHeight * absVariance2ChartSpan / (absVarianceChartSpan + absVariance2ChartSpan)
            + absChart2TopMargin + bottomMargin;
        plusMinusChart(this.reportArea, this.settings, x, y, absVarianceChartHeight, width, chartData, absChartTopMargin, absChartBottomMargin,
            chartIndex, chartData.minVariance, chartData.maxVariance, false, plotChart1Title, plotLegend);

        plusMinusChart(this.reportArea, this.settings, x, y + absVarianceChartHeight, absVariance2ChartHeight, width, chartData, absChart2TopMargin, bottomMargin,
            chartIndex, chartData.minSecondReferenceVariance, chartData.maxSecondReferenceVariance, true, false, plotLegend, true);
    }

    private plotDoubleReferenceActualAbsoluteCharts(chartData: ChartData, height: number, bottomMargin: number, topMargin: number, x: number, y: number,
        width: number, chartIndex: number, plotChart1Title: boolean, plotLegend: boolean) {
        const absChartSpan = chartData.maxVariance - chartData.minVariance;
        const absChart2Span = chartData.maxSecondReferenceVariance - chartData.minSecondReferenceVariance;
        const barChartSpan = chartData.max - chartData.min;
        const absChartTopMargin = topMargin + this.layoutAttributes.topCommentMarkerMargin;
        const absChartBottomMargin = this.getChartLabelsBottomMargin(chartData.minVariance) + this.layoutAttributes.bottomCommentMarkerMargin;
        const absChart2BottomMargin = this.getChartLabelsBottomMargin(chartData.minSecondReferenceVariance);
        const absChart2TopMargin = this.getChartLabelsTopMargin(chartData.maxSecondReferenceVariance);
        const barChartTopMargin = this.settings.labelFontSize + 5;
        const availableHeight = height - (bottomMargin + absChartTopMargin + absChartBottomMargin + barChartTopMargin + absChart2BottomMargin + absChart2TopMargin);
        const absChartHeight = availableHeight * absChartSpan / (barChartSpan + absChartSpan + absChart2Span) + absChartTopMargin + absChartBottomMargin;
        const absChart2Height = availableHeight * absChart2Span / (barChartSpan + absChartSpan + absChart2Span) + absChart2BottomMargin + absChart2TopMargin;
        const barChartHeight = availableHeight * barChartSpan / (barChartSpan + absChartSpan + absChart2Span) + bottomMargin + barChartTopMargin;

        plusMinusChart(this.reportArea, this.settings, x, y, absChartHeight, width, chartData, absChartTopMargin, absChartBottomMargin,
            chartIndex, chartData.minVariance, chartData.maxVariance, false, plotChart1Title, plotLegend);
        plusMinusChart(this.reportArea, this.settings, x, y + absChartHeight, absChart2Height, width, chartData, absChart2TopMargin, absChart2BottomMargin,
            chartIndex, chartData.minSecondReferenceVariance, chartData.maxSecondReferenceVariance, false, false, plotLegend, true);

        if (this.settings.showDotChart) {
            this.plotComboChart(chartData, barChartHeight, width, bottomMargin, 0, topMargin, x,
                x, y + absChartHeight + absChart2Height, false, ComboChartUnderlyingChart.columnChart, 0, plotLegend);
        }
        else {
            columnChart(this.reportArea, this.svg, this.settings, this.slider, x, y + absChartHeight + absChart2Height, barChartHeight, width, chartData,
                barChartTopMargin, bottomMargin, chartIndex, chartData.min, chartData.max, false, this.settings.plotOverlappedReference, plotLegend);
        }
    }

    private plotDoubleReferencePlusMinusDotCharts(chartData: ChartData, totalRelativeChartsHeight: number, topMargin: number, x: number, y: number,
        width: number, plotChart2Categories: boolean, plotLegend: boolean) {
        const relativeVarianceChartSpan = chartData.maxRelativeVariance - chartData.minRelativeVariance;
        const relativeVariance2ChartSpan = chartData.maxSecondReferenceRelativeVariance - chartData.minSecondReferenceRelativeVariance;
        if (relativeVarianceChartSpan + relativeVariance2ChartSpan === 0) {
            return;
        }
        const referenceChartTopMargin = topMargin + (this.settings.chartLayout === RELATIVE ? this.layoutAttributes.topCommentMarkerMargin : 0);
        const referenceChartBottomMargin = this.getChartLabelsBottomMargin(chartData.minRelativeVariance) + (this.settings.chartLayout === RELATIVE ? this.layoutAttributes.bottomCommentMarkerMargin : 0);
        const reference2ChartBottomMargin = this.getChartLabelsBottomMargin(chartData.minSecondReferenceRelativeVariance);
        const reference2ChartTopMargin = this.getChartLabelsTopMargin(chartData.maxSecondReferenceRelativeVariance);
        const availableHeight = totalRelativeChartsHeight - (referenceChartTopMargin + referenceChartBottomMargin + reference2ChartBottomMargin + reference2ChartTopMargin);
        const referenceVarianceChartHeight = availableHeight * relativeVarianceChartSpan / (relativeVarianceChartSpan + relativeVariance2ChartSpan)
            + referenceChartTopMargin + referenceChartBottomMargin;
        const secondReferenceVarianceChartHeight = availableHeight * relativeVariance2ChartSpan / (relativeVarianceChartSpan + relativeVariance2ChartSpan)
            + reference2ChartBottomMargin + reference2ChartTopMargin;
        plusMinusDotChart(this.reportArea, this.settings, x, y, referenceVarianceChartHeight, width, chartData,
            referenceChartTopMargin, referenceChartBottomMargin, 0, chartData.minOutlierValue, chartData.maxOutlierValue, false, true, plotLegend);
        plusMinusDotChart(this.reportArea, this.settings, x, y + referenceVarianceChartHeight, secondReferenceVarianceChartHeight, width, chartData,
            reference2ChartTopMargin, reference2ChartBottomMargin, 0, chartData.minSecondReferenceRelativeVariance, chartData.maxSecondReferenceRelativeVariance,
            plotChart2Categories, false, plotLegend, true);
    }

    private plotDebuggingLines(x: number, y: number, width: number, height: number, topMargin: number, bottomMargin: number) {
        if (USEDEBUGGING) {
            drawing.drawLine(this.reportArea, x, x + width, y, y, 1, "black", "line");
            drawing.drawLine(this.reportArea, x, x + width, y + topMargin, y + topMargin, 1, "red", "line");
            drawing.drawLine(this.reportArea, x, x + width, y + height - bottomMargin, y + height - bottomMargin, 1, "blue", "line");
            drawing.drawLine(this.reportArea, x, x + width, y + height, y + height, 1, "yellow", "line");
        }
    }

    private plotCategoriesOnly(x: number, y: number, height: number, width: number, chartData: ChartData, topMargin: number, bottomMargin: number, min: number, max: number,
        reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings) {
        const chartWidth = width;
        const yScale = charting.getYScale(min, max, height, topMargin, bottomMargin);
        const xScale = charting.getXScale(chartData, x, chartWidth, 0.2);
        charting.plotChartTitle(reportArea, settings, true, chartData, x, y, width, height, max, min, topMargin, bottomMargin);
        charting.plotCategories(reportArea, settings, chartData, y + height, xScale, width, y + yScale(0));
        drawing.plotHorizontalAxis(reportArea, false, x, chartWidth, y + yScale(0), settings.colorScheme.axisColor, settings.scenarioOptions.referenceScenario);
    }

    private getChartLabelsBottomMargin(min: number): number {
        return this.settings.labelFontSize + (this.settings.showDataLabels && (min < 0 || this.viewModel.isMultiples) ? this.settings.labelFontSize : 5);
    }

    private getChartLabelsTopMargin(max: number): number {
        return this.settings.labelFontSize + (this.settings.showDataLabels && (max > 0 || this.viewModel.isMultiples) ? this.settings.labelFontSize : 5);
    }
}
