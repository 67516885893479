import { Visual } from "../../visual";
import { DataLabelMenuItem, DataLabelsUnits, NegativeValuesFormat, LabelDensity } from "../../enums";
import {
    FontFormSubscriber, FontFormData, FontSettingsActions, Subscriber, DecimalPointsIconsOrganismSubscriber,
    DecimalPointsOrganismActions, DropdownSubscriber, ToggleButtonSubscriber, ToggleButtonData, TextInputSubscriber,
    NumberInputSubscriber
} from "@zebrabi/design-library";

export default class DataLabelMenuItemSubscriberFactory {
    private chartSettings = Visual.settings;

    public getSubscriber(menuItem: DataLabelMenuItem): Subscriber<any> {
        switch (menuItem) {
            case DataLabelMenuItem.fontSettings: {
                const fontSettingsSubscriber = new FontFormSubscriber();
                fontSettingsSubscriber.update = (message: FontFormData, action: FontSettingsActions) => {
                    if (action === FontSettingsActions.FontFamilyChange) {
                        this.chartSettings.persistDataLabelSettings({ labelFontFamily: message.fontFamily });
                    } else if (action === FontSettingsActions.FontSizeChange) {
                        this.chartSettings.persistDataLabelSettings({ labelFontSize: message.fontSize });
                    } else if (action === FontSettingsActions.FontColorSave) {
                        this.chartSettings.persistDataLabelSettings({ labelFontColor: message.fontColor.hex });
                    } else if (action === FontSettingsActions.FontColorClear) {
                        this.chartSettings.persistDataLabelSettings({ labelFontColor: this.chartSettings.categoriesFontColor });
                    }
                };

                return fontSettingsSubscriber;
            }
            case DataLabelMenuItem.decimalPlaces: {
                const decimalPointsSetterOrganismSubscriber = new DecimalPointsIconsOrganismSubscriber();
                decimalPointsSetterOrganismSubscriber.update = (value: number, action: DecimalPointsOrganismActions): void => {
                    if (action === DecimalPointsOrganismActions.ValueChange) {
                        this.chartSettings.persistDataLabelSettings({ decimalPlaces: value });
                    } else if (action === DecimalPointsOrganismActions.ValuePercentageChange) {
                        this.chartSettings.persistDataLabelSettings({ decimalPlacesPercentage: value });
                    }
                };
                return decimalPointsSetterOrganismSubscriber;
            }
            case DataLabelMenuItem.units: {
                const unitsDataLabelDropdownSubscriber = new DropdownSubscriber();
                unitsDataLabelDropdownSubscriber.update = (value: DataLabelsUnits): void => {
                    this.chartSettings.persistDataLabelSettings({ displayUnits: value });
                };
                return unitsDataLabelDropdownSubscriber;
            }
            case DataLabelMenuItem.showPercentageInLabel: {
                const showPercentageSymbolDLToggleSubscriber = new ToggleButtonSubscriber();
                showPercentageSymbolDLToggleSubscriber.update = (message: ToggleButtonData): void => {
                    this.chartSettings.persistDataLabelSettings({ showPercentageInLabel: message.value });
                };
                return showPercentageSymbolDLToggleSubscriber;
            }
            case DataLabelMenuItem.negativeValuesFormat: {
                const negativeValuesFormatDLDropdownSubscriber = new DropdownSubscriber();
                negativeValuesFormatDLDropdownSubscriber.update = (value: NegativeValuesFormat): void => {
                    this.chartSettings.persistDataLabelSettings({ negativeValuesFormat: value });
                };
                return negativeValuesFormatDLDropdownSubscriber;
            }
            case DataLabelMenuItem.labelDensity: {
                const labelDensityDLDropdownSubscriber = new DropdownSubscriber();
                labelDensityDLDropdownSubscriber.update = (value: LabelDensity): void => {
                    this.chartSettings.persistDataLabelSettings({ labelDensity: value });
                };
                return labelDensityDLDropdownSubscriber;
            }
            case DataLabelMenuItem.labelPercentagePointUnit: {
                const percentagePointInputNSubscriber = new TextInputSubscriber();
                percentagePointInputNSubscriber.update = (value: string): void => {
                    this.chartSettings.persistDataLabelSettings({ labelPercentagePointUnit: value });
                };
                return percentagePointInputNSubscriber;
            }
            case DataLabelMenuItem.backgroundTransparency: {
                const backgroundTransparencyDLInputSubscriber = new NumberInputSubscriber();
                backgroundTransparencyDLInputSubscriber.update = (value: any): void => {
                    this.chartSettings.persistDataLabelSettings({ labelBackgroundTransparency: value });
                };
                return backgroundTransparencyDLInputSubscriber;
            }
            case DataLabelMenuItem.showReferenceLabels: {
                const showReferenceLabelsSubscriber = new ToggleButtonSubscriber();
                showReferenceLabelsSubscriber.update = (message: ToggleButtonData): void => {
                    this.chartSettings.persistDataLabelSettings({ showReferenceLabels: message.value });
                };
                return showReferenceLabelsSubscriber;
            }
        }
    }
}
