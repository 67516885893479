import {
    ChartStyle,
    CLICK, RESET,
} from "../library/constants";
import {
    DropdownSubscriber,
    InlineDropdown,
    InlineNumberInput,
    InlineToggleButton,
    NumberInputSubscriber,
    LinkButton,
    ToggleButtonSubscriber
} from "@zebrabi/design-library";
import { Visual } from "../visual";
import { v4 as UUIDv4 } from "uuid";
import ContextMenu from "./base/ContextMenu";

export default class GlobalLegendMenu extends ContextMenu {

    protected template: string = `<div class="context-menu2 global-legend">
                                <div class="menu-title">Legend</div>
                                <ul class="menu-items"></ul>
                           </div>`;

    constructor() {
        super();
    }

    protected addMenuItems() {
        this.addHorizontalChartMenuItems();
    }

    private addAliasesTooltipsToggle() {
        let menuItemDiv = this.createMenuItem();
        const aliasesTooltipsToggle = new InlineToggleButton(this.chartSettings.useAliasesInTooltips, {
            name: "aliases-tooltips",
            label: "Use aliases as tooltips"
        });
        const cwId = `cw-${UUIDv4()}`;
        menuItemDiv = this.createMenuItem(cwId);
        aliasesTooltipsToggle.appendTo(menuItemDiv);
        const aliasesTooltipsSub = new ToggleButtonSubscriber();
        aliasesTooltipsSub.update = (message: any): void => {
            this.chartSettings.persistGlobalLegendSettings({ useAliasesInTooltips: message.value });
        };
        aliasesTooltipsToggle.subscribe(aliasesTooltipsSub);
    }

    private addHorizontalChartMenuItems() {
        if (this.chartSettings.chartStyle === ChartStyle.Custom) {
            this.addColorCodingToggle();
        }
        if (Visual.viewModel.isMultiples) {
            this.addTopNdropdown();
            this.addTopNItems();
            this.addTopNPercentage();
        }
        if (this.chartSettings.scenarioOptions.secondReferenceScenario !== null) {
            this.addSwitchComparisonMeasuressToggle();
        }
        // TODO When tooltip will be available.
        // this.addAliasesTooltipsToggle();
        this.addMarginMenuItems();
        this.addResetToDefault();
        if (Visual.viewModel.isMultiples) {
            this.toggleTopNInputsElement(this.chartSettings.showTopNChartsOptions);
        }
    }

    private addMarginMenuItems() {
        const itemsInput = new InlineNumberInput("Margin", this.chartSettings.legendItemsMargin, {
            name: "margin-settings",
            min: 0,
            max: 50
        });
        const tbnId = `tbn-${UUIDv4()}`;
        const menuItemDiv = this.createMenuItem(tbnId);
        itemsInput.appendTo(menuItemDiv);

        const marginSub = new NumberInputSubscriber();
        marginSub.update = (message: any): void => {
            this.chartSettings.persistGlobalLegendSettings({ legendItemsMargin: message });
        };
        itemsInput.subscribe(marginSub);
    }

    private addSwitchComparisonMeasuressToggle() {
        let menuItemDiv = this.createMenuItem();
        const switchComparisonMeasuressToggle = new InlineToggleButton(this.chartSettings.switchReferenceScenarios, {
            name: "switch-comparison-measuress",
            label: "Switch comparison measuress"
        });
        const cwId = `cw-${UUIDv4()}`;
        menuItemDiv = this.createMenuItem(cwId);
        switchComparisonMeasuressToggle.appendTo(menuItemDiv);

        const switchComparisonMeasuressSub = new ToggleButtonSubscriber();
        switchComparisonMeasuressSub.update = (message: any): void => {
            this.chartSettings.persistGlobalLegendSettings({ switchReferenceScenarios: message.value });
        };
        switchComparisonMeasuressToggle.subscribe(switchComparisonMeasuressSub);
    }

    private addColorCodingToggle() {
        let menuItemDiv = this.createMenuItem();
        const colorCodingToggle = new InlineToggleButton(this.chartSettings.useColoredLegendNames, {
            name: "color-coding",
            label: "Color coding"
        });
        const cwId = `cw-${UUIDv4()}`;
        menuItemDiv = this.createMenuItem(cwId);
        colorCodingToggle.appendTo(menuItemDiv);

        const colorCodingSub = new ToggleButtonSubscriber();
        colorCodingSub.update = (message: any): void => {
            this.chartSettings.persistGlobalLegendSettings({ useColoredLegendNames: message.value });
        };
        colorCodingToggle.subscribe(colorCodingSub);
    }

    protected addResetToDefault() {
        const resetToDefault = new LinkButton("Reset to default");
        const menuItemDiv = this.createMenuItem();
        resetToDefault.appendTo(menuItemDiv);
        resetToDefault.containerNode().addEventListener(CLICK, () => {
            this.resetToDefault();
        });
    }

    protected resetToDefault() {
        document.querySelector(`.global-legend`).classList.add(RESET);
        this.chartSettings.persistResetButtonLegendSettings();
    }

    private addTopNdropdown() {
        const topNOthersDropdown = new InlineDropdown(
            "Top N + Others",
            this.chartSettings.showTopNChartsOptions,
            { name: "top-n-others" },
            [{
                value: 0,
                label: "Off"
            }, {
                value: 1,
                label: "Items",
            }, {
                value: 2,
                label: "Percentage"
            }]);
        const tbnId = `tbn-${UUIDv4()}`;
        const menuItemDiv = this.createMenuItem(tbnId);
        topNOthersDropdown.appendTo(menuItemDiv);

        const topNOthersDropdownSub = new DropdownSubscriber();
        topNOthersDropdownSub.update = (value: any): void => {
            this.toggleTopNInputsElement(value);
            this.chartSettings.persistGlobalLegendSettings({ showTopNChartsOptions: value });
        };
        topNOthersDropdown.subscribe(topNOthersDropdownSub);
    }

    private toggleTopNInputsElement(value: number) {
        const topNItems = <HTMLElement>document.querySelector(".top-n-items");
        const topNPercantage = <HTMLElement>document.querySelector(".top-n-percentage");
        topNItems.style.display = value === 1 ? "block" : "none";
        topNPercantage.style.display = value === 2 ? "block" : "none";
    }

    private addTopNItems() {
        const itemsInput = new InlineNumberInput("Items", this.chartSettings.topNChartsToKeep, {
            name: "top-n-items",
            min: 1,
            max: 99
        });
        const tbnId = `tbn-${UUIDv4()}`;
        const menuItemDiv = this.createMenuItem(tbnId);
        itemsInput.appendTo(menuItemDiv);
        menuItemDiv.classList.add("top-n-items");

        const topItemssub = new NumberInputSubscriber();
        topItemssub.update = (value: any): void => {
            this.chartSettings.persistGlobalLegendSettings({ topNChartsToKeep: value });
        };

        itemsInput.subscribe(topItemssub);
    }

    private addTopNPercentage() {

        const topNItemsInput = new InlineNumberInput("Percentage", this.chartSettings.topNChartsPercentage, {
            name: "top-n-percentage",
            min: 1,
            max: 99
        });
        const tbnId = `tbn-${UUIDv4()}`;
        const menuItemDiv = this.createMenuItem(tbnId);
        topNItemsInput.appendTo(menuItemDiv);
        menuItemDiv.classList.add("top-n-percentage");

        const topNItemssub = new NumberInputSubscriber();
        topNItemssub.update = (value: any): void => {
            this.chartSettings.persistGlobalLegendSettings({ topNChartsPercentage: value });
        };
        topNItemsInput.subscribe(topNItemssub);
    }

}
