import { Visual } from "../../visual";
import { DataLabelMenuItem, DataLabelsUnits, NegativeValuesFormat, LabelDensity } from "../../enums";
import { DesignLibraryComponent } from "../../library/types";
import DataLabelMenuItemSubscriberFactory from "./DataLabelMenuItemSubscriberFactory";
import { fontFamilies } from "../../library/constants";
import {
    FontForm, DecimalPointsIconsOrganism, InlineDropdown, InlineToggleButton,
    InlineTextInput, InlineNumberInput
} from "@zebrabi/design-library";
import {
    DATA_LABEL_UNITS, DATA_LABEL_NEGATIVE_VALUES_FORMAT, DATA_LABEL_DENSITY
} from "../../library/dropdownOptionsConstants";

export default class DataLabelMenuItemFactory {
    private chartSettings = Visual.settings;
    private subscriberFactory = new DataLabelMenuItemSubscriberFactory();

    public getItem(menuItem: DataLabelMenuItem): DesignLibraryComponent {
        switch (menuItem) {
            case DataLabelMenuItem.fontSettings: {
                const fontSettings = new FontForm({
                    fontSize: this.chartSettings.labelFontSize,
                    fontFamily: this.chartSettings.labelFontFamily,
                    fontColor: this.chartSettings.labelFontColor
                }, {
                    fontSizeUnit: "px",
                    fontSizeMin: 8,
                    fontFamilyOptions: fontFamilies
                });

                fontSettings.subscribe(this.subscriberFactory.getSubscriber(menuItem));
                return fontSettings;
            }
            case DataLabelMenuItem.decimalPlaces: {
                const decimalPointsSetterOrganism = new DecimalPointsIconsOrganism({ value: this.chartSettings.decimalPlaces, valuePercentage: this.chartSettings.decimalPlacesPercentage }, {
                    name: "decimal-points-setter-organism",
                    min: 0,
                    max: 4,
                });
                decimalPointsSetterOrganism.subscribe(this.subscriberFactory.getSubscriber(menuItem));
                return decimalPointsSetterOrganism;
            }
            case DataLabelMenuItem.units: {
                const unitsDataLabelDropdown = new InlineDropdown<DataLabelsUnits>(
                    "Units",
                    <DataLabelsUnits>this.chartSettings.displayUnits,
                    { name: "units-dl" },
                    DATA_LABEL_UNITS);
                unitsDataLabelDropdown.subscribe(this.subscriberFactory.getSubscriber(menuItem));
                return unitsDataLabelDropdown;
            }
            case DataLabelMenuItem.showPercentageInLabel: {
                const showPercentageSymbolDLToggle = new InlineToggleButton(this.chartSettings.showPercentageInLabel, {
                    name: "dh-for-subtotals",
                    label: "Show % symbol in numbers"
                });
                showPercentageSymbolDLToggle.subscribe(this.subscriberFactory.getSubscriber(menuItem));
                return showPercentageSymbolDLToggle;
            }
            case DataLabelMenuItem.negativeValuesFormat: {
                const negativeValuesFormatDLDropdown = new InlineDropdown<NegativeValuesFormat>(
                    "Negative values format",
                    this.chartSettings.negativeValuesFormat,
                    { name: "negative-values-format-dl" },
                    DATA_LABEL_NEGATIVE_VALUES_FORMAT);
                negativeValuesFormatDLDropdown.subscribe(this.subscriberFactory.getSubscriber(menuItem));
                return negativeValuesFormatDLDropdown;
            }
            case DataLabelMenuItem.labelDensity: {
                const labelDensityDLDropdown = new InlineDropdown<LabelDensity>(
                    "Density",
                    this.chartSettings.labelDensity,
                    { name: "label-density-dl" },
                    DATA_LABEL_DENSITY);
                labelDensityDLDropdown.subscribe(this.subscriberFactory.getSubscriber(menuItem));
                return labelDensityDLDropdown;
            }
            case DataLabelMenuItem.labelPercentagePointUnit: {
                const percentagePointInput = new InlineTextInput("Percentage point", this.chartSettings.labelPercentagePointUnit, {
                    name: "label-percentage-point-unit",
                });
                percentagePointInput.subscribe(this.subscriberFactory.getSubscriber(menuItem));
                return percentagePointInput;
            }
            case DataLabelMenuItem.backgroundTransparency: {
                const backgroundTransparencyDLInput = new InlineNumberInput("Background transparency", this.chartSettings.labelBackgroundTransparency, {
                    name: "background-transparency-dl",
                    min: 0,
                    max: 100
                });
                backgroundTransparencyDLInput.subscribe(this.subscriberFactory.getSubscriber(menuItem));
                return backgroundTransparencyDLInput;
            }
            case DataLabelMenuItem.showReferenceLabels: {
                const showReferenceLabelsToggle = new InlineToggleButton(this.chartSettings.showReferenceLabels, {
                    name: "show-reference-labels-dl",
                    label: "Show comparison labels"
                });
                showReferenceLabelsToggle.subscribe(this.subscriberFactory.getSubscriber(menuItem));
                return showReferenceLabelsToggle;
            }
        }
    }
}
