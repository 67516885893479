
import { ViewModel, DataPoint, Viewport } from "./../interfaces";
import { getVarianceDataLabel, getRelativeVarianceLabel, getFontFamily, getFontWeight } from "./../charting/chart";
import { AxisLabelDensity, ChartType, DifferenceHighlightEllipse, MultiplesAxisLabelsOptions } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { CategoryWidthOptions, CategoryLabelsOptions, CategoryDisplayOptions, DifferenceLabel, NORMAL } from "./../library/constants";
import {
    MULTIPLE_MARGIN, RESPONSIVE, INTEGRATED, ABSOLUTE, ABSOLUTE_RELATIVE, ACTUAL, ROWS_LAYOUT, RELATIVE
} from "./../consts";

import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as viewModels from "./../viewModel/viewModelHelperFunctions";
import InfoBoxHandler from "../ui/InfoBoxHandler";

export default class LayoutAttributes {
    public width: number;
    public height: number;
    public numOfCharts: number;
    public rowCount: number;
    public maxChartsPerRow: number;
    public titleHeight: number;
    public minChartHeight: number;
    public minChartWidth: number;
    public plotChartWidth: number;
    public bottomMargin: number;
    public topMargin: number;
    public rowMargin: number;
    public columnMargin: number;
    public leftMargin: number;
    public leftMarginCategories: number;
    public rightMargin: number;
    public freezedCategoriesMargin: number;
    public topCommentMarkerMargin: number;
    public bottomCommentMarkerMargin: number;

    constructor(protected settings: ChartSettings, protected viewModel: ViewModel, protected viewPort: Viewport, visualTitleHeight: number) {
        if (settings.shouldPlotVerticalCharts()) {
            this.calculateVerticalChartLayoutParameters(viewPort, settings, viewModel, visualTitleHeight);
        }
        else {
            this.calculateHorizontalLayoutParameters(viewPort, settings, viewModel, visualTitleHeight);
        }
    }

    // eslint-disable-next-line max-lines-per-function
    private calculateHorizontalLayoutParameters(viewport: Viewport, settings: ChartSettings, viewModel: ViewModel, titleHeight: number) {
        let width = viewport.width;
        let height = viewport.height - 5;
        if (this.settings.showCommentBox) {
            width = InfoBoxHandler.GET_AVAILABLE_CHART_CONTAINER_WIDTH(settings, width);
            height = InfoBoxHandler.GET_AVAILABLE_CHART_CONTAINER_HEIGHT(settings, height);
        }
        const dataLabelsHeight = drawing.getEstimatedTextHeight("0123456789bnKM%", settings.labelFontSize, getFontFamily(settings.labelFontFamily), getFontWeight(settings.labelFontFamily), NORMAL);
        const categoriesFontSize = settings.showCategoriesFontSettings ? settings.categoriesFontSize : settings.labelFontSize;
        const categoriesFontFamily = settings.showCategoriesFontSettings ? getFontFamily(settings.categoriesFontFamily) : getFontFamily(settings.labelFontFamily);
        const categoriesFontWeight = settings.showCategoriesFontSettings ? getFontWeight(settings.categoriesFontFamily) : getFontWeight(settings.labelFontFamily);
        const categoriesLabelsHeight = drawing.getEstimatedTextHeight(viewModel.chartData[0].dataPoints.map(d => d.category).join(""),
            categoriesFontSize, categoriesFontFamily, categoriesFontWeight, NORMAL);
        let categoriesMargin = categoriesLabelsHeight;
        let topMargin = 11 + (settings.showDataLabels ? dataLabelsHeight : 0);
        let bottomMargin = settings.showCategories ? categoriesLabelsHeight : 0;
        const columnMargin = MULTIPLE_MARGIN;
        const rowMargin = MULTIPLE_MARGIN;
        const minCategoryWidth = settings.categoryWidth === CategoryWidthOptions.Fixed ? settings.categoryMinWidth : settings.getMinCategoryWidth(settings.chartType);

        let minChartHeight = topMargin + bottomMargin + (settings.chartType === ChartType.Waterfall ? 160 : 120);
        if (viewModel.isMultiples && settings.shouldPlotExtendedChartsMultiples()) {
            minChartHeight = 1.5 * minChartHeight;
        }
        if (settings.minChartHeight !== null && viewModel.isMultiples) {
            minChartHeight = Math.max(100, settings.minChartHeight);  // only needed for resize multiples?
        }
        settings.minChartHeight = minChartHeight;

        const numOfCharts = viewModel.chartData.length;
        const numOfCategories = viewModel.chartData[0].dataPoints.length;
        settings.calculateDifferenceHighlights(viewModel);
        let minChartWidth = numOfCategories * minCategoryWidth + settings.differenceHighlightWidth;


        if (settings.showLegend && numOfCharts === 1) {
            minChartWidth += settings.getLegendWidth();
        }
        else if (settings.showLegend && numOfCharts > 1 && settings.chartType !== ChartType.Waterfall) {
            width -= settings.getLegendWidth();
        }

        if (settings.showGrandTotal && settings.chartType === ChartType.Variance) {
            minChartWidth += minCategoryWidth * 1.5;
        }

        let maxChartsPerRow = Math.floor((width) / (minChartWidth + columnMargin));
        if (width < minChartWidth || maxChartsPerRow === 0) {
            minChartWidth = width;
            maxChartsPerRow = 1;
        }

        if (settings.showCategories) {
            if (viewModel.chartData[0].categoryLevelsCount > 1) {
                bottomMargin += (viewModel.chartData[0].categoryLevelsCount - 1) * categoriesLabelsHeight;
                categoriesMargin = viewModel.chartData[0].categoryLevelsCount * categoriesLabelsHeight;
            }
            else if (settings.categoryLabelsOptions !== CategoryLabelsOptions.Rotate && !viewModel.isDateCategories
                && viewModel.chartData[0].dataPoints.some(d => d.category.indexOf(" ") !== -1) && settings.axisLabelDensity === AxisLabelDensity.All) {
                const fitstChartDataPoints = viewModel.chartData[0].dataPoints;
                const estimatedCategoryWidth = this.getEstimatedCategoryWidth(width, settings, numOfCharts, maxChartsPerRow, numOfCategories, minCategoryWidth);
                const maxCatTextWidth = Math.max(...fitstChartDataPoints.map(
                    d => drawing.measureTextWidth(d.category, categoriesFontSize, categoriesFontFamily, categoriesFontWeight, NORMAL)));
                if (maxCatTextWidth > estimatedCategoryWidth) {
                    bottomMargin += categoriesLabelsHeight;
                    categoriesMargin += categoriesLabelsHeight;
                    settings.multilineCategories = true;
                }
            }
            else if (settings.categoryLabelsOptions === CategoryLabelsOptions.Rotate) {
                let categoryLabelsHeight = categoriesLabelsHeight;
                const fitstChartDataPoints = viewModel.chartData[0].dataPoints;
                const estimatedCategoryWidth = this.getEstimatedCategoryWidth(width, settings, numOfCharts, maxChartsPerRow, numOfCategories, minCategoryWidth);
                let usedCategoryWidth = estimatedCategoryWidth;
                if (viewModel.isSingleSeriesViewModel && !fitstChartDataPoints[0].isVariance && settings.chartType === ChartType.Waterfall &&
                    this.firstCategoryHasEnoughWidth(fitstChartDataPoints, categoriesFontSize, categoriesFontFamily, estimatedCategoryWidth)) {
                    usedCategoryWidth *= 2;
                }
                const maxCategoryTextWidth = Math.max(...fitstChartDataPoints.map(
                    d => drawing.measureTextWidth(d.category, categoriesFontSize, categoriesFontFamily, categoriesFontWeight, NORMAL))) + categoriesFontSize;
                if (maxCategoryTextWidth > estimatedCategoryWidth) {
                    const firstCategoryTextWidth = drawing.measureTextWidth(fitstChartDataPoints[0].category, categoriesFontSize, categoriesFontFamily, categoriesFontWeight, NORMAL) + categoriesFontSize;
                    if (firstCategoryTextWidth / maxCategoryTextWidth < 0.73) {
                        settings.categoryRotateAngleLimit = 55;
                        const rotatedLabelsHorizontalWidth = this.calculateRotatedWidth(settings, usedCategoryWidth);
                        settings.categoryRotateAngle = Math.acos(Math.sqrt(rotatedLabelsHorizontalWidth / maxCategoryTextWidth)) * 180 / Math.PI;
                        if (settings.categoryRotateAngle < settings.categoryRotateAngleLimit) {
                            categoryLabelsHeight = Math.max(categoryLabelsHeight, ((categoriesFontSize / 2 + 3) + maxCategoryTextWidth * Math.sin(settings.categoryRotateAngle * Math.PI / 180)));
                        } else {
                            categoryLabelsHeight = (categoriesFontSize / 2 + 3) + maxCategoryTextWidth;
                        }
                    } else {
                        settings.categoryRotateAngleLimit = 70;
                        const rotatedLabelsHorizontalWidth = this.calculateRotatedWidth(settings, usedCategoryWidth);
                        settings.categoryRotateAngle = Math.acos(rotatedLabelsHorizontalWidth / maxCategoryTextWidth) * 180 / Math.PI;
                        categoryLabelsHeight = Math.max(categoryLabelsHeight, ((categoriesFontSize / 2 + 3) + Math.sqrt(maxCategoryTextWidth * maxCategoryTextWidth - rotatedLabelsHorizontalWidth * rotatedLabelsHorizontalWidth)));
                    }
                }
                settings.rotatedCartegoriesHeight = categoryLabelsHeight;
                bottomMargin = categoryLabelsHeight;
                categoriesMargin = categoryLabelsHeight;
            }
        }

        if (settings.chartType === ChartType.Variance && settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute &&
            (settings.chartLayout === RESPONSIVE || settings.chartLayout === INTEGRATED)) {
            if (viewModel.chartData.some(c => c.dataPoints.some(p => viewModels.getDataPointNonNullValue(p) < 0))) {
                bottomMargin += dataLabelsHeight;
            }

            if (viewModel.chartData.length > 1 && viewModel.chartData.some(c => c.dataPoints.some(p => viewModels.getDataPointNonNullValue(p) > 0))) {
                // increase top margin so that it does not overlap with group title
                topMargin += dataLabelsHeight;
            }
        }
        if (settings.chartType === ChartType.Variance && (settings.chartLayout === ABSOLUTE || settings.chartLayout === ABSOLUTE_RELATIVE)) {
            if (settings.scenarioOptions.secondReferenceIndex !== null && viewModel.chartData.some(c => c.minSecondReferenceVariance < 0)) {
                bottomMargin += dataLabelsHeight;
            }
        }
        if (settings.chartType === ChartType.Line || settings.chartType === ChartType.Area || settings.chartType === ChartType.Pin) {
            if (viewModel.chartData.some(c => c.min < 0)) {
                bottomMargin += 5;
            }
        }

        if ((numOfCharts > 1 || viewModel.chartData[0].group) && !settings.shouldPlotStackedChart(viewModel.isMultiples)) {
            const groupTitleHeight = drawing.getEstimatedTextHeight(viewModel.chartData[0].group, settings.groupTitleFontSize, settings.groupTitleFontFamily, NORMAL, NORMAL);
            topMargin += groupTitleHeight;
        }

        let topCommentMarkerMargin = 0;
        let bottomCommentMarkerMargin = 0;
        if (settings.showCommentMarkers()) {
            const markerMargin = Math.min(40, 0.6 * this.getEstimatedCategoryWidth(width, settings, numOfCharts, maxChartsPerRow, numOfCategories, minCategoryWidth));
            if (settings.chartType !== ChartType.Waterfall) {
                if (viewModel.chartData.some(cd => {
                    const commentsDataPoint = cd.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
                    return commentsDataPoint.length > 0;
                })) {
                    topCommentMarkerMargin = markerMargin;
                    if (settings.chartType === ChartType.Variance && viewModel.chartData.some(c => c.min < 0)) {
                        bottomCommentMarkerMargin = markerMargin;
                        if (settings.chartLayout === ABSOLUTE || settings.chartLayout === RELATIVE) {
                            bottomCommentMarkerMargin += 10;
                        }
                    }
                }
            }
            else {
                const hasWfNegativeValCommentsDps = viewModel.chartData[0].dataPoints.some(p => p.isVariance && p.isNegative && p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
                if (hasWfNegativeValCommentsDps) {
                    topCommentMarkerMargin = markerMargin;
                }
            }
        }

        const multiplesTitleMargin = numOfCharts > 1 && (settings.showTitle || settings.showMultiplesGrid && settings.showOuterBorders) ? 6 : 0;
        titleHeight += multiplesTitleMargin;
        if (numOfCharts > 1) {  // could be fixed in singleChartlayout instead
            height -= titleHeight;
            if (settings.shouldFreezeCategories()) {
                if (settings.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.LastRow) {
                    bottomMargin -= categoriesMargin - 1;
                }
                categoriesMargin += 5;
                height -= categoriesMargin;
            }
        }

        let rowCount = 1;
        let remainingChartsToLayout = numOfCharts;
        let plotChartWidth = (width - columnMargin * maxChartsPerRow) / maxChartsPerRow;
        plotChartWidth = Math.max(minChartWidth, plotChartWidth);
        while (Math.floor(remainingChartsToLayout * (plotChartWidth + columnMargin)) > width) {
            rowCount++;
            remainingChartsToLayout -= maxChartsPerRow;
        }
        if (rowCount === 1) {
            plotChartWidth = (width - columnMargin * numOfCharts) / numOfCharts;
            if (maxChartsPerRow < numOfCharts) {
                maxChartsPerRow = numOfCharts;
            }
            if (numOfCharts > 1 && settings.shouldFreezeCategories() && settings.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.FirstChartLastRow && settings.multiplesLayoutType === ROWS_LAYOUT) {
                bottomMargin -= (categoriesMargin - 5) - 1;
            }
        }

        let availableWidth = width;
        if (settings.showMultiplesGrid && settings.showOuterBorders) {
            availableWidth -= (MULTIPLE_MARGIN + 5);
        }
        if (rowCount * minChartHeight + (rowCount - 1) * rowMargin + titleHeight > height) {    // is scrollbar shown estimate
            availableWidth -= 15;
            plotChartWidth = (availableWidth - columnMargin * (maxChartsPerRow - 1)) / maxChartsPerRow;
        }

        this.width = width;
        this.height = height;
        this.numOfCharts = numOfCharts;
        this.rowCount = rowCount;
        this.maxChartsPerRow = maxChartsPerRow;
        this.titleHeight = titleHeight;
        this.minChartHeight = minChartHeight;
        this.minChartWidth = minChartWidth;
        this.plotChartWidth = plotChartWidth;
        this.bottomMargin = bottomMargin;
        this.topMargin = topMargin;
        this.rowMargin = rowMargin;
        this.columnMargin = columnMargin;
        this.freezedCategoriesMargin = settings.shouldFreezeCategories() && numOfCharts > 1 ? categoriesMargin : 0;
        this.topCommentMarkerMargin = topCommentMarkerMargin;
        this.bottomCommentMarkerMargin = bottomCommentMarkerMargin;
    }

    private getEstimatedCategoryWidth(width: number, settings: ChartSettings, numOfCharts: number, maxChartsPerRow: number, dataPointsLength: number, minCategoryWidth: number): number {
        let singleChartWidth = width;

        if (numOfCharts > 1) {
            singleChartWidth = width / Math.min(maxChartsPerRow, numOfCharts);
            if (this.viewModel.is2dMultiples) {
                const numOfCols = this.viewModel.chartData.map(c => c.columnHeader).filter((value, index, self) => self.indexOf(value) === index).length;
                const maxRowHeaderLength = Math.max(...this.viewModel.chartData.map(
                    c => drawing.measureTextWidth(c.rowHeader, settings.groupTitleFontSize, settings.groupTitleFontFamily, NORMAL, NORMAL)));
                singleChartWidth = Math.max(minCategoryWidth * this.viewModel.chartData[0].dataPoints.length, (width - maxRowHeaderLength - 10) / numOfCols);
            }
        }
        const estimatedCategoryWidth = (singleChartWidth
            - (settings.differenceHighlightWidth)
            - (settings.showLegend && !(settings.chartType === ChartType.Waterfall) ? settings.getLegendWidth() : 0)
            - (settings.showGrandTotal && settings.chartType === ChartType.Variance ? minCategoryWidth * 1.5 : 0))
            / dataPointsLength;
        return Math.max(0, estimatedCategoryWidth);
    }

    private calculateRotatedWidth(settings: ChartSettings, estimatedCategoryWidth: number) {
        if (settings.chartType === ChartType.Waterfall) {
            if (this.viewModel.isSingleSeriesViewModel) {
                return estimatedCategoryWidth / 2;
            }
            else {
                return estimatedCategoryWidth;
            }
        }
        else {
            if (settings.showLegend) {
                return estimatedCategoryWidth;
            }
            else {
                return estimatedCategoryWidth / 2;
            }
        }
    }

    private firstCategoryHasEnoughWidth(dataPoints: DataPoint[], categoriesFontSize, categoriesFontFamily: string, estimatedCategoryWidth: number) {
        const maxNonVarianceCategoryLength = Math.max(...dataPoints.filter(d => !d.isVariance)
            .map(d => drawing.measureTextWidth(d.category, categoriesFontSize, categoriesFontFamily, NORMAL, NORMAL)));
        return (maxNonVarianceCategoryLength < estimatedCategoryWidth);
    }

    // eslint-disable-next-line max-lines-per-function
    private calculateVerticalChartLayoutParameters(viewport: Viewport, settings: ChartSettings, viewModel: ViewModel, titleHeight: number) {
        let viewportWidth = viewport.width;
        let viewportHeight = viewport.height - 5;
        const columnMargin = MULTIPLE_MARGIN;
        const rowMargin = MULTIPLE_MARGIN;
        const numOfCharts = viewModel.chartData.length;
        const numOfCategories = viewModel.chartData[0].dataPoints.length;

        const categoriesFontSize = settings.labelFontSize;
        const categoriesFontFamily = getFontFamily(settings.labelFontFamily);
        const categoriesFontWeight = getFontWeight(settings.labelFontFamily);
        const categoriesLabelsHeight = drawing.getEstimatedTextHeight(viewModel.chartData[0].dataPoints.map(d =>
            d.category).join(""), categoriesFontSize, categoriesFontFamily, categoriesFontWeight, NORMAL);
        const maxCategoryLabelsWidth = settings.showCategories ? Math.max(...viewModel.chartData[0].dataPoints.map(d =>
            drawing.measureTextWidth(d.category, categoriesFontSize, categoriesFontFamily, categoriesFontWeight, NORMAL))) : 0;

        if (this.settings.showCommentBox) {
            viewportWidth = InfoBoxHandler.GET_AVAILABLE_CHART_CONTAINER_WIDTH(settings, viewportWidth);
            viewportHeight = InfoBoxHandler.GET_AVAILABLE_CHART_CONTAINER_HEIGHT(settings, viewportHeight);
        }

        let rightMargin = 10;
        if (settings.showDataLabels) {
            let maxDataLabelValue = Math.max(...viewModel.chartData.map(c => c.max));
            if (!viewModel.isSingleSeriesViewModel && settings.chartType === ChartType.Variance) {
                maxDataLabelValue = Math.max(...viewModel.chartData.map(c => Math.max(Math.abs(c.maxVariance), Math.abs(c.minVariance))));
            }
            const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);
            const hideUnits = settings.shouldHideDataLabelUnits();
            const maxLabel = getVarianceDataLabel(maxDataLabelValue, settings.decimalPlaces, settings.displayUnits,
                settings.locale, settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelsFormat, hideUnits);
            let maxLabelWidth = drawing.measureTextWidth(maxLabel, settings.labelFontSize, categoriesFontFamily, categoriesFontWeight, NORMAL);

            if (settings.chartType === ChartType.Waterfall) {
                const maxVarianceValue = Math.max(...viewModel.chartData.map(c => Math.max(...c.dataPoints.filter(p => p.isVariance).map(p => p.value))));
                const maxVarianceLabel = getVarianceDataLabel(maxVarianceValue, settings.decimalPlaces, settings.displayUnits,
                    settings.locale, settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelsFormat, hideUnits);
                const maxVarianceLabelWidth = drawing.measureTextWidth(maxVarianceLabel, settings.labelFontSize, categoriesFontFamily, categoriesFontWeight, NORMAL);
                maxLabelWidth = Math.max(maxLabelWidth, maxVarianceLabelWidth);
            }

            if (settings.varianceLabel === DifferenceLabel.Relative || settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute) {
                if (settings.chartType === ChartType.Variance) {
                    const relativeMaxDataLabelValue = Math.max(...viewModel.chartData.map(c => Math.max(Math.abs(c.maxRelativeVariance), Math.abs(c.minRelativeVariance))));
                    const relativeLabelText = getRelativeVarianceLabel(settings, relativeMaxDataLabelValue * 100, settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute);
                    const maxRelativeLabelWidth = drawing.measureTextWidth(relativeLabelText, settings.labelFontSize, categoriesFontFamily, categoriesFontWeight, NORMAL);
                    maxLabelWidth = Math.max(maxLabelWidth, maxRelativeLabelWidth);
                }
                else if (settings.chartType === ChartType.Waterfall) {
                    const relativeLabelText = getRelativeVarianceLabel(settings, 100, settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute);
                    const maxRelativeLabelWidth = drawing.measureTextWidth(relativeLabelText, settings.labelFontSize, categoriesFontFamily, categoriesFontWeight, NORMAL);
                    maxLabelWidth = Math.max(maxLabelWidth, maxRelativeLabelWidth);
                }
            }

            rightMargin += maxLabelWidth;
        }

        if (settings.showCommentMarkers() && settings.chartType !== ChartType.Waterfall) {
            const markerMargin = Math.min(40, 0.6 * this.getVerticalChartsEstimatedCategoryHeight(viewportHeight, numOfCharts, categoriesLabelsHeight, numOfCategories));
            rightMargin += markerMargin; //Math.min(Math.max(categoriesLabelsHeight, 15), 40);
        }

        let leftMargin = maxCategoryLabelsWidth + 15;
        if (settings.verticalCategoriesDisplay === CategoryDisplayOptions.FixedWidth) {
            leftMargin = settings.categoryMinWidth;
        }
        else if (settings.verticalCategoriesDisplay === CategoryDisplayOptions.Auto) {
            leftMargin = Math.min(100, maxCategoryLabelsWidth) + 10;
        }
        this.leftMarginCategories = leftMargin;
        if (settings.showDataLabels && Math.min(...viewModel.chartData.map(c => c.min)) < 0) {
            leftMargin += rightMargin - 10;
        }
        settings.calculateDifferenceHighlights(viewModel);
        let bottomMargin = 3;
        if (settings.differenceHighlight) {
            let approxChartHeight = 0;
            if (viewModel.isMultiples) {
                approxChartHeight = numOfCategories * Math.max(categoriesLabelsHeight, 25);
            }
            else {
                approxChartHeight = viewportHeight
                    - (settings.showTitle ? drawing.getEstimatedTextHeight(viewModel.title, settings.titleFontSize, settings.titleFontFamily, getFontWeight(settings.titleFontFamily), NORMAL) : 0)
                    - (settings.showLegend ? drawing.getEstimatedTextHeight(viewModel.valuesFieldName, settings.labelFontSize, settings.labelFontFamily, getFontWeight(settings.labelFontFamily), NORMAL) : 0)
                    - (settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute ? 2 * settings.differenceHighlightFontSize : settings.differenceHighlightFontSize);
            }
            const approxDiffHighlightBottomMargin = (approxChartHeight / numOfCategories + 1);
            bottomMargin += approxDiffHighlightBottomMargin / 2;
            bottomMargin += (settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute ? 2 * settings.differenceHighlightFontSize : settings.differenceHighlightFontSize);

            if (settings.differenceHighlightEllipse !== DifferenceHighlightEllipse.Off) {
                bottomMargin += 2;
                if (settings.differenceHighlightEllipse === DifferenceHighlightEllipse.Circle) {
                    bottomMargin += settings.differenceHighlightWidth / 5 * (1 + settings.differenceHighlightFontSize / 100);
                }
                else if (settings.differenceHighlightEllipse === DifferenceHighlightEllipse.Ellipse) {
                    bottomMargin += 0.75 * (settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute ? settings.differenceHighlightFontSize : settings.differenceHighlightFontSize / 2);
                }
                bottomMargin += settings.differenceHighlightEllipseBorderPadding;
                bottomMargin += settings.differenceHighlightEllipseBorderWidth / 2;
            }
            bottomMargin += settings.differenceHighlightMargin;
        }
        if (!settings.differenceHighlight && settings.showLegend && settings.chartType !== ChartType.Waterfall && settings.scenarioOptions.referenceScenario && settings.chartLayout === ACTUAL) {
            bottomMargin = settings.labelFontSize / 2;
        }

        let minChartWidth = leftMargin + rightMargin + (settings.chartType === ChartType.Waterfall ? 160 : 120);
        if (viewModel.isMultiples && settings.shouldPlotExtendedChartsMultiples()) {
            minChartWidth = 1.5 * minChartWidth;
        }

        let minChartHeight = numOfCategories * Math.max(categoriesLabelsHeight, 25);

        let maxChartsPerRow = Math.floor(viewportWidth / minChartWidth);
        if (viewportWidth < minChartWidth || maxChartsPerRow === 0) {
            minChartWidth = viewportWidth;
            maxChartsPerRow = 1;
        }

        let topMargin = 0;
        if (numOfCharts > 1 || viewModel.chartData[0].group) {
            const groupTitleHeight = drawing.getEstimatedTextHeight(viewModel.chartData[0].group, settings.groupTitleFontSize, settings.groupTitleFontFamily, NORMAL, NORMAL);
            topMargin += groupTitleHeight + 3;
        }

        minChartHeight += topMargin + bottomMargin;

        const multiplesTitleMargin = numOfCharts > 1 ? 6 : 0;
        titleHeight += multiplesTitleMargin;
        if (numOfCharts > 1) {
            viewportHeight -= titleHeight;
        }

        this.width = viewportWidth;
        this.height = viewportHeight;
        this.numOfCharts = numOfCharts;
        this.rowCount = 1;
        this.titleHeight = titleHeight;
        this.maxChartsPerRow = maxChartsPerRow;
        this.minChartHeight = minChartHeight;
        this.minChartWidth = minChartWidth;
        this.plotChartWidth = null;
        this.bottomMargin = bottomMargin;
        this.topMargin = topMargin;
        this.rowMargin = rowMargin;
        this.columnMargin = columnMargin;
        this.leftMargin = leftMargin;
        this.rightMargin = rightMargin;
    }

    private getVerticalChartsEstimatedCategoryHeight(height: number, numOfCharts: number, categoriesLabelsHeight: number, dataPointsLength: number): number {
        const estimatedCategoryWidth = numOfCharts > 1 ? Math.max(categoriesLabelsHeight, 25) : height - 30 / dataPointsLength;
        return Math.max(0, estimatedCategoryWidth);
    }
}