import React from "react";
import useStore from "../../useStore";
import { changeRootDOMElementDisplay } from "../index";
import dismissIcon from "../images/dismiss.svg";

export default function CloseExcelDataLinking() {
  const [, setDrives] = useStore("drives");
  const [, setActiveDriveId] = useStore("activeDriveId");
  const [, setActiveSiteId] = useStore("activeSiteId");
  const [, setActiveWorksheetId] = useStore("activeWorksheetId");
  const [, setActiveFileId] = useStore("activeFileId");
  const [, setFoldersAndFiles] = useStore("foldersAndFiles");
  const [, setFoldersNavigation] = useStore("foldersNavigation");
  const [, setCurrentView] = useStore("currentView");
  const [, setShowFullScreenLoader] = useStore("showFullScreenLoader");

  const close = (event: PointerEvent) => {
    event.stopPropagation();
    // make sure these calls are the same as in RangeSelection
    setShowFullScreenLoader(false);
    setFoldersAndFiles([]);
    setFoldersNavigation([]);
    setDrives([]);
    setActiveSiteId("");
    setActiveDriveId("");
    setActiveWorksheetId("");
    setActiveFileId("");
    setCurrentView("file-selection");

    changeRootDOMElementDisplay("none");
  };

  return (
    <button className="close-excel-data-linking" onClick={close}>
      <img src={dismissIcon} alt="Close" />
    </button>
  );
}
