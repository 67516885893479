import { DifferenceHighlightOption, LabelDensity, NegativeValuesFormat, DataLabelsUnits } from "../enums";

export const DIFFERENCE_HIGHLIGHT_ARROW_STYLE_OPTIONS = [
    {
        "label": "Closed arrow",
        "value": 0
    },
    {
        "label": "Open arrow",
        "value": 5
    },
    {
        "label": "Round arrow",
        "value": 1
    },
    {
        "label": "Two dots",
        "value": 2
    },
    {
        "label": "One dot",
        "value": 3
    },
    {
        "label": "None",
        "value": 4
    }
];

export const DIFFERENCE_HIGHLIGHT_FROM_TO_OPTIONS = [
    {
        label: "Auto",
        value: DifferenceHighlightOption.Auto
    },
    {
        label: "First to last",
        value: DifferenceHighlightOption.FirstToLast
    },
    {
        label: "Penultimate to last",
        value: DifferenceHighlightOption.PenultimateToLast
    },
    {
        label: "Last to corresponding",
        value: DifferenceHighlightOption.LastToCorresponding
    },
    {
        label: "Last AC to last FC",
        value: DifferenceHighlightOption.LastACtoLastFC
    },
    {
        label: "First to last FC",
        value: DifferenceHighlightOption.FirstToLastFC
    },
    {
        label: "Penultimate to last FC",
        value: DifferenceHighlightOption.PenultimateToLastFC
    },
    {
        label: "Last FC to corresponding",
        value: DifferenceHighlightOption.LastFCtoCorresponding
    },
    {
        label: "First to last PL",
        value: DifferenceHighlightOption.FirstToLastPL
    },
    {
        label: "Penultimate to last PL",
        value: DifferenceHighlightOption.PenultimateToLastPL
    },
    {
        label: "Last PL to corresponding",
        value: DifferenceHighlightOption.LastPLtoCorresponding
    },
    {
        label: "Min to Max",
        value: DifferenceHighlightOption.MinToMax
    }
];


export const DIFFERENCE_LABEL_TYPE_OPTIONS = [
    {
        "label": "Relative (%)",
        "value": 0
    },
    {
        "label": "Absolute",
        "value": 1
    },
    {
        "label": "Relative and absolute",
        "value": 2
    }
];

export const DIFFERENCE_HIGHLIGHT_ELLIPSE_OPTIONS = [
    {
        "label": "Off",
        "value": 0
    },
    {
        "label": "Ellipse",
        "value": 1
    },
    {
        "label": "Circle",
        "value": 2
    }
];

export const DIFFERENCE_HIGHLIGHT_CONNECTING_LINE_STYLE_OPTIONS = [
    {
        "label": "Solid",
        "value": 0
    },
    {
        "label": "Dotted",
        "value": 1
    },
    {
        "label": "Dashed",
        "value": 2
    }
];

export const DATA_LABEL_DENSITY = [
    {
        "label": "Highest (Auto)",
        "value": LabelDensity.Auto
    },
    {
        "label": "High (Auto)",
        "value": LabelDensity.High
    },
    {
        "label": "Medium (Auto)",
        "value": LabelDensity.Medium
    },
    {
        "label": "Low (Auto)",
        "value": LabelDensity.Low
    },
    {
        "label": "Full",
        "value": LabelDensity.Full
    },
    {
        "label": "Last",
        "value": LabelDensity.Last
    },
    {
        "label": "First, last",
        "value": LabelDensity.FirstLast
    },
    {
        "label": "Min, max",
        "value": LabelDensity.MinMax
    },
    {
        "label": "First, last, min, max",
        "value": LabelDensity.FirstLastMinMax
    }
];

export const DATA_LABEL_NEGATIVE_VALUES_FORMAT = [
    {
        "label": "Minus sign | -123.4",
        "value": NegativeValuesFormat.Default
    },
    {
        "label": "Parenthesis | (123.4)",
        "value": NegativeValuesFormat.Parenthesis
    }
];

export const DATA_LABEL_UNITS = [
    {
        "label": "Auto",
        "value": DataLabelsUnits.Auto
    },
    {
        "label": "None",
        "value": DataLabelsUnits.None
    },
    {
        "label": "Thousands",
        "value": DataLabelsUnits.Thousands
    },
    {
        "label": "Millions",
        "value": DataLabelsUnits.Millions
    },
    {
        "label": "Billions",
        "value": DataLabelsUnits.Billions
    },
    {
        "label": "Percentage (%)",
        "value": DataLabelsUnits.Percentage
    }
];
