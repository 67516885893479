import {
    SplitButton, SplitButtonSubscriber, Position, ButtonStateTypes, SplitButtonData,
    SplitButtonActions
} from "@zebrabi/design-library";
import { StackedDataPoint } from "../charting/stackedChart";
import StackedChartMenu from "../components/StackedChartMenu";
import * as d3 from "../d3";
import { BaseType } from "../d3";
import { calculateSplitButtonOverlayPosition, repositionElementInsideViewPort, setElementFontStyles } from "../helpers";
import { FontSettings, LegendEntry } from "../interfaces";
import {
    ACTIVE, BOLD,
    CLICK,
    CONTEXT_MENU2_DIV,
    FIXED,
    FONT_WEIGHT, HIGHLIGHTABLE,
    MOUSEENTER,
    MOUSELEAVE, NORMAL, OPACITY,
    OVERLAY, PATH, RECT,
    STROKE, TEXT,
    TRANSPARENT
} from "../library/constants";
import { addOverlayProperties } from "./overlayHelpers";

export function showOverlayOnStackedChartLegendHover(legends: d3.Selection<BaseType, any, SVGElement, any>, isVertical = false, fontSettings) {
    const overlayContainer = <HTMLElement>document.querySelector(".zebrabi-charts-container");
    let timeOut;
    legends.on(MOUSEENTER, (e: MouseEvent, dp: LegendEntry) => {
        if (!overlayContainer.querySelector(`.${OVERLAY}.${ACTIVE}`)) {
            timeOut = setTimeout(() => {
                showLegendLabelOverlay(dp, overlayContainer, e.target as HTMLElement, isVertical, fontSettings);
            }, 200);
        }
    });
    legends.on(MOUSELEAVE, () => {
        clearTimeout(timeOut);
    });
}

function showLegendLabelOverlay(dp: LegendEntry, overlayContainer: HTMLElement, legendLabel: HTMLElement, isVertical = false, fontSettings: FontSettings): void {
    overlayContainer?.querySelector(".split-btn")?.remove();

    const arrowButtonPosition = isVertical ? Position.Bottom : Position.Right;
    const arrowButtonRotation = arrowButtonPosition;
    const splitButton = new SplitButton(
        dp.groupName,
        ButtonStateTypes.outlined,
        undefined,
        { arrowButtonPosition, arrowButtonRotation }
    );
    splitButton.appendTo(overlayContainer);

    const buttonNode = splitButton.defaultButton.buttonNode();
    setElementFontStyles(buttonNode, fontSettings);
    addSplitButtonTextEventListeners(buttonNode, dp);

    addOverlayProperties(splitButton.buttonNode());
    const overlay = splitButton.buttonNode();
    const labelBoundingRect = legendLabel.getBoundingClientRect();
    const overlayBoundingRect = overlay.getBoundingClientRect();
    const arrowButtonWidth = 23;
    calculateSplitButtonOverlayPosition(
        overlay,
        labelBoundingRect,
        {
            verticalLeft: labelBoundingRect.width / 2 - overlayBoundingRect.width / 2,
            verticalTop: (-overlayBoundingRect.height + arrowButtonWidth) / 2 + labelBoundingRect.height / 2,
            horizontalLeft: (-overlayBoundingRect.width + arrowButtonWidth) / 2 + labelBoundingRect.width / 2,
            horizontalTop: -overlayBoundingRect.height / 2 + labelBoundingRect.height / 2
        },
        isVertical
    );

    const splitButtonSubscriber = new SplitButtonSubscriber();
    splitButtonSubscriber.update = (message: SplitButtonData, action?: string) => {
        if (action === SplitButtonActions.IconClick) {
            toggleStackedChartMenu(dp, overlayContainer, isVertical);
        }
    };
    splitButton.subscribe(splitButtonSubscriber);
}

function addSplitButtonTextEventListeners(textDiv: HTMLElement, dp: LegendEntry) {
    textDiv.addEventListener(MOUSEENTER, () => {
        const areas = d3.selectAll<any, StackedDataPoint[]>(`${PATH}.stack-area`);
        const shapes = d3.selectAll<any, StackedDataPoint>(`${RECT}.${HIGHLIGHTABLE}`);
        const textShapes = d3.selectAll<any, StackedDataPoint>(`${TEXT}.${HIGHLIGHTABLE}`);

        areas.attr(OPACITY, dataPoints => dataPoints[0].group === dp.groupName ? 1 : 0.5);
        shapes.attr(OPACITY, dataPoint => dataPoint.group === dp.groupName ? 1 : 0.5);
        textShapes.attr(FONT_WEIGHT, dataPoint => dataPoint.group === dp.groupName || dataPoint.isHighlighted ? BOLD : NORMAL);
        textShapes.attr(OPACITY, dataPoint => dataPoint.group === dp.groupName ? 1 : 0.5);
    });

    textDiv.addEventListener(MOUSELEAVE, () => {
        if (!textDiv.classList.contains(ACTIVE)) {
            const areas = d3.selectAll<any, StackedDataPoint[]>(`${PATH}.stack-area`);
            const shapes = d3.selectAll<any, StackedDataPoint>(`${RECT}.${HIGHLIGHTABLE}`);
            const textShapes = d3.selectAll<any, StackedDataPoint>(`${TEXT}.${HIGHLIGHTABLE}`);
            shapes.attr(OPACITY, 1);
            areas.attr(OPACITY, 1);
            textShapes
                .attr(FONT_WEIGHT, p => p.isHighlighted ? BOLD : NORMAL)
                .attr(OPACITY, 1);
        }
    });
}

function toggleStackedChartMenu(dp: LegendEntry, appendTo: HTMLElement, isVertical = false): void {
    document.querySelector(`.${CONTEXT_MENU2_DIV}:not(.stacked-chart)`)?.remove();
    const rect = d3.select(`.global-category-outline.${ACTIVE}`);
    rect.attr(STROKE, TRANSPARENT);
    rect.classed(ACTIVE, false);

    if (!appendTo.querySelector(`.${CONTEXT_MENU2_DIV}.stacked-chart`)) {
        const stackedChartMenu = new StackedChartMenu(dp).appendTo(appendTo);
        const arrow = <HTMLElement>appendTo.querySelector(".split-btn");
        arrow.classList.add(ACTIVE);

        calculateStackedChartMenuPosition(stackedChartMenu, arrow, isVertical);
        closeMenuOnOutsideClick();

        document.addEventListener(MOUSELEAVE, () => {
            appendTo.querySelector(`.${CONTEXT_MENU2_DIV}`)?.remove();
            appendTo.querySelector(".split-btn")?.remove();
        });
    } else {
        appendTo.querySelector(`.${CONTEXT_MENU2_DIV}`)?.remove();
        appendTo.querySelector(".split-btn")?.remove();
    }
}

function closeMenuOnOutsideClick(): void {
    const closeMenuOnOutsideClick = (event: MouseEvent) => {
        const stackedChartMenu = document.querySelector(`.${CONTEXT_MENU2_DIV}.stacked-chart`);
        const arrow = document.querySelector(".split-btn");

        if (stackedChartMenu && !event.composedPath().includes(arrow) && !event.composedPath().includes(stackedChartMenu)) {
            stackedChartMenu.remove();
            arrow.remove();
            document.removeEventListener(CLICK, closeMenuOnOutsideClick);
        }
    };

    document.addEventListener(CLICK, closeMenuOnOutsideClick);
}

function calculateStackedChartMenuPosition(stackedChartMenu: HTMLElement, splitButton: HTMLElement, isVertical: boolean): void {
    stackedChartMenu.style.position = FIXED;

    const splitButtonBoundingRect = splitButton.getBoundingClientRect();
    const stackedChartMenuBoundingRect = stackedChartMenu.getBoundingClientRect();

    if (isVertical) {
        stackedChartMenu.style.left = `${splitButtonBoundingRect.left + splitButtonBoundingRect.width / 2 - stackedChartMenuBoundingRect.width / 2}px`;
        stackedChartMenu.style.top = `${splitButtonBoundingRect.top + splitButtonBoundingRect.height + 10}px`;
    } else {
        stackedChartMenu.style.left = `${splitButtonBoundingRect.left + splitButtonBoundingRect.width + 10}px`;
        stackedChartMenu.style.top = `${splitButtonBoundingRect.top - stackedChartMenuBoundingRect.height / 2 + splitButtonBoundingRect.height/2}px`;
    }

    repositionElementInsideViewPort(stackedChartMenu);
}