import { Visual } from "./../visual";
import Layout from "./Layout";
import { ChartLayout } from "./../interfaces";
import { HEIGHT, WIDTH } from "../library/constants";
import stackedColumnChart from "./../charting/stackedColumnChart";
import stackedAreaChart from "./../charting/stackedAreaChart";
import verticalStackedBarChart from "./../charting/verticalStackedBarChart";
import InfoBoxHandler from "../ui/InfoBoxHandler";
import { ChartType } from "../enums";

export class StackedChartLayout extends Layout {
    public getChartLayouts(rowCount: number, maxChartsPerRow: number, height: number, visualTitleHeight: number, minChartHeight: number, minChartWidth: number, plotChartWidth: number, bottomMargin: number, topMargin: number, rowMargin: number, columnMargin: number, labelFontSize: number, xPosition: number): ChartLayout[] {
        return [];
    }

    public plot(rowCount: number, maxChartsPerRow: number, height: number, titleHeight: number, minChartHeight: number, minChartWidth: number, plotChartWidth: number, bottomMargin: number,
        topMargin: number, rowMargin: number, columnMargin: number) {

        let chartHeight = Math.max(Visual.visualViewPort.height - (titleHeight + 7), 0);
        let chartWidth = Visual.visualViewPort.width - 2; // this apparently fixes some scroller issues

        //let chartWidth = this.options.viewport.width - 2; // this apparently fixes some scroller issues
        //let chartHeight = Math.max(this.options.viewport.height - (titleHeight + 7), 0);
        //let svgHeight = this.options.viewport.height - 5;
        let svgHeight = Visual.visualViewPort.height - 5;

        if (this.settings.showCommentBox) {
            chartHeight = Math.max(InfoBoxHandler.GET_AVAILABLE_CHART_CONTAINER_HEIGHT(this.settings, Visual.visualViewPort.height) - (titleHeight + 7), 0);
            chartWidth = InfoBoxHandler.GET_AVAILABLE_CHART_CONTAINER_WIDTH(this.settings, chartWidth);
            svgHeight = InfoBoxHandler.GET_AVAILABLE_CHART_CONTAINER_HEIGHT(this.settings, Visual.visualViewPort.height) - 5;
        }

        this.svg.attr(WIDTH, chartWidth)
            .attr(HEIGHT, svgHeight);

        if (this.viewModel.chartData.some(c => c.min < 0)) {
            bottomMargin += Number(this.settings.labelFontSize) + 5;
        }

        const yChart = titleHeight;
        if (this.settings.showCommentMarkers()) {
            topMargin += this.layoutAttributes.topCommentMarkerMargin;
        }

        if (this.settings.chartType === ChartType.Waterfall) {
            this.settings.chartType = this.settings.scenarioOptions.referenceScenario === null ? ChartType.Bar : ChartType.Variance;
        }

        if (this.settings.showVerticalCharts) {
            const layoutAttrs = this.layoutAttributes;
            verticalStackedBarChart(this.viewModel, this.reportArea, this.svg, this.slider, 0, yChart, chartHeight, chartWidth,
                bottomMargin, layoutAttrs.leftMarginCategories);
        }
        else if (this.settings.isLineChart()) {
            stackedAreaChart(this.viewModel, this.reportArea, this.svg, this.slider, 0, yChart, chartHeight, chartWidth, topMargin, bottomMargin);
        }
        else {
            stackedColumnChart(this.viewModel, this.reportArea, this.svg, this.slider, 0, yChart, chartHeight, chartWidth, topMargin, bottomMargin);
        }

        if (this.slideHandler) {
            this.slideHandler.refreshUIelements(this.width, Visual.element, this.settings, Visual.visualViewPort.height);
        }
    }
}