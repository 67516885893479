
import { Scenario, HEIGHT, WP_USERID, SEGOE_UI, NORMAL, DISPLAY, MOUSEENTER, MOUSEOUT, MOUSEOVER, RIGHT, COMMENT_BOX_RESIZE_LINE, ZEBRABI_CHARTS_VISUAL, ZEBRABI_CHART_SVG_CONTAINER, CONTEXT_MENU, ZEBRABI_REPORT_GROUP } from "./../library/constants";
import { ChartSettings } from "./../settings/chartSettings";

import {
    PX, CLICK, DEFS, DIV, P, NONE, BLOCK
} from "./../library/constants";
import {
    AREA_VALUE_GRADIENT, VALUE_GRADIENT, REFERENCE_GRADIENT, AREA_VALUE_GRADIENT_NEGATIVE, REFERENCE_GRADIENT_NEGATIVE, SECOND_VALUE_GRADIENT, SECOND_VALUE_GRADIENT_NEGATIVE, VALUE_GRADIENT_NEGATIVE, INFO_BOX_CONTAINER
} from "./../consts";
import * as drawing from "./../library/drawing";

import * as d3 from "../d3";
import { Visual } from "./../visual";
import { showTooltip } from "../library/ui/showTooltip";

export function addTopLevelElements(visual: Visual, parent: HTMLElement, isAdvert?: boolean) {
    visual.main = document.createElement("main");
    visual.main.classList.add("zebrabi-charts-container");
    parent.append(visual.main);

    if (isAdvert) {
        return;
    }
    visual.svgContainer = document.createElement("div");
    visual.svgContainer.classList.add(ZEBRABI_CHART_SVG_CONTAINER);
    visual.main.append(visual.svgContainer);

    visual.svg = d3.select(visual.svgContainer)
        .append("svg")
        .classed(ZEBRABI_CHARTS_VISUAL, true);

    visual.reportArea = visual.svg.append("g")
        .classed(ZEBRABI_REPORT_GROUP, true);

    visual.infoBox = document.createElement("aside");
    visual.infoBox.classList.add(INFO_BOX_CONTAINER);

    visual.commentBoxResizeLine = document.createElement(DIV);
    visual.commentBoxResizeLine.classList.add(COMMENT_BOX_RESIZE_LINE);

    visual.main.append(visual.commentBoxResizeLine);
    visual.main.append(visual.infoBox);
}

export function removeVisualElements(visual: Visual, removeDefs: boolean) {
    removeProLicenseMsg();
    visual.reportArea.selectAll("*").remove();
    if (removeDefs) {
        visual.svg.selectAll(DEFS).remove();
    }
    d3.selectAll("." + "fixed-categories").remove();
}

export function addAxisBreakPatternDefinitions(defs: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, isArea: boolean, existsNegativeBreak: boolean) {
    let valueColor = settings.colorScheme.neutralColor;
    let referenceColor = settings.colorScheme.neutralColor;
    const scenarioOptions = settings.scenarioOptions;
    const plotVerticalCharts = settings.shouldPlotVerticalCharts();
    if (settings.colorScheme.useCustomScenarioColors) {
        if (scenarioOptions.valueScenario === Scenario.PreviousYear) {
            valueColor = settings.colorScheme.previousYearColor;
        }
        else if (scenarioOptions.valueScenario === Scenario.Plan) {
            valueColor = settings.colorScheme.planColor;
        }
        if (scenarioOptions.valueScenario === Scenario.Forecast) {
            valueColor = settings.colorScheme.forecastColor;
        }

        if (scenarioOptions.referenceScenario === Scenario.PreviousYear) {
            referenceColor = settings.colorScheme.previousYearColor;
        }
        else if (scenarioOptions.referenceScenario === Scenario.Plan) {
            referenceColor = settings.colorScheme.planColor;
        }
        if (scenarioOptions.referenceScenario === Scenario.Forecast) {
            referenceColor = settings.colorScheme.forecastColor;
        }
    }

    if (isArea) {
        drawing.addGradientDefinitions(defs, AREA_VALUE_GRADIENT, valueColor, scenarioOptions.valueScenario, false, isArea);
        Visual.viewModel.chartData.forEach(groupData => {
            drawing.addGradientDefinitions(defs, groupData.highlightGroupColor, groupData.highlightGroupColor, scenarioOptions.valueScenario, false, false, plotVerticalCharts);
        });
    }
    else {
        drawing.addGradientDefinitions(defs, VALUE_GRADIENT, valueColor, scenarioOptions.valueScenario, false, false, plotVerticalCharts);
        Visual.viewModel.chartData.forEach(groupData => {
            settings.highlightedCategoriesCustomColors.find(category => {
                if (scenarioOptions.valueScenario === Scenario.Actual) {
                    drawing.addGradientDefinitions(defs, `${category[settings.actual] ?? "#0070C0"}-AC`, category[settings.actual] ?? "#0070C0", scenarioOptions.valueScenario, false, false, plotVerticalCharts);
                }
                if (scenarioOptions.valueScenario === Scenario.PreviousYear) {
                    drawing.addGradientDefinitions(defs, `${category[settings.previousYear] ?? "#0070C0"}-PY`, category[settings.previousYear] ?? "#0070C0", scenarioOptions.valueScenario, false, false, plotVerticalCharts);
                }
                if (scenarioOptions.valueScenario === Scenario.Forecast) {
                    drawing.addGradientDefinitions(defs, `${category[settings.forecast] ?? "#0070C0"}-FC`, category[settings.forecast] ?? "#0070C0", scenarioOptions.valueScenario, false, false, plotVerticalCharts);
                }
            });
            drawing.addGradientDefinitions(defs, groupData.highlightGroupColor, groupData.highlightGroupColor, scenarioOptions.valueScenario, false, false, plotVerticalCharts);
        });
    }
    drawing.addGradientDefinitions(defs, REFERENCE_GRADIENT, referenceColor, scenarioOptions.referenceScenario, false, false, plotVerticalCharts);
    if (existsNegativeBreak) {
        if (isArea) {
            drawing.addGradientDefinitions(defs, AREA_VALUE_GRADIENT_NEGATIVE, valueColor, scenarioOptions.valueScenario, true, isArea);
        }
        else {
            drawing.addGradientDefinitions(defs, VALUE_GRADIENT_NEGATIVE, valueColor, scenarioOptions.valueScenario, true, false, plotVerticalCharts);
        }
        drawing.addGradientDefinitions(defs, REFERENCE_GRADIENT_NEGATIVE, referenceColor, scenarioOptions.referenceScenario, true, false, plotVerticalCharts);
    }

    if (scenarioOptions.secondValueScenario !== null) {
        const secondValueColor = settings.colorScheme.useCustomScenarioColors ? settings.colorScheme.forecastColor : settings.colorScheme.neutralColor;
        drawing.addGradientDefinitions(defs, SECOND_VALUE_GRADIENT, secondValueColor, scenarioOptions.secondValueScenario, false, false, plotVerticalCharts);
        if (existsNegativeBreak) {
            drawing.addGradientDefinitions(defs, SECOND_VALUE_GRADIENT_NEGATIVE, secondValueColor, scenarioOptions.secondValueScenario, true, false, plotVerticalCharts);
        }
    }
}

export function showProLicenseNeededMsg(container: HTMLElement, msg: string, isBottomAligned: boolean) {
    const overlay = d3.select(container).append(DIV);
    overlay.classed("overlay-div", true);
    if (isBottomAligned) {
        overlay
            .style(HEIGHT, "150px");
    }

    const overlayContent = overlay.append(DIV).classed("overlay-content", true);
    overlayContent.append(DIV).classed("lock-icon", true);
    overlayContent.append(P).text(msg);

    const buyButton = overlayContent.append(P).text("Start Pro trial").classed("learn-more", true);
    buyButton.on(CLICK, () => {
        // Visual.visualHost.launchUrl(licensingUI.FREE_TRIAL_URL);
    });
}

export function showEmbeddedProLicenseExpired(container: HTMLElement) {
    const overlay = d3.select(container).append(DIV);
    overlay.classed("overlay-div", true);
    const overlayContent = overlay.append(DIV).classed("overlay-content", true);
    overlayContent.append(DIV).classed("lock-icon", true);
    overlayContent.append(P).text("Your license has expired");
    const buyButton = overlayContent.append(P).text("Renew").classed("learn-more", true);
    buyButton.on(CLICK, () => {
        // Visual.visualHost.launchUrl(PAID_EXPIRED_URL + "&ref=" + WP_USERID);
    });
}

export function removeProLicenseMsg() {
    d3.selectAll("." + "overlay-div").remove();
}

export function showPopupMessage(element: HTMLElement, x: number, y: number, text: string) {
    d3.selectAll(".message-tooltip").remove();
    const tooltipDiv = document.createElement(DIV);
    tooltipDiv.className = "message-tooltip";
    tooltipDiv.style.left = Math.max(0, x) + PX;
    tooltipDiv.style.top = y + PX;
    tooltipDiv.style.width = "fit-content";
    tooltipDiv.style.width = "-moz-fit-content";
    tooltipDiv.style.paddingLeft = "5px";
    tooltipDiv.style.paddingRight = "5px";
    const popupContent = text;
    tooltipDiv.textContent = popupContent;
    element.appendChild(tooltipDiv);
    tooltipDiv.style.display = NONE;
    tooltipDiv.style.pointerEvents = NONE;
    window.setTimeout(() => {
        tooltipDiv.style.display = BLOCK;
    }, 100);
    window.setTimeout(() => {
        tooltipDiv.style.display = NONE;
        if (element.contains(tooltipDiv)) {
            element.removeChild(tooltipDiv);
        }
    }, 2000);
}
