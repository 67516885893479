import { OVERLAY, FIXED, MOUSELEAVE, ACTIVE } from "../library/constants";

export function addOverlayProperties(element: HTMLElement): void {
    element.classList.add(OVERLAY);
    element.style.position = FIXED;
    element.addEventListener(MOUSELEAVE, () => {
        if (!element.classList.contains(ACTIVE)) {
            element.remove();
        }
    });
}
