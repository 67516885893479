import GlobalToolbarObserver from "@zebrabi/global-toolbar/dist/lib/observers/GlobalToolbarObserver";

/**
 * Type defined observer used to isolate the notifications
 */
abstract class AboutSwitcherObserver extends GlobalToolbarObserver {
    static readonly CLASS_NAME = "AboutSwitcherObserver";

    getClassName(): string {
        return AboutSwitcherObserver.CLASS_NAME;
    }
}

export default AboutSwitcherObserver;
