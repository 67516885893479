import { Client } from "@microsoft/microsoft-graph-client";

class SharepointBrowserAPI {
  private accessToken: string;

  private _graphClient = null;
  /**
   * Gets the graph client or initialises it if it hasn't been initialised yet
   * @private
   */
  private get graphClient(): Client {
    if (this._graphClient === null) {
      this._graphClient = Client.init({
        authProvider: (done) => {
          done(null, this.accessToken);
        },
      });
    }

    return this._graphClient;
  }

  public async getCurrentUserOnedrive<K>(): Promise<{
    message?: string;
    value: K | [];
  }> {
    try {
      return await this.graphClient.api("/me/drive").get();
    } catch (e) {
      return {
        value: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  public async getCurrentUserOnedrives<K>(): Promise<{
    message?: string;
    value: K | [];
  }> {
    try {
      return await this.graphClient.api("/me/drives").get();
    } catch (e) {
      return {
        value: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  public async getDriveByID<K>(driveId: string): Promise<{
    message?: string;
    value: K | [];
  }> {
    try {
      return await this.graphClient.api(`/drives/${driveId}`).get();
    } catch (e) {
      return {
        value: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  public async getDriveChildren<K>(driveId: string): Promise<{
    message?: string;
    value: [];
  }> {
    try {
      return await this.graphClient.api(`/drives/${driveId}/root/children`).get();
    } catch (e) {
      return {
        value: [],
        message: JSON.stringify(e.message),
      };
    }
  }


  /**
   * Gets the sites from the graph client for the tenant
   */
  public async getSites<K>(): Promise<{
    message?: string;
    value: K | [];
  }> {
    try {
      return await this.graphClient.api("/sites/?search=*").get();
    } catch (e) {
      return {
        value: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  public async listDriveItemChildren(
    siteId: string,
    itemId?: string
  ): Promise<{
    message?: string;
    value: [];
  }> {
    try {
      let path = itemId ? `/sites/${siteId}/drive/items/${itemId}/children` : `/sites/${siteId}/drive/root/children`;
      if (!siteId) {
        path = itemId ? `/me/drive/items/${itemId}/children` : `/me/drive/root/children`;
      }

      return await this.graphClient.api(path).get();
    } catch (e) {
      return {
        value: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  /**
   * Get the list of drives on a site
   * @param siteId {string} - the site id provided by the graph site search
   */
  public async getDrives<K>(siteId: string): Promise<{
    message?: string;
    value: K | [];
  }> {
    try {
      return await this.graphClient.api(`/sites/${siteId}/drives`).get();
    } catch (e) {
      return {
        value: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  /**
   * Get the default drive on a site
   * @param siteId
   */
  public async getDefaultDrive<K>(siteId: string): Promise<{
    message?: string;
    value?: K;
  }> {
    try {
      return {
        value: await this.graphClient.api(`/sites/${siteId}/drive`).get(),
      };
    } catch (e) {
      return {
        message: JSON.stringify(e.message),
      };
    }
  }

  /**
   * Get the list of items witch include files and folders
   * @param siteId {string} - the site id provided by the graph site search
   * @param driveId {string} - the drive id provided by the graph drive search
   * @param path {string} - the folder path concatenated with the drive root or the actual file path
   */
  public async getItems(
    siteId: string,
    driveId: string,
    path?: string
  ): Promise<{
    message?: string;
    value: [];
  }> {
    try {
      return await this.graphClient
        .api(`/sites/${siteId}/drives/${driveId}/root${path ? `:${path}:` : ""}/children`)
        .get();
    } catch (e) {
      return {
        value: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  /**
   * Get the list of worksheets in the workbook
   * @param driveId {string} - the drive id provided by the graph drive search
   * @param fileId {string} - the file id provided by the graph file search
   */
  public async getWorksheets<K>(
    driveId: string,
    fileId: string
  ): Promise<{
    message?: string;
    value: K | [];
  }> {
    try {
      return await this.graphClient.api(`/drives/${driveId}/items/${fileId}/workbook/worksheets`).get();
    } catch (e) {
      return {
        value: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  /**
   * Get the range of cells from a worksheet
   * @param driveId {string} - the drive id provided by the graph drive search
   * @param fileId {string} - the file id provided by the graph file search
   * @param worksheetId {string} - the worksheet id provided by the graph worksheet search
   * @param range {string} - the range of cells to get (format: A1:B2)
   */
  public async getRange(
    driveId: string,
    fileId: string,
    worksheetId: string,
    range: string
  ): Promise<{
    message?: string;
    values: [];
  }> {
    try {
      return await this.graphClient
        .api(
          `/drives/${driveId}/items/${fileId}/workbook/worksheets/${worksheetId}/range(address='${range}')?valuesOnly=true`
        )
        .get();
    } catch (e) {
      return {
        values: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  /**
   * Get the list of tables in the entire workbook
   * @param driveId {string} - the drive id provided by the graph drive search
   * @param fileId {string} - the file id provided by the graph file search
   */
  public async getWorkbookTables<K>(
    driveId: string,
    fileId: string
  ): Promise<{
    message?: string;
    value: K | [];
  }> {
    try {
      return await this.graphClient.api(`/drives/${driveId}/items/${fileId}/workbook/tables`).get();
    } catch (e) {
      return {
        value: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  /**
   * Get the list of tables in a worksheet
   * @param driveId {string} - the drive id provided by the graph drive search
   * @param fileId {string} - the file id provided by the graph file search
   * @param worksheetId {string} - the worksheet id provided by the graph worksheet search
   */
  public async getWorksheetTables<K>(
    driveId: string,
    fileId: string,
    worksheetId: string
  ): Promise<{
    message?: string;
    value: K | [];
  }> {
    try {
      return await this.graphClient
        .api(`/drives/${driveId}/items/${fileId}/workbook/worksheets/${worksheetId}/tables`)
        .get();
    } catch (e) {
      return {
        value: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  /**
   * Get the range of a table in a worksheet
   * @param driveId {string} - the drive id provided by the graph drive search
   * @param fileId {string} - the file id provided by the graph file search
   * @param tableId {string} - the table name provided by the graph table search (the table id seems to produce errors in this call)
   */
  public async getTableRange(
    driveId: string,
    fileId: string,
    tableId: string
  ): Promise<{
    message?: string;
    values: [];
  }> {
    try {
      return await this.graphClient.api(`/drives/${driveId}/items/${fileId}/workbook/tables/${tableId}/range`).get();
    } catch (e) {
      return {
        values: [],
        message: JSON.stringify(e.message),
      };
    }
  }

  private constructor() { }

  /**
   * Creates an instance of the SharepointBrowserAPI
   * @param accessToken {string} - access token with extended privileges that allow site, drive and file access
   */
  public static createInstance(accessToken: string) {
    const instance = new SharepointBrowserAPI();
    instance.setAccessToken(accessToken);
    return instance;
  }

  /**
   * Sets the access token
   * @param accessToken {string} - access token with extended privileges that allow site, drive and file access
   * @private
   */
  private setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }
}

export default SharepointBrowserAPI;
