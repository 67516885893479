import * as d3 from "../d3";
import { LabelAlignment } from "../library/types";
import { ChartData, DataPoint } from "./../interfaces";
import { LabelProperties } from "./../library/interfaces";
import { ChartType, DifferenceHighlightFromTo, } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { Visual } from "./../visual";

import {
    Scenario, DifferenceLabel,
    END, NORMAL, HIGHLIGHTABLE, RECT, BLACK, WHITE, TOOLTIP, G, BAR, ITALIC, FONT_STYLE, VARIANCE, AXIS_SCENARIO_DELIMITER, GRAY, START, AXIS, WIDTH, HEIGHT, Y, X, OPACITY, FILL, VarianceDisplayType, PATH, D, TRANSFORM, LINE, X1, STROKE, STROKE_DASHARRAY, STROKE_WIDTH, X2, Y1, Y2
} from "./../library/constants";
import {
    CHART_CONTAINER, VALUE, SECOND_SEGMENT_VALUE
} from "./../consts";

import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as viewModels from "./../viewModel/viewModelHelperFunctions";
import * as charting from "./chart";
import { mapScenarioKeyAndName, mapAdditionalLabelsWithOriginal } from "../helpers";
import { plotChartLegendSettings } from "../ui/drawGlobalLegendMenuOverlay";

// eslint-disable-next-line max-lines-per-function
export default function verticalVarianceChart(reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement,
    x: number, y: number, height: number, width: number, chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number,
    plotTitle: boolean, allowLabelsChange: boolean, plotLegend: boolean, plotCategories: boolean, leftMargin: number, rightMargin: number, leftMarginCategories: number) {

    const scenarioOptions = settings.scenarioOptions;
    const dataLabelsMargin = settings.showDataLabels || settings.showCommentMarkers() ? rightMargin : 0;
    const categoriesMargin = plotCategories ? leftMargin : 1;
    if (plotLegend) {
        topMargin += settings.labelFontSize;
    }
    if (bottomMargin + topMargin > height) {
        topMargin = 0;
    }
    const chartXPos = x;
    const chartYPos = y + topMargin;
    const chartHeight = height - topMargin - bottomMargin;

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    const yScale = charting.getOrdinalScale(chartData, chartYPos, chartHeight, settings.getGapBetweenColumns());
    const xScale = charting.getLinearScale(min, max, x + categoriesMargin, x + width - (dataLabelsMargin));

    charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, chartXPos + categoriesMargin, y,
        charting.getChartTitleWidth(width - categoriesMargin, settings), height, max, min, topMargin, bottomMargin);
    if (settings.showCategories && plotCategories) {
        charting.plotVerticalCategories(chartContainer, settings, chartData, x, yScale, leftMarginCategories);
    }

    const setBarHeightToZero = min === 0 && max === 0;

    let secondSegmentDataPoints: DataPoint[] = [];
    let secondSegmentVariancesDataPoints: DataPoint[] = [];
    let secondSegmentValuesOnlyDPs: DataPoint[] = [];
    if (settings.scenarioOptions.secondValueScenario !== null) {
        secondSegmentDataPoints = settings.showAllForecastData ? chartData.dataPoints.filter(dp => dp.secondSegmentValue !== null) :
            chartData.dataPoints.filter(dp => dp.value === null && dp.secondSegmentValue !== null);
        secondSegmentValuesOnlyDPs = settings.showAllForecastData ? secondSegmentDataPoints.filter(dp => dp.value === null) : secondSegmentDataPoints;

        let secondSegmentBars = chartContainer
            .selectAll(`.${BAR}_SECOND_SEGMENT` + chartIndex)
            .data(secondSegmentDataPoints);
        const rect = secondSegmentBars.enter()
            .append(RECT)
            .classed(BAR, true)
            .classed(HIGHLIGHTABLE, true);
        secondSegmentBars = secondSegmentBars.merge(rect)
            .attr(HEIGHT, yScale.bandwidth())
            .attr(WIDTH, d => Math.abs(xScale(d.secondSegmentValue) - xScale(0)))
            .attr(X, d => xScale(d.secondSegmentValue > 0 ? 0 : d.secondSegmentValue))
            .attr(Y, d => yScale(d.category));
        styles.applyToBars(secondSegmentBars, (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenarioOptions.secondValueScenario, settings.chartStyle, false, settings.colorScheme);

        secondSegmentVariancesDataPoints = secondSegmentValuesOnlyDPs.filter(d => d.reference !== null);
        let secondSegmentVariances = chartContainer
            .selectAll(`.${BAR}_VARIANCES_SECOND_SEGMENT` + chartIndex)
            .data(secondSegmentVariancesDataPoints);

        if (settings.varianceDisplayType === VarianceDisplayType.Bar) {
            const rectSecondSegment = secondSegmentVariances.enter()
                .append(RECT)
                .classed(BAR, true)
                .classed(HIGHLIGHTABLE, true)
                .classed(VARIANCE, true);
            secondSegmentVariances = secondSegmentVariances.merge(rectSecondSegment)
                .attr(HEIGHT, yScale.bandwidth() * 0.7)
                .attr(WIDTH, d => Math.abs(xScale(d.secondSegmentValue) - xScale(d.reference)))
                .attr(X, d => d.reference > d.secondSegmentValue ? xScale(d.secondSegmentValue) : xScale(d.reference))
                .attr(Y, d => yScale(d.category));
        }
        else {
            const rectSecondSegment = secondSegmentVariances.enter()
                .append(PATH)
                .classed(BAR, true)
                .classed(HIGHLIGHTABLE, true)
                .classed(VARIANCE, true)
                .attr(D, (d) => drawing.getChevronArrowPath(Math.abs(xScale(d.secondSegmentValue) - xScale(d.reference)), yScale.bandwidth(), d.isNegative, true))
                .attr(TRANSFORM, (d) => {
                    const X = d.reference > d.secondSegmentValue ? xScale(d.secondSegmentValue) : xScale(d.reference);
                    const Y = yScale(d.category);
                    return "translate(" + X + "," + Y + ")";
                });
            secondSegmentVariances.enter()
                .append(LINE)
                .attr(X1, (d) => xScale(d.reference))
                .attr(Y1, (d) => yScale(d.category))
                .attr(X2, (d) => xScale(d.reference))
                .attr(Y2, (d) => yScale(d.category) + yScale.bandwidth())
                .attr(STROKE, settings.scenarioOptions.referenceScenario === Scenario.PreviousYear ? styles.getLighterColor(settings.colorScheme.axisColor) : settings.colorScheme.axisColor)
                .attr(STROKE_DASHARRAY, settings.scenarioOptions.referenceScenario === Scenario.Forecast ? "2" : 0)
                .attr(STROKE_WIDTH, "1");
            secondSegmentVariances = secondSegmentVariances.merge(rectSecondSegment);
        }
        const varianceColor = (d: DataPoint): string => { return styles.getVarianceColor(chartData.isInverted, d.secondSegmentValue - d.reference, settings.colorScheme); };
        styles.applyToBars(secondSegmentVariances, varianceColor, scenarioOptions.secondValueScenario, settings.chartStyle, true, settings.colorScheme);
        if (secondSegmentValuesOnlyDPs.length > 0) {
            const yAxisDelimiter = yScale(secondSegmentDataPoints[0].category) - yScale.bandwidth() / 8;
            drawing.drawLine(chartContainer, chartXPos, xScale(0) + 20, yAxisDelimiter, yAxisDelimiter, 1, GRAY, AXIS_SCENARIO_DELIMITER);
        }
        charting.addMouseHandlers(secondSegmentBars, svg);
        charting.addMouseHandlers(secondSegmentVariances, svg);
        secondSegmentBars.exit().remove();
        secondSegmentVariances.exit().remove();
    }

    const valuesDataPoints = chartData.dataPoints.filter(dp => dp.value !== null);
    let bars = chartContainer
        .selectAll(`.${BAR}` + chartIndex)
        .data(valuesDataPoints);
    const rect = bars.enter()
        .append(RECT)
        .classed(BAR, true)
        .classed(HIGHLIGHTABLE, true);
    bars = bars.merge(rect)
        .attr(WIDTH, d => setBarHeightToZero ? 0 : Math.abs(xScale(d.value) - xScale(0)))
        .attr(HEIGHT, yScale.bandwidth())
        .attr(Y, d => yScale(d.category))
        .attr(X, d => xScale(d.value > 0 ? 0 : d.value));
    styles.applyToBars(bars, (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme);

    const variancesDataPoints = chartData.dataPoints.filter(dp => dp.value !== null && dp.reference !== null);
    let variances = chartContainer
        .selectAll(VARIANCE + chartIndex)
        .data(variancesDataPoints);

    if (settings.varianceDisplayType === VarianceDisplayType.Bar) {
        const rectVariances = variances.enter()
            .append(RECT)
            .classed(VARIANCE, true)
            .classed(BAR, true)
            .classed(HIGHLIGHTABLE, true);
        variances = variances.merge(rectVariances)
            .attr(HEIGHT, yScale.bandwidth() * 0.7)
            .attr(WIDTH, d => Math.abs(xScale(d.value) - xScale(d.reference)))
            .attr(X, d => d.reference > d.value ? xScale(d.value) : xScale(d.reference))
            .attr(Y, d => yScale(d.category));
    }
    else {
        const rectVariances = variances.enter()
            .append(PATH)
            .classed(BAR, true)
            .classed(HIGHLIGHTABLE, true)
            .attr(D, (d) => drawing.getChevronArrowPath(Math.abs(xScale(d.value) - xScale(d.reference)), yScale.bandwidth(), d.isNegative, true))
            .attr(TRANSFORM, (d) => {
                const X = d.isNegative ? xScale(d.value) : xScale(d.reference);
                const Y = yScale(d.category);
                return "translate(" + X + "," + Y + ")";
            });
        const lineVariance = variances.enter()
            .append(LINE)
            .attr(X1, (d) => xScale(d.reference))
            .attr(Y1, (d) => yScale(d.category))
            .attr(X2, (d) => xScale(d.reference))
            .attr(Y2, (d) => yScale(d.category) + yScale.bandwidth())
            .attr(STROKE, settings.scenarioOptions.referenceScenario === Scenario.PreviousYear ? styles.getLighterColor(settings.colorScheme.axisColor) : settings.colorScheme.axisColor)
            .attr(STROKE_DASHARRAY, settings.scenarioOptions.referenceScenario === Scenario.Forecast ? "2" : 0)
            .attr(STROKE_WIDTH, "1");
        variances = variances.merge(rectVariances).merge(lineVariance);
    }

    const varianceColor = (d: DataPoint): string => { return styles.getVarianceColor(chartData.isInverted !== d.isCategoryInverted, d.value - d.reference, settings.colorScheme); };
    styles.applyToBars(variances, varianceColor, scenarioOptions.valueScenario, settings.chartStyle, true, settings.colorScheme);

    if (valuesDataPoints.length < chartData.dataPoints.length) {
        // plot reference only bars
        const referenceDataPoints = chartData.dataPoints.filter(dp => dp.value === null && dp.secondSegmentValue === null && dp.reference !== null);
        let referenceBars = chartContainer
            .selectAll(`.${BAR}_REFERENCE` + chartIndex)
            .data(referenceDataPoints);
        const rect = referenceBars.enter()
            .append(RECT)
            .classed(BAR, true)
            .classed(HIGHLIGHTABLE, true);
        referenceBars = referenceBars.merge(rect)
            .attr(HEIGHT, yScale.bandwidth())
            .attr(WIDTH, d => Math.abs(xScale(d.reference) - xScale(0)))
            .attr(X, d => xScale(d.reference > 0 ? 0 : d.reference))
            .attr(Y, d => yScale(d.category));
        styles.applyToBars(referenceBars, (d: DataPoint) => d.color, settings.scenarioOptions.referenceScenario, settings.chartStyle, false, settings.colorScheme);
        charting.addMouseHandlers(referenceBars, svg);
        referenceBars.exit().remove();
    }

    // plot axis
    drawing.drawLine(chartContainer, xScale(0), xScale(0), chartYPos, chartYPos + chartHeight, 1, settings.colorScheme.axisColor, AXIS);

    if (settings.scenarioOptions.secondReferenceScenario) {
        charting.addReferenceTriangles(chartContainer, chartData.dataPoints.filter(dp => dp.secondReference !== null), settings, yScale, xScale, y, scenarioOptions.secondReferenceScenario, true);
    }

    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    const varianceLabelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);

    if (settings.showDataLabels) {
        const hideUnits = settings.shouldHideDataLabelUnits();
        const color = styles.getLabelColor(settings.colorScheme.neutralColor);

        const labelsProperties = getBarLabelProperties(chartData.dataPoints, settings, labelsFormat, hideUnits, yScale, xScale, categoriesMargin);
        if (labelsProperties.length > 0) {
            const forecastLabelProperties = labelsProperties.filter(p => p.isForecast);
            if (forecastLabelProperties.length > 0) {
                charting.plotLabelBackgrounds(chartContainer, chartIndex, forecastLabelProperties, settings, WHITE, true, false);
            }

            const backgroundsColor = charting.getLabelBackgroundColor(settings, settings.scenarioOptions, chartData.group);
            charting.plotLabelBackgrounds(chartContainer, chartIndex, labelsProperties.filter(p => !p.isForecast), settings, backgroundsColor, true, true);
            const valueLabels = charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings);
            valueLabels
                .attr(FILL, d => d.isForecast ? BLACK : d.isHighlighted ? styles.getLabelColor(settings.getCategoryHighlightColor(d.category)) : color);
        }

        const varianceLabelsDataPoints = variancesDataPoints.concat(secondSegmentVariancesDataPoints);
        const varianceLabelProperties = getBarVarianceLabelProperties(varianceLabelsDataPoints, settings, labelsFormat, varianceLabelsFormat, hideUnits, yScale, xScale, y);
        if (settings.showAllForecastData && settings.scenarioOptions.secondValueScenario !== null) {
            charting.plotLabelBackgrounds(chartContainer, chartIndex, varianceLabelProperties, settings, WHITE, true, false, null, true);
        }
        const varianceCalculationChangeAllowed = settings.getRealInteractionSettingValue(settings.allowVarianceCalculationChange) && allowLabelsChange;
        const varianceLabels = charting.plotLabels(chartContainer, `labels_V${chartIndex}`, varianceLabelProperties, settings, NORMAL, varianceCalculationChangeAllowed);

        if (allowLabelsChange) {
            let additionalLabels: d3.Selection<any, LabelProperties, any, any> = null;
            if (settings.varianceLabel === DifferenceLabel.Relative) {
                varianceLabels.style(FONT_STYLE, ITALIC);
            }
            else if (settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute) {
                const additionalVarianceLabelsProperties = getBarAdditionalLabelProperties(varianceLabelsDataPoints, settings, yScale, xScale, y);
                mapAdditionalLabelsWithOriginal(additionalVarianceLabelsProperties, varianceLabels, settings.labelFontSize);
                additionalLabels = charting.plotLabels(chartContainer, `labels_V${chartIndex}_2`, additionalVarianceLabelsProperties, settings, ITALIC, varianceCalculationChangeAllowed);
            }

            if (varianceCalculationChangeAllowed && Visual.animateVarianceLabels) {
                charting.animateLabels(varianceLabels, additionalLabels, settings.labelFontSize);
            }
        }

        if (valuesDataPoints.length < chartData.dataPoints.length) {
            const referenceOnlyDataPoints = chartData.dataPoints.filter((p, i) => i >= valuesDataPoints.length && p.reference !== null && p.secondSegmentValue === null);
            const referenceLabelProperties = getReferenceLabelProperties(referenceOnlyDataPoints, settings, labelsFormat, hideUnits, yScale, xScale);
            if (referenceLabelProperties.length > 0) {
                charting.plotLabels(chartContainer, `${chartIndex}`, referenceLabelProperties, settings);
            }
        }
    }

    if (settings.differenceHighlight && valuesDataPoints.length > 0) {
        let { fromDataPoint, toDataPoint } = charting.getDiffHighlightAutoDataPoints(valuesDataPoints, ChartType.Variance, false);
        let isLastAcToLastFc = false;
        if (settings.differenceHighlightFromTo !== DifferenceHighlightFromTo.Auto) {
            if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.LastACToLastFC && secondSegmentDataPoints.length !== 0 && valuesDataPoints.length !== 0) {
                isLastAcToLastFc = true;
                fromDataPoint = valuesDataPoints[valuesDataPoints.length - 1];
                toDataPoint = secondSegmentDataPoints[secondSegmentDataPoints.length - 1];
            }
            else {
                const diffHighlightDataPoints = settings.isSecondSegmentDiffHighlight() ? secondSegmentDataPoints : valuesDataPoints;
                const fromToDataPoints = charting.getDiffHighlightDataPoints(diffHighlightDataPoints, settings.differenceHighlightFromTo);
                fromDataPoint = fromToDataPoints.fromDP;
                toDataPoint = fromToDataPoints.toDP;
            }
        }

        if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.Auto && !settings.isSecondSegmentDiffHighlight() && toDataPoint && toDataPoint.reference === null) {
            // if there is no reference value for the last data point, display diff higlight between the values of last two data points (as in single measure column chart)
            fromDataPoint = charting.getDiffHighlightAutoDataPoints(valuesDataPoints, ChartType.Variance, true).fromDataPoint;
        }

        if (fromDataPoint && toDataPoint) {
            const valueProperty: keyof DataPoint = settings.isSecondSegmentDiffHighlight() ? SECOND_SEGMENT_VALUE : VALUE;
            const endY =
                yScale(chartData.dataPoints[chartData.dataPoints.length - 1].category) +
                yScale.bandwidth() / 2 +
                yScale.step();

            if (fromDataPoint === toDataPoint && fromDataPoint[valueProperty] !== null && toDataPoint.reference !== null) {
                const dhX1 = xScale(fromDataPoint[valueProperty]);
                const dhX2 = xScale(fromDataPoint.reference);
                const dhY1 = yScale(fromDataPoint.category);
                const dhY2 = yScale(fromDataPoint.category) + 0.3 * yScale.bandwidth();
                charting.addVerticalDifferenceHighlight(chartContainer, settings, slider, endY, dhX1, dhX2, fromDataPoint[valueProperty], fromDataPoint.reference, dhY1, dhY2, chartData.isInverted);
            }
            else if (fromDataPoint !== toDataPoint && (fromDataPoint[valueProperty] !== null && toDataPoint[valueProperty] !== null
                || isLastAcToLastFc && fromDataPoint.value !== null && toDataPoint.secondSegmentValue !== null)) {
                const startValue = isLastAcToLastFc ? fromDataPoint.value : fromDataPoint[valueProperty];
                const endValue = isLastAcToLastFc ? toDataPoint.secondSegmentValue : toDataPoint[valueProperty];
                const dhX1 = xScale(endValue);
                const dhX2 = xScale(startValue);
                const dhY1 = yScale(toDataPoint.category);
                const dhY2 = yScale(fromDataPoint.category);
                charting.addVerticalDifferenceHighlight(chartContainer, settings, slider, endY, dhX1, dhX2, endValue, startValue, dhY1, dhY2, chartData.isInverted);
            }
        }
    }

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, settings, rightMargin);
        }
    }

    charting.addMouseHandlers(bars, svg);
    charting.addMouseHandlers(variances, svg);

    // Visual.tooltipServiceWrapper.addTooltip(chartContainer.selectAll(`.${BAR}, .${TOOLTIP}`),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => charting.getTooltipData(tooltipEvent.data, settings, labelsFormat, varianceLabelsFormat, chartData.isInverted, undefined, chartData),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => tooltipEvent.data.selectionId);

    bars.exit().remove();
    variances.exit().remove();

    if (plotLegend && valuesDataPoints.length > 0) {
        const firstDP = valuesDataPoints[0];
        const valueLegendXPosition = xScale(firstDP.value > 0 ? 0 : firstDP.value) + Math.abs(xScale(firstDP.value / 2) - xScale(0));
        const legendYPosition = chartYPos;
        charting.plotChartLegend(chartContainer, mapScenarioKeyAndName("valueHeader", settings.valueHeader, scenarioOptions), valueLegendXPosition, legendYPosition, settings);
        const referencePoints = chartData.dataPoints.filter(dp => dp.reference !== null);
        const differenceLegendXPositionValue = referencePoints.length > 0 ? referencePoints[0].reference : 0;
        let differenceLegendXPosition = xScale(differenceLegendXPositionValue) - 5;
        if (Math.abs(differenceLegendXPosition - valueLegendXPosition) < 2 * settings.labelFontSize) {
            differenceLegendXPosition = valueLegendXPosition + 2 * settings.labelFontSize;
        }
        charting.plotChartLegend(chartContainer, mapScenarioKeyAndName("absoluteDifferenceHeader", settings.absoluteDifferenceHeader, scenarioOptions), differenceLegendXPosition, legendYPosition, settings);
        if (settings.scenarioOptions.secondReferenceScenario) {
            const secondReferenceDataPoints = chartData.dataPoints.filter(dp => dp.secondReference !== null);
            if (secondReferenceDataPoints.length > 0) {
                const legendXSecondReferencePosition = xScale(secondReferenceDataPoints[0].secondReference) - 5;
                if (Math.abs(legendXSecondReferencePosition - valueLegendXPosition) > 2 * settings.labelFontSize &&
                    Math.abs(legendXSecondReferencePosition - differenceLegendXPosition) > 2 * settings.labelFontSize) {
                    charting.plotChartLegend(chartContainer, mapScenarioKeyAndName("secondReferenceHeader", settings.secondReferenceHeader, scenarioOptions), legendXSecondReferencePosition, legendYPosition, settings);
                }
            }
        }
        plotChartLegendSettings(chartContainer, x, y, width);
    }
}

function getBarLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    ordinalScale: d3.ScaleBand<string>, linearScale: d3.ScaleLinear<number, number>, categoriesMargin: number): LabelProperties[] {
    const categoryRangeBand = ordinalScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const labelProperties = labelsDataPoints.map(d => {
        const value = viewModels.getDataPointNonNullValue(d);
        const isEnoughSpace = Math.abs(linearScale(value) - linearScale(0)) > 33; // Improve?: use value labels width
        let labelText = "";
        if (isEnoughSpace) {
            labelText = charting.getFormattedDataLabel(value, settings.decimalPlaces, settings.displayUnits, settings.locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        }
        const xPos = linearScale(value / 2);
        const yPos = ordinalScale(d.category) + ordinalScale.bandwidth() / 2 + settings.labelFontSize * 0.35;
        const isForecast = d.value !== null && settings.scenarioOptions.valueScenario === Scenario.Forecast
            || d.value === null && d.secondSegmentValue !== null && settings.scenarioOptions.secondValueScenario === Scenario.Forecast;

        return charting.getLabelProperty(labelText, xPos, yPos, null, value, d, settings.labelFontSize, settings.labelFontFamily, null, null, isForecast);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, categoryRangeBand);
}

function getBarVarianceLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, varianceLabelsFormat: string, hideUnits: boolean,
    ordinalScale: d3.ScaleBand<string>, linearScale: d3.ScaleLinear<number, number>, y: number): LabelProperties[] {
    const yRangeBand = ordinalScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const labelProperties = labelsDataPoints.map(d => {
        let labelText = "";
        let labelValue = 0;
        if (settings.varianceLabel === DifferenceLabel.Relative) {
            labelValue = viewModels.calculateRelativeDifferencePercent((viewModels.getDataPointNonNullValue(d)), d.reference);
            labelText = charting.getRelativeVarianceLabel(settings, labelValue, false);
        }
        else {
            const labelValue = viewModels.getDataPointNonNullValue(d) - d.reference;
            labelText = charting.getVarianceDataLabel(labelValue, settings.decimalPlaces, settings.displayUnits, settings.locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, varianceLabelsFormat, hideUnits);
        }

        let maxVal = Math.max((viewModels.getDataPointNonNullValue(d)), d.reference);
        if (maxVal < 0) {
            maxVal = Math.min((viewModels.getDataPointNonNullValue(d)), d.reference);
        }
        const yPos = ordinalScale(d.category) + yRangeBand * 0.5;
        const xPos = maxVal >= 0 ? linearScale(maxVal) + 3 : linearScale(maxVal) - 5;
        const alignment: LabelAlignment = maxVal < 0 ? END : START;
        return charting.getLabelProperty(labelText, xPos, yPos, null, labelValue, d, settings.labelFontSize, settings.labelFontFamily, true, null, null, alignment, settings.varianceLabel === DifferenceLabel.Relative);
    });

    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, yRangeBand);
}

function getBarAdditionalLabelProperties(dataPoints: DataPoint[], settings: ChartSettings,
    ordinalScale: d3.ScaleBand<string>, linearScale: d3.ScaleLinear<number, number>, y: number): LabelProperties[] {
    const rangeBand = ordinalScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const labelProperties = labelsDataPoints.map(d => {
        const labelValue = viewModels.calculateRelativeDifferencePercent(viewModels.getDataPointNonNullValue(d), d.reference);
        const labelText = charting.getRelativeVarianceLabel(settings, labelValue, true);
        let maxVal = Math.max((viewModels.getDataPointNonNullValue(d)), d.reference);
        if (maxVal < 0) {
            maxVal = Math.min((viewModels.getDataPointNonNullValue(d)), d.reference);
        }
        const xPos = maxVal > 0 ? linearScale(maxVal) + 3 : linearScale(maxVal) - 5;
        const yPos = ordinalScale(d.category) + rangeBand;
        const alignment: LabelAlignment = maxVal < 0 ? END : START;
        return charting.getLabelProperty(labelText, xPos, yPos, null, labelValue, d, settings.labelFontSize, settings.labelFontFamily, true, null, null, alignment, true);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, rangeBand);
}

function getReferenceLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    ordinalScale: d3.ScaleBand<string>, linearScale: d3.ScaleLinear<number, number>): LabelProperties[] {
    const categoryRangeBand = ordinalScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const labelProperties = labelsDataPoints.map(d => {
        const value = d.reference;
        const labelText = charting.getFormattedDataLabel(value, settings.decimalPlaces, settings.displayUnits, settings.locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        const xPos = linearScale(value) + (value > 0 ? 5 : -5);
        const yPos = ordinalScale(d.category) + ordinalScale.bandwidth() / 2 + settings.labelFontSize * 0.35;
        const alignment: LabelAlignment = value > 0 ? START : END;
        return charting.getLabelProperty(labelText, xPos, yPos, null, value, d, settings.labelFontSize, settings.labelFontFamily, null, null, null, alignment);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, categoryRangeBand);
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], linearScale: d3.ScaleLinear<number, number>, ordinalScale: d3.ScaleBand<string>, settings: ChartSettings, rightMargin: number) {
    let labelsMargin = rightMargin;
    const scaleBandWidth = ordinalScale.bandwidth();
    const markerAttrs = charting.getCommentMarkerAttributes(scaleBandWidth);
    labelsMargin = labelsMargin - 2 * markerAttrs.radius - markerAttrs.margin;
    const getMarkerYPosition = (d: DataPoint): number => ordinalScale(d.category) + scaleBandWidth / 2;
    const getMarkerXPosition = (d: DataPoint): number => linearScale(Math.max(viewModels.getDataPointNonNullValue(d), d.reference)) + labelsMargin + markerAttrs.radius + markerAttrs.margin;
    charting.addCommentMarkers(container, commentDataPoints, getMarkerXPosition, getMarkerYPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}
