import React from "react";
import useStore from "../../useStore";
import { SharePointSite } from "../../ExcelDataLinking.types";
import folderIcon from "../images/folder.svg";

const SitesListing = React.memo(({ sites, onSiteClick }) => {
  const [activeSiteId] = useStore("activeSiteId");

  const isActive = (site: SharePointSite) => site.id === activeSiteId;

  return (
    <div className="sites-listing">
      <h3 className="text-sm">SharePoint:</h3>
      <ul>
        {sites.length > 0 ? (
          sites.map((site) => (
            <li key={site.id}>
              <button
                className={isActive(site) ? "active" : ""}
                onClick={() => {
                  onSiteClick(site);
                }}
              >
                <img src={folderIcon} alt={site.displayName} />
                {site.displayName}
              </button>
            </li>
          ))
        ) : (
          <p>No Sharepoint sites found</p>
        )}
      </ul>
    </div>
  );
});

export default SitesListing;
