import React, { useState, useEffect } from "react";
import WorkbooksAndWorksheetsListing from "./WorkbooksAndWorksheetsListing";
import RangeSelection from "./RangeSelection";
import CloseExcelDataLinking from "../components/CloseExcelDataLinking";
import BackToFileSelection from "../components/BackToFileSelection";
import { SharePointWorkbook, SharePointWorksheet } from "../../ExcelDataLinking.types";
import SharepointBrowserAPI from "@zebrabi/sharepoint-api-browser/SharepointBrowserAPI";
import useStore from "../../useStore";

export default function DataSelection() {
  const [accessToken] = useStore("accessToken");
  const [activeDriveId] = useStore("activeDriveId");
  const [activeFileId] = useStore("activeFileId");
  const [loading, setLoading] = useState(false);

  const [worksheets, setWorksheets] = useState<SharePointWorksheet[]>([]);
  const [workbooks, setWorkbooks] = useState<SharePointWorkbook[]>([]);
  const browser = SharepointBrowserAPI.createInstance(accessToken);

  useEffect(() => {
    setLoading(true);

    Promise.all([
      browser.getWorkbookTables<SharePointWorkbook[]>(activeDriveId, activeFileId).then((res) => {
        return setWorkbooks(res.value);
      }),
      browser.getWorksheets<SharePointWorksheet[]>(activeDriveId, activeFileId).then((res) => {
        return setWorksheets(res.value);
      }),
    ]).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="data-selection">
      <div className="flex justify-between" style={{ minHeight: "28px" }}>
        <div className="flex">
          <BackToFileSelection />

          <RangeSelection />
        </div>

        <CloseExcelDataLinking />
      </div>

      {loading && <div className="loading-text">Loading...</div>}
      {!loading && <WorkbooksAndWorksheetsListing workbooks={workbooks} worksheets={worksheets} />}
    </div>
  );
}
