import GlobalToolbarObserver from "@zebrabi/global-toolbar/dist/lib/observers/GlobalToolbarObserver";

/**
 * Type defined observer used to isolate the notifications
 */
abstract class ChartSettingsSwitcherObserver extends GlobalToolbarObserver {
    static readonly CLASS_NAME = `ChartSettingsSwitcherObserver`;

    getClassName(): string {
        return ChartSettingsSwitcherObserver.CLASS_NAME;
    }

    /**
     * Triggered on general actions (open, close, save, etc.)
     * @param action
     * @param message
     */
    action(action: string, message: string): void {return;}
    update: (message: Map<string, any>) => void;
}

export default ChartSettingsSwitcherObserver;
