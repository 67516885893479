import { useState, useEffect } from "react";
import { ExcelDataLinkingStore, ExcelDataLinkingStoreData } from "./store";

export default function useStore<T extends keyof ExcelDataLinkingStoreData>(
  key: T
): [ExcelDataLinkingStoreData[T], (value: ExcelDataLinkingStoreData[T]) => void] {
  const [state, setState] = useState<T>(ExcelDataLinkingStore.get()[key]);

  useEffect(() => ExcelDataLinkingStore.subscribe((value) => setState(value[key])), []);

  const setStoreState = (value: ExcelDataLinkingStoreData[T]) => ExcelDataLinkingStore.set({ [key]: value });

  return [state, setStoreState];
}
