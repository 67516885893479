import { dataLinkingEventBus } from "@zebrabi/excel-data-linking/DataLinkingEventBus";
import { ToolbarOptions } from "@zebrabi/global-toolbar-old/interface/ToolbarOption";
import { mountTableDataEditor, parseChartData, setXSpreadSheetData, VisualType } from "@zebrabi/table-data-editor";
import { updateSpreadsheet } from "@zebrabi/table-data-editor/x-spreadsheet";
import GlobalToolbar from "@zebrabi/global-toolbar/dist/lib/components/GlobalToolbar";
import { BLOCK, CLICK, NONE } from "../../library/constants";
import ExcelDataLinkingSwitcher from '@zebrabi/excel-data-linking/ExcelDataLinkingSwitcher';
import BaseSwitcherWithHeaderOld from "./BaseSwitcherWithHeaderOld";


class DataTableEditorSwitcher extends BaseSwitcherWithHeaderOld {
    static readonly CLASS_NAME = "DataTableSwitcher";
    private styleClassName: string = "xspreadsheet-container";

    private excelDataLinking = new ExcelDataLinkingSwitcher();

    constructor() {
        super();

        const eventObserver = {
            event: (event: string, rows: any) => {
                setXSpreadSheetData(parseChartData(rows));
                updateSpreadsheet(rows);
            }
        }
        dataLinkingEventBus.subscribe(eventObserver);
    }

    toolbarOptions: ToolbarOptions = {
        actions: [],
        elementName: "Data Table",
        icon: "data-table-editor-base64",
        type: "button"
    };

    buttonAction(action: string, message: string): void {
        return;
    }

    public getClassName(): string {
        return DataTableEditorSwitcher.CLASS_NAME;
    }

    public getStyleClassName(): string {
        return this.styleClassName;
    }

    update(message: Map<string, any>): void {
        if (message.has("viewer")) {
            if (message.get("viewer") == "true") {
                this.switcherContainer.style.display = NONE;
            } else {
                this.switcherContainer.style.display = BLOCK;
            }
        }
        return;
    }

    stopPropagation = (event) => event.stopPropagation();

    close() {
        super.close();
        const target: HTMLElement = document.querySelector(`.${this.styleClassName}`);
        target?.classList.remove("active");
    }

    open() {
        super.open();
        const target: HTMLElement = document.querySelector(`.${this.styleClassName}`);
        target?.classList.add("active");
    }

    /**
     * @override BaseSwitcher action method to implement custom open/close behaviour
     * @param action
     * @param message
     */
    action(action: string, message: string) {
        switch (action) { // add an action here if you want to for example handle a specific action, like define a close action specifically instead of a toggle
            case GlobalToolbar.TOOLBAR_CLOSE:
                this.close();
                break;
            case GlobalToolbar.TOOLBAR_GROUP_CLICK: // toggle the toolbar group active/inactive
                if (message !== this.getClassName()) return; // we are only interested on click on self
                this.open();
                break;
        }
    }

    async createMenuHeader(switcherMenuContainer: HTMLElement) {
        super.createMenuHeader(switcherMenuContainer);

        const titleNode: HTMLDivElement = switcherMenuContainer.querySelector(".title");
        this.excelDataLinking.appendElementsToHtml(titleNode);
    }

    createMenuItems(switcherMenuContainer: HTMLElement, active: string = "") {
        const target: HTMLElement = document.querySelector(`.${this.styleClassName}`);

        if (!target) {
            const target = document.createElement("div");
            target.classList.add(this.styleClassName);
            this.createMenuHeader(target);

            const sheet = document.createElement("div");
            target.appendChild(sheet);
            target.addEventListener(CLICK, e => e.stopPropagation());

            document.body.appendChild(target);
            mountTableDataEditor({ visualType: VisualType.CHARTS }, sheet);
        }

        this.excelDataLinking.updateButtonsStates();
    }

    getScrollPosition(): number {
        return 0;
    }
}

export default DataTableEditorSwitcher;
