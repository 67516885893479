import Layout from "./Layout";
import { ChartData, ChartLayout } from "./../interfaces";

export class LargestFirstLayout extends Layout {

    public getChartLayouts(rowCount: number, maxChartsPerRow: number, height: number, visualTitleHeight: number, minChartHeight: number, minChartWidth: number,
        plotChartWidth: number, bottomMargin: number, topMargin: number, rowMargin: number, columnMargin: number, labelFontSize: number, xPosition: number, leftMargin: number): ChartLayout[] {

        const diffArray = this.viewModel.chartData.map((a) => {
            return a.max - a.min;
        });
        const margin = topMargin + bottomMargin;
        const columnsHeights: number[] = [];
        const columnChartCount: number[] = [];

        for (let i = 0; i < maxChartsPerRow; i++) {
            columnsHeights.push(visualTitleHeight);
            columnChartCount.push(0);
        }

        const scaleFactor = this.calculateScaleFactor(diffArray, this.viewModel.chartData, maxChartsPerRow, height - visualTitleHeight, margin, rowMargin, minChartHeight, labelFontSize);
        const chartLayouts: ChartLayout[] = [];

        for (let i = 0; i < this.viewModel.chartData.length; i++) {
            const current = diffArray[i];
            const currentColIndex = columnsHeights.indexOf(Math.min(...columnsHeights));
            const currentHeight = current * scaleFactor + margin;
            const x = leftMargin + xPosition + currentColIndex * (plotChartWidth + columnMargin);
            let y = columnsHeights[currentColIndex];

            const min = this.viewModel.chartData[i].min;
            const max = this.viewModel.chartData[i].max;

            if (currentColIndex > 0) {
                const currentColBottom = y + currentHeight + rowMargin;
                const previousColHeight = columnsHeights[currentColIndex - 1];
                if (this.shouldMoveChartToTheBottomOfTheRow(diffArray, i, previousColHeight, currentColBottom, scaleFactor, margin)) {
                    y = columnsHeights[currentColIndex - 1] - currentHeight - rowMargin;
                }
            }

            const currentBottomMargin = bottomMargin + (min < 0 ? labelFontSize : 0);
            chartLayouts.push({
                height: currentHeight,
                width: currentColIndex === 0 ? plotChartWidth + xPosition : plotChartWidth,
                position: { x: currentColIndex === 0 ? leftMargin : x, y: y },
                columnIndex: currentColIndex,
                rowIndex: Math.floor(i / maxChartsPerRow),
                min: min,
                max: max,
                bottomMargin: currentBottomMargin,
            });
            columnsHeights[currentColIndex] = y + currentHeight + rowMargin;
            columnChartCount[currentColIndex]++;
        }

        const maxColHeight = Math.max(...columnsHeights) - rowMargin;
        for (let i = 0; i < columnsHeights.length; i++) {
            const h = columnsHeights[i];
            if (h < maxColHeight) {
                const chartsToAdjust = chartLayouts.filter(c => c.columnIndex === i);
                const chartToAdjust = chartsToAdjust[chartsToAdjust.length - 1];
                if (chartToAdjust) {
                    chartToAdjust.position.y = maxColHeight - chartToAdjust.height;
                }
            }
        }
        return chartLayouts;
    }

    private calculateScaleFactor(diffArray: number[], chartData: ChartData[], numOfColumns, height: number, chartMargin: number, rowMargin: number, minChartHeight: number, labelFontSize: number): number {
        const columnChartCount: number[] = [];
        const columnsHeightsRatios: number[] = [];
        const additionalRowBottomMargins: number[] = [];

        for (let i = 0; i < numOfColumns; i++) {
            columnChartCount.push(0);
            columnsHeightsRatios.push(0);
            additionalRowBottomMargins.push(0);
        }

        for (let i = 0; i < diffArray.length; i++) {
            const currentColIndex = columnsHeightsRatios.indexOf(Math.min(...columnsHeightsRatios));
            columnsHeightsRatios[currentColIndex] += diffArray[i];
            columnChartCount[currentColIndex]++;
            if (chartData[i].min < 0) {
                additionalRowBottomMargins[currentColIndex] += labelFontSize;
            }
        }

        const maxH = Math.max(...columnsHeightsRatios);
        const chartsInAMaxColumn = columnChartCount[columnsHeightsRatios.indexOf(maxH)];
        const totalMargins = chartMargin * chartsInAMaxColumn + rowMargin * (chartsInAMaxColumn) + Math.max(...additionalRowBottomMargins);
        let scaleFactor = (height - totalMargins) / maxH;
        let largestChartDiff = Math.max(...diffArray);

        if (largestChartDiff === 0) {
            largestChartDiff = 1;
        }
        const maxPredictedChartHeight = largestChartDiff * scaleFactor;
        if (maxPredictedChartHeight < minChartHeight) {
            scaleFactor = (minChartHeight - chartMargin) / largestChartDiff;
        }
        return scaleFactor;
    }

    private shouldMoveChartToTheBottomOfTheRow(diffArray: number[], currentIndex: number, previousColHeight: number, currentColBottom: number, scaleFactor: number, chartMargin: number): boolean {
        const nextChartHeight = diffArray[currentIndex + 1] * scaleFactor + chartMargin;
        return previousColHeight > currentColBottom && (currentIndex >= diffArray.length - 1 || previousColHeight - currentColBottom < nextChartHeight);
    }
}