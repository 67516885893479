
import { Visual } from "./../visual";
import * as d3 from "../d3";
import {
    TEXT, PX, HIDDEN, MOUSEOUT, MOUSEOVER, CLICK, HIGHLIGHTABLE, RECT, LINE, OPACITY, FONT_WEIGHT, NORMAL, CATEGORY, WIDTH, HEIGHT, POINTS, CONTEXT_MENU, CHART_GLOBAL_LEGEND_MENU, CHART_LEGEND_SETTINGS, DIFFERENCE_HIGHLIGHT_MENU
} from "./../library/constants";
import {
    SELECTION_DROPLINE
} from "./../consts";

import { ViewModel, DataPoint } from "./../interfaces";
import { ChartSettings } from "./../settings/chartSettings";
import { addTopLevelElements } from "./ui";
import { ChartType } from "../enums";

export class SliderHandler {
    public slider: HTMLElement;
    public sliderButtonRight: HTMLElement;
    // public sliderButtonLeft: HTMLElement;
    private sliderButtonsCreated: boolean;

    constructor(private parentElement: HTMLElement, private visual: Visual) {
        this.slider = document.createElement("div");
        this.slider.id = "slide1";
        this.slider.className = "slide";
        this.slider.style.left = 0 + PX;
        parentElement.appendChild(this.slider);
        this.sliderButtonsCreated = false;
    }

    public removeContent() {
        d3.selectAll(this.slider.children).remove();
    }

    public addSliderButtons() {
        const clientRect = this.parentElement.getBoundingClientRect();
        let x0 = clientRect.left + clientRect.width - 25;
        const y0 = clientRect.top + clientRect.height / 2 - 42;

        this.sliderButtonRight = this.createSliderButton(x0, y0);
        this.parentElement.appendChild(this.sliderButtonRight);

        const sliderArrowRight = d3.select(this.sliderButtonRight).append("svg")
            .attr(WIDTH, "100%")
            .attr(HEIGHT, "100%")
            .append("polyline")
            .classed("slider-arrow", true)
            .attr(POINTS, "6,13 21,33 6,53");

        x0 = clientRect.left;
        // this.sliderButtonLeft = this.createSliderButton(x0, y0);
        // this.parentElement.appendChild(this.sliderButtonLeft);

        // const sliderArrowLeft = d3.select(this.sliderButtonLeft).append("svg")
        //     .attr(WIDTH, "100%")
        //     .attr(HEIGHT, "100%")
        //     .append("polyline")
        //     .classed("slider-arrow", true)
        //     .attr(POINTS, "18,13 3,33 18,53");
        this.sliderButtonsCreated = true;
    }

    public addSliderEventHandlers(viewModel: ViewModel) {
        if (!this.sliderButtonsCreated) {
            return;
        }
        this.sliderButtonRight.onclick = () => {
            const isChanged = viewModel.settings.setNextChartType(viewModel);
            if (isChanged) {
                this.switchSlider(false, viewModel.settings.chartType === ChartType.Advert);
                this.visual.constructViewModelAndVisualUpdate(viewModel.settings);
            }
        };
        // this.sliderButtonLeft.onclick = () => {
        //     const isChanged = viewModel.settings.setPreviousChartType(viewModel);
        //     if (isChanged) {
        //         this.switchSlider(true, viewModel.settings.chartType === ChartType.Advert);
        //         this.visual.constructViewModelAndVisualUpdate(viewModel.settings);
        //     }
        //     this.addSvgEventHandlers(true);
        // };
    }

    public createSliderButton(x: number, y: number): HTMLElement {
        const button = document.createElement("div");
        button.className = "slider-button";
        button.style.left = x + "px";
        button.style.top = y + "px";
        button.style.opacity = "0";
        button.onmouseover = () => {
            button.style.opacity = "0.4";
        };
        button.onmouseout = () => {
            button.style.opacity = "0";
        };
        return button;
    }

    public hideSliderButtons() {
        if (!this.sliderButtonsCreated) {
            return;
        }
        // this.sliderButtonLeft.classList.add(HIDDEN);
        this.sliderButtonRight.classList.add(HIDDEN);
    }

    public showSliderButtons() {
        if (!this.sliderButtonsCreated) {
            return;
        }
        // this.sliderButtonLeft.classList.remove(HIDDEN);
        this.sliderButtonRight.classList.remove(HIDDEN);
    }

    public switchSlider(isTransitionLeft: boolean, isAdvert?: boolean) {
        const width = Visual.visualViewPort.width;
        const directionFactor = isTransitionLeft ? 1 : -1;
        const newSlideId = this.slider.id === "slide1" ? "slide2" : "slide1";
        const currentSlide = document.getElementById(this.slider.id);
        const commentSettings: HTMLElement = document.querySelector(".comment-settings");
        const infoContainer: HTMLElement = currentSlide.querySelector(".zebrabi-info-container");
        if (infoContainer) {
            infoContainer.parentNode.removeChild(infoContainer);
        }
        if (commentSettings) {
            commentSettings.parentNode.removeChild(commentSettings);
        }
        const legendSettings: HTMLElement = currentSlide.querySelector(`.${CHART_LEGEND_SETTINGS}`);
        if (legendSettings) {
            legendSettings.parentNode.removeChild(legendSettings);
        }
        d3.selectAll(`.${CHART_GLOBAL_LEGEND_MENU}`)?.remove();
        d3.select(`.${DIFFERENCE_HIGHLIGHT_MENU}`)?.remove();
        currentSlide.style.transition = "200ms";
        const parentEl = currentSlide.parentElement;

        currentSlide.addEventListener("transitionend", () => {
            parentEl.removeChild(currentSlide);
        });

        const newSlide = document.createElement("div");
        newSlide.id = newSlideId;
        newSlide.className = "slide";
        newSlide.style.transition = "200ms";

        addTopLevelElements(this.visual, newSlide, isAdvert);
        this.addSvgEventHandlers(true);

        parentEl.insertBefore(newSlide, currentSlide);

        currentSlide.style.left = (width * directionFactor) + PX;
        newSlide.style.left = (-width * directionFactor) + PX;

        this.slider = newSlide;
        window.setTimeout(() => {
            newSlide.style.transition = "0ms";
            const commentBoudingBox = this.slider.querySelector(".zebrabi-info-container")?.getBoundingClientRect();
            const commentSettings: HTMLElement = document.querySelector(".comment-settings");
            if (commentSettings && commentBoudingBox) {
                commentSettings.style.left = `${commentBoudingBox.left + 5}px`;
            }
        }, 600);
    }

    public addSvgEventHandlers(setSliderButtonsOpacityHandlers: boolean) {
        if (setSliderButtonsOpacityHandlers) {
            this.visual.svg.on(MOUSEOUT, () => {
                this.sliderButtonRight.style.opacity = "0";
                // this.sliderButtonLeft.style.opacity = "0";
            });
            this.visual.svg.on(MOUSEOVER, () => {
                this.sliderButtonRight.style.opacity = "0.2";
                // this.sliderButtonLeft.style.opacity = "0.2";
            });
        }

        // let selectionManager = this.visual.selectionManager;
        // this.visual.svg.on(CLICK, () => {
        //     if (selectionManager.hasSelection()) {
        //         selectionManager.clear();
        //         d3.selectAll(RECT + "." + HIGHLIGHTABLE)
        //             .attr(OPACITY, 1);
        //         d3.selectAll(TEXT + "." + HIGHLIGHTABLE).attr(FONT_WEIGHT, NORMAL);
        //         d3.selectAll(LINE + "." + SELECTION_DROPLINE).remove();
        //     }
        // });

        // handle context menu
        // this.visual.svg.on('contextmenu', () => {
        //     const mouseEvent: MouseEvent = <MouseEvent>d3.event;
        //     const eventTarget: EventTarget = mouseEvent.target;
        //     let dataPoint = <DataPoint>d3.select(<SVGElement>eventTarget).datum();
        //     if (dataPoint && dataPoint.selectionId && !d3.select(<SVGElement>eventTarget).classed(CATEGORY)) {
        //         selectionManager.showContextMenu(dataPoint ? dataPoint.selectionId : {}, {
        //             x: mouseEvent.clientX,
        //             y: mouseEvent.clientY,
        //         });
        //     }
        //     mouseEvent.preventDefault();
        // });
    }

    public refreshUIelements(width: number, parentElement: HTMLElement, settings: ChartSettings, height: number) {

        if (settings != null && settings.getRealInteractionSettingValue(settings.showChartSlider)) {
            this.sliderButtonRight.style.left = (width - 25) + PX;
            // this.sliderButtonLeft.style.left = 0 + PX;
            this.sliderButtonRight.style.top = (parentElement.clientHeight / 2 - 42) + PX;
            // this.sliderButtonLeft.style.top = this.sliderButtonRight.style.top;
        }

        this.slider.style.left = `0${PX}`;
        // this.slider.style.top = `4${PX}`;
        //this.slider.style.width = options.viewport.width + PX;
        this.slider.style.width = parentElement.clientWidth + PX;
        this.slider.style.height = height + PX;

        Visual.animateDiffHighlightLabel = false;
        Visual.animateVarianceLabels = false;
    }
}