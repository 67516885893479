import { ColorScheme } from "./interfaces";
import {
    ChartStyle, Scenario, MarkerStyle,
    DESIGN_SETTINGS, BLACK, WHITE, GRIDLINE_COLOR, MAJOR_GRIDLINE_COLOR, FILL, PATH, STROKE, STROKE_DASHARRAY, STROKE_WIDTH, STROKE_LINECAP, STROKE_LINEJOIN,
    PY, PL, FC, NEUTRAL, DASHED_DASHARRAY, DOTTED_DASHARRAY, ROUND, POSITIVE, NEGATIVE, MARKER, NONE, FILL_OPACITY,
    CUSTOMER_POSITIVE_COLOR, CUSTOMER_NEGATIVE_COLOR, CUSTOMER_NEUTRAL_COLOR, CUSTOMER_MARKER_COLOR, CUSTOMER_LINE_COLOR, CUSTOMER_AXIS_COLOR, CUSTOMER_GRIDLINE_COLOR,
    CUSTOMER_DOT_CHART_COLOR, CUSTOMER_MORE_COLORS, CUSTOMER_PY_COLOR, CUSTOMER_PL_COLOR, CUSTOMER_FC_COLOR, CUSTOMER_APPLY_PATTERNS, HIGHLIGHT_COLOR
} from "./constants";

import * as d3 from "../d3";

// eslint-disable-next-line max-lines-per-function
export function getColorSchemeFromStyle(chartStyle: ChartStyle): ColorScheme {
    switch (chartStyle) {
        case ChartStyle.Zebra:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.ZebraLight:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#DEDDC8",
                markerColor: "#404040",
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.DrHichert:
            return {
                positiveColor: "#8CB400",
                negativeColor: "#FF0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#b0b0b0",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.PowerBI:
            return {
                positiveColor: "#01B8AA",
                negativeColor: "#FD625E",
                neutralColor: "#374649",
                markerColor: BLACK,
                lineColor: "#374649",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.ColorblindSafe:
            return {
                positiveColor: "#6D97FF",
                negativeColor: "#000000",
                neutralColor: "#CCCCCC",
                markerColor: "#404040",
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.Company:
            return {
                positiveColor: CUSTOMER_POSITIVE_COLOR,
                negativeColor: CUSTOMER_NEGATIVE_COLOR,
                neutralColor: CUSTOMER_NEUTRAL_COLOR,
                markerColor: CUSTOMER_MARKER_COLOR,
                lineColor: CUSTOMER_LINE_COLOR,
                axisColor: CUSTOMER_AXIS_COLOR,
                gridlineColor: CUSTOMER_GRIDLINE_COLOR,
                majorGridlineColor: "#999",
                dotChartColor: CUSTOMER_DOT_CHART_COLOR,
                useCustomScenarioColors: CUSTOMER_MORE_COLORS.toString() === "true",
                previousYearColor: CUSTOMER_PY_COLOR,
                planColor: CUSTOMER_PL_COLOR,
                forecastColor: CUSTOMER_FC_COLOR,
                applyPatterns: CUSTOMER_APPLY_PATTERNS.toString() !== "false",
                highlightColor: HIGHLIGHT_COLOR,
            };
        default:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
    }
}

export function getLighterColor(hex: string): string {
    const color = getColorFromHex(hex);
    const brightness = getBrightness(color);
    const factor = 0.1 + Math.pow((255 - brightness) / 255, 2) * 0.4;
    const lighterColor: Color = {
        r: Math.round(Math.min(255, color.r + 255 * factor)),
        g: Math.round(Math.min(255, color.g + 255 * factor)),
        b: Math.round(Math.min(255, color.b + 255 * factor)),
    };
    return colorToHex(lighterColor);
}

export function getLabelColor(hex: string): string {
    const color = getColorFromHex(hex);
    const brightness = getBrightness(color);
    return brightness < 129 ? WHITE : BLACK;
}

function getColorFromHex(hex: string): Color {
    let result = [];
    if (!hex) {
        hex = BLACK;
    }

    if (hex?.length === 4 || hex?.length === 5) {
        result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})?$/i.exec(hex);
    }
    else {
        result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})*$/i.exec(hex);
    }

    if (!result) {
        return { r: 0, g: 0, b: 0 };
    }
    return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    };
}

function getBrightness(color: Color): number {
    return Math.sqrt(color.r * color.r * 0.241 + color.g * color.g * 0.691 + color.b * color.b * 0.068);
}

function colorToHex(color: Color): string {
    return "#" + componentToHex(color.r) + componentToHex(color.g) + componentToHex(color.b);
}

function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export class Color {
    r: number;
    g: number;
    b: number;
}

export function getVarianceColor(invert: boolean, value: number, scheme: ColorScheme): string {
    if (value < 0) {
        if (invert) {
            return scheme.positiveColor;
        }
        return scheme.negativeColor;
    }
    else {
        if (invert) {
            return scheme.negativeColor;
        }
        return scheme.positiveColor;
    }
}

export function applyToBars(element: d3.Selection<any, any, any, any>, colorGetter: (d: any) => string, scenario: Scenario, style: ChartStyle, isVariance: boolean, colorScheme: ColorScheme) {
    applyTo(element, colorGetter, scenario, style, isVariance, colorScheme, 1, false);
}

export function applyToLines(element: d3.Selection<any, any, any, any>, colorGetter: (d: any) => string, scenario: Scenario, style: ChartStyle, isVariance: boolean, colorScheme: ColorScheme, isReferenceScenario: boolean) {
    let strokeWidth = 0;
    if (scenario === Scenario.Plan) {
        strokeWidth = isReferenceScenario ? 1 : 2;
    }
    else if (scenario === Scenario.Forecast) {
        strokeWidth = 1;
    }
    applyTo(element, colorGetter, scenario, style, isVariance, colorScheme, strokeWidth, true);
}

function applyTo(element: d3.Selection<any, any, any, any>, colorGetter: (d: any) => string, scenario: Scenario, style: ChartStyle, isVariance: boolean, colorScheme: ColorScheme, strokeWidth: number, useTargetFill: boolean) {
    element.attr(STROKE_WIDTH, 0);
    if (scenario === Scenario.Actual) {
        element.attr(FILL, d => colorGetter(d));
    }
    else if (scenario === Scenario.Plan) {
        if (colorScheme.useCustomScenarioColors && !isVariance) {
            const plColor = (d: any) => d.isHighlighted ? colorGetter(d) : colorScheme.planColor;
            element.attr(FILL, d => plColor(d));
            element.attr(FILL_OPACITY, 1);
            if (colorScheme.applyPatterns) {
                element.attr(FILL_OPACITY, 0)
                    .attr(STROKE_WIDTH, 1)
                    .attr(STROKE, d => plColor(d));
                if (useTargetFill) {
                    element.attr(FILL, WHITE);
                }
                else {
                    element.attr(FILL_OPACITY, 0.1);
                }
            }
        }
        else {
            element
                .attr(FILL, d => colorGetter(d))
                .attr(STROKE_WIDTH, strokeWidth)
                .attr(STROKE, d => colorGetter(d));
            if (useTargetFill) {
                element.attr(FILL, WHITE);
            }
            else {
                element.attr(FILL_OPACITY, style === ChartStyle.DrHichert ? 0 : 0.1);
            }
        }
    }
    else if (scenario === Scenario.PreviousYear) {
        element.attr(FILL, d => {
            if (colorScheme.useCustomScenarioColors && !isVariance && !d.isHighlighted) {
                return colorScheme.applyPatterns ? getLighterColor(colorScheme.previousYearColor) : colorScheme.previousYearColor;
            }
            else {
                // Variances should not be lightened
                if (isVariance) {
                    return colorGetter(d);
                }
                else {
                    return getLighterColor(colorGetter(d));
                }
            }
        });
    }
    else if (scenario === Scenario.Forecast) {
        const fcColor = (d: any) => colorScheme.useCustomScenarioColors && !isVariance && !d.isHighlighted ? colorScheme.forecastColor : colorGetter(d);
        element.attr(FILL, d => {
            if (colorScheme.useCustomScenarioColors && !colorScheme.applyPatterns) {
                return fcColor(d);
            }
            else {
                return `url(#diagonal-stripe-${getPatternFromColor(fcColor(d), colorScheme)})`;
            }
        });
        if (strokeWidth !== 0) {
            element
                .attr(STROKE_WIDTH, strokeWidth)
                .attr(STROKE, d => fcColor(d));
        }
    }
}

export function applyToElement(element: d3.Selection<SVGElement, any, any, any>, color: string, scenario: Scenario, style: ChartStyle, colorScheme: ColorScheme) {
    const isVariance = color === colorScheme.positiveColor || color === colorScheme.negativeColor;
    if (scenario === Scenario.Actual) {
        element.attr(FILL, color);
    }
    else if (scenario === Scenario.Plan) {
        if (colorScheme.useCustomScenarioColors && !isVariance) {
            element.attr(FILL, colorScheme.planColor);
            if (colorScheme.applyPatterns) {
                element
                    .attr(FILL_OPACITY, 0)
                    .attr(STROKE_WIDTH, 1)
                    .attr(STROKE, colorScheme.planColor);
            }
        }
        else {
            element
                .attr(FILL, color)
                .attr(FILL_OPACITY, style === ChartStyle.DrHichert ? 0 : 0.1)
                .attr(FILL_OPACITY, 0)
                .attr(STROKE_WIDTH, 1)
                .attr(STROKE, color);
        }
    }
    else if (scenario === Scenario.PreviousYear) {
        if (colorScheme.useCustomScenarioColors && !isVariance) {
            element.attr(FILL, colorScheme.applyPatterns ? getLighterColor(colorScheme.previousYearColor) : colorScheme.previousYearColor);
        }
        else {
            // Variances should not be lightened
            if (isVariance) {
                element.attr(FILL, color);
            }
            else {
                element.attr(FILL, getLighterColor(color));
            }
        }
    }
    else if (scenario === Scenario.Forecast) {
        const fcColor = colorScheme.useCustomScenarioColors && !isVariance ? colorScheme.forecastColor : color;
        if (colorScheme.useCustomScenarioColors && !colorScheme.applyPatterns) {
            element.attr(FILL, fcColor);
        }
        else {
            element.attr(FILL, `url(#diagonal-stripe-${getPatternFromColor(fcColor, colorScheme)})`);
        }
    }
}

export function applyMarkerStyleToElement(element: d3.Selection<SVGElement, any, any, any>, color: string, markerStyle: MarkerStyle, style: ChartStyle, colorScheme: ColorScheme) {
    if (markerStyle === MarkerStyle.Actual || markerStyle === MarkerStyle.Plan || markerStyle === MarkerStyle.PreviousYear || markerStyle === MarkerStyle.Forecast) {
        applyToElement(element, color, <Scenario>(<number>markerStyle), style, colorScheme);
    }
    else if (markerStyle === MarkerStyle.Gridline) {
        element.attr(FILL, color);
    }
    else if (markerStyle === MarkerStyle.None) {
        element.attr(FILL, NONE);
    }
}

export function getPatternFromColor(color: string, colorScheme: ColorScheme): string {
    if (color === colorScheme.neutralColor) {
        return NEUTRAL;
    }
    else if (color === colorScheme.positiveColor) {
        return POSITIVE;
    }
    else if (color === colorScheme.negativeColor) {
        return NEGATIVE;
    }
    else if (color === colorScheme.markerColor) {
        return MARKER;
    }
    else if (color === colorScheme.forecastColor) {
        return FC;
    }
    else if (color === colorScheme.planColor) {
        return PL;
    }
    else if (color === colorScheme.previousYearColor) {
        return PY;
    }
    else if (color === colorScheme.highlightColor) {
        return HIGHLIGHT_COLOR;
    }
    else {
        return color;
    }
}

export function drawLine(container: d3.Selection<SVGElement, any, any, any>, fill: string, color: string, strokeLinejoin: string, scenario: Scenario,
    isReferenceScenario: boolean, style: ChartStyle, colorScheme: ColorScheme): d3.Selection<SVGElement, any, any, any> {
    let strokeWidth = isReferenceScenario ? 2 : 3;
    const line = container.append(PATH)
        .attr(FILL, fill)
        .attr(STROKE_LINEJOIN, strokeLinejoin)
        .attr(STROKE_LINECAP, strokeLinejoin);
    if (style === ChartStyle.DrHichert) {
        line.attr(STROKE, scenario === Scenario.PreviousYear ? getLighterColor(color) : color);
        line.attr(STROKE_WIDTH, 2);
    }
    else if (style === ChartStyle.Custom && colorScheme.useCustomScenarioColors) {
        if (scenario === Scenario.Actual) {
            line.attr(STROKE, color);
        }
        else if (scenario === Scenario.PreviousYear) {
            line.attr(STROKE, colorScheme.applyPatterns ? getLighterColor(colorScheme.previousYearColor) : colorScheme.previousYearColor);
        }
        else if (scenario === Scenario.Plan) {
            line.attr(STROKE, colorScheme.planColor);
            if (colorScheme.applyPatterns) {
                strokeWidth--;
                line.style(STROKE_DASHARRAY, DASHED_DASHARRAY);
            }
        }
        else if (scenario === Scenario.Forecast) {
            line.attr(STROKE, colorScheme.forecastColor);
            if (colorScheme.applyPatterns) {
                line.style(STROKE_LINECAP, ROUND);
                line.style(STROKE_DASHARRAY, DOTTED_DASHARRAY);
            }
        }
        else {
            line.attr(STROKE, color);
        }
        line.attr(STROKE_WIDTH, strokeWidth);
    }
    else {
        switch (scenario) {
            case Scenario.Actual:
                line.attr(STROKE, color);
                break;
            case Scenario.Plan:
                strokeWidth--;
                line.attr(STROKE, color);
                line.style(STROKE_DASHARRAY, DASHED_DASHARRAY);
                break;
            case Scenario.Forecast:
                line.attr(STROKE, color);
                line.style(STROKE_LINECAP, ROUND);
                line.style(STROKE_DASHARRAY, DOTTED_DASHARRAY);
                break;
            case Scenario.PreviousYear:
                line.attr(STROKE, getLighterColor(color));
                break;
        }
        line.attr(STROKE_WIDTH, strokeWidth);
    }
    return line;
}