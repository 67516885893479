import { ToolbarOptions } from "@zebrabi/global-toolbar-old/interface/ToolbarOption";
import BaseSwitcherWithHeaderOld from "./BaseSwitcherWithHeaderOld";

class StackedChartSwitcher extends BaseSwitcherWithHeaderOld {
    static readonly CLASS_NAME = "StackedChartSwitcher";

    toolbarOptions: ToolbarOptions = {
        actions: [],
        elementName: "Stacked Chart Switcher",
        icon: "stacked-chart-switcher-stacked-base64",
        altIcon: "stacked-chart-switcher-multiples-base64",
        type: "button"
    };

    public getClassName(): string {
        return StackedChartSwitcher.CLASS_NAME;
    }

    buttonAction(action: string, message: string): void {
        return ;
    }

    update(message: Map<string, any>): void {
        return;
    }

    createMenuItems(switcherMenuContainer: HTMLElement, active: string = "") {
        // sidebar content appends to switcherMenuContainer
    }

    getScrollPosition(): number {
        return 0;
    }

    createOptions(useAltIcon?: boolean, overrideUseAltIcon?: boolean): HTMLElement {
        useAltIcon = overrideUseAltIcon;
        return super.createOptions(useAltIcon, overrideUseAltIcon);
    }
}

export default StackedChartSwitcher;
