export enum ChartType {
    Waterfall,
    Area,
    Bar,
    Variance,
    Line,
    PlusMinus,
    PlusMinusDot,
    Pin,
    Advert, // TODO: Remove, refactor Ad Slide on Office & update in core as well
}

export enum LabelDensity {
    Full,
    None,
    Last,
    FirstLast,
    MinMax,
    FirstLastMinMax,
    Auto,
    Low,
    Medium,
    High,
}

export enum MarkerSize {
    Auto,
    Fixed
}

export enum MarkerType {
    Start,
    Mid,
    End,
}

export enum DifferenceHighlightFromTo {
    Auto,
    FirstToLast,
    PenultimateToLast,
    LastToCorresponding,
    FirstToLastFC,
    PenultimateToLastFC,
    LastToCorrespondingFC,
    FirstToLastPL,
    PenultimateToLastPL,
    LastToCorrespondingPL,
    LastACToLastFC,
    MinToMax,
}

export enum Sort {
    None,
    Ascending,
    Descending,
}

export enum ShowTopNChartsOptions {
    Off,
    Items,
    Percentage,
}

export enum GridlineStyle {
    Solid,
    Dotted,
    Dashed,
}

export enum NegativeValuesFormat {
    Default,
    Parenthesis,
}

export enum DifferenceHighlightArrowStyle {
    ClosedArrow,
    RoundArrow,
    Dots,
    SingleDot,
    None,
    OpenArrow,
}

export enum AxisLabelDensity {
    All,
    FirstLast,
    EveryNth
}

export enum MultiplesAxisLabelsOptions {
    AllCharts,
    FirstChart,
    FirstRow,
    LastRow,
    FirstChartLastRow,
}

export enum ReferenceDisplayType {
    None,
    OverlappedColumn,
    Triangles,
}

export enum DotChartMarkerShape {
    Circle,
    Square,
    Diamond,
    Triangle
}

export enum ComboChartUnderlyingChart {
    plotChart,
    waterfallChart,
    columnChart,
    varianceChart,
    plusMinusChart,
    plusMinusDotChart,
}

export enum DifferenceHighlightEllipse {
    Off,
    Ellipse,
    Circle
}

// Use this enum to iterate through all possible settings when doing a menu reset
export enum GlobalCategorySettings {
    verticalCategoriesDisplay,
    topNOtherLabel,
    showTopNCategories,
    topNCategoriesToKeep,
    axisLabelDensityEveryNthLabel,
    axisLabelDensity,
    minWidth,
    categoriesFontSize,
    categoryWidth,
    categoriesFontColor,
    categoriesFontFamily,
    categoryLabelsOptions,
    gapBetweenColumnsPercent,
    showCategoriesFontSettings
}

export enum GlobalStackedChartSettings {
    showTopNStackedOptions,
    topNStackedToKeep,
    topNStackedPercentage,
    topNStackedOthersLabel,
    stackedAreaOpacity,
    showStackedLabelsAsPercentage,
    stackedChartSort
}

export enum DifferenceHighlightSettings {
    differenceLabelType,
    differenceHighlightFromTo,
    differenceHighlightLineWidth,
    differenceHighlightArrowStyle,
    differenceHighlightConnectingLineStyle,
    differenceHighlightEllipse,
    differenceHighlightEllipseBorderWidth,
    differenceHighlightEllipseBorderPadding,
    differenceHighlightEllipseFillOpacity,
    differenceHighlightMargin,
    differenceHighlightConnectingLineColor
}

export enum DifferenceHighlightOption {
    // order is intentionally like this, because this is the order that we want in Difference highlight options
    Auto = 0,
    FirstToLast = 1,
    PenultimateToLast = 2,
    LastToCorresponding = 3,
    LastACtoLastFC = 10,
    FirstToLastFC = 4,
    PenultimateToLastFC = 5,
    LastFCtoCorresponding = 6,
    FirstToLastPL = 7,
    PenultimateToLastPL = 8,
    LastPLtoCorresponding = 9,
    MinToMax = 11
}

export enum SidebarChartSettings {
    invert,
    chartLayout,
    showGrandTotal,
    grandTotalLabel,
    limitOutliers,
    maxOutlierValue,
    minOutlierValue,
    referenceDisplayType,
    showAllForecastData,
    currentPeriodVarianceOptions,
    currentPeriodVarianceCondition,
    dayInMonthVarianceCondition,
    dayInWeekVarianceCondition,
    monthInQuarterVarianceCondition,
    monthInYearVarianceCondition,
    showVerticalCharts,
    handleNullsAsZeros
}

export enum DataLabelsUnits {
    Auto = "Auto",
    None = "None",
    Thousands = "K",
    Millions = "M",
    Billions = "G",
    Percentage = "P"
}

export enum DataLabelSetting {
    show = "showDataLabels",
    labelFontColor = "labelFontColor",
    displayUnits = "displayUnits",
    decimalPlaces = "decimalPlaces",
    decimalPlacesPercentage = "decimalPlacesPercentage",
    showPercentageInLabel = "showPercentageInLabel",
    negativeValuesFormat = "negativeValuesFormat",
    labelDensity = "labelDensity",
    labelPercentagePointUnit = "labelPercentagePointUnit",
    labelFontSize = "labelFontSize",
    labelFontFamily = "labelFontFamily",
    labelBackgroundTransparency = "labelBackgroundTransparency"
}

export enum DataLabelMenuItem {
    fontSettings,
    decimalPlaces,
    units,
    showPercentageInLabel,
    negativeValuesFormat,
    labelDensity,
    labelPercentagePointUnit,
    backgroundTransparency,
    showReferenceLabels
}
