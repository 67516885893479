import { v4 as UUIDv4 } from "uuid";
import { GlobalStackedChartMenu } from "../components/GlobalStackedChartMenu/GlobalStackedChartMenu";
import * as d3 from "../d3";
import { ChartType } from "../enums";
import { repositionElementInsideViewPort } from "../helpers";
import { OutlineDimensions } from "../interfaces";
import {
    ACTIVE, BORDER_RADIUS, CLICK, CONTEXT_MENU2_DIV, FILL, FIXED, GLOBAL_STACKED_CHART, HEIGHT, ID, MOUSEENTER,
    MOUSELEAVE, OUTLINE_BORDER_RADIUS, OUTLINE_COLOR, RECT, RX, STROKE, TRANSPARENT, WIDTH, X, Y
} from "../library/constants";
import { Visual } from "../visual";

const hasChartTypeChanged = makeChartTypeChecker();

function makeChartTypeChecker(): (currentChartType: ChartType) => boolean {
    let previousChartType = null;

    return function(currentChartType: ChartType): boolean {
        if (previousChartType === null) {
            previousChartType = currentChartType;
            return false;
        }

        if (currentChartType !== previousChartType) {
            previousChartType = currentChartType;
            return true;
        }

        return false;
    };
}

export function showGlobalOverlayOnStackedChartLegendHover(container: d3.Selection<SVGElement, any, any, any>, isVertical: boolean): void {
    if (hasChartTypeChanged(Visual.settings.chartType)) {
        setTimeout(() => {
            drawGlobalStackedChartOutline(container, isVertical, getOutlineDimensions(isVertical));
        }, 300);
    } else {
        const outlineDimensions = getOutlineDimensions(isVertical);
        removeExistingStackedChartMenu(isVertical);
        drawGlobalStackedChartOutline(container, isVertical, outlineDimensions);
    }
}

function getOutlineDimensions(isVertical: boolean): OutlineDimensions {
    const isAreaChart = Visual.settings.chartType === ChartType.Area;
    const [minX, maxX, minY, maxY] = getOutlineCoordinates(isAreaChart, isVertical);
    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
    };
}

/**
 * Removes existing stacked chart menu if perspective was changed
 * @param isVertical
 */
function removeExistingStackedChartMenu(isVertical: boolean): void {
    document.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_STACKED_CHART}:not(.vertical-${isVertical})`)?.remove();
}

function drawGlobalStackedChartOutline(container: d3.Selection<SVGElement, any, any, any>, isVertical: boolean, outlineDimensions: OutlineDimensions): void {
    const globalOutlineId = `o-${UUIDv4()}`;
    const globalMenu = document.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_STACKED_CHART}.vertical-${isVertical}`);
    const rect = container.insert(RECT, ".chart-legend.highlightable");

    rect.attr(X, outlineDimensions.x)
        .attr(Y, outlineDimensions.y)
        .style(WIDTH, `${outlineDimensions.width}px`)
        .style(HEIGHT, `${outlineDimensions.height}px`)
        .style(BORDER_RADIUS, OUTLINE_BORDER_RADIUS)
        .attr(STROKE, globalMenu ? OUTLINE_COLOR : TRANSPARENT)
        .attr(FILL, TRANSPARENT)
        .attr(ID, globalOutlineId)
        .attr(RX, 2)
        .classed("global-chart-legend-outline", true)
        .classed(ACTIVE, !!globalMenu);

    rect.on(MOUSEENTER, () => {
        rect.attr(STROKE, OUTLINE_COLOR);
    });

    rect.on(MOUSELEAVE, () => {
        if (!document.querySelector(`.global-chart-legend-outline`)?.classList.contains(ACTIVE)) {
            rect.attr(STROKE, TRANSPARENT);
        }
    });

    rect.on(CLICK, (event: MouseEvent) => {
        toggleGlobalCategoryMenu(event, outlineDimensions, isVertical, globalOutlineId);
    });
}

function getOutlineCoordinates(isAreaChart: boolean, isVertical: boolean): [number, number, number, number] {
    const legendLabels = document.querySelectorAll(`.chart-legend.highlightable`);
    const barsOrAreas = isAreaChart ? document.querySelectorAll(`.stack-area`) : document.querySelectorAll(`.bars.highlightable`);
    const [minY, maxY] = getMinMaxY(isVertical ? legendLabels : barsOrAreas);
    const [minX, maxX] = getMinMaxX(isVertical ? barsOrAreas : legendLabels);
    return [minX, maxX, minY, maxY];
}

function getMinMaxY(elements: NodeList): [number, number] {
    let minY = null;
    let maxY = null;

    elements.forEach((element: Element) => {
        const elementBoundingRect = element.getBoundingClientRect();

        if (minY === null || minY > elementBoundingRect.y) {
            minY = elementBoundingRect.y < 1 ? 1 : elementBoundingRect.y;
        }

        const sumYAndHeight = elementBoundingRect.y + elementBoundingRect.height;
        if (maxY === null || maxY < sumYAndHeight) {
            maxY = sumYAndHeight;
        }
    });

    return [minY, maxY];
}

function getMinMaxX(elements: NodeList): [number, number] {
    let minX = null;
    let maxX = null;

    elements.forEach((element: Element) => {
        const elementBoundingRect = element.getBoundingClientRect();

        if (minX === null || minX > elementBoundingRect.x) {
            minX = elementBoundingRect.x < 1 ? 1 : elementBoundingRect.x;
        }

        const sumXAndWidth = elementBoundingRect.x + elementBoundingRect.width;
        if (maxX === null || maxX < sumXAndWidth) {
            maxX = sumXAndWidth;
        }
    });

    return [minX, maxX];
}

function toggleGlobalCategoryMenu(event: MouseEvent, outlineDimensions: OutlineDimensions, isVertical: boolean, globalOutlineId: string): void {
    const main = <HTMLElement>document.querySelector(".zebrabi-charts-container");

    // remove previous outline
    const outline = d3.select(`rect[class*=outline]:not(#${globalOutlineId})`);
    outline.classed(ACTIVE, false);
    outline.attr(STROKE, TRANSPARENT);

    const globalStackedChartMenu = main.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_STACKED_CHART}`);
    document.querySelector(`.global-chart-legend-outline`)?.classList.toggle(ACTIVE);

    if (!globalStackedChartMenu) {
        const menu = new GlobalStackedChartMenu(`vertical-${isVertical}`).appendTo(main);
        calculateMenuPosition(event, menu, isVertical, outlineDimensions);
        repositionElementInsideViewPort(menu);
    } else {
        globalStackedChartMenu.remove();
    }
}

function calculateMenuPosition(event: MouseEvent, menu: HTMLElement, isVertical: boolean, outlineDimensions: OutlineDimensions): void {
    menu.style.position = FIXED;

    const menuBoundingRect = menu.getBoundingClientRect();

    if (isVertical) {
        menu.style.top = `${outlineDimensions.y + outlineDimensions.height + 10}px`;
        menu.style.left = `${event.clientX - menuBoundingRect.width/2}px`;
    } else {
        menu.style.top = `${event.clientY - menuBoundingRect.height/2}px`;
        menu.style.left = `${outlineDimensions.x + outlineDimensions.width + 10}px`;
    }
}

