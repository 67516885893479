import { AnalyticsHandler } from "@zebrabi/zebrabi-core";

export const analyticsHandler = new AnalyticsHandler();

export const ignoredEditEvents = [
    "plottedDataProperties",
    "differenceHighlightWidth",
    "minChartHeight",
    "multilineCategories"
];
