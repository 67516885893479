import * as d3 from "../d3";
import { ChartData, DataPoint, ScenarioOptions } from "./../interfaces";
import { LabelProperties } from "./../library/interfaces";
import { ChartType, DifferenceHighlightFromTo, } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { Visual } from "./../visual";

import {
    Scenario, DifferenceLabel,
    MIDDLE, NORMAL, TEXT, FONT_SIZE, FONT_FAMILY, TEXT_ANCHOR, FILL, WIDTH, HEIGHT, HIGHLIGHTABLE, RECT, X, Y, RX, RY,
    BLACK, WHITE, BACKGROUND, G, BAR, ITALIC, FONT_STYLE, FONT_WEIGHT, STROKE, STROKE_WIDTH, VarianceDisplayType, LINE,
    STROKE_DASHARRAY, TRANSFORM, PATH, D, X1, X2, Y1, Y2, VARIANCE, FONT_SIZE_UNIT
} from "./../library/constants";
import {
    CHART_CONTAINER, VALUE, SECOND_SEGMENT_VALUE
} from "./../consts";

import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as viewModels from "./../viewModel/viewModelHelperFunctions";
import * as charting from "./chart";
import { showOverlayOnCategoryHover } from "../ui/drawCategoryOverlay";
import { mapAdditionalLabelsWithOriginal } from "../helpers";

// eslint-disable-next-line max-lines-per-function
export default function varianceChart(reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement,
    x: number, y: number, height: number, width: number, chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number,
    plotTitle: boolean, allowLabelsChange: boolean, plotLegend: boolean) {
    let chartWidth = width;
    let chartXPos = x;
    if (plotLegend) {
        chartWidth -= settings.getLegendWidth();
        chartXPos += settings.getLegendWidth();
    }

    if (settings.differenceHighlight) {
        chartWidth -= settings.differenceHighlightWidth;
    }
    if (settings.showGrandTotal) {
        chartWidth = chartWidth * chartData.dataPoints.length / (chartData.dataPoints.length + 1.5);
    }

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    const yScale = charting.getYScale(min, max, height, topMargin, bottomMargin);
    const xScale = charting.getXScale(chartData, chartXPos, chartWidth, settings.getGapBetweenColumns());
    charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, chartXPos, y, charting.getChartTitleWidth(chartWidth, settings), height, max, min, topMargin, bottomMargin);
    charting.plotCategories(chartContainer, settings, chartData, y + height, xScale, chartWidth, y + yScale(0), false, chartIndex);

    const valuesDataPoints = chartData.dataPoints.filter(dp => dp.value !== null);

    let secondSegmentDataPoints: DataPoint[] = [];
    let secondSegmentVariancesDataPoints: DataPoint[] = [];
    let secondSegmentValuesOnlyDPs: DataPoint[] = [];
    if (settings.scenarioOptions.secondValueScenario !== null) {
        secondSegmentDataPoints = settings.showAllForecastData ? chartData.dataPoints.filter(dp => dp.secondSegmentValue !== null) :
            chartData.dataPoints.filter(dp => dp.value === null && dp.secondSegmentValue !== null);
        secondSegmentValuesOnlyDPs = settings.showAllForecastData ? secondSegmentDataPoints.filter(dp => dp.value === null) : secondSegmentDataPoints;

        let secondSegmentBars = chartContainer
            .selectAll(`.${BAR}_SECOND_SEGMENT` + chartIndex)
            .data(secondSegmentDataPoints);
        const rect = secondSegmentBars.enter()
            .append(RECT)
            .classed(BAR, true)
            .classed(HIGHLIGHTABLE, true);
        secondSegmentBars = secondSegmentBars.merge(rect)
            .attr(WIDTH, xScale.bandwidth())
            .attr(HEIGHT, d => yScale(max - Math.abs(d.secondSegmentValue)) - topMargin)
            .attr(Y, d => y + yScale(Math.max(0, d.secondSegmentValue)))
            .attr(X, d => xScale(d.category));
        styles.applyToBars(secondSegmentBars, (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), settings.scenarioOptions.secondValueScenario, settings.chartStyle, false, settings.colorScheme);

        secondSegmentVariancesDataPoints = secondSegmentValuesOnlyDPs.filter(d => d.reference !== null);
        let secondSegmentVariances = chartContainer
            .selectAll(`.${BAR}_VARIANCES_SECOND_SEGMENT` + chartIndex)
            .data(secondSegmentVariancesDataPoints);

        if (settings.varianceDisplayType === VarianceDisplayType.Bar) {
            const rectSecondSegment = secondSegmentVariances.enter()
                .append(RECT)
                .classed(BAR, true)
                .classed(HIGHLIGHTABLE, true)
                .classed(VARIANCE, true);
            secondSegmentVariances = secondSegmentVariances.merge(rectSecondSegment)
                .attr(WIDTH, xScale.bandwidth() * 0.7)
                .attr(HEIGHT, d => yScale(max - Math.abs(d.secondSegmentValue - d.reference)) - topMargin)
                .attr(Y, d => y + (d.reference > d.secondSegmentValue ? yScale(d.reference) : yScale(d.secondSegmentValue)))
                .attr(X, d => xScale(d.category) + 0.3 * xScale.bandwidth());
        }
        else {
            const rectSecondSegment = secondSegmentVariances.enter()
                .append(PATH)
                .classed(BAR, true)
                .classed(HIGHLIGHTABLE, true)
                .classed(VARIANCE, true)
                .attr(D, (d) => drawing.getChevronArrowPath(xScale.bandwidth(), yScale(max - Math.abs(d.secondSegmentValue - d.reference)) - topMargin, d.isNegative, false))
                .attr(TRANSFORM, (d) => {
                    const X = xScale(d.category);
                    const Y = y + (d.reference > d.secondSegmentValue ? yScale(d.reference) : yScale(d.secondSegmentValue));
                    return "translate(" + X + "," + Y + ")";
                });
            secondSegmentVariances.enter()
                .append(LINE)
                .attr(X1, (d) => xScale(d.category))
                .attr(Y1, (d) => y + yScale(d.reference))
                .attr(X2, (d) => xScale(d.category) + xScale.bandwidth())
                .attr(Y2, (d) => y + yScale(d.reference))
                .attr(STROKE, settings.scenarioOptions.referenceScenario === Scenario.PreviousYear ? styles.getLighterColor(settings.colorScheme.axisColor) : settings.colorScheme.axisColor)
                .attr(STROKE_DASHARRAY, settings.scenarioOptions.referenceScenario === Scenario.Forecast ? "2" : 0)
                .attr(STROKE_WIDTH, "1");
            secondSegmentVariances = secondSegmentVariances.merge(rectSecondSegment);
        }
        const varianceColor = (d: DataPoint): string => { return styles.getVarianceColor(chartData.isInverted, d.secondSegmentValue - d.reference, settings.colorScheme); };
        styles.applyToBars(secondSegmentVariances, varianceColor, settings.scenarioOptions.secondValueScenario, settings.chartStyle, true, settings.colorScheme);
        if (secondSegmentValuesOnlyDPs.length > 0) {
            drawing.plotAxisScenarioDelimiter(chartContainer, xScale(secondSegmentValuesOnlyDPs[0].category) - xScale.bandwidth() / 8, y + yScale(0) - 25, y + height);
        }
        charting.addMouseHandlers(secondSegmentBars, svg);
        charting.addMouseHandlers(secondSegmentVariances, svg);
        secondSegmentBars.exit().remove();
        secondSegmentVariances.exit().remove();
    }

    if (valuesDataPoints.length < chartData.dataPoints.length) {
        // plot reference only bars
        const referenceDataPoints = chartData.dataPoints.filter(dp => dp.value === null && dp.secondSegmentValue === null && dp.reference !== null);
        let referenceBars = chartContainer
            .selectAll(`.${BAR}_REFERENCE` + chartIndex)
            .data(referenceDataPoints);
        const rect = referenceBars.enter()
            .append(RECT)
            .classed(BAR, true)
            .classed(HIGHLIGHTABLE, true);
        referenceBars = referenceBars.merge(rect)
            .attr(WIDTH, xScale.bandwidth())
            .attr(HEIGHT, d => yScale(max - Math.abs(d.reference)) - topMargin)
            .attr(Y, d => y + yScale(Math.max(0, d.reference)))
            .attr(X, d => xScale(d.category));
        styles.applyToBars(referenceBars, (d: DataPoint) => d.color, settings.scenarioOptions.referenceScenario, settings.chartStyle, false, settings.colorScheme);
        charting.addMouseHandlers(referenceBars, svg);
        referenceBars.exit().remove();
    }

    let bars = chartContainer
        .selectAll(`.${BAR}` + chartIndex)
        .data(valuesDataPoints);
    const rect = bars.enter()
        .append(RECT)
        .classed(BAR, true)
        .classed(HIGHLIGHTABLE, true);
    bars = bars.merge(rect)
        .attr(WIDTH, xScale.bandwidth())
        .attr(HEIGHT, d => Math.max(0, yScale(max - Math.abs(d.value)) - topMargin))
        .attr(Y, d => y + yScale(Math.max(0, d.value)))
        .attr(X, d => xScale(d.category));
    styles.applyToBars(bars, (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), settings.scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme);

    drawing.plotHorizontalAxis(chartContainer, false, chartXPos, chartWidth, y + yScale(0), settings.colorScheme.axisColor, settings.scenarioOptions.referenceScenario);

    const variancesDataPoints = chartData.dataPoints.filter(dp => dp.value !== null && dp.reference !== null);
    if (variancesDataPoints.length > 0 && settings.shouldHideLastVariance(variancesDataPoints[variancesDataPoints.length - 1].category)) {
        variancesDataPoints.pop();
    }
    let variances = chartContainer
        .selectAll(".bar_2" + chartIndex)
        .data(variancesDataPoints);
    if (settings.varianceDisplayType === VarianceDisplayType.Bar) {
        const rectVariances = variances.enter()
            .append(RECT)
            .classed(BAR, true)
            .classed(HIGHLIGHTABLE, true)
            .classed(VARIANCE, true);
        variances = variances.merge(rectVariances)
            .attr(WIDTH, xScale.bandwidth() * 0.7)
            .attr(HEIGHT, d => Math.max(0, yScale(max - Math.abs(d.value - d.reference)) - topMargin))
            .attr(X, d => xScale(d.category) + 0.3 * xScale.bandwidth())
            .attr(Y, d => y + (d.reference > d.value ? yScale(d.reference) : yScale(d.value)));
    }
    else {
        const rectVariances = variances.enter()
            .append(PATH)
            .attr(D, (d) => drawing.getChevronArrowPath(xScale.bandwidth(), yScale(max - Math.abs(d.value - d.reference)) - topMargin, d.isNegative, false))
            .classed(BAR, true)
            .classed(HIGHLIGHTABLE, true)
            .attr(TRANSFORM, (d) => {
                const X = xScale(d.category);
                const Y = y + (d.isNegative ? yScale(d.reference) : yScale(d.value));
                return "translate(" + X + "," + Y + ")";
            });
        const lineVariance = variances.enter()
            .append(LINE)
            .attr(X1, (d) => xScale(d.category))
            .attr(Y1, (d) => y + yScale(d.reference))
            .attr(X2, (d) => xScale(d.category) + xScale.bandwidth())
            .attr(Y2, (d) => y + yScale(d.reference))
            .attr(STROKE, settings.scenarioOptions.referenceScenario === Scenario.PreviousYear ? styles.getLighterColor(settings.colorScheme.axisColor) : settings.colorScheme.axisColor)
            .attr(STROKE_DASHARRAY, settings.scenarioOptions.referenceScenario === Scenario.Forecast ? "2" : 0)
            .attr(STROKE_WIDTH, "1");
        variances = variances.merge(rectVariances).merge(lineVariance);
    }

    const varianceColor = (d: DataPoint): string => { return styles.getVarianceColor(chartData.isInverted !== d.isCategoryInverted, d.value - d.reference, settings.colorScheme); };
    styles.applyToBars(variances, varianceColor, settings.scenarioOptions.valueScenario, settings.chartStyle, true, settings.colorScheme);

    if (settings.scenarioOptions.secondReferenceScenario) {
        charting.addReferenceTriangles(chartContainer, chartData.dataPoints.filter(dp => dp.secondReference !== null), settings, xScale, yScale, y, settings.scenarioOptions.secondReferenceScenario, true);
    }

    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    const varianceLabelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);
    if (settings.showDataLabels) {
        const hideUnits = settings.shouldHideDataLabelUnits();
        const color = styles.getLabelColor(settings.colorScheme.neutralColor);

        const labelsProperties = getLabelProperties(chartData.dataPoints, settings, labelsFormat, hideUnits, xScale, yScale, y, max, topMargin);
        if (labelsProperties.length > 0) {
            const forecastLabelProperties = labelsProperties.filter(p => p.isForecast);
            if (forecastLabelProperties.length > 0) {
                charting.plotLabelBackgrounds(chartContainer, chartIndex, forecastLabelProperties, settings, WHITE, true, false);
            }

            const backgroundsColor = charting.getLabelBackgroundColor(settings, settings.scenarioOptions, chartData.group);
            charting.plotLabelBackgrounds(chartContainer, chartIndex, labelsProperties.filter(p => !p.isForecast), settings, backgroundsColor, true, true);
            const valueLabels = charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings);
            valueLabels
                .attr(FILL, d => d.isForecast ? BLACK : d.isHighlighted ? styles.getLabelColor(settings.getCategoryHighlightColor(d.category)) : color);
        }

        const varianceLabelsDataPoints = variancesDataPoints.concat(secondSegmentVariancesDataPoints);
        const varianceLabelProperties = getVarianceLabelProperties(varianceLabelsDataPoints, settings, labelsFormat, varianceLabelsFormat, hideUnits, xScale, yScale, y);
        if (settings.showAllForecastData && settings.scenarioOptions.secondValueScenario !== null) {
            charting.plotLabelBackgrounds(chartContainer, chartIndex, varianceLabelProperties.filter(p => !p.isForecast), settings, WHITE, true, false);
        }
        const varianceCalculationChangeAllowed = settings.getRealInteractionSettingValue(settings.allowVarianceCalculationChange) && allowLabelsChange;
        const varianceLabels = charting.plotLabels(chartContainer, `labels_V${chartIndex}`, varianceLabelProperties, settings, NORMAL, varianceCalculationChangeAllowed);

        let additionalLabels: d3.Selection<any, LabelProperties, any, any> = null;
        if (allowLabelsChange) {
            if (settings.varianceLabel === DifferenceLabel.Relative) {
                varianceLabels.style(FONT_STYLE, ITALIC);
            }
            else if (settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute) {
                const additionalVarianceLabelsProperties = getAdditionalLabelProperties(varianceLabelsDataPoints, settings, xScale, yScale, y);
                mapAdditionalLabelsWithOriginal(additionalVarianceLabelsProperties, varianceLabels, settings.labelFontSize);
                additionalLabels = charting.plotLabels(chartContainer, `labels_V${chartIndex}_2`, additionalVarianceLabelsProperties, settings, ITALIC, varianceCalculationChangeAllowed);
            }

            if (varianceCalculationChangeAllowed && Visual.animateVarianceLabels) {
                charting.animateLabels(varianceLabels, additionalLabels, settings.labelFontSize);
            }
        }

        if (valuesDataPoints.length < chartData.dataPoints.length) {
            const referenceOnlyDataPoints = chartData.dataPoints.filter((p, i) => i >= valuesDataPoints.length && p.reference !== null && p.secondSegmentValue === null);
            const referenceLabelProperties = getReferenceLabelProperties(referenceOnlyDataPoints, settings, labelsFormat, hideUnits, xScale, yScale, y);
            if (referenceLabelProperties.length > 0) {
                charting.plotLabels(chartContainer, `${chartIndex}`, referenceLabelProperties, settings);
            }
        }
    }

    if (settings.differenceHighlight && valuesDataPoints.length > 0) {
        let { fromDataPoint, toDataPoint } = charting.getDiffHighlightAutoDataPoints(valuesDataPoints, ChartType.Variance, false);
        let isLastAcToLastFc = false;
        if (settings.differenceHighlightFromTo !== DifferenceHighlightFromTo.Auto) {
            if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.LastACToLastFC && secondSegmentDataPoints.length !== 0 && valuesDataPoints.length !== 0) {
                isLastAcToLastFc = true;
                fromDataPoint = valuesDataPoints[valuesDataPoints.length - 1];
                toDataPoint = secondSegmentDataPoints[secondSegmentDataPoints.length - 1];
            }
            else {
                const diffHighlightDataPoints = settings.isSecondSegmentDiffHighlight() ? secondSegmentDataPoints : valuesDataPoints;
                const fromToDataPoints = charting.getDiffHighlightDataPoints(diffHighlightDataPoints, settings.differenceHighlightFromTo);
                fromDataPoint = fromToDataPoints.fromDP;
                toDataPoint = fromToDataPoints.toDP;
            }
        }

        if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.Auto && !settings.isSecondSegmentDiffHighlight() && toDataPoint && toDataPoint.reference === null) {
            // if there is no reference value for the last data point, display diff highlight between the values of last two data points (as in single measure column chart)
            fromDataPoint = charting.getDiffHighlightAutoDataPoints(valuesDataPoints, ChartType.Variance, true).fromDataPoint;
        }

        if (fromDataPoint && toDataPoint) {
            const valueProperty: keyof DataPoint = settings.isSecondSegmentDiffHighlight() ? SECOND_SEGMENT_VALUE : VALUE;
            const endX = chartXPos + chartWidth + settings.differenceHighlightWidth;
            if (fromDataPoint === toDataPoint && fromDataPoint[valueProperty] !== null && toDataPoint.reference !== null) {
                const dhX1 = xScale(fromDataPoint.category);
                const dhX2 = dhX1 + 0.3 * xScale.bandwidth();
                const dhY1 = y + yScale(fromDataPoint[valueProperty]);
                const dhY2 = y + yScale(fromDataPoint.reference);
                charting.addDifferenceHighlight(chartContainer, settings, slider, endX, dhX1, dhX2, fromDataPoint[valueProperty], fromDataPoint.reference, dhY1, dhY2, chartData.isInverted);
            }
            else if (fromDataPoint !== toDataPoint && (fromDataPoint[valueProperty] !== null && toDataPoint[valueProperty] !== null
                || isLastAcToLastFc && fromDataPoint.value !== null && toDataPoint.secondSegmentValue !== null)) {
                const dhX1 = xScale(toDataPoint.category);
                const dhX2 = xScale(fromDataPoint.category);
                const startValue = isLastAcToLastFc ? fromDataPoint.value : fromDataPoint[valueProperty];
                const endValue = isLastAcToLastFc ? toDataPoint.secondSegmentValue : toDataPoint[valueProperty];
                const dhY1 = y + yScale(endValue);
                const dhY2 = y + yScale(startValue);
                charting.addDifferenceHighlight(chartContainer, settings, slider, endX, dhX1, dhX2, endValue, startValue, dhY1, dhY2, chartData.isInverted);
            }
        }
    }

    if (settings.showGrandTotal) {
        plotGrandTotalVarianceChart(chartContainer, chartData.dataPoints, secondSegmentValuesOnlyDPs, chartIndex, xScale, yScale, max, topMargin,
            y, x, width, height, settings.scenarioOptions, settings, labelsFormat, chartData.isInverted, settings.showCategories && chartData.showCategoryLabels !== false, chartData.group);
    }

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, y, settings);
        }
    }

    charting.addMouseHandlers(bars, svg);
    charting.addMouseHandlers(variances, svg);

    if (plotLegend) {
        const secondReferenceDataPoints = chartData.dataPoints.filter(dp => dp.secondReference !== null);
        charting.plotAllChartLegends(chartContainer, settings, x, y, chartWidth, yScale, valuesDataPoints, variancesDataPoints, secondSegmentDataPoints, secondReferenceDataPoints, true, height);
    }

    bars.exit().remove();
    variances.exit().remove();
}

// eslint-disable-next-line max-lines-per-function
function plotGrandTotalVarianceChart(reportArea: d3.Selection<SVGElement, any, any, any>, dataPoints: DataPoint[], secondSegmentDataPoints: DataPoint[], chartIndex: number,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, max: number, topMargin: number, y: number, x: number, width: number, height: number,
    scenarioOptions: ScenarioOptions, settings: ChartSettings, labelsFormat: string, isInverted: boolean, plotTotalLabel: boolean, group: string) {
    const locale = Office.context.contentLanguage || "en-US";
    let totalDataPoint: DataPoint;
    // Exception for setting showAllForecastData - filter dataPoints if treat nulls as zeroes is off when there is no forecast or we show all forecast data
    if (secondSegmentDataPoints.length > 0 || settings.showAllForecastData && scenarioOptions.secondValueScenario === Scenario.Forecast) {
        totalDataPoint = charting.getGrandTotalDataPoint(dataPoints, secondSegmentDataPoints, settings);
    } else {
        totalDataPoint = charting.getGrandTotalDataPoint(!settings.handleNullsAsZeros ? dataPoints.filter(dp => dp.value !== null) : dataPoints, secondSegmentDataPoints, settings);
    }

    const valuesDataPoints = dataPoints.filter(dp => dp.value !== null);
    const totalReference = totalDataPoint.reference;
    let avgReference = totalDataPoint.reference / dataPoints.length;
    const totalValue = totalDataPoint.value;
    const totalSecondValue = totalDataPoint.secondSegmentValue;
    let avgSecondValue = 0;

    const xScaleCategoryWidth = xScale.bandwidth();
    const totalColumnXPosition = x + width - xScaleCategoryWidth;
    const totalLabelsXPosition = x + width - 0.5 * xScaleCategoryWidth - 10.5;
    const hideUnits = settings.shouldHideDataLabelUnits();

    let avgValue = totalDataPoint.value / dataPoints.length;
    if (secondSegmentDataPoints.length > 0 || settings.showAllForecastData && scenarioOptions.secondValueScenario === Scenario.Forecast) {
        avgSecondValue = totalSecondValue / dataPoints.length;
    }
    let totalVariance = totalValue + totalSecondValue - totalDataPoint.reference;
    let avgVariance = avgValue + avgSecondValue - avgReference;
    if (valuesDataPoints.length > 0 && valuesDataPoints.length < dataPoints.length && secondSegmentDataPoints.length === 0) {
        avgValue = totalValue / valuesDataPoints.length;
        totalVariance = totalValue - valuesDataPoints.map(p => p.reference).reduce((a, b) => a + b);
        avgVariance = totalVariance / valuesDataPoints.length;
        avgReference = d3.sum(valuesDataPoints.map(p => p.reference)) / valuesDataPoints.length;
    }

    if (secondSegmentDataPoints.length > 0) {
        let avgSecondValueBar = reportArea
            .selectAll(`.${BAR}_SECOND_VALUE_TOTAL` + chartIndex)
            .data([totalDataPoint]);
        const rect = avgSecondValueBar.enter()
            .append(RECT)
            .classed(BAR, true);
        avgSecondValueBar = avgSecondValueBar.merge(rect)
            .attr(WIDTH, xScaleCategoryWidth)
            .attr(HEIGHT, Math.abs(yScale(avgSecondValue) - yScale(0)))
            .attr(Y, y + yScale(avgValue < 0 ? avgValue : avgSecondValue + avgValue))
            .attr(X, totalColumnXPosition - 10);
        styles.applyToBars(avgSecondValueBar, (d: DataPoint) => settings.getGroupDataPointColor(group, d.color), scenarioOptions.secondValueScenario, settings.chartStyle, false, settings.colorScheme);
        avgSecondValueBar.exit().remove();
    }

    if (valuesDataPoints.length > 0) {
        let avgValuesBar = reportArea
            .selectAll(`.${BAR}_VALUE_TOTAL` + chartIndex)
            .data([totalDataPoint]);
        const rect = avgValuesBar.enter()
            .append(RECT)
            .classed(BAR, true);
        avgValuesBar = avgValuesBar.merge(rect)
            .attr(WIDTH, xScaleCategoryWidth + 1)
            .attr(HEIGHT, Math.abs(yScale(avgValue) - yScale(0)))
            .attr(Y, y + yScale(avgValue < 0 ? 0 : avgValue))
            .attr(X, totalColumnXPosition - 10.5);
        styles.applyToBars(avgValuesBar, (d: DataPoint) => settings.getGroupDataPointColor(group, d.color), scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme);
        avgValuesBar.exit().remove();

        let varianceBar = reportArea
            .selectAll(".bar_total_variance")
            .data([totalDataPoint]);

        if (settings.varianceDisplayType === VarianceDisplayType.Bar) {
            const rectVariance = varianceBar
                .enter()
                .append(RECT)
                .classed(BAR, true);
            varianceBar = varianceBar.merge(rectVariance)
                .attr(WIDTH, xScaleCategoryWidth * 0.7)
                .attr(HEIGHT, Math.abs(yScale(avgVariance) - yScale(0)))
                .attr(X, totalColumnXPosition - 10 + xScaleCategoryWidth * 0.3)
                .attr(Y, y + yScale(totalVariance > 0 ? avgValue + avgSecondValue : avgReference));
        }
        else {
            const varianceArrow = varianceBar
                .enter()
                .append(PATH)
                .classed(BAR, true)
                .attr(D, (d) => drawing.getChevronArrowPath(xScaleCategoryWidth, Math.abs(yScale(avgVariance) - yScale(0)), totalVariance < 0, false))
                .attr(TRANSFORM, (d) => {
                    const X = totalColumnXPosition - 10;
                    const Y = y + yScale(totalVariance > 0 ? avgValue + avgSecondValue : avgReference);
                    return "translate(" + X + "," + Y + ")";
                });
            varianceBar.enter()
                .append(LINE)
                .attr(X1, totalColumnXPosition - 10)
                .attr(Y1, y + yScale(avgReference))
                .attr(X2, totalColumnXPosition - 10 + xScale.bandwidth())
                .attr(Y2, y + yScale(avgReference))
                .attr(STROKE, settings.scenarioOptions.referenceScenario === Scenario.PreviousYear ? styles.getLighterColor(settings.colorScheme.axisColor) : settings.colorScheme.axisColor)
                .attr(STROKE_DASHARRAY, settings.scenarioOptions.referenceScenario === Scenario.Forecast ? "2" : 0)
                .attr(STROKE_WIDTH, "1");
            varianceBar = varianceArrow;
        }
        const scenario = secondSegmentDataPoints.length > 0 && scenarioOptions.secondValueScenario !== null ? scenarioOptions.secondValueScenario : scenarioOptions.valueScenario;
        const varianceColor = (d: DataPoint): string => { return styles.getVarianceColor(isInverted, totalVariance, settings.colorScheme); };
        styles.applyToBars(varianceBar, varianceColor, scenario, settings.chartStyle, true, settings.colorScheme);
        varianceBar.exit().remove();
        const backgroundsColor = charting.getLabelBackgroundColor(settings, scenarioOptions, group);
        const labelText = charting.getFormattedDataLabel(totalValue, settings.decimalPlaces, settings.displayUnits, locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        const yPos = y + yScale(avgValue < 0 ? 0 : avgValue) + Math.abs(yScale(avgValue) - yScale(0)) / 2;
        const labelProperty = charting.getLabelProperty(labelText, totalLabelsXPosition, yPos, true, totalValue, totalDataPoint, settings.labelFontSize, settings.labelFontFamily);
        charting.plotLabelBackgrounds(reportArea, chartIndex, [labelProperty], settings, backgroundsColor, false, false);
        const valuesLabel = reportArea
            .append(TEXT)
            .text(d => labelText)
            .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
            .style(FONT_FAMILY, charting.getFontFamily(settings.labelFontFamily))
            .style(FONT_WEIGHT, charting.getFontWeight(settings.labelFontFamily))
            .style(TEXT_ANCHOR, MIDDLE)
            .attr(X, totalLabelsXPosition)
            .attr(Y, yPos)
            .attr(FILL, d => scenarioOptions.valueScenario === Scenario.Forecast ? BLACK : styles.getLabelColor(settings.colorScheme.neutralColor));

        const negativeLabelOffset = settings.labelFontSize + 2;
        const varianceLabel = reportArea
            .append(TEXT)
            .text(d => charting.getVarianceDataLabel(totalVariance, settings.decimalPlaces, settings.displayUnits, locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelsFormat, hideUnits))
            .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
            .style(FONT_FAMILY, charting.getFontFamily(settings.labelFontFamily))
            .style(FONT_WEIGHT, charting.getFontWeight(settings.labelFontFamily))
            .style(TEXT_ANCHOR, MIDDLE)

            .attr(X, totalLabelsXPosition + xScaleCategoryWidth * 0.15)
            .attr(Y, d => {
                const maxVal = Math.max(avgValue + avgSecondValue, avgReference);
                return y + (maxVal > 0 ? yScale(maxVal) - 5 : yScale(Math.min(avgValue + avgSecondValue, avgReference)) + negativeLabelOffset);
            })
            .attr(FILL, settings.labelFontColor);

        if (settings.varianceLabel === DifferenceLabel.Relative) {
            varianceLabel.text(d => charting.getRelativeVarianceLabel(settings,
                viewModels.calculateRelativeDifferencePercent(totalValue + totalSecondValue, totalReference), false));
            varianceLabel.style(FONT_STYLE, ITALIC);
        }
        else if (settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute) {
            varianceLabel
                .attr(Y, d => {
                    const maxVal = Math.max(avgValue + avgSecondValue, avgReference);
                    return y + (maxVal > 0 ? yScale(maxVal) - 17 : yScale(Math.min(avgValue + avgSecondValue, avgReference)) + 2 * negativeLabelOffset);
                });
            reportArea.append(TEXT)
                .text(d => {
                    return charting.getRelativeVarianceLabel(settings, viewModels.calculateRelativeDifferencePercent(totalValue + totalSecondValue, totalReference), true);
                })
                .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
                .style(FONT_FAMILY, charting.getFontFamily(settings.labelFontFamily))
                .style(FONT_WEIGHT, charting.getFontWeight(settings.labelFontFamily))
                .style(FONT_STYLE, ITALIC)
                .style(TEXT_ANCHOR, MIDDLE)
                .attr(X, totalLabelsXPosition + xScaleCategoryWidth * 0.15)
                .attr(Y, d => {
                    const maxVal = Math.max(avgValue + avgSecondValue, avgReference);
                    return y + (maxVal > 0 ? yScale(maxVal) - 5 : yScale(Math.min(avgValue + avgSecondValue, avgReference)) + negativeLabelOffset);
                })
                .attr(FILL, settings.labelFontColor);
        }
    }

    if (secondSegmentDataPoints.length > 0) {
        const color = scenarioOptions.secondValueScenario === Scenario.Forecast ? BLACK : styles.getLabelColor(settings.colorScheme.neutralColor);
        const backgrounds = drawing.getShapes(reportArea, BACKGROUND, BACKGROUND, RECT, [secondSegmentDataPoints[0]]);
        const totalValuesLabel = reportArea
            .append(TEXT)
            .text(d => charting.getFormattedDataLabel(totalSecondValue, settings.decimalPlaces, settings.displayUnits, locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits))
            .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
            .style(FONT_FAMILY, charting.getFontFamily(settings.labelFontFamily))
            .style(FONT_WEIGHT, charting.getFontWeight(settings.labelFontFamily))
            .style(TEXT_ANCHOR, MIDDLE)

            .attr(X, totalLabelsXPosition)
            .attr(Y, y + yScale(avgSecondValue + avgValue < 0 ? avgValue : avgSecondValue + avgValue) + Math.abs(yScale(avgSecondValue) - yScale(0)) / 2 + 5)
            .attr(FILL, color);
        if (scenarioOptions.secondValueScenario === Scenario.Forecast) {
            const boundingBoxes = [];
            totalValuesLabel.each(function (d, i) {
                boundingBoxes.push(this.getBBox());
            });
            const paddingTopBottom = 2;
            const i = 0;
            backgrounds
                .attr(X, (d, i) => { return boundingBoxes[i].x; })
                .attr(Y, (d, i) => { return boundingBoxes[i].y - paddingTopBottom / 2; })
                .attr(WIDTH, (d, i) => { return boundingBoxes[i].width; })
                .attr(HEIGHT, (d, i) => { return boundingBoxes[i].height + paddingTopBottom; })
                .attr(FILL, WHITE)
                .attr(RX, 2)
                .attr(RY, 2);
        }
    }

    if (settings.scenarioOptions.secondReferenceScenario) {
        const avgSecondReference = totalDataPoint.secondReference / (valuesDataPoints.length > 0 && secondSegmentDataPoints.length === 0 ? valuesDataPoints.length : dataPoints.length);
        charting.addReferenceTriangles(reportArea, [totalDataPoint], settings, xScale, yScale, y, settings.scenarioOptions.secondReferenceScenario, true, totalColumnXPosition - 10, avgSecondReference);
    }

    drawing.plotHorizontalAxis(reportArea, false, totalColumnXPosition - 20, xScaleCategoryWidth + 20, y + yScale(0), settings.colorScheme.axisColor, scenarioOptions.referenceScenario);
    const hasNegativeValues = totalValue + totalSecondValue < 0 || totalReference < 0;

    const fontSize = settings.showCategoriesFontSettings ? settings.categoriesFontSize : settings.labelFontSize;
    const fontColor = settings.showCategoriesFontSettings ? settings.categoriesFontColor : settings.labelFontColor;
    const fontFamily = settings.showCategoriesFontSettings ? settings.categoriesFontFamily : settings.labelFontFamily;

    if (plotTotalLabel) {
        const totalLabelTextEl = reportArea
            .data([totalDataPoint])
            .append(TEXT)
            .text(drawing.getTailoredText(settings.grandTotalLabel, fontSize, charting.getFontFamily(fontFamily), charting.getFontWeight(fontFamily), NORMAL, xScaleCategoryWidth + 20))
            .style(FONT_SIZE, fontSize + FONT_SIZE_UNIT)
            .style(TEXT_ANCHOR, MIDDLE)
            .style(FONT_FAMILY, charting.getFontFamily(fontFamily))
            .style(FONT_WEIGHT, charting.getFontWeight(fontFamily))
            .attr(X, totalLabelsXPosition)
            .attr(Y, y + (hasNegativeValues ? height - 1 : yScale(0) + fontSize + 3))
            .attr(FILL, fontColor);

        showOverlayOnCategoryHover(totalLabelTextEl, false, {
            fontSize,
            fontColor,
            fontFamily
        });
    }
}

function getLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, max: number, topMargin: number): LabelProperties[] {
    const locale = Office.context.contentLanguage || "en-US";
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const labelProperties = labelsDataPoints.map(d => {
        const value = viewModels.getDataPointNonNullValue(d);
        const isEnoughVerticalSpace = yScale(max - Math.abs(value)) - topMargin > settings.labelFontSize;
        let labelText = "";
        if (isEnoughVerticalSpace) {
            labelText = charting.getFormattedDataLabel(value, settings.decimalPlaces, settings.displayUnits, locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        }
        const isForecast = d.value !== null && settings.scenarioOptions.valueScenario === Scenario.Forecast
            || d.value === null && d.secondSegmentValue !== null && settings.scenarioOptions.secondValueScenario === Scenario.Forecast;
        const xPos = xScale(d.category) + xRangeBand * 0.5;
        const yPos = y + yScale(value / 2) + 4;
        return charting.getLabelProperty(labelText, xPos, yPos, null, value, d, settings.labelFontSize, settings.labelFontFamily, null, null, isForecast);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

function getVarianceLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, varianceLabelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const locale = Office.context.contentLanguage || "en-US";
    const labelProperties = labelsDataPoints.map(d => {
        let labelText = "";
        let labelValue = 0;
        if (settings.varianceLabel === DifferenceLabel.Relative) {
            labelValue = viewModels.calculateRelativeDifferencePercent((viewModels.getDataPointNonNullValue(d)), d.reference);
            labelText = charting.getRelativeVarianceLabel(settings, labelValue, false);
        }
        else {
            labelValue = viewModels.getDataPointNonNullValue(d) - d.reference;
            labelText = charting.getVarianceDataLabel(labelValue, settings.decimalPlaces, settings.displayUnits, locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, varianceLabelsFormat, hideUnits);
        }
        const xPos = xScale(d.category) + xRangeBand * 0.6;
        const maxVal = Math.max((viewModels.getDataPointNonNullValue(d)), d.reference);
        const belowTheBarLabelOffset = settings.labelFontSize + 2;
        let yPos = y + (maxVal >= 0 ? yScale(maxVal) - 5 : yScale(Math.min((viewModels.getDataPointNonNullValue(d)), d.reference)) + belowTheBarLabelOffset);
        if (settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute) {
            yPos += maxVal >= 0 ? -belowTheBarLabelOffset : belowTheBarLabelOffset;
        }

        return charting.getLabelProperty(labelText, xPos, yPos, null, labelValue, d, settings.labelFontSize, settings.labelFontFamily);
    });

    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

function getAdditionalLabelProperties(dataPoints: DataPoint[], settings: ChartSettings,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const labelProperties = labelsDataPoints.map(d => {
        const labelValue = viewModels.calculateRelativeDifferencePercent(viewModels.getDataPointNonNullValue(d), d.reference);
        const labelText = charting.getRelativeVarianceLabel(settings, labelValue, true);
        const xPos = xScale(d.category) + xRangeBand * 0.6;
        const maxVal = Math.max((viewModels.getDataPointNonNullValue(d)), d.reference);
        const yPos = y + (maxVal > 0 ? yScale(maxVal) - 5 : yScale(Math.min((viewModels.getDataPointNonNullValue(d)), d.reference)) + settings.labelFontSize + 2);
        return charting.getLabelProperty(labelText, xPos, yPos, null, labelValue, d, settings.labelFontSize, settings.labelFontFamily);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

function getReferenceLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const labelProperties = labelsDataPoints.map(d => {
        const value = d.reference;
        const labelText = charting.getFormattedDataLabel(value, settings.decimalPlaces, settings.displayUnits, settings.locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        const xPos = xScale(d.category) + xScale.bandwidth() / 2;
        const yPos = y + yScale(value) + (value < 0 ? settings.labelFontSize + 2 : - 5);
        return charting.getLabelProperty(labelText, xPos, yPos, null, value, d, settings.labelFontSize, settings.labelFontFamily);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, settings: ChartSettings) {
    const xScaleBandWidth = xScale.bandwidth();
    const markerAttrs = charting.getCommentMarkerAttributes(xScaleBandWidth);
    const labelsMargin = settings.showDataLabels ? (settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute ? 2 * settings.labelFontSize : settings.labelFontSize) + 4 : 0;
    const getMarkerVerticalPosition = (d: DataPoint): number => {
        return y + yScale(Math.max((viewModels.getDataPointNonNullValue(d)), d.reference)) - (labelsMargin + markerAttrs.radius + markerAttrs.margin);
    };
    const getMarkerHorizontalPosition = (d: DataPoint): number => xScale(d.category) + xScale.bandwidth() / 2;
    charting.addCommentMarkers(container, commentDataPoints, getMarkerHorizontalPosition, getMarkerVerticalPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}

