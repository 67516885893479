import { License } from "./components/License";
import { LicenseType } from "./licensing.types";

export function openURL(url: string) {
  if (
    Office.context.requirements.isSetSupported("OpenBrowserWindowApi", "1.1") &&
    Office.context.ui.openBrowserWindow
  ) {
    Office.context.ui.openBrowserWindow(url);
  } else {
    window.open(url, "_blank").focus();
  }
}

export function licenseToString(license: License) {
  if (!license) {
    return "Unknown";
  }

  if (license.hasLicense) {
    if (license.licenseType === LicenseType.Trial) {
      return "Trial";
    } else {
      return "Pro";
    }
  }

  return "Free";
}
