import { getOfficeSettings } from "@zebrabi/office-settings";
import { persistManagerInstance } from "@zebrabi/office-settings/PersistManager";
import { SETTING_GROUP_LICENSE } from "../constants";
import { licensing } from "../Licensing";
import { LicenseType, ProductType } from "../licensing.types";
import { Observable } from "@zebrabi/observable";
import { License } from "./License";
import { signInService } from "./signin/MSFTSignIn";
import { TrialStatus } from "../interfaces/TrialStatus";

abstract class UserInfo {
  private observer: Observable<UserInfo>;

  protected license: License;
  protected trialStatus: TrialStatus;

  public name: string;
  public email: string;
  public userId: string; // MSFT oid
  public organizationId: string; // MSFT tid

  public isViewer: boolean;

  protected constructor() {
    this.observer = new Observable<UserInfo>(this);
    this.isViewer = true;
  }

  protected async fetchLicenseInfo() {
    // eslint-disable-next-line no-undef
    const product = Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint;

    const resolveUserInfoUrl = `${process.env.LICENSING_SERVER_URL}/add-in/licenses/${this.organizationId}/${this.userId}/${product}`;
    const response = await fetch(resolveUserInfoUrl, {});

    return await response.json();
  }

  public async fetchTrialStatus(): Promise<TrialStatus> {
    const product = Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint;

    const trialStatusUrl = `${process.env.LICENSING_SERVER_URL}/add-in/trials/${this.organizationId}/${this.userId}/${product}`;
    const response = await fetch(trialStatusUrl);

    return await response.json();
  }

  public subscribe(handler: (value: UserInfo) => void) {
    this.observer.subscribe(handler);
  }

  public notify() {
    this.observer.setAndUpdate(this);
  }

  public isKnown(): boolean {
    return this.userId !== undefined;
  }

  public isSameUserAs(other: UserInfo): boolean {
    return this.userId === other?.userId;
  }

  public getName(): string {
    return this.name;
  }

  public getEmail(): string {
    return this.email;
  }

  public getValidFrom(): Date {
    return this.license.validFrom;
  }

  public getValidTo(): Date {
    return this.license.validTo;
  }

  public getUserID(): string {
    return this.userId;
  }

  public getOrganizationID(): string {
    return this.organizationId;
  }

  public getLicense(): License {
    return this.license;
  }

  public isTrial(): boolean {
    return this.trialStatus && (this.trialStatus.companyTrialValidTo !== null || this.trialStatus.personalTrialValidTo !== null);
  }
}

export class OwnerUserInfo extends UserInfo {
  protected static _instance: OwnerUserInfo;
  public lastDisplayedOverlay: Date;

  public constructor() {
    super();
  }

  public static getInstance(): OwnerUserInfo {
    return <OwnerUserInfo>OwnerUserInfo._instance;
  }

  public static async setOwnerInfo(userInfo: OwnerUserInfo) {
    persistManagerInstance.update({
      objectName: SETTING_GROUP_LICENSE,
      properties: {
        userInfo: {
          name: userInfo?.name,
          email: userInfo?.email,
          hasLicense: userInfo?.getLicense()?.hasLicense,
          userId: userInfo?.userId, // MSFT oid
          organizationId: userInfo?.organizationId, // MSFT tid
          isViewer: userInfo?.isViewer,
          lastDisplayedOverlay: userInfo?.lastDisplayedOverlay ? userInfo?.lastDisplayedOverlay.toUTCString() : undefined,
        },
      },
    });
  }

  static async createInstance() {
    const licenseStore = getOfficeSettings(SETTING_GROUP_LICENSE);

    if (licenseStore) {
      const userInfo = new OwnerUserInfo();

      if (licenseStore.userInfo) {
        userInfo.name = licenseStore.userInfo.name;
        userInfo.email = licenseStore.userInfo.email;
        userInfo.userId = licenseStore.userInfo.userId;
        userInfo.organizationId = licenseStore.userInfo.organizationId;
        userInfo.isViewer = licenseStore.userInfo.isViewer;
      }

      let licenseInfo;
      try {
        licenseInfo = await userInfo.fetchLicenseInfo();

        userInfo.license = License.createInstance({
          hasLicense: licenseInfo.licenseType === LicenseType.Paid || licenseInfo.licenseType === LicenseType.Trial,
          validFrom: licenseInfo.validFrom ? new Date(licenseInfo.validFrom) : undefined,
          validTo: licenseInfo.validTo ? new Date(licenseInfo.validTo) : undefined,
          licenseType: licenseInfo.licenseType
        });
      } catch (error) {
        console.error("Error CHECK LICENSE TYPE", error);
      }

      if (userInfo) {
        userInfo.lastDisplayedOverlay = licenseStore.userInfo?.lastDisplayedOverlay
          ? new Date(licenseStore.userInfo?.lastDisplayedOverlay)
          : undefined;
      }


      OwnerUserInfo._instance = userInfo;
    }

    return <OwnerUserInfo>OwnerUserInfo._instance;
  }
}
//export const ownerUserInfo = OwnerUserInfo.createInstance();

export class CurrentUserInfo extends UserInfo {
  protected static _instance: CurrentUserInfo;

  static getInstance(): CurrentUserInfo {
    return CurrentUserInfo._instance;
  }
  public static async createInstance() {
    const accessToken = signInService.getAccessToken(); // check if user is signed in
    const currentUserInfo = new CurrentUserInfo();

    if (!accessToken) {
      CurrentUserInfo._instance = currentUserInfo;
      return <CurrentUserInfo>CurrentUserInfo._instance;
    }

    try {
      const response = await currentUserInfo.fetchUserInfo(accessToken);

      currentUserInfo.name = response.name;
      currentUserInfo.email = response.email;
      currentUserInfo.userId = response.oid;
      currentUserInfo.organizationId = response.tid;
      currentUserInfo.isViewer = false;

      const licenseInfo = await currentUserInfo.fetchLicenseInfo();
      currentUserInfo.license = License.createInstance({
        hasLicense: licenseInfo.licenseType === LicenseType.Paid || licenseInfo.licenseType === LicenseType.Trial,
        validFrom: licenseInfo.validFrom ? new Date(licenseInfo.validFrom) : undefined,
        validTo: licenseInfo.validTo ? new Date(licenseInfo.validTo) : undefined,
        licenseType: licenseInfo.licenseType
      });

      const trialStatus = await currentUserInfo.fetchTrialStatus();
      currentUserInfo.trialStatus = trialStatus;

      if (!licensing.getOwnerUser()) {
        const ownerUserInfo = currentUserInfo?.convertToOwnerUserInfo();
        await OwnerUserInfo.setOwnerInfo(ownerUserInfo);
      }

      currentUserInfo.notify();
      CurrentUserInfo._instance = currentUserInfo;
    } catch (error) {
      console.error("Error resolving user info", error);
    }

    return <CurrentUserInfo>CurrentUserInfo._instance;
  }

  private async fetchUserInfo(accessToken: string) {
    const resolveUserInfoUrl = process.env.LICENSING_SERVER_URL + "/add-in/resolve-user-info";

    const response = await fetch(resolveUserInfoUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return await response.json();
  }

  public convertToOwnerUserInfo() {
    const ownerUserInfo = new OwnerUserInfo();
    if (ownerUserInfo) {
      ownerUserInfo.userId = this.userId;
      ownerUserInfo.organizationId = this.organizationId;
      ownerUserInfo.name = this.name;
      ownerUserInfo.email = this.email;
      ownerUserInfo.isViewer = this.isViewer;
    }

    return ownerUserInfo;
  }
}

export default UserInfo;
