import {
    DropdownSubscriber,
    InlineDropdown, InlineNumberInput, InlineTextInput,
    InlineToggleButton, NumberInputSubscriber, TextInputSubscriber,
    ToggleButtonSubscriber
} from "@zebrabi/design-library";
import { GlobalStackedChartSettings, ShowTopNChartsOptions, Sort } from "../../enums";
import {
    TOP_N_OPTION_ITEMS, TOP_N_OPTION_OFF, TOP_N_OPTION_PERCENTAGE, STACKED_CHART_SORT_OPTION_DESCENDING,
    STACKED_CHART_SORT_OPTION_ASCENDING, STACKED_CHART_SORT_OPTION_ORIGINAL_ORDER
} from "../../library/constants";
import { DesignLibraryComponent } from "../../library/types";
import { Visual } from "../../visual";
import GlobalStackedChartMenuItemSubscriberFactory from "./GlobalStackedChartMenuItemSubscriberFactory";

export default class GlobalStackedChartMenuItemFactory {
    private chartSettings = Visual.settings;
    private subscriberFactory = new GlobalStackedChartMenuItemSubscriberFactory();

    public getItem(menuItem: GlobalStackedChartSettings): DesignLibraryComponent {
        switch (menuItem) {
            case GlobalStackedChartSettings.showStackedLabelsAsPercentage: {
                const labelsAsPercentageInlineToggleButton = new InlineToggleButton(this.chartSettings.showStackedLabelsAsPercentage, {
                    label: "Labels as % of total",
                    name: "labels-as-percentage"
                });
                labelsAsPercentageInlineToggleButton.subscribe(<ToggleButtonSubscriber>this.subscriberFactory.getSubscriber(menuItem));
                return labelsAsPercentageInlineToggleButton;
            }
            case GlobalStackedChartSettings.showTopNStackedOptions: {
                const topNoptionsInlineDropdown = new InlineDropdown<ShowTopNChartsOptions>("Top N", this.chartSettings.showTopNStackedOptions, {
                    name: "top-n-options"
                }, [
                    { label: TOP_N_OPTION_OFF, value: ShowTopNChartsOptions.Off },
                    { label: TOP_N_OPTION_ITEMS, value: ShowTopNChartsOptions.Items },
                    { label: TOP_N_OPTION_PERCENTAGE, value: ShowTopNChartsOptions.Percentage }
                ]);
                topNoptionsInlineDropdown.subscribe(<DropdownSubscriber<ShowTopNChartsOptions>>this.subscriberFactory.getSubscriber(menuItem));
                return topNoptionsInlineDropdown;
            }
            case GlobalStackedChartSettings.topNStackedToKeep: {
                const topNtoKeepInlineNumberInput = new InlineNumberInput("Number of items", this.chartSettings.topNStackedToKeep, {
                    name: "top-n-to-keep",
                    min: 1,
                    max: 99,
                    id: "top-n-child-1"
                });
                topNtoKeepInlineNumberInput.subscribe(<NumberInputSubscriber>this.subscriberFactory.getSubscriber(menuItem));
                return topNtoKeepInlineNumberInput;
            }
            case GlobalStackedChartSettings.topNStackedPercentage: {
                const topNStackedPercentageInlineNumberInput = new InlineNumberInput("Percent", this.chartSettings.topNStackedPercentage, {
                    name: "top-n-to-keep-percent",
                    min: 0,
                    max: 100,
                    id: "top-n-child-1"
                });
                topNStackedPercentageInlineNumberInput.subscribe(<NumberInputSubscriber>this.subscriberFactory.getSubscriber(menuItem));
                return topNStackedPercentageInlineNumberInput;
            }
            case GlobalStackedChartSettings.topNStackedOthersLabel: {
                const topNStackedOthersLabelInlineTextInput = new InlineTextInput("Others label", this.chartSettings.topNStackedOthersLabel, {
                    name: "top-n-other-label",
                    id: "top-n-child-2"
                });
                topNStackedOthersLabelInlineTextInput.subscribe(<TextInputSubscriber>this.subscriberFactory.getSubscriber(menuItem));
                return topNStackedOthersLabelInlineTextInput;
            }
            case GlobalStackedChartSettings.stackedAreaOpacity: {
                const areaOpacityInlineNumberInput = new InlineNumberInput("Area opacity (%)", this.chartSettings.stackedAreaOpacity, {
                    name: "area opacity",
                    min: 0,
                    max: 100,
                });
                areaOpacityInlineNumberInput.subscribe(<NumberInputSubscriber>this.subscriberFactory.getSubscriber(menuItem));
                return areaOpacityInlineNumberInput;
            }
            case GlobalStackedChartSettings.stackedChartSort: {
                const sortDropdown = new InlineDropdown<Sort>("Sort", this.chartSettings.stackedChartSort, {
                    name: "sort"
                }, [
                    { label: STACKED_CHART_SORT_OPTION_DESCENDING, value: Sort.Descending },
                    { label: STACKED_CHART_SORT_OPTION_ASCENDING, value: Sort.Ascending },
                    { label: STACKED_CHART_SORT_OPTION_ORIGINAL_ORDER, value: Sort.None }
                ]);
                sortDropdown.subscribe(<DropdownSubscriber<Sort>>this.subscriberFactory.getSubscriber(menuItem));
                return sortDropdown;
            }
        }
    }
}