import { DataPoint, FontSettings } from "../interfaces";
import * as d3 from "../d3";
import CategoryMenu from "../components/CategoryMenu";
import { calculateSplitButtonOverlayPosition, repositionElementInsideViewPort, setElementFontStyles } from "../helpers";
import { Visual } from "../visual";
import { BaseType } from "../d3";
import {
    ACTIVE,
    CLICK,
    CONTEXT_MENU2_DIV,
    FIXED,
    MOUSEENTER,
    MOUSELEAVE, OVERLAY,
    STROKE,
    TRANSPARENT
} from "../library/constants";
import {
    SplitButton, SplitButtonSubscriber, Position, ButtonStateTypes, SplitButtonData,
    SplitButtonActions
} from "@zebrabi/design-library";
import { addOverlayProperties } from "./overlayHelpers";

export function showOverlayOnCategoryHover(categories: d3.Selection<BaseType, DataPoint, SVGElement, any>, isVertical: boolean, fontSettings: FontSettings): void {
    const overlayContainer = <HTMLElement>document.querySelector(".zebrabi-charts-container");
    let timeOut;
    categories.on(MOUSEENTER, (e: MouseEvent, dp: DataPoint) => {
        if (!overlayContainer.querySelector(`.${OVERLAY}.${ACTIVE}`)) {
            timeOut = setTimeout(() => {
                // in this case category menu does not contain any items
                if (!dp || dp.isOther && !Visual.viewModel.isSingleSeriesViewModel) {
                    return;
                }

                showCategoryLabelOverlay(dp, overlayContainer, e.target as HTMLElement, isVertical, fontSettings);
            }, 200);
        }
    });
    categories.on(MOUSELEAVE, () => {
        clearTimeout(timeOut);
    });
}

function showCategoryLabelOverlay(dp: DataPoint, overlayContainer: HTMLElement, categoryLabel: HTMLElement, isVertical = false, fontSettings: FontSettings): void {
    overlayContainer?.querySelector(".split-btn")?.remove();

    const arrowButtonPosition = isVertical ? Position.Right : Position.Top;
    const arrowButtonRotation = arrowButtonPosition;
    const splitButton = new SplitButton(
        dp.isCategoryInverted ? `- ${dp.category}` : dp.category,
        ButtonStateTypes.outlined,
        undefined,
        { arrowButtonPosition, arrowButtonRotation }
    );

    splitButton.appendTo(overlayContainer);
    const buttonNode = splitButton.defaultButton.buttonNode();
    setElementFontStyles(buttonNode, fontSettings);

    addOverlayProperties(splitButton.buttonNode());
    const overlay = splitButton.buttonNode();
    const categoryLabelBoundingRect = categoryLabel.getBoundingClientRect();
    const overlayBoundingRect = overlay.getBoundingClientRect();
    const arrowButtonWidth = 23;
    calculateSplitButtonOverlayPosition(
        overlay,
        categoryLabelBoundingRect,
        {
            verticalLeft: (-overlayBoundingRect.width + arrowButtonWidth) / 2 + categoryLabelBoundingRect.width / 2,
            verticalTop: - overlayBoundingRect.height / 2 + categoryLabelBoundingRect.height / 2,
            horizontalLeft: categoryLabelBoundingRect.width / 2 - overlayBoundingRect.width / 2,
            horizontalTop: (-arrowButtonWidth - overlayBoundingRect.height + categoryLabelBoundingRect.height) / 2
        },
        isVertical
    );

    const splitButtonSubscriber = new SplitButtonSubscriber();
    splitButtonSubscriber.update = (message: SplitButtonData, action?: string) => {
        if (action === SplitButtonActions.IconClick) {
            toggleCategoryMenu(dp, overlayContainer, isVertical);
        }
    };
    splitButton.subscribe(splitButtonSubscriber);
}

function toggleCategoryMenu(dp: DataPoint, appendTo: HTMLElement, isVertical = false): void {
    document.querySelector(`.${CONTEXT_MENU2_DIV}:not(.category)`)?.remove();
    const rect = d3.select(`.global-category-outline.${ACTIVE}`);
    rect.attr(STROKE, TRANSPARENT);
    rect.classed(ACTIVE, false);

    if (!appendTo.querySelector(`.${CONTEXT_MENU2_DIV}.category`)) {
        const categoryMenu = new CategoryMenu(dp).appendTo(appendTo);
        const arrow = <HTMLElement>appendTo.querySelector(".split-btn");
        arrow.classList.add(ACTIVE);

        calculateCategoryMenuPosition(categoryMenu, arrow, isVertical);

        const closeMenuOnOutsideClick = (event: MouseEvent) => {
            const categoryMenu = appendTo.querySelector(`.${CONTEXT_MENU2_DIV}.category`);
            const arrow = appendTo.querySelector(".split-btn");

            if (categoryMenu && !event.composedPath().includes(arrow) && !event.composedPath().includes(categoryMenu)) {
                categoryMenu.remove();
                arrow.remove();
                document.removeEventListener(CLICK, closeMenuOnOutsideClick);
            }
        };

        document.addEventListener(CLICK, closeMenuOnOutsideClick);
        document.addEventListener(MOUSELEAVE, () => {
            appendTo.querySelector(`.${CONTEXT_MENU2_DIV}`)?.remove();
            appendTo.querySelector(".split-btn")?.remove();
        });
    } else {
        appendTo.querySelector(`.${CONTEXT_MENU2_DIV}`)?.remove();
        appendTo.querySelector(".split-btn")?.remove();
    }
}

function calculateCategoryMenuPosition(categoryMenu: HTMLElement, splitButton: HTMLElement, isVertical: boolean): void {
    categoryMenu.style.position = FIXED;
    if (isVertical) {
        categoryMenu.style.left = `${splitButton.getBoundingClientRect().left + splitButton.getBoundingClientRect().width + 10}px`;
        categoryMenu.style.top = `${splitButton.getBoundingClientRect().top - categoryMenu.getBoundingClientRect().height / 2 + splitButton.getBoundingClientRect().height/2}px`;

        // when split button is on the lower half of screen set bottom instead top property in order for menu to expand upwards
        // this is needed for edge cases when menu could expand outside view port
        if (splitButton.getBoundingClientRect().top > window.innerHeight / 2) {
            categoryMenu.style.top = "auto";
            categoryMenu.style.bottom = `${window.innerHeight - splitButton.getBoundingClientRect().bottom + splitButton.getBoundingClientRect().height / 2 - categoryMenu.getBoundingClientRect().height / 2}px`;
        }
    } else {
        categoryMenu.style.left = `${splitButton.getBoundingClientRect().left + splitButton.getBoundingClientRect().width / 2 - categoryMenu.getBoundingClientRect().width / 2}px`;
        categoryMenu.style.bottom = `${window.innerHeight - splitButton.getBoundingClientRect().top + 10}px`;
    }

    repositionElementInsideViewPort(categoryMenu);
}

