import { ChartType, DifferenceHighlightOption } from "./enums";
import { TextOptions, ScenarioOptions, SplitButtonOffset, FontSettings } from "./interfaces";
import { ChartSettings } from "./settings/chartSettings";
import { Title } from "./charting/title";
import { TEXT, FONT_SIZE, FONT_FAMILY, TEXT_ANCHOR, PX, X, Y, FILL, FONT_SIZE_UNIT, Scenario } from "./library/constants";

import * as d3 from "./d3";
import { Comparator } from "./library/types";
import { DIFFERENCE_HIGHLIGHT_FROM_TO_OPTIONS } from "./library/dropdownOptionsConstants";
import { LabelProperties } from "./library/interfaces";

export function dayInMiliseconds() {
    return 1000 * 60 * 60 * 24;
}

export function drawTextWithOptions(options: TextOptions): d3.Selection<SVGElement, any, any, any> {
    return options
        .container
        .append(TEXT)
        .text(options.text)
        .attr(X, Math.round(options.x))
        .attr(Y, Math.round(options.y + options.fontSize * 0.35))
        .attr(FILL, options.color)
        .style(FONT_SIZE, `${options.fontSize}${FONT_SIZE_UNIT}`)
        .style(FONT_FAMILY, options.fontFamily)
        .style(TEXT_ANCHOR, options.textAnchor)
        .classed(options.textClass, true);
}

export function plotTitleResponsive(titleText: string, settings: ChartSettings, parentElement: HTMLElement, svg: d3.Selection<SVGElement, any, any, any>) {
    if (!settings.showTitle) {
        return 0;
    }
    const title = new Title(settings, parentElement, titleText, svg);
    title.addTitle();

    return title.getTitleHeight();
}

// Marker sizes used for combo and variance chart
export function calculateMarkerFixedSize(markerSize: number, borderWidth?: number) {
    return calculateMarkerArea(markerSize, borderWidth);
}

export function calculateMarkerAutoSize(ordinalScale: d3.ScaleBand<string>, settings: ChartSettings, borderWidth?: number) {
    return calculateMarkerArea(getMarkerBasis(ordinalScale, settings), borderWidth);
}

export function calculateMarkerArea(base: number, borderWidth: number = 0) {
    return Math.ceil(Math.pow(base - 3 * borderWidth, 2)); // formula based on standard definition | for plan, draw border inside
}

function getMarkerBasis(ordinalScale: d3.ScaleBand<string>, settings: ChartSettings) {
    const markerBasis: number = ordinalScale.bandwidth() * 0.3;  // h = category_width / 3
    const minMarkerSize: number = settings.labelFontSize * 0.6;

    // Marker sizing (max, min) is limited by text size - from standard definition
    if (markerBasis > settings.labelFontSize) {
        return settings.labelFontSize;
    } else if (markerBasis < minMarkerSize) {
        return minMarkerSize;
    } else {
        return markerBasis;
    }
}

/**
 * Returns object containing key: value mapping between scenario key name and value used for legend text.
 * e.g.: { legendText: "AC", legendScenarioKey: "valueHeader" }
 *
 * @param key
 * @param value
 * @returns object
 */
export function mapScenarioKeyAndName(key: keyof ChartSettings, value: string, scenarioOptions: ScenarioOptions) {
    const legendObject: any = {};

    legendObject.legendText = value;
    const scenarioKey = getLegendScenarioKeyFromLegendHeader(key, scenarioOptions);
    legendObject.legendScenarioKey = scenarioKey;

    return legendObject;
}

export function getLegendScenarioKeyFromLegendHeader(property: keyof ChartSettings, scenarioOptions: ScenarioOptions): keyof ChartSettings {
    switch (property) {
        case "valueHeader":
            return getScenarioSettingName(scenarioOptions.valueScenario);
        case "referenceHeader":
            return getScenarioSettingName(scenarioOptions.referenceScenario);
        case "secondReferenceHeader":
            return getScenarioSettingName(scenarioOptions.secondReferenceScenario);
        case "secondValueHeader":
            return getScenarioSettingName(scenarioOptions.secondValueScenario);
        case "absoluteDifferenceHeader":
            return getScenarioDifferenceSettingName(scenarioOptions.valueScenario, scenarioOptions.referenceScenario, false);
        case "secondAbsoluteDifferenceHeader":
            return getScenarioDifferenceSettingName(scenarioOptions.valueScenario, scenarioOptions.secondReferenceScenario, false);
        case "relativeDifferenceHeader":
            return getScenarioDifferenceSettingName(scenarioOptions.valueScenario, scenarioOptions.referenceScenario, true);
        case "secondRelativeDifferenceHeader":
            return getScenarioDifferenceSettingName(scenarioOptions.valueScenario, scenarioOptions.secondReferenceScenario, true);
        default:
            return getScenarioSettingName(scenarioOptions.valueScenario);
    }
}

function getScenarioSettingName(scenario: Scenario): keyof ChartSettings {
    switch (scenario) {
        case Scenario.Actual:
            return "actual";
        case Scenario.PreviousYear:
            return "previousYear";
        case Scenario.Plan:
            return "plan";
        case Scenario.Forecast:
            return "forecast";
    }
}

function getScenarioDifferenceSettingName(valueScenario: Scenario, referenceScenario: Scenario, isRelative: boolean): keyof ChartSettings {
    switch (valueScenario) {
        case Scenario.Actual:
            if (referenceScenario === Scenario.PreviousYear) {
                return isRelative ? "actual_previousYear_percent" : "actual_previousYear";
            } else if (referenceScenario === Scenario.Plan) {
                return isRelative ? "actual_plan_percent" : "actual_plan";
            } else if (referenceScenario === Scenario.Forecast) {
                return isRelative ? "actual_forecast_percent" : "actual_forecast";
            }
            break;
        case Scenario.PreviousYear:
            if (referenceScenario === Scenario.Plan) {
                return isRelative ? "previousYear_plan_percent" : "previousYear_plan";
            }
            break;
        case Scenario.Forecast:
            if (referenceScenario === Scenario.PreviousYear) {
                return isRelative ? "forecast_previousYear_percent" : "forecast_previousYear";
            } else if (referenceScenario === Scenario.Plan) {
                return isRelative ? "forecast_plan_percent" : "forecast_plan";
            }
            break;
        default:
            return null;
    }
}

export function repositionElementInsideViewPort(element: HTMLElement): void {
    const boundingRect = element.getBoundingClientRect();

    if (boundingRect.x < 0) {
        element.style.left = "10px";
        element.style.right = "auto";
    } else if (boundingRect.x + boundingRect.width > window.innerWidth) {
        element.style.left = "auto";
        element.style.right = "10px";
    } else if (boundingRect.y < 0) {
        element.style.top = "10px";
        element.style.bottom = "auto";
    } else if (boundingRect.y + boundingRect.height > window.innerHeight) {
        element.style.top = "auto";
        element.style.bottom = "10px";
    }
}

export function calculateSplitButtonOverlayPosition(button: HTMLElement, labelBoundingRect: DOMRect, splitButtonOffset: SplitButtonOffset, isVertical: boolean) {
    if (isVertical) {
        button.style.left = `${labelBoundingRect.left + splitButtonOffset.verticalLeft}px`;
        button.style.top = `${labelBoundingRect.top + splitButtonOffset.verticalTop}px`;
    } else {
        button.style.left = `${labelBoundingRect.left + splitButtonOffset.horizontalLeft}px`;
        button.style.top = `${labelBoundingRect.top + splitButtonOffset.horizontalTop}px`;
    }
}

export function setElementFontStyles(element: HTMLElement, fontSettings: FontSettings) {
    Object.entries(fontSettings).forEach((entry: [string, string]) => {
        const fontStyleProperty = entry[0];
        const fontStyleValue = entry[1];

        element.style[fontStyleProperty] = fontStyleValue;

        if (fontStyleProperty === "fontSize") {
            element.style[fontStyleProperty] = fontStyleValue + FONT_SIZE_UNIT;
        }
    });
}

/**
 * Enable the option in the category menu to invert a category
 * */
export function enableToggleInvert(isOther: boolean, isCumulative: boolean, total?: boolean): boolean {
    return !total && !isOther && !isCumulative;
}

/**
 * Enable the option in the category menu to mark category as a result
 * */
export function enableToggleResult(isOther: boolean, isSingleSeriesWaterfall: boolean, isCumulative: boolean, total?: boolean): boolean {
    return isSingleSeriesWaterfall && !isOther && (!isCumulative || total);
}

/**
 * Enable the option in the category menu to mark category as a floating result
 * */
export function enableToggleFloatingResult(settings: ChartSettings, category: string): boolean {
    return settings.isCategoryResult(category) && !settings.showVerticalCharts;
}

/**
 * Enable the switch in the category menu to change category pattern according to chosen scenario
 * */
export function enableToggleScenarioPatterns(settings: ChartSettings, category: string): boolean {
    return settings.isCategoryResult(category);
}

/**
 * Enable the option in the category menu to highlight a category with a particular color
 * */
export function enableToggleHighlight(chartType: ChartType, isSingleSeriesWaterfall: boolean, isCumulative: boolean): boolean {
    return chartType !== ChartType.Waterfall || isSingleSeriesWaterfall || isCumulative;
}

export function sortAscending<T>(array: T[], getProperty: (item: T) => number): void {
    const ascendingComparator: Comparator<T> = (a: T, b: T) => getProperty(a) - getProperty(b);
    array.sort(ascendingComparator);
}

export function sortDescending<T>(array: T[], getProperty: (item: T) => number): void {
    const descendingComparator: Comparator<T> = (a: T, b: T) => getProperty(b) - getProperty(a);
    array.sort(descendingComparator);
}

export function sanitizeDrawingAttribute(value: number, backupValue: number = 0): number {
    return Number.isNaN(value) ? 0 : Math.max(value ?? 0, backupValue);
}

export function getDifferenceHighlightOptions(isSingleSeriesViewModel: boolean, secondValueScenario: Scenario): Array<{ value: DifferenceHighlightOption, label: string }> {
    let validFromToValues: DifferenceHighlightOption[] = [DifferenceHighlightOption.Auto, DifferenceHighlightOption.FirstToLast, DifferenceHighlightOption.PenultimateToLast, DifferenceHighlightOption.MinToMax];

    if (secondValueScenario !== null) {
        if (secondValueScenario === Scenario.Forecast) {
            validFromToValues = [...validFromToValues, ...[DifferenceHighlightOption.LastACtoLastFC, DifferenceHighlightOption.FirstToLastFC, DifferenceHighlightOption.PenultimateToLastFC, DifferenceHighlightOption.LastFCtoCorresponding]];
        } else {
            validFromToValues = [...validFromToValues, ...[DifferenceHighlightOption.FirstToLastPL, DifferenceHighlightOption.PenultimateToLastPL, DifferenceHighlightOption.LastPLtoCorresponding]];
        }
    }

    const validDifferenceHighlightOptions = DIFFERENCE_HIGHLIGHT_FROM_TO_OPTIONS.filter((option) => {
        return validFromToValues.includes(option.value);
    });

    return validDifferenceHighlightOptions;
}

export function repositionElement(element: HTMLElement, top: number, bottom: number, diff: number): void {
    if (top !== null && !isNaN(top)) {
        element.style.top = `${top - diff}px`;
    }
    if (bottom !== null && !isNaN(bottom)) {
        element.style.bottom = `${bottom + diff}px`;
    }
}

/**
 * Maps additional label properties to the original labels.
 *
 * The function iterates over an array of additional labels. For each additional label,
 * it finds the corresponding original label by comparing the 'category' property.
 * It then updates both the original label and the additional label based on the
 * properties of each other.
 *
 * @export
 * @param {LabelProperties[]} additionalVarianceLabelsProperties - Array of additional label properties.
 * @param {d3.Selection<d3.BaseType, LabelProperties, SVGElement, any>} varianceLabels - Original labels data.
 * @param {number} labelFontSize - Font size for the labels.
 */
export function mapAdditionalLabelsWithOriginal(
    additionalVarianceLabelsProperties: LabelProperties[],
    varianceLabels: d3.Selection<d3.BaseType, LabelProperties, SVGElement, any>,
    labelFontSize: number) {

    additionalVarianceLabelsProperties.forEach((additionalLabel: LabelProperties) => {
        const upperLabel = varianceLabels.data().find((value: LabelProperties) => value.category === additionalLabel.category);
        if (upperLabel) {
            upperLabel.additionalTextLabel = additionalLabel.text;
            upperLabel.additionalValueLabel = additionalLabel.value;

            additionalLabel.additionalLabelY = upperLabel.y - labelFontSize;
            additionalLabel.additionalValueLabel = upperLabel.value;
            additionalLabel.additionalTextLabel = upperLabel.text;
        }
    });
}

export function delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

