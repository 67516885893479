import React from "react";
import useStore from "../../useStore";
import exelWorksheetIcon from "../images/excel-worksheet.svg";
import excelTableIcon from "../images/excel-table.svg";
import { SharePointWorkbook, SharePointWorksheet } from "../../ExcelDataLinking.types";

interface ComponentProps {
  workbooks: SharePointWorkbook[];
  worksheets: SharePointWorksheet[];
}

export default function WorkbooksAndWorksheetsListing({ workbooks, worksheets }: ComponentProps) {
  const [activeWorksheetId, setActiveWorksheetId] = useStore("activeWorksheetId");
  const [activeWorkbookId, setActiveWorkbookId] = useStore("activeWorkbookId");

  const selectWorkbook = (workbookId: string) => {
    setActiveWorkbookId(workbookId);
    setActiveWorksheetId("");
  };

  const selectWorksheet = (worksheetId: string) => {
    setActiveWorksheetId(worksheetId);
    setActiveWorkbookId("");
  };

  return (
    <div className="workbooks-and-worksheets">
      <ul>
        {workbooks.map((workbook) => (
          <li key={workbook.id}>
            <button
              className={activeWorkbookId === workbook.id ? "active" : ""}
              onClick={() => {
                selectWorkbook(workbook.id);
              }}
            >
              <img src={excelTableIcon} alt="Table" />
              {workbook.name}
            </button>
          </li>
        ))}

        {worksheets.map((worksheet) => (
          <li key={worksheet.id}>
            <button
              className={worksheet.id === activeWorksheetId ? "active" : ""}
              onClick={() => {
                selectWorksheet(worksheet.id);
              }}
            >
              <img src={exelWorksheetIcon} alt="Worksheet" />
              {worksheet.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
