import { ActionType } from "../ActionType";
import ActionObserver from "../observers/ActionObserver";
import ActionSubject from "../subject/ActionSubject";
import { WatermarkConfig } from "../interfaces/WatermarkConfig";
import Overlay from "./Overlay";
import { watermarkBar } from "../template/watermarkBar";
import { openURL } from "../helpers";

export const ACTION_WATERMARK_FOOTER_LINK = "footerLink";

class Watermark extends HTMLElement {
  private actionSubject = new ActionSubject();
  private overlay = new Overlay();
  private _watermarkConfig: WatermarkConfig = {
    text: "",
    linktext: "",
    link: "",
    signin: "",
    retry: ""
  };

  set watermarkConfig(watermarkConfig: WatermarkConfig) {
    this._watermarkConfig = watermarkConfig;
    this.render(watermarkConfig);
  }

  get watermarkConfig() {
    return this._watermarkConfig;
  }

  constructor() {
    super();

    const signInButton = this.overlay.querySelector(".zbi-sign-in button.sign-in");
    signInButton?.addEventListener("click", () => {
      this.actionSubject.notify(ActionType.SignIn);
    });

    const getProLicenseButton = this.overlay.querySelector(".zbi-sign-in button.buy");
    getProLicenseButton?.addEventListener("click", () => {
      this.actionSubject.notify(ActionType.Buy);
    });

    const adminButton = this.overlay.querySelector(".zbi-sign-in .admin");
    adminButton?.addEventListener("click", () => {
      this.actionSubject.notify(ActionType.Admin);
    });

    const startTrialButton = this.overlay.querySelector(".zbi-sign-in button.trial");
    startTrialButton?.addEventListener("click", () => {
      this.actionSubject.notify(ActionType.Trial);
    });

    const footerLink = this.overlay.querySelector(".zbi-sign-in a");
    footerLink?.addEventListener("click", () => {
      this.actionSubject.notify(ACTION_WATERMARK_FOOTER_LINK);
    });
  }

  private render(config: WatermarkConfig) {
    const range = document.createRange();

    this.overlay.close();
    this.close();

    const barTemplate = range.createContextualFragment(watermarkBar(config));

    const signInButton = barTemplate.querySelector(".zbi-watermark button.sign-in");
    signInButton?.addEventListener("click", () => {
      this.actionSubject.notify(ActionType.SignIn);
    });

    const retryButton = barTemplate.querySelector(".zbi-watermark button.retry");
    retryButton?.addEventListener("click", () => {
      this.actionSubject.notify(ActionType.RetryFetchLicensing);
    });

    const startFreeTrialButton = barTemplate.querySelector(".zbi-watermark button.free-trial");
    startFreeTrialButton?.addEventListener("click", () => {
      openURL(config.link)
    });

    const textUrl = barTemplate.querySelector(".watermark-text-url");
    textUrl?.addEventListener("click", () => {
      const url = config.textWithLink?.find(item => item.url)?.url;
      if (url) {
        openURL(url)
      }
    });

    document.body.append(barTemplate);
  }

  public close() {
    const watermark = document.getElementById("zbiWatermark");
    watermark?.parentNode?.removeChild(watermark);
  }

  public attach(actionObserver: ActionObserver) {
    this.actionSubject.attach(actionObserver);
  }
}

if (customElements.get("zebra-watermark") === undefined) {
  customElements.define("zebra-watermark", Watermark);
}

export default Watermark;
