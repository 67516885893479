import { LabelAlignment } from "../library/types";
import { ChartData, DataPoint } from "./../interfaces";
import { ChartType, DifferenceHighlightFromTo } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { LabelProperties } from "./../library/interfaces";
import {
    AXIS, BAR, CHART_AREA, ChartStyle, CIRCLE, CX, CY, DR_HICHERT_SQUARE_MARKER_WIDTH, END, FILL, G, HEIGHT,
    HIGHLIGHTABLE, ITALIC, MARKER, R, RECT, START, WIDTH, X, Y
} from "./../library/constants";
import { CHART_CONTAINER, VALUE } from "./../consts";

import * as drawing from "./../library/drawing";
import * as charting from "./chart";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as d3 from "../d3";
import { mapScenarioKeyAndName } from "../helpers";
import { plotChartLegendSettings } from "../ui/drawGlobalLegendMenuOverlay";

export default function verticalPinChart(reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement, x: number, y: number, height: number, width: number,
    chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number, plotCategories: boolean, plotTitle: boolean,
    plotLegend: boolean, leftMargin: number, rightMargin: number, leftMarginCategories: number) {

    const scenarioOptions = settings.scenarioOptions;
    const dataLabelsMargin = settings.showDataLabels || settings.showCommentMarkers() ? rightMargin : 0;
    const categoriesMargin = plotCategories ? leftMargin : 1;
    if (plotLegend) {
        topMargin += settings.labelFontSize;
    }
    const chartYPos = y + topMargin;
    const chartHeight = height - topMargin - bottomMargin;

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    const yScale = charting.getOrdinalScale(chartData, chartYPos, chartHeight, 0.2);
    const xScale = charting.getLinearScale(min, max, x + categoriesMargin, x + width - (dataLabelsMargin));
    const setBarHeightToZero = min === 0 && max === 0;
    const valuesDataPoints = chartData.dataPoints.filter(dp => dp.value !== null);

    charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, x + categoriesMargin, y,
        charting.getChartTitleWidth(width - categoriesMargin, settings), height, max, min, topMargin, bottomMargin);
    if (settings.showCategories && plotCategories) {
        charting.plotVerticalCategories(chartContainer, settings, chartData, x, yScale, leftMarginCategories, chartIndex);
    }
    // plot axis
    drawing.drawLine(chartContainer, xScale(0), xScale(0), chartYPos, chartYPos + chartHeight, 1, settings.colorScheme.axisColor, AXIS);

    if (plotLegend && valuesDataPoints.length > 0) {
        const firstDP = valuesDataPoints[0];
        const legendYPosition = yScale(firstDP.category);
        const legendXPosition = xScale(firstDP.value > 0 ? 0 : firstDP.value) + Math.abs(xScale(firstDP.value / 2) - xScale(0));
        charting.plotChartLegend(chartContainer, mapScenarioKeyAndName("valueHeader", settings.valueHeader, scenarioOptions), legendXPosition, legendYPosition, settings);
    }

    const chartArea = drawing.createGroupElement(chartContainer, `${CHART_AREA}-${chartIndex}`);
    const barsClass = `${BAR}_RELATIVE_${chartIndex} ${HIGHLIGHTABLE}`;
    const bars: d3.Selection<any, DataPoint, any, any> = drawing.getShapes(chartArea, barsClass, barsClass, RECT, valuesDataPoints);
    const rangeBandHalf = yScale.bandwidth() / 2;
    bars
        .attr(HEIGHT, 1)
        .attr(WIDTH, d => setBarHeightToZero ? 0 : Math.abs(xScale(d.value) - xScale(0)))
        .attr(X, d => xScale(d.value > 0 ? 0 : d.value))
        .attr(Y, d => yScale(d.category) + yScale.bandwidth() / 2)
        .attr(FILL, d => settings.getCategoryDataPointColor(d.category, d.color));

    plotVerticalPinChartMarkers(settings, chartIndex, chartArea, valuesDataPoints, xScale, yScale, rangeBandHalf);

    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    if (settings.showDataLabels) {
        const hideUnits = settings.shouldHideDataLabelUnits();
        const labelsProperties = getVerticalPinChartLabelProperties(valuesDataPoints, settings, labelsFormat, hideUnits, yScale, xScale);
        if (labelsProperties.length > 0) {
            charting.plotLabels(chartArea, `${chartIndex}`, labelsProperties, settings, ITALIC);
        }
    }

    if (settings.differenceHighlight && valuesDataPoints.length > 1) {
        let { fromDataPoint, toDataPoint } = charting.getDiffHighlightAutoDataPoints(valuesDataPoints, ChartType.Pin, true);
        if (settings.differenceHighlightFromTo !== DifferenceHighlightFromTo.Auto) {
            const fromToDataPoints = charting.getDiffHighlightDataPoints(valuesDataPoints, settings.differenceHighlightFromTo);
            fromDataPoint = fromToDataPoints.fromDP;
            toDataPoint = fromToDataPoints.toDP;
        }
        if (fromDataPoint && toDataPoint) {
            const valueProperty: keyof DataPoint = VALUE;
            const endY =
                yScale(chartData.dataPoints[chartData.dataPoints.length - 1].category) +
                yScale.bandwidth() / 2 +
                yScale.step();

            if (fromDataPoint !== toDataPoint && (fromDataPoint[valueProperty] !== null && toDataPoint[valueProperty] !== null)) {
                const startValue = fromDataPoint[valueProperty];
                const endValue = toDataPoint[valueProperty];
                const dhX1 = xScale(endValue);
                const dhX2 = xScale(startValue);
                const dhY1 = yScale(toDataPoint.category) + rangeBandHalf;
                const dhY2 = yScale(fromDataPoint.category) + rangeBandHalf;
                charting.addVerticalDifferenceHighlight(chartContainer, settings, slider, endY, dhX1, dhX2, endValue, startValue, dhY1, dhY2, chartData.isInverted);
            }
        }
    }

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, settings, rightMargin);
        }
    }

    charting.addMouseHandlers(bars, reportArea);
    const varianceLabelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);
    // Visual.tooltipServiceWrapper.addTooltip(chartArea,
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => charting.getTooltipData(tooltipEvent.data, settings, labelsFormat, varianceLabelsFormat, chartData.isInverted, undefined, chartData),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => tooltipEvent.data ? tooltipEvent.data.selectionId : null);
    bars.exit().remove();

    if (plotLegend && valuesDataPoints.length > 0) {
        const firstDP = valuesDataPoints[0];
        const legendYPosition = yScale(firstDP.category);
        const legendXPosition = xScale(firstDP.value > 0 ? 0 : firstDP.value) + Math.abs(xScale(firstDP.value / 2) - xScale(0));
        charting.plotChartLegend(chartContainer, mapScenarioKeyAndName("valueHeader", settings.valueHeader, scenarioOptions), legendXPosition, legendYPosition, settings);
        plotChartLegendSettings(chartContainer, x, y, width);
    }
}

function plotVerticalPinChartMarkers(settings: ChartSettings, chartIndex: number, chartArea: d3.Selection<SVGElement, any, any, any>, valuesDataPoints: DataPoint[],
    xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>, rangeBandHalf: number) {

    if (settings.chartStyle === ChartStyle.DrHichert) {
        const markersClass = `${BAR}${MARKER}${chartIndex} ${HIGHLIGHTABLE}`;
        const rectangles = drawing.getShapes(chartArea, markersClass, markersClass, RECT, valuesDataPoints);
        rectangles
            .attr(WIDTH, DR_HICHERT_SQUARE_MARKER_WIDTH)
            .attr(HEIGHT, DR_HICHERT_SQUARE_MARKER_WIDTH)
            .attr(X, d => xScale(d.value) - DR_HICHERT_SQUARE_MARKER_WIDTH / 2)
            .attr(Y, d => yScale(d.category) + rangeBandHalf - DR_HICHERT_SQUARE_MARKER_WIDTH / 2);
        rectangles.exit().remove();
        styles.applyToLines(rectangles, (d: DataPoint) => settings.getCategoryDataPointColor(d.category, settings.colorScheme.markerColor), settings.scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme, false);
    }
    else {
        const markersClass = `${CIRCLE}${MARKER}${chartIndex} ${HIGHLIGHTABLE}`;
        const circles = drawing.getShapes(chartArea, markersClass, markersClass, CIRCLE, valuesDataPoints);
        circles
            .attr(R, 4)
            .attr(CX, d => xScale(d.value))
            .attr(CY, d => yScale(d.category) + rangeBandHalf);
        circles.exit().remove();
        styles.applyToLines(circles, (d: DataPoint) => settings.getCategoryDataPointColor(d.category, settings.colorScheme.markerColor), settings.scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme, false);
    }
}

function getVerticalPinChartLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    ordinalScale: d3.ScaleBand<string>, linearScale: d3.ScaleLinear<number, number>): LabelProperties[] {
    const categoryRangeBand = ordinalScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const labelProperties = labelsDataPoints.map(d => {
        const value = d.value;
        const labelText = charting.getFormattedDataLabel(value, settings.decimalPlaces, settings.displayUnits, settings.locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        const xPos = linearScale(value) + (value > 0 ? 8 : -8);
        const yPos = ordinalScale(d.category) + ordinalScale.bandwidth() / 2 + settings.labelFontSize * 0.35;
        const alignment: LabelAlignment = value > 0 ? START : END;
        return charting.getLabelProperty(labelText, xPos, yPos, null, value, d, settings.labelFontSize, settings.labelFontFamily, null, null, null, alignment, true);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, categoryRangeBand);
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], linearScale: d3.ScaleLinear<number, number>, ordinalScale: d3.ScaleBand<string>, settings: ChartSettings, rightMargin: number) {
    let labelsMargin = rightMargin;
    const scaleBandWidth = ordinalScale.bandwidth();
    const markerAttrs = charting.getCommentMarkerAttributes(scaleBandWidth);
    labelsMargin = labelsMargin - 2 * markerAttrs.radius - markerAttrs.margin;
    const getMarkerYPosition = (d: DataPoint): number => ordinalScale(d.category) + scaleBandWidth / 2;
    const getMarkerXPosition = (d: DataPoint): number => linearScale(d.value) + labelsMargin + markerAttrs.radius + markerAttrs.margin;
    charting.addCommentMarkers(container, commentDataPoints, getMarkerXPosition, getMarkerYPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}
