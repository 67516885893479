import { Visual } from "./../visual";
import Layout from "./Layout";
import { ChartType, ComboChartUnderlyingChart } from "./../enums";
import { showProLicenseNeededMsg } from "./../ui/ui";
import { INTEGRATED, CHART_CONTAINER, WATERFALL } from "./../consts";
import { ChartLayout } from "./../interfaces";
import dotChart from "./../charting/dotChart";
import * as d3 from "../d3";
import { HEIGHT, WIDTH } from "../library/constants";
import { applyBlur } from "../library/drawing";

export class SingleChartLayout extends Layout {
    public getChartLayouts(rowCount: number, maxChartsPerRow: number, height: number, visualTitleHeight: number, minChartHeight: number, minChartWidth: number, plotChartWidth: number, bottomMargin: number, topMargin: number, rowMargin: number, columnMargin: number, labelFontSize: number, xPosition: number): ChartLayout[] {
        return [];
    }

    public plot(rowCount: number, maxChartsPerRow: number, height: number, titleHeight: number, minChartHeight: number, minChartWidth: number, plotChartWidth: number, bottomMargin: number,
        topMargin: number, rowMargin: number, columnMargin: number) {
        const chartHeight = Math.max(height - titleHeight, 0);
        const chartWidth = this.width - 2; // this apparently fixes some scroller issues
        const chartLayout = this.settings.chartLayout;
        this.svg
            .attr(WIDTH, chartWidth)
            .attr(HEIGHT, chartHeight + titleHeight);

        if (this.viewModel.chartData[0].min < 0 && !this.settings.shouldPlotVerticalCharts()) {
            bottomMargin += Number(this.settings.labelFontSize);
        }

        const yChart = titleHeight;
        const chartData = this.viewModel.chartData[0];
        const plotComboChart = this.settings.scenarioOptions.secondActualValueIndex !== null && this.settings.showDotChart && !this.settings.shouldPlotVerticalCharts();

        if ((this.settings.chartType === ChartType.Variance || this.settings.chartType === ChartType.Waterfall)
            && (chartLayout !== INTEGRATED && chartLayout !== WATERFALL)
            && !this.viewModel.isCategoryOnlyViewModel
            && !this.viewModel.isSingleSeriesViewModel) {
            this.plotResponsiveChart(0, yChart, chartHeight - 3, chartWidth, this.viewModel.chartData[0], topMargin, bottomMargin, 0, minChartHeight, this.settings.showLegend);
        }
        else {
            if (this.settings.showCommentMarkers() && !this.settings.shouldPlotVerticalCharts()) {
                topMargin += this.layoutAttributes.topCommentMarkerMargin;
                bottomMargin += this.layoutAttributes.bottomCommentMarkerMargin;
            }

            if (plotComboChart) {
                this.plotComboChart(chartData, chartHeight - 3, chartWidth, bottomMargin, yChart, topMargin, 0, 0, yChart, true,
                    ComboChartUnderlyingChart.plotChart, 0, this.settings.showLegend);
            }
            else {
                this.plotChart(0, yChart, chartHeight - 3, chartWidth, this.viewModel.chartData[0], topMargin, bottomMargin, 0, this.settings.showLegend);
            }
        }

        if (this.slideHandler) {
            this.slideHandler.refreshUIelements(this.width, Visual.element, this.settings, Visual.visualViewPort.height);
        }

        if (this.shouldBlurAChart()) {
            const chartsToBlur = d3.selectAll("g." + CHART_CONTAINER);
            applyBlur([<any>chartsToBlur], []);
            showProLicenseNeededMsg(Visual.element, "Available with Pro licence", false);
        }
    }
}