import { DropdownSubscriber, InlineDropdown } from "@zebrabi/design-library";
import { v4 as UUIDv4 } from "uuid";
import * as d3 from "../../d3";
import { ChartType, GlobalStackedChartSettings, ShowTopNChartsOptions } from "../../enums";
import { ScenarioOptions } from "../../interfaces";
import {
    ACTIVE, CLICK, CONTEXT_MENU2_DIV, CUSTOMER_STYLE_VISIBLE, FIXED, GLOBAL_STACKED_CHART, MOUSELEAVE, STROKE,
    TRANSPARENT
} from "../../library/constants";
import { ChartSettings } from "../../settings/chartSettings";
import { Visual } from "../../visual";
import ContextMenu from "../base/ContextMenu";
import GlobalStackedChartMenuItemFactory from "./GlobalStackedChartMenuItemFactory";

export class GlobalStackedChartMenu extends ContextMenu {
    private readonly isAreaChart = Visual.settings.chartType === ChartType.Area;
    private readonly menuItemFactory = new GlobalStackedChartMenuItemFactory();
    protected template: string = `
        <div class="${CONTEXT_MENU2_DIV} ${GLOBAL_STACKED_CHART} ${this.classString}">
            <div class="menu-title">Stacked chart</div>
            <ul class="menu-items"></ul>
        </div>
    `;

    constructor(private readonly classString: string) {
        super();
    }

    protected closeMenuOnDocumentMouseLeave(): void {
        document.addEventListener(MOUSELEAVE, () => {
            const main = <HTMLElement>document.querySelector(".zebrabi-charts-container");
            main.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_STACKED_CHART}`)?.remove();
            const rect = d3.select(`.global-chart-legend-outline.${ACTIVE}`);
            rect.attr(STROKE, TRANSPARENT);
            rect.classed(ACTIVE, false);
        });
    }

    protected closeMenuOnOutsideClick(): void {
        const closeMenuOnOutsideClick = (event: MouseEvent) => {
            const main = <HTMLElement>document.querySelector(".zebrabi-charts-container");
            const globalStackedChartMenu = main.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_STACKED_CHART}`);
            const globalStackedChartOutline = main.querySelector(`.global-chart-legend-outline`);

            if (globalStackedChartMenu && !event.composedPath().includes(globalStackedChartOutline) && !event.composedPath().includes(globalStackedChartMenu)) {
                globalStackedChartMenu.remove();
                document.removeEventListener(CLICK, closeMenuOnOutsideClick);
                const rect = d3.select(`.global-chart-legend-outline.${ACTIVE}`);
                rect.attr(STROKE, TRANSPARENT);
                rect.classed(ACTIVE, false);
            }
        };

        document.addEventListener(CLICK, closeMenuOnOutsideClick);
    }

    protected resetToDefault(): void {
        const val = {};
        // IMPORTANT: when null is persisted on string type, property is not deleted from object but is instead empty string
        const settings = new ChartSettings(CUSTOMER_STYLE_VISIBLE.toString() === "true", "en", <ScenarioOptions>{});
        Object.keys(GlobalStackedChartSettings).filter((v) => isNaN(Number(v))).forEach(key => {
            val[key] = settings[key];
        });

        this.chartSettings.persistGlobalStackedChartSettings(val);

        // save current position and re-append menu with fresh settings
        let stackedChartMenu: HTMLElement = document.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_STACKED_CHART}`);
        const stackedChartMenuBoundingRect = stackedChartMenu.getBoundingClientRect();
        stackedChartMenu.remove();
        const main = <HTMLElement>document.querySelector(".zebrabi-charts-container");
        stackedChartMenu = new GlobalStackedChartMenu(this.classString).appendTo(main);
        stackedChartMenu.style.position = FIXED;
        stackedChartMenu.style.top = `${stackedChartMenuBoundingRect.top}px`;
        stackedChartMenu.style.left = `${stackedChartMenuBoundingRect.left}px`;
    }

    protected addMenuItems(): void {
        this.appendItem(GlobalStackedChartSettings.showStackedLabelsAsPercentage);
        this.appendItem(GlobalStackedChartSettings.stackedChartSort);
        this.appendItem(GlobalStackedChartSettings.showTopNStackedOptions);

        if (this.isAreaChart) {
            this.appendItem(GlobalStackedChartSettings.stackedAreaOpacity);
        }

        this.addResetToDefault();
    }

    private appendItem(setting: GlobalStackedChartSettings, id?: string): void {
        if (setting === GlobalStackedChartSettings.showTopNStackedOptions) {
            this.appendTopNDropdown();
            return;
        }

        const item = this.menuItemFactory.getItem(setting);
        const menuItemDiv = this.createMenuItem(id);
        item.appendTo(menuItemDiv);
    }

    private insertItem(id: string, setting: GlobalStackedChartSettings): void {
        const item = this.menuItemFactory.getItem(setting);
        const menuItemDiv = this.insertNewMenuItem(id);
        item.appendTo(menuItemDiv);
    }

    /**
     * Customize appending for Top N dropdown because it requires additional subscriber for toggling inputs
     * and needs to show inputs for initial dropdown selection.
     * @private
     */
    private appendTopNDropdown(): void {
        const topNid = `tbn-${UUIDv4()}`;
        const topNdropdown = <InlineDropdown<ShowTopNChartsOptions>>this.menuItemFactory.getItem(GlobalStackedChartSettings.showTopNStackedOptions);
        const menuItemDiv = this.createMenuItem(topNid);
        topNdropdown.appendTo(menuItemDiv);
        topNdropdown.subscribe(this.getTopNSubscriber(topNid));
        this.addTopNInputs(topNid);
    }

    /**
     * Subscriber for Top N dropdown selection change which re-adds inputs for this selection.
     * @param topNid
     * @private
     */
    private getTopNSubscriber(topNid: string): DropdownSubscriber<ShowTopNChartsOptions> {
        const topNSub = new DropdownSubscriber<ShowTopNChartsOptions>();

        topNSub.update = (): void => {
            this.removeTopNInputs(topNid);
            this.addTopNInputs(topNid);
        };

        return topNSub;
    }

    /**
     * Insert inputs depending on current Top N dropdown selection.
     * @param id
     * @private
     */
    private addTopNInputs(id: string): void {
        switch (this.chartSettings.showTopNStackedOptions) {
            case ShowTopNChartsOptions.Items:
                this.insertItem(id, GlobalStackedChartSettings.topNStackedOthersLabel);
                this.insertItem(id, GlobalStackedChartSettings.topNStackedToKeep);
                break;
            case ShowTopNChartsOptions.Percentage:
                this.insertItem(id, GlobalStackedChartSettings.topNStackedOthersLabel);
                this.insertItem(id, GlobalStackedChartSettings.topNStackedPercentage);
                break;
        }
    }

    /**
     * Defines max. number of inputs added by Top N dropdown selection. Looks for specific ids which are inputs
     * shown by a Top N dropdown selection. If input exists remove whole menu item which contains that input.
     * @param menuItemId
     * @private
     */
    private removeTopNInputs(menuItemId: string): void {
        const topNchildrenCount = 2;

        for (let i = 1; i <= topNchildrenCount; i++) {
            if (document.querySelector(`#top-n-child-${i}`)) {
                this.removeNextSibling(menuItemId);
            }
        }
    }
}