import Layout from "./Layout";
import { ChartType } from "./../enums";
import { ACTUAL_ABSOLUTE, ACTUAL_RELATIVE, ABSOLUTE_RELATIVE } from "./../consts";
import { ChartLayout, ChartData } from "./../interfaces";

export class VerticalChartsLayout extends Layout {

    public getChartLayouts(rowCount: number, maxChartsPerRow: number, height: number, visualTitleHeight: number, minChartHeight: number,
        minChartWidth: number, plotChartWidth: number,
        bottomMargin: number, topMargin: number, rowMargin: number, columnMargin: number, labelFontSize: number, xPosition: number, leftMargin: number): ChartLayout[] {

        const layoutAttributes = this.layoutAttributes;
        const chartLayouts: ChartLayout[] = [];
        let colWidths: number[] = [];
        const colDataDiffs: number[] = [];
        const colDataSpans: number[][] = [];
        const extendedRelativeChartSpan = 0;
        const maxCols = layoutAttributes.maxChartsPerRow;
        const isWaterfallWithTopNCategories = this.settings.chartType === ChartType.Waterfall && this.settings.showTopNCategories;

        const plotLegend = this.settings.showLegend && this.settings.chartType !== ChartType.Waterfall;
        const legendHeight = plotLegend ? this.settings.labelFontSize + 5 : 0;

        for (let i = 0; i < maxCols; i++) {
            const partition = this.viewModel.chartData.filter((c, j) => j % maxCols === i);
            const dataSpan = this.getMultipleDataSpan(partition);
            colDataSpans.push(dataSpan);
            const diff = dataSpan[1] + extendedRelativeChartSpan - dataSpan[0];
            colDataDiffs.push(diff);
        }

        let totalLeftAndRightMargins = maxCols * this.layoutAttributes.rightMargin;
        if (isWaterfallWithTopNCategories) {
            totalLeftAndRightMargins += (maxCols - 1) * this.layoutAttributes.leftMargin;
        }
        const totalDataDiffSum = colDataDiffs.reduce((a, b) => a + b, 0);
        const availableWidth = layoutAttributes.width - layoutAttributes.leftMargin - columnMargin;
        const scaleFactor = totalDataDiffSum === 0 ? 1 : (availableWidth - totalLeftAndRightMargins - columnMargin * (maxCols - 1)) / (totalDataDiffSum);
        colWidths = colDataDiffs.map((a, i) => a * scaleFactor + layoutAttributes.rightMargin);
        if (isWaterfallWithTopNCategories) {
            colWidths = colWidths.map((cw, i) => cw + (i > 0 ? this.layoutAttributes.leftMargin : 0));
        }

        const startPositionX = layoutAttributes.leftMargin;
        let currentPositionX = startPositionX;
        let currentPositionY = visualTitleHeight;
        let currentRowIndex = 0;
        let currentColIndex = 0;
        const plotChartHeight = layoutAttributes.minChartHeight;

        for (let i = 0, len = this.viewModel.chartData.length; i < len; i++) {
            let currentColWidth = colWidths[currentColIndex];
            if (i > 0) {
                if (!currentColWidth || currentPositionX + currentColWidth > layoutAttributes.width) {
                    currentPositionX = startPositionX;
                    currentRowIndex++;
                    currentColIndex = 0;
                    currentColWidth = colWidths[currentColIndex];
                    currentPositionY += chartLayouts[i - 1].height + rowMargin;
                }
            }

            chartLayouts.push({
                height: currentRowIndex === 0 ? plotChartHeight + legendHeight : plotChartHeight,
                width: currentColIndex === 0 ? currentColWidth + startPositionX : currentColWidth,
                position: {
                    x: currentColIndex === 0 ? 0 : currentPositionX,
                    y: currentPositionY,
                },
                columnIndex: currentColIndex,
                rowIndex: currentRowIndex,
                min: colDataSpans[currentColIndex][0],
                max: colDataSpans[currentColIndex][1],
                extendedChartMin: null,
                extendedChartMax: null,
                extendedRelativeChartHeight: null,
                bottomMargin: bottomMargin,
            });
            currentColIndex++;
            currentPositionX += currentColWidth + columnMargin;
        }

        return chartLayouts;
    }

    private getMultipleDataSpan(chartData: ChartData[]): number[] {
        let min = 0;
        let max = 0;
        let extendedChartMin = 0;
        let extendedChartMax = 0;

        for (let i = 0; i < chartData.length; i++) {
            const chartDataMin = chartData[i].min;
            const chartDataMax = chartData[i].max;

            if (this.settings.chartLayout === ACTUAL_ABSOLUTE) {
                extendedChartMin = Math.min(extendedChartMin, chartData[i].minVariance);
                extendedChartMax = Math.max(extendedChartMax, chartData[i].maxVariance);
            }
            else if (this.settings.chartLayout === ACTUAL_RELATIVE) {
                extendedChartMin = Math.min(extendedChartMin, chartData[i].minOutlierValue);
                extendedChartMax = Math.max(extendedChartMax, chartData[i].maxOutlierValue);
            }
            else if (this.settings.chartLayout === ABSOLUTE_RELATIVE) {
                extendedChartMin = Math.min(extendedChartMin, chartData[i].minOutlierValue);
                extendedChartMax = Math.max(extendedChartMax, chartData[i].maxOutlierValue);
            }

            if (chartDataMin < min) {
                min = chartDataMin;
            }
            if (chartDataMax > max) {
                max = chartDataMax;
            }
        }

        return [min, max, extendedChartMin, extendedChartMax];
    }
}
