import { OverlayConfig } from "../interfaces/OverlayConfig";

export const signInOverlay = (config: OverlayConfig) => `
    <div class="zbi-sign-in" id="zbiSignIn">
       <div class="overlay">
           <div class="modal">
               <header class="logo"></header>
                ${config.copy ? `<div class="copy">${config.copy}</div>` : ""}
                ${config.signIn ? `<button class="sign-in">${config.signIn}</button>` : ""}
                ${config.buy ? `<button class="buy">${config.buy}</button>` : ""}
                ${config.confirm ? `<button class="admin">${config.confirm}</button>` : ""}
                ${config.trial ? `<button class="trial">${config.trial}</button>` : ""}
                ${config.footer ? `<footer><a>${config.footer.text}</a></footer>` : ""}
           </div>
       </div>
    </div>
`;
