import React from "react";
import useStore from "../../useStore";
import { SharePointDrive } from "../../ExcelDataLinking.types";
import folderIcon from "../images/folder.svg";

const OnedriveListing = React.memo(({ onedrives, onDriveClick }) => {
    const [activeDriveId] = useStore("activeDriveId");

    const isActive = (drive: SharePointDrive) => drive.id === activeDriveId;

    return (
        <div className="onedrives-listing">
            <h3 className="text-sm">OneDrive:</h3>
            <ul>
                {onedrives.length > 0 ? (
                    onedrives.map((drive: SharePointDrive) => (
                        <li key={drive.id}>
                            <button
                                className={isActive(drive) ? "active" : ""}
                                onClick={() => {
                                    onDriveClick(drive);
                                }}
                            >
                                <img src={folderIcon} alt={drive.name} />
                                {drive.name + " " + drive.driveType}
                            </button>
                        </li>
                    ))
                ) : (
                    <p>No Onedrive found</p>
                )}
            </ul>
        </div>
    );
});

export default OnedriveListing;
