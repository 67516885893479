// Language locales
const EN_US = "en-US";
const DE_DE = "de-DE";
const FR_FR = "fr-FR";
const SL_SI = "sl-SI";
const SL = "sl";
const NL_NL = "nl-NL";
const HR_HR = "hr-HR";
const IT_IT = "it-IT";
const PT_BR = "pt-BR";
const PR_PT = "pt-PT";
const TR_TR = "tr-TR";
const PL_PL = "pl-PL";

export function getTranslatedAndString(locale: string): string {
    switch (locale) {
        case EN_US:
            return "and";
        case DE_DE:
            return "und";
        case FR_FR:
            return "et";
        case SL_SI:
        case SL:
            return "in";
        case NL_NL:
            return "en";
        case HR_HR:
            return "i";
        case IT_IT:
            return "e";
        case PT_BR:
        case PR_PT:
            return "e";
        case TR_TR:
            return ",";
        case PL_PL:
            return "i";
        default:
            return "and";
    }
}

export function getTranslatedByString(locale: string): string {
    switch (locale) {
        case EN_US:
            return "by";
        case DE_DE:
            return "nach";
        case FR_FR:
            return "par";
        case SL_SI:
        case SL:
            return "glede na";
        case NL_NL:
            return "per";
        case HR_HR:
            return "kategorije";
        case IT_IT:
            return "per";
        case PT_BR:
        case PR_PT:
            return "por";
        case TR_TR:
            return "ve";
        case PL_PL:
            return "wg";
        default:
            return "by";
    }
}

export function getTranslatedInString(locale: string): string {
    switch (locale) {
        case EN_US:
            return "in";
        case DE_DE:
            return "in";
        case FR_FR:
            return "en";
        case SL_SI:
        case SL:
            return "v";
        case NL_NL:
            return "in";
        case HR_HR:
            return "u";
        case IT_IT:
            return "in";
        case PT_BR:
        case PR_PT:
            return "em";
        case TR_TR:
            return "bin";
        case PL_PL:
            return "w";
        default:
            return "in";
    }
}
