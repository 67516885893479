import * as d3 from "../d3";
import { ChartData, DataPoint, ScenarioOptions, ViewModel } from "./../interfaces";
import { ColorScheme, LabelProperties } from "./../library/interfaces";
import { ChartSettings } from "./../settings/chartSettings";
import { Visual } from "./../visual";

import {
    Scenario, NORMAL, TEXT, FILL, FILL_OPACITY, STROKE_OPACITY, WIDTH, HEIGHT, HIGHLIGHTABLE, RECT, X, Y, BLACK, WHITE, TOOLTIP, G, BAR, OPACITY, FONT_WEIGHT, STROKE, STROKE_WIDTH, CLICK, MOUSEOVER, MOUSEOUT, DISPLAY, NONE, BOLD, ChartStyle
} from "./../library/constants";
import { CHART_CONTAINER } from "./../consts";
import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as charting from "./chart";
import { StackedDataPoint, StackedLabelProperties, DiffHighlightAttrs, getStackedData, getTotalsLabelsData, getStackedLabelProperty, getDifferenceHighlightAttributes, getColorScale, plotStackedChartLegend, getTotalLabelProperties, getStackedLabelProperties, plotStackedCommentMarkers, applyStylesToStackedBars, getValueDataPointColor, addColorsCustomHighlightColorsPatternDefinitions, addStackedChartMouseHandlers, addValuesLabelsInteractions } from "./stackedChart";

// eslint-disable-next-line max-lines-per-function
export default function stackedColumnChart(viewModel: ViewModel, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
    slider: HTMLElement, x: number, y: number, height: number, width: number, topMargin: number, bottomMargin: number) {

    if (!viewModel.chartData || viewModel.chartData.length === 0) {
        return;
    }
    const settings = viewModel.settings;
    const scenarioOptions = settings.scenarioOptions;
    const colorScheme = settings.colorScheme;
    const plotLegend = settings.showLegend;
    let chartWidth = width;
    let chartXPos = x;

    const plotOverlappedReference = !viewModel.isSingleSeriesViewModel && settings.plotOverlappedReference;
    const groups = viewModel.chartData.map(d => d.group);

    let legendWidth = 0;
    if (plotLegend) {
        legendWidth = Math.max(...groups.map(v => drawing.measureTextWidth(v, settings.labelFontSize, settings.labelFontFamily, NORMAL, NORMAL)));
        let legendMargin = legendWidth + settings.legendItemsMargin;
        if (plotOverlappedReference) {
            legendMargin += 10;
        }
        chartWidth -= legendMargin;
        chartXPos += legendMargin;
    }

    const stackedData = getStackedData(viewModel, groups, plotOverlappedReference);
    const minStacked = stackedData.min;
    let maxStacked = stackedData.max;
    const stackedDataPoints = stackedData.dataPoints;
    const chartData0 = viewModel.chartData[0];

    let diffHighlightAttrs: DiffHighlightAttrs;
    if (settings.differenceHighlight) {
        diffHighlightAttrs = getDifferenceHighlightAttributes(stackedData.stackedDataValues, stackedData.stackedDataReference, settings, plotOverlappedReference, stackedData.isDiverging);
        chartWidth -= diffHighlightAttrs.width;
        settings.differenceHighlightWidth = diffHighlightAttrs.width;
    }

    let setBarHeightToZero = false;
    if (minStacked === 0 && maxStacked === 0) {
        setBarHeightToZero = true;
        maxStacked = 1;
    }
    if (height - bottomMargin <= topMargin) {
        topMargin -= topMargin - (height - bottomMargin + 1);
    }
    const xScale = charting.getXScale(chartData0, chartXPos, chartWidth, settings.getGapBetweenColumns());
    const yScale = charting.getLinearScale(minStacked, maxStacked, y + height - bottomMargin, y + topMargin);

    const colorScale = getColorScale(settings, groups, scenarioOptions.valueScenario);
    if (scenarioOptions.valueScenario === Scenario.Forecast) {
        drawing.addColorsArrayPatternDefinitions(svg, colorScale);
        addColorsCustomHighlightColorsPatternDefinitions(svg, groups, settings);
    }
    stackedDataPoints.forEach(p => {
        p.color = getValueDataPointColor(p, settings, groups, colorScale);
    });

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    if (plotOverlappedReference) {
        plotOverlappedStackedReferences(settings, groups, scenarioOptions, svg, chartContainer, stackedDataPoints, xScale, yScale, colorScheme);
    }

    let stackedColumns = chartContainer
        .selectAll(`.${BAR}_value`)
        .data(stackedDataPoints);
    const rect = stackedColumns.enter()
        .append(RECT)
        .classed(BAR, true)
        .classed(HIGHLIGHTABLE, true);
    stackedColumns = stackedColumns.merge(rect);
    stackedColumns.attr(X, d => xScale(d.category))
        .attr(WIDTH, xScale.bandwidth())
        .attr(Y, d => yScale(d.end))
        .attr(HEIGHT, d => setBarHeightToZero ? 0 : Math.abs(yScale(d.start) - yScale(d.end)));
    applyStylesToStackedBars(stackedColumns, colorScale, settings, groups, scenarioOptions.valueScenario, settings.chartStyle, colorScheme, true);

    if (plotLegend) {
        plotStackedChartLegend(stackedData.stackedDataValues, chartContainer, viewModel.chartData, settings, x, legendWidth, yScale, stackedData.isDiverging, colorScale, stackedDataPoints);
    }
    drawing.plotHorizontalAxis(chartContainer, false, chartXPos, chartWidth, yScale(0), settings.colorScheme.axisColor, scenarioOptions.referenceScenario);

    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    const hideUnits = settings.shouldHideDataLabelUnits();
    if (settings.showDataLabels) {
        const stackedDataValues = stackedData.stackedDataValues;
        const totalsLabelsData = getTotalsLabelsData(stackedDataValues, stackedData.isDiverging);
        const totalsLabelsProperties = getTotalLabelProperties(totalsLabelsData, settings, labelsFormat, hideUnits, xScale, yScale);
        if (totalsLabelsProperties.length > 0) {
            charting.plotLabels(chartContainer, "total-label", totalsLabelsProperties, settings);
        }
        const valuesLabelProperties = getStackedLabelProperties(stackedDataPoints, settings, labelsFormat, hideUnits, xScale, yScale, totalsLabelsData, settings.showStackedLabelsAsPercentage);
        if (valuesLabelProperties.length > 0) {
            const backgroundsColor = WHITE; //scenarioOptions.valueScenario === Scenario.Forecast ? WHITE : baseColor;
            charting.plotLabelBackgrounds(chartContainer, 0, valuesLabelProperties.filter(l => l.scenario === Scenario.Forecast), settings, backgroundsColor, true, false);
            const valueLabels = charting.plotLabels(chartContainer, "value-label", valuesLabelProperties, settings);
            valueLabels.attr(FILL, d => (<StackedLabelProperties>d).scenario === Scenario.Plan || (<StackedLabelProperties>d).scenario === Scenario.Forecast ?
                BLACK : styles.getLabelColor((<StackedLabelProperties>d).color));
            //if (Visual.visualHost.hostCapabilities.allowInteractions) {
            addValuesLabelsInteractions(valueLabels, chartContainer, settings, slider);
            if (Visual.animateVarianceLabels) {
                charting.animateLabels(valueLabels, null, settings.labelFontSize);
            }
            //}
        }
    }

    if (settings.differenceHighlight) {
        plotDifferenceHighlight(chartContainer, diffHighlightAttrs, chartXPos, chartWidth, settings, slider, xScale, yScale);
    }

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = stackedDataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotStackedCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, settings);
        }
    }

    // Visual.tooltipServiceWrapper.addTooltip(chartContainer.selectAll(`.${BAR}, .${TOOLTIP}`),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => charting.getTooltipData(tooltipEvent.data, settings, labelsFormat, labelsFormat, settings.invert, undefined, chartData0),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => tooltipEvent && tooltipEvent.data ? tooltipEvent.data.selectionId : null);
    addStackedChartMouseHandlers(stackedColumns, chartContainer);


    stackedColumns.exit().remove();

    charting.plotCategories(chartContainer, settings, chartData0, y + height, xScale, chartWidth, yScale(0));
}

function plotOverlappedStackedReferences(settings: ChartSettings, groups: string[], scenarioOptions: ScenarioOptions, svg: d3.Selection<SVGElement, any, any, any>, chartContainer: d3.Selection<SVGGElement, any, any, any>, stackedDataPoints: StackedDataPoint[], xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, colorScheme: ColorScheme) {
    const referenceColorScheme = getColorScale(settings, groups, scenarioOptions.referenceScenario);
    if (scenarioOptions.referenceScenario === Scenario.Forecast) {
        drawing.addColorsArrayPatternDefinitions(svg, referenceColorScheme);
        addColorsCustomHighlightColorsPatternDefinitions(svg, groups, settings);
    }

    let referenceBars = chartContainer
        .selectAll(`.${BAR}_REFERENCE`)
        .data(stackedDataPoints);
    const rect = referenceBars.enter()
        .append(RECT)
        .classed(BAR, true)
        .classed("reference", true)
        .classed(HIGHLIGHTABLE, true)
        .attr(FILL, WHITE)
        .attr(STROKE, BLACK);
    referenceBars = referenceBars.merge(rect);
    referenceBars
        .attr(X, d => xScale(d.category) - xScale.bandwidth() * settings.getGapBetweenColumns() / 1.6)
        .attr(Y, d => yScale(d.referenceEnd))
        .attr(WIDTH, xScale.bandwidth())
        .attr(HEIGHT, d => Math.abs(yScale(d.referenceStart) - yScale(d.referenceEnd)));

    applyStylesToStackedBars(referenceBars, referenceColorScheme, settings, groups, scenarioOptions.referenceScenario, settings.chartStyle, colorScheme, false);
    if (settings.lightenOverlapped) {
        referenceBars.attr(STROKE_OPACITY, 0.5);
        const overlappedFillOpacity = scenarioOptions.referenceScenario === Scenario.Plan &&
            (!settings.colorScheme.useCustomScenarioColors || settings.colorScheme.applyPatterns) ?
            +referenceBars.attr(FILL_OPACITY) * 0.5 : 0.5;
        referenceBars.attr(FILL_OPACITY, overlappedFillOpacity);
    }
    referenceBars.exit().remove();
}

function getValuesLabelProperties(stackedData: d3.Series<{ [key: string]: number; }, string>[], chartData: ChartData[],
    settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number): LabelProperties[] {
    const xBandWidthHalf = xScale.bandwidth() / 2;
    const labelProperties = [];
    stackedData.forEach(d => {
        d.forEach(dp => {
            if (!dp.data) {
                return;
            }
            const isEnoughVerticalSpace = yScale(dp[0]) - yScale(dp[1]) > settings.labelFontSize + 1;
            if (!isEnoughVerticalSpace) {
                return;
            }

            const value = dp.data[d.key];
            if (value === undefined) {
                return;
            }
            const labelText = charting.getFormattedDataLabel(value, settings.decimalPlaces, settings.displayUnits, settings.locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);

            const chartD = chartData.find(cd => cd.group === d.key);
            const dataPoint = chartD ? chartD.dataPoints.find(p => p.category === dp.data.category.toString()) : null;
            if (!dataPoint) {
                return;
            }

            const xPos = xScale(dataPoint.category) + xBandWidthHalf;
            const yPos = yScale((dp[1] + dp[0]) / 2);

            const labelProperty = getStackedLabelProperty(value, labelText, xPos, yPos, null, dataPoint, settings.labelFontSize, settings.labelFontFamily);
            labelProperties.push(labelProperty);
        });
    });
    return labelProperties; //charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

function plotDifferenceHighlight(chartContainer: d3.Selection<SVGElement, any, any, any>, diffHighlightAttrs: DiffHighlightAttrs,
    chartXPos: number, chartWidth: number, settings: ChartSettings,
    slider: HTMLElement, xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>) {
    const endX = chartXPos + chartWidth + diffHighlightAttrs.width;
    const dhX1 = xScale(diffHighlightAttrs.endCategory);
    const dhX2 = xScale(diffHighlightAttrs.startCategory);
    const dhY1 = yScale(diffHighlightAttrs.endValue);
    const dhY2 = yScale(diffHighlightAttrs.startValue);
    charting.addDifferenceHighlight(chartContainer, settings, slider, endX, dhX1, dhX2, diffHighlightAttrs.endValue, diffHighlightAttrs.startValue, dhY1, dhY2, settings.invert);
}

function getBaseColor(settings: ChartSettings): string {
    let baseColor = settings.colorScheme.neutralColor;
    //Visual.visualHost.colorPalette.foreground.value; //settings.colorScheme.neutralColor; // BLACK
    if (settings.scenarioOptions.valueScenario === Scenario.PreviousYear) {
        baseColor = styles.getLighterColor(baseColor);
    }
    else if (settings.scenarioOptions.valueScenario === Scenario.Plan) {
        baseColor = WHITE;
    }
    return baseColor;
}

function applyToStackedBars(element: d3.Selection<any, any, any, any>,
    colorScale: string[], settings: ChartSettings, groups: string[],
    scenario: Scenario, style: ChartStyle, colorScheme: ColorScheme) {
    //applyTo(element, colorGetter, scenario, style, isVariance, colorScheme, 1, false);

    const getValueDataPointColor = (dataPoint: StackedDataPoint): string => {
        if (settings.isCategoryHighlighted(dataPoint.category)) {
            return settings.getCategoryHighlightColor(dataPoint.category);
        }

        const groupIndex = groups.indexOf(dataPoint.group);
        const colorIndex = groupIndex % colorScale.length; //(grayColorScale.length - 1 - groupIndex) % grayColorScale.length;
        return colorScale[colorIndex];
    };

    const strokeWidth = 1;

    element.attr(STROKE_WIDTH, 0);
    if (scenario === Scenario.Actual) {
        element.attr(FILL, d => getValueDataPointColor(d));
    }
    else if (scenario === Scenario.Plan) {
        if (colorScheme.useCustomScenarioColors) {
            element.attr(FILL, d => getValueDataPointColor(d));
            element.attr(FILL_OPACITY, 1);
            if (colorScheme.applyPatterns) {
                element.attr(FILL_OPACITY, 0)
                    .attr(STROKE_WIDTH, 1)
                    .attr(STROKE, d => getValueDataPointColor(d));

                element.attr(FILL_OPACITY, 0.1);
            }
        }
        else {
            element
                .attr(FILL, d => getValueDataPointColor(d))
                .attr(STROKE_WIDTH, strokeWidth)
                .attr(STROKE, d => getValueDataPointColor(d));
            element.attr(FILL_OPACITY, style === ChartStyle.DrHichert ? 0 : 0.1);
        }
    }
    else if (scenario === Scenario.PreviousYear) {
        element.attr(FILL, d => {
            return getValueDataPointColor(d);
        });
    }
    else if (scenario === Scenario.Forecast) {
        element.attr(FILL, d => {
            if (colorScheme.useCustomScenarioColors && !colorScheme.applyPatterns) {
                return getValueDataPointColor(d);
            }
            else {
                return `url(#diagonal-stripe-${styles.getPatternFromColor(getValueDataPointColor(d), colorScheme)})`;
            }
        });
        element.attr(STROKE_WIDTH, strokeWidth)
            .attr(STROKE, d => getValueDataPointColor(d));
    }
}
