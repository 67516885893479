import React from "react";
import { ChartDimensionItem } from "../ChartDimensionItem";
import { mainDimensions } from "../../chartDimensionList";
import useStore from "@zebrabi/chart-chooser/useStore";

export default function MainDimensionList() {
  const [selectedDimension, setSelectedDimension] = useStore<string>("selectedDimension");

  const handleActive = (dimension) => setSelectedDimension(dimension.id);

  return (
    <div className="w-full">
      {mainDimensions.map((dimension, index) => (
        <div key={index}>
          <ChartDimensionItem
            dimension={dimension}
            isActive={dimension.id === selectedDimension}
            onActive={handleActive}
          />
        </div>
      ))}
    </div>
  );
}
