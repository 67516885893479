export const transformSharePointResponse = (values = []) => {
  const rows = (values ?? []).reduce((acc, values: [], index) => {
    acc[index] = {
      cells: values.reduce((acc2, value, index2) => {
        acc2[index2] = { text: value };
        return acc2;
      }, {}),
    };
    return acc;
  }, {});

  return { rows };
};
