import { ChartData, DataPoint } from "./../interfaces";
import { ChartType, DifferenceHighlightFromTo } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { LabelProperties } from "./../library/interfaces";
import {
    BAR, CHART_AREA, ChartStyle, CIRCLE, CX, CY, DR_HICHERT_SQUARE_MARKER_WIDTH, END, FILL, G, HEIGHT, HIGHLIGHTABLE,
    ITALIC, MARKER, PIN_CHART_RECT_WIDTH, R, RECT, WIDTH, X, Y
} from "./../library/constants";
import { CHART_CONTAINER, VALUE } from "./../consts";

import * as drawing from "./../library/drawing";
import * as charting from "./chart";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as d3 from "../d3";
import { mapScenarioKeyAndName } from "../helpers";
import { plotChartLegendSettings } from "../ui/drawGlobalLegendMenuOverlay";

export default function pinChart(reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement, x: number, y: number, height: number, width: number,
    chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number, shouldPlotCategories: boolean, plotTitle: boolean,
    plotLegend: boolean, useSecondReferenceVariance: boolean = false) {

    let chartWidth = width;
    let chartXPos = x;
    if (plotLegend) {
        chartWidth -= settings.getLegendWidth();
        chartXPos += settings.getLegendWidth();
    }
    if (settings.differenceHighlight) {
        chartWidth -= settings.differenceHighlightWidth;
    }

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    const dataPoints = chartData.dataPoints;
    const nonOutlierDataPoints = dataPoints;
    const yScale = charting.getYScale(min, max, height, topMargin, bottomMargin);
    const xScale = charting.getXScale(chartData, chartXPos, chartWidth, 0);
    const setBarHeightToZero = min === 0 && max === 0;

    if (plotTitle) {
        charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, chartXPos, y, charting.getChartTitleWidth(chartWidth, settings), height, max, min, topMargin, bottomMargin);
    }
    if (shouldPlotCategories) {
        charting.plotCategories(chartContainer, settings, chartData, y + height, xScale, chartWidth, y + yScale(0), false, chartIndex);
    }
    drawing.plotHorizontalAxis(chartContainer, false, chartXPos, chartWidth, y + yScale(0), settings.colorScheme.axisColor, null);

    const chartArea = drawing.createGroupElement(chartContainer, `${CHART_AREA}-${chartIndex}`);
    const barsClass = `${BAR}_RELATIVE_${chartIndex} ${HIGHLIGHTABLE}`;
    const bars: d3.Selection<any, DataPoint, any, any> = drawing.getShapes(chartArea, barsClass, barsClass, RECT, nonOutlierDataPoints);
    bars
        .attr(WIDTH, PIN_CHART_RECT_WIDTH)
        .attr(HEIGHT, d => setBarHeightToZero ? 0 : Math.max(0, yScale(max - Math.abs(d.value)) - topMargin))
        .attr(X, d => xScale(d.category) + xScale.bandwidth() / 2 - PIN_CHART_RECT_WIDTH / 2)
        .attr(Y, d => y + yScale(Math.max(0, d.value)))
        .attr(FILL, d => settings.getValueDataPointColor(d, chartData.group));

    plotPinChartMarkers(settings, chartIndex, chartArea, nonOutlierDataPoints, xScale, y, yScale, chartData);

    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    if (settings.showDataLabels) {
        const hideUnits = settings.shouldHideDataLabelUnits();
        const labelsProperties = getPinChartLabelProperties(dataPoints, settings, labelsFormat, hideUnits, chartData, xScale, yScale, y, useSecondReferenceVariance);
        if (labelsProperties.length > 0) {
            charting.plotLabels(chartArea, `${chartIndex}`, labelsProperties, settings, ITALIC);
        }
    }

    if (settings.differenceHighlight && dataPoints.length > 1) {
        let { fromDataPoint, toDataPoint } = charting.getDiffHighlightAutoDataPoints(dataPoints, ChartType.Pin, true);
        if (settings.differenceHighlightFromTo !== DifferenceHighlightFromTo.Auto) {
            const fromToDataPoints = charting.getDiffHighlightDataPoints(dataPoints, settings.differenceHighlightFromTo);
            fromDataPoint = fromToDataPoints.fromDP;
            toDataPoint = fromToDataPoints.toDP;
        }
        if (fromDataPoint && toDataPoint) {
            const valueProperty: keyof DataPoint = VALUE;
            if (fromDataPoint !== toDataPoint && (fromDataPoint[valueProperty] !== null && toDataPoint[valueProperty] !== null)) {
                const dhX1 = xScale(toDataPoint.category) + xScale.bandwidth() / 2;
                const dhX2 = xScale(fromDataPoint.category) + xScale.bandwidth() / 2;
                const startValue = fromDataPoint[valueProperty];
                const endValue = toDataPoint[valueProperty];
                const dhY1 = y + yScale(endValue);
                const dhY2 = y + yScale(startValue);
                charting.addDifferenceHighlight(chartContainer, settings, slider, x + width, dhX1, dhX2, endValue, startValue, dhY1, dhY2, chartData.isInverted);
            }
        }
    }

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, y, settings);
        }
    }

    charting.addMouseHandlers(bars, reportArea);
    const varianceLabelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);
    // Visual.tooltipServiceWrapper.addTooltip(chartArea,
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => charting.getTooltipData(tooltipEvent.data, settings, labelsFormat, varianceLabelsFormat, chartData.isInverted, undefined, chartData),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => tooltipEvent.data ? tooltipEvent.data.selectionId : null);
    bars.exit().remove();
    if (plotLegend && dataPoints.length > 0) {
        charting.plotChartLegend(chartContainer, mapScenarioKeyAndName("valueHeader", settings.valueHeader, settings.scenarioOptions), x, y + yScale(dataPoints[0].value / 2) + 4, settings, true, settings.scenarioOptions.valueScenario, END);
        plotChartLegendSettings(chartContainer, x, y, height);
    }
}

function plotPinChartMarkers(settings: ChartSettings, chartIndex: number, chartArea: d3.Selection<SVGElement, any, any, any>, nonOutlierDataPoints: DataPoint[],
    xScale: d3.ScaleBand<string>, y: number, yScale: d3.ScaleLinear<number, number>, cd: ChartData) {

    if (settings.chartStyle === ChartStyle.DrHichert) {
        const markersClass = `${BAR}${MARKER}${chartIndex} ${HIGHLIGHTABLE}`;
        const rectangles = drawing.getShapes(chartArea, markersClass, markersClass, RECT, nonOutlierDataPoints);
        rectangles
            .attr(WIDTH, DR_HICHERT_SQUARE_MARKER_WIDTH)
            .attr(HEIGHT, DR_HICHERT_SQUARE_MARKER_WIDTH)
            .attr(X, d => xScale(d.category) + xScale.bandwidth() / 2 - DR_HICHERT_SQUARE_MARKER_WIDTH / 2)
            .attr(Y, d => Math.round(y + yScale(d.value)) - DR_HICHERT_SQUARE_MARKER_WIDTH / 2);
        rectangles.exit().remove();
        styles.applyToLines(rectangles, (d: DataPoint) => settings.getCategoryDataPointColor(d.category, settings.colorScheme.markerColor), settings.scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme, false);
    }
    else {
        const markersClass = `${CIRCLE}${MARKER}${chartIndex} ${HIGHLIGHTABLE}`;
        const circles = drawing.getShapes(chartArea, markersClass, markersClass, CIRCLE, nonOutlierDataPoints);
        circles
            .attr(R, 4)
            .attr(CX, d => xScale(d.category) + xScale.bandwidth() / 2)
            .attr(CY, d => Math.round(y + yScale(d.value)));
        circles.exit().remove();
        styles.applyToLines(circles, (d: DataPoint) => settings.getCategoryDataPointColor(d.category, settings.colorScheme.markerColor), settings.scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme, false);
    }
}

function getPinChartLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean, chartData: ChartData,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, useSecondReferenceVariance: boolean): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);

    const labelProperties = labelsDataPoints.map(d => {
        const value = d.value;
        const labelText = charting.getFormattedDataLabel(value, settings.decimalPlaces, settings.displayUnits, settings.locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        const xPos = xScale(d.category) + xScale.bandwidth() / 2;
        const yPos = y + yScale(value) + (value < 0 ? settings.labelFontSize + 5 : -9);
        return charting.getLabelProperty(labelText, xPos, yPos, null, value, d, settings.labelFontSize, settings.labelFontFamily);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], xScale: d3.ScaleBand<string>,
    yScale: d3.ScaleLinear<number, number>, y: number, settings: ChartSettings) {
    const xScaleBandWidth = xScale.bandwidth();
    const markerAttrs = charting.getCommentMarkerAttributes(xScaleBandWidth);
    const labelsMargin = settings.showDataLabels ? settings.labelFontSize + 4 : 0;
    const getMarkerVerticalPosition = (d: DataPoint): number => y + yScale(d.value) - (d.value < 0 ? -1 : 1) * (labelsMargin + markerAttrs.radius + markerAttrs.margin);
    const getMarkerHorizontalPosition = (d: DataPoint): number => xScale(d.category) + xScale.bandwidth() / 2;
    charting.addCommentMarkers(container, commentDataPoints, getMarkerHorizontalPosition, getMarkerVerticalPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}
