import * as d3 from "../d3";
import { ChartData, DataPoint, ScenarioOptions } from "./../interfaces";
import { LabelProperties } from "./../library/interfaces";
import { ChartType, DifferenceHighlightFromTo, ReferenceDisplayType, } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";

import {
    Scenario,
    END, MIDDLE, NORMAL, TEXT, PX, FONT_SIZE, FONT_FAMILY, TEXT_ANCHOR, FILL, FILL_OPACITY, STROKE_OPACITY, WIDTH, HEIGHT, HIGHLIGHTABLE, RECT, X, Y, RX, RY,
    BLACK, WHITE, BACKGROUND, TOOLTIP, G, BAR, OPACITY, FONT_WEIGHT, FONT_SIZE_UNIT, CONTEXT_MENU
} from "./../library/constants";
import {
    CHART_CONTAINER, VALUE, SECOND_SEGMENT_VALUE, ACTUAL, ACTUAL_RELATIVE
} from "./../consts";

import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as viewModels from "./../viewModel/viewModelHelperFunctions";
import * as charting from "./chart";
import { showOverlayOnCategoryHover } from "../ui/drawCategoryOverlay";

// eslint-disable-next-line max-lines-per-function
export default function columnChart(reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement, x: number, y: number, height: number, width: number,
    chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number,
    plotTitle: boolean, plotOverlappedReference: boolean, plotLegend: boolean) {
    const scenarioOptions = settings.scenarioOptions;
    let chartWidth = width;
    let chartXPos = x;
    if (plotLegend) {
        chartWidth -= settings.getLegendWidth();
        chartXPos += settings.getLegendWidth();
    }

    if (settings.differenceHighlight) {
        chartWidth -= settings.differenceHighlightWidth;
    }
    if (settings.showGrandTotal) {
        chartWidth = chartWidth * chartData.dataPoints.length / (chartData.dataPoints.length + 1.5);
    }

    if (bottomMargin + topMargin > height) {
        topMargin = 0;
    }

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    const yScale = charting.getYScale(min, max, height, topMargin, bottomMargin);
    const xScale = charting.getXScale(chartData, chartXPos, chartWidth, settings.getGapBetweenColumns());

    charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, chartXPos, y, charting.getChartTitleWidth(chartWidth, settings), height, max, min, topMargin, bottomMargin);

    const setBarHeightToZero = min === 0 && max === 0;
    const referenceDataPoints = chartData.dataPoints.filter(d => d.reference !== null);

    if (plotOverlappedReference && referenceDataPoints.length > 0 && settings.referenceDisplayType === ReferenceDisplayType.OverlappedColumn) {
        let referenceBars = chartContainer
            .selectAll(`.${BAR}_REFERENCE` + chartIndex)
            .data(referenceDataPoints);
        const rect =
            referenceBars.enter()
                .append(RECT)
                .classed(BAR, true)
                .classed(HIGHLIGHTABLE, true);
        referenceBars = referenceBars.merge(rect);
        referenceBars
            .attr(WIDTH, xScale.bandwidth())
            .attr(HEIGHT, d => setBarHeightToZero ? 0 : Math.abs(yScale(max - d.reference) - topMargin))
            .attr(Y, d => y + yScale(d.reference > 0 ? d.reference : 0))
            .attr(X, d => xScale(d.category) - xScale.bandwidth() * settings.getGapBetweenColumns() / 1.6);
        styles.applyToBars(referenceBars, (p: DataPoint) => settings.getValueDataPointColor(p, chartData.group), scenarioOptions.referenceScenario, settings.chartStyle, false, settings.colorScheme);
        if (settings.lightenOverlapped) {
            referenceBars.attr(STROKE_OPACITY, 0.5);
            const overlappedFillOpacity = scenarioOptions.referenceScenario === Scenario.Plan && (!settings.colorScheme.useCustomScenarioColors || settings.colorScheme.applyPatterns) ? +referenceBars.attr(FILL_OPACITY) * 0.5 : 0.5;
            referenceBars.attr(FILL_OPACITY, overlappedFillOpacity);
        }

        charting.addMouseHandlers(referenceBars, svg);
        referenceBars.exit().remove();
    }

    const valuesDataPoints = chartData.dataPoints.filter(dp => dp.value !== null);

    let secondSegmentDataPoints: DataPoint[] = [];
    let secondSegmentValuesOnlyDPs: DataPoint[] = [];
    if (scenarioOptions.secondValueScenario !== null) {
        secondSegmentDataPoints = settings.showAllForecastData ? chartData.dataPoints.filter(dp => dp.secondSegmentValue !== null) :
            chartData.dataPoints.filter(dp => dp.value === null && dp.secondSegmentValue !== null);
        secondSegmentValuesOnlyDPs = settings.showAllForecastData ? secondSegmentDataPoints.filter(dp => dp.value === null) : secondSegmentDataPoints;
        let secondSegmentBars = chartContainer
            .selectAll(`.${BAR}_SECOND_SEGMENT` + chartIndex)
            .data(secondSegmentDataPoints);
        const rect = secondSegmentBars.enter()
            .append(RECT)
            .classed(BAR, true)
            .classed(HIGHLIGHTABLE, true);
        secondSegmentBars = secondSegmentBars.merge(rect)
            .attr(WIDTH, xScale.bandwidth())
            .attr(HEIGHT, d => setBarHeightToZero ? 0 : yScale(max - Math.abs(d.secondSegmentValue)) - topMargin)
            .attr(Y, d => y + yScale(Math.max(0, d.secondSegmentValue)))
            .attr(X, d => xScale(d.category));
        styles.applyToBars(secondSegmentBars, (p: DataPoint) => settings.getValueDataPointColor(p, chartData.group), scenarioOptions.secondValueScenario, settings.chartStyle, false, settings.colorScheme);
        if (secondSegmentValuesOnlyDPs.length > 0) {
            drawing.plotAxisScenarioDelimiter(chartContainer, xScale(secondSegmentValuesOnlyDPs[0].category) - xScale.bandwidth() / 8, y + yScale(0) - 25, y + height);
        }

        charting.addMouseHandlers(secondSegmentBars, svg);
        secondSegmentBars.exit().remove();
    }

    let bars = chartContainer
        .selectAll(`.${BAR}` + chartIndex)
        .data(valuesDataPoints);
    const rect = bars.enter()
        .append(RECT)
        .classed(BAR, true)
        .classed(HIGHLIGHTABLE, true);
    bars = bars.merge(rect);
    bars
        .attr(WIDTH, xScale.bandwidth())
        .attr(HEIGHT, d => setBarHeightToZero ? 0 : Math.abs(yScale(max - d.value) - topMargin))
        .attr(Y, d => y + yScale(d.value > 0 ? d.value : 0))
        .attr(X, d => xScale(d.category));
    styles.applyToBars(bars, (p: DataPoint) => settings.getValueDataPointColor(p, chartData.group), scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme);

    drawing.plotHorizontalAxis(chartContainer, false, chartXPos, chartWidth, y + yScale(0), settings.colorScheme.axisColor, scenarioOptions.referenceScenario);

    if (settings.scenarioOptions.secondReferenceScenario) {
        charting.addReferenceTriangles(chartContainer, chartData.dataPoints.filter(dp => dp.secondReference !== null), settings, xScale, yScale, y, scenarioOptions.secondReferenceScenario, true);
    }

    if (plotOverlappedReference && referenceDataPoints.length > 0 && settings.referenceDisplayType === ReferenceDisplayType.Triangles) {
        charting.addReferenceTriangles(chartContainer, referenceDataPoints, settings, xScale, yScale, y, scenarioOptions.referenceScenario, false);
    }

    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    const hideUnits = settings.shouldHideDataLabelUnits();
    if (settings.showDataLabels) {
        const labelsProperties = getLabelProperties(chartData.dataPoints, settings, labelsFormat, hideUnits, xScale, yScale, y);
        if (labelsProperties.length > 0) {
            if (plotOverlappedReference && !(settings.lightenOverlapped && scenarioOptions.referenceScenario === Scenario.PreviousYear)) {
                charting.plotLabelBackgrounds(chartContainer, chartIndex, labelsProperties, settings, WHITE, true, false);
            }
            charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings);
        }
        if (plotOverlappedReference && valuesDataPoints.length < chartData.dataPoints.length) {
            const referenceOnlyDataPoints = chartData.dataPoints.filter((p, i) => i >= valuesDataPoints.length && p.reference !== null && p.secondSegmentValue === null);
            const referenceLabelProperties = getLabelProperties(referenceOnlyDataPoints, settings, labelsFormat, hideUnits, xScale, yScale, y, true);
            if (referenceLabelProperties.length > 0) {
                charting.plotLabels(chartContainer, `${chartIndex}`, referenceLabelProperties, settings);
            }
        }
    }

    if (settings.differenceHighlight && (chartData.dataPoints.length > 1 || scenarioOptions.referenceScenario !== null && settings.plotOverlappedReference)) {
        const dhDataPoints = valuesDataPoints.length > 0 ? valuesDataPoints : secondSegmentDataPoints;
        let { fromDataPoint, toDataPoint } = charting.getDiffHighlightAutoDataPoints(dhDataPoints, ChartType.Bar, true);
        let isLastAcToLastFc = false;
        if (settings.differenceHighlightFromTo !== DifferenceHighlightFromTo.Auto) {
            if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.LastACToLastFC && secondSegmentDataPoints.length !== 0 && valuesDataPoints.length !== 0) {
                isLastAcToLastFc = true;
                fromDataPoint = valuesDataPoints[valuesDataPoints.length - 1];
                toDataPoint = secondSegmentDataPoints[secondSegmentDataPoints.length - 1];
            }
            else {
                const diffHighlightDataPoints = settings.isSecondSegmentDiffHighlight() ? secondSegmentDataPoints : chartData.dataPoints.filter(p => p.value !== null);
                const fromToDataPoints = charting.getDiffHighlightDataPoints(diffHighlightDataPoints, settings.differenceHighlightFromTo);
                fromDataPoint = fromToDataPoints.fromDP;
                toDataPoint = fromToDataPoints.toDP;
            }
        }

        if (fromDataPoint && toDataPoint) {
            const valueProperty: keyof DataPoint = settings.isSecondSegmentDiffHighlight() && scenarioOptions.secondValueScenario !== null || valuesDataPoints.length === 0 ? SECOND_SEGMENT_VALUE : VALUE;
            const endX = chartXPos + chartWidth + settings.differenceHighlightWidth;
            if (fromDataPoint === toDataPoint && fromDataPoint[valueProperty] !== null && toDataPoint.reference !== null) {
                const dhX = xScale(fromDataPoint.category);
                const dhY1 = y + yScale(fromDataPoint[valueProperty]);
                const dhY2 = y + yScale(fromDataPoint.reference);
                charting.addDifferenceHighlight(chartContainer, settings, slider, endX, dhX, dhX, fromDataPoint[valueProperty], fromDataPoint.reference,
                    dhY1, dhY2, chartData.isInverted);
            }
            else if (fromDataPoint !== toDataPoint && (toDataPoint[valueProperty] !== null && fromDataPoint[valueProperty] !== null
                || isLastAcToLastFc && fromDataPoint.value !== null && toDataPoint.secondSegmentValue !== null)) {
                const dhX1 = xScale(toDataPoint.category);
                const dhX2 = xScale(fromDataPoint.category);
                const startValue = isLastAcToLastFc ? fromDataPoint.value : fromDataPoint[valueProperty];
                const endValue = isLastAcToLastFc ? toDataPoint.secondSegmentValue : toDataPoint[valueProperty];
                const dhY1 = y + yScale(endValue);
                const dhY2 = y + yScale(startValue);
                charting.addDifferenceHighlight(chartContainer, settings, slider, endX, dhX1, dhX2, endValue, startValue, dhY1, dhY2, chartData.isInverted);
            }
        }
    }

    if (settings.showGrandTotal) {
        plotGrandTotalColumnChart(chartContainer, chartData.dataPoints, secondSegmentValuesOnlyDPs, chartIndex, xScale, yScale, max, topMargin, y, x, width, height,
            scenarioOptions, settings, labelsFormat, plotOverlappedReference && scenarioOptions.referenceScenario !== null, settings.locale, settings.showCategories && chartData.showCategoryLabels !== false, chartData.group);
    }

    if (settings.showCommentMarkers() && (scenarioOptions.referenceScenario === null || settings.chartLayout === ACTUAL || settings.chartLayout === ACTUAL_RELATIVE)) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, y, settings);
        }
    }

    charting.addMouseHandlers(bars, svg);
    // Visual.tooltipServiceWrapper.addTooltip(chartContainer.selectAll(`.${BAR}, .${TOOLTIP}`),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => charting.getTooltipData(tooltipEvent.data, settings,
    //         labelsFormat, formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true), chartData.isInverted, undefined, chartData),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => tooltipEvent.data.selectionId);
    if (plotLegend) {
        const legendReferenceDataPoints = plotOverlappedReference ? referenceDataPoints : [];
        const secondReferenceDataPoints = chartData.dataPoints.filter(dp => dp.secondReference !== null);
        charting.plotAllChartLegends(chartContainer, settings, x, y, chartWidth, yScale, valuesDataPoints, legendReferenceDataPoints, secondSegmentDataPoints, secondReferenceDataPoints, false, height);
    }

    bars.exit().remove();

    charting.plotCategories(chartContainer, settings, chartData, y + height, xScale, chartWidth, y + yScale(0), false, chartIndex);
}

function getLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, useReferenceValue: boolean = false): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const labelProperties = labelsDataPoints.map(d => {
        const value = useReferenceValue ? d.reference : viewModels.getDataPointNonNullValue(d);
        const labelText = charting.getFormattedDataLabel(value, settings.decimalPlaces, settings.displayUnits, settings.locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        let xPos = xScale(d.category) + xScale.bandwidth() / 2;
        if (useReferenceValue) {
            xPos -= xScale.bandwidth() * settings.getGapBetweenColumns() / 1.6;
        }
        const yPos = y + yScale(value) + (value < 0 ? settings.labelFontSize + 2 : - 5);
        return charting.getLabelProperty(labelText, xPos, yPos, null, value, d, settings.labelFontSize, settings.labelFontFamily);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

// eslint-disable-next-line max-lines-per-function
function plotGrandTotalColumnChart(reportArea: d3.Selection<SVGElement, any, any, any>, dataPoints: DataPoint[], secondSegmentDataPoints: DataPoint[], chartIndex: number,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, max: number, topMargin: number, y: number, x: number, width: number, height: number,
    scenarioOptions: ScenarioOptions, settings: ChartSettings, labelsFormat: string, plotOverlappedReference: boolean, locale: string, plotTotalLabel: boolean, group: string) {

    const totalDataPoint = charting.getGrandTotalDataPoint(dataPoints, secondSegmentDataPoints, settings);
    if (settings.showAllForecastData) {
        totalDataPoint.secondSegmentValue = d3.sum(dataPoints.map(p => p.secondSegmentValue || 0));
    }
    const valuesDataPoints = dataPoints.filter(dp => dp.value !== null);
    const totalReference = totalDataPoint.reference;
    const totalValue = totalDataPoint.value;
    const totalSecondValue = totalDataPoint.secondSegmentValue;
    const avgReference = totalReference / dataPoints.length;
    let avgValue = 0, avgSecondValue = 0;
    const xScaleCategoryWidth = xScale.bandwidth();
    const totalColumnXPosition = x + width - xScaleCategoryWidth;
    const totalLabelsXPosition = x + width - 0.5 * xScaleCategoryWidth - 10.5;
    const hideUnits = settings.shouldHideDataLabelUnits();

    if (valuesDataPoints.length > 0) {
        avgValue = totalValue / dataPoints.length;
    }
    if (secondSegmentDataPoints.length > 0 || settings.showAllForecastData && scenarioOptions.secondValueScenario === Scenario.Forecast) {
        avgSecondValue = totalSecondValue / dataPoints.length;
    }

    const hasNegativeValues = totalDataPoint.value < 0 || plotOverlappedReference && totalDataPoint.reference < 0 ||
        secondSegmentDataPoints.length > 0 && totalDataPoint.secondSegmentValue < 0;

    let referenceLabelPosition: number = null;
    if (plotOverlappedReference) {
        if (settings.referenceDisplayType === ReferenceDisplayType.OverlappedColumn) {
            let avgReferenceBar = reportArea
                .selectAll(`.${BAR}_REFERENCE_TOTAL` + chartIndex)
                .data([totalDataPoint]);
            const rect = avgReferenceBar.enter()
                .append(RECT)
                .classed(BAR, true);
            avgReferenceBar = avgReferenceBar.merge(rect)
                .attr(WIDTH, xScaleCategoryWidth)
                .attr(HEIGHT, Math.abs(yScale(avgReference) - yScale(0)))
                .attr(Y, y + yScale(avgReference > 0 ? avgReference : 0))
                .attr(X, totalColumnXPosition - xScaleCategoryWidth / 8 - 11);
            styles.applyToBars(avgReferenceBar, (d: DataPoint) => d.color, scenarioOptions.referenceScenario, settings.chartStyle, false, settings.colorScheme);
            if (settings.lightenOverlapped) {
                avgReferenceBar.attr(STROKE_OPACITY, 0.5);
                const overlappedFillOpacity = scenarioOptions.referenceScenario === Scenario.Plan && !settings.colorScheme.useCustomScenarioColors ? +avgReferenceBar.attr(FILL_OPACITY) * 0.5 : 0.5;
                avgReferenceBar.attr(FILL_OPACITY, overlappedFillOpacity);
            }
            avgReferenceBar.exit().remove();
        }

        if (valuesDataPoints.length === 0 || secondSegmentDataPoints.length === 0 && !settings.showAllForecastData) {
            referenceLabelPosition = y + yScale(avgReference) + (avgReference < 0 ? settings.labelFontSize + 3 : - 5);
            reportArea.append(TEXT)
                .text(d => charting.getFormattedDataLabel(totalReference, settings.decimalPlaces, settings.displayUnits, locale,
                    settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits))
                .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
                .style(FONT_FAMILY, charting.getFontFamily(settings.labelFontFamily))
                .style(FONT_WEIGHT, charting.getFontWeight(settings.labelFontFamily))
                .style(TEXT_ANCHOR, MIDDLE)
                .attr(X, totalLabelsXPosition - xScaleCategoryWidth / 8)
                .attr(Y, referenceLabelPosition)
                .attr(FILL, settings.labelFontColor);
        }
    }

    if (secondSegmentDataPoints.length > 0 || settings.showAllForecastData) {
        let avgSecondValueBar = reportArea
            .selectAll(`.${BAR}_SECOND_VALUE_TOTAL` + chartIndex)
            .data([totalDataPoint]);
        const rect = avgSecondValueBar.enter()
            .append(RECT)
            .classed(BAR, true);
        avgSecondValueBar = avgSecondValueBar.merge(rect)
            .attr(WIDTH, xScaleCategoryWidth)
            .attr(HEIGHT, Math.abs(yScale(avgSecondValue) - yScale(0)))
            .attr(Y, d => y + yScale(settings.showAllForecastData ? avgSecondValue : (avgValue < 0 ? avgValue : avgSecondValue + avgValue)))
            .attr(X, settings.showAllForecastData ? totalColumnXPosition - 10 + xScaleCategoryWidth / 8 : totalColumnXPosition - 10);
        styles.applyToBars(avgSecondValueBar, (d: DataPoint) => d.color, scenarioOptions.secondValueScenario, settings.chartStyle, false, settings.colorScheme);
        avgSecondValueBar.exit().remove();
        const color = scenarioOptions.secondValueScenario === Scenario.Forecast ? BLACK : styles.getLabelColor(settings.colorScheme.neutralColor);
        const backgrounds = drawing.getShapes(reportArea, BACKGROUND, BACKGROUND, RECT, [secondSegmentDataPoints[0]]);
        const label = reportArea
            .append(TEXT)
            .text(d => charting.getFormattedDataLabel(totalSecondValue, settings.decimalPlaces, settings.displayUnits, locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits))
            .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
            .style(FONT_FAMILY, charting.getFontFamily(settings.labelFontFamily))
            .style(FONT_WEIGHT, charting.getFontWeight(settings.labelFontFamily))
            .style(TEXT_ANCHOR, MIDDLE)
            .attr(X, settings.showAllForecastData ? totalLabelsXPosition + + xScaleCategoryWidth / 8 : totalLabelsXPosition)
            .attr(Y, y + (settings.showAllForecastData ?
                yScale(avgSecondValue) + (avgSecondValue < 0 ? -settings.labelFontSize - 3 : settings.labelFontSize + 3) :
                yScale(avgSecondValue + avgValue) + (yScale(max - avgSecondValue) - topMargin) / 2 + 5))
            .attr(FILL, color);

        if (backgrounds !== null && scenarioOptions.secondValueScenario === Scenario.Forecast) {
            const boundingBoxes = [];
            label.each(function (d, i) {
                boundingBoxes.push(this.getBBox());
            });
            const paddingTopBottom = 2;
            const i = 0;
            backgrounds
                .attr(X, (d, i) => { return boundingBoxes[i].x; })
                .attr(Y, (d, i) => { return boundingBoxes[i].y - paddingTopBottom / 2; })
                .attr(WIDTH, (d, i) => { return boundingBoxes[i].width; })
                .attr(HEIGHT, (d, i) => { return boundingBoxes[i].height + paddingTopBottom; })
                .attr(FILL, WHITE)
                .attr(RX, 2)
                .attr(RY, 2);
        }
    }

    if (valuesDataPoints.length > 0) {
        let avgValuesBar = reportArea
            .selectAll(`.${BAR}_VALUE_TOTAL` + chartIndex)
            .data([totalDataPoint]);
        const rect = avgValuesBar.enter()
            .append(RECT)
            .classed(BAR, true);
        avgValuesBar = avgValuesBar.merge(rect)
            .attr(WIDTH, xScaleCategoryWidth + 1)
            .attr(HEIGHT, Math.abs(yScale(avgValue) - yScale(0)))
            .attr(Y, y + yScale(avgValue < 0 ? 0 : avgValue))
            .attr(X, totalColumnXPosition - 10.5);
        styles.applyToBars(avgValuesBar, (d: DataPoint) => settings.getGroupDataPointColor(group, d.color), scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme);
        avgValuesBar.exit().remove();

        const lbColor = settings.labelFontColor;
        let valuesLabelYPosition = y + yScale(avgValue) + (avgValue < 0 ? 6 : - 6);
        const backgroundsColor = WHITE;
        const labelText = charting.getFormattedDataLabel(totalValue, settings.decimalPlaces, settings.displayUnits, locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        if (secondSegmentDataPoints.length > 0 || settings.showAllForecastData || referenceLabelPosition !== null && Math.abs(referenceLabelPosition - valuesLabelYPosition) <= settings.labelFontSize) {
            valuesLabelYPosition = y + yScale(avgValue) + (avgValue < 0 ? -settings.labelFontSize - 3 : settings.labelFontSize + 3);
        }
        const labelProperty = charting.getLabelProperty(labelText, totalLabelsXPosition, valuesLabelYPosition, true, totalValue, totalDataPoint, settings.labelFontSize, settings.labelFontFamily);
        charting.plotLabelBackgrounds(reportArea, chartIndex, [labelProperty], settings, backgroundsColor, false, false);
        const valLabel = reportArea
            .append(TEXT)
            .text(labelText)
            .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
            .style(FONT_FAMILY, charting.getFontFamily(settings.labelFontFamily))
            .style(FONT_WEIGHT, charting.getFontWeight(settings.labelFontFamily))
            .style(TEXT_ANCHOR, MIDDLE)
            .attr(X, totalLabelsXPosition)
            .attr(Y, valuesLabelYPosition)
            .attr(FILL, scenarioOptions.valueScenario === Scenario.Forecast ? BLACK : lbColor);
    }

    if (!settings.showAllForecastData && valuesDataPoints.length > 0 && secondSegmentDataPoints.length > 0) {
        reportArea
            .append(TEXT)
            .text(d => charting.getFormattedDataLabel(totalValue + totalSecondValue, settings.decimalPlaces, settings.displayUnits, locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits))
            .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
            .style(FONT_FAMILY, charting.getFontFamily(settings.labelFontFamily))
            .style(FONT_WEIGHT, charting.getFontWeight(settings.labelFontFamily))
            .style(TEXT_ANCHOR, MIDDLE)
            .attr(X, totalLabelsXPosition)
            .attr(Y, y + yScale(avgValue + avgSecondValue) + (avgValue + avgSecondValue < 0 ? settings.labelFontSize + 3 : - 5))
            .attr(FILL, settings.labelFontColor);
    }

    if (settings.scenarioOptions.secondReferenceScenario) {
        charting.addReferenceTriangles(reportArea, [totalDataPoint], settings, xScale, yScale, y, scenarioOptions.secondReferenceScenario, true, totalColumnXPosition - 10, totalDataPoint.secondReference / dataPoints.length);
    }
    if (plotOverlappedReference && settings.referenceDisplayType === ReferenceDisplayType.Triangles) {
        charting.addReferenceTriangles(reportArea, [totalDataPoint], settings, xScale, yScale, y, scenarioOptions.referenceScenario, false, totalColumnXPosition - 10, totalDataPoint.reference / dataPoints.length);
    }
    drawing.plotHorizontalAxis(reportArea, false, totalColumnXPosition - 20, xScaleCategoryWidth + 20, y + yScale(0), settings.colorScheme.axisColor, scenarioOptions.referenceScenario);

    const fontSize = settings.showCategoriesFontSettings ? settings.categoriesFontSize : settings.labelFontSize;
    const fontColor = settings.showCategoriesFontSettings ? settings.categoriesFontColor : settings.labelFontColor;
    const fontFamily = settings.showCategoriesFontSettings ? settings.categoriesFontFamily : settings.labelFontFamily;

    if (plotTotalLabel) {
        const totalLabelTextEl = reportArea
            .append(TEXT)
            .data([totalDataPoint])
            .text(drawing.getTailoredText(settings.grandTotalLabel, fontSize, charting.getFontFamily(fontFamily), charting.getFontWeight(fontFamily), NORMAL, xScaleCategoryWidth + 20))
            .style(FONT_SIZE, fontSize + FONT_SIZE_UNIT)
            .style(TEXT_ANCHOR, MIDDLE)
            .style(FONT_FAMILY, charting.getFontFamily(fontFamily))
            .style(FONT_WEIGHT, charting.getFontWeight(fontFamily))
            .attr(X, totalLabelsXPosition)
            .attr(Y, y + (hasNegativeValues ? height - 1 : yScale(0) + fontSize + 3))
            .attr(FILL, fontColor);

        showOverlayOnCategoryHover(totalLabelTextEl, false, {
            fontSize,
            fontColor,
            fontFamily
        });
    }
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, settings: ChartSettings) {
    const xScaleBandWidth = xScale.bandwidth();
    const markerAttrs = charting.getCommentMarkerAttributes(xScaleBandWidth);
    const labelsMargin = settings.showDataLabels ? settings.labelFontSize + 4 : 0;
    const getMarkerVerticalPosition = (d: DataPoint): number =>
        y + yScale(viewModels.getDataPointNonNullValue(d)) - (labelsMargin + markerAttrs.radius + markerAttrs.margin);
    const getMarkerHorizontalPosition = (d: DataPoint): number => xScale(d.category) + xScaleBandWidth / 2;
    charting.addCommentMarkers(container, commentDataPoints, getMarkerHorizontalPosition, getMarkerVerticalPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}
