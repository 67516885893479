import * as d3 from "../d3";
import { ViewModel, ChartData, DataPoint } from "./../interfaces";
import { ChartType, DifferenceHighlightFromTo, } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { handleLineChartMissingValues, getLineChartLabelProperties, plotCommentMarkers } from "./lineChart";

import {
    Scenario,
    PATH, FILL, OPACITY, STROKE_WIDTH, BLACK, G, NONE, ROUND, D
} from "./../library/constants";
import {
    CHART_CONTAINER, VALUE, SECOND_SEGMENT_VALUE, ACTUAL, REFERENCE, SECOND_REFERENCE,
    AREA_VALUE_GRADIENT, AREA_VALUE_GRADIENT_NEGATIVE, SECOND_VALUE_GRADIENT, SECOND_VALUE_GRADIENT_NEGATIVE
} from "./../consts";

import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as charting from "./chart";

// eslint-disable-next-line max-lines-per-function
export default function areaChart(reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement, viewModel: ViewModel,
    x: number, y: number, height: number, width: number, chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number,
    min: number, max: number, isSingleSeriesViewModel: boolean, plotTitle: boolean, plotLegend: boolean) {
    let chartWidth = width;
    const scenarioOptions = settings.scenarioOptions;
    let chartXPos = x;
    if (plotLegend) {
        chartWidth -= settings.getLegendWidth();
        chartXPos += settings.getLegendWidth();
    }

    if (settings.differenceHighlight) {
        chartWidth -= settings.differenceHighlightWidth;
    }
    else if (charting.shouldProvideSomeSpaceForLineChartRightLegend(settings, plotLegend)) {
        chartWidth -= settings.getRightLegendWidth();
    }

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    const yScale = charting.getYScale(min, max, height, topMargin, bottomMargin);
    const xScale = charting.getXScale(chartData, chartXPos, chartWidth, 0.0);

    charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, chartXPos, y, charting.getChartTitleWidth(chartWidth, settings), height, max, min, topMargin, bottomMargin);
    charting.plotCategories(chartContainer, settings, chartData, y + height, xScale, chartWidth, y + yScale(0), false, chartIndex);

    let areaValueFunction = d3.area<DataPoint>().defined(d => d.value !== null && d.reference !== null)
        .x((d) => {
            return xScale(d.category) + xScale.bandwidth() / 2 + (d.isVariance ? 0 : xScale.bandwidth() * d.reference);
        })
        .y0((d) => {
            return y + (d.isVariance ? yScale(Math.min(d.reference, d.value)) : yScale(d.value));
        })
        .y1((d) => {
            return y + yScale(d.value);
        });

    let areaReferenceFunction = d3.area<DataPoint>().defined(d => d.value !== null && d.reference !== null)
        .x((d) => {
            return xScale(d.category) + xScale.bandwidth() / 2 + (d.isVariance ? 0 : xScale.bandwidth() * d.reference);
        })
        .y0((d) => {
            return y + (d.isVariance ? yScale(Math.min(d.reference, d.value)) : yScale(d.value));
        })
        .y1((d) => {
            return y + (d.isVariance ? yScale(d.reference) : yScale(d.value));
        });

    const areaMinFunction = d3.area<DataPoint>().defined(d => d.value !== null && d.reference !== null)
        .x((d) => {
            return xScale(d.category) + xScale.bandwidth() / 2 + (d.isVariance ? 0 : xScale.bandwidth() * d.reference);
        })
        .y0(y + yScale(0))
        .y1((d) => {
            return y + (d.isVariance ? yScale(Math.min(d.reference || d.value, d.value)) : yScale(d.value));
        });

    const hasSecondSegmentValues = settings.scenarioOptions.secondValueScenario !== null;
    if (settings.handleNullsAsZeros) {
        handleLineChartMissingValues(chartData);
    }
    const areaValueDataPoints = chartData.dataPoints.filter(dp => dp.value !== null);
    const areaReferenceDataPoints = chartData.dataPoints.filter(dp => dp.reference !== null);
    let secondSegmentDataPoints: DataPoint[] = [];
    const modifiedDataPoints: DataPoint[] = [];
    if (hasSecondSegmentValues) {
        secondSegmentDataPoints = chartData.dataPoints.filter(dp => dp.value === null && dp.reference !== null && dp.secondSegmentValue !== null);
        chartData.dataPoints.forEach((dp) => {
            if (dp.value === null && dp.secondSegmentValue !== null) {
                dp.value = dp.secondSegmentValue;
                modifiedDataPoints.push(dp);
            }
        });
    }

    const areaDataAndIntersections = addIntersections(chartData.dataPoints);
    const referenceVarianceColor = styles.getVarianceColor(chartData.isInverted, -1, settings.colorScheme);
    const valueVarianceColor = styles.getVarianceColor(chartData.isInverted, 1, settings.colorScheme);
    const opacity = (settings.chartLayout === ACTUAL ? settings.areaActualOpacity : settings.areaNeutralOpacity) / 100;
    const neutralAreaClass = "neutralArea";
    const neutralAreaSecondValueClass = "neutralAreaSecondValue";

    const valueDataPointColorGetter = settings.getGroupDataPointColor(chartData.group, settings.colorScheme.lineColor);
    const valueDataAreaColorGetter = settings.getGroupDataPointColor(chartData.group, settings.colorScheme.neutralColor);

    if (settings.chartLayout === ACTUAL) {
        areaValueFunction = d3.area<DataPoint>()
            .x(d => xScale(d.category) + xScale.bandwidth() / 2)
            .y0(y + yScale(0))
            .y1(d => y + yScale(d.value));
        areaReferenceFunction = d3.area<DataPoint>()
            .x(d => xScale(d.category) + xScale.bandwidth() / 2)
            .y0(y + yScale(0))
            .y1(d => y + yScale(d.reference));
        if (hasSecondSegmentValues) {
            const lastValuePoint = areaValueDataPoints[areaValueDataPoints.length - 1];
            const lastValuePointIndex = areaValueDataPoints.length - 1;
            const valueAreaPoints = chartData.dataPoints.slice(0, lastValuePointIndex + 1);
            const secondValueAreaPoints = lastValuePointIndex !== -1 ? chartData.dataPoints.slice(lastValuePointIndex, chartData.dataPoints.length) : chartData.dataPoints;
            plotArea(chartContainer, settings, areaValueFunction, valueAreaPoints, opacity, settings.colorScheme.neutralColor, scenarioOptions.valueScenario, neutralAreaClass);
            plotArea(chartContainer, settings, areaValueFunction, secondValueAreaPoints, opacity, settings.colorScheme.neutralColor, scenarioOptions.secondValueScenario, neutralAreaSecondValueClass);
            if (secondSegmentDataPoints.length > 0 && lastValuePoint) {
                const lastValue = lastValuePoint.value;
                drawing.plotAxisScenarioDelimiter(chartContainer, xScale(secondSegmentDataPoints[0].category) - xScale.bandwidth() / 2, y + yScale(lastValue), y + yScale(0));
            }
        }
        else {
            plotArea(chartContainer, settings, areaValueFunction, areaValueDataPoints, opacity, valueDataAreaColorGetter, scenarioOptions.valueScenario, neutralAreaClass);
        }
    }
    else {
        const varianceOpacity = settings.areaVarianceOpacity / 100;
        if (hasSecondSegmentValues) {
            const lastValuePoint = areaValueDataPoints[areaValueDataPoints.length - 1];
            const lastValuePointIndex = areaDataAndIntersections.indexOf(lastValuePoint);
            const valueAreaPoints = areaDataAndIntersections.slice(0, lastValuePointIndex + 1);
            const secondValueAreaPoints = lastValuePointIndex !== -1 ? areaDataAndIntersections.slice(lastValuePointIndex, areaDataAndIntersections.length) : areaDataAndIntersections;
            plotArea(chartContainer, settings, areaMinFunction, valueAreaPoints, opacity, settings.colorScheme.neutralColor, scenarioOptions.valueScenario, neutralAreaClass);
            plotArea(chartContainer, settings, areaReferenceFunction, valueAreaPoints, varianceOpacity, referenceVarianceColor, scenarioOptions.valueScenario);
            plotArea(chartContainer, settings, areaValueFunction, valueAreaPoints, varianceOpacity, valueVarianceColor, scenarioOptions.valueScenario);
            plotArea(chartContainer, settings, areaMinFunction, secondValueAreaPoints, opacity, settings.colorScheme.neutralColor, scenarioOptions.secondValueScenario, neutralAreaSecondValueClass);
            plotArea(chartContainer, settings, areaReferenceFunction, secondValueAreaPoints, varianceOpacity, referenceVarianceColor, scenarioOptions.secondValueScenario);
            plotArea(chartContainer, settings, areaValueFunction, secondValueAreaPoints, varianceOpacity, valueVarianceColor, scenarioOptions.secondValueScenario);
            if (secondSegmentDataPoints.length > 0 && lastValuePoint) {
                const lastValue = lastValuePoint.value;
                drawing.plotAxisScenarioDelimiter(chartContainer, xScale(secondSegmentDataPoints[0].category) - xScale.bandwidth() / 2, y + yScale(lastValue), y + yScale(0));
            }
        }
        else {
            plotArea(chartContainer, settings, areaMinFunction, areaDataAndIntersections, opacity, valueDataAreaColorGetter, scenarioOptions.valueScenario, neutralAreaClass);
            plotArea(chartContainer, settings, areaReferenceFunction, areaDataAndIntersections, varianceOpacity, referenceVarianceColor, scenarioOptions.valueScenario);
            plotArea(chartContainer, settings, areaValueFunction, areaDataAndIntersections, varianceOpacity, valueVarianceColor, scenarioOptions.valueScenario);
        }
    }

    if (settings.hasAxisBreak && chartData.axisBreak) {
        const isBreakNegative = chartData.axisBreak < 0;
        const valueGradient = isBreakNegative ? AREA_VALUE_GRADIENT_NEGATIVE : AREA_VALUE_GRADIENT;
        const neutralArea = chartContainer.selectAll("." + neutralAreaClass);
        chartData.isGroupHighlighted ? neutralArea.attr(FILL, `url(#${chartData.highlightGroupColor})`) : neutralArea.attr(FILL, `url(#${valueGradient})`);
        if (hasSecondSegmentValues) {
            const neutralAreaSecondSegment = chartContainer.selectAll("." + neutralAreaSecondValueClass);
            neutralAreaSecondSegment.attr(FILL, `url(#${isBreakNegative ? SECOND_VALUE_GRADIENT_NEGATIVE : SECOND_VALUE_GRADIENT})`);
        }
    }

    let secondReferenceDataPoints: DataPoint[] = [];
    if (settings.scenarioOptions.secondReferenceScenario !== null) {
        secondReferenceDataPoints = chartData.dataPoints.filter(dp => dp.secondReference !== null);
        const secondLineReferenceFunction = d3.line<DataPoint>()
            .x((d) => { return xScale(d.category) + xScale.bandwidth() / 2; })
            .y((d) => { return y + yScale(d.secondReference); });
        const secondReferenceLine = styles.drawLine(chartContainer, NONE, settings.colorScheme.lineColor, ROUND, scenarioOptions.secondReferenceScenario, true, settings.chartStyle, settings.colorScheme);
        secondReferenceLine.attr(D, secondLineReferenceFunction(secondReferenceDataPoints));
    }

    if (!settings.hasAxisBreak || chartData.axisBreak === 0) {
        drawing.plotHorizontalAxis(chartContainer, false, chartXPos, chartWidth, y + yScale(0), settings.colorScheme.axisColor, scenarioOptions.referenceScenario);
    }
    const effectiveAxisBreak = settings.hasAxisBreak && chartData.axisBreak !== null ? chartData.axisBreak : 0;

    const lineValueFunction = d3.line<DataPoint>().defined(d => d.value !== null)
        .x((d) => { return xScale(d.category) + xScale.bandwidth() / 2; })
        .y((d) => { return y + yScale(d.value); });
    const lineReferenceFunction = d3.line<DataPoint>().defined(d => d.reference !== null)
        .x((d) => { return xScale(d.category) + xScale.bandwidth() / 2; })
        .y((d) => { return y + yScale(d.reference); });
    const lineSecondValueFunction = d3.line<DataPoint>().defined(d => d.secondSegmentValue !== null)
        .x((d) => { return xScale(d.category) + xScale.bandwidth() / 2; })
        .y((d) => { return y + yScale(d.secondSegmentValue); });

    if (settings.scenarioOptions.referenceScenario) {
        const referenceLine = styles.drawLine(chartContainer, NONE, settings.colorScheme.lineColor, ROUND, scenarioOptions.referenceScenario, true, settings.chartStyle, settings.colorScheme);
        referenceLine.attr(D, lineReferenceFunction(chartData.dataPoints));
    }

    const valueLine = styles.drawLine(chartContainer, NONE, valueDataPointColorGetter, ROUND, scenarioOptions.valueScenario, false, settings.chartStyle, settings.colorScheme);
    valueLine.attr(D, lineValueFunction(hasSecondSegmentValues ? areaValueDataPoints : chartData.dataPoints));
    if (hasSecondSegmentValues) {
        const secondSegmentValueLine = styles.drawLine(chartContainer, NONE, settings.colorScheme.lineColor, ROUND, scenarioOptions.secondValueScenario, false, settings.chartStyle, settings.colorScheme);
        const secondSegmentLineDataPoints = settings.showAllForecastData ? chartData.dataPoints.filter(dp => dp.secondSegmentValue !== null) :
            areaValueDataPoints.filter((p, i) => i === areaValueDataPoints.length - 1).concat(secondSegmentDataPoints);
        secondSegmentValueLine.attr(D, settings.showAllForecastData ? lineSecondValueFunction(secondSegmentLineDataPoints) : lineValueFunction(secondSegmentLineDataPoints));
    }

    if (settings.showDataLabels) {
        const allDataPoints = areaValueDataPoints.concat(secondSegmentDataPoints);
        const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
        const hideUnits = settings.shouldHideDataLabelUnits();
        const labelsProperties = getLineChartLabelProperties(allDataPoints, settings, labelsFormat, hideUnits, xScale, yScale,
            y, chartXPos, chartWidth, effectiveAxisBreak, max, topMargin, isSingleSeriesViewModel);
        if (labelsProperties.length > 0) {
            charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings);
        }

        if (areaReferenceDataPoints.length > 0 && scenarioOptions.referenceScenario) {
            const referenceMarkerDataPoints = [areaReferenceDataPoints[areaReferenceDataPoints.length - 1]];
            charting.plotMarkers(chartContainer, areaReferenceDataPoints, settings, xScale, y, yScale, scenarioOptions.referenceScenario, REFERENCE, true, false, referenceMarkerDataPoints, chartData);
            if (settings.showReferenceLabels) {
                const labelsProperties = getLineChartLabelProperties(areaReferenceDataPoints, settings, labelsFormat, hideUnits, xScale, yScale, y, chartXPos, chartWidth, effectiveAxisBreak, max, topMargin, isSingleSeriesViewModel, true);
                if (labelsProperties.length > 0) {
                    const referenceLabels = charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings);
                    if (scenarioOptions.referenceScenario === Scenario.PreviousYear) {
                        referenceLabels.attr(FILL, styles.getLighterColor(settings.labelFontColor));
                    }
                }
            }
        }
        const valueMarkersDataPoints = areaValueDataPoints.filter(charting.densityFilter, settings);
        charting.plotMarkers(chartContainer, areaValueDataPoints, settings, xScale, y, yScale, scenarioOptions.valueScenario, VALUE, false, true, valueMarkersDataPoints, chartData);
        if (hasSecondSegmentValues) {
            const secondValueMarkersPoints = secondSegmentDataPoints.filter(charting.densityFilter, settings);
            charting.plotMarkers(chartContainer, secondSegmentDataPoints, settings, xScale, y, yScale, scenarioOptions.secondValueScenario, SECOND_SEGMENT_VALUE, false, true, secondValueMarkersPoints, chartData);
        }
        if (settings.scenarioOptions.secondReferenceScenario !== null && secondReferenceDataPoints.length > 0) {
            const secondReferenceMarker = [secondReferenceDataPoints[secondReferenceDataPoints.length - 1]];
            charting.plotMarkers(chartContainer, secondReferenceDataPoints, settings, xScale, y, yScale, scenarioOptions.secondReferenceScenario, SECOND_REFERENCE, true, false, secondReferenceMarker, chartData);
        }
    }

    charting.addDroplineHandlers(chartContainer, y, height, bottomMargin, settings.titleFontSize + 3, xScale, chartData.dataPoints, viewModel);

    if (settings.differenceHighlight && areaValueDataPoints.length > 0) {
        let { fromDataPoint, toDataPoint } = charting.getDiffHighlightAutoDataPoints(areaValueDataPoints, ChartType.Area, isSingleSeriesViewModel);
        let isLastAcToLastFc = false;
        if (settings.differenceHighlightFromTo !== DifferenceHighlightFromTo.Auto) {
            if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.LastACToLastFC && secondSegmentDataPoints.length !== 0 && areaValueDataPoints.length !== 0) {
                isLastAcToLastFc = true;
                fromDataPoint = areaValueDataPoints[areaValueDataPoints.length - 1];
                toDataPoint = secondSegmentDataPoints[secondSegmentDataPoints.length - 1];
            }
            else {
                const diffHighlightDataPoints = hasSecondSegmentValues && settings.isSecondSegmentDiffHighlight() ? secondSegmentDataPoints : areaValueDataPoints;
                const fromToDataPoints = charting.getDiffHighlightDataPoints(diffHighlightDataPoints, settings.differenceHighlightFromTo);
                fromDataPoint = fromToDataPoints.fromDP;
                toDataPoint = fromToDataPoints.toDP;
            }
        }

        if (fromDataPoint && toDataPoint) {
            const valueProperty: keyof DataPoint = hasSecondSegmentValues && settings.isSecondSegmentDiffHighlight() ? SECOND_SEGMENT_VALUE : VALUE;
            if (fromDataPoint === toDataPoint && fromDataPoint[valueProperty] !== null && toDataPoint.reference !== null) {
                const dhX = xScale(fromDataPoint.category) + xScale.bandwidth() / 2;
                const dhY1 = y + yScale(fromDataPoint[valueProperty]);
                const dhY2 = y + yScale(fromDataPoint.reference);
                charting.addDifferenceHighlight(chartContainer, settings, slider, x + width, dhX, dhX,
                    fromDataPoint[valueProperty] + effectiveAxisBreak, fromDataPoint.reference + effectiveAxisBreak, dhY1, dhY2, chartData.isInverted);
            }
            else if (fromDataPoint !== toDataPoint && (fromDataPoint[valueProperty] !== null && toDataPoint[valueProperty] !== null
                || isLastAcToLastFc && fromDataPoint.value !== null && toDataPoint.secondSegmentValue !== null)) {
                const dhX1 = xScale(toDataPoint.category) + xScale.bandwidth() / 2;
                const dhX2 = xScale(fromDataPoint.category) + xScale.bandwidth() / 2;
                const startValue = isLastAcToLastFc ? fromDataPoint.value : fromDataPoint[valueProperty];
                const endValue = isLastAcToLastFc ? toDataPoint.secondSegmentValue : toDataPoint[valueProperty];
                const dhY1 = y + yScale(endValue);
                const dhY2 = y + yScale(startValue);
                charting.addDifferenceHighlight(chartContainer, settings, slider, x + width, dhX1, dhX2,
                    endValue + effectiveAxisBreak, startValue + effectiveAxisBreak, dhY1, dhY2, chartData.isInverted);
            }
        }
    }
    const axisBreakXPos = charting.getAxisBreakUIxPosition(chartXPos, chartWidth, viewModel, settings);
    charting.addAxisBreakLineChartsUIHandlers(chartContainer, axisBreakXPos, y + settings.titleFontSize + 3, y + yScale(0) - 1, settings, slider);

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, y, settings);
        }
    }

    charting.addHighlightedDataPointsDroplines(settings, chartData, xScale, y, yScale, chartContainer);

    modifiedDataPoints.forEach(dp => {
        dp.value = null;
    });

    if (plotLegend) {
        charting.plotAllChartLegends(chartContainer, settings, x, y, chartWidth, yScale, areaValueDataPoints, areaReferenceDataPoints, secondSegmentDataPoints, secondReferenceDataPoints, false, height);
    }
}

function plotArea(chartContainer: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, areaFunction: d3.Area<DataPoint>, areaDataPoints: DataPoint[],
    opacity: number, color: string, scenario: Scenario, className?: string) {
    const area = chartContainer
        .append(PATH)
        .classed(className, true)
        .attr(STROKE_WIDTH, 0)
        .attr(OPACITY, opacity)
        .attr(D, areaFunction(areaDataPoints));
    styles.applyToElement(area, color, scenario, settings.chartStyle, settings.colorScheme);
}

function addIntersections(dataPoints: DataPoint[]): DataPoint[] {
    const result: DataPoint[] = [];
    for (let i = 0; i < dataPoints.length - 1; i++) {
        const current = dataPoints[i];
        const next = dataPoints[i + 1];
        result.push(current);
        if (current.value !== null && current.reference !== null && next.value !== null && next.reference !== null) {
            if ((current.value - current.reference) * (next.value - next.reference) < 0) {
                const intersection = line_intersect(0, current.reference, 1, next.reference,
                    0, current.value, 1, next.value);
                result.push({
                    category: current.category,
                    color: BLACK,
                    value: intersection.y,
                    isNegative: false,
                    isVariance: false,
                    reference: intersection.x,
                    secondSegmentValue: null,
                    isHighlighted: false,
                });
            }
        }
    }
    result.push(dataPoints[dataPoints.length - 1]);
    return result;
}

function line_intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
    const denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

    if (denom === 0) {
        return null;
    }
    const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
    const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;
    return {
        x: x1 + ua * (x2 - x1),
        y: y1 + ua * (y2 - y1),
        seg1: ua >= 0 && ua <= 1,
        seg2: ub >= 0 && ub <= 1,
    };
}
