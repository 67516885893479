import { flagHandler } from "@zebrabi/zebrabi-core";

export default class GlobalToolbarFeature {

    constructor(private visual: any) {}

    public init(settings?: any) {
        if (flagHandler.has("global-toolbar")) {
            this.visual.initGlobalToolbar(settings);
        } else {
            this.visual.initGlobalToolbarOld(settings);
        }
    }
}
