import { ViewMode } from "@zebrabi/table-data";

import { Visual } from "./../visual";
import { ChartSettings } from "./../settings/chartSettings";
import {
    TEXT, FONT_SIZE_UNIT, NORMAL, EMPTY, DEFAULT_FONT, PX, BOLD, SEGOE_UI, SEGOE_UI_BOLD, CHANGE, FONT_FAMILY, BACKGROUND, HEADER, DIV, P, ARROW_DOWN, MOUSEENTER, MOUSELEAVE, DISPLAY, NONE, INPUT, HEADER_TOOLTIP, CLICK, BLOCK, MOUSEOVER, RIGHT, WIDTH, LEFT, COLOR, FONT_SIZE, WHITE_SPACE, TEXT_ALIGN, FLOAT, Z_INDEX, BOX_SHADOW, HEIGHT, TOP, BLACK, DEFAULT_FONT_SIZE, MARGIN_LEFT, POSITION, POLYLINE, POINTS, STROKE, GRAY, STROKE_WIDTH, FILL_OPACITY, VISIBILITY, VISIBLE, G, DROP_DOWN_ARROW, TITLE_ARROW_ICON, HEADER_DROPDOWN_SETTINGS_ARROW, PADDING_LEFT, HIDDEN, BACKGROUND_COLOR, PADDING_RIGHT, FLEX, BOX_SIZING, BORDER_BOX, JUSTIFY_CONTENT, CENTER, FLEX_START, FLEX_END, FIT_CONTENT, ENTER
} from "./../library/constants";
import * as drawing from "./../library/drawing";
import { TitleMenu } from "@zebrabi/design-library";

import * as d3 from "../d3";
import { RELATIVE } from "../consts";

export class Title {
    private titleWidth: number;
    private startingTextAlign: string;
    private text: string;
    private fontFamily: string;
    private fontWeight: string;
    private fontSize: number;
    private fontColor: string;
    private titleTextElement: d3.Selection<HTMLElement, any, any, any>;
    private titleTextChanged: boolean;
    private titleFontFamilyChanged: boolean;
    private titleFontColorChanged: boolean;
    private titleFontSizeChanged: boolean;
    private titleSelection: d3.Selection<HTMLElement, any, any, any>;
    private titleElement: HTMLElement;
    private titleContainer: d3.Selection<HTMLElement, any, any, any>;
    private titleContainerWidth: number;
    private arrow: d3.Selection<SVGElement, any, any, any>;
    private titleInput: HTMLInputElement;
    private isWrapping: boolean;
    private svg: d3.Selection<SVGElement, any, any, any>;
    private clickOutsideListener: any;

    constructor(private settings: ChartSettings, parentElement: HTMLElement, private titleText: string, svg: d3.Selection<SVGElement, any, any, any>) {
        this.startingTextAlign = settings.titleAlignment;
        this.fontFamily = this.settings.titleFontFamily;
        this.fontSize = this.settings.titleFontSize;
        this.fontColor = this.settings.titleFontColor;
        this.text = this.titleText;
        this.fontWeight = NORMAL;
        this.titleContainer = d3.select(Visual.title);
        this.titleContainer.style(DISPLAY, FLEX);
        this.titleContainer.style(BOX_SIZING, BORDER_BOX);
        this.svg = svg;
        const bb = drawing.getBoundingBoxHtml(<HTMLElement>this.titleContainer.node());
        this.titleContainerWidth = bb.width;
        this.calculateTitleWidth();
        this.isWrapping = false;
        if (settings.titleWrap) {
            if (this.titleContainerWidth < this.titleWidth) {
                this.isWrapping = true;
                this.titleWidth = this.titleContainerWidth - 5;
            }
        }

        this.titleTextChanged = false;
        this.titleFontFamilyChanged = false;
        this.titleFontColorChanged = false;
        this.titleFontSizeChanged = false;
    }

    public addTitle() {
        this.plotTitle();
        if (this.settings.viewMode === ViewMode.Edit && this.settings.proFeaturesUnlocked) {
            this.addEventHandlers();
        }
    }
    private calculateTitleWidth() {
        if (this.fontFamily === SEGOE_UI_BOLD) {
            this.titleWidth = drawing.measureTextWidth(this.titleText, this.fontSize, SEGOE_UI, BOLD, NORMAL);
        }
        else {
            this.titleWidth = drawing.measureTextWidth(this.titleText, this.fontSize, this.fontFamily, this.fontWeight, NORMAL);
        }
    }

    public plotTitle() {

        this.titleSelection = this.titleContainer.append(DIV).classed(HEADER, true);

        this.titleElement = <HTMLElement>this.titleSelection.node();

        this.titleSelection
            .style(DISPLAY, FLEX)
            .style(LEFT, this.getXposition() + FONT_SIZE_UNIT)
            .style(COLOR, this.settings.titleFontColor);

        this.titleTextElement = this.titleSelection.append(P).text(this.text);
        this.titleTextElement
            .style(FONT_SIZE, this.fontSize + FONT_SIZE_UNIT)
            .style(WHITE_SPACE, this.isWrapping ? "normal" : "nowrap")
            .style(TEXT_ALIGN, this.startingTextAlign);
        drawing.applyFontFamily(this.titleTextElement, this.fontFamily, false, false);

        this.arrow = drawing.createSvgElement(this.titleSelection.node(), HEADER_DROPDOWN_SETTINGS_ARROW);
        this.arrow
            .attr(WIDTH, 15)
            .attr(FLOAT, RIGHT)
            .attr(HEIGHT, this.getHeight())
            .style(DISPLAY, NONE)
            .append(POLYLINE)
            .attr(POINTS, p => {
                let y = (this.getHeight() / 2);
                return `${3} ${y}, ${8} ${y + 5}, ${13} ${y}`;
            })
            .attr(STROKE, GRAY)
            .attr(STROKE_WIDTH, 1)
            .attr(FILL_OPACITY, 0)
            .attr(VISIBILITY, VISIBLE);
    }

    public addEventHandlers() {
        this.setButtonHoverHandlers();
        this.setTitleSettingsForm();
    }

    private setButtonHoverHandlers() {
        this.titleSelection
            .on(MOUSEENTER, () => {
                Visual.textSettings.style(DISPLAY, NONE);
                this.arrow.style(DISPLAY, "inline");
                this.arrow.style(FLOAT, RIGHT);
                this.titleSelection
                    .style(Z_INDEX, 2)
                    .style(BOX_SHADOW, "0 8px 10px 1px rgba(0, 0, 0, 0.10), 0 3px 14px 2px rgba(0, 0, 0, 0.09), 0 5px 5px -3px rgba(0, 0, 0, 0.15)")
                    .style(DISPLAY, FLEX);
                this.fixTitleHeight();
            })
            .on(MOUSELEAVE, () => {
                this.arrow.style(DISPLAY, NONE);
                this.titleSelection
                    .style(BOX_SHADOW, NONE)
                    .style(Z_INDEX, 1)
                d3.selectAll("." + HEADER_TOOLTIP).remove();
                this.fixTitleHeight();
            });
    }

    private getXposition() {
        let xpos = 0;
        const bb = drawing.getBoundingBoxHtml(<HTMLElement>this.titleElement.parentElement);
        if (this.startingTextAlign === "center") {
            xpos = bb.width / 2 - this.titleWidth / 2;
        }
        else if (this.startingTextAlign === "right") {
            xpos = this.isWrapping ? bb.width - this.titleWidth - 5 : bb.width - this.titleWidth - 18;
        }
        return xpos;
    }

    private fixTitleHeight() {
        const bb = drawing.getBoundingBoxHtml(<HTMLElement>this.titleTextElement.node());
        this.titleContainer
            .style(HEIGHT, (bb.height + 3) + PX);
        this.titleSelection
            .style(HEIGHT, (bb.height + 3) + PX);
    }

    public getTitleHeight() {
        const bb = drawing.getBoundingBoxHtml(<HTMLElement>this.titleTextElement.node());
        return bb.height;
    }

    public fixPickerPosition(classname: string) {
        const pickerSelection = d3.select(classname);
        const pickerheight = parseInt(pickerSelection.style(HEIGHT));
        const visualHeight = Visual.visualViewPort.height;
        if (visualHeight < pickerheight + parseInt(pickerSelection.style(TOP))) {
            pickerSelection.style(TOP, (visualHeight - pickerheight) / 2 - 10 + PX);
        }
    }

    private closeTitleSettingsForm() {
        document.querySelector(".zebrabi-charts-container")?.removeEventListener(CLICK, this.clickOutsideListener, true);
        Visual.textSettings.style(DISPLAY, NONE);
        Visual.titleMenuSettings?.remove();
        Visual.titleMenuSettings = null;
    }

    private setTitleSettingsForm() {
        this.titleSelection
            // eslint-disable-next-line max-lines-per-function
            .on(CLICK, (event) => {
                event.stopPropagation();
                let titleSettingsDiv = <HTMLElement>Visual.textSettings.node();
                let isShown = Visual.titleMenuSettings !== null;
                if (isShown) {
                    Visual.textSettings.style(DISPLAY, NONE);
                    Visual.titleMenuSettings?.remove();
                    Visual.titleMenuSettings = null;
                    return;
                }
                // Visual.textSettings.style(DISPLAY, BLOCK);
                let bb = (<HTMLElement>this.titleSelection.node()).getBoundingClientRect();

                // Get title menu from design library
                let titleMenu = new TitleMenu(titleSettingsDiv, this.titleText, this.fontColor, this.fontSize, this.fontFamily);
                let [menuElement, inputElement, dropdownElement, fontSizeElement, colorSquare, resetButton, pickr] = titleMenu.openItemMenu(bb.left, bb.top + bb.height + 2);

                // Register listeners
                Visual.titleMenuSettings = d3.select(menuElement);
                d3.select(inputElement).on(INPUT, (event) => {
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }
                    this.setTitleText((<HTMLInputElement>event.target).value);
                });
                inputElement.focus();
                (<HTMLInputElement>inputElement).setSelectionRange(this.titleText.length, this.titleText.length);

                d3.select(inputElement).on("keyup", (event) => {
                    if (event.key === ENTER) {
                        this.setTitleText((<HTMLInputElement>event.target).value, true)
                    }
                });
                d3.select(dropdownElement).on(CHANGE, (event) => {
                    event.preventDefault();
                    this.setFontFamily((<HTMLSelectElement>event.target).value);
                }).on("keyup", () => {
                    (<KeyboardEvent>event).preventDefault();
                    if ((<KeyboardEvent>event).key === 'Enter') {
                        this.setFontFamily((<HTMLSelectElement>event.target).value);
                    }
                });

                d3.select(fontSizeElement).on(CHANGE, (event) => {
                    let valueAsANumber = (<HTMLInputElement>event.target).valueAsNumber;

                    if (valueAsANumber > 60) {
                        valueAsANumber = 60;
                        (<HTMLInputElement>event.target).value = '60';
                    } else if (valueAsANumber < 8) {
                        valueAsANumber = 8;
                        (<HTMLInputElement>event.target).value = '8';
                    }
                    this.setFontSize(valueAsANumber);
                }).on("keyup", () => {
                    if ((<KeyboardEvent>event).key === 'Enter') {
                        this.setFontSize((<HTMLInputElement>event.target).valueAsNumber, true);
                    }
                })
                d3.select(resetButton).on("click", () => {
                    this.settings.persistTitleStyle(EMPTY, BLACK, DEFAULT_FONT, 12);
                    this.closeTitleSettingsForm();
                });

                let fontColorPickerRevertBack = true;
                pickr.on("save", (color) => {
                    this.setFontColor(color ? color.toHEXA().toString() : null, colorSquare);
                    pickr.hide();
                }).on("clear", () => {
                    this.setFontColor(BLACK, colorSquare);
                    pickr.hide();
                }).on("show", () => {
                    fontColorPickerRevertBack = false;
                    this.fixPickerPosition('.fontpickr');
                }).on("change", (color) => {
                    this.setFontColor(color ? color.toHEXA().toString() : null, colorSquare);
                }).on("hide", () => {
                    // delay setting this variable for clickOutsideListener to run before it - this will prevent the tooltip from closing after selection
                    setTimeout(() => {
                        fontColorPickerRevertBack = true;
                    }, 20);
                });

                this.clickOutsideListener = (event: PointerEvent) => {
                    const wasClickedOutside = !Visual.titleMenuSettings?.node()?.contains(<Node>event.target);
                    event.stopPropagation();
                    if (fontColorPickerRevertBack && wasClickedOutside) {
                        this.setFontColor(this.fontColor, colorSquare, true);
                        this.titleSelection.node().click();
                        this.closeTitleSettingsForm();
                    }
                }
                document.querySelector(".zebrabi-charts-container")?.addEventListener(CLICK, this.clickOutsideListener
                , true);

                bb = titleSettingsDiv.getBoundingClientRect();
                const bbtotal = drawing.getBoundingBoxHtml(this.titleElement.parentElement);
                if (bb.left + bb.width > bbtotal.width) {
                    const left = bbtotal.width - bb.width - 2;
                    Visual.textSettings
                        .style(LEFT, left + PX);
                }
            })
    }
    public getHeight(): number {
        return drawing.getBoundingBoxHtml(this.titleElement).height;
    }

    private setTitleText(titleText: string, persist = false): void {
        this.titleText = titleText;
        // make sure the element doesn't lose it's height when user removes all text - otherwise could lead to focusout event triggering
        if (!titleText) {
            this.titleTextElement.style('min-height', this.titleTextElement.style('height'))
        }
        this.titleTextElement.text(titleText);

        this.attemptToPersistChanges(persist);
    }

    private setFontSize(fontSize: number, persist = false): void {
        this.fontSize = fontSize;
        this.titleTextElement.style(FONT_SIZE, `${fontSize}${FONT_SIZE_UNIT}`);

        this.attemptToPersistChanges(persist);
    }

    private setFontFamily(fontFamily: string, persist = false): void {
        this.fontFamily = fontFamily;
        this.titleTextElement.style(FONT_FAMILY, fontFamily);

        this.attemptToPersistChanges(persist);
    }

    private setFontColor(fontColor: string, colorSquare: HTMLElement, persist = false): void {
        this.fontColor = fontColor;
        this.titleTextElement.style(COLOR, fontColor);
        d3.select(colorSquare).style(BACKGROUND, fontColor);

        this.attemptToPersistChanges(persist);
    }

    public attemptToPersistChanges = (persist: boolean) => {
        if (persist) {
            this.settings.persistTitleStyle(this.titleText, this.fontColor, this.fontFamily, this.fontSize);
            this.closeTitleSettingsForm();
        }
    }
}
