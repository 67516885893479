import React from "react";
import ReactDOM from "react-dom/client";
import ExcelDataLinkingScreen from "./ExcelDataLinkingScreen";

const ROOT_ELEMENT_ID = "excel-data-linking";

function addRootDOMElement() {
  if (!document.getElementById(ROOT_ELEMENT_ID)) {
    const element = document.createElement("div");
    element.setAttribute("id", ROOT_ELEMENT_ID);
    document.body.prepend(element);
  }
}

export function changeRootDOMElementDisplay(display: "block" | "none") {
  const rootElement = document.getElementById(ROOT_ELEMENT_ID) as HTMLDivElement;

  if (rootElement) {
    rootElement.style.display = display;
  }
}

export function mountReactExcelDataLinking(accessToken: string) {
  const rootElement = document.getElementById(ROOT_ELEMENT_ID) as HTMLDivElement;

  if (rootElement) {
    changeRootDOMElementDisplay("block");
    return;
  }

  addRootDOMElement();

  const reactRoot = ReactDOM.createRoot(document.getElementById(ROOT_ELEMENT_ID) as HTMLDivElement);

  reactRoot.render(
    <React.StrictMode>
      <ExcelDataLinkingScreen accessToken={accessToken} />
    </React.StrictMode>
  );
}
