import { SheetData } from "x-data-spreadsheet";

export const PartWholeStructureBarSampleData: SheetData = {
  rows: {
    0: {
      cells: {
        0: { text: "Category" },
        1: { text: "Europe" },
        2: { text: "America" },
        3: { text: "Asia" },
        4: { text: "Africa" },
        5: { text: "Others" },
      },
    },
    1: {
      cells: {
        0: { text: "Oranges" },
        1: { text: "91" },
        2: { text: "47" },
        3: { text: "16" },
        4: { text: "10" },
        5: { text: "15" },
      },
    },
    2: {
      cells: {
        0: { text: "Lemons" },
        1: { text: "80" },
        2: { text: "50" },
        3: { text: "20" },
        4: { text: "24" },
        5: { text: "17" },
      },
    },
    3: {
      cells: {
        0: { text: "Bananas" },
        1: { text: "78" },
        2: { text: "45" },
        3: { text: "18" },
        4: { text: "56" },
        5: { text: "9" },
      },
    },
    4: {
      cells: {
        0: { text: "Kiwi" },
        1: { text: "73" },
        2: { text: "42" },
        3: { text: "17" },
        4: { text: "32" },
        5: { text: "8" },
      },
    },
    5: {
      cells: {
        0: { text: "Apples" },
        1: { text: "74" },
        2: { text: "32" },
        3: { text: "17" },
        4: { text: "42" },
        5: { text: "15" },
      },
    },
    6: {
      cells: {
        0: { text: "Melons" },
        1: { text: "79" },
        2: { text: "44" },
        3: { text: "14" },
        4: { text: "42" },
        5: { text: "8" },
      },
    },
    7: {
      cells: {
        0: { text: "Pears" },
        1: { text: "71" },
        2: { text: "21" },
        3: { text: "14" },
        4: { text: "62" },
        5: { text: "14" },
      },
    },
    8: {
      cells: {
        0: { text: "Grapes" },
        1: { text: "57" },
        2: { text: "33" },
        3: { text: "11" },
        4: { text: "24" },
        5: { text: "9" },
      },
    },
  },
};

export const PartWholeTimeColumnSampleData: SheetData = {
  rows: {
    0: {
      cells: {
        0: { text: "Month" },
        1: { text: "Audio" },
        2: { text: "Baby Care" },
        3: { text: "Comp" },
        4: { text: "Hair Care" },
        5: { text: "Mobile" },
        6: { text: "Oral Care" },
        7: { text: "Skin Care" },
        8: { text: "Video" },
        9: { text: "Wearable" },
      },
    },
    1: {
      cells: {
        0: { text: "Jan" },
        1: { text: "83329" },
        2: { text: "338313" },
        3: { text: "106422" },
        4: { text: "76442" },
        5: { text: "233961" },
        6: { text: "4372" },
        7: { text: "8635" },
        8: { text: "41307" },
        9: { text: "347659" },
      },
    },
    2: {
      cells: {
        0: { text: "Feb" },
        1: { text: "113952" },
        2: { text: "312216" },
        3: { text: "26272" },
        4: { text: "127250" },
        5: { text: "199055" },
        6: { text: "2755" },
        7: { text: "7622" },
        8: { text: "66154" },
        9: { text: "311798" },
      },
    },
    3: {
      cells: {
        0: { text: "Mar" },
        1: { text: "215457" },
        2: { text: "295621" },
        3: { text: "181653" },
        4: { text: "187766" },
        5: { text: "239958" },
        6: { text: "20280" },
        7: { text: "4935" },
        8: { text: "49844" },
        9: { text: "309386" },
      },
    },
    4: {
      cells: {
        0: { text: "Apr" },
        1: { text: "178617" },
        2: { text: "481192" },
        3: { text: "65716" },
        4: { text: "120910" },
        5: { text: "299027" },
        6: { text: "1482" },
        7: { text: "9518" },
        8: { text: "67001" },
        9: { text: "363589" },
      },
    },
    5: {
      cells: {
        0: { text: "May" },
        1: { text: "158154" },
        2: { text: "288304" },
        3: { text: "46957" },
        4: { text: "104938" },
        5: { text: "207221" },
        6: { text: "13730" },
        7: { text: "1728" },
        8: { text: "58116" },
        9: { text: "310995" },
      },
    },
    6: {
      cells: {
        0: { text: "Jun" },
        1: { text: "127178" },
        2: { text: "273629" },
        3: { text: "55603" },
        4: { text: "115213" },
        5: { text: "264442" },
        6: { text: "10947" },
        7: { text: "9055" },
        8: { text: "59447" },
        9: { text: "371236" },
      },
    },
    7: {
      cells: {
        0: { text: "Jul" },
        1: { text: "133010" },
        2: { text: "236845" },
        3: { text: "10132" },
        4: { text: "103142" },
        5: { text: "206553" },
        6: { text: "2451" },
        7: { text: "2339" },
        8: { text: "66707" },
        9: { text: "328444" },
      },
    },
    8: {
      cells: {
        0: { text: "Aug" },
        1: { text: "82613" },
        2: { text: "329146" },
        3: { text: "10555" },
        4: { text: "95924" },
        5: { text: "218048" },
        6: { text: "4245" },
        7: { text: "26771" },
        8: { text: "79005" },
        9: { text: "410819" },
      },
    },
    9: {
      cells: {
        0: { text: "Sep" },
        1: { text: "68512" },
        2: { text: "363022" },
        3: { text: "22802" },
        4: { text: "115577" },
        5: { text: "220051" },
        6: { text: "6979" },
        7: { text: "3764" },
        8: { text: "58415" },
        9: { text: "339497" },
      },
    },
    10: {
      cells: {
        0: { text: "Oct" },
        1: { text: "168488" },
        2: { text: "524682" },
        3: { text: "155838" },
        4: { text: "163962" },
        5: { text: "375476" },
        6: { text: "28958" },
        7: { text: "8702" },
        8: { text: "64372" },
        9: { text: "359602" },
      },
    },
    11: {
      cells: {
        0: { text: "Nov" },
        1: { text: "96446" },
        2: { text: "579961" },
        3: { text: "9353" },
        4: { text: "182363" },
        5: { text: "366211" },
        6: { text: "46304" },
        7: { text: "4284" },
        8: { text: "84912" },
        9: { text: "408238" },
      },
    },
    12: {
      cells: {
        0: { text: "Dec" },
        1: { text: "174985" },
        2: { text: "1098585" },
        3: { text: "35884" },
        4: { text: "165325" },
        5: { text: "247100" },
        6: { text: "15803" },
        7: { text: "17758" },
        8: { text: "70191" },
        9: { text: "439920" },
      },
    },
  },
};

export const PartWholeTimeAreaSampleData: SheetData = {
  rows: {
    0: {
      cells: {
        0: { text: "Month" },
        1: { text: "Audio" },
        2: { text: "Baby Care" },
        3: { text: "Comp" },
        4: { text: "Hair Care" },
        5: { text: "Mobile" },
        6: { text: "Oral Care" },
        7: { text: "Skin Care" },
        8: { text: "Video" },
        9: { text: "Wearable" },
      },
    },
    1: {
      cells: {
        0: { text: "Jan" },
        1: { text: "83329" },
        2: { text: "338313" },
        3: { text: "106422" },
        4: { text: "76442" },
        5: { text: "233961" },
        6: { text: "4372" },
        7: { text: "8635" },
        8: { text: "41307" },
        9: { text: "347659" },
      },
    },
    2: {
      cells: {
        0: { text: "Feb" },
        1: { text: "113952" },
        2: { text: "312216" },
        3: { text: "26272" },
        4: { text: "127250" },
        5: { text: "199055" },
        6: { text: "2755" },
        7: { text: "7622" },
        8: { text: "66154" },
        9: { text: "311798" },
      },
    },
    3: {
      cells: {
        0: { text: "Mar" },
        1: { text: "215457" },
        2: { text: "295621" },
        3: { text: "181653" },
        4: { text: "187766" },
        5: { text: "239958" },
        6: { text: "20280" },
        7: { text: "4935" },
        8: { text: "49844" },
        9: { text: "309386" },
      },
    },
    4: {
      cells: {
        0: { text: "Apr" },
        1: { text: "178617" },
        2: { text: "481192" },
        3: { text: "65716" },
        4: { text: "120910" },
        5: { text: "299027" },
        6: { text: "1482" },
        7: { text: "9518" },
        8: { text: "67001" },
        9: { text: "363589" },
      },
    },
    5: {
      cells: {
        0: { text: "May" },
        1: { text: "158154" },
        2: { text: "288304" },
        3: { text: "46957" },
        4: { text: "104938" },
        5: { text: "207221" },
        6: { text: "13730" },
        7: { text: "1728" },
        8: { text: "58116" },
        9: { text: "310995" },
      },
    },
    6: {
      cells: {
        0: { text: "Jun" },
        1: { text: "127178" },
        2: { text: "273629" },
        3: { text: "55603" },
        4: { text: "115213" },
        5: { text: "264442" },
        6: { text: "10947" },
        7: { text: "9055" },
        8: { text: "59447" },
        9: { text: "371236" },
      },
    },
    7: {
      cells: {
        0: { text: "Jul" },
        1: { text: "133010" },
        2: { text: "236845" },
        3: { text: "10132" },
        4: { text: "103142" },
        5: { text: "206553" },
        6: { text: "2451" },
        7: { text: "2339" },
        8: { text: "66707" },
        9: { text: "328444" },
      },
    },
    8: {
      cells: {
        0: { text: "Aug" },
        1: { text: "82613" },
        2: { text: "329146" },
        3: { text: "10555" },
        4: { text: "95924" },
        5: { text: "218048" },
        6: { text: "4245" },
        7: { text: "26771" },
        8: { text: "79005" },
        9: { text: "410819" },
      },
    },
    9: {
      cells: {
        0: { text: "Sep" },
        1: { text: "68512" },
        2: { text: "363022" },
        3: { text: "22802" },
        4: { text: "115577" },
        5: { text: "220051" },
        6: { text: "6979" },
        7: { text: "3764" },
        8: { text: "58415" },
        9: { text: "339497" },
      },
    },
    10: {
      cells: {
        0: { text: "Oct" },
        1: { text: "168488" },
        2: { text: "524682" },
        3: { text: "155838" },
        4: { text: "163962" },
        5: { text: "375476" },
        6: { text: "28958" },
        7: { text: "8702" },
        8: { text: "64372" },
        9: { text: "359602" },
      },
    },
    11: {
      cells: {
        0: { text: "Nov" },
        1: { text: "96446" },
        2: { text: "579961" },
        3: { text: "9353" },
        4: { text: "182363" },
        5: { text: "366211" },
        6: { text: "46304" },
        7: { text: "4284" },
        8: { text: "84912" },
        9: { text: "408238" },
      },
    },
    12: {
      cells: {
        0: { text: "Dec" },
        1: { text: "174985" },
        2: { text: "1098585" },
        3: { text: "35884" },
        4: { text: "165325" },
        5: { text: "247100" },
        6: { text: "15803" },
        7: { text: "17758" },
        8: { text: "70191" },
        9: { text: "439920" },
      },
    },
  },
};
