import { Categories } from "../category.type";
import { ChartType } from "../../../../../../../packages/visuals/charts/enums";
import { ROWS_LAYOUT } from "../../../../../../../packages/visuals/charts/consts";
import PartWholeStructureBarImage from "./images/part-whole-structure-bar.svg";
import PartWholeTimeColumnImage from "./images/part-whole-time-column.svg";
import PartWholeTimeAreaImage from "./images/part-whole-time-area.svg";

import {
  PartWholeStructureBarSampleData,
  PartWholeTimeAreaSampleData,
  PartWholeTimeColumnSampleData,
} from "./partWhole.mock";

export const partWholeCategories: Categories = [
  {
    displayName: "Part-Whole",
    charts: [
      {
        id: "part-whole-structure-bar",
        title: "Stacked bar chart",
        image: PartWholeStructureBarImage,
        sampleData: PartWholeStructureBarSampleData,
        isMultiples: true,
        disabledComments: true,
        tooltip:
          "Assess the relation of a part to the whole value. Use a vertically oriented stacked chart for visualizing the value of discrete items. The analysis exposes the significance of each part relative to the total value.",
        settings: {
          chartType: ChartType.Bar,
          showVerticalCharts: true,
          multiplesLayoutType: ROWS_LAYOUT,
          proFeaturesUnlocked: true,
          showMultiplesGrid: true,
          stackedChart: true,
        },
      },
      {
        id: "part-whole-time-column",
        title: "Stacked column chart",
        image: PartWholeTimeColumnImage,
        sampleData: PartWholeTimeColumnSampleData,
        isMultiples: true,
        disabledComments: true,
        tooltip:
          "Assess the relation of a part to the whole value, and simultaneously observe how data categories and their totals change. The analysis exposes the significance of each part relative to the total value.",
        settings: {
          chartType: ChartType.Bar,
          showVerticalCharts: false,
          multiplesLayoutType: ROWS_LAYOUT,
          proFeaturesUnlocked: true,
          showMultiplesGrid: true,
          stackedChart: true,
        },
      },
      {
        id: "part-whole-time-area",
        title: "Stacked area chart",
        image: PartWholeTimeAreaImage,
        sampleData: PartWholeTimeAreaSampleData,
        isMultiples: true,
        disabledComments: true,
        tooltip:
          "Assess the relation of a part to the whole value, analyze how each of the data categories and their totals develops over time. The analysis exposes the significance of each part relative to the total value.",
        settings: {
          chartType: ChartType.Area,
          showVerticalCharts: false,
          multiplesLayoutType: ROWS_LAYOUT,
          proFeaturesUnlocked: true,
          showMultiplesGrid: true,
          stackedChart: true,
        },
      },
    ],
  },
];
