
import * as d3 from "../../d3";
import { Position } from "@zebrabi/legacy-library-common/interfaces";
import {
    DIV, NONE, PX, BLOCK, HEADER_TOOLTIP
} from "../constants";

export function showTooltip(element: HTMLElement, position: Position, text: string, startTime: number, endTime: number) {
    d3.selectAll("." + HEADER_TOOLTIP).remove();
    const tooltipDiv = document.createElement(DIV);
    tooltipDiv.className = HEADER_TOOLTIP;
    tooltipDiv.style.left = Math.max(0, position.x) + PX;
    tooltipDiv.style.top = position.y + PX;
    const popupContent = text;
    tooltipDiv.textContent = popupContent;
    element.appendChild(tooltipDiv);
    tooltipDiv.style.display = NONE;
    window.setTimeout(() => {
        tooltipDiv.style.display = BLOCK;
    }, startTime);
    if (endTime > 2000) {
        window.setTimeout(() => {
            tooltipDiv.style.opacity = "0";
        }, endTime - 500);
    }

    window.setTimeout(() => {
        tooltipDiv.style.display = NONE;
        if (element.contains(tooltipDiv)) {
            element.removeChild(tooltipDiv);
        }
    }, endTime);
}
