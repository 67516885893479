
export async function applyExcelSLicers(selectedCategory: string, categoryField: string, isMultiSelect: boolean): Promise<string[]> {
    if (Office.context.host !== Office.HostType.Excel) {
        return [];
    }

    return await Excel.run(async (context) => {
        let selectedCategories = [];
        const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
        const slicers = activeWorksheet.slicers;
        const slicersCount = slicers.getCount()
        await context.sync();

        if (slicersCount.value === 0) {
            return [];
        }

        const slicerToUse = categoryField ? slicers.getItemOrNullObject(categoryField) : slicers.getItemAt(0)
        await context.sync();

        if (slicerToUse.isNullObject) {
            console.log("no slicer found");
            return [];
        }

        const selectedSlicerItems = slicerToUse.getSelectedItems();
        await context.sync();

        const selectedItems = selectedSlicerItems.value;
        if (!selectedCategory || selectedItems.length === 1 && selectedItems.includes(selectedCategory)) {
            slicerToUse.clearFilters();
        }
        else if (isMultiSelect) {
            if (selectedItems.includes(selectedCategory)) {
                selectedCategories = selectedItems.filter((item) => item !== selectedCategory);
                slicerToUse.selectItems(selectedCategories);
            } else {
                selectedCategories = selectedItems.concat([selectedCategory]);
                slicerToUse.selectItems(selectedCategories);
            }
        }
        else {
            selectedCategories = [selectedCategory];
            slicerToUse.selectItems(selectedCategories);
        }

        await context.sync();
        return selectedCategories;
    }).catch((error) => {
        console.log("Excel.Run error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
        return [];
    });
}