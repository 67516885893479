import { getOfficeSettings } from "@zebrabi/office-settings";
import { ActionType } from "./ActionType";
import Overlay from "./components/Overlay";
import { signInService } from "./components/signin/MSFTSignIn";
import Watermark from "./components/Watermark";
import { PlanType, UNLOCK_ALL_FEATURES_URL, START_TRIAL_URL } from "./constants";
import { openURL } from "./helpers";
import { MemberLicenseStatusResponse } from "./interfaces/MemberLicenseStatusResponse";
import { OverlayConfig } from "./interfaces/OverlayConfig";
import { LicenseType, ProductType } from "./licensing.types";
import { CurrentUserInfo, OwnerUserInfo } from "./components/UserInfo";
import ActionObserver from "./observers/ActionObserver";

export class Licensing {
  private static _instance;
  private currentUserInfo: CurrentUserInfo;
  private ownerUserInfo: OwnerUserInfo;
  private overlay = new Overlay();
  private watermark: Watermark = new Watermark();

  private constructor() {
    const actionObserver = new ActionObserver();
    actionObserver.update = async (actionType: string) => {
      const product = Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint;

      if (actionType === ActionType.SignIn || actionType === ActionType.RetryFetchLicensing) {
        await signInService.signIn();
        return;
      }

      if (actionType === ActionType.Trial) {
        await this.startTrial();
        return;
      }

      if (actionType === ActionType.Admin) {
        await this.registerFreeUser(product, signInService.getAccessToken());
        return;
      }

      if (actionType === ActionType.Buy) {
        const buyUrl = UNLOCK_ALL_FEATURES_URL
          // eslint-disable-next-line no-undef
          .replace("$host", product)
          .replace("$visual", process.env.ZBI_VISUAL);
        openURL(buyUrl);
        return;
      }
    };

    this.watermark.attach(actionObserver);
    this.overlay.attach(actionObserver);
  }

  public getPlanString(): PlanType {
    if (this.currentUserInfo?.getLicense()?.licenseType == LicenseType.Paid) {
      return PlanType.Pro;
    } else if (this.currentUserInfo?.getLicense()?.licenseType == LicenseType.Trial) {
      return PlanType.Trial;
    } else if (this.currentUserInfo?.isKnown()) {
      return PlanType.Free;
    } else if (this.freeViewerMode()) {
      return PlanType.Template;
    } else {
      return PlanType.Null;
    }
  }

  public freeViewerMode() {
    return (
      this.ownerUserInfo &&
      !this.ownerUserInfo?.getLicense()?.hasLicense &&
      !this.currentUserInfo?.getLicense()?.hasLicense
    );
  }

  public isPaidUser() {
    if (this.getCurrentUser()?.getLicense()?.licenseType == LicenseType.Paid) {
      return true;
    }
    return false;
  }

  public renderFreeLicenseWatermark() {
    const isTrialUser = this.currentUserInfo?.isTrial();
    let url = (isTrialUser ? UNLOCK_ALL_FEATURES_URL : START_TRIAL_URL).replace(
      "$host",
      // eslint-disable-next-line no-undef
      Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint
    ).replace("$visual", process.env.ZBI_VISUAL);
    this.watermark.watermarkConfig = {
      text: "",
      linktext: isTrialUser ? "Upgrade" : "Start Free Trial",
      link: url,
      signin: signInService.isSignedIn() ? null : "Sign in",
      textWithLink: [
        {
          text: "Created with ",
          url: null,
        },
        {
          text: "Zebra BI for Office",
          url: Office.context.host === Office.HostType.Excel ? "https://zebrabi.com/excel-add-in/" : "https://zebrabi.com/powerpoint-add-in/",
        },
        {
          text: " Free",
          url: null,
        },
      ]
    };
  }

  public renderLicensingUnavailableWatermark() {
    this.watermark.watermarkConfig = {
      text: "Licensing service not available",
      linktext: "",
      link: "",
      signin: "",
      retry: "Retry"
    };
  }

  public async startTrial() {
    const ownerUserInfo: OwnerUserInfo = this.currentUserInfo?.convertToOwnerUserInfo();
    if (ownerUserInfo) {
      ownerUserInfo.lastDisplayedOverlay = licensing.getOwnerUser()?.lastDisplayedOverlay ?? new Date();
    }

    if (!licensing.ownerUserInfo?.getLicense()?.hasLicense) {
      await OwnerUserInfo.setOwnerInfo(ownerUserInfo);
    }

    const product = Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint;
    await this.registerFreeUser(product, signInService.getAccessToken());
  }

  private async registerFreeUser(product: string, accessToken: string) {
    const registerFreeUserUrl = process.env.LICENSING_SERVER_URL + `/add-in/licenses/${product}/register-free`;

    try {
      const response = await fetch(registerFreeUserUrl, {
        method: "POST",
        body: JSON.stringify({
          product: product,
        }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const licenseResponse: MemberLicenseStatusResponse = await response.json();
      if (process.env.DEBUG_LOG === "true") {
        console.debug("response", licenseResponse);
      }
    } catch (error) {
      console.error("Error REGISTER FREE USER", error);
    }
  }

  public getLocalLicense() /*: SavedLicenseInfo*/ {
    return getOfficeSettings("ZBILicenseSettings");
  }

  public proFeaturesUnlocked() {
    return this.currentUserInfo?.getLicense()?.hasLicense || this.ownerUserInfo?.getLicense()?.hasLicense;
  }

  public getCurrentUser() {
    return CurrentUserInfo.getInstance();
  }

  public getOwnerUser() {
    return OwnerUserInfo.getInstance();
  }

  public async fetchUsers() {
    this.ownerUserInfo = await OwnerUserInfo.createInstance();
    this.currentUserInfo = await CurrentUserInfo.createInstance();
  }

  public static getInstance() {
    return Licensing._instance;
  }
  // eslint-disable-next-line prettier/prettier

  public static createInstance(): Licensing {
    if (!Licensing._instance) {
      Licensing._instance = new Licensing();
    }

    return Licensing._instance;
  }

  public renderNoLicenseOverlay = () => {
    this.overlay.overlayConfig = <OverlayConfig>{
      copy: "Hi " + (this.currentUserInfo?.name ?? "") + ", thanks for signing in.",
      confirm: null, //"Contact admin",
      buy: "Unlock all features",
      footer: null,
      signIn: null,
      trial: "Continue with a free license",
    };

    document.body.append(this.overlay);
  };

  clearWatermark() {
    this.watermark?.close();
  }
}

export const licensing = Licensing.createInstance();
