import SharepointBrowserAPI from "@zebrabi/sharepoint-api-browser/SharepointBrowserAPI";
import React, { useEffect } from "react";
import { SharePointDrive, SharePointSite } from "../../ExcelDataLinking.types";
import SitesListing from "./SitesListing";
import Breadcrumbs from "./Breadcrumbs";
import CloseExcelDataLinking from "../components/CloseExcelDataLinking";
import FoldersAndFilesListing from "./FoldersAndFilesListing";
import DrivesListing from "./DrivesListing";
import OnedriveListing from "./OnedriveListing";
import useStore from "../../useStore";

const FileSelection = React.memo(({ accessToken }) => {
  const [activeSiteId] = useStore("activeSiteId");
  const [activeDriveId, setActiveDriveId] = useStore("activeDriveId");
  const [sites, setSites] = useStore("sites");
  const [onedrives, setOnedrives] = useStore("onedrives");
  const [, setDrives] = useStore("drives");
  const [, setFoldersAndFiles] = useStore("foldersAndFiles");
  const [, setFoldersNavigation] = useStore("foldersNavigation");
  const [, setActiveSiteId] = useStore("activeSiteId");

  const browser = SharepointBrowserAPI.createInstance(accessToken);

  const fetchSitesData = async () => {
    const response = await browser.getSites<SharePointSite[]>();
    setSites(response.value);
  };

  const fetchOnedriveData = async () => {
    const currentUserOnedrives = await browser.getCurrentUserOnedrives<SharePointDrive[]>();
    setOnedrives(currentUserOnedrives.value);
  };

  useEffect(() => {
    fetchSitesData();
    fetchOnedriveData();
  }, [accessToken]);

  const handleSelectSite = (site: SharePointSite) => {
    if (site) {
      setActiveSiteId(site.id);
    }
    setActiveDriveId("");
    setDrives([]);
    setFoldersAndFiles([]);
    setFoldersNavigation([]);

    browser.getDrives<SharePointDrive[]>(site.id).then((response) => {
      browser.getDefaultDrive<SharePointDrive>(site.id).then(({ value: siteDrive }) => {
        setDrives(response.value);
        if (siteDrive) {
          setActiveDriveId(siteDrive.id);
        }
      });
    });
  };

  const handleSelectOnedrive = (drive: SharePointDrive) => {
    setActiveSiteId("");
    if (drive) {
      setActiveDriveId(drive.id);
    }
    setDrives([]);
    setFoldersAndFiles([]);
    setFoldersNavigation([]);
  };

  return (
    <div className="flex gap-4 h-full">
      <div className="file-source-browser">
        <OnedriveListing onedrives={onedrives} onDriveClick={handleSelectOnedrive} />
        <SitesListing sites={sites} onSiteClick={handleSelectSite} />
      </div>
      <div className="main-content">
        <div className={`flex ${activeSiteId || activeDriveId ? "justify-between" : "justify-end"}`}>
          <Breadcrumbs />

          <CloseExcelDataLinking />
        </div>

        {activeSiteId && !activeDriveId ? <DrivesListing /> : <FoldersAndFilesListing />}
      </div>
    </div>
  );
});

export default FileSelection;
