import {
    ConnectingLineProperties, ConnectingLinePropertiesActions, ConnectingLinePropertiesSubscriber, DropdownSubscriber,
    HighlightArrowProperties, HighlightArrowPropertiesActions, HighlightArrowPropertiesSubscriber,
    HighlightEllipseProperties, HighlightEllipsePropertiesActions, HighlightEllipsePropertiesSubscriber,
    InlineDropdown, InlineNumberInput, InlineToggleButton, LinkButton, NumberInputSubscriber, ToggleButtonSubscriber
} from "@zebrabi/design-library";
import ContextMenu from "./base/ContextMenu";
import { BLOCK, CLICK, CUSTOMER_STYLE_VISIBLE, NONE } from "../library/constants";
import { Visual } from "../visual";
import { ChartSettings } from "../settings/chartSettings";
import { ScenarioOptions, DifferenceHighlightSettings } from "../interfaces";
import { ChartType, DifferenceHighlightSettings as DifferenceHighlightSettingsEnum } from "../enums";
import {
    DIFFERENCE_HIGHLIGHT_ARROW_STYLE_OPTIONS, DIFFERENCE_HIGHLIGHT_CONNECTING_LINE_STYLE_OPTIONS,
    DIFFERENCE_HIGHLIGHT_ELLIPSE_OPTIONS, DIFFERENCE_LABEL_TYPE_OPTIONS
} from "../library/dropdownOptionsConstants";
import { getDifferenceHighlightOptions } from "../helpers";

export default class DifferenceHighlightMenu extends ContextMenu {

    protected template: string = `<div class="context-menu2 difference-highlight-menu">
                                <div class="difference-highlight-title">Difference highlight</div>
                                <ul class="menu-items"></ul>
                           </div>`;

    constructor() {
        super();
    }

    protected addMenuItems() {
        this.addFromToDHDropdown();
        if (this.chartSettings.chartType === ChartType.Waterfall && Visual.viewModel.isSingleSeriesViewModel) {
            this.addDHSubtotalsToggle();
        }
        // @TODO Add all this adding of the components into factory
        this.addEllipseDHDropdown();
        this.addHighlightEllipseProperties();
        this.addArrowStyleProperties();
        this.addConnectingLineProperties();
        this.addLabelTypeDHDropdown();
        this.addMarginInput();
        this.addResetToDefault();
        this.toggleEllipsesElement(this.chartSettings.differenceHighlightEllipse);
    }

    private addFromToDHDropdown() {
        const fromDHDropdown = new InlineDropdown(
            "From/To",
            this.chartSettings.differenceHighlightFromTo,
            { name: "from-dh" },
            getDifferenceHighlightOptions(Visual.viewModel.isSingleSeriesViewModel, this.chartSettings.scenarioOptions.secondValueScenario));
        const menuItemDiv = this.createMenuItem();
        fromDHDropdown.appendTo(menuItemDiv);

        const fromDHDropdownSub = new DropdownSubscriber();
        fromDHDropdownSub.update = (value: any): void => {
            this.chartSettings.persistDifferenceHighlightSettings({ differenceHighlightFromTo: value });
        };
        fromDHDropdown.subscribe(fromDHDropdownSub);
    }

    private addEllipseDHDropdown() {
        const ellipseDHDropdown = new InlineDropdown(
            "Highlight",
            this.chartSettings.differenceHighlightEllipse,
            { name: "ellipse-dh" },
            DIFFERENCE_HIGHLIGHT_ELLIPSE_OPTIONS);
        const menuItemDiv = this.createMenuItem();
        ellipseDHDropdown.appendTo(menuItemDiv);

        const ellipseDHDropdownSub = new DropdownSubscriber();
        ellipseDHDropdownSub.update = (value: any): void => {
            this.toggleEllipsesElement(value);
            this.chartSettings.persistDifferenceHighlightSettings({ differenceHighlightEllipse: value });
        };
        ellipseDHDropdown.subscribe(ellipseDHDropdownSub);
    }

    private addConnectingLineProperties() {
        const connectingLineProperties = new ConnectingLineProperties(
            {
                lineWidth: this.chartSettings.differenceHighlightLineWidth,
                lineStyle: this.chartSettings.differenceHighlightConnectingLineStyle,
                lineColor: this.chartSettings.differenceHighlightConnectingLineColor
            },
            {
                label:"Connecting line",
                lineStyleOptions: DIFFERENCE_HIGHLIGHT_CONNECTING_LINE_STYLE_OPTIONS,
                lineWidthMin: 0,
                lineWidthMax: 10
            }
        );
        const menuItemDiv = this.createMenuItem();
        connectingLineProperties.appendTo(menuItemDiv);

        const connectingLineSub = new ConnectingLinePropertiesSubscriber();
        connectingLineSub.update = (value: number, action: ConnectingLinePropertiesActions) => {
            const properties = {} as DifferenceHighlightSettings;
            if (action === ConnectingLinePropertiesActions.LineStyleChange) {
                properties.differenceHighlightConnectingLineStyle = value;
            } else if (action === ConnectingLinePropertiesActions.LineColorChange) {
                properties.differenceHighlightConnectingLineColor = value.toString();
            }
            this.chartSettings.persistDifferenceHighlightSettings(properties);
        };

        connectingLineProperties.subscribe(connectingLineSub);
    }

    private addArrowStyleProperties() {
        const highlightArrowProperties = new HighlightArrowProperties(
            {
                lineWidth: this.chartSettings.differenceHighlightLineWidth,
                lineStyle: this.chartSettings.differenceHighlightArrowStyle,
                lineColor: this.chartSettings.differenceHighlightConnectingLineColor
            },
            {
                label: "Arrow",
                arrowStyleOptions: DIFFERENCE_HIGHLIGHT_ARROW_STYLE_OPTIONS,
                lineWidthMin: 0,
                lineWidthMax: 10
            }
        );
        const menuItemDiv = this.createMenuItem();
        highlightArrowProperties.appendTo(menuItemDiv);

        const highlightArrowSub = new HighlightArrowPropertiesSubscriber();
        highlightArrowSub.update = (value: number, action: HighlightArrowPropertiesActions) => {
            const properties = {} as DifferenceHighlightSettings;
            if (action === HighlightArrowPropertiesActions.LineArrowStyleChange) {
                properties.differenceHighlightArrowStyle = value;
            } else if (action === HighlightArrowPropertiesActions.LineArrowWidthChange) {
                properties.differenceHighlightLineWidth = value;
            }
            this.chartSettings.persistDifferenceHighlightSettings(properties);
        };

        highlightArrowProperties.subscribe(highlightArrowSub);
    }

    private addHighlightEllipseProperties() {
        const highlightEllipseProperties = new HighlightEllipseProperties({
            width: this.chartSettings.differenceHighlightEllipseBorderWidth,
            padding: this.chartSettings.differenceHighlightEllipseBorderPadding,
            opacity: this.chartSettings.differenceHighlightEllipseFillOpacity,
        });
        const menuItemDiv = this.createMenuItem();
        highlightEllipseProperties.appendTo(menuItemDiv);
        menuItemDiv.classList.add("dh-ellipse-settings");

        const highlightEllipsePropertiesSub = new HighlightEllipsePropertiesSubscriber();
        highlightEllipsePropertiesSub.update = (value: number, action: HighlightEllipsePropertiesActions): void => {
            const properties = {} as DifferenceHighlightSettings;
            if (action === HighlightEllipsePropertiesActions.OpacityChange) {
                properties.differenceHighlightEllipseFillOpacity = value;
            } else if (action === HighlightEllipsePropertiesActions.PaddingChange) {
                properties.differenceHighlightEllipseBorderPadding = value;
            } else if (action === HighlightEllipsePropertiesActions.WidthChange) {
                properties.differenceHighlightEllipseBorderWidth = value;
            }
            this.chartSettings.persistDifferenceHighlightSettings(properties);
        };
        highlightEllipseProperties.subscribe(highlightEllipsePropertiesSub);
    }

    private addMarginInput() {
        const marginDHInput = new InlineNumberInput("Margin", this.chartSettings.differenceHighlightMargin, {
            name: "dh-margin-settings",
            min: 1,
            max: 50
        });
        const menuItemDiv = this.createMenuItem();
        marginDHInput.appendTo(menuItemDiv);

        const marginDHInputSub = new NumberInputSubscriber();
        marginDHInputSub.update = (message: any): void => {
            this.chartSettings.persistDifferenceHighlightSettings({ differenceHighlightMargin: message });
        };
        marginDHInput.subscribe(marginDHInputSub);
    }

    private addLabelTypeDHDropdown() {
        const labelTypeDHDropdown = new InlineDropdown(
            "Difference label",
            this.chartSettings.differenceLabel,
            { name: "ellipse-dh" },
            DIFFERENCE_LABEL_TYPE_OPTIONS);
        const menuItemDiv = this.createMenuItem();
        labelTypeDHDropdown.appendTo(menuItemDiv);

        const labelTypeDHDropdownSub = new DropdownSubscriber();
        labelTypeDHDropdownSub.update = (value: any): void => {
            this.chartSettings.persistDifferenceHighlightSettings({ differenceLabel: value });
        };
        labelTypeDHDropdown.subscribe(labelTypeDHDropdownSub);
    }

    private addDHSubtotalsToggle() {
        const subtotalsDHToggle = new InlineToggleButton(this.chartSettings.showDifferenceHighlightSubtotals, {
            name: "dh-for-subtotals",
            label: "Show for all subtotals"
        });
        const menuItemDiv = this.createMenuItem();
        subtotalsDHToggle.appendTo(menuItemDiv);

        const subtotalsDHToggleSub = new ToggleButtonSubscriber();
        subtotalsDHToggleSub.update = (message: any): void => {
            this.chartSettings.persistDifferenceHighlightSettings({ showDifferenceHighlightSubtotals: message.value });
        };
        subtotalsDHToggle.subscribe(subtotalsDHToggleSub);
    }

    private toggleEllipsesElement(value: number) {
        const ellipsesSettings = <HTMLElement>document.querySelector(".dh-ellipse-settings");
        if (ellipsesSettings) {
            ellipsesSettings.style.display = value !== 0 ? BLOCK : NONE;
        }
    }

    protected addResetToDefault(): void {
        const resetToDefault = new LinkButton("Reset to default");
        const menuItemDiv = this.createMenuItem();
        resetToDefault.appendTo(menuItemDiv);
        resetToDefault.containerNode().addEventListener(CLICK, () => {
            this.resetToDefault();
        });
    }

    protected resetToDefault(): void {
        // @TODO Try to unify all the reset to default functions
        document.querySelector(`.difference-highlight-menu`).classList.add("reset");
        const val = {};
        // IMPORTANT: when null is persisted on string type, property is not deleted from object but is instead empty string
        const settings = new ChartSettings(CUSTOMER_STYLE_VISIBLE.toString() === "true", "en", <ScenarioOptions>{});
        Object.keys(DifferenceHighlightSettingsEnum).forEach(key => {
            val[key] = settings[key];
        });
        this.chartSettings.persistDifferenceHighlightSettings(val);
    }
}
