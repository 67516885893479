import { v4 as UUIDv4 } from "uuid";
import * as d3 from "../d3";
import { ChartData } from "../interfaces";
import {
    ACTIVE,
    BORDER_RADIUS,
    CLICK,
    CONTEXT_MENU2_DIV, FILL,
    FIXED, GLOBAL_CATEGORY, HEIGHT, ID,
    MOUSEENTER,
    MOUSELEAVE, OUTLINE_BORDER_RADIUS,
    OUTLINE_COLOR, OVERLAY, RECT, RESET,
    RX, STROKE, TRANSPARENT,
    WIDTH,
    X,
    Y
} from "../library/constants";
import GlobalCategoryMenu from "../components/GlobalCategoryMenu";
import { repositionElementInsideViewPort } from "../helpers";

export function addHorizontalGlobalCategoryButton(container: d3.Selection<SVGElement, any, any, any>, chartData: ChartData, plottingFrozenCategories: boolean, xScale: d3.ScaleBand<string>, chartIndex = 0, chartBottom: number, categoryLabelsGroup: string) {
    let maxLabelHeight = 0;
    let horizontalLabelsTop = null;
    const categoryLabels = document.querySelectorAll(`.category.highlightable.chartIndex${chartIndex}.${categoryLabelsGroup}`);
    categoryLabels.forEach((label: Element) => {
        const labelBoundingRect = label.getBoundingClientRect();
        // find max label height
        const labelHeight = labelBoundingRect.height;
        if (maxLabelHeight < labelHeight) {
            maxLabelHeight = labelHeight;
        }

        // get labels top position
        if (labelHeight && (horizontalLabelsTop === null || horizontalLabelsTop > labelBoundingRect.top)) {
            horizontalLabelsTop = labelBoundingRect.top;
        }
    });

    const y = plottingFrozenCategories ? 1 : horizontalLabelsTop + document.querySelector(".zebrabi-chart-svg-container").scrollTop - 1;
    const firstDp = chartData.dataPoints[0];
    const lastDp = chartData.dataPoints[chartData.dataPoints.length - 1];
    const buttonWidth = xScale(lastDp.category) - xScale(firstDp.category) + xScale.bandwidth();

    // lower the outline height if it breaches bottom of the chart
    const additionalOutlineHeight = 2;
    const visibleChartBottomOverflow = 2;
    if (y + maxLabelHeight + additionalOutlineHeight > chartBottom) {
        maxLabelHeight = maxLabelHeight + additionalOutlineHeight - (y + maxLabelHeight + additionalOutlineHeight - chartBottom - visibleChartBottomOverflow);
    } else {
        maxLabelHeight = maxLabelHeight + additionalOutlineHeight;
    }

    addGlobalCategoryButton(container, xScale(firstDp.category), y, buttonWidth, maxLabelHeight, false, plottingFrozenCategories, chartIndex);
}

export function addVerticalGlobalCategoryButton(container: d3.Selection<SVGElement, any, any, any>, chartData: ChartData, yScale: d3.ScaleBand<string>, xPos: number, chartIndex) {
    let maxLabelWidth = 0;
    const categoryLabels = document.querySelectorAll(".category.highlightable");
    categoryLabels.forEach((label: Element) => {
        const labelHeight = label.getBoundingClientRect().width;
        if (maxLabelWidth < labelHeight) {
            maxLabelWidth = labelHeight;
        }
    });

    const firstDp = chartData.dataPoints[0];
    const lastDp = chartData.dataPoints[chartData.dataPoints.length - 1];
    const height = yScale(lastDp.category) - yScale(firstDp.category) + yScale.bandwidth();

    addGlobalCategoryButton(container, xPos, yScale(firstDp.category), maxLabelWidth + 3, height, true, false, chartIndex);
}

function addGlobalCategoryButton(container: d3.Selection<SVGElement, any, any, any>, x: number, y: number, width: number, height: number, isVertical: boolean, plottingFrozenCategories: boolean, chartIndex: number) {
    const globalOutlineId = `o-${UUIDv4()}`;
    const globalMenu = document.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_CATEGORY}.chart-index${chartIndex}`);
    const rect = container.insert(RECT, ".category.highlightable");
    rect.attr(X, x)
        .attr(Y, y)
        .style(WIDTH, `${width}px`)
        .style(HEIGHT, `${height}px`)
        .style(BORDER_RADIUS, OUTLINE_BORDER_RADIUS)
        .attr(STROKE, globalMenu ? OUTLINE_COLOR : TRANSPARENT)
        .attr(FILL, TRANSPARENT)
        .attr(RX, 2)
        .attr(ID, globalOutlineId)
        .classed("global-category-outline", true)
        .classed(ACTIVE, !!globalMenu);

    rect.on(MOUSEENTER, () => {
        rect.attr(STROKE, OUTLINE_COLOR);
    });

    rect.on(MOUSELEAVE, () => {
        if (!document.querySelector(`#${globalOutlineId}`)?.classList.contains(ACTIVE)) {
            rect.attr(STROKE, TRANSPARENT);
        }
    });

    rect.on(CLICK, () => {
        toggleGlobalCategoryMenu(x, y, width, height, isVertical, globalOutlineId, plottingFrozenCategories, chartIndex);
    });

    // global menu component adds class reset when reset to default is clicked
    // on visual update check if menu has been reset and redraw it
    const menu = document.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_CATEGORY}.${RESET}`);
    if (menu?.classList.contains(`chart-index${chartIndex}`)) {
        const globalCategoryMenu = redrawGlobalCategoryMenu(globalOutlineId, chartIndex);
        calculateGlobalCategoryMenuPosition(globalCategoryMenu, x, y, width, height, isVertical, plottingFrozenCategories);
        repositionElementInsideViewPort(globalCategoryMenu);
    }

    // on visual update check if global menu exists in html to add active color for outline
    if (globalMenu) {
        rect.attr(STROKE, OUTLINE_COLOR);

        // if after 300ms menu does not exist in html anymore means slide transition happened while menu was open which removed global menu
        // we need to adjust outline because it was drawn active when menu was still present during slide transition
        setTimeout(() => {
            if (!document.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_CATEGORY}.chart-index${chartIndex}`)) {
                rect.attr(STROKE, TRANSPARENT);
                rect.classed(ACTIVE, false);
            }
        }, 300);
    }

    closeMenuOnDocumentMouseLeave();
}

function toggleGlobalCategoryMenu(x: number, y: number, width: number, height: number, isVertical: boolean, globalOutlineId: string, plottingFrozenCategories: boolean, chartIndex: number) {
    const main = <HTMLElement>document.querySelector(".zebrabi-charts-container");
    // remove category label overlay
    document.querySelector(`.split-button.${OVERLAY}`)?.remove();
    // remove previous outline
    const outline = d3.select(`rect[class*=outline]:not(#${globalOutlineId})`);
    outline.classed(ACTIVE, false);
    outline.attr(STROKE, TRANSPARENT);

    const globalCategoryMenu = main.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_CATEGORY}.${globalOutlineId}`);
    document.querySelector(`#${globalOutlineId}`)?.classList.toggle(ACTIVE);

    if (!globalCategoryMenu) {
        const globalCategoryMenu = new GlobalCategoryMenu().appendTo(main);
        globalCategoryMenu.classList.add(globalOutlineId, `chart-index${chartIndex}`);
        calculateGlobalCategoryMenuPosition(globalCategoryMenu, x, y, width, height, isVertical, plottingFrozenCategories);
        closeMenuOnOutsideClick(globalOutlineId);
    } else {
        globalCategoryMenu.remove();
    }
}

function redrawGlobalCategoryMenu(globalOutlineId: string, chartIndex: number): HTMLElement {
    document.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_CATEGORY}.${RESET}`).remove();
    const main = <HTMLElement>document.querySelector(".zebrabi-charts-container");
    const globalCategoryMenu = new GlobalCategoryMenu().appendTo(main);
    globalCategoryMenu.classList.add(globalOutlineId, `chart-index${chartIndex}`);
    closeMenuOnOutsideClick(globalOutlineId);
    return globalCategoryMenu;
}

function closeMenuOnDocumentMouseLeave() {
    document.addEventListener(MOUSELEAVE, () => {
        const main = <HTMLElement>document.querySelector(".zebrabi-charts-container");
        main.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_CATEGORY}`)?.remove();
        const rect = d3.select(`.global-category-outline.${ACTIVE}`);
        rect.attr(STROKE, TRANSPARENT);
        rect.classed(ACTIVE, false);
    });
}

function closeMenuOnOutsideClick(globalOutlineId: string) {
    const closeMenuOnOutsideClick = (event: MouseEvent) => {
        const main = <HTMLElement>document.querySelector(".zebrabi-charts-container");
        const globalCategoryMenu = main.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_CATEGORY}.${globalOutlineId}`);
        const globalCategoryOutline = main.querySelector(`#${globalOutlineId}`);

        if (globalCategoryMenu && !event.composedPath().includes(globalCategoryOutline) && !event.composedPath().includes(globalCategoryMenu)) {
            globalCategoryMenu.remove();
            document.removeEventListener(CLICK, closeMenuOnOutsideClick);
            const rect = d3.select(`.global-category-outline.${ACTIVE}`);
            rect.attr(STROKE, TRANSPARENT);
            rect.classed(ACTIVE, false);
        }
    };

    document.addEventListener(CLICK, closeMenuOnOutsideClick);
}

function calculateGlobalCategoryMenuPosition(globalCategoryMenu: HTMLElement, x: number, y: number, width: number, height: number, isVertical: boolean, plottingFrozenCategories: boolean) {
    globalCategoryMenu.style.position = FIXED;
    const containerScrollTop = document.querySelector(".zebrabi-chart-svg-container").scrollTop;

    if (isVertical) {
        globalCategoryMenu.style.left = `${x + width + 10}px`;
        globalCategoryMenu.style.top = `${(y + height / 2) - globalCategoryMenu.getBoundingClientRect().height / 2 - containerScrollTop}px`;
        globalCategoryMenu.style.bottom = "auto";
    } else if (plottingFrozenCategories) {
        globalCategoryMenu.style.left = `${(x + width / 2) - globalCategoryMenu.getBoundingClientRect().width / 2}px`;
        globalCategoryMenu.style.bottom = document.querySelector(`.global-category-outline.${ACTIVE}`).getBoundingClientRect().height + 10 + "px";
        globalCategoryMenu.style.top = "auto";
    } else {
        globalCategoryMenu.style.left = `${(x + width / 2) - globalCategoryMenu.getBoundingClientRect().width / 2}px`;
        globalCategoryMenu.style.top = `${y - 10 - globalCategoryMenu.getBoundingClientRect().height - containerScrollTop}px`;
        globalCategoryMenu.style.bottom = "auto";
    }

    repositionElementInsideViewPort(globalCategoryMenu);
}
