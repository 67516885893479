export enum ActionType {
  SignIn = "SignIn",
  SignInSuccess = "SignInSuccess",
  SignInError = "SignInError",
  SignOut = "SignOut",
  Buy = "Buy",
  Admin = "Admin",
  Trial = "Trial",
  RetryFetchLicensing = "RetryFetchLicensing"
}
