import * as d3 from "d3";
import {
    BLOCK,
    DIV,
    NONE, Scenario, CLICK, CUSTOMER_STYLE_VISIBLE
} from "../../library/constants";
import { ToolbarOptions } from "@zebrabi/global-toolbar/dist/lib/interface/ToolbarOption";
import { Visual } from "../../visual";
import {
    ABSOLUTE, ABSOLUTE_RELATIVE, ACTUAL, ACTUAL_ABSOLUTE,
    ACTUAL_RELATIVE, INTEGRATED, RELATIVE, RESPONSIVE, WATERFALL
} from "../../consts";
import { ChartType, ReferenceDisplayType, SidebarChartSettings } from "../../enums";
import BaseSwitcherWithHeader from "./BaseSwitcherWithHeader";
import { ChartSettings } from "../../settings/chartSettings";
import { InlineToggleButton, DropdownOptionAttributes, InlineDropdown, InlineTextInput, InlineNumberInput, LinkButton } from "@zebrabi/design-library";
import { ScenarioOptions } from "../../interfaces";

class ChartSettingsSwitcher extends BaseSwitcherWithHeader {
    static readonly CLASS_NAME = `ChartSettingsSwitcher`;

    // define the title, icon and type of the global toolbar item that will be added
    toolbarOptions: ToolbarOptions = {
        collapsed: true, // if you want the content to directly render to the toolbar row, set this to false
        elementName: "Settings",
        icon: "settings-icon-base64", // icon should be a base64 icon due to powerbi limitations. Additional files are not allowed to be loaded (just the visual.css and visual.js)
        type: "button",
        actions: [], // this is used to auto generate actions mainly used in cards
    };

    /**
     * Transpiling will lose the type of the class once minimised, method is used the type is preserved
     */
    public getClassName(): string {
        return ChartSettingsSwitcher.CLASS_NAME;
    }

    buttonAction(action: string, message: string): void {
    }

    update(message: Map<string, any>): void {
        if (message.has("viewer")) {
            if (message.get("viewer") == "true") {
                this.switcherContainer.style.display = NONE;
            } else {
                this.switcherContainer.style.display = BLOCK;
            }
        }
        return;
    }


    createMenuItems(switcherMenuContainer: HTMLElement, active = "") {
        super.createMenuItems(switcherMenuContainer, active);
        const chartSettingsForm = d3.select(switcherMenuContainer.querySelector(".body")).append(DIV).classed("chart-settings-form", true);
        this.drawFieldsForm(chartSettingsForm);
    }

    public drawFieldsForm(chartSettingsForm: d3.Selection<HTMLElement, any, any, any>) {
        chartSettingsForm.selectAll("*").remove();
        this.createSettingsForm(chartSettingsForm);
    }

    private appendDesignLibraryComponent(component: any, listItems: HTMLUListElement) {
        const listItem = document.createElement("li");
        listItem.classList.add("item");
        component.appendTo(listItem);
        listItems.append(listItem);
    }

    private appendInvertVarianceToggle(listItems: HTMLUListElement, settings: ChartSettings): void {
        const invertVarianceColorsToggle = new InlineToggleButton(settings.invert, {
            id: "invert-variance-colors-id",
            label: "Invert variance colors",
            name: "invert"
        });
        this.appendDesignLibraryComponent(invertVarianceColorsToggle, listItems);
    }

    private appendLayoutDropdown(listItems: HTMLUListElement, settings: ChartSettings, plotStacked: boolean): void {
        const validLayoutValues: Map<string, DropdownOptionAttributes<any>> = this.getValidChartLayouts();

        if (validLayoutValues.size > 1 && !plotStacked) {
            const layoutDropdown = new InlineDropdown("Layout", settings.chartLayout && Array.from(validLayoutValues.values()).some(p => p.value === settings.chartLayout) ? settings.chartLayout : RESPONSIVE, {
                id: "layout-select",
                name: "chartLayout",
            }, Array.from(validLayoutValues.values()));
            this.appendDesignLibraryComponent(layoutDropdown, listItems);
        }
    }

    private appendGrandTotalToggle(listItems: HTMLUListElement, settings: ChartSettings): void {
        const grandTotalToggle = new InlineToggleButton(settings.showGrandTotal, {
            id: "show-grand-total-id",
            label: "Grand total",
            name: "showGrandTotal"
        });
        this.appendDesignLibraryComponent(grandTotalToggle, listItems);
    }

    private appendGrandTotalLabelInput(listItems: HTMLUListElement, settings: ChartSettings): void {
        const grandTotalLabelInput = new InlineTextInput("Total label", settings.grandTotalLabel, {
            id: "grand-total-label-id",
            name: "grandTotalLabel",
        });
        this.appendDesignLibraryComponent(grandTotalLabelInput, listItems);
    }

    private appendCustomOutliersToggle(listItems: HTMLUListElement, settings: ChartSettings): void {
        const customOutlierLimitsToggle = new InlineToggleButton(settings.limitOutliers, {
            id: "custom-outlier-limits-id",
            label: "Custom outlier limits",
            name: "limitOutliers",
        });
        this.appendDesignLibraryComponent(customOutlierLimitsToggle, listItems);
    }

    private appendCustomOutliersInputs(listItems: HTMLUListElement, settings: ChartSettings): void {
        const maxOutlierInput = new InlineNumberInput("Max outlier limit (%)", settings.maxOutlierValue, {
            id: "max-outlier-value-id",
            name: "maxOutlierValue",
        });
        this.appendDesignLibraryComponent(maxOutlierInput, listItems);

        const minOutlierInput = new InlineNumberInput("Min outlier limit (%)", settings.minOutlierValue, {
            id: "min-outlier-value-id",
            name: "minOutlierValue",
        });
        this.appendDesignLibraryComponent(minOutlierInput, listItems);
    }

    private appendReferenceDisplayTypeDropdown(listItems: HTMLUListElement, settings: ChartSettings): void {
        const referenceDisplayTypeDropdown = new InlineDropdown("Show comparisons as", settings.referenceDisplayType, {
            id: "reference-display-type-id",
            name: "referenceDisplayType",
        }, Array.from(this.getReferenceDisplayTypeOptions().values()));
        this.appendDesignLibraryComponent(referenceDisplayTypeDropdown, listItems);
    }

    private appendShowAllForecastToggle(listItems: HTMLUListElement, settings: ChartSettings): void {
        const showAllForecastToggle = new InlineToggleButton(settings.showAllForecastData, {
            id: "show-all-forecast-data-id",
            label: "Show all forecast data",
            name: "showAllForecastData",
        });
        this.appendDesignLibraryComponent(showAllForecastToggle, listItems);
    }

    private appendCurrentPeriodVarianceOptionsDropdown(listItems: HTMLUListElement, settings: ChartSettings): void {
        const currentPeriodVarianceOptionsDropdown = new InlineDropdown("Current period variance", settings.currentPeriodVarianceOptions, {
            id: "current-period-variance-option-id",
            name: "currentPeriodVarianceOptions",
        }, Array.from(this.getCurrentPeriodVarianceOptions().values()));
        this.appendDesignLibraryComponent(currentPeriodVarianceOptionsDropdown, listItems);
    }

    private appendCurrentPeriodVarianceConditionDropdown(listItems: HTMLUListElement, settings: ChartSettings): void {
        const currentPeriodVarianceConditionDropdown = new InlineDropdown("Condition", settings.currentPeriodVarianceCondition, {
            id: "current-period-variance-condition-id",
            name: "currentPeriodVarianceCondition",
        }, Array.from(this.getCurrentPeriodVarianceConditions().values()));
        this.appendDesignLibraryComponent(currentPeriodVarianceConditionDropdown, listItems);
    }

    private appendDayInMonthConditionInput(listItems: HTMLUListElement, settings: ChartSettings): void {
        const dayInMonthConditionInput = new InlineNumberInput("Show from day", settings.dayInMonthVarianceCondition, {
            id: "day-in-month-variance-condition-id",
            name: "dayInMonthVarianceCondition",
            min: 1,
            max: 31
        });
        this.appendDesignLibraryComponent(dayInMonthConditionInput, listItems);
    }

    private appendDayInWeekConditionInput(listItems: HTMLUListElement, settings: ChartSettings): void {
        const dayInWeekConditionInput = new InlineNumberInput("Show from day", settings.dayInWeekVarianceCondition, {
            id: "day-in-week-variance-condition-id",
            name: "dayInWeekVarianceCondition",
            min: 1,
            max: 7
        });
        this.appendDesignLibraryComponent(dayInWeekConditionInput, listItems);
    }

    private appendMonthInQuarterConditionInput(listItems: HTMLUListElement, settings: ChartSettings): void {
        const monthInQuarterConditionInput = new InlineNumberInput("Show from month", settings.monthInQuarterVarianceCondition, {
            id: "month-in-quarter-variance-condition-id",
            name: "monthInQuarterVarianceCondition",
            min: 1,
            max: 3
        });
        this.appendDesignLibraryComponent(monthInQuarterConditionInput, listItems);
    }

    private appendMonthInYearConditionInput(listItems: HTMLUListElement, settings: ChartSettings): void {
        const monthInYearConditionInput = new InlineNumberInput("Show from month", settings.monthInYearVarianceCondition, {
            id: "month-in-year-variance-condition-id",
            name: "monthInYearVarianceCondition",
            min: 1,
            max: 12
        });
        this.appendDesignLibraryComponent(monthInYearConditionInput, listItems);
    }

    private appendVerticalAxisToggle(listItems: HTMLUListElement, settings: ChartSettings): void {
        const verticalAxisToggle = new InlineToggleButton(settings.showVerticalCharts, {
            id: "vertical-axis-id",
            label: "Show vertical axis",
            name: "showVerticalCharts"
        });
        this.appendDesignLibraryComponent(verticalAxisToggle, listItems);
    }

    private appendNullsAsZerosToggle(listItems: HTMLUListElement, settings: ChartSettings): void {
        const nullsAsZerosToggle = new InlineToggleButton(settings.handleNullsAsZeros, {
            id: "handle-nulls-as-zeros-id",
            label: "Replace empty values with zeros",
            name: "handleNullsAsZeros",
        });
        this.appendDesignLibraryComponent(nullsAsZerosToggle, listItems);
    }

    private createSettingsForm(innerForm: d3.Selection<HTMLElement, any, any, any>) {
        const settings: ChartSettings = Visual.settings;

        if (!settings) {
            return;
        }

        const plotVertical = settings.shouldPlotVerticalCharts();
        const plotStacked = settings.shouldPlotStackedChart(Visual.viewModel.isMultiples);

        const listItems = document.createElement("ul");
        listItems.classList.add("list-items");
        innerForm.node().append(listItems);

        this.appendInvertVarianceToggle(listItems, settings);

        if (settings.chartType === ChartType.Waterfall || settings.chartType === ChartType.Variance || settings.chartType === ChartType.Area) {
           this.appendLayoutDropdown(listItems, settings, plotStacked);
        }

        if ((!plotVertical && !plotStacked && (settings.chartType === ChartType.Variance || settings.chartType === ChartType.Waterfall && Visual.viewModel.isSingleSeriesViewModel))
            || !plotVertical && !plotStacked && settings.chartType === ChartType.Bar) {
            this.appendGrandTotalToggle(listItems, settings);

            if (settings.showGrandTotal) {
                this.appendGrandTotalLabelInput(listItems, settings);
            }
        }

        if (settings.chartType === ChartType.Waterfall || settings.chartType === ChartType.Variance || settings.chartType === ChartType.Area) {
            if ((settings.chartType === ChartType.Variance && (settings.chartLayout === RESPONSIVE || settings.chartLayout === RELATIVE || settings.chartLayout === ACTUAL_RELATIVE || settings.chartLayout === ABSOLUTE_RELATIVE) ||
                settings.chartType === ChartType.Waterfall && settings.chartLayout === RESPONSIVE)) {
                this.appendCustomOutliersToggle(listItems, settings);

                if (settings.limitOutliers) {
                    this.appendCustomOutliersInputs(listItems, settings);
                }
            }
        }

        if (settings.chartType === ChartType.Waterfall || settings.chartType === ChartType.Variance || settings.chartType === ChartType.Area) {
            if (settings.chartType === ChartType.Variance && (settings.chartLayout === RESPONSIVE || settings.chartLayout === ACTUAL
                || settings.chartLayout === ACTUAL_RELATIVE || settings.chartLayout === ACTUAL_ABSOLUTE)) {
                this.appendReferenceDisplayTypeDropdown(listItems, settings);
            }
        }

        if (settings.scenarioOptions.secondValueScenario === Scenario.Forecast
            && (settings.chartType !== ChartType.Waterfall || settings.scenarioOptions.secondReferenceScenario !== null)) {
            this.appendShowAllForecastToggle(listItems, settings);
        }

        if (settings.scenarioOptions.referenceScenario !== null && settings.chartType === ChartType.Variance) {
            this.appendCurrentPeriodVarianceOptionsDropdown(listItems, settings);

            if (settings.currentPeriodVarianceOptions === 2) {
                this.appendCurrentPeriodVarianceConditionDropdown(listItems, settings);

                switch (settings.currentPeriodVarianceCondition) {
                    case 0: {
                        this.appendDayInMonthConditionInput(listItems, settings);
                        break;
                    }
                    case 1: {
                        this.appendDayInWeekConditionInput(listItems, settings);
                        break;
                    }
                    case 2: {
                        this.appendMonthInQuarterConditionInput(listItems, settings);
                        break;
                    }
                    default: {
                        this.appendMonthInYearConditionInput(listItems, settings);
                    }
                }
            }
        }

        if (settings.chartTypeSupportsVertical(settings.chartType)) {
            this.appendVerticalAxisToggle(listItems, settings);
        }

        this.appendNullsAsZerosToggle(listItems, settings);

        const resetButton = new LinkButton("Reset to default");
        resetButton.containerNode().addEventListener(CLICK, () => {
            this.resetToDefault();
        });
        resetButton.appendTo(innerForm.node());

        this.observeFormChanges(innerForm.node());
    }

    private resetToDefault(): void {
        const defaultSettings = {};
        // IMPORTANT: when null is persisted on string type, property is not deleted from object but is instead empty string
        const settings = new ChartSettings(CUSTOMER_STYLE_VISIBLE.toString() === "true", "en", <ScenarioOptions>{});
        Object.keys(SidebarChartSettings).filter((v) => isNaN(Number(v))).forEach(key => {
            defaultSettings[key] = settings[key];
        });

        this.persistChartSettings(defaultSettings);
    }

    private persistChartSettings(newSettings: any) {
        let settings = Visual.settings;

        for (const [key, value] of Object.entries(newSettings)) {
            settings[key] = value;
        }

        Visual.getInstance().constructViewModelAndVisualUpdate(settings);
    }

    getCurrentPeriodVarianceConditions(): Map<string, DropdownOptionAttributes> {
        const currentPeriodVarianceOptions = new Map<string, DropdownOptionAttributes>([
            ["DayInMonth", {
                label: "Day in month",
                value: 0
            }],
            ["DayInWeek", {
                label: "Day in week",
                value: 1
            }],
            ["MonthInQuarter", {
                label: "Month in quarter",
                value: 2
            }],
            ["MonthInYear", {
                label: "Month in year",
                value: 3
            }]
        ]);
        return currentPeriodVarianceOptions;
    }

    getCurrentPeriodVarianceOptions(): Map<string, DropdownOptionAttributes> {
        const currentPeriodVarianceOptions = new Map<string, DropdownOptionAttributes>([
            ["AlwaysShow", {
                label: "Always show",
                value: 0
            }],
            ["HideUntilClosed", {
                label: "Hide until closed",
                value: 1
            }],
            ["ShowConditionally", {
                label: "Show conditionally",
                value: 2
            }]
        ]);
        return currentPeriodVarianceOptions;
    }

    getReferenceDisplayTypeOptions(): Map<string, DropdownOptionAttributes> {
        const referenceDisplayTypeOptions = new Map<string, DropdownOptionAttributes>([
            ["ValueColumnOnly", {
                label: "Value column only",
                value: ReferenceDisplayType.None
            }],
            ["OverlappedColumns", {
                label: "Overlapped columns",
                value: ReferenceDisplayType.OverlappedColumn
            }],
            ["Markers", {
                label: "Markers",
                value: ReferenceDisplayType.Triangles
            }]
        ]);
        return referenceDisplayTypeOptions;
    }

    getValidChartLayouts(): Map<string, DropdownOptionAttributes> {
        let validChartLayoutValues: Map<string, DropdownOptionAttributes>;
        const settings = Visual.settings;

        if (settings.showVerticalCharts) {
            validChartLayoutValues = settings.chartType === ChartType.Variance ?
                new Map<string, DropdownOptionAttributes>([
                    [INTEGRATED, {
                        label: `${INTEGRATED}`,
                        value: INTEGRATED
                    }],
                    [ACTUAL, {
                        label: `${ACTUAL}`,
                        value: ACTUAL
                    }]
                ]) :
                new Map<string, DropdownOptionAttributes>([
                    [WATERFALL, {
                        label: `${WATERFALL}`,
                        value: WATERFALL
                    }]
                ]);
        }
        else {
            if (settings.chartType === ChartType.Area) {
                validChartLayoutValues = new Map<string, DropdownOptionAttributes>([
                    [INTEGRATED, {
                        label: `${INTEGRATED}`,
                        value: INTEGRATED
                    }],
                    [ACTUAL, {
                        label: `${ACTUAL}`,
                        value: ACTUAL
                    }]
                ]);
            }
            else if (settings.chartType === ChartType.Waterfall) {
                validChartLayoutValues = new Map<string, DropdownOptionAttributes>([
                    [RESPONSIVE, {
                        label: `${RESPONSIVE}`,
                        value: RESPONSIVE
                    }],
                    [WATERFALL, {
                        label: `${WATERFALL}`,
                        value: WATERFALL
                    }]
                ]);
            }
            else {
                validChartLayoutValues = new Map<string, DropdownOptionAttributes>([
                    [RESPONSIVE, {
                        label: `${RESPONSIVE}`,
                        value: RESPONSIVE
                    }],
                    [INTEGRATED, {
                        label: `${INTEGRATED}`,
                        value: INTEGRATED
                    }],
                    [ABSOLUTE, {
                        label: `${ABSOLUTE}`,
                        value: ABSOLUTE
                    }],
                    [RELATIVE, {
                        label: `${RELATIVE}`,
                        value: RELATIVE
                    }],
                    [ABSOLUTE_RELATIVE, {
                        label: `${ABSOLUTE_RELATIVE}`,
                        value: ABSOLUTE_RELATIVE
                    }],
                    [ACTUAL_ABSOLUTE, {
                        label: `${ACTUAL_ABSOLUTE}`,
                        value: ACTUAL_ABSOLUTE
                    }],
                    [ACTUAL_RELATIVE, {
                        label: `${ACTUAL_RELATIVE}`,
                        value: ACTUAL_RELATIVE
                    }],
                    [ACTUAL, {
                        label: `${ACTUAL}`,
                        value: ACTUAL
                    }]
                ]);
            }
        }

        if (validChartLayoutValues.size < 2 || settings.stackedChart) {
            validChartLayoutValues = new Map<string, DropdownOptionAttributes>([[RESPONSIVE, {
                label: `${RESPONSIVE}`,
                value: RESPONSIVE
            }]]);
        }

        return validChartLayoutValues;
    }
}

export default ChartSettingsSwitcher;
