import React, { useState, useEffect } from "react";
import { dataLinkingEventBus } from "../../DataLinkingEventBus";
import useStore from "../../useStore";
import SharepointBrowserAPI from "@zebrabi/sharepoint-api-browser/SharepointBrowserAPI";
import { changeRootDOMElementDisplay } from "../index";
import { transformSharePointResponse } from "../../helpers";

export default function RangeSelection() {
  const [accessToken] = useStore("accessToken");
  const [activeSiteId, setActiveSiteId] = useStore("activeSiteId");
  const [activeDriveId, setActiveDriveId] = useStore("activeDriveId");
  const [activeWorksheetId, setActiveWorksheetId] = useStore("activeWorksheetId");
  const [activeWorkbookId] = useStore("activeWorkbookId");
  const [activeFileId, setActiveFileId] = useStore("activeFileId");
  const [, setFoldersAndFiles] = useStore("foldersAndFiles");
  const [, setDrives] = useStore("drives");
  const [, setFoldersNavigation] = useStore("foldersNavigation");
  const [, setCurrentView] = useStore("currentView");
  const [, setLoaded] = useStore("loaded");
  const [, setShowFullScreenLoader] = useStore("showFullScreenLoader");

  const [range, setRange] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const browser = SharepointBrowserAPI.createInstance(accessToken);

  useEffect(() => {
    setRange("");
  }, [activeWorksheetId, setRange]);

  const updateRange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\s/g, "").toUpperCase();

    setRange(value);
  };

  const persistRange = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowFullScreenLoader(true);
    setShowErrorMessage(false);

    const valuesResponse = activeWorkbookId
      ? await browser.getTableRange(activeDriveId, activeFileId, activeWorkbookId)
      : await browser.getRange(activeDriveId, activeFileId, activeWorksheetId, range);

    if (valuesResponse?.message) {
      setShowErrorMessage(true);
      setShowFullScreenLoader(false);

      return;
    }

    const rows = transformSharePointResponse(valuesResponse.values);

    setLoaded({
      siteId: activeSiteId,
      driveId: activeDriveId,
      fileId: activeFileId,
      worksheetId: activeWorksheetId,
      workbookId: activeWorkbookId,
      range,
    });

    dataLinkingEventBus.dispatch("range-selected", rows);

    // make sure these calls are the same as in CloseExcelDataLinking
    setShowFullScreenLoader(false);
    setFoldersAndFiles([]);
    setFoldersNavigation([]);
    setDrives([]);
    setActiveSiteId("");
    setActiveDriveId("");
    setActiveWorksheetId("");
    setActiveFileId("");
    setCurrentView("file-selection");

    changeRootDOMElementDisplay("none");
  };

  const isEnabled: boolean = activeWorkbookId || (activeWorksheetId && range);

  return (
    <form className="range-selection" onSubmit={persistRange}>
      {activeWorksheetId && <label htmlFor="range">Range:</label>}
      {activeWorksheetId && (
        <input type="text" placeholder="A1:D10" name="range" id="range" value={range} onChange={updateRange} />
      )}
      <button type="submit" disabled={!isEnabled}>
        Fetch data
      </button>

      {showErrorMessage && <p className="error-message">Table is too large.</p>}
    </form>
  );
}
