
export async function getSampleDataRangeStartRowAndColumn(activeWorksheet: Excel.Worksheet, context: Excel.RequestContext): Promise<{ startRow: number, startColumn: number }> {
    let startRow = 0;
    let startColumn = 0;
    // check for stored named range address and use it for sample data location, if it exists
    const zebraNamedRange: Excel.NamedItem = activeWorksheet.names.getItemOrNullObject("ZBI_datasource_address");
    await context.sync();
    if (!zebraNamedRange.isNullObject) {
        zebraNamedRange.load({ value: true });
        await context.sync();
        try {
            const range = activeWorksheet.getRange(zebraNamedRange.value);
            range.load({ rowIndex: true, columnIndex: true });
            await context.sync();
            startRow = range.rowIndex;
            startColumn = range.columnIndex;
        } catch (error) {
            console.log("could not parse named range address: ", zebraNamedRange.value);
        }
    }
    return { startRow, startColumn };
}

export async function getExcelTable(context: Excel.RequestContext, worksheet?: Excel.Worksheet, tableName?: string, tableId?: string): Promise<Excel.Table> {
    if (tableId || tableName) {
        return context.workbook.tables.getItem(tableId ? tableId : tableName); //sheet.tables.getItem(tableName);
    }

    const sheet = worksheet ? worksheet : context.workbook.worksheets.getActiveWorksheet();
    if (!sheet?.tables) {
        return null;
    }

    const tables = sheet.tables;
    const tablesCount = tables?.getCount();
    await context.sync();

    if (tablesCount.value === 0) {
        return null;
    } else {
        const table = tables.getItemAt(0);
        table.load({ id: true, name: true });
        await context.sync();
        return table;
    }
}
