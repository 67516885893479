import Layout from "./Layout";
import { ChartType, Sort } from "./../enums";
import { MULTIPLE_MARGIN } from "./../consts";
import { NORMAL, AUTO, RIGHT_SCROLLBAR_MARGIN, BOTTOM_SCROLLBAR_MARGIN, HEIGHT, WIDTH } from "./../library/constants";
import { ChartData, ChartLayout } from "./../interfaces";
import * as d3 from "../d3";
import * as drawing from "./../library/drawing";
import * as charting from "./../charting/multiples";

export class TwoDimensionalLayout extends Layout {

    public getChartLayouts(rowCount: number, maxChartsPerRow: number, height: number, visualTitleHeight: number, minChartHeight: number, minChartWidth: number,
        plotChartWidth: number, bottomMargin: number, topMargin: number, rowMargin: number, columnMargin: number, labelFontSize: number, xPosition: number, leftMargin: number): ChartLayout[] {

        const rowHeaders = this.viewModel.chartData.map(c => c.rowHeader);
        const rowHeaderMargin = Math.max(...rowHeaders.map(h => drawing.measureTextWidth(h, this.settings.groupTitleFontSize, this.settings.groupTitleFontFamily, NORMAL, NORMAL))) + 10;
        const columnHeaderMargin = this.settings.groupTitleFontSize * 1.5;
        height -= columnHeaderMargin;
        topMargin -= this.settings.groupTitleFontSize + 3;

        const layouts: ChartLayout[] = [];
        const rows = this.viewModel.chartData.map(d => d.rowHeader).filter((r, i, a) => a.indexOf(r) === i);
        let sortedRows = rows;

        const rowsSpans = rows.map(r => {
            const rowCharts = this.viewModel.chartData.filter(c => c.rowHeader === r);
            const totalSpan = d3.sum(rowCharts.map(c => c.max - c.min));
            return { rowHeader: r, maxSpan: totalSpan };
        });
        if (this.settings.multiples2dRowsSort === Sort.Descending) {
            sortedRows = rowsSpans.sort((a, b) => b.maxSpan - a.maxSpan).map(r => r.rowHeader);
        }
        else if (this.settings.multiples2dRowsSort === Sort.Ascending) {
            sortedRows = rowsSpans.sort((a, b) => a.maxSpan - b.maxSpan).map(r => r.rowHeader);
        }

        const columnsHeaders = this.viewModel.chartData.map(d => d.columnHeader).filter((r, i, a) => a.indexOf(r) === i);
        const columnSpans = columnsHeaders.map(r => {
            const colCharts = this.viewModel.chartData.filter(c => c.columnHeader === r);
            const maxSpan = d3.sum(colCharts.map(c => c.max - c.min));
            return { colHeader: r, maxSpan: maxSpan };
        });
        let sortedCols = columnsHeaders;
        if (this.settings.multiples2dColumnsSort === Sort.Descending) {
            sortedCols = columnSpans.sort((a, b) => b.maxSpan - a.maxSpan).map(r => r.colHeader);
        }
        else if (this.settings.multiples2dColumnsSort === Sort.Ascending) {
            sortedCols = columnSpans.sort((a, b) => a.maxSpan - b.maxSpan).map(r => r.colHeader);
        }

        this.viewModel.chartData.sort((a, b) => {
            if (a.rowHeader !== b.rowHeader) {
                return sortedRows.indexOf(a.rowHeader) - sortedRows.indexOf(b.rowHeader);
            }
            else {
                return sortedCols.indexOf(a.columnHeader) - sortedCols.indexOf(b.columnHeader);
            }
        });
        const chartMargin = topMargin + bottomMargin;
        const rowsDataDiffs = sortedRows.map(r => Math.max(...this.viewModel.chartData.filter(c => c.rowHeader === r).map(cd => cd.max - cd.min)));
        const totalDataDiffSum = rowsDataDiffs.reduce((a, b) => a + b, 0);
        const totalTopAndBottomMargins = rows.length * chartMargin;
        const availableHeight = this.getAvailableHeight(this.width, height, minChartWidth, columnMargin, sortedCols.length, rowHeaderMargin);
        let scaleFactor = totalDataDiffSum === 0 ? 1 : (availableHeight - totalTopAndBottomMargins - rowMargin * (rows.length - 1)) / (totalDataDiffSum);
        let rowHeights = rowsDataDiffs.map(a => a * scaleFactor + chartMargin);
        const maxRowHeight = Math.max(...rowHeights);
        if (rows.length > 1 && maxRowHeight < minChartHeight && totalDataDiffSum !== 0) {
            scaleFactor = (minChartHeight - chartMargin) / Math.max(...rowsDataDiffs);
            rowHeights = rowsDataDiffs.map(a => a * scaleFactor + chartMargin);
        }

        maxChartsPerRow = Math.max(...rows.map(r => this.viewModel.chartData.filter(c => c.rowHeader === r).length), sortedCols.length);
        const availableWidth = this.getAvailableWidth(this.width - leftMargin * 2, height, rowHeights, rowMargin, rows.length);
        plotChartWidth = (availableWidth - rowHeaderMargin - columnMargin * maxChartsPerRow) / maxChartsPerRow;
        plotChartWidth = Math.max(plotChartWidth, minChartWidth);
        let currentRowIndex = 0;
        let currentColIndex = sortedCols.indexOf(this.viewModel.chartData[0].columnHeader); // 0;
        const firstRowCharts = this.viewModel.chartData.filter(c => c.rowHeader === sortedRows[0]);
        let largestInARowChart: ChartData = firstRowCharts.reduce((a, b) => a.max - a.min > b.max - b.min ? a : b);
        for (let i = 0; i < this.viewModel.chartData.length; i++) {
            const current = this.viewModel.chartData[i];
            const x = rowHeaderMargin + leftMargin + xPosition + currentColIndex * (plotChartWidth + columnMargin);
            const y = visualTitleHeight + columnHeaderMargin + rowHeights.filter((h, i) => i < currentRowIndex).reduce((a, b) => a + b, 0) + rowMargin * currentRowIndex;

            layouts.push({
                height: rowHeights[currentRowIndex],
                width: currentColIndex === 0 ? plotChartWidth + xPosition + leftMargin : plotChartWidth,
                position: {
                    x: currentColIndex === 0 ? rowHeaderMargin : x,
                    y: y,
                },
                columnIndex: currentColIndex,
                rowIndex: currentRowIndex,
                min: largestInARowChart.min,
                max: largestInARowChart.max,
            });

            if (i < this.viewModel.chartData.length - 1) {
                const next = this.viewModel.chartData[i + 1];
                currentRowIndex = sortedRows.indexOf(next.rowHeader);
                currentColIndex = sortedCols.indexOf(next.columnHeader);
                if (current.rowHeader !== next.rowHeader) {
                    const nextRowCharts = this.viewModel.chartData.filter(c => c.rowHeader === next.rowHeader);
                    largestInARowChart = nextRowCharts.reduce((a, b) => a.max - a.min > b.max - b.min ? a : b);
                }
            }
        }
        return layouts;
    }

    public plot(rowCount: number, maxChartsPerRow: number, height: number, titleHeight: number, minChartHeight: number, minChartWidth: number, plotChartWidth: number, bottomMargin: number,
        topMargin: number, rowMargin: number, columnMargin: number) {
        this.slider.style.overflowX = AUTO;
        const columnHeaderMargin = this.settings.groupTitleFontSize * 1.5;
        const xPosition = this.settings.showLegend && this.settings.chartType !== ChartType.Waterfall ? this.settings.getLegendWidth() : 0;
        let leftMargin = 0;
        const multiplesMargin = MULTIPLE_MARGIN;
        if (this.settings.showMultiplesGrid && this.settings.showOuterBorders) {
            height -= multiplesMargin / 2;
            leftMargin = multiplesMargin / 2;
        }

        const chartLayouts = this.getChartLayouts(rowCount, maxChartsPerRow, height, titleHeight, minChartHeight, minChartWidth, plotChartWidth, bottomMargin,
            topMargin, rowMargin, columnMargin, this.settings.labelFontSize, xPosition, leftMargin);
        const maxColumnHeight = Math.max(...chartLayouts.map((layout) => layout.height + layout.position.y)) + leftMargin + 1;
        const maxRowWidth = Math.max(...chartLayouts.map(c => c.position.x + c.width)) + leftMargin + 1;
        const svgWidth = maxRowWidth;
        charting.plotRowHeaders(this.reportArea, this.viewModel.chartData, chartLayouts, this.settings);
        charting.plotColumnHeaders(this.reportArea, this.viewModel.chartData, chartLayouts, this.settings, chartLayouts[0].position.y - columnHeaderMargin);
        this.svg
            .attr(WIDTH, svgWidth)
            .attr(HEIGHT, maxColumnHeight);
        this.plotMultiples(this.viewModel.chartData, chartLayouts, topMargin, bottomMargin, false);
    }

    private getAvailableWidth(visualWidth: number, visualHeight: number, rowHeights: number[], rowMargin: number, rowsCount: number): number {
        let availableWidth = visualWidth;
        const totalHeight = d3.sum(rowHeights) + rowMargin * (rowsCount - 1);
        if (totalHeight - 1 > visualHeight) {
            availableWidth -= RIGHT_SCROLLBAR_MARGIN;
        }
        return availableWidth;
    }

    private getAvailableHeight(visualWidth: number, visualHeight: number, chartWidth: number, columnMargin: number, columnCount: number, rowHeaderMargin: number): number {
        let availableHeight = visualHeight;
        const totalWidth = rowHeaderMargin + chartWidth * columnCount + columnMargin * (columnCount - 1);
        if (totalWidth - 1 > visualWidth) {
            availableHeight -= BOTTOM_SCROLLBAR_MARGIN;
        }
        return availableHeight;
    }
}
