import BaseSubject from "./BaseSubject";
import type GlobalToolbarObserver from "../observers/GlobalToolbarObserver";

export default class GlobalToolbarSubject extends BaseSubject<Map<string, any>, GlobalToolbarObserver> {
    public static CLASS_NAME = "GlobalToolbarSubject";

    notifyAction(action: string, message: string, target?: string) {
        for (const observer of this.observers) {
            if (target === observer.getClassName() || !target) {
                (<GlobalToolbarObserver> observer).action(action, message);
            }
        }
    }
}
