import { ChartType } from "../../../../../../../packages/visuals/charts/enums";
import { Categories } from "../category.type";
import { ACTUAL } from "../../../../../../../packages/visuals/charts/consts";

import StructureComparisonBarImage from "./images/structure-comparison-bar.svg";
import StructureComparisonDotImage from "./images/structure-comparison-dot.svg";
import StructureSeriesComparisonBarImage from "./images/structure-series-comparison-bar.svg";

import {
  StructureComparisonBarCommentData,
  StructureComparisonBarSampleData,
  StructureComparisonDotCommentData,
  StructureComparisonDotSampleData,
  StructureSeriesComparisonBarCommentData,
  StructureSeriesComparisonBarSampleData,
} from "./structureCategories.mock";

export const structureCategories: Categories = [
  {
    displayName: "Single Series",
    charts: [
      {
        id: "structure-comparison-bar",
        title: "Bar Chart",
        image: StructureComparisonBarImage,
        sampleData: StructureComparisonBarSampleData,
        commentData: StructureComparisonBarCommentData,
        tooltip:
          "Compare two or more values, rank data categories, look up specific values, and recognize patterns in data. Use when you have discrete items like products or customers.",
        settings: {
          chartType: ChartType.Bar,
          showVerticalCharts: true,
        },
      },
      {
        id: "structure-comparison-dot",
        title: "Vertical pin chart",
        image: StructureComparisonDotImage,
        sampleData: StructureComparisonDotSampleData,
        commentData: StructureComparisonDotCommentData,
        tooltip:
          "Compare two or more values, rank data categories, look up specific values, and recognize patterns in data. Use when you have discrete items like products or customers.",
        settings: {
          chartType: ChartType.Pin,
          showVerticalCharts: true,
        },
      },
    ],
  },
  {
    displayName: "Multiple Series",
    charts: [
      {
        id: "structure-series-comparison-bar",
        title: "Comparison bar chart",
        image: StructureSeriesComparisonBarImage,
        sampleData: StructureSeriesComparisonBarSampleData,
        commentData: StructureSeriesComparisonBarCommentData,
        tooltip:
          "Compare multiple data series(AC, PY, Plan, FC), rank data categories, look up specific values, and recognize patterns in data. Use when you have discrete items like products or customers.",
        settings: {
          chartType: ChartType.Variance,
          showVerticalCharts: true,
          chartLayout: ACTUAL,
        },
      },
    ],
  },
];
