import GlobalToolbarObserver from "../../observers/GlobalToolbarObserver";
import { actionButton as actionButtonHTML } from "../../templates/actionButton";
import { actionSelect as actionSelectHTML } from "../../templates/actionSelect";
import { toolbarGroup as toolbarGroupHTML } from "../../templates/toolbarGroup";
import GlobalToolbar from "../../components/GlobalToolbar";
import GlobalToolbarSubject from "../../subject/GlobalToolbarSubject";
import { ToolbarOptions } from "../../interface/ToolbarOption";
import { ORGANIZATION_STYLE_PREFIX } from "@zebrabi/data-helpers/organizationStyles";

export default abstract class BaseSwitcher extends GlobalToolbarObserver {
  public static CLASS_NAME = "BaseSwitcher";

  abstract toolbarOptions: ToolbarOptions;
  protected switcherContainer: HTMLElement;
  public switcherSubject: GlobalToolbarSubject = new GlobalToolbarSubject();
  private static ICON_PREFIX = "icon-toolbar-component-";
  protected switcherScrollPosition: number;

  protected returnData = new Map<string, any>();

  constructor() {
    super();

    const range = document.createRange();
    const groupFragment = range.createContextualFragment(toolbarGroupHTML);
    const group = groupFragment.querySelector(".group");
    group.id = this.getClassName();

    this.switcherContainer = <HTMLElement>group;
  }

  /**
   * define what should happen once the value of a form item changes
   * @param action {string}
   * @param message {string}
   */
  abstract buttonAction(action: string, message: string): void;
  abstract getScrollPosition(): number;

  getToolbarOptions(): ToolbarOptions {
    return this.toolbarOptions;
  }

  /**
   * Creates toolbar option
   */
  createOptions(useAltIcon?: boolean, overrideUseAltIcon?: boolean) {
    const option: ToolbarOptions = this.toolbarOptions;

    const title = this.switcherContainer.querySelector(".title");
    title.textContent = option.elementName;

    if (option.collapsed) {
      this.switcherContainer.classList.add("collapsed");
    }

    const icon = this.switcherContainer.querySelector(".group-icon .icon");
    if (option.altIcon && useAltIcon) {
      icon.classList.add(option.altIcon);
      icon.classList.remove(option.icon);
    } else {
      icon.classList.add(option.icon);
      if (option.altIcon) {
        icon.classList.remove(option.altIcon);
      }
    }
    icon.classList.add("icon");

    const actionContainer = <HTMLElement>this.switcherContainer.querySelector(".actions");
    actionContainer.textContent = "";
    this.createMenuItems(actionContainer);

    return this.switcherContainer;
  }

  /**
   * Method that populates the content of the menu items from the ToolbarOptions actions array
   * override this method if you need to define a custom settings window
   * @param switcherMenuContainer {HTMLElement} - the target to populate
   * @param active {string} - for matching active items
   */
  createMenuItems(switcherMenuContainer: HTMLElement, active = ""): void {
    const range = document.createRange();
    range.selectNode(document.body);

    const actionButtonTemplate = range.createContextualFragment(actionButtonHTML);

    this.toolbarOptions.actions.map((menuAction) => {
      const actionButton = <HTMLElement>actionButtonTemplate.cloneNode(true);
      const button: HTMLElement = actionButton.querySelector(".button");
      button.classList.add(menuAction.value);

      const radioInput = actionButton.querySelector("input");
      radioInput.value = menuAction.value;
      radioInput.name = this.getClassName();

      button.dataset.icon = menuAction.icon;
      button.setAttribute("title", menuAction.title);

      this.setInactiveButton(button);

      if (active === menuAction.value) {
        this.setActiveButton(button);
        radioInput.checked = true;
      }
      const title = actionButton.querySelector(".title");
      title.textContent = menuAction.title;

      if (this.toolbarOptions.actions.length == 1) {
        radioInput.type = "checkbox";

        radioInput.addEventListener("change", () => {
          this.buttonAction(menuAction.action, menuAction.value);

          if (button.classList.contains("active")) {
            this.setInactiveButton(button);
          } else {
            this.setActiveButton(button);
          }

          this.notifyAction(menuAction.action, menuAction.value, this.getClassName());
        });
      } else {
        radioInput.addEventListener("change", () => {
          this.buttonAction(menuAction.action, menuAction.value);

          const actionButtons = switcherMenuContainer.querySelectorAll(".action");
          actionButtons.forEach((actionButton: HTMLElement) => {
            this.setInactiveButton(actionButton);
          });

          this.setActiveButton(button);
          this.notifyAction(menuAction.action, menuAction.value, this.getClassName());
        });
      }

      switcherMenuContainer.appendChild(actionButton);
    });
  }

  getActionContainer(): HTMLElement {
    return this.switcherContainer.querySelector(".actions");
  }

  abstract setScrollPosition(scrollPosition: number);

  setActiveButton(button: HTMLElement) {
    button.classList.add("active");
    const icon = button.querySelector(".icon");
    icon.classList.add("active");
  }

  setInactiveButton(button: HTMLElement) {
    button.classList.remove("active");
    const idleIcon = document.getElementById(BaseSwitcher.ICON_PREFIX + button.dataset.icon);

    if (idleIcon) {
      const icon = button.querySelector("svg use");
      icon.classList.remove("active");
    }
  }

  /**
   * generate a select input instead of a button to populate directly in to the global toolbar
   * @param switcherMenuContainer
   * @param active
   */
  createMenuSelector(switcherMenuContainer: HTMLElement, active: string | number) {
    const range = document.createRange();
    range.selectNode(document.body);

    const actionSelectorTemplate = range.createContextualFragment(actionSelectHTML);
    const actionSelect = <HTMLElement>actionSelectorTemplate.cloneNode(true);
    const select = actionSelect.querySelector("select");

    select.addEventListener("change", (e) => {
      const changedSelect: HTMLSelectElement = <HTMLSelectElement>e.target;

      this.buttonAction(this.toolbarOptions.actions[0]?.action, changedSelect.value);
      this.notifyAction(this.toolbarOptions.actions[0]?.action, changedSelect.value, this.getClassName());
    });

    this.toolbarOptions.actions.map((menuAction) => {
      const option = document.createElement("option");
      option.value = menuAction.value;
      option.textContent = menuAction.title;

      if (active === menuAction.value) {
        option.selected = true;
      }

      select.appendChild(option);
    });

    switcherMenuContainer.appendChild(actionSelect);
  }

  action(action: string, message: string) {
    const componentGroup = document.getElementById(this.getClassName());
    const actions = <HTMLElement>componentGroup?.querySelector(".actions");
    let actionsBox: DOMRect;
    let bodyBox: DOMRect;

    switch (
    action // add an action here if you want to for example handle a specific action, like define a close action specifically instead of a toggle
    ) {
      case GlobalToolbar.TOOLBAR_CLOSE:
        componentGroup?.classList.remove("active");
        break;
      case GlobalToolbar.TOOLBAR_GROUP_CLICK: // toggle the toolbar group active/inactive
        if (!componentGroup?.classList.contains("active") && message === this.getClassName()) {
          componentGroup?.classList.add("active");
          actions.classList.remove("right-overflow");
        } else {
          componentGroup?.classList.remove("active");
        }

        actionsBox = actions.getBoundingClientRect();
        bodyBox = document.body.getBoundingClientRect();

        if (actionsBox.right > bodyBox.width) {
          actions.classList.add("right-overflow");
        } else {
          actions.classList.remove("right-overflow");
        }

        actionsBox = actions.getBoundingClientRect();
        actions.style.top =
          componentGroup.getBoundingClientRect().top + componentGroup.getBoundingClientRect().height + "px";
        actionsBox = actions.getBoundingClientRect();

        if (actionsBox.bottom > bodyBox.bottom) {
          actions.style.maxHeight = bodyBox.bottom - actionsBox.top + "px";
        } else {
          actions.style.maxHeight = null;
        }

        break;
    }
  }

  getSwitcherContainer() {
    return this.switcherContainer;
  }

  bindEvents() {
    return;
  }

  isOpen() {
    const globalToolbar = document.querySelector(".global-toolbar");
    return globalToolbar.querySelector(`.${this.getClassName()}-container`)?.classList.contains("visible");
  }

  init() {
    this.bindEvents();
  }

  attach(observer: GlobalToolbarObserver) {
    this.switcherSubject.attach(observer);
  }

  notify(message: Map<string, any>, target?: string) {
    this.switcherSubject.notify(message, target);
  }

  notifyAction(action: string, message: string, target?: string) {
    this.switcherSubject.notifyAction(action, message, target);
  }

  open(): void {
    this.switcherContainer.classList.toggle("active");
  }

  close(): void {
    this.switcherContainer.classList.remove("active");
  }

  public abstract getClassName(): string;

  observeFormChanges(formRoot: HTMLElement): void {
    const formElements: HTMLElement[] = Array.from(
      formRoot.querySelectorAll("select[name], textarea[name], input[name]")
    );

    for (const element of formElements) {
      element.addEventListener("change", (e) => {
        const inputElement = <HTMLInputElement>e.currentTarget;
        const settingName = inputElement.name;
        const returnData = new Map<string, any>();
        const type = inputElement.type !== "select-one" ? inputElement.type : inputElement.dataset?.type;

        switch (type) {
          case "checkbox":
            returnData.set(settingName, inputElement.checked);
            break;
          case "number":
            returnData.set(settingName, Number(inputElement.value));
            break;
          default:
            returnData.set(settingName, inputElement.value);
            break;
        }

        if (settingName === "chartStyle") {
          if (inputElement.value.startsWith(ORGANIZATION_STYLE_PREFIX)) {
            // If the selected chart style is an organization style we extract the id
            const organizationStyleId = parseInt(inputElement.value.replace(ORGANIZATION_STYLE_PREFIX, ""))
            returnData.set("selectedOrganizationStyleId", organizationStyleId);

            const CompanyStyleEnumValue = -1;
            returnData.set(settingName, CompanyStyleEnumValue);
          } else {
            returnData.set(settingName, parseInt(inputElement.value));
          }
        }

        this.switcherScrollPosition = this.getScrollPosition();
        this.notify(returnData, `${this.getClassName()}Observer`); // notify anyone listening of the new data
      });
    }
  }
}
