import { SplitButton, SplitButtonSubscriber, Position, ButtonStateTypes, SplitButtonActions } from "@zebrabi/design-library";
import { BaseType } from "../d3";
import * as d3 from "../d3";
import { calculateSplitButtonOverlayPosition, repositionElement, setElementFontStyles } from "../helpers";
import { FontSettings } from "../interfaces";
import {
    ACTIVE, DATA_LABEL_MENU, MOUSEENTER, MOUSELEAVE, OVERLAY, RESET, SCROLL, ZEBRABI_CHARTS_CONTAINER, ZEBRABI_CHART_SVG_CONTAINER
} from "../library/constants";
import { LabelProperties } from "../library/interfaces";
import DataLabelMenu from "../components/DataLabelMenu/DataLabelMenu";
import { Visual } from "../visual";
import { showPopupMessage } from "./ui";
import { addOverlayProperties } from "./overlayHelpers";

export function showOverlayOnDataLabelHover(selection: d3.Selection<BaseType, LabelProperties, SVGElement, any>,
    fontSettings: FontSettings, //TODO see if these font settings can be used directly via visual settings
    labelInteraction: boolean = false,
    isLabelOverBar: boolean = true): void {
    const overlayContainer = <HTMLElement>document.querySelector(`.${ZEBRABI_CHARTS_CONTAINER}`);
    let timeOut;
    selection.on(MOUSEENTER, (e: MouseEvent, label: LabelProperties) => {
        if (!overlayContainer.querySelector(`.${OVERLAY}.${ACTIVE}`)) {
            timeOut = setTimeout(() => {
                showDataLabelSplitButton(overlayContainer, e.target as HTMLElement, label, fontSettings, labelInteraction, isLabelOverBar);
            }, 200);
        }
    });
    selection.on(MOUSELEAVE, () => {
        clearTimeout(timeOut);
    });
    if ((<HTMLElement>d3.select(`.${DATA_LABEL_MENU}.${RESET}`)?.node())) {
        reopenDataLabelMenu(overlayContainer);
    }
}

function showDataLabelSplitButton(overlayContainer: HTMLElement, labelHTML: HTMLElement, label: LabelProperties, fontSettings: FontSettings, labelInteraction: boolean, isLabelOverBar: boolean = true): void {
    overlayContainer?.querySelector(".split-btn")?.remove();

    const arrowButtonPosition = isLabelOverBar ? Position.Bottom : Position.Top;
    const arrowButtonRotation = arrowButtonPosition;
    const buttonText = label.additionalLabelY ? `${label.additionalTextLabel}<br>${label.text}` : `${label.text}<br>${label.additionalTextLabel}`;
    const splitButtonText = label.additionalTextLabel ? buttonText : label.text;
    const splitButton = new SplitButton(
        splitButtonText,
        ButtonStateTypes.outlined,
        undefined,
        { arrowButtonPosition, arrowButtonRotation }
    );

    splitButton.appendTo(overlayContainer);
    const buttonNode = splitButton.defaultButton.buttonNode();
    setElementFontStyles(buttonNode, fontSettings);

    const dataLabelBoundingRect = labelHTML.getBoundingClientRect();
    if (label.additionalLabelY) {
        dataLabelBoundingRect.y = label.additionalLabelY;
    }

    addOverlayProperties(splitButton.buttonNode());
    const overlay = splitButton.buttonNode();
    const overlayBoundingRect = overlay.getBoundingClientRect();
    const arrowButtonWidth = 23;
    calculateSplitButtonOverlayPosition(
        overlay,
        dataLabelBoundingRect,
        {
            verticalLeft: dataLabelBoundingRect.width / 2 - overlayBoundingRect.width / 2,
            verticalTop: (-overlayBoundingRect.height + arrowButtonWidth) / 2 + dataLabelBoundingRect.height / 2,
            horizontalLeft: dataLabelBoundingRect.width / 2 - overlay.getBoundingClientRect().width / 2,
            horizontalTop: -arrowButtonWidth
        },
        isLabelOverBar);

    const splitButtonSubscriber = new SplitButtonSubscriber();
    splitButtonSubscriber.update = (message, action) => handleSplitButtonUpdate(action, overlay, labelInteraction);
    splitButton.subscribe(splitButtonSubscriber);
}

function handleSplitButtonUpdate(action, overlay, labelInteraction): void {
    if (action === SplitButtonActions.ButtonClick && labelInteraction) {
        toggleLabelAnimation(overlay);
    } else if (action === SplitButtonActions.IconClick) {
        toggleDataLabelMenu(overlay);
    }
}

function toggleLabelAnimation(overlay: HTMLElement): void {
    if (Visual.settings.proVersionActive()) {
        Visual.settings.varianceLabel = (Visual.settings.varianceLabel + 2) % 3;
        Visual.animateVarianceLabels = true;
        Visual.settings.persistDataLabels();
    } else {
        const overlayBB = overlay.getBoundingClientRect();
        showPopupMessage(Visual.element, overlayBB.x, overlayBB.y - 30, "Pro license needed");
    }
}

/**
 * Reopens the data label menu with the latest data if settings are persisted.
 * The new menu is positioned at the same coordinates as the old menu.
 */
function reopenDataLabelMenu(overlay: HTMLElement): void {
    // if settings are persisted, we reopen the data label menu with the latest data
    let dataLabelMenu = <HTMLElement>overlay.querySelector(`.${DATA_LABEL_MENU}`);
    const dataLabelMenuBB = dataLabelMenu?.getBoundingClientRect();
    dataLabelMenu?.remove();
    const main = <HTMLElement>document.querySelector(`.${ZEBRABI_CHARTS_CONTAINER}`);
    dataLabelMenu = new DataLabelMenu().appendTo(main);

    dataLabelMenu.style.top = `${dataLabelMenuBB.top}px`;
    dataLabelMenu.style.left = `${dataLabelMenuBB.left}px`;
}

function toggleDataLabelMenu(overlay: HTMLElement): void {
    const main = <HTMLElement>document.querySelector(`.${ZEBRABI_CHARTS_CONTAINER}`);
    let dataLabelMenu = (<HTMLElement>d3.select(`.${DATA_LABEL_MENU}`)?.node());
    if (!dataLabelMenu) {
        overlay.classList.add(ACTIVE);
        dataLabelMenu = new DataLabelMenu().appendTo(main);
        calculateDataLabelMenuPosition(dataLabelMenu, overlay.getBoundingClientRect(), main.getBoundingClientRect());
        positionDataLabelMenuOnScroll(dataLabelMenu, overlay);
    } else {
        overlay.remove();
        dataLabelMenu.remove();
    }
}


/**
 * Calculate optimal position for the data label menu considering the
 * available spaces (above, below, left, and right)
 * @TODO Refactor/unify with all other settings.
 */
function calculateDataLabelMenuPosition(
    dataLabelMenu: HTMLElement,
    dataLabelBoundingRect: DOMRect,
    chartContainerBB: DOMRect
): void {
    if (!dataLabelMenu || !dataLabelBoundingRect || !chartContainerBB) {
        return;
    }

    // Spaces calculation
    const { x: labelX, y: labelY, height: labelHeight, width: labelWidth } = dataLabelBoundingRect;
    const { top: chartTop, bottom: chartBottom, left: chartLeft, right: chartRight, height: chartHeight } = chartContainerBB;
    const { clientHeight: menuHeight, clientWidth: menuWidth } = dataLabelMenu;

    const spaceAbove = labelY - chartTop;
    const spaceBelow = chartBottom - (labelY + labelHeight);
    const spaceLeft = labelX - chartLeft;
    const spaceRight = chartRight - (labelX + labelWidth);

    // Menu position determination
    let menuLeft = labelX;
    let menuTop = labelY;
    let newMenuHeight = menuHeight;
    let aboveBelowMenuPosition = false;
    const chartContainerMargin = 20;

    if (spaceBelow >= menuHeight || spaceAbove >= menuHeight) {
        aboveBelowMenuPosition = true;
        menuTop = spaceBelow >= menuHeight ? labelY + labelHeight : labelY - menuHeight;
        menuLeft = spaceRight <= menuWidth ? labelX + spaceRight - menuWidth : menuLeft;
    } else if (menuTop + menuHeight > chartHeight) {
        const offsetY = menuHeight - (chartHeight - chartContainerMargin - labelY);
        if (menuTop - offsetY > 0) {
            menuTop = menuTop - offsetY;
        } else {
            menuTop = 0;
            dataLabelMenu.style.overflowY = SCROLL;
            newMenuHeight = menuHeight - (menuHeight - chartHeight + chartContainerMargin);
            dataLabelMenu.style.height = `${newMenuHeight}px`;
        }
    }
    if (!aboveBelowMenuPosition && (spaceLeft >= menuWidth || spaceRight >= menuWidth)) {
        menuLeft = spaceLeft >= menuWidth ? labelX - menuWidth : labelX + labelWidth;
    }
    // Apply calculated styles to the menu
    dataLabelMenu.style.left = `${menuLeft}px`;
    dataLabelMenu.style.top = `${menuTop}px`;
}

function positionDataLabelMenuOnScroll(dataLabelMenu: HTMLElement, button: HTMLElement): void {
    const container = Visual.element.querySelector(`.${ZEBRABI_CHART_SVG_CONTAINER}`);
    const initialScrollTop = container.scrollTop;
    const menuTopOffset = parseInt(dataLabelMenu.style.top, 10);
    const buttonTopOffset = parseInt(button.style.top, 10);

    container.addEventListener(SCROLL, () => {
        const scrollTop = container.scrollTop;
        repositionElement(button, buttonTopOffset, null, scrollTop - initialScrollTop);
        repositionElement(dataLabelMenu, menuTopOffset, null, scrollTop - initialScrollTop);
    });
}

