import Layout from "./Layout";
import { ACTUAL_ABSOLUTE, ACTUAL_RELATIVE, ABSOLUTE_RELATIVE } from "./../consts";
import { ChartData, ChartLayout } from "./../interfaces";
import { MultiplesAxisLabelsOptions } from "../enums";

export class SmartRowsLayout extends Layout {

    public getChartLayouts(rowCount: number, maxChartsPerRow: number, height: number, visualTitleHeight: number, minChartHeight: number, minChartWidth: number, plotChartWidth: number,
        bottomMargin: number, topMargin: number, rowMargin: number, columnMargin: number, labelFontSize: number, xPosition: number, leftMargin: number): ChartLayout[] {

        const chartLayouts: ChartLayout[] = [];
        let rowHeights: number[] = [];
        const rowDataDiffs: number[] = [];
        const rowBottomMargins: number[] = [];
        const rowDataSpans: number[][] = [];
        const plotExtendedCharts = this.settings.shouldPlotExtendedChartsMultiples();
        let extendedRelativeChartSpan = 0;
        for (let i = 0; i < rowCount; i++) {
            const startIndex = i * maxChartsPerRow;
            const endIndex = Math.min(i * maxChartsPerRow + maxChartsPerRow, this.viewModel.chartData.length);
            const partition = this.viewModel.chartData.slice(startIndex, endIndex);
            const dataSpan = this.getMultipleDataSpan(partition);
            if (plotExtendedCharts) {
                if (this.settings.chartLayout === ACTUAL_ABSOLUTE) {
                    extendedRelativeChartSpan = dataSpan[3] - dataSpan[2];
                }
                else if (i === 0 && this.settings.chartLayout !== ACTUAL_ABSOLUTE) {
                    extendedRelativeChartSpan = (dataSpan[1] - dataSpan[0]);
                }
            }
            rowDataSpans.push(dataSpan);
            const diff = dataSpan[1] + extendedRelativeChartSpan - dataSpan[0];
            rowDataDiffs.push(diff);
            if (this.settings.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.AllCharts) {
                rowBottomMargins.push(dataSpan[0] < 0 ? bottomMargin + labelFontSize : bottomMargin);
            }
            else if (this.settings.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.FirstChart ||
                this.settings.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.FirstRow ||
                this.settings.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.FirstChartLastRow && rowCount > 1) {
                if (i === 0) {
                    rowBottomMargins.push(dataSpan[0] < 0 ? bottomMargin + labelFontSize : bottomMargin);
                }
                else {
                    rowBottomMargins.push(dataSpan[0] < 0 ? labelFontSize : 0);
                }
            }
            else {
                rowBottomMargins.push(dataSpan[0] < 0 ? bottomMargin + labelFontSize : bottomMargin);
            }
        }

        const extendedChartMargin = plotExtendedCharts && this.settings.chartLayout === ACTUAL_ABSOLUTE ? 3 * this.settings.labelFontSize : 0;
        const totalTopAndBottomMargins = rowBottomMargins.reduce((a, b) => a + b + topMargin + extendedChartMargin, 0);
        const totalDataDiffSum = rowDataDiffs.reduce((a, b) => a + b, 0);
        let scaleFactor = totalDataDiffSum === 0 ? 1 : (height - totalTopAndBottomMargins - rowMargin * (rowCount - 1)) / (totalDataDiffSum);
        rowHeights = rowDataDiffs.map((a, i) => a * scaleFactor + topMargin + rowBottomMargins[i] + extendedChartMargin);
        const maxRowDiff = Math.max(...rowDataDiffs);
        const maxRowHeight = Math.max(...rowHeights);
        if (rowCount > 1 && maxRowHeight < minChartHeight && totalDataDiffSum !== 0) {
            //Improve?: always use avg bottomMargin
            const avgBottomMargin = this.settings.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.AllCharts ? bottomMargin :
                rowBottomMargins.reduce((a, b) => a + b) / rowBottomMargins.length;
            scaleFactor = (minChartHeight - (topMargin + avgBottomMargin + extendedChartMargin)) / maxRowDiff;
            rowHeights = rowDataDiffs.map((a, i) => a * scaleFactor + topMargin + rowBottomMargins[i] + extendedChartMargin);
        }

        let currentPositionX = leftMargin + xPosition;
        let currentPositionY = visualTitleHeight;
        let currentRowIndex = 0;
        let currentColIndex = 0;
        for (let i = 0, len = this.viewModel.chartData.length; i < len; i++) {
            let currentRowHeight = rowHeights[currentRowIndex];
            if (rowCount > 0 && i > 0 && i % maxChartsPerRow === 0) {
                currentRowIndex++;
                currentColIndex = 0;
                currentPositionX = leftMargin + xPosition;
                currentPositionY += currentRowHeight + rowMargin;
                currentRowHeight = rowHeights[currentRowIndex];
            }
            else {
                currentColIndex = i % maxChartsPerRow;
            }

            chartLayouts.push({
                height: currentRowHeight,
                width: currentColIndex === 0 ? plotChartWidth + xPosition : plotChartWidth,
                position: {
                    x: currentColIndex === 0 ? leftMargin : currentPositionX,
                    y: currentPositionY,
                },
                columnIndex: currentColIndex,
                rowIndex: currentRowIndex,
                min: rowDataSpans[currentRowIndex][0],
                max: rowDataSpans[currentRowIndex][1],
                extendedChartMin: plotExtendedCharts ? this.getExtendedChartMin(this.viewModel.chartData, rowDataSpans[currentRowIndex]) : null,
                extendedChartMax: plotExtendedCharts ? this.getExtendedChartMax(this.viewModel.chartData, rowDataSpans[currentRowIndex]) : null,
                extendedRelativeChartHeight: plotExtendedCharts && this.settings.chartLayout !== ACTUAL_ABSOLUTE ? 0.33 * rowHeights[0] : null,
                bottomMargin: rowBottomMargins[currentRowIndex],
            });
            currentPositionX += plotChartWidth + columnMargin;
        }
        return chartLayouts;
    }

    private getMultipleDataSpan(chartData: ChartData[]): number[] {
        let min = 0;
        let max = 0;
        let extendedChartMin = 0;
        let extendedChartMax = 0;

        for (let i = 0; i < chartData.length; i++) {
            const chartDataMin = chartData[i].min;
            const chartDataMax = chartData[i].max;

            if (this.settings.chartLayout === ACTUAL_ABSOLUTE) {
                extendedChartMin = Math.min(extendedChartMin, chartData[i].minVariance);
                extendedChartMax = Math.max(extendedChartMax, chartData[i].maxVariance);
            }
            else if (this.settings.chartLayout === ACTUAL_RELATIVE) {
                extendedChartMin = Math.min(extendedChartMin, chartData[i].minOutlierValue);
                extendedChartMax = Math.max(extendedChartMax, chartData[i].maxOutlierValue);
            }
            else if (this.settings.chartLayout === ABSOLUTE_RELATIVE) {
                extendedChartMin = Math.min(extendedChartMin, chartData[i].minOutlierValue);
                extendedChartMax = Math.max(extendedChartMax, chartData[i].maxOutlierValue);
            }

            if (chartDataMin < min) {
                min = chartDataMin;
            }
            if (chartDataMax > max) {
                max = chartDataMax;
            }
        }

        return [min, max, extendedChartMin, extendedChartMax];
    }

    private getExtendedChartMin(chartData: ChartData[], rowDataSpans: number[]): number {
        if (this.settings.chartLayout === ACTUAL_ABSOLUTE) {
            return rowDataSpans[2];
        }
        else {
            return Math.min(0, ...chartData.map(cd => cd.minOutlierValue));
        }
    }

    private getExtendedChartMax(chartData: ChartData[], rowDataSpans: number[]): number {
        if (this.settings.chartLayout === ACTUAL_ABSOLUTE) {
            return rowDataSpans[3];
        }
        else {
            return Math.max(0, ...chartData.map(cd => cd.maxOutlierValue));
        }
    }
}
