
// License visual types
export const VISUAL_TYPE_POWER_CHARTS = "PowerCharts";
export const VISUAL_TYPE_POWER_TABLES = "PowerTables";
export const VISUAL_TYPE_ALL = "All";

export const WATERMARK_TEXT = "Zebra BI";
export const WATERMARK_HOVER_TEXT = "Zebra BI visuals for Power BI";
// Layout settings
export const GROUP_HEADER_FONT_SIZE = 15;
export const END_MARKER_SIZE_CIRCLE = 4;
export const INNER_MARKER_SIZE_CIRCLE = 2.5;
export const END_MARKER_SIZE_SQUARE = 8;
export const INNER_MARKER_SIZE_SQUARE = 6;
export const DR_HICHERT_SQUARE_MARKER_WIDTH = 8;

export const BOTTOM_SCROLLBAR_MARGIN = 25;
export const RIGHT_SCROLLBAR_MARGIN = 20;

export const PIN_CHART_RECT_WIDTH = 1; // seems as a line, but it is drawn as a rectangle (x, y, width, height)
export const PLUS_MINUS_DOT_RECT_WIDTH = 3;

// Special characters
export const ARROW_UP: string = "\u25B2";
export const ARROW_DOWN: string = "\u25BC";
export const ARROW_UP_SLIM: string = "\u2191";
export const ARROW_DOWN_SLIM: string = "\u2193";
export const EMPTY = "";
export const LINE_BREAK = "\n";
export const TOTAL_VARIANCE_SEPARATOR = "|";

// Column headers
export const DELTA = "Δ";
export const PERCENT = "%";
export const DELTA_PL = "ΔPL";
export const DELTA_PY = "ΔPY";
export const DELTA_AC = "ΔAC";
export const DELTA_FC = "ΔFC";
export const DELTA_PL_PERCENT = "ΔPL%";
export const DELTA_PY_PERCENT = "ΔPY%";
export const DELTA_AC_PERCENT = "ΔAC%";
export const DELTA_FC_PERCENT = "ΔFC%";
export const AC = "AC";
export const FC = "FC";
export const PL = "PL";
export const PY = "PY";
export const SCENARIO_ENUMS = [AC, PY, PL, FC];
export const SCENARIO_FULL_STRINGS = ["Actual", "Previous year", "Plan", "Forecast"];
export const YTD = "YTD";
export const TOTAL = "Total";


// D3 elements and classes
export const GRIDLINE = "gridline";
export const DEBUG = "debug";
export const BAR = "bars";
export const CIRCLE = "circle";
export const ELLIPSE = "ellipse";
export const SQUARE = "square";
export const RECT = "rect";
export const RECTANGLE = "rectangle";
export const TEXT = "text";
export const LINE = "line";
export const POLYLINE = "polyline";
export const POINTS = "points";
export const MARKER = "marker";
export const LABEL = "label";
export const VARIANCE = "variance";
export const CATEGORY = "category";
export const FORMULA_CATEGORY = "formula-category";
export const FORMULA_ELEMENT = "formula-element";
export const AXIS = "axis";
export const CHART_HEADER = "chartHeader";
export const COLUMN_HEADER = "columnHeader";
export const MULTIPLES_SORT = "multiples-sort";
export const GROUP_HEADER = "groupHeader";
export const BACKGROUND = "background";
export const BACKGROUND_COLOR = "background-color";
export const HIGHLIGHTABLE = "highlightable";
export const CLICKABLE = "clickable";
export const TOOLTIP = "tooltip";
export const TITLE = "title";
export const REPORT_AREA = "reportArea";
export const CHART_AREA = "chartArea";
export const ANALYTICS_AREA = "analyticsArea";
export const GROUP_HEADER_AREA = "groupHeaderArea";
export const CATEGORIES_AREA = "categoriesArea";
export const WATERMARK = "watermark";
export const ELASTIC = "elastic";
export const TOGGLE = "toggle";
export const FOR = "for";
export const ID = "id";
export const NAME = "name";
export const CLASS = "class";
export const LIGHT = "light";
export const DARK = "dark";
export const DIV = "div";
export const ASIDE = "aside";
export const IMG = "img";
export const BUTTON = "button";
export const SELECT = "select";
export const OPTION = "option";
export const DEFS = "defs";
export const P = "p";
export const SPAN = "span";
export const A = "a";
export const G = "g";
export const UL = "ul";
export const LI = "li";
export const BODY = "body";
export const PATH = "path";
export const PATTERNED = "patterned";
export const INPUT = "input";
export const TYPE = "type";
export const CHECKBOX = "checkbox";
export const CHECKED = "checked";
export const DRAG_TARGET = "drag-target";
export const CONTEXT_MENU_DIV = "context-menu";
export const CONTEXT_MENU2_DIV = "context-menu2";
export const FILTER = "filter";
export const IN = "in";
export const COMMENT_MARKER = "comment-marker";
export const DROPLINE = "dropline";
export const HIGHLIGHT_DROPLINE = "highlight-dropline";
export const DROPLINE_MARKER = "dropline_marker";


// Sort options
export const VALUE_SORT = "value";
export const REFERENCE_SORT = "referenceValue";
export const SECOND_REFERENCE_SORT = "secondReferenceValue";
export const THIRD_REFERENCE_SORT = "thirdReferenceValue";
export const ABSOLUTE_SORT = "absoluteDifference";
export const THIRD_ABSOLUTE_SORT = "thirdAbsoluteDifference";
export const SECOND_ABSOLUTE_SORT = "secondAbsoluteDifference";
export const RELATIVE_SORT = "relativeDifference";
export const SECOND_RELATIVE_SORT = "secondRelativeDifference";
export const THIRD_RELATIVE_SORT = "thirdRelativeDifference";
export const ADDITIONAL_MEASURE1_SORT = "additionalMeasure1";
export const ADDITIONAL_MEASURE2_SORT = "additionalMeasure2";
export const ADDITIONAL_MEASURE3_SORT = "additionalMeasure3";
export const ADDITIONAL_MEASURE4_SORT = "additionalMeasure4";
export const ADDITIONAL_MEASURE5_SORT = "additionalMeasure5";
export const ADDITIONAL_MEASURE6_SORT = "additionalMeasure6";
export const ADDITIONAL_MEASURE7_SORT = "additionalMeasure7";
export const ADDITIONAL_MEASURE8_SORT = "additionalMeasure8";
export const ADDITIONAL_MEASURE9_SORT = "additionalMeasure9";
export const ADDITIONAL_MEASURE10_SORT = "additionalMeasure10";
export const ADDITIONAL_MEASURE11_SORT = "additionalMeasure11";
export const ADDITIONAL_MEASURE12_SORT = "additionalMeasure12";
export const ADDITIONAL_MEASURE13_SORT = "additionalMeasure13";
export const ADDITIONAL_MEASURE14_SORT = "additionalMeasure14";
export const ADDITIONAL_MEASURE15_SORT = "additionalMeasure15";
export const ADDITIONAL_MEASURE16_SORT = "additionalMeasure16";
export const ADDITIONAL_MEASURE17_SORT = "additionalMeasure17";
export const ADDITIONAL_MEASURE18_SORT = "additionalMeasure18";
export const ADDITIONAL_MEASURE19_SORT = "additionalMeasure19";
export const ADDITIONAL_MEASURE20_SORT = "additionalMeasure20";

// Colors
export const YELLOW = "#F2C811";
export const GREY_TEXT = "#A6A6A6";
export const DARK_GREY_TEXT = "#555";
export const GRIDLINE_COLOR = "#b0b0b0";
export const MAJOR_GRIDLINE_COLOR = "#999";
export const BLACK = "#000";
export const WHITE = "#fff";
export const TRANSPARENT = "#ffffff00";
export const GRAY = "#808080";
export const LIGHTGRAY = "#D3D3D3";
export const ORANGE = "#ffa500";
export const NEUTRAL = "neutral";
export const POSITIVE = "positive";
export const NEGATIVE = "negative";
export const HIGHLIGHT_COLOR = "#0070C0";
export const OUTLINE_COLOR = "#0078D4";

export const LICENSE_TYPE = "TYPE_OF_LICENSE";
export const WP_USERID = "USER_ID";

export const EXPIRY_DATE_STRING = "6da6f0b95ae044938b93fd0a87c858ef";
export const COMPANY_STRING = "4d9aafcc5af345ccbe588445508b5e8a";

export const CUSTOMER_STYLE_VISIBLE = "COMPANY_STYLE_VISIBLE";
export const CUSTOMER_NEUTRAL_COLOR = "COMPANY_NEUTRAL_COLOR";
export const CUSTOMER_POSITIVE_COLOR = "COMPANY_POSITIVE_COLOR";
export const CUSTOMER_NEGATIVE_COLOR = "COMPANY_NEGATIVE_COLOR";
export const CUSTOMER_LINE_COLOR = "COMPANY_LINE_COLOR";
export const CUSTOMER_MARKER_COLOR = "COMPANY_MARKER_COLOR";
export const CUSTOMER_AXIS_COLOR = "COMPANY_AXIS_COLOR";
export const CUSTOMER_GRIDLINE_COLOR = "#b0b0b0";
export const CUSTOMER_DOT_CHART_COLOR = "#4080FF";
export const CUSTOMER_MORE_COLORS = "COMPANY_MORE_COLORS";
export const CUSTOMER_PY_COLOR = "COMPANY_PY_COLOR";
export const CUSTOMER_FC_COLOR = "COMPANY_FC_COLOR";
export const CUSTOMER_PL_COLOR = "COMPANY_PL_COLOR";
export const CUSTOMER_APPLY_PATTERNS = "COMPANY_APPLY_PATTERNS";

export const CUSTOMER_TITLE_FONT_FAMILY = "COMPANY_TITLE_FONT_FAMILY";
export const CUSTOMER_TITLE_FONT_SIZE = "COMPANY_TITLE_FONT_SIZE";
export const CUSTOMER_TITLE_FONT_COLOR = "COMPANY_TITLE_FONT_COLOR";
export const CUSTOMER_GROUP_TITLE_FONT_FAMILY = "COMPANY_GROUP_TITLE_FONT_FAMILY";
export const CUSTOMER_GROUP_TITLE_FONT_SIZE = "COMPANY_GROUP_TITLE_FONT_SIZE";
export const CUSTOMER_GROUP_TITLE_FONT_COLOR = "COMPANY_GROUP_TITLE_FONT_COLOR";
export const CUSTOMER_DATA_LABELS_FONT_FAMILY = "COMPANY_DATA_LABELS_FONT_FAMILY";
export const CUSTOMER_DATA_LABELS_FONT_SIZE = "COMPANY_DATA_LABELS_FONT_SIZE";
export const CUSTOMER_DATA_LABELS_FONT_COLOR = "COMPANY_DATA_LABELS_FONT_COLOR";
export const CUSTOMER_CATEGORY_LABELS_FONT_FAMILY = "COMPANY_CATEGORY_LABELS_FONT_FAMILY";
export const CUSTOMER_CATEGORY_LABELS_FONT_SIZE = "COMPANY_CATEGORY_LABELS_FONT_SIZE";
export const CUSTOMER_CATEGORY_LABELS_FONT_COLOR = "COMPANY_CATEGORY_LABELS_FONT_COLOR";
export const CUSTOMER_CATEGORY_LABELS_FONT_SETTINGS = "COMPANY_CATEGORY_LABELS_FONT_SETTINGS";

// Font attributes and values
export const FONT_FAMILY = "font-family";
export const FONT_SIZE = "font-size";
export const FONT_STYLE = "font-style";
export const FONT_WEIGHT = "font-weight";
export const BOLD = "bold";
export const NORMAL = "normal";
export const ITALIC = "italic";
export const TEXT_ANCHOR = "text-anchor";
export const SUBSTITUTE_FONT = "helvetica, arial, sans-serif";
export const DEFAULT_FONT = `Calibri, ${SUBSTITUTE_FONT}`;
export const DEFAULT_FONT_SIZE = 10;
export const SEGOE_UI = `'Segoe UI', wf_segoe-ui_normal, ${SUBSTITUTE_FONT}`;
export const SEGOE_UI_LIGHT = `'Segoe UI Light', wf_segoe-ui_light, ${SUBSTITUTE_FONT}`;
export const SEGOE_UI_SEMIBOLD = `'Segoe UI Semibold', wf_segoe-ui_semibold, ${SUBSTITUTE_FONT}`;
export const SEGOE_UI_BOLD = `'Segoe UI Bold', wf_segoe-ui_bold, ${SUBSTITUTE_FONT}`;

// CSS atributes and values
export const NONE = "none";
export const HIDDEN = "hidden";
export const SCROLL = "scroll";
export const INITIAL = "initial";
export const DISPLAY = "display";
export const VISIBILITY = "visibility";
export const VISIBLE = "visible";
export const TOP = "top";
export const BOTTOM = "bottom";
export const LEFT = "left";
export const RIGHT = "right";
export const CENTER = "center";
export const BLOCK = "block";
export const INLINE_BLOCK = "inline-block";
export const FLEX = "flex";
export const FLEX_DIRECTION = "flex-direction";
export const FLEX_END = "flex-end";
export const FLEX_START = "flex-start";
export const OVERFLOW = "overflow";
export const OVERFLOW_X = "overflow-x";
export const OVERFLOW_Y = "overflow-y";
export const MARGIN = "margin";
export const MARGIN_TOP = "margin-top";
export const MARGIN_LEFT = "margin-left";
export const MARGIN_BOTTOM = "margin-bottom";
export const MARGIN_RIGHT = "margin-right";
export const POSITION = "position";
export const TEXT_ALIGN = "text-align";
export const Z_INDEX = "z-index";
export const WHITE_SPACE = "white-space";
export const FLOAT = "float";
export const BOX_SHADOW = "box-shadow";
export const ALIGN_ITEMS = "align-items";
export const LINE_HEIGHT = "line-height";
export const BORDER_BOTTOM = "border-bottom";
export const COLOR = "color";
export const BORDER_RADIUS = "border-radius";
export const BOX_SIZING = "box-sizing";
export const BORDER_BOX = "border-box";
export const JUSTIFY_CONTENT = "justify-content";
export const FIT_CONTENT = "fit-content";
export const PADDING = "padding";
export const PADDING_TOP = "padding-top";
export const PADDING_LEFT = "padding-left";
export const PADDING_RIGHT = "padding-right";
export const OFFSET = "offset";
export const STOP_COLOR = "stop-color";
export const STATIC = "static";
export const ROW = "row";
export const ROW_REVERSE = "row-reverse";
export const COLUMN = "column";
export const COLUMN_REVERSE = "column-reverse";
export const SOLID = "solid";

// Other d3 attributes and values
export const STROKE = "stroke";
export const STROKE_WIDTH = "stroke-width";
export const STROKE_LINEJOIN = "stroke-linejoin";
export const STROKE_LINECAP = "stroke-linecap";
export const STROKE_DASHARRAY = "stroke-dasharray";
export const STROKE_OPACITY = "stroke-opacity";
export const ROUND = "round";
export const MIDDLE = "middle";
export const CENTRAL = "central";
export const END = "end";
export const START = "start";
export const X = "x";
export const Y = "y";
export const CX = "cx";
export const CY = "cy";
export const R = "r";
export const RX = "rx";
export const RY = "ry";
export const WIDTH = "width";
export const HEIGHT = "height";
export const OPACITY = "opacity";
export const POINTER_EVENTS = "pointer-events";
export const FILL = "fill";
export const FILL_OPACITY = "fill-opacity";
export const PX = "px";
export const PT = "pt";
export const FONT_SIZE_UNIT = PT;

export const TRANSFORM = "transform";
export const DOTTED_DASHARRAY = "0.15,5";
export const DASHED_DASHARRAY = "8,4";
export const AUTO = "auto";
export const TSPAN = "tspan";
export const DX = "dx";
export const DY = "dy";
export const X1 = "x1";
export const X2 = "x2";
export const Y1 = "y1";
export const Y2 = "y2";
export const VALUE = "value";
export const STOP_OPACITY = "stop-opacity";
export const VIEWBOX = "viewBox";
export const MIN = "min";
export const MAX = "max";
export const STEP = "step";
export const SELECTED = "selected";

// Capabilities
export const PRO_FEATURES_SETTINGS = "proFeaturesSettings";
export const CHART_SETTINGS = "chartSettings";
export const COMMENT_BOX_SETTINGS = "commentBoxSettings";
export const DESIGN_SETTINGS = "designSettings";
export const TITLE_SETTINGS = "titleSettings";
export const GROUP_TITLE_SETTINGS = "groupTitleSettings";
export const DATA_LABEL_SETTINGS = "dataLabelSettings";
export const DOT_CHART_DATA_LABEL_SETTINGS = "dotChartDataLabelSettings";
export const DIFFERENCE_HIGHLIGHT_SETTINGS = "differenceHighlightSettings";
export const SHOW_DIFF_HIGHLIGHT_SUBTOTALS = "showDifferenceHighlightSubtotals";
export const LICENSE_SETTINGS = "licenseSettings";
export const TOOLTIP_SETTINGS = "tooltipSettings";
export const INTERACTION_SETTINGS = "interactionSettings";
export const CATEGORIES_METADATA = "categoriesMetadata";
export const GROUPS_METADATA = "groupsMetadata";
export const LEGEND_HEADER_SETTINGS = "legendHeaderSettings";
export const CATEGORY_SETTINGS = "categorySettings";
export const AVERAGE_LINE_SETTINGS = "averageLineSettings";
export const CONSTANT_LINE_SETTINGS = "constantLineSettings";
export const MEDIAN_LINE_SETTINGS = "medianLineSettings";
export const PERCENTILE_LINE_SETTINGS = "percentileLineSettings";

// Class selectors
export const COLUMN_SETTINGS_DIV = "column-settings";
export const COLUMN_ADDER_LIST = "column-adder-list";
export const COLUMN_REMOVE_LIST = "column-remove-list";
export const ADDITIONAL_MEASURES_SETTINGS = "additionalMeasuresSettings";
export const FROZEN_CATEGORIES = "frozen-categories";

// Events
export const MOUSEOVER = "mouseover";
export const MOUSEOUT = "mouseout";
export const MOUSEMOVE = "mousemove";
export const MOUSEENTER = "mouseenter";
export const MOUSELEAVE = "mouseleave";
export const CLICK = "click";
export const CLEAR = "clear";
export const KEYUP = "keyup";
export const HIDE = "hide";
export const DRAG = "drag";
export const DRAGSTART = "start";
export const DRAGEND = "end";
export const CONTEXT_MENU = "contextmenu";
export const CHANGE = "change";
export const ENTER = 13;
export const SAVE = "save";
export const SHOW = "show";

// Headers
export const VALUE_HEADER = "valueHeader";
export const SECOND_VALUE_HEADER = "secondValueHeader";
export const REFERENCE_HEADER = "referenceHeader";
export const SECOND_REFERENCE_HEADER = "secondReferenceHeader";
export const THIRD_REFERENCE_HEADER = "thirdReferenceHeader";
export const ABSOLUTE_DIFFERENCE_HEADER = "absoluteDifferenceHeader";
export const SECOND_ABSOLUTE_DIFFERENCE_HEADER = "secondAbsoluteDifferenceHeader";
export const THIRD_ABSOLUTE_DIFFERENCE_HEADER = "thirdAbsoluteDifferenceHeader";
export const RELATIVE_DIFFERENCE_HEADER = "relativeDifferenceHeader";
export const SECOND_RELATIVE_DIFFERENCE_HEADER = "secondRelativeDifferenceHeader";
export const THIRD_RELATIVE_DIFFERENCE_HEADER = "thirdRelativeDifferenceHeader";

// Scenarios
export const VALUE_SCENARIO = "value";
export const REFERENCE_SCENARIO = "reference";
export const SECOND_VALUE_SCENARIO = "secondValue";
export const SECOND_REFERENCE_SCENARIO = "secondReference";
export const THIRD_VALUE_SCENARIO = "thirdValue";
export const THIRD_REFERENCE_SCENARIO = "thirdReference";
export const AXIS_SCENARIO_DELIMITER = "axisScenarioDelimiter";

// Element classes
export const INDICATOR = "indicator";
export const CONNECTING = "connecting";
export const SUBTOTAL = "subtotal";
export const HIERARCHY = "hierarchy";
export const HEADER = "header";
export const HEADER_ROW = "header-row";
export const HEADER_RECTANGLE = "header-rectangle";
export const HEADER_LABEL_RECTANGLE = "header-label-rectangle";
export const HEADER_DROPDOWN_SETTINGS_ARROW = "drop-down-settings-arrow";
export const COLLAPSE_RECTANGLE = "collapse-rectangle";
export const TOP_N_RECTANGLE = "topn-rectangle";
export const TOP_N_ARROW = "topn-arrow";
export const COLLAPSE_ARROW_ICON = "expand-arrow-icon";
export const TITLE_ARROW_ICON = "title-arrow-icon";
export const DROP_DOWN_ARROW = "drop-down-arrow";
export const SETTINGS_RECTANGLE = "settings-rectangle";
export const SETTINGS_ICON = "settings-icon";
export const GRAND_TOTAL = "grand-total";
export const FLAT_RESULTS = "flat-results";
export const BOTTOM_SHADOW = "bottom-shadow";
export const RIGHT_SHADOW = "right-shadow";
export const BOTH_SHADOW = "both-shadow";
export const ZOOM_VALUE_POPUP = "zoom-value-popup";
export const HEADER_TOOLTIP = "header-tooltip";
export const OVERLAY = "overlay";

// Custom attributes
export const DATA_SCALE_GROUP = "dataScaleGroup";
export const DATA_PROPERTY = "dataProperty";
export const D = "d";

// Analytics labels
export const AVERAGE = "Avg.";
export const CONSTANT = "Const.";
export const PERCENTILE = "Perc.";
export const MEDIAN = "Med.";

// Classes for tests (`t-` prefix)
export const GRIDLINE_TEST_CLASS = "t-grid";

// Info Box and comment box
export const ZEBRABI_TABLE_CONTAINER = "zebrabi-table-container";
export const ZEBRABI_CHART_SVG_CONTAINER = "zebrabi-chart-svg-container";
export const ZEBRABI_CHARTS_VISUAL = "zebrabi-charts-visual";
export const ZEBRABI_REPORT_GROUP = "report-group";


export const INFO_BOX_CONTAINER = "zebrabi-info-container";
export const COMMENT_BOX_RESIZE_LINE = "comment-box-resize-line";
export const COMMENT_BOX_SHADOW_DEFAULT = false;
export const COMMENT_BOX_MARGIN_DEFAULT = 10;
export const COMMENT_BOX_PADDING_DEFAULT = 10;
export const FREE_MODE_COMMENT_LIMIT = 3;

export const ICON_MARKER_DISTANCE: number = 25;

export const VISUAL_UPDATE_TYPE_RESIZE_END = 36;    // VisualUpdateType.ResizeEnd (32) should be used but it appears that the real value for ResizeEnd is 36 (for now?)

// Legend settings
export const LEGEND_OUTLINE_ACTIVE = "legend-active";
export const CHART_LEGEND_SETTINGS = "chart-legend-settings";
export const CHART_GLOBAL_LEGEND_MENU = "chart-global-legend-menu";
export const GLOBAL_LEGEND = "global-legend";

// Global stacked chart
export const GLOBAL_STACKED_CHART = "global-stacked-chart";

// Global category
export const GLOBAL_CATEGORY = "global-category";

// Difference highlight settings
export const DIFFERENCE_HIGHLIGHT_OUTLINE_ACTIVE = "difference-highlight-active";
export const CHART_DIFFERENCE_HIGHLIGHT_SETTINGS = "chart-difference-highlight-settings";
export const CHART_DIFFERENCE_HIGHLIGHT_OUTLINE = "chart-difference-highlight-outline";
export const DIFFERENCE_HIGHLIGHT_MENU = "difference-highlight-menu";
export const DIFFERENCE_HIGHLIGHT = "diffHighlight";
export const ZEBRABI_CHARTS_CONTAINER = "zebrabi-charts-container";

// Design library components actions
export const SWITCH_CHANGE = "switch-change";
export const RADIO_CHANGE = "radio-change";

export const SAVE_COLOR = "save-color";
export const CHANGE_COLOR = "change-color";
export const CLEAR_COLOR = "clear-color";

export enum ChartStyle {
    Company = -1,
    Zebra = 0,
    ZebraLight = 1,
    DrHichert = 2,
    PowerBI = 3,
    Custom = 4,
    ColorblindSafe = 5,
}

export enum Scenario {
    Actual = 0,
    PreviousYear = 1,
    Plan = 2,
    Forecast = 3,
}

export enum VarianceDisplayType {
    Bar = 0,
    Chevron = 1
}

export enum MarkerStyle {
    Actual = 0,
    PreviousYear = 1,
    Plan = 2,
    Forecast = 3,
    Gridline = 4,
    None = 5,
}

export enum CategoryDisplayOptions {
    Auto = 0,
    Full = 1,
    FixedWidth = 2,
}

export enum GroupTitleDisplayOptions {
    Auto = 0,
    SizeToData = 1,
    FixedWidth = 2,
}

export enum CategoryRowHeight {
    Auto = 0,
    FontSized = 1,
    FontSized_1_1 = 2,
    FontSized_1_5 = 3,
    Stretch = 4,
    Fixed = 5,
}

export enum CategoryWidthOptions {
    Auto = 0,
    Fixed = 1,
}

export enum CategoryLabelsOptions {
    Trim = 0,
    Rotate = 1,
}

export enum MarkerShape {
    Square = 0,
    Circle = 1,
}

export enum DifferenceLabel {
    Relative,
    Absolute,
    RelativeAndAbsolute,
}

export enum ShowTotals {
    Above = 0,
    Below = 1,
    AboveHideValues = 2,
}

export enum HorizontalLabelPostion {
    Left = 0,
    Right = 1,
}

export enum VerticalLabelPosition {
    Above = 0,
    Below = 1,
}

export enum LabelTextOption {
    NameAndValue = 0,
    Value = 1,
    Name = 2,
}

export const NumberOfAdditionalMeasures = 20;
export const NumberOfDataProperties = 35;
export const NumberOfTooltipMeasures = 5;
export enum DataProperty {
    Value = 0,
    ReferenceValue = 1,
    AbsoluteDifference = 2,
    RelativeDifference = 3,
    SecondReferenceValue = 4,
    SecondAbsoluteDifference = 5,
    SecondRelativeDifference = 6,
    AdditionalMeasure1 = 7,
    AdditionalMeasure2 = 8,
    AdditionalMeasure3 = 9,
    AdditionalMeasure4 = 10,
    AdditionalMeasure5 = 11,
    AdditionalMeasure6 = 12,
    AdditionalMeasure7 = 13,
    AdditionalMeasure8 = 14,
    AdditionalMeasure9 = 15,
    AdditionalMeasure10 = 16,
    ThirdReferenceValue = 17,
    ThirdAbsoluteDifference = 18,
    ThirdRelativeDifference = 19,
    AdditionalMeasure11 = 20,
    AdditionalMeasure12 = 21,
    AdditionalMeasure13 = 22,
    AdditionalMeasure14 = 23,
    AdditionalMeasure15 = 24,
    AdditionalMeasure16 = 25,
    AdditionalMeasure17 = 26,
    AdditionalMeasure18 = 27,
    AdditionalMeasure19 = 28,
    AdditionalMeasure20 = 29,
    TooltipsMeasure1 = 30,
    TooltipsMeasure2 = 31,
    TooltipsMeasure3 = 32,
    TooltipsMeasure4 = 33,
    TooltipsMeasure5 = 34
}

export enum ColumnFormat {
    Value = 0,
    AbsoluteDifference = 1,
    RelativeDifference = 2,
    Percent = 3,
    NotSet = 4,
}

export enum DataLabelUnitOptions {
    DataLabels,
    Title,
    None,
}

export enum LogType {
    MinsAndMaxes,
    Timings,
    ViewModel,
    DisplayBounds,
    Dimensions,
}

export const LOG_LEVEL = [];

export enum BorderType {
    Header = 0,
    Body = 1,
    GrandTotal = 2,
}

export enum ShowAsTableOptions {
    NotSet = 0,
    True = 1,
    False = 2,
}

export enum TopNType {
    Off = 0,
    Top = 1,
    Bottom = 2,
    TopAndBottom = 3,
}

export enum CommentBoxPlacement {
    Right = 0,
    Left = 1,
    Above = 2,
    Below = 3
}

export enum CommentBoxTitle {
    Off = 0,
    Title = 1,
    TitleValue = 2,
    TitleValueVariance = 3
}

export enum CommentBoxVariance {
    AbsoluteVariance = 0,
    RelativeVariance = 1,
    AbsoluteAndRelativeVariance = 2
}

export enum VarianceIcon {
    Circle = 0,
    CircleWithArrow = 1,
    Triangle = 2
}

// All PBI Fonts
export const fontFamilies = [
    { label: "Arial", value: "Arial" },
    { label: "Arial Black", value: "Arial Black" },
    { label: "Arial Unicode MS", value: "Arial Unicode MS" },
    { label: "Calibri", value: DEFAULT_FONT },
    { label: "Cambria", value: "Cambria" },
    { label: "Cambria Math", value: "Cambria Math" },
    { label: "Candara", value: "Candara" },
    { label: "Comic Sans MS", value: "Comic Sans MS" },
    { label: "Consolas", value: "Consolas, monospace" },
    { label: "Constantia", value: "Constantia" },
    { label: "Corbel", value: "Corbel" },
    { label: "Courier New", value: "Courier New" },
    { label: "DIN", value: `wf_standard-font, ${SUBSTITUTE_FONT}` },
    { label: "DIN Light", value: `wf_standard-font_light, ${SUBSTITUTE_FONT}` },
    { label: "Georgia", value: "Georgia" },
    { label: "Lucida Sans Unicode", value: "Lucida Sans Unicode" },
    { label: "Segoe UI", value: SEGOE_UI },
    { label: "Segoe UI Light", value: SEGOE_UI_LIGHT },
    { label: "Segoe UI Semibold", value: SEGOE_UI_SEMIBOLD },
    { label: "Segoe UI Bold", value: SEGOE_UI_BOLD },
    { label: "Symbol", value: "Symbol" },
    { label: "Tahoma", value: "Tahoma" },
    { label: "Times New Roman", value: "Times New Roman" },
    { label: "Trebuchet MS", value: "Trebuchet MS" },
    { label: "Verdana", value: "Verdana" },
    { label: "Wingdings", value: "Wingdings" }
];

// Styles
export const FIXED = "fixed";
export const OUTLINE_BORDER_RADIUS = "2px";

// Active class
export const ACTIVE = "active";

// Reset class
export const RESET = "reset";

// Category display options
export const CATEGORY_DISPLAY_OPTION_AUTO = "Auto";
export const CATEGORY_DISPLAY_OPTION_FULL = "Full";
export const CATEGORY_DISPLAY_OPTION_FIXED_WIDTH = "Fixed width";

// Category width options
export const CATEGORY_WIDTH_OPTION_FIXED = "Fixed";
export const CATEGORY_WIDTH_OPTION_AUTO = "Auto";

// Category label options
export const CATEGORY_LABEL_OPTION_TRIM = "Trim";
export const CATEGORY_LABEL_OPTION_ROTATE = "Rotate";

// Category axis label density
export const CATEGORY_AXIS_LABEL_DENSITY_ALL = "All";
export const CATEGORY_AXIS_LABEL_DENSITY_FIRST_LAST = "First and last";
export const CATEGORY_AXIS_LABEL_DENSITY_EVERY_NTH = "Every N-th";

// Top N chart options
export const TOP_N_OPTION_OFF = "Off";
export const TOP_N_OPTION_ITEMS = "Items";
export const TOP_N_OPTION_PERCENTAGE = "Percentage";

// Stacked chart options
export const STACKED_CHART_SORT_OPTION_DESCENDING = "Descending";
export const STACKED_CHART_SORT_OPTION_ASCENDING = "Ascending";
export const STACKED_CHART_SORT_OPTION_ORIGINAL_ORDER = "Original order";

// Data labels
export const DATA_LABEL_MENU = "data-label-menu";

