
import { valueFormatter } from "powerbi-visuals-utils-formattingutils";
import { Visual } from "../visual";

const BILLION = 1e9;
const MILLION = 1e6;
const THOUSAND = 1e3;
const ONE = 1;

export function getFormattedTooltipMeasureLabel(value: number | string, format: string, isDate: boolean): string {
    if (typeof value === "number") {
        const formatter = valueFormatter.create({ format: format });
        return formatter.format(value);
    }
    else if (isDate) {
        return fixDateTime(new Date(value), format, undefined);
    }
    else {
        return value;
    }
}

export function getDataLabel(value: number, decimalPlaces: number, displayUnits: string, locale: string, percentageFormat?: string, hideUnits: boolean = false): string {
    if (value == null) {
        return "";
    }
    let format = "#,0";
    if (percentageFormat && !hideUnits) {
        format = percentageFormat;
    }
    if (percentageFormat && percentageFormat.indexOf("%") === -1) {
        value = value * 100;
    }
    let formatter: valueFormatter.IValueFormatter;

    switch (displayUnits) {
        case "Auto": {
            const v = Math.abs(value);
            if (v > BILLION) {
                formatter = getFormatterFromMap(decimalPlaces, BILLION, locale, format);
                break;
            }
            else if (v > MILLION) {
                formatter = getFormatterFromMap(decimalPlaces, MILLION, locale, format);
                break;
            }
            else if (v > THOUSAND) {
                formatter = getFormatterFromMap(decimalPlaces, THOUSAND, locale, format);
                break;
            }
            else {
                formatter = getFormatterFromMap(decimalPlaces, ONE, locale, format);
                break;
            }
        }
        case "G": {
            formatter = getFormatterFromMap(decimalPlaces, BILLION, locale, format);
            break;
        }
        case "M": {
            formatter = getFormatterFromMap(decimalPlaces, MILLION, locale, format);
            break;
        }
        case "K": {
            formatter = getFormatterFromMap(decimalPlaces, THOUSAND, locale, format);
            break;
        }
        case "Relative": {
            formatter = getFormatterFromMap(decimalPlaces, ONE, locale, format);
            break;
        }
        case "None": {
            formatter = getFormatterFromMap(decimalPlaces, ONE, locale, format);
            break;
        }
        case "P": {
            formatter = getFormatterFromMap(decimalPlaces, ONE, locale, format);
            break;
        }
        default: {
            formatter = getFormatterFromMap(4, undefined, locale, undefined);
            break;
        }
    }

    // it appears that labelFormat property is somehow persisted so it has to be always set even though the formatter is created new every time
    if (formatter.displayUnit) {
        if (hideUnits) {
            formatter.displayUnit.labelFormat = undefined;
        }
        else {
            formatter.displayUnit.labelFormat = getLabelFormat(displayUnits, Math.abs(value));
        }
    }
    if (percentageFormat) {
        // for percentage format value should not be rounded with .toFixed(decimalPlaces) so we return here.
        return formatter.format(value);
    }
    return value === undefined || value === null || (!value.toFixed) ? "" : formatter.format(+value.toFixed(decimalPlaces));
}

function getFormatterFromMap(decimalPlaces: number, units: number, locale: string, format: string) {
    const key = units + locale + decimalPlaces + format;
    let formatter = Visual.formatterMap.get(key);
    if (!formatter || formatter === undefined) {
        formatter = valueFormatter.create({ cultureSelector: locale, value: units, precision: decimalPlaces, format: format });
        Visual.formatterMap.set(key, formatter);
    }
    return formatter;
}

function getLabelFormat(displayUnits: string, value: number): string {
    if (displayUnits === "M" || displayUnits === "K") {
        return "{0}" + displayUnits;
    }
    else if (displayUnits === "G") {
        return "{0}bn";
    }
    else if (displayUnits === "P") {
        return "{0}%";
    }
    else if (displayUnits === "Auto") {
        if (value > BILLION) {
            return "{0}bn";
        }
        else if (value > MILLION) {
            return "{0}M";
        }
        else if (value > THOUSAND) {
            return "{0}K";
        }
        else {
            return "{0}";
        }
    }
}

export function getPercentageFormatOrNull(isPercentageData: boolean, labelPercentagePointUnit: string, isVarianceLabel: boolean, showPercentInLabel: boolean): string {
    if (isPercentageData) {
        return isVarianceLabel || !showPercentInLabel ? "0" + labelPercentagePointUnit : "0%";
    }
    else {
        return null;
    }
}

export function fixDateTime(value: any, format: string, locale: string) {
    const formatter = valueFormatter.create({ format: format, cultureSelector: locale });
    const dtValue = value instanceof Date ? value : new Date(value);
    return formatter.format(dtValue);
}

export function getDateFromString(value: string) {
    const sections = value.split("T");
    const yearMonthDay = sections[0].split("-");
    const hourMinuteSecond = sections[1].split(":");
    return new Date(parseInt(yearMonthDay[0]), parseInt(yearMonthDay[1]) - 1, parseInt(yearMonthDay[2]), parseInt(hourMinuteSecond[0]), parseInt(hourMinuteSecond[1]), parseInt(hourMinuteSecond[2]));
}
