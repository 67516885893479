import React from "react";
import useStore from "../../useStore";
import { SharePointFolder } from "../../ExcelDataLinking.types";
import chevronRightIcon from "../images/chevron-right.svg";

export default function Breadcrumbs() {
  const [sites] = useStore("sites");
  const [drives] = useStore("drives");
  const [onedrives] = useStore("onedrives");
  const [activeDriveId, setActiveDriveId] = useStore("activeDriveId");
  const [activeSiteId] = useStore("activeSiteId");
  const [, setFoldersAndFiles] = useStore("foldersAndFiles");
  const [foldersNavigation, setFoldersNavigation] = useStore("foldersNavigation");

  const activeSite = sites.find((site) => site.id === activeSiteId);
  const activeDrive = activeSiteId ? drives.find((drive) => drive.id === activeDriveId) : onedrives.find((drive) => drive.id === activeDriveId);

  const changeFolder = (folder: SharePointFolder) => {
    const index = foldersNavigation.indexOf(folder);

    if (index + 1 !== foldersNavigation.length) {
      setFoldersAndFiles([]);
      setFoldersNavigation(foldersNavigation.slice(0, index + 1));
    }
  };

  const handleDriveClick = () => {
    if (!foldersNavigation.length) {
      return;
    }

    setFoldersAndFiles([]);
    setFoldersNavigation([]);
  };

  const displayAllDrivesOnSite = () => {
    if (!activeDriveId) {
      return;
    }

    setFoldersAndFiles([]);
    setFoldersNavigation([]);
    setActiveDriveId("");
  };

  if (!activeSiteId && !activeDriveId) {
    return null;
  }

  return (
    <section className="breadcrumbs">
      <div>
        <span onClick={displayAllDrivesOnSite}>{activeSite?.displayName ?? activeDrive?.name}</span>
        <img src={chevronRightIcon} />
      </div>
      {activeDriveId ? (
        <div>
          <span onClick={handleDriveClick}>{activeDrive.name}</span>
          <img src={chevronRightIcon} />
        </div>
      ) : null}
      {foldersNavigation.map((folder) => (
        <div key={folder.id}>
          <span onClick={() => changeFolder(folder)}>{folder.name}</span>
          <img src={chevronRightIcon} />
        </div>
      ))}
    </section>
  );
}
