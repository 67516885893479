import { LinkButton } from "@zebrabi/design-library";
import { CLICK, CONTEXT_MENU2_DIV, LI } from "../../library/constants";
import { ChartSettings } from "../../settings/chartSettings";
import { Visual } from "../../visual";

export default abstract class ContextMenu {
    protected element: HTMLElement;
    private menuItems: HTMLElement;
    protected template = `<div class="context-menu2">
                        <ul class="menu-items"></ul>
                     </div>`;

    public appendTo(container: HTMLElement): HTMLElement {
        const range = document.createRange();
        const documentFragment = range.createContextualFragment(this.template);
        this.element = <HTMLElement>documentFragment.querySelector(`.${CONTEXT_MENU2_DIV}`);
        this.menuItems = <HTMLElement>documentFragment.querySelector(".menu-items");
        this.removeExistingMenus();
        container.append(this.element);
        this.addMenuItems();
        this.closeMenuOnDocumentMouseLeave();
        this.closeMenuOnOutsideClick();
        return this.element;
    }

    protected removeExistingMenus(): void {
        document.querySelector(`.${CONTEXT_MENU2_DIV}`)?.remove();
    }

    protected addMenuItems(): void {
        // add content of the menu in the extended class
    }

    protected closeMenuOnDocumentMouseLeave(): void {
        // add logic in extended class
    }

    protected closeMenuOnOutsideClick(): void {
        // add logic in extended class
    }

    protected createMenuItem(id?: string): HTMLElement {
        const menuItemDiv = document.createElement(LI);
        menuItemDiv.classList.add("menu-item");
        menuItemDiv.setAttribute("id", id ? id : "");
        this.menuItems.append(menuItemDiv);
        return menuItemDiv;
    }

    protected insertNewMenuItem(id: string): HTMLElement {
        const menuItemDiv = document.createElement(LI);
        menuItemDiv.classList.add("menu-item");
        const insertAfterElement = document.getElementById(id);
        this.insertAfter(menuItemDiv, insertAfterElement);
        return menuItemDiv;
    }

    protected removeNextSibling(id: string) {
        document.getElementById(id).nextSibling?.remove();
    }

    protected addResetToDefault() {
        const resetToDefault = new LinkButton("Reset to default");
        const menuItemDiv = this.createMenuItem();
        resetToDefault.appendTo(menuItemDiv);
        resetToDefault.containerNode().addEventListener(CLICK, (e) => {
            e.stopPropagation();
            this.resetToDefault();
        });
    }

    protected resetToDefault() {
        // add logic for reset in extended class
    }

    private insertAfter(newNode, existingNode) {
        existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
    }

    protected get chartSettings(): ChartSettings {
        return Visual.settings;
    }
}
