import { contributionCategories } from "./contribution/contributionCategories";
import { partWholeCategories } from "./partWhole/partWholeCategories";
import { structureCategories } from "./structure/structureCategories";
import { timeCategories } from "./time/timeCategories";
import { varianceCategories } from "./variance/varianceCategories";

export const categories = {
  structure: structureCategories,
  time: timeCategories,
  variance: varianceCategories,
  contribution: contributionCategories,
  partWhole: partWholeCategories,
};

export const chartsMap: any = {
  ...getChartSettingsMapFromDimensionCategories(structureCategories),
  ...getChartSettingsMapFromDimensionCategories(timeCategories),
  ...getChartSettingsMapFromDimensionCategories(varianceCategories),
  ...getChartSettingsMapFromDimensionCategories(contributionCategories),
  ...getChartSettingsMapFromDimensionCategories(partWholeCategories),
};

function getCategoryChartSettingsMap(categoryCharts: any) {
  return categoryCharts
    .map((chart) => ({
      [chart.id]: { ...chart },
    }))
    .reduce((prev: any, curr: any) => ({ ...prev, ...curr }));
}

function getChartSettingsMapFromDimensionCategories(dimensionCategories: any) {
  return dimensionCategories
    .map((cat: any) => getCategoryChartSettingsMap(cat.charts))
    .reduce((prev: any, curr: any) => ({ ...prev, ...curr }));
}
