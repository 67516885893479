import React from "react";
import useStore from "../../useStore";

export default function FullScreenLoader() {
  const [showFullScreenLoader] = useStore("showFullScreenLoader");

  if (!showFullScreenLoader) {
    return null;
  }

  return (
    <div className="full-screen-loader">
      <div className="loader-container">
        <div className="loader"></div>
        <p className="loading-text">Loading...</p>
      </div>
    </div>
  );
}
