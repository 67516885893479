import { ViewModel, ChartData, DataPoint } from "./../interfaces";
import { ChartType, DifferenceHighlightFromTo, LabelDensity } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { LabelProperties } from "./../library/interfaces";

import { G, NONE, ROUND, D, Scenario, FILL } from "./../library/constants";
import {
    CHART_CONTAINER, VALUE, SECOND_SEGMENT_VALUE, REFERENCE, SECOND_REFERENCE,
} from "./../consts";

import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as charting from "./chart";
import * as viewModels from "./../viewModel/viewModelHelperFunctions";
import * as helpers from "./../library/helpers";
import * as d3 from "../d3";

// eslint-disable-next-line max-lines-per-function
export default function lineChart(reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement, viewModel: ViewModel,
    x: number, y: number, height: number, width: number, chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number,
    min: number, max: number, isSingleSeries: boolean, plotTitle: boolean, plotLegend: boolean) {
    const scenarioOptions = settings.scenarioOptions;
    let chartWidth = width;
    let chartXPos = x;
    if (plotLegend) {
        chartWidth -= settings.getLegendWidth();
        chartXPos += settings.getLegendWidth();
    }

    if (settings.differenceHighlight) {
        chartWidth -= settings.differenceHighlightWidth;
    }
    else if (charting.shouldProvideSomeSpaceForLineChartRightLegend(settings, plotLegend)) {
        chartWidth -= settings.getRightLegendWidth();
    }

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    const yScale = charting.getYScale(min, max, height, topMargin, bottomMargin);
    const xScale = charting.getXScale(chartData, chartXPos, chartWidth, 0);

    charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, chartXPos, y, charting.getChartTitleWidth(chartWidth, settings), height, max, min, topMargin, bottomMargin);
    if (!settings.hasAxisBreak || chartData.axisBreak === 0) {
        drawing.plotHorizontalAxis(chartContainer, false, chartXPos, chartWidth, y + yScale(0), settings.colorScheme.axisColor, scenarioOptions.referenceScenario);
    }
    const effectiveAxisBreak = settings.hasAxisBreak && chartData.axisBreak !== null ? chartData.axisBreak : 0;
    charting.plotCategories(chartContainer, settings, chartData, y + height, xScale, chartWidth, y + yScale(0), false, chartIndex);

    const lineValueFunction = d3.line<DataPoint>().defined(d => d.value !== null)
        .x((d) => { return xScale(d.category) + xScale.bandwidth() / 2; })
        .y((d) => { return y + yScale(d.value); });

    const hasSecondSegmentValues = settings.scenarioOptions.secondValueScenario !== null;
    if (settings.handleNullsAsZeros) {
        handleLineChartMissingValues(chartData);
    }

    const valueDataPoints = chartData.dataPoints.filter(dp => dp.value !== null);
    const referenceDataPoints = chartData.dataPoints.filter(dp => dp.reference !== null);
    if (!isSingleSeries) {
        const lineReferenceFunction = d3.line<DataPoint>().defined(d => d.reference !== null)
            .x((d) => { return xScale(d.category) + xScale.bandwidth() / 2; })
            .y((d) => { return y + yScale(d.reference); });
        const referenceLine = styles.drawLine(chartContainer, NONE, settings.colorScheme.lineColor, ROUND, scenarioOptions.referenceScenario, true, settings.chartStyle, settings.colorScheme);
        referenceLine.attr(D, lineReferenceFunction(chartData.dataPoints));
    }

    const valueLine = styles.drawLine(chartContainer, NONE, settings.getGroupDataPointColor(chartData.group, settings.colorScheme.lineColor), ROUND, scenarioOptions.valueScenario, false, settings.chartStyle, settings.colorScheme);
    valueLine.attr(D, lineValueFunction(chartData.dataPoints));

    let secondSegmentDataPoints: DataPoint[] = [];
    let secondSegmentValuesOnlyDPs: DataPoint[] = [];
    if (hasSecondSegmentValues) {
        const secondSegmentValueFunction = d3.line<DataPoint>()
            .x((d) => { return xScale(d.category) + xScale.bandwidth() / 2; })
            .y((d) => { return y + yScale(d.secondSegmentValue); });
        secondSegmentDataPoints = settings.showAllForecastData ? chartData.dataPoints.filter(dp => dp.secondSegmentValue !== null) :
            chartData.dataPoints.filter(dp => dp.value === null && dp.secondSegmentValue !== null);
        secondSegmentValuesOnlyDPs = settings.showAllForecastData ? secondSegmentDataPoints.filter(dp => dp.value === null) : secondSegmentDataPoints;

        let allSecondSegmentLineDataPoints = [];
        if (settings.showAllForecastData) {
            allSecondSegmentLineDataPoints = secondSegmentDataPoints;
        }
        else {
            allSecondSegmentLineDataPoints = valueDataPoints.filter((p, i) => i === valueDataPoints.length - 1).concat(secondSegmentDataPoints);
            if (allSecondSegmentLineDataPoints.length > 0) {
                allSecondSegmentLineDataPoints[0].secondSegmentValue = allSecondSegmentLineDataPoints[0].value;
            }
        }

        const secondSegmentLine = styles.drawLine(chartContainer, NONE, settings.colorScheme.lineColor, ROUND, scenarioOptions.secondValueScenario, false, settings.chartStyle, settings.colorScheme);
        secondSegmentLine.attr(D, secondSegmentValueFunction(allSecondSegmentLineDataPoints));
        if (secondSegmentDataPoints.length > 0 && valueDataPoints.length > 0 && !settings.showAllForecastData) {
            const lastValue = valueDataPoints[valueDataPoints.length - 1].value;
            drawing.plotAxisScenarioDelimiter(chartContainer, xScale(secondSegmentDataPoints[0].category) - xScale.bandwidth() / 2, y + yScale(lastValue), y + height - bottomMargin);
        }
    }

    let secondReferenceDataPoints: DataPoint[] = [];
    if (settings.scenarioOptions.secondReferenceScenario !== null) {
        secondReferenceDataPoints = chartData.dataPoints.filter(dp => dp.secondReference !== null);
        const secondLineReferenceFunction = d3.line<DataPoint>()
            .x((d) => { return xScale(d.category) + xScale.bandwidth() / 2; })
            .y((d) => { return y + yScale(d.secondReference); });
        const secondReferenceLine = styles.drawLine(chartContainer, NONE, settings.colorScheme.lineColor, ROUND, scenarioOptions.secondReferenceScenario, true, settings.chartStyle, settings.colorScheme);
        secondReferenceLine.attr(D, secondLineReferenceFunction(secondReferenceDataPoints));
    }

    if (settings.showDataLabels) {
        const allDataPoints = valueDataPoints.concat(secondSegmentValuesOnlyDPs);
        const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
        const hideUnits = settings.shouldHideDataLabelUnits();
        const labelsProperties = getLineChartLabelProperties(allDataPoints, settings, labelsFormat, hideUnits, xScale, yScale,
            y, chartXPos, chartWidth, effectiveAxisBreak, max, topMargin, isSingleSeries);
        if (labelsProperties.length > 0) {
            charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings);
        }

        if (referenceDataPoints.length > 0 && !isSingleSeries) {
            const referenceMarker = [referenceDataPoints[referenceDataPoints.length - 1]];
            charting.plotMarkers(chartContainer, referenceDataPoints, settings, xScale, y, yScale, scenarioOptions.referenceScenario, REFERENCE, true, false, referenceMarker, chartData);
            if (settings.showReferenceLabels) {
                const labelsProperties = getLineChartLabelProperties(referenceDataPoints, settings, labelsFormat, hideUnits, xScale, yScale, y, chartXPos, chartWidth, effectiveAxisBreak, max, topMargin, isSingleSeries, true);
                if (labelsProperties.length > 0) {
                    const referenceLabels = charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings);
                    if (scenarioOptions.referenceScenario === Scenario.PreviousYear) {
                        referenceLabels.attr(FILL, styles.getLighterColor(settings.labelFontColor));
                    }
                }
            }
        }
        const valueMarkersDataPoints = valueDataPoints.filter(charting.densityFilter, settings);
        charting.plotMarkers(chartContainer, valueDataPoints, settings, xScale, y, yScale, scenarioOptions.valueScenario, VALUE, false, true, valueMarkersDataPoints, chartData);
        if (settings.scenarioOptions.secondValueScenario !== null) {
            const secondValueMarkersPoints = secondSegmentDataPoints.filter(charting.densityFilter, settings);
            charting.plotMarkers(chartContainer, secondSegmentDataPoints, settings, xScale, y, yScale, scenarioOptions.secondValueScenario, SECOND_SEGMENT_VALUE, false, true, secondValueMarkersPoints, chartData);
        }
        if (settings.scenarioOptions.secondReferenceScenario !== null && secondReferenceDataPoints.length > 0) {
            const secondReferenceMarker = [secondReferenceDataPoints[secondReferenceDataPoints.length - 1]];
            charting.plotMarkers(chartContainer, secondReferenceDataPoints, settings, xScale, y, yScale, scenarioOptions.secondReferenceScenario, SECOND_REFERENCE, true, false, secondReferenceMarker, chartData);
            if (settings.showReferenceLabels) {
                const labelsProperties = getLineChartLabelProperties(secondReferenceDataPoints, settings, labelsFormat, hideUnits, xScale, yScale, y, chartXPos, chartWidth, effectiveAxisBreak, max, topMargin, isSingleSeries, false, true);
                if (labelsProperties.length > 0) {
                    const secReferenceLabels = charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings);
                    if (scenarioOptions.secondReferenceScenario === Scenario.PreviousYear) {
                        secReferenceLabels.attr(FILL, styles.getLighterColor(settings.labelFontColor));
                    }
                }
            }
        }
    }

    charting.addDroplineHandlers(chartContainer, y, height, bottomMargin, settings.titleFontSize + 3, xScale, chartData.dataPoints, viewModel);

    if (settings.differenceHighlight && valueDataPoints.length > 0) {
        let { fromDataPoint, toDataPoint } = charting.getDiffHighlightAutoDataPoints(valueDataPoints, ChartType.Line, isSingleSeries);
        let isLastAcToLastFc = false;
        if (settings.differenceHighlightFromTo !== DifferenceHighlightFromTo.Auto) {
            if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.LastACToLastFC && secondSegmentDataPoints.length !== 0 && valueDataPoints.length !== 0) {
                isLastAcToLastFc = true;
                fromDataPoint = valueDataPoints[valueDataPoints.length - 1];
                toDataPoint = secondSegmentDataPoints[secondSegmentDataPoints.length - 1];
            }
            else {
                const diffHighlightDataPoints = hasSecondSegmentValues && settings.isSecondSegmentDiffHighlight() ? secondSegmentDataPoints : valueDataPoints;
                const fromToDataPoints = charting.getDiffHighlightDataPoints(diffHighlightDataPoints, settings.differenceHighlightFromTo);
                fromDataPoint = fromToDataPoints.fromDP;
                toDataPoint = fromToDataPoints.toDP;
            }
        }
        if (fromDataPoint && toDataPoint) {
            const valueProperty: keyof DataPoint = hasSecondSegmentValues && settings.isSecondSegmentDiffHighlight() ? SECOND_SEGMENT_VALUE : VALUE;
            if (fromDataPoint === toDataPoint && fromDataPoint[valueProperty] !== null && toDataPoint.reference !== null) {
                const dhX = xScale(fromDataPoint.category) + xScale.bandwidth() / 2;
                const dhY1 = y + yScale(fromDataPoint[valueProperty]);
                const dhY2 = y + yScale(fromDataPoint.reference);
                charting.addDifferenceHighlight(chartContainer, settings, slider, x + width, dhX, dhX, fromDataPoint[valueProperty] + effectiveAxisBreak,
                    fromDataPoint.reference + effectiveAxisBreak, dhY1, dhY2, chartData.isInverted);
            }
            else if (fromDataPoint !== toDataPoint && (fromDataPoint[valueProperty] !== null && toDataPoint[valueProperty] !== null
                || isLastAcToLastFc && fromDataPoint.value !== null && toDataPoint.secondSegmentValue !== null)) {
                const dhX1 = xScale(toDataPoint.category) + xScale.bandwidth() / 2;
                const dhX2 = xScale(fromDataPoint.category) + xScale.bandwidth() / 2;
                const startValue = isLastAcToLastFc ? fromDataPoint.value : fromDataPoint[valueProperty];
                const endValue = isLastAcToLastFc ? toDataPoint.secondSegmentValue : toDataPoint[valueProperty];
                const dhY1 = y + yScale(endValue);
                const dhY2 = y + yScale(startValue);
                charting.addDifferenceHighlight(chartContainer, settings, slider, x + width, dhX1, dhX2, endValue + effectiveAxisBreak,
                    startValue + effectiveAxisBreak, dhY1, dhY2, chartData.isInverted);
            }
        }
    }
    const axisBreakXPos = charting.getAxisBreakUIxPosition(chartXPos, chartWidth, viewModel, settings);
    charting.addAxisBreakLineChartsUIHandlers(chartContainer, axisBreakXPos, y + settings.titleFontSize + 3, y + yScale(0) - 1, settings, slider);

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, y, settings);
        }
    }

    charting.addHighlightedDataPointsDroplines(settings, chartData, xScale, y, yScale, chartContainer);

    if (plotLegend) {
        charting.plotAllChartLegends(chartContainer, settings, x, y, chartWidth, yScale, valueDataPoints, referenceDataPoints, secondSegmentDataPoints, secondReferenceDataPoints, false, height);
    }
}

export function handleLineChartMissingValues(chartData: ChartData) {
    let lastNonNullValueIndex = chartData.dataPoints.length - 1;
    let lastNonNullReferenceIndex = chartData.dataPoints.length - 1;
    while (lastNonNullValueIndex > 0 && chartData.dataPoints[lastNonNullValueIndex].value === null) {
        lastNonNullValueIndex--;
    }
    while (lastNonNullReferenceIndex > 0 && chartData.dataPoints[lastNonNullReferenceIndex].reference === null) {
        lastNonNullReferenceIndex--;
    }
    chartData.dataPoints.forEach((d, i) => {
        if (d.value === null && i < lastNonNullValueIndex) {
            d.value = 0;
        }
        if (d.reference === null && i < lastNonNullReferenceIndex) {
            d.reference = 0;
        }
    });
}

export function getLineChartLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, chartXPos: number, chartWidth: number,
    axisBreak: number, max: number, topMargin: number, isSingleSeries: boolean, isReference: boolean = false, isSecondReference: boolean = false): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const labelProperties = labelsDataPoints.map((d, i) => {
        const plotValue = isReference ? d.reference : (isSecondReference ? d.secondReference : viewModels.getDataPointNonNullValue(d));
        const isPlotValueLargerThanComparison = isSingleSeries || isSecondReference ? null : (isReference ? d.reference > d.value : d.value > d.reference);
        const labelText = charting.getFormattedDataLabel(plotValue + axisBreak, settings.decimalPlaces, settings.displayUnits, settings.locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        const xPos = xScale(d.category) + xScale.bandwidth() / 2;
        const yPos = getLineChartLabelYPosition(y, yScale, plotValue, max, topMargin, settings, isSingleSeries, d, labelsDataPoints, i, xScale, isPlotValueLargerThanComparison, isReference || isSecondReference);
        const alignment = null;
        return charting.getLabelProperty(labelText, xPos, yPos, yPos === null ? false : null, plotValue, d, settings.labelFontSize, settings.labelFontFamily, false, false, false, alignment, false, chartXPos, chartXPos + chartWidth);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

export function getLineChartLabelYPosition(y: number, yScale: d3.ScaleLinear<number, number>, plotValue: number, max: number, topMargin: number, settings: ChartSettings,
    isSingleSeries: boolean, d: DataPoint, labelsDataPoints: DataPoint[], i: number, xScale: d3.ScaleBand<string>, isPlotValueLarger: boolean, isReference: boolean) {
    let yPosition = y + yScale(plotValue);
    let shouldMoveLabelBelow = plotValue < 0;
    const isEnoughSpaceBelowLine = plotValue < 0 || plotValue >= 0 && yScale(max - Math.abs(plotValue)) - topMargin > settings.labelFontSize + 7;
    if (settings.showReferenceLabels && !isSingleSeries && isPlotValueLarger !== null) {
        if (isReference && !isEnoughSpaceBelowLine) {
            return null;
        }
        shouldMoveLabelBelow = !isPlotValueLarger;
        if (!isReference && !isEnoughSpaceBelowLine) {
            shouldMoveLabelBelow = false;
        }
    }
    else if (isEnoughSpaceBelowLine) {
        if ((!isSingleSeries || settings.chartType === ChartType.Area) && plotValue < d.reference) {
            shouldMoveLabelBelow = true;
        }
        if (settings.labelDensity === LabelDensity.Full || settings.labelDensity === LabelDensity.Auto || settings.labelDensity === LabelDensity.High || settings.labelDensity === LabelDensity.Medium || settings.labelDensity === LabelDensity.Low) {
            if (labelsDataPoints.length > 1 && (i === 0 || i === labelsDataPoints.length - 1)) {
                const x0 = xScale(d.category);
                const y0 = yScale(plotValue);
                const nextOrPreviousDataPoint = i === 0 ? labelsDataPoints[1] : labelsDataPoints[labelsDataPoints.length - 2];
                const x1 = xScale(nextOrPreviousDataPoint.category);
                const y1 = yScale(viewModels.getDataPointNonNullValue(nextOrPreviousDataPoint));
                const angle = helpers.getLineAngle(x1, x0, y1, y0);
                if (i === 0 && angle > 35 || i !== 0 && angle < 150 && angle > 0) {
                    shouldMoveLabelBelow = true;
                }
            }
            else if (i > 0 && labelsDataPoints[i - 1].value > d.value && i < labelsDataPoints.length - 1 && d.value < labelsDataPoints[i + 1].value) {
                shouldMoveLabelBelow = true;
            }
        }
    }
    const labelmargin = shouldMoveLabelBelow ? settings.labelFontSize + 5 : -10;
    yPosition = yPosition + labelmargin;
    return yPosition;
}

export function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], xScale: d3.ScaleBand<string>,
    yScale: d3.ScaleLinear<number, number>, y: number, settings: ChartSettings) {
    const xScaleBandWidth = xScale.bandwidth();
    const markerAttrs = charting.getCommentMarkerAttributes(xScaleBandWidth);
    const labelsMargin = settings.showDataLabels ? settings.labelFontSize + 4 : 0;
    const getMarkerVerticalPosition = (d: DataPoint): number => y + yScale((viewModels.getDataPointNonNullValue(d))) - (labelsMargin + markerAttrs.radius + markerAttrs.margin);
    const xScaleBandWidthHalf = xScale.bandwidth() / 2;
    const getMarkerHorizontalPosition = (d: DataPoint): number => xScale(d.category) + xScaleBandWidthHalf;
    charting.addCommentMarkers(container, commentDataPoints, getMarkerHorizontalPosition, getMarkerVerticalPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}

