import React from "react";

type ChartSeriesRowProps = {
  title: string;
  children?: any;
  key: string;
};

export default function ChartCategoryList({ title, children }: ChartSeriesRowProps) {
  return (
    <div className="flex flex-col mb-2.5">
      <h1 className="text-xs font-semibold mb-2.5">{title}</h1>
      <div className="flex flex-wrap">{children}</div>
    </div>
  );
}
