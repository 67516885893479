import * as d3 from "../d3";
import { showGlobalOverlayOnStackedChartLegendHover, } from "../ui/drawGlobalStackedChartLegendOverlay";
import { showOverlayOnStackedChartLegendHover } from "../ui/drawStackedChartLegendOverlay";
import { LabelAlignment } from "../library/types";
import { ChartData, ScenarioOptions, ViewModel } from "./../interfaces";
import { ColorScheme, LabelProperties } from "./../library/interfaces";
import { LabelDensity, ShowTopNChartsOptions, } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { Visual } from "./../visual";

import {
    Scenario, END, NORMAL, TEXT, FONT_SIZE, FONT_FAMILY, TEXT_ANCHOR, FILL, FILL_OPACITY, STROKE_OPACITY, WIDTH, HEIGHT, HIGHLIGHTABLE, RECT, X, Y,
    BLACK, WHITE, G, BAR, OPACITY, FONT_WEIGHT, STROKE, DifferenceLabel, MOUSEOVER, MOUSEOUT, TOP_N_RECTANGLE, TOP_N_ARROW, AXIS, START, MIDDLE, FONT_SIZE_UNIT
} from "./../library/constants";
import { CHART_CONTAINER, HORIZONTAL_LABEL_PADDING } from "./../consts";
import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as charting from "./chart";
import { getCommentMarkerAttributes, getFontFamily, getFontWeight, getFormattedDataLabel } from "./chart";
import { addColorsCustomHighlightColorsPatternDefinitions, addValuesLabelsInteractions, applyStylesToStackedBars, getValueDataPointColor, drawSingleOtherArrow, getColorScale, getDifferenceHighlightAttributes, getNonNullStackedDataPointValue, getStackedChartLegendColor, getStackedLabelProperties, getStackedLabelProperty, getTotalsLabelsData, stackedLabelDensityFilter } from "./stackedChart";
import { StackedDataPoint, StackedLabelProperties, DiffHighlightAttrs, getStackedData, addStackedChartMouseHandlers } from "./stackedChart";

// eslint-disable-next-line max-lines-per-function
export default function verticalStackedBarChart(viewModel: ViewModel, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
    slider: HTMLElement, x: number, y: number, height: number, width: number, bottomMargin: number, categoriesleftMargin: number) {
    if (!viewModel.chartData || viewModel.chartData.length === 0) {
        return;
    }

    const settings = viewModel.settings;
    const scenarioOptions = settings.scenarioOptions;

    const groups = viewModel.chartData.map(d => d.group);
    const plotOverlappedReference = !viewModel.isSingleSeriesViewModel && settings.plotOverlappedReference;

    const stackedData = getStackedData(viewModel, groups, plotOverlappedReference);
    const minStacked = stackedData.min;
    const maxStacked = stackedData.max;
    const stackedDataPoints = stackedData.dataPoints;
    const stackedDataValues = stackedData.stackedDataValues;
    const chartData0 = viewModel.chartData[0];

    const totalsLabelsData = getTotalsLabelsData(stackedDataValues, stackedData.isDiverging);
    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    const hideUnits = settings.shouldHideDataLabelUnits();

    let leftTotalMargin = settings.showCategories ? categoriesleftMargin : 1;
    let rightTotalMargin = 5;
    if (settings.showDataLabels) {
        const totalsLabelsInfo = getTotalLabelsInfo(totalsLabelsData, settings, labelsFormat, hideUnits);
        if (totalsLabelsInfo.some(t => t.displayValue < 0)) {
            leftTotalMargin += Math.max(...totalsLabelsInfo.filter(t => t.displayValue < 0).map(n => n.width));
        }
        if (totalsLabelsInfo.some(t => t.displayValue >= 0)) {
            rightTotalMargin += Math.max(...totalsLabelsInfo.filter(t => t.displayValue >= 0).map(n => n.width));
        }
    }

    let chartYPos = y;
    let topMargin = plotOverlappedReference ? 5 : 0;
    let legendRows = 0;
    if (settings.showLegend) {
        const legendHeight = getLegendHeight(groups, width - leftTotalMargin - rightTotalMargin, settings, maxStacked, totalsLabelsData[0].value);
        legendRows = legendHeight.numberOfRows;
        topMargin += legendRows * legendHeight.rowLegendHeight;
        chartYPos += topMargin;
    }

    if (bottomMargin + topMargin > height) {
        bottomMargin = 0;
    }

    const chartHeight = height - (topMargin + bottomMargin);

    let setBarHeightToZero = false;
    if (minStacked === 0 && maxStacked === 0) {
        setBarHeightToZero = true;
    }

    const yScale = charting.getOrdinalScale(chartData0, chartYPos, chartHeight, settings.getGapBetweenColumns());
    const xScale = charting.getLinearScale(minStacked, maxStacked, x + leftTotalMargin, x + width - rightTotalMargin);

    const colorScale = getColorScale(settings, groups, scenarioOptions.valueScenario);
    if (scenarioOptions.valueScenario === Scenario.Forecast) {
        drawing.addColorsArrayPatternDefinitions(svg, colorScale);
        addColorsCustomHighlightColorsPatternDefinitions(svg, groups, settings);
    }
    stackedDataPoints.forEach(p => {
        p.color = getValueDataPointColor(p, settings, groups, colorScale);
    });

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);
    charting.plotVerticalCategories(chartContainer, settings, chartData0, x, yScale, categoriesleftMargin);

    if (plotOverlappedReference) {
        plotOverlappedStackedReferencesBars(settings, groups, scenarioOptions, svg, chartContainer, stackedDataPoints, xScale, yScale, settings.colorScheme);
    }

    let stackedColumns = chartContainer
        .selectAll(`.${BAR}_value`)
        .data(stackedDataPoints);
    const rect = stackedColumns.enter()
        .append(RECT)
        .classed(BAR, true)
        .classed(HIGHLIGHTABLE, true);
    stackedColumns = stackedColumns.merge(rect);
    stackedColumns
        .attr(X, d => xScale(d.start))
        .attr(WIDTH, d => setBarHeightToZero ? 0 : Math.abs(xScale(d.start) - xScale(d.end)))
        .attr(Y, d => yScale(d.category))
        .attr(HEIGHT, yScale.bandwidth());

    applyStylesToStackedBars(stackedColumns, colorScale, settings, groups, scenarioOptions.valueScenario, settings.chartStyle, settings.colorScheme, true);

    // plot vertical axis
    drawing.drawLine(chartContainer, xScale(0), xScale(0), chartYPos, chartYPos + chartHeight, 1, settings.colorScheme.axisColor, AXIS);

    if (settings.showDataLabels) {
        const totalsLabelsProperties = getTotalLabelProperties(totalsLabelsData, settings, labelsFormat, hideUnits, xScale, yScale);
        if (totalsLabelsProperties.length > 0) {
            charting.plotLabels(chartContainer, "total-label", totalsLabelsProperties, settings);
        }
        const valuesLabelProperties = getVerticalStackedLabelProperties(stackedDataPoints, settings, labelsFormat, hideUnits, xScale, yScale, totalsLabelsData, settings.showStackedLabelsAsPercentage);
        if (valuesLabelProperties.length > 0) {
            const backgroundsColor = WHITE; //scenarioOptions.valueScenario === Scenario.Forecast ? WHITE : baseColor;
            charting.plotLabelBackgrounds(chartContainer, 0, valuesLabelProperties.filter(l => l.scenario === Scenario.Forecast), settings, backgroundsColor, true, false);
            const valueLabels = charting.plotLabels(chartContainer, "value-label", valuesLabelProperties, settings);
            valueLabels.attr(FILL, d => (<StackedLabelProperties>d).scenario === Scenario.Plan || (<StackedLabelProperties>d).scenario === Scenario.Forecast ?
                BLACK : styles.getLabelColor((<StackedLabelProperties>d).color));

            //if (Visual.visualHost.hostCapabilities.allowInteractions) {
            addValuesLabelsInteractions(valueLabels, chartContainer, settings, slider);
            if (Visual.animateVarianceLabels) {
                charting.animateLabels(valueLabels, null, settings.labelFontSize);
            }
            //}
        }
    }

    if (settings.differenceHighlight) {
        const diffHighlightLabelHeight = 8 + (settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute ? 2 : 1) * settings.differenceHighlightFontSize;
        const endY = y + height - diffHighlightLabelHeight;
        const diffHighlightAttrs = getDifferenceHighlightAttributes(stackedData.stackedDataValues, stackedData.stackedDataReference, settings, plotOverlappedReference, stackedData.isDiverging);
        plotDifferenceHighlight(chartContainer, diffHighlightAttrs, settings, endY, slider, xScale, yScale);
    }

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = stackedDataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotStackedCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, settings);
        }
    }

    addStackedChartMouseHandlers(stackedColumns, chartContainer);

    // Visual.tooltipServiceWrapper.addTooltip(chartContainer.selectAll(`.${BAR}, .${TOOLTIP}`),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => charting.getTooltipData(tooltipEvent.data, settings, labelsFormat, labelsFormat, settings.invert, undefined, chartData0),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => tooltipEvent && tooltipEvent.data ? tooltipEvent.data.selectionId : null);

    stackedColumns.exit().remove();

    if (settings.showLegend) {
        plotStackedChartLegend(stackedData.stackedDataValues, chartContainer, viewModel.chartData, settings, y, legendRows, xScale, stackedData.isDiverging, stackedDataPoints);
    }
}

function plotOverlappedStackedReferencesBars(settings: ChartSettings, groups: string[], scenarioOptions: ScenarioOptions,
    svg: d3.Selection<SVGElement, any, any, any>, chartContainer: d3.Selection<SVGGElement, any, any, any>,
    stackedDataPoints: StackedDataPoint[], xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>, colorScheme: ColorScheme) {
    const referenceColorScheme = getColorScale(settings, groups, scenarioOptions.referenceScenario);
    if (scenarioOptions.referenceScenario === Scenario.Forecast) {
        drawing.addColorsArrayPatternDefinitions(svg, referenceColorScheme);
        addColorsCustomHighlightColorsPatternDefinitions(svg, groups, settings);
    }

    let referenceBars = chartContainer
        .selectAll(`.${BAR}_REFERENCE`)
        .data(stackedDataPoints);
    const rect = referenceBars.enter()
        .append(RECT)
        .classed(BAR, true)
        .classed("reference", true)
        .classed(HIGHLIGHTABLE, true)
        .attr(FILL, WHITE)
        .attr(STROKE, BLACK);
    referenceBars = referenceBars.merge(rect);
    referenceBars
        .attr(X, d => xScale(d.referenceStart))
        .attr(Y, d => yScale(d.category) - yScale.bandwidth() * settings.getGapBetweenColumns() / 1.6)
        .attr(HEIGHT, yScale.bandwidth())
        .attr(WIDTH, d => Math.abs(xScale(d.referenceStart) - xScale(d.referenceEnd)));

    applyStylesToStackedBars(referenceBars, referenceColorScheme, settings, groups, scenarioOptions.referenceScenario, settings.chartStyle, colorScheme, false);
    if (settings.lightenOverlapped) {
        referenceBars.attr(STROKE_OPACITY, 0.5);
        const overlappedFillOpacity = scenarioOptions.referenceScenario === Scenario.Plan &&
            (!settings.colorScheme.useCustomScenarioColors || settings.colorScheme.applyPatterns) ?
            +referenceBars.attr(FILL_OPACITY) * 0.5 : 0.5;
        referenceBars.attr(FILL_OPACITY, overlappedFillOpacity);
    }
    referenceBars.exit().remove();
}

function getTotalLabelsInfo(totalsStackedData: { category: string, value: number, displayValue: number }[],
    settings: ChartSettings, labelsFormat: string, hideUnits: boolean) {
    const labelsDataPoints = applyStackedTotalsLabelDensity(settings, totalsStackedData);
    return labelsDataPoints.map((d, i) => {
        const labelText = charting.getFormattedDataLabel(d.displayValue, settings.decimalPlaces, settings.displayUnits, settings.locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        const labelWidth = drawing.measureTextWidth(labelText, settings.labelFontSize, getFontFamily(settings.labelFontFamily),
            getFontWeight(settings.labelFontFamily), NORMAL) + 2 * HORIZONTAL_LABEL_PADDING;
        return {
            ...d,
            label: labelText,
            width: labelWidth
        };
    });
}

function getTotalLabelProperties(totalsStackedData: { category: string, value: number, displayValue: number }[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>): LabelProperties[] {
    const rangeBand = yScale.bandwidth();
    const labelsDataPoints = applyStackedTotalsLabelDensity(settings, totalsStackedData);
    const labelProperties = labelsDataPoints.map((d, i) => {
        const value = d.value;
        const labelText = charting.getFormattedDataLabel(d.displayValue, settings.decimalPlaces, settings.displayUnits, settings.locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        const category = d.category;
        const yPos = yScale(category) + rangeBand / 2; // + + settings.labelFontSize * 0.35
        const xPos = xScale(value) + (value < 0 ? -5 : 5);
        const alignment: LabelAlignment = value > 0 ? START : END;

        return getStackedLabelProperty(d.displayValue, labelText, xPos, yPos, null, null, settings.labelFontSize, settings.labelFontFamily, category, alignment);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, rangeBand);
}

function plotDifferenceHighlight(chartContainer: d3.Selection<SVGElement, any, any, any>, diffHighlightAttrs: DiffHighlightAttrs,
    settings: ChartSettings, endY: number, slider: HTMLElement, xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>) {
    const dhY1 = yScale(diffHighlightAttrs.endCategory);
    const dhY2 = yScale(diffHighlightAttrs.startCategory);
    const dhX1 = xScale(diffHighlightAttrs.endValue);
    const dhX2 = xScale(diffHighlightAttrs.startValue);
    charting.addVerticalDifferenceHighlight(chartContainer, settings, slider, endY, dhX1, dhX2, diffHighlightAttrs.endValue, diffHighlightAttrs.startValue, dhY1, dhY2, settings.invert);
}

function getVerticalStackedLabelProperties(dataPoints: StackedDataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>,
    totalsLabelsData: { category: string, value: number }[], plotPercentage: boolean): StackedLabelProperties[] {

    const rangeBand = yScale.bandwidth();
    const labelsDataPoints = applyLabelDensity(settings, totalsLabelsData, dataPoints);

    const labelProperties: StackedLabelProperties[] = labelsDataPoints.map(d => {
        let value = getNonNullStackedDataPointValue(d);
        const isEnoughHorizontalSpace = Math.abs(xScale(d.start) - xScale(d.end)) > 35; //Improve?: label width settings.labelFontSize + 1;
        let labelText = "";
        if (isEnoughHorizontalSpace) {
            if (plotPercentage) {
                const totalValue = totalsLabelsData.find(t => t.category === d.category)?.value;
                if (totalValue) {
                    value = value / totalValue;
                    labelText = getFormattedDataLabel(value, settings.decimalPlaces, "None", settings.locale,
                        settings.showNegativeValuesInParenthesis(), true, false, false, false, "0%", false);
                }
            }
            else {
                labelText = getFormattedDataLabel(value, settings.decimalPlaces, settings.displayUnits, settings.locale,
                    settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
            }
        }

        const isForecast = d.value !== null && settings.scenarioOptions.valueScenario === Scenario.Forecast
            || d.value === null && d.secondSegmentValue !== null && settings.scenarioOptions.secondValueScenario === Scenario.Forecast;
        const xPos = xScale((d.end + d.start) / 2);
        const yPos = yScale(d.category) + rangeBand * 0.5 + 0.35 * settings.labelFontSize;

        const property = charting.getLabelProperty(labelText, xPos, yPos, null, value, d, settings.labelFontSize, settings.labelFontFamily, null, null, isForecast);
        return {
            group: d.group,
            ...property,
            scenario: d.scenario,
            color: d.color
        };
    });
    return labelProperties ? <StackedLabelProperties[]>charting.checkForOverlappedLabels(<LabelProperties[]>labelProperties, settings.labelDensity, rangeBand) : [];
}

function applyLabelDensity(settings: ChartSettings, totalsLabelsData: { category: string; value: number; }[], dataPoints: StackedDataPoint[]) {
    let minIndex = 0;
    let maxIndex = 0;
    if (settings.labelDensity === LabelDensity.MinMax || settings.labelDensity === LabelDensity.FirstLastMinMax) {
        const getValue: (d: { category: string; value: number; }) => number = d => d.value;
        const maxPoint = totalsLabelsData.reduce((a, b) => getValue(a) > getValue(b) ? a : b);
        const minPoint = totalsLabelsData.reduce((a, b) => getValue(a) > getValue(b) ? b : a);
        maxIndex = totalsLabelsData.indexOf(maxPoint);
        minIndex = totalsLabelsData.indexOf(minPoint);
    }
    return dataPoints.filter((p, i, arr) => {
        const pointCategoryIndex = totalsLabelsData.findIndex(c => c.category === p.category);
        const isLastIndex = pointCategoryIndex === totalsLabelsData.length - 1;
        switch (settings.labelDensity) {
            case LabelDensity.Full:
            case LabelDensity.Auto:
            case LabelDensity.High:
            case LabelDensity.Medium:
            case LabelDensity.Low:
                return true;
            case LabelDensity.None:
                return false;
            case LabelDensity.Last:
                return isLastIndex;
            case LabelDensity.FirstLast:
                return pointCategoryIndex === 0 || isLastIndex;
            case LabelDensity.MinMax:
                return pointCategoryIndex === maxIndex || pointCategoryIndex === minIndex;
            case LabelDensity.FirstLastMinMax:
                return pointCategoryIndex === 0 || isLastIndex || pointCategoryIndex === maxIndex || pointCategoryIndex === minIndex;
        }
    });
}

export function applyStackedTotalsLabelDensity(settings: ChartSettings, totalsLabelsData: { category: string; value: number; displayValue: number }[]) {
    let minIndex = 0;
    let maxIndex = 0;
    if (settings.labelDensity === LabelDensity.MinMax || settings.labelDensity === LabelDensity.FirstLastMinMax) {
        const getValue: (d: { category: string; value: number; }) => number = d => d.value;
        const maxPoint = totalsLabelsData.reduce((a, b) => getValue(a) > getValue(b) ? a : b);
        const minPoint = totalsLabelsData.reduce((a, b) => getValue(a) > getValue(b) ? b : a);
        maxIndex = totalsLabelsData.indexOf(maxPoint);
        minIndex = totalsLabelsData.indexOf(minPoint);
    }

    return totalsLabelsData.filter((p, i, arr) => {
        const isLastIndex = i === totalsLabelsData.length - 1;
        switch (settings.labelDensity) {
            case LabelDensity.Full:
            case LabelDensity.Auto:
            case LabelDensity.High:
            case LabelDensity.Medium:
            case LabelDensity.Low:
                return true;
            case LabelDensity.None:
                return false;
            case LabelDensity.Last:
                return isLastIndex;
            case LabelDensity.FirstLast:
                return i === 0 || isLastIndex;
            case LabelDensity.MinMax:
                return i === maxIndex || i === minIndex;
            case LabelDensity.FirstLastMinMax:
                return i === 0 || isLastIndex || i === maxIndex || i === minIndex;
        }
    });
}

function plotStackedCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: StackedDataPoint[], xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>, settings: ChartSettings) {
    const scaleBandWidth = yScale.bandwidth();
    const labelsMargin = 0;
    const markerAttrs = getCommentMarkerAttributes(scaleBandWidth);
    const getMarkerVerticalPosition = (d: StackedDataPoint): number => yScale(d.category) + scaleBandWidth / 2;
    const getMarkerHorizontalPosition = (d: StackedDataPoint): number =>
        xScale(d.end) + (markerAttrs.radius + markerAttrs.margin + labelsMargin);
    charting.addCommentMarkers(container, commentDataPoints, getMarkerHorizontalPosition, getMarkerVerticalPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}

export function plotStackedChartLegend(stackedData: d3.Series<{ [key: string]: number; }, string>[],
    container: d3.Selection<SVGElement, any, any, any>, chartData: ChartData[],
    settings: ChartSettings, y: number, legendRows: number, xScale: d3.ScaleLinear<number, number>, isDiverging: boolean, stackedDataPoints: any[]) {

    const legendData = stackedData.map(d => {
        const groupName = d.key ? d.key : "[empty]";
        const groupWidth = drawing.measureTextWidth(groupName, settings.labelFontSize, settings.labelFontFamily, NORMAL, NORMAL);
        return {
            groupName: groupName,
            min: d[0][0],
            max: d[0][1],
            selectionId: chartData.find(c => c.group === d.key).selectionId,
            groupWidth: groupWidth
        };
    });

    // let plotAllNegative = isDiverging && legendData[0].max === 0;
    // let lastXPos: number = null;
    // let lastGroupWidth = null;
    const prevLegendEntries = [];
    const legendYPos = y + 5;
    const moreRows = legendRows > 1;
    let groupLegends = container
        .selectAll("chart-legend")
        .data(legendData);
    const legendTexts = groupLegends
        .enter()
        .append(TEXT)
        .classed("chart-legend", true)
        .classed(HIGHLIGHTABLE, true)
        .text(d => d.groupName)
        .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, getFontFamily(settings.labelFontFamily))
        .style(FONT_WEIGHT, getFontWeight(settings.labelFontFamily))
        .style(TEXT_ANCHOR, MIDDLE)
        .attr(X, (d, i) => {
            let xPos = xScale(d.max) + (xScale(d.min) - xScale(d.max)) / 2; //+ settings.labelFontSize / 3;
            // if (i > 0 && xPos - lastXPos < (d.groupWidth + lastGroupWidth) / 2) {
            //     moreRows = true;
            // }

            // if (i > 0 && lastXPos !== null) {
            //     let diff = Math.abs(lastXPos - xPos);
            //     if (diff < settings.labelFontSize + 2 || xPos >= lastXPos && !plotAllNegative && d.min >= 0 || xPos < lastXPos && plotAllNegative && d.min < 0) {
            //         xPos = lastXPos + (plotAllNegative ? 1 : -1) * (settings.labelFontSize + 2);
            //     }
            // }

            // lastXPos = xPos;
            // lastGroupWidth = d.groupWidth;
            if (i > 0 && !moreRows || moreRows && i > 1) {
                const minXPosEntry = prevLegendEntries[prevLegendEntries.length - (moreRows ? 2 : 1)];
                const minXPos = minXPosEntry.xPos + minXPosEntry.width / 2 + d.groupWidth / 2 + 5;
                if (xPos < minXPos) {
                    xPos = minXPos;
                }
            }
            prevLegendEntries.push({ xPos: xPos, width: d.groupWidth, i: i });
            return xPos;
        })
        .attr(Y, (d, i) => moreRows && i % 2 === 0 ? legendYPos + settings.labelFontSize * 1.3 : legendYPos)
        .attr(FILL, (d, i) => getStackedChartLegendColor(stackedDataPoints[i], settings));

    groupLegends = groupLegends.merge(legendTexts);

    showOverlayOnStackedChartLegendHover(groupLegends, true, {
        fontSize: settings.labelFontSize,
        fontFamily: getFontFamily(settings.labelFontFamily),
        fontColor: settings.labelFontColor
    });

    showGlobalOverlayOnStackedChartLegendHover(container, true);


    if (settings.showTopNStackedOptions === ShowTopNChartsOptions.Items) {
        const otherLabel = legendTexts.filter(d => d.groupName === settings.topNStackedOthersLabel);
        if (!otherLabel.empty()) {
            let othersLabelXPos = +otherLabel.attr(X);
            othersLabelXPos += otherLabel.node().getBBox().width / 2;
            const othersLabelYPos = +otherLabel.attr(Y);
            const fontSize = settings.labelFontSize;
            const arrowSize = Math.max(fontSize + 7, 16);
            drawSingleOtherArrow(container, othersLabelXPos, othersLabelYPos, true, settings, true, arrowSize);
            drawSingleOtherArrow(container, othersLabelXPos, othersLabelYPos, false, settings, true, arrowSize);
            Visual.element.addEventListener(MOUSEOUT, () => {
                d3.selectAll("." + TOP_N_RECTANGLE).attr(STROKE_OPACITY, 0);
                d3.selectAll("." + TOP_N_ARROW).attr(OPACITY, 0);
            });
            Visual.element.addEventListener(MOUSEOVER, () => {
                d3.selectAll("." + TOP_N_RECTANGLE).attr(STROKE_OPACITY, 1);
                d3.selectAll("." + TOP_N_ARROW).attr(OPACITY, 1);
            });
        }
    }
}

function getLegendHeight(groups: string[], width: number, settings: ChartSettings, maxStacked: number, firstTotalValue: number): { rowLegendHeight: number, numberOfRows: number } {
    const allLegendLabels = groups.join(" ");
    const totalLegendWidth = drawing.measureTextWidth(allLegendLabels, settings.labelFontSize, settings.labelFontFamily, NORMAL, NORMAL);
    let rowLegendHeight = drawing.measureTextHeight(allLegendLabels, settings.labelFontSize, settings.labelFontFamily, NORMAL, NORMAL);
    let legendRows = 1;
    const legendWidth = firstTotalValue / maxStacked * width * 0.7;
    if (totalLegendWidth > legendWidth && legendRows < 2) {
        legendRows++;
    }
    rowLegendHeight += settings.legendItemsMargin;
    return { rowLegendHeight: rowLegendHeight, numberOfRows: legendRows };
}

