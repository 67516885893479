import { SheetData } from "x-data-spreadsheet";

export const VarianceStructureColumnSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Month" }, 1: { text: "AC" }, 2: { text: "PY" } } },
    1: { cells: { 0: { text: "Jan" }, 1: { text: "138" }, 2: { text: "155" } } },
    2: { cells: { 0: { text: "Feb" }, 1: { text: "150" }, 2: { text: "160" } } },
    3: { cells: { 0: { text: "Mar" }, 1: { text: "170" }, 2: { text: "150" } } },
    4: { cells: { 0: { text: "Apr" }, 1: { text: "168" }, 2: { text: "160" } } },
    5: { cells: { 0: { text: "May" }, 1: { text: "140" }, 2: { text: "170" } } },
    6: { cells: { 0: { text: "Jun" }, 1: { text: "130" }, 2: { text: "165" } } },
    7: { cells: { 0: { text: "Jul" }, 1: { text: "120" }, 2: { text: "162" } } },
    8: { cells: { 0: { text: "Aug" }, 1: { text: "122" }, 2: { text: "150" } } },
    9: { cells: { 0: { text: "Sep" }, 1: { text: "125" }, 2: { text: "145" } } },
    10: { cells: { 0: { text: "Oct" }, 1: { text: "156" }, 2: { text: "145" } } },
    11: { cells: { 0: { text: "Nov" }, 1: { text: "182" }, 2: { text: "150" } } },
    12: { cells: { 0: { text: "Dec" }, 1: { text: "200" }, 2: { text: "170" } } },
  },
};

export const VarianceStructureColumnCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Seasonality disrupted due to Asian market lockdown. YTD plan expected to be achieved by May.",
        },
      },
    },
    7: {
      cells: {
        0: {
          text: "Production site #5 out of order due to natural disaster. Estimated repair time in late Oct. Evaluate alternative sites to meet the demand.",
        },
      },
    },
    10: { cells: { 0: { text: "Production site #5 re-built and in full capacity." } } },
  },
};

export const VarianceTimeDotSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Month" }, 1: { text: "AC" }, 2: { text: "PY" } } },
    1: { cells: { 0: { text: "Jan" }, 1: { text: "138" }, 2: { text: "155" } } },
    2: { cells: { 0: { text: "Feb" }, 1: { text: "150" }, 2: { text: "160" } } },
    3: { cells: { 0: { text: "Mar" }, 1: { text: "170" }, 2: { text: "150" } } },
    4: { cells: { 0: { text: "Apr" }, 1: { text: "168" }, 2: { text: "160" } } },
    5: { cells: { 0: { text: "May" }, 1: { text: "140" }, 2: { text: "170" } } },
    6: { cells: { 0: { text: "Jun" }, 1: { text: "130" }, 2: { text: "165" } } },
    7: { cells: { 0: { text: "Jul" }, 1: { text: "120" }, 2: { text: "162" } } },
    8: { cells: { 0: { text: "Aug" }, 1: { text: "122" }, 2: { text: "150" } } },
    9: { cells: { 0: { text: "Sep" }, 1: { text: "125" }, 2: { text: "145" } } },
    10: { cells: { 0: { text: "Oct" }, 1: { text: "156" }, 2: { text: "145" } } },
    11: { cells: { 0: { text: "Nov" }, 1: { text: "182" }, 2: { text: "150" } } },
    12: { cells: { 0: { text: "Dec" }, 1: { text: "200" }, 2: { text: "170" } } },
  },
};

export const VarianceTimeDotCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Seasonality disrupted due to Asian market lockdown. YTD plan expected to be achieved by May.",
        },
      },
    },
    7: {
      cells: {
        0: {
          text: "Production site #5 out of order due to natural disaster. Estimated repair time in late Oct. Evaluate alternative sites to meet the demand.",
        },
      },
    },
    10: { cells: { 0: { text: "Production site #5 re-built and in full capacity." } } },
  },
};

export const IntegratedVarianceTimeColumnResponsiveSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Month" }, 1: { text: "AC" }, 2: { text: "PY" } } },
    1: { cells: { 0: { text: "Jan" }, 1: { text: "138" }, 2: { text: "155" } } },
    2: { cells: { 0: { text: "Feb" }, 1: { text: "150" }, 2: { text: "160" } } },
    3: { cells: { 0: { text: "Mar" }, 1: { text: "170" }, 2: { text: "150" } } },
    4: { cells: { 0: { text: "Apr" }, 1: { text: "168" }, 2: { text: "160" } } },
    5: { cells: { 0: { text: "May" }, 1: { text: "140" }, 2: { text: "170" } } },
    6: { cells: { 0: { text: "Jun" }, 1: { text: "130" }, 2: { text: "165" } } },
    7: { cells: { 0: { text: "Jul" }, 1: { text: "120" }, 2: { text: "162" } } },
    8: { cells: { 0: { text: "Aug" }, 1: { text: "122" }, 2: { text: "150" } } },
    9: { cells: { 0: { text: "Sep" }, 1: { text: "125" }, 2: { text: "145" } } },
    10: { cells: { 0: { text: "Oct" }, 1: { text: "156" }, 2: { text: "145" } } },
    11: { cells: { 0: { text: "Nov" }, 1: { text: "182" }, 2: { text: "150" } } },
    12: { cells: { 0: { text: "Dec" }, 1: { text: "200" }, 2: { text: "170" } } },
  },
};

export const IntegratedVarianceTimeColumnResponsiveCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Seasonality disrupted due to Asian market lockdown. YTD plan expected to be achieved by May.",
        },
      },
    },
    7: {
      cells: {
        0: {
          text: "Production site #5 out of order due to natural disaster. Estimated repair time in late Oct. Evaluate alternative sites to meet the demand.",
        },
      },
    },
    10: { cells: { 0: { text: "Production site #5 re-built and in full capacity." } } },
  },
};

export const IntegratedVarianceTimeColumnSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Month" }, 1: { text: "AC" }, 2: { text: "PY" } } },
    1: { cells: { 0: { text: "Jan" }, 1: { text: "138" }, 2: { text: "155" } } },
    2: { cells: { 0: { text: "Feb" }, 1: { text: "150" }, 2: { text: "160" } } },
    3: { cells: { 0: { text: "Mar" }, 1: { text: "170" }, 2: { text: "150" } } },
    4: { cells: { 0: { text: "Apr" }, 1: { text: "168" }, 2: { text: "160" } } },
    5: { cells: { 0: { text: "May" }, 1: { text: "140" }, 2: { text: "170" } } },
    6: { cells: { 0: { text: "Jun" }, 1: { text: "130" }, 2: { text: "165" } } },
    7: { cells: { 0: { text: "Jul" }, 1: { text: "120" }, 2: { text: "162" } } },
    8: { cells: { 0: { text: "Aug" }, 1: { text: "122" }, 2: { text: "150" } } },
    9: { cells: { 0: { text: "Sep" }, 1: { text: "125" }, 2: { text: "145" } } },
    10: { cells: { 0: { text: "Oct" }, 1: { text: "156" }, 2: { text: "145" } } },
    11: { cells: { 0: { text: "Nov" }, 1: { text: "182" }, 2: { text: "150" } } },
    12: { cells: { 0: { text: "Dec" }, 1: { text: "200" }, 2: { text: "170" } } },
  },
};

export const IntegratedVarianceTimeColumnCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Seasonality disrupted due to Asian market lockdown. YTD plan expected to be achieved by May.",
        },
      },
    },
    7: {
      cells: {
        0: {
          text: "Production site #5 out of order due to natural disaster. Estimated repair time in late Oct. Evaluate alternative sites to meet the demand.",
        },
      },
    },
    10: { cells: { 0: { text: "Production site #5 re-built and in full capacity." } } },
  },
};

export const IntegratedVarianceTimeAreaSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Month" }, 1: { text: "AC" }, 2: { text: "PY" } } },
    1: { cells: { 0: { text: "Jan" }, 1: { text: "138" }, 2: { text: "155" } } },
    2: { cells: { 0: { text: "Feb" }, 1: { text: "150" }, 2: { text: "160" } } },
    3: { cells: { 0: { text: "Mar" }, 1: { text: "170" }, 2: { text: "150" } } },
    4: { cells: { 0: { text: "Apr" }, 1: { text: "168" }, 2: { text: "160" } } },
    5: { cells: { 0: { text: "May" }, 1: { text: "140" }, 2: { text: "170" } } },
    6: { cells: { 0: { text: "Jun" }, 1: { text: "130" }, 2: { text: "165" } } },
    7: { cells: { 0: { text: "Jul" }, 1: { text: "120" }, 2: { text: "162" } } },
    8: { cells: { 0: { text: "Aug" }, 1: { text: "122" }, 2: { text: "150" } } },
    9: { cells: { 0: { text: "Sep" }, 1: { text: "125" }, 2: { text: "145" } } },
    10: { cells: { 0: { text: "Oct" }, 1: { text: "156" }, 2: { text: "145" } } },
    11: { cells: { 0: { text: "Nov" }, 1: { text: "182" }, 2: { text: "150" } } },
    12: { cells: { 0: { text: "Dec" }, 1: { text: "200" }, 2: { text: "170" } } },
  },
};

export const IntegratedVarianceTimeAreaCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Seasonality disrupted due to Asian market lockdown. YTD plan expected to be achieved by May.",
        },
      },
    },
    7: {
      cells: {
        0: {
          text: "Production site #5 out of order due to natural disaster. Estimated repair time in late Oct. Evaluate alternative sites to meet the demand.",
        },
      },
    },
    10: { cells: { 0: { text: "Production site #5 re-built and in full capacity." } } },
  },
};

export const IntegratedVarianceStructureBarSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Fruit" }, 1: { text: "AC" }, 2: { text: "PY" } } },
    1: { cells: { 0: { text: "Oranges" }, 1: { text: "170" }, 2: { text: "150" } } },
    2: { cells: { 0: { text: "Lemons" }, 1: { text: "168" }, 2: { text: "160" } } },
    3: { cells: { 0: { text: "Bananas" }, 1: { text: "150" }, 2: { text: "160" } } },
    4: { cells: { 0: { text: "Kiwi" }, 1: { text: "140" }, 2: { text: "170" } } },
    5: { cells: { 0: { text: "Apples" }, 1: { text: "138" }, 2: { text: "155" } } },
    6: { cells: { 0: { text: "Melons" }, 1: { text: "145" }, 2: { text: "160" } } },
    7: { cells: { 0: { text: "Pears" }, 1: { text: "120" }, 2: { text: "110" } } },
    8: { cells: { 0: { text: "Grapes" }, 1: { text: "110" }, 2: { text: "105" } } },
  },
};

export const IntegratedVarianceStructureBarCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Oranges volume will be increased in the next quarter. Contracts signed with new suppliers in Sourhern Europe to meet the increased demand.",
        },
      },
    },
    3: { cells: { 0: { text: "South American supply has dropped by 10% due to a temporary export ban." } } },
    8: {
      cells: {
        0: { text: "The demand is gradually increasing just before spring/summer season. Evaluate new suppliers." },
      },
    },
  },
};
