import { LabelProperties } from "./interfaces";

// creates mapping into labelProperties, ordered by y-coordinate
function orderByY(labelProperties: LabelProperties[]) {
    const N = labelProperties.length;
    const ordered = [];
    for (let i = 0; i < N - 1; i++) {
        const lp = labelProperties[i];
        ordered.push({ idx: i, rank: lp.y });
    }
    // sort and add the last element
    ordered.sort((a, b) => a.rank - b.rank);
    ordered.push({ idx: N - 1, rank: N }); // y is not important for the last element
    return ordered;
}


// creates mapping into labelProperties, ordered by rank (rank = number of lower - number of higher bars)
function orderByRank(labelProperties: LabelProperties[], offset: number) {
    const N = labelProperties.length;
    const ordered = [];
    // fixed visibilities for the last/min/max
    const maxRank = 3 * 2 * offset; // 3 for extra safety (instead of 2)
    ordered.push({ idx: N - 1, rank: maxRank + 2, val: labelProperties[N - 1].value }); // add last
    // find min and max
    let maxIndex = 0, minIndex = 0;
    for (let idx = 0; idx < N - 1; idx++) {
        if (labelProperties[idx].value < labelProperties[minIndex].value) {
            minIndex = idx;
        } else if (labelProperties[idx].value > labelProperties[maxIndex].value) {
            maxIndex = idx;
        }
    }
    ordered.push({ idx: maxIndex, rank: maxRank + 1, val: labelProperties[maxIndex].value }); // add max
    ordered.push({ idx: minIndex, rank: maxRank, val: labelProperties[minIndex].value }); // add min
    // calculate ranks for others
    for (let idx = 0; idx < N - 1; idx++) {
        if (idx == maxIndex || idx == minIndex) continue;
        const idxp = labelProperties[idx];
        // calculate rank if idxp
        let rankMin = 0, rankMax = 0;
        for (let neigh = idx - offset; neigh <= idx + offset; neigh++) {
            if (neigh === idx) { continue; }
            if (neigh < 0 || neigh >= N) { rankMin += 2; rankMax += 2; continue; }
            const neighVal = labelProperties[neigh].value;
            if (idxp.value === neighVal) { rankMin++; rankMax++; continue; }
            if (idxp.value < neighVal) { rankMin += 2; }
            else { rankMax += 2; }
        }
        const rank = Math.max(rankMin, rankMax);
        ordered.push({ idx: idx, rank: rank, val: idxp.value });
    }
    // sort by rank (and also by value)
    ordered.sort((a, b) => { const d = b.rank - a.rank; return d == 0 ? b.val - a.val : d; });
    return ordered;
}

// assume: labels are ordered by their x coordinate
export function labelsOverlapping(labelProperties: LabelProperties[], marginSpeed: number, xScaleRangeBand: number) {
    if (!labelProperties || labelProperties.length === 0) {
        return;
    }
    // number of labels
    const N = labelProperties.length;
    // calculate window for overlap checking
    const maxLabelWidth = Math.max(...labelProperties.map((p, i) => p.width));
    const offset = Math.min(N, Math.ceil((2 * maxLabelWidth - xScaleRangeBand) / xScaleRangeBand)); // +- width
    // order by rank (local maximums have higher rank)
    const ordered = orderByRank(labelProperties, offset);
    // check for overlapping
    let msh = 0, msv = 0;
    for (let i = 0; i < N; i++) {
        const idx = ordered[i].idx;
        const idxp = labelProperties[idx];
        if (idxp.visibility != null) { continue; }  // non-null visibility => visibility was set externally
        // check for change of margin space
        if (i > 3 && ordered[i].rank < ordered[i - 1].rank) {
            msh += maxLabelWidth * marginSpeed;
            msv += labelProperties[0].height * marginSpeed;
        }
        // check neighbors (by x coordinate)
        let overlap = false;
        for (let neigh = idx - offset; neigh <= idx + offset; neigh++) {
            if (neigh < 0 || neigh === idx || neigh >= N) { continue; }
            const nghp = labelProperties[neigh];
            if (!nghp.visibility) { continue; }
            overlap = overlap || rectOverlap(
                nghp.x - msh, nghp.y - msv, nghp.x + nghp.width + msh, nghp.y + nghp.height + msv,
                idxp.x - msh, idxp.y - msv, idxp.x + idxp.width + msh, idxp.y + idxp.height + msv);
            if (overlap) { break; }
        }
        labelProperties[idx].visibility = !overlap;
    }
}

function rectOutside(ax1: number, ay1: number, ax2: number, ay2: number, bx1: number, by1: number, bx2: number, by2: number) {
    return ax1 > bx2 || ax2 < bx1 || ay1 > by2 || ay2 < by1;
}

function rectOverlap(ax1: number, ay1: number, ax2: number, ay2: number, bx1: number, by1: number, bx2: number, by2: number) {
    return ax1 <= bx2 && ax2 >= bx1 && ay1 <= by2 && ay2 >= by1;
}
