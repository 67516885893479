import { MainDimension } from "./types";

import structureImage from "./images/dimension-structure.svg";
import timeImage from "./images/dimension-time.svg";
import varianceImage from "./images/dimension-variance.svg";
import contributionImage from "./images/dimension-contribution.svg";
import partWholeImage from "./images/dimension-part-whole.svg";

export const mainDimensions: MainDimension[] = [
  {
    id: "time",
    title: "Time",
    image: timeImage,
  },
  {
    id: "structure",
    title: "Structure",
    image: structureImage,
  },
  {
    id: "variance",
    title: "Variance",
    image: varianceImage,
  },
  {
    id: "contribution",
    title: "Contribution",
    image: contributionImage,
  },
  {
    id: "partWhole",
    title: "Part-Whole",
    image: partWholeImage,
  },
];
