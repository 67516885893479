import GlobalToolbarSubject from "../subject/GlobalToolbarSubject";
import { globalToolbar } from "../templates/globalToolbar";
import GlobalToolbarObserver from "../observers/GlobalToolbarObserver";
import type BaseSwitcher from "../components/global-toolbar/BaseSwitcher";
const CLICK = "click";
const isLocalDevelopment = process.env.SERVER_ENVIRONMENT === "local";

/**
 * Controls the Global toolbar and loads the switcher components
 */
class GlobalToolbarOld extends GlobalToolbarSubject {
  public static CLASS_NAME = "GlobalToolbar";
  public static readonly TOOLBAR_CLOSE: string = "toolbar-close";
  public static readonly TOOLBAR_OPEN: string = "toolbar-open";
  public static readonly TOOLBAR_GROUP_CLICK: string = "component-group-click";

  public buttonArray = [];
  public selectorArray = [];
  private groups: BaseSwitcher[] = [];
  private loadedComponents: { [key: string]: BaseSwitcher } = {};
  private focusElementName = null;
  private toolbar: HTMLElement;

  private itemActionObserver = new (class extends GlobalToolbarObserver {
    action: (action: string, message: string) => void;
    update: (message: Map<string, any>) => void;
  })();

  public handleClose = () => this.close();
  private stopPropagation = (event) => event.stopPropagation();

  constructor(private target: HTMLElement) {
    super();
    this.removeToolbar(target); // clear any existing toolbar

    const range = document.createRange();
    const globalToolbarTemplate = range.createContextualFragment(globalToolbar);
    const activator = globalToolbarTemplate.querySelector(".activator");
    this.toolbar = <HTMLElement>globalToolbarTemplate.querySelector(".global-toolbar");
    if (isLocalDevelopment) this.toolbar.classList.add("development");
    this.toolbar.classList.add("hidden");

    if (Office.context.platform === Office.PlatformType.PC || Office.context.platform === Office.PlatformType.Mac) {
      this.toolbar.style.right = 14 + "px";
    }

    document.body.addEventListener(CLICK, this.handleClose);
    this.toolbar.addEventListener(CLICK, this.stopPropagation);

    target.appendChild(globalToolbarTemplate);

    this.itemActionObserver.action = (action, message) => {
      switch (action) {
        default:
          this.notifyAction(action, message);
          break;
        case GlobalToolbarOld.TOOLBAR_CLOSE:
          this.close();
          break;
      }
    };

    this.itemActionObserver.update = (message) => {
      this.notify(message);
    };

    const activatorToggle = (e) => {
      if (e.target === e.currentTarget) {
        for (const component of this.groups) {
          this.collapseOptions(component.getSwitcherContainer());
        }

        if (this.toolbar.classList.contains("active")) {
          this.close();
          this.notifyAction(GlobalToolbarOld.TOOLBAR_CLOSE, "");
        } else {
          this.collapseToolbar();
          this.open();
          this.notifyAction(GlobalToolbarOld.TOOLBAR_OPEN, "");
        }
      }
    };

    const activatorElements: HTMLElement[] = Array.from(activator.querySelectorAll("*"));
    for (const activatorElement of activatorElements) {
      activatorElement?.addEventListener(CLICK, activatorToggle);
    }

    activator?.addEventListener(CLICK, activatorToggle);
  }

  add(component: BaseSwitcher) {
    if (this.loadedComponents[component.getClassName()]) {
      this.detach(this.loadedComponents[component.getClassName()]);
      this.loadedComponents[component.getClassName()].switcherSubject.detachAll();
    }

    this.attach(component);
    component.switcherSubject.attach(this.itemActionObserver);
    this.loadedComponents[component.getClassName()] = component;

    component
      .getSwitcherContainer()
      .querySelector(".group-toolbar-button")
      .addEventListener("click", (e) => {
        //this.notifyAction(GlobalToolbar.TOOLBAR_GROUP_CLICK, component.getClassName());
        component.notifyAction(GlobalToolbarOld.TOOLBAR_GROUP_CLICK, component.getClassName());
      });
  }

  collapseOptions(group: HTMLElement) {
    const toolbar = this.target.querySelector(".global-toolbar");
    if (toolbar.getBoundingClientRect().width > this.target.getBoundingClientRect().width) {
      group.classList.add("collapsed");
    }
  }

  collapseToolbar() {
    const toolbar = this.target.querySelector(".global-toolbar");

    if (toolbar.getBoundingClientRect().width > this.target.getBoundingClientRect().width) {
      toolbar.classList.add("small");
    } else {
      toolbar.classList.remove("small");
    }
  }

  public rotateArrows(container: Element) {
    const menuArrow = container.querySelector(".menu-arrow");

    if (container.classList.contains("active")) {
      menuArrow?.classList.remove("active");
    } else {
      menuArrow?.classList.add("active");
    }
  }

  setActiveCollapsedButton(container: HTMLElement) {
    const checkedInput = container.getElementsByTagName("input");
    const icon = container.querySelector(".group-icon .icon");

    for (const input of Array.from(checkedInput)) {
      if (input.checked) {
        icon.classList.add("icon", input.value);
      }
    }
  }

  render(useAltStackChartIcon?: boolean) {
    const scrollPositions: Map<string, number> = new Map(
      this.groups.map((group) => {
        return [group.getClassName(), group.getScrollPosition()];
      })
    );

    this.groups = [];

    const toolbar = this.target.querySelector(".global-toolbar");
    const controls = toolbar.querySelector(".controls");
    const activeAction = controls.querySelector(".group.active");

    controls.textContent = "";

    for (const component of Object.values(this.loadedComponents)) {
      let useAltIcon = false;
      component.createOptions(useAltIcon, useAltStackChartIcon);

      this.groups.push(component);
    }

    this.groups.map((component) => {
      const action = component.getSwitcherContainer();
      controls?.append(action);
      if (scrollPositions.has(component.getClassName())) {
        component.setScrollPosition(scrollPositions.get(component.getClassName()));
      }

      this.rotateArrows(action);
      this.setActiveCollapsedButton(action);

      if (action.id === activeAction?.id) {
        action.classList.add("active");
      }
    });

    if (this.getToolbarProjectedWidth() > this.target.getBoundingClientRect().width) {
      const groups = toolbar?.querySelectorAll(".group");
      groups.forEach((group) => {
        group.classList.add("collapsed");
      });
    }
    const inputs = controls?.querySelectorAll("[name]");
    if (inputs) {
      for (const formElement of Object.values(inputs)) {
        const currentElement = <HTMLInputElement>formElement;

        if (currentElement.name === this.focusElementName) {
          currentElement.focus();
        }

        formElement.addEventListener("focus", (e) => {
          this.focusElementName = (<HTMLFormElement>e.target).name;
        });
      }
    }
  }

  private removeToolbar(target) {
    this.groups = [];

    const existingToolbar = target.querySelector(".global-toolbar");
    if (existingToolbar) {
      document.body.removeEventListener(CLICK, this.handleClose);
      this.toolbar.removeEventListener(CLICK, this.stopPropagation);

      target.removeChild(existingToolbar);
    }
  }

  private getToolbarProjectedWidth() {
    const toolbar = this.target.querySelector(".global-toolbar");
    const groups = toolbar.querySelectorAll(".group");

    let width = 0;
    groups.forEach((group) => {
      if (group.classList.contains("collapsed")) {
        width += 42 + 16 * 2;
      } else {
        const actions = group.querySelectorAll(".action");
        actions.forEach(() => {
          width += 42;
        });

        width += 16 * 2;
      }
    });

    return width;
  }

  private open() {
    this.toolbar.classList.add("active");
  }

  private close() {
    this.toolbar.classList.remove("active");
    this.toolbar.classList.add("closing");
    setTimeout(() => this.toolbar.classList.remove("closing"), 500);
    this.notifyAction(GlobalToolbarOld.TOOLBAR_CLOSE, "");
  }

  public show() {
    this.toolbar?.classList.remove("hidden");
  }

  public hide() {
    this.toolbar?.classList.add("hidden");
    this.close();
  }
}

export default GlobalToolbarOld;
