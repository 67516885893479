import { ColorPickerData, ColorPickerSubscriber, SwitchPicker } from "@zebrabi/design-library";
import { StackedDataPoint } from "../charting/stackedChart";
import { CHART_CONTAINER } from "../consts";
import * as d3 from "../d3";
import { LegendEntry } from "../interfaces";
import {
    BAR,
    CHANGE_COLOR,
    CLEAR_COLOR,
    FILL,
    G,
    HIGHLIGHT_COLOR,
    PATH,
    RECT,
    SAVE_COLOR,
    SWITCH_CHANGE,
    VARIANCE
} from "../library/constants";
import ContextMenu from "./base/ContextMenu";

export default class StackedChartMenu extends ContextMenu {
    protected template: string = `<div class="context-menu2 stacked-chart">
                                <ul class="menu-items"></ul>
                           </div>`;

    constructor(private readonly legendEntry: LegendEntry) {
        super();
    }

    protected addMenuItems(): void {
        const highlightSwitch = new SwitchPicker(this.chartSettings.isGroupHighlighted(this.legendEntry.groupName), {
            selectedColor: this.chartSettings.getGroupHighlightColor(this.legendEntry.groupName),
            label: "Highlight",
            pickerLabel: "Color",
            defaultColor: HIGHLIGHT_COLOR
        });

        const menuItemDiv = this.createMenuItem();
        highlightSwitch.appendTo(menuItemDiv);

        const highlightSub = new ColorPickerSubscriber();
        highlightSub.update = (message: ColorPickerData | undefined, action?: string): void => {
            if (action === SWITCH_CHANGE) {
                this.chartSettings.persistHighlightedGroups(this.legendEntry.groupName);
            } else if (action === SAVE_COLOR) {
                this.chartSettings.persistHighlightedGroupsCustomColors(this.legendEntry.groupName, message?.hex);
            } else if (action === CLEAR_COLOR) {
                this.chartSettings.persistHighlightedGroupsCustomColors(this.legendEntry.groupName, null);
            } else if (action === CHANGE_COLOR) {
                const chartAreas = d3.selectAll(`${G}.${CHART_CONTAINER}`);

                const paths = chartAreas.selectAll(`${PATH}.stack-area`).filter((d: StackedDataPoint[]) => {
                    return d[0]?.group === this.legendEntry.groupName;
                });
                const rects = chartAreas.selectAll(`${RECT}.${BAR}:not(.${VARIANCE})`).filter((d: StackedDataPoint) => {
                    return (d && d.category !== undefined && d.group === this.legendEntry.groupName);
                });

                paths.attr(FILL, message?.hex);
                rects.attr(FILL, message?.hex);
            }
        };

        highlightSwitch.subscribe(highlightSub);
    }
}