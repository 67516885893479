import BaseObserver from "../interface/BaseObserver";

/**
 * This class defines the basic structure of the subject, it's in use wherever a object needs to let other objects know
 * that something has changed
 */
abstract class BaseSubject<T, O extends BaseObserver<T>> {
  protected observers: O[] = [];

  /**
   * Attach an observer that will be notified when the conditions are met
   * @param {BaseObserver} observer
   * @throws Error
   */
  attach(observer: O): void {
    if (!observer.update)
      throw new Error("Cannot attach Observer without a update method! Object: " + observer.constructor.name);

    if (!this.observers.includes(observer)) {
      this.observers.push(observer);
    }
  }

  /**
   * Detach a observer that is no longer needed
   * @param observer {Type}
   */
  detach(observer: O): void {
    this.observers = this.observers.filter((item) => {
      if (item !== observer) {
        return item; // return all but the subject beign removed
      }
    });
  }

  /**
   * detaches all observer currently registered
   */
  detachAll() {
    this.observers = [];
  }

  /**
   * notifies all BaseObserver objects of the change
   * @param message {Type} - message to deliver
   * @param eventName {string} - name of the event that triggered the notification
   * @param target {string} - limit the notification to only the defined observer types
   */
  notify(message: T, eventName?: string, target?: string) {
    for (const observer of this.observers) {
      if (target === observer.getClassName() || !target) {
        observer.update(message, eventName);
      }
    }
  }
}

export default BaseSubject;
