import { ColorScheme } from "./../library/interfaces";
import {
    DataLabelUnitOptions, CategoryWidthOptions, CategoryDisplayOptions, CategoryLabelsOptions, ChartStyle,
    DifferenceLabel, VarianceDisplayType, BLACK, LEFT, EMPTY, DEFAULT_FONT, NORMAL, ITALIC, HIGHLIGHT_COLOR,
    SEGOE_UI_BOLD, SEGOE_UI, BOLD, Scenario, CommentBoxVariance, VarianceIcon, CommentBoxTitle, CommentBoxPlacement,
    GRAY, TRANSPARENT, FONT_SIZE_UNIT
} from "./../library/constants";
import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import { calculateRelativeDifferencePercent } from "./../viewModel/viewModelHelperFunctions";
import {
    getRelativeVarianceLabel, getVarianceDataLabel, getDiffHighlightAutoDataPoints, getDiffHighlightDataPoints,
    getFontFamily, getFontWeight
} from "./../charting/chart";
import {
    LabelDensity, NegativeValuesFormat, ShowTopNChartsOptions, GridlineStyle, Sort, ChartType,
    DifferenceHighlightFromTo, DifferenceHighlightArrowStyle, AxisLabelDensity, MultiplesAxisLabelsOptions,
    ReferenceDisplayType, DotChartMarkerShape, DifferenceHighlightEllipse, MarkerSize
} from "./../enums";

import {
    ScenarioOptions, DataPoint, ViewModel, ChartData, GlobalCategorySettings, GlobalLegendSettings,
    GlobalStackedChartSettings, DifferenceHighlightSettings, DataLabelSettings
} from "./../interfaces";
import {
    RESPONSIVE, ROWS_LAYOUT, AUTO_LAYOUT, LARGEST_FIRST_LAYOUT, VALUE, SECOND_SEGMENT_VALUE, START_POSITION,
    INTEGRATED, ACTUAL, ACTUAL_ABSOLUTE, ACTUAL_RELATIVE, ABSOLUTE, RELATIVE, ABSOLUTE_RELATIVE
} from "./../consts";
import { Visual } from "../visual";
import { persistManagerInstance } from "@zebrabi/office-settings/PersistManager";
import { MeasureRoles } from "@zebrabi/data-helpers/fieldAssignment";
import { licensing as officeLicensing } from "@zebrabi/licensing/Licensing";
import { ViewMode } from "@zebrabi/table-data";
import { OrganizationStyleData } from "@zebrabi/data-helpers/organizationStyles";

export const CHART_SETTINGS_NAME = "ChartSettings";

export class ChartSettings {
    public viewMode: ViewMode;
    public locale: string;

    // Pro features settings;
    public proFeaturesEnabled: boolean;
    public proFeaturesUnlocked: boolean;
    public proFeaturesDisabledByUser: boolean;

    // Chart settings
    public invert: boolean;
    public chartLayout: string;
    public showGrandTotal: boolean;
    public grandTotalLabel: string;
    public referenceDisplayType: ReferenceDisplayType;
    public plotOverlappedReference: boolean;
    public handleNullsAsZeros: boolean;
    public limitOutliers: boolean;
    public minOutlierValue: number;
    public maxOutlierValue: number;
    public gapBetweenColumnsPercent: number;
    public valueChartIntegrated: boolean;
    public suppressEmptyColumns: boolean;
    public showAllForecastData: boolean;

    public currentPeriodVarianceOptions: number;
    public currentPeriodVarianceCondition: number;
    public dayInMonthVarianceCondition: number;
    public dayInWeekVarianceCondition: number;
    public monthInQuarterVarianceCondition: number;
    public monthInYearVarianceCondition: number;

    // Comment Box
    public showCommentBox: boolean;
    public commentBoxTitle: CommentBoxTitle;
    public commentBoxCustomTitleStyle: boolean;
    public commentBoxTitleFontSize: number;
    public commentBoxTitleFontFamily: string;
    public commentBoxTitleFontColor: string;
    public commentBoxCustomTextStyle: boolean;
    public commentBoxTextFontSize: number;
    public commentBoxTextFontFamily: string;
    public commentBoxTextFontColor: string;
    public commentBoxPlacement: number;
    public commentBoxListHorizontal: boolean;
    public commentBoxSize: string;
    public commentBoxPadding: number;
    public commentBoxItemsMargin: number;
    public commentBoxBorderWidth: number;
    public commentBoxBorderColor: string;
    public commentBoxBorderRadius: number;
    public commentBoxShadow: boolean;
    public commentBoxBackgroundColor: string;
    public commentBoxShowVariance: CommentBoxVariance;
    public commentBoxVarianceIcon: VarianceIcon;
    public commentBoxExpandedGroup: string;
    public commentBoxUserChangedExpandedGroup: boolean;

    // Stacked chart
    public stackedChart: boolean;
    public stackedChartColors: number;
    public d3ColorScheme: number;
    public showTopNStackedOptions: ShowTopNChartsOptions;
    public topNStackedToKeep: number;
    public topNStackedPercentage: number;
    public topNStackedOthersLabel: string;
    public stackedAreaOpacity: number;
    public showStackedLabelsAsPercentage: boolean;
    public stackedChartSort: Sort;

    // Title
    public showTitle: boolean;
    public titleText: string;
    public titleWrap: boolean;
    public titleFontColor: string;
    public titleAlignment: string;
    public titleFontSize: number;
    public titleFontFamily: string;

    // Group title
    public groupTitleFontColor: string;
    public groupTitleAlignment: string;
    public groupTitleVerticalAlignment: string;
    public groupTitleFontSize: number;
    public groupTitleFontFamily: string;

    // Data labels
    public showDataLabels: boolean;
    public showReferenceLabels: boolean;
    public labelFontColor: string;
    public displayUnits: string;
    public showUnits: DataLabelUnitOptions;
    public decimalPlaces: number;
    public decimalPlacesPercentage: number;
    public labelDensity: LabelDensity;
    public labelPercentagePointUnit: string;
    public labelFontSize: number;
    public labelFontFamily: string;
    public labelBackgroundTransparency: number;
    public negativeValuesFormat: NegativeValuesFormat;
    public isPercentageData: boolean;
    public showPercentageInLabel: boolean;

    // Dot chart data labels
    public showDotChart: boolean;
    public dotChartFontColor: string;
    public dotChartDisplayUnits: string;
    public dotChartDecimalPlaces: number;
    public dotChartLabelDensity: LabelDensity;
    public isDotChartPercentageData: boolean;
    public dotChartMaxHeightPercent: number;
    public dotChartLineWidth: number;
    public dotChartLineStyle: GridlineStyle;
    public dotChartDroplineWidth: number;
    public dotChartDroplineColor: string;
    public dotChartMarkerSizing: MarkerSize;
    public dotChartMarkerFixedSize: number;
    public dotChartMarkerDensity: LabelDensity;
    public dotChartMarkerShape: DotChartMarkerShape;

    // Categories
    public showCategories: boolean;
    public categoryWidth: CategoryWidthOptions;
    public verticalCategoriesDisplay: CategoryDisplayOptions;    // vertical charts only
    public categoryMinWidth: number;
    public categoryLabelsOptions: CategoryLabelsOptions;
    public axisLabelDensity: AxisLabelDensity;
    public axisLabelDensityEveryNthLabel: number;
    public categoryRotateAngle: number;
    public categoryRotateAngleLimit: number;
    public rotatedCartegoriesHeight: number;
    public showTopNCategories: boolean;
    public topNCategoriesToKeep: number;
    public showCategoriesFontSettings: boolean;
    public categoriesFontColor: string;
    public categoriesFontSize: number;
    public categoriesFontFamily: string;
    public topNOtherLabel: string;

    // Legend
    public showLegend: boolean;
    public valueHeader: string;
    public absoluteDifferenceHeader: string;
    public relativeDifferenceHeader: string;
    public referenceHeader: string;
    public secondReferenceHeader: string;
    public secondAbsoluteDifferenceHeader: string;
    public secondRelativeDifferenceHeader: string;
    public secondValueHeader: string;
    public switchReferenceScenarios: boolean;
    public legendFontColor: string;

    public actual: string;
    public previousYear: string;
    public forecast: string;
    public plan: string;
    public actual_previousYear: string;
    public actual_previousYear_percent: string;
    public actual_forecast: string;
    public actual_forecast_percent: string;
    public actual_plan: string;
    public actual_plan_percent: string;
    public forecast_previousYear: string;
    public forecast_previousYear_percent: string;
    public forecast_plan: string;
    public forecast_plan_percent: string;
    public previousYear_plan: string;
    public previousYear_plan_percent: string;
    public defaultScenarioHeaders: object;
    public useAliasesInTooltips: boolean;
    public useColoredLegendNames: boolean;
    public legendItemsMargin: number;

    // Design
    public chartStyle: ChartStyle;
    public lightenOverlapped: boolean;
    public dotChartLineTransparency: number;
    public colorScheme: ColorScheme;
    public areaNeutralOpacity: number;
    public areaActualOpacity: number;
    public areaVarianceOpacity: number;
    public varianceDisplayType: VarianceDisplayType;
    public referenceMarkerSize: MarkerSize;
    public referenceMarkerFixedSize: number;
    public selectedOrganizationStyleId: number;

    // Interaction
    public allowInteractions: boolean;
    public showChartSlider: boolean;
    public allowVarianceCalculationChange: boolean;
    public allowDifferenceHighlightChange: boolean;
    public allowAxisBreakChange: boolean;
    public focusModeFontZoomPercentage: number;
    public enableMeasureDrillThrough: boolean;
    public enableStackedChartIconInViewMode: boolean;
    public allowInteractiveCommentBox: boolean;
    public allowInteractiveLegendSettings: boolean;

    // Small multiples
    public multiplesLayoutType: string;
    public multiplesSort: Sort;
    public multiples2dRowsSort: Sort;
    public multiples2dColumnsSort: Sort;
    public showTopNChartsOptions: ShowTopNChartsOptions;
    public topNChartsToKeep: number;
    public topNChartsPercentage: number;
    public topNChartsOthersLabel: string;
    public showMultiplesGrid: boolean;
    public multiplesGridlinesColor: string;
    public gridlineStyle: GridlineStyle;
    public showOuterBorders: boolean;
    public multiplesAxisLabelsOptions: MultiplesAxisLabelsOptions;
    public zoomedChartBackgroundColor: string;

    // Difference highlight
    public differenceHighlight: boolean;
    public differenceHighlightFromTo: number;
    public differenceLabel: DifferenceLabel;
    public varianceLabel: DifferenceLabel;
    public differenceHighlightLineWidth: number;
    public differenceHighlightArrowStyle: DifferenceHighlightArrowStyle;
    public differenceHighlightConnectingLineColor: string;
    public differenceHighlightConnectingLineStyle: GridlineStyle;
    public differenceHighlightCustomFont: boolean;
    public differenceHighlightFontSize: number;
    public differenceHighlightFontFamily: string;
    public differenceHighlightEllipse: DifferenceHighlightEllipse;
    public differenceHighlightEllipseBorderWidth: number;
    public differenceHighlightEllipseBorderPadding: number;
    public differenceHighlightEllipseFillOpacity: number;
    public differenceHighlightMargin: number;
    public showDifferenceHighlightSubtotals: boolean;

    // Axis break
    public hasAxisBreak: boolean;
    public axisBreakPercent: number;
    // About
    public expiryDate: Date;
    public company: string;

    // internal
    public chartType: ChartType;
    public disabledInViewMode: boolean;
    public scenarioOptions: ScenarioOptions;
    public multilineCategories: boolean;
    public invertedGroups: string[];
    public highlightedGroups: string[];
    public highlightedGroupsCustomColors: object[];
    public differenceHighlightWidth: number;
    public minChartHeight: number;
    public invertedCategories: string[];
    public resultCategories: string[];
    public scenarioCategories: object[];
    public highlightedCategories: string[];
    public highlightedCategoriesCustomColors: object[];
    public lockedChartTypesViewMode: string[];

    // License settings
    public licenseKey: string;
    public lastLicenseCheck: string;

    public showVerticalCharts: boolean;

    public measure1Role: string;
    public measure2Role: string;
    public measure3Role: string;
    public measure4Role: string;
    public measure5Role: string;
    public usedMeasuresCount: number;

    public enableFiltering: boolean;

    // eslint-disable-next-line max-lines-per-function
    constructor(showCompanyStyle: boolean, locale: string, scenarioOptions: ScenarioOptions) {
        this.locale = locale;

        this.measure1Role = MeasureRoles.Values;
        this.measure2Role = MeasureRoles.PreviousYear;
        this.measure3Role = MeasureRoles.Plan;
        this.measure4Role = MeasureRoles.Forecast;
        this.measure5Role = MeasureRoles.Comments;

        this.enableFiltering = Office.context.host === Office.HostType.Excel;

        //this.viewMode = options.viewMode;
        this.viewMode = ViewMode.Edit;
        this.invert = false;
        this.chartLayout = RESPONSIVE;
        this.referenceDisplayType = ReferenceDisplayType.OverlappedColumn;
        this.plotOverlappedReference = true;
        this.handleNullsAsZeros = false;
        this.limitOutliers = false;
        this.minOutlierValue = null;
        this.maxOutlierValue = null;
        this.showVerticalCharts = false;
        this.gapBetweenColumnsPercent = 20;
        this.valueChartIntegrated = false;
        this.suppressEmptyColumns = false;
        this.showAllForecastData = false;

        this.currentPeriodVarianceOptions = 0;
        this.currentPeriodVarianceCondition = 0;
        this.dayInMonthVarianceCondition = 15;
        this.dayInWeekVarianceCondition = 4;
        this.monthInQuarterVarianceCondition = 2;
        this.monthInYearVarianceCondition = 7;

        this.stackedChart = false;
        this.stackedChartColors = 0;
        this.d3ColorScheme = 1;
        this.showTopNStackedOptions = ShowTopNChartsOptions.Items;
        this.topNStackedToKeep = 5;
        this.topNStackedPercentage = 80;
        this.topNStackedOthersLabel = "Others";
        this.stackedAreaOpacity = 67;
        this.showStackedLabelsAsPercentage = false;
        this.stackedChartSort = Sort.Descending;

        this.colorScheme = {
            positiveColor: "#7aca00",
            negativeColor: "#ff0000",
            neutralColor: "#404040",
            markerColor: BLACK,
            lineColor: "#404040",
            axisColor: BLACK,
            gridlineColor: "#cccccc",
            majorGridlineColor: "#cccccc",
            dotChartColor: "#4080FF",
            useCustomScenarioColors: false,
            previousYearColor: BLACK,
            planColor: BLACK,
            forecastColor: BLACK,
            applyPatterns: true,
            highlightColor: HIGHLIGHT_COLOR,
        };
        this.showDataLabels = true;
        this.showReferenceLabels = false;
        this.multilineCategories = false;
        this.displayUnits = "Auto";
        this.showUnits = DataLabelUnitOptions.DataLabels;
        this.decimalPlaces = 1;
        this.decimalPlacesPercentage = 1;
        this.differenceHighlight = true;
        this.showDifferenceHighlightSubtotals = true;
        this.differenceLabel = DifferenceLabel.Relative;
        this.differenceHighlightFromTo = DifferenceHighlightFromTo.Auto;
        this.differenceHighlightLineWidth = 2;
        this.differenceHighlightArrowStyle = DifferenceHighlightArrowStyle.ClosedArrow;
        this.differenceHighlightConnectingLineColor = "#808080";
        this.differenceHighlightConnectingLineStyle = GridlineStyle.Solid;
        this.differenceHighlightCustomFont = false;
        this.differenceHighlightFontSize = 10;
        this.differenceHighlightFontFamily = DEFAULT_FONT;
        this.differenceHighlightEllipse = DifferenceHighlightEllipse.Off;
        this.differenceHighlightEllipseBorderWidth = 1;
        this.differenceHighlightEllipseBorderPadding = 4;
        this.differenceHighlightEllipseFillOpacity = 10;
        this.differenceHighlightMargin = 0;

        // Comment box settings
        this.showCommentBox = true;
        this.commentBoxCustomTitleStyle = false;
        this.commentBoxTitle = CommentBoxTitle.TitleValueVariance;
        this.commentBoxTitleFontColor = BLACK;
        this.commentBoxTitleFontFamily = DEFAULT_FONT;
        this.commentBoxTitleFontSize = 18;
        this.commentBoxCustomTextStyle = false;
        this.commentBoxTextFontColor = BLACK;
        this.commentBoxTextFontFamily = DEFAULT_FONT;
        this.commentBoxTextFontSize = 16;
        this.commentBoxPlacement = CommentBoxPlacement.Right;
        this.commentBoxSize = "0.66";
        this.commentBoxPadding = 10;
        this.commentBoxListHorizontal = false;
        this.commentBoxItemsMargin = 10;
        this.commentBoxBorderWidth = 0;
        this.commentBoxBorderColor = GRAY;
        this.commentBoxBorderRadius = 0;
        this.commentBoxShadow = false;
        this.commentBoxBackgroundColor = TRANSPARENT;
        this.commentBoxVarianceIcon = VarianceIcon.Triangle;
        this.commentBoxShowVariance = CommentBoxVariance.RelativeVariance;
        this.commentBoxExpandedGroup = EMPTY;
        this.commentBoxUserChangedExpandedGroup = false;

        this.hasAxisBreak = false;
        this.axisBreakPercent = 80;
        this.varianceLabel = DifferenceLabel.Absolute;
        this.showPercentageInLabel = true;
        this.varianceDisplayType = VarianceDisplayType.Bar;

        this.multiplesLayoutType = ROWS_LAYOUT;
        this.multiplesSort = Sort.Descending;
        this.multiples2dRowsSort = Sort.Descending;
        this.multiples2dColumnsSort = Sort.Descending;
        this.showTopNChartsOptions = ShowTopNChartsOptions.Off;
        this.topNChartsToKeep = 10;
        this.topNChartsPercentage = 80;
        this.topNChartsOthersLabel = "Others";
        this.showMultiplesGrid = false;
        this.multiplesGridlinesColor = this.colorScheme.gridlineColor;
        this.gridlineStyle = GridlineStyle.Solid;
        this.showOuterBorders = true;
        this.multiplesAxisLabelsOptions = MultiplesAxisLabelsOptions.AllCharts;
        this.zoomedChartBackgroundColor = "#fff";

        this.chartType = ChartType.Waterfall;
        this.labelDensity = LabelDensity.Auto;
        this.labelFontSize = 10;
        this.labelFontColor = BLACK;
        this.labelFontFamily = DEFAULT_FONT;
        this.labelPercentagePointUnit = "pp";
        this.labelBackgroundTransparency = 20;
        this.negativeValuesFormat = NegativeValuesFormat.Default;
        this.isPercentageData = false;

        this.showDotChart = true;
        this.dotChartFontColor = "#4080FF";
        this.dotChartDisplayUnits = "Auto";
        this.dotChartDecimalPlaces = 1;
        this.dotChartLabelDensity = LabelDensity.FirstLastMinMax;
        this.isDotChartPercentageData = false;
        this.dotChartMaxHeightPercent = 90;
        this.dotChartLineWidth = 1;
        this.dotChartLineStyle = GridlineStyle.Solid;
        this.dotChartMarkerShape = DotChartMarkerShape.Circle;
        this.dotChartMarkerDensity = LabelDensity.Auto;
        this.dotChartMarkerSizing = MarkerSize.Auto;
        this.dotChartMarkerFixedSize = this.labelFontSize;
        this.dotChartDroplineWidth = 0;
        this.dotChartDroplineColor = "#4080FF";

        this.chartStyle = showCompanyStyle ? ChartStyle.Company : ChartStyle.Zebra;
        this.lightenOverlapped = true;
        this.dotChartLineTransparency = 0;
        this.areaNeutralOpacity = 20;
        this.areaActualOpacity = 34;
        this.areaVarianceOpacity = 100;
        this.referenceMarkerSize = MarkerSize.Auto;
        this.referenceMarkerFixedSize = this.labelFontSize;

        this.showTitle = true;
        this.titleWrap = true;
        this.titleAlignment = LEFT;
        this.titleFontSize = 12;
        this.titleFontColor = BLACK;
        this.titleText = EMPTY;
        this.titleFontFamily = DEFAULT_FONT;
        this.groupTitleFontSize = 12;
        this.groupTitleFontColor = BLACK;
        this.groupTitleFontFamily = DEFAULT_FONT;
        this.groupTitleAlignment = LEFT;
        this.groupTitleVerticalAlignment = "Auto";

        this.showCategories = true;
        this.categoryWidth = CategoryWidthOptions.Auto;
        this.verticalCategoriesDisplay = CategoryDisplayOptions.Auto;
        this.categoryMinWidth = 35;
        this.categoryRotateAngle = 0;
        this.categoryRotateAngleLimit = 70;
        this.rotatedCartegoriesHeight = 0;
        this.categoryLabelsOptions = CategoryLabelsOptions.Trim;
        this.axisLabelDensity = AxisLabelDensity.All;
        this.axisLabelDensityEveryNthLabel = 5;
        this.showTopNCategories = false;
        this.topNCategoriesToKeep = 5;
        this.showCategoriesFontSettings = false;
        this.categoriesFontColor = this.labelFontColor;
        this.categoriesFontSize = this.labelFontSize;
        this.categoriesFontFamily = this.labelFontFamily;
        this.topNOtherLabel = "Others";

        this.showGrandTotal = false;
        this.grandTotalLabel = "Total";

        // Header/legend settings
        this.valueHeader = null;
        this.absoluteDifferenceHeader = null;
        this.relativeDifferenceHeader = null;
        this.referenceHeader = null;
        this.secondReferenceHeader = null;
        this.secondAbsoluteDifferenceHeader = null;
        this.secondRelativeDifferenceHeader = null;
        this.secondValueHeader = null;
        this.showLegend = true;
        this.switchReferenceScenarios = false;
        this.legendFontColor = this.labelFontColor;

        this.actual = null;
        this.previousYear = null;
        this.forecast = null;
        this.plan = null;
        this.actual_previousYear = null;
        this.actual_previousYear_percent = null;
        this.actual_plan = null;
        this.actual_plan_percent = null;
        this.actual_forecast = null;
        this.actual_forecast_percent = null;
        this.forecast_previousYear = null;
        this.forecast_previousYear_percent = null;
        this.forecast_plan = null;
        this.forecast_plan_percent = null;
        this.previousYear_plan = null;
        this.previousYear_plan_percent = null;
        this.useAliasesInTooltips = false;
        this.useColoredLegendNames = false;
        this.legendItemsMargin = 0;

        // Pro features settings
        this.proFeaturesEnabled = true;
        this.proFeaturesUnlocked = true;
        this.proFeaturesDisabledByUser = false;

        // License settings
        this.company = "Free version";
        this.expiryDate = null;
        this.disabledInViewMode = false;
        this.licenseKey = "";
        this.lastLicenseCheck = "";

        this.allowInteractions = true;
        this.showChartSlider = true;
        this.allowVarianceCalculationChange = true;
        this.allowDifferenceHighlightChange = true;
        this.allowAxisBreakChange = true;
        this.focusModeFontZoomPercentage = 150;
        this.enableMeasureDrillThrough = false;
        this.lockedChartTypesViewMode = [];
        this.enableStackedChartIconInViewMode = true;
        this.allowInteractiveCommentBox = true;
        this.allowInteractiveLegendSettings = true;

        this.invertedGroups = [];
        this.highlightedGroups = [];
        this.highlightedGroupsCustomColors = [];
        this.invertedCategories = [];
        this.resultCategories = [];
        this.scenarioCategories = [];
        this.highlightedCategories = [];
        this.highlightedCategoriesCustomColors = [];

        this.differenceHighlightWidth = 0;
        this.minChartHeight = null;

        // Scenario settings
        this.scenarioOptions = scenarioOptions;

        if (scenarioOptions && scenarioOptions.valueScenario !== null && scenarioOptions.referenceScenario === null) {
            this.chartType = ChartType.Bar;
        }

        // Overwrite settings with organization style if available
        let organizationStyleSettings = Visual.getInstance().getOrganizationStyleSettings();
        if (organizationStyleSettings) {
            this.setOrganizationStyleSettings(organizationStyleSettings);
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public setNewScenarioLegendValues() {
        this.actual_previousYear = this["actual-previousYear"] ?? this.actual_previousYear;
        this.actual_previousYear_percent = this["actual-previousYear-percent"] ?? this.actual_previousYear_percent;
        this.actual_plan = this["actual-plan"] ?? this.actual_plan;
        this.actual_plan_percent = this["actual-plan-percent"] ?? this.actual_plan_percent;
        this.actual_forecast = this["actual-forecast"] ?? this.actual_forecast;
        this.actual_forecast_percent = this["actual-forecast-percent"] ?? this.actual_forecast_percent;
        this.previousYear_plan = this["previousYear-plan"] ?? this.previousYear_plan;
        this.previousYear_plan_percent = this["previousYear-plan-percent"] ?? this.previousYear_plan_percent;
        this.forecast_previousYear = this["forecast-previousYear"] ?? this.forecast_previousYear;
        this.forecast_previousYear_percent = this["forecast-previousYear-percent"] ?? this.forecast_previousYear_percent;
        this.forecast_plan = this["forecast-plan"] ?? this.forecast_plan;
        this.forecast_plan_percent = this["forecast-plan-percent"] ?? this.forecast_plan_percent;
        const valueScenario = this.scenarioOptions.valueScenario;
        if (valueScenario !== null) {
            if (valueScenario === Scenario.Actual) {
                if (this.actual) {
                    this.valueHeader = this.actual;
                }
                else {
                    this.actual = this.valueHeader;
                }
            }
            else if (valueScenario === Scenario.PreviousYear) {
                if (this.previousYear) {
                    this.valueHeader = this.previousYear;
                }
                else {
                    this.previousYear = this.valueHeader;
                }
            }
            else if (valueScenario === Scenario.Plan) {
                if (this.plan) {
                    this.valueHeader = this.plan;
                }
                else {
                    this.plan = this.valueHeader;
                }
            }
            else if (valueScenario === Scenario.Forecast) {
                if (this.forecast) {
                    this.valueHeader = this.forecast;
                }
                else {
                    this.forecast = this.valueHeader;
                }
            }
        }

        const referenceScenario = this.scenarioOptions.referenceScenario;
        if (referenceScenario !== null) {
            if (referenceScenario === Scenario.PreviousYear) {
                if (this.previousYear) {
                    this.referenceHeader = this.previousYear;
                }
                else {
                    this.previousYear = this.referenceHeader;
                }

                if (valueScenario === Scenario.Actual) {
                    if (this.actual_previousYear) {
                        this.absoluteDifferenceHeader = this.actual_previousYear;
                    }
                    else {
                        this.actual_previousYear = this.absoluteDifferenceHeader;
                    }
                    if (this.actual_previousYear_percent) {
                        this.relativeDifferenceHeader = this.actual_previousYear_percent;
                    }
                    else {
                        this.actual_previousYear_percent = this.relativeDifferenceHeader;
                    }
                }
                else if (valueScenario === Scenario.Forecast) {
                    if (this.forecast_previousYear) {
                        this.absoluteDifferenceHeader = this.forecast_previousYear;
                    }
                    else {
                        this.forecast_previousYear = this.absoluteDifferenceHeader;
                    }
                    if (this.forecast_previousYear_percent) {
                        this.relativeDifferenceHeader = this.forecast_previousYear_percent;
                    }
                    else {
                        this.forecast_previousYear_percent = this.relativeDifferenceHeader;
                    }
                }
            }
            else if (referenceScenario === Scenario.Plan) {
                if (this.plan) {
                    this.referenceHeader = this.plan;
                }
                else {
                    this.plan = this.referenceHeader;
                }

                if (valueScenario === Scenario.Actual) {
                    if (this.actual_plan) {
                        this.absoluteDifferenceHeader = this.actual_plan;
                    }
                    else {
                        this.actual_plan = this.absoluteDifferenceHeader;
                    }
                    if (this.actual_plan_percent) {
                        this.relativeDifferenceHeader = this.actual_plan_percent;
                    }
                    else {
                        this.actual_plan_percent = this.relativeDifferenceHeader;
                    }
                }
                else if (valueScenario === Scenario.Forecast) {
                    if (this.forecast_plan) {
                        this.absoluteDifferenceHeader = this.forecast_plan;
                    }
                    else {
                        this.forecast_plan = this.absoluteDifferenceHeader;
                    }
                    if (this.forecast_plan_percent) {
                        this.relativeDifferenceHeader = this.forecast_plan_percent;
                    }
                    else {
                        this.forecast_plan_percent = this.relativeDifferenceHeader;
                    }
                }
                else if (valueScenario === Scenario.PreviousYear) {
                    if (this.previousYear_plan) {
                        this.absoluteDifferenceHeader = this.previousYear_plan;
                    }
                    else {
                        this.previousYear_plan = this.absoluteDifferenceHeader;
                    }

                    if (this.previousYear_plan_percent) {
                        this.relativeDifferenceHeader = this.previousYear_plan_percent;
                    }
                    else {
                        this.previousYear_plan_percent = this.relativeDifferenceHeader;
                    }
                }
            }
            else if (referenceScenario === Scenario.Forecast) {
                if (this.forecast) {
                    this.referenceHeader = this.forecast;
                }
                else {
                    this.forecast = this.referenceHeader;
                }

                if (this.actual_forecast) {
                    this.absoluteDifferenceHeader = this.actual_forecast;
                }
                else {
                    this.actual_forecast = this.absoluteDifferenceHeader;
                }
                if (this.actual_forecast_percent) {
                    this.relativeDifferenceHeader = this.actual_forecast_percent;
                }
                else {
                    this.actual_forecast_percent = this.relativeDifferenceHeader;
                }
            }
        }

        const secondReferenceScenario = this.scenarioOptions.secondReferenceScenario;
        if (secondReferenceScenario !== null) {
            if (secondReferenceScenario === Scenario.PreviousYear) {
                if (this.previousYear) {
                    this.secondReferenceHeader = this.previousYear;
                }
                else {
                    this.previousYear = this.secondReferenceHeader;
                }

                if (valueScenario === Scenario.Actual) {
                    if (this.actual_previousYear) {
                        this.secondAbsoluteDifferenceHeader = this.actual_previousYear;
                    }
                    else {
                        this.actual_previousYear = this.secondAbsoluteDifferenceHeader;
                    }
                    if (this.actual_previousYear_percent) {
                        this.secondRelativeDifferenceHeader = this.actual_previousYear_percent;
                    }
                    else {
                        this.actual_previousYear_percent = this.secondRelativeDifferenceHeader;
                    }
                }
                else if (valueScenario === Scenario.Forecast) {
                    if (this.forecast_previousYear) {
                        this.secondAbsoluteDifferenceHeader = this.forecast_previousYear;
                    }
                    else {
                        this.forecast_previousYear = this.secondAbsoluteDifferenceHeader;
                    }
                    if (this.forecast_previousYear_percent) {
                        this.secondRelativeDifferenceHeader = this.forecast_previousYear_percent;
                    }
                    else {
                        this.forecast_previousYear_percent = this.secondRelativeDifferenceHeader;
                    }
                }
            }
            else if (secondReferenceScenario === Scenario.Plan) {
                if (this.plan) {
                    this.secondReferenceHeader = this.plan;
                }
                else {
                    this.plan = this.secondReferenceHeader;
                }

                if (valueScenario === Scenario.Actual) {
                    if (this.actual_plan) {
                        this.secondAbsoluteDifferenceHeader = this.actual_plan;
                    }
                    else {
                        this.actual_plan = this.secondAbsoluteDifferenceHeader;
                    }
                    if (this.actual_plan_percent) {
                        this.secondRelativeDifferenceHeader = this.actual_plan_percent;
                    }
                    else {
                        this.actual_plan_percent = this.secondRelativeDifferenceHeader;
                    }
                }
                else if (valueScenario === Scenario.Forecast) {
                    if (this.forecast_plan) {
                        this.secondAbsoluteDifferenceHeader = this.forecast_plan;
                    }
                    else {
                        this.forecast_plan = this.secondAbsoluteDifferenceHeader;
                    }
                    if (this.forecast_plan_percent) {
                        this.secondRelativeDifferenceHeader = this.forecast_plan_percent;
                    }
                    else {
                        this.forecast_plan_percent = this.secondRelativeDifferenceHeader;
                    }
                }
            }
        }

        if (this.scenarioOptions.secondValueScenario !== null) {
            if (this.forecast) {
                this.secondValueHeader = this.forecast;
            }
            else {
                this.forecast = this.secondValueHeader;
            }
        }
    }

    public getRealInteractionSettingValue(settingValue: boolean): boolean {
        return true && (this.viewMode !== ViewMode.View || this.allowInteractions && settingValue);
    }

    public getMinCategoryWidth(chartType: ChartType): number {
        return chartType === ChartType.Variance ? 32 : 25;
    }

    public showNegativeValuesInParenthesis(): boolean {
        return this.negativeValuesFormat === NegativeValuesFormat.Parenthesis;
    }

    /**
     * Measures the width (in pixels) of a text, given the font
     *
     * @param text
     * @param fontSize
     * @param fontFamily
     * @param fontWeight
     * @returns number
     */
    public getLegendTextWidth(text: string, fontSize: number, fontFamily: string, fontWeight: string): number {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        context.font = `${fontWeight} ${fontSize}${FONT_SIZE_UNIT} ${fontFamily}`;

        return Math.ceil(context.measureText(text).width);
    }

    /**
     * Measures the height (in pixels) of a text, given the font
     *
     * @param text
     * @param fontSize
     * @param fontFamily
     * @param fontWeight
     * @returns number
     */
    public getLegendTextHeight(text: string, fontSize: number, fontFamily: string, fontWeight: string): number {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;

        return Math.ceil(context.measureText(text).actualBoundingBoxAscent + context.measureText(text).actualBoundingBoxDescent);
    }

    // eslint-disable-next-line max-lines-per-function
    public getLegendWidth(element?: HTMLDivElement): number {
        let fontFamily = this.labelFontFamily;
        let fontWeight = NORMAL;
        if (this.labelFontFamily === SEGOE_UI_BOLD) {
            fontFamily = SEGOE_UI;
            fontWeight = BOLD;
        }
        let legendWidth = 30;
        if (this.chartType !== ChartType.Waterfall) {
            legendWidth = this.getLegendTextWidth(element?.textContent, this.labelFontSize, fontWeight, fontFamily);
        }
        if (this.chartType === ChartType.Line || this.chartType === ChartType.Area || this.chartType === ChartType.Pin) {
            legendWidth = this.getLegendTextWidth(this.valueHeader, this.labelFontSize, fontFamily, fontWeight);
            if (this.scenarioOptions.referenceScenario !== null) {
                legendWidth = Math.max(legendWidth, this.getLegendTextWidth(this.referenceHeader, this.labelFontSize, fontFamily, fontWeight));
            }
            if (this.scenarioOptions.secondReferenceScenario !== null) {
                legendWidth = Math.max(legendWidth, this.getLegendTextWidth(this.secondReferenceHeader, this.labelFontSize, fontFamily, fontWeight));
            }
        }
        else if (this.chartType === ChartType.Waterfall) {
            if (this.chartLayout === RESPONSIVE) {
                if (this.scenarioOptions.referenceScenario !== null) {
                    legendWidth = Math.max(legendWidth, this.getLegendTextWidth(this.relativeDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
                if (this.scenarioOptions.secondReferenceScenario !== null) {
                    legendWidth = Math.max(legendWidth,
                        this.getLegendTextWidth(this.secondAbsoluteDifferenceHeader, this.labelFontSize, fontFamily, fontWeight),
                        this.getLegendTextWidth(this.secondRelativeDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
            }
            else {
                legendWidth = 0;
            }
        }
        else if (this.chartType === ChartType.Variance || this.chartType === ChartType.Bar) {
            legendWidth = this.getLegendTextWidth(this.valueHeader, this.labelFontSize, fontFamily, fontWeight);
            if (this.chartLayout === RESPONSIVE) {
                if (this.scenarioOptions.referenceScenario !== null) {
                    legendWidth = Math.max(legendWidth,
                        this.getLegendTextWidth(this.referenceHeader, this.labelFontSize, fontFamily, fontWeight),
                        this.getLegendTextWidth(this.absoluteDifferenceHeader, this.labelFontSize, fontFamily, fontWeight),
                        this.getLegendTextWidth(this.relativeDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
                if (this.scenarioOptions.secondReferenceScenario !== null) {
                    legendWidth = Math.max(legendWidth,
                        this.getLegendTextWidth(this.secondReferenceHeader, this.labelFontSize, fontFamily, fontWeight),
                        this.getLegendTextWidth(this.secondAbsoluteDifferenceHeader, this.labelFontSize, fontFamily, fontWeight),
                        this.getLegendTextWidth(this.secondRelativeDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
            }
            else if (this.chartLayout === INTEGRATED) {
                if (this.scenarioOptions.referenceScenario !== null) {
                    legendWidth = Math.max(legendWidth,
                        this.getLegendTextWidth(this.referenceHeader, this.labelFontSize, fontFamily, fontWeight),
                        this.getLegendTextWidth(this.absoluteDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
                if (this.scenarioOptions.secondReferenceScenario !== null) {
                    legendWidth = Math.max(legendWidth,
                        this.getLegendTextWidth(this.secondReferenceHeader, this.labelFontSize, fontFamily, fontWeight),
                        this.getLegendTextWidth(this.secondAbsoluteDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
            }
            else if (this.chartLayout === ABSOLUTE) {
                legendWidth = this.getLegendTextWidth(this.absoluteDifferenceHeader, this.labelFontSize, fontFamily, fontWeight);
                if (this.scenarioOptions.secondReferenceScenario !== null) {
                    legendWidth = Math.max(legendWidth, this.getLegendTextWidth(this.secondAbsoluteDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
            }
            else if (this.chartLayout === RELATIVE) {
                legendWidth = this.getLegendTextWidth(this.relativeDifferenceHeader, this.labelFontSize, fontFamily, fontWeight);
                if (this.scenarioOptions.secondReferenceScenario !== null) {
                    legendWidth = Math.max(legendWidth, this.getLegendTextWidth(this.secondRelativeDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
            }
            else if (this.chartLayout === ABSOLUTE_RELATIVE) {
                legendWidth = Math.max(this.getLegendTextWidth(this.absoluteDifferenceHeader, this.labelFontSize, fontFamily, fontWeight),
                    this.getLegendTextWidth(this.relativeDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                if (this.scenarioOptions.secondReferenceScenario !== null) {
                    legendWidth = Math.max(legendWidth,
                        this.getLegendTextWidth(this.secondAbsoluteDifferenceHeader, this.labelFontSize, fontFamily, fontWeight),
                        this.getLegendTextWidth(this.secondRelativeDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
            }
            else if (this.chartLayout === ACTUAL) {
                if (this.scenarioOptions.referenceScenario !== null) {
                    legendWidth = Math.max(legendWidth,
                        this.getLegendTextWidth(this.referenceHeader, this.labelFontSize, fontFamily, fontWeight),
                        this.getLegendTextWidth(this.secondReferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
            }
            else if (this.chartLayout === ACTUAL_ABSOLUTE) {
                legendWidth = Math.max(legendWidth, this.getLegendTextWidth(this.absoluteDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                if (this.scenarioOptions.referenceScenario !== null) {
                    legendWidth = Math.max(legendWidth, this.getLegendTextWidth(this.referenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
                if (this.scenarioOptions.secondReferenceScenario !== null) {
                    legendWidth = Math.max(
                        legendWidth,
                        this.getLegendTextWidth(this.secondReferenceHeader, this.labelFontSize, fontFamily, fontWeight),
                        this.getLegendTextWidth(this.secondAbsoluteDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
            }
            else if (this.chartLayout === ACTUAL_RELATIVE) {
                legendWidth = Math.max(legendWidth, this.getLegendTextWidth(this.relativeDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                if (this.scenarioOptions.referenceScenario !== null) {
                    legendWidth = Math.max(legendWidth, this.getLegendTextWidth(this.referenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
                if (this.scenarioOptions.secondReferenceScenario !== null) {
                    legendWidth = Math.max(legendWidth,
                        this.getLegendTextWidth(this.secondReferenceHeader, this.labelFontSize, fontFamily, fontWeight),
                        this.getLegendTextWidth(this.secondRelativeDifferenceHeader, this.labelFontSize, fontFamily, fontWeight));
                }
            }
        }

        return Math.ceil(legendWidth + 10 + this.legendItemsMargin);
    }

    public getRightLegendWidth() {
        return this.getLegendTextWidth(this.secondValueHeader, this.labelFontSize, getFontFamily(this.labelFontFamily), getFontWeight(this.labelFontFamily)) + 10;
    }

    /**
     * Returns appropriate legend item text color based on scenario and user setting Use colored legend names.
     *
     * @param {boolean} [isLegendItemColored = false] - excludes deltas PL, PY e.g. as called in plusMinus and plusMinusDot charts
     * @param {Scenario} [scenario = Scenario.Actual]
     * @returns - color string in HEX
     */
    public getLegendColor(isLegendItemColored: boolean = false, scenario: Scenario = Scenario.Actual): string {

        if (isLegendItemColored && this.useColoredLegendNames && this.chartStyle === ChartStyle.Custom) {

            if (this.colorScheme.useCustomScenarioColors) {
                switch (scenario) {
                    case Scenario.PreviousYear:
                        return this.isPreviousYearColorLighter(scenario, isLegendItemColored) ? styles.getLighterColor(this.colorScheme.previousYearColor) : this.colorScheme.previousYearColor;
                    case Scenario.Plan:
                        return this.colorScheme.planColor;
                    case Scenario.Forecast:
                        return this.colorScheme.forecastColor;
                }
            }

            const chartColor = this.chartType === ChartType.Line || this.chartType === ChartType.Area ? this.colorScheme.markerColor : this.colorScheme.neutralColor;
            return this.isPreviousYearColorLighter(scenario, isLegendItemColored) ? styles.getLighterColor(chartColor) : chartColor;
        }

        return this.isPreviousYearColorLighter(scenario, isLegendItemColored) ? styles.getLighterColor(this.legendFontColor) : this.legendFontColor;
    }

    /**
     * Returns if previous year legend item fulfills condition to be colored with lighter shade.
     *
     * @param scenario
     * @param {boolean} [isLegendItemColored = false] - excludes deltas PL, PY e.g. as called in plusMinus and plusMinusDot charts
     * @returns boolean
     */
    private isPreviousYearColorLighter(scenario: Scenario, isLegendItemColored: boolean = false): boolean {
        return isLegendItemColored && scenario === Scenario.PreviousYear && this.colorScheme.applyPatterns;
    }

    public shouldPlotExtendedChartsMultiples(): boolean {
        return this.chartType === ChartType.Variance
            && this.multiplesLayoutType === ROWS_LAYOUT
            && (this.chartLayout === ACTUAL_ABSOLUTE || this.chartLayout === ACTUAL_RELATIVE || this.chartLayout === ABSOLUTE_RELATIVE);
    }

    public getAvailableChartTypes(isSingleValueViewModel: boolean, isSingleSeriesViewModel: boolean): ChartType[] {
        const plotVertical = this.shouldPlotVerticalCharts();
        let chartTypes = [];
        if (isSingleValueViewModel) {
            chartTypes = [ChartType.Bar, ChartType.Line];
        }
        else if (isSingleSeriesViewModel) {
            if (plotVertical) {
                chartTypes = [ChartType.Bar, ChartType.Pin, ChartType.Waterfall];
            }
            else {
                chartTypes = [ChartType.Bar, ChartType.Line, ChartType.Area, ChartType.Pin, ChartType.Waterfall];
            }
        }
        else {
            if (plotVertical) {
                chartTypes = [ChartType.Waterfall, ChartType.Variance];
            }
            else {
                chartTypes = [ChartType.Waterfall, ChartType.Variance, ChartType.Area, ChartType.Line];
            }
        }

        if (this.viewMode === ViewMode.View && this.lockedChartTypesViewMode.length > 0) {
            const prefix = isSingleSeriesViewModel || isSingleValueViewModel ? "1" : "2";
            this.lockedChartTypesViewMode.filter(c => c.startsWith(prefix)).forEach((t) => {
                if (chartTypes.length > 1) {
                    chartTypes = chartTypes.filter(ct => ct !== this.getChartTypeFromEncoding(t));
                }
            });
        }

        // Put in the "Ad chart type"
        if (!officeLicensing.proFeaturesUnlocked()) {
            chartTypes.push(ChartType.Advert);
        }

        return chartTypes;
    }

    public getNextAvailableChartType(isSingleValueViewModel: boolean, isSingleSeriesViewModel: boolean): ChartType {
        const availableChartTypes = this.getAvailableChartTypes(isSingleValueViewModel, isSingleSeriesViewModel);
        const currentIndex = availableChartTypes.indexOf(this.chartType);
        if (currentIndex === -1 || currentIndex === availableChartTypes.length - 1) {
            return availableChartTypes[0];
        }
        else {
            return availableChartTypes[currentIndex + 1];
        }
    }

    public getPreviousAvailableChartType(isSingleValueViewModel: boolean, isSingleSeriesViewModel: boolean): ChartType {
        const availableChartTypes = this.getAvailableChartTypes(isSingleValueViewModel, isSingleSeriesViewModel);
        const currentIndex = availableChartTypes.indexOf(this.chartType);
        if (currentIndex === -1) {
            return availableChartTypes[0];
        }
        else {
            return currentIndex === 0 ? availableChartTypes[availableChartTypes.length - 1] : availableChartTypes[currentIndex - 1];
        }
    }

    public setNextChartType(viewModel: ViewModel): boolean {
        if (this.shouldPlotStackedChart(viewModel.isMultiples)) {
            if (this.shouldPlotVerticalCharts()) {
                this.chartType = ChartType.Bar;
                return false;
            }
            else {
                this.chartType = this.chartType === ChartType.Area ? ChartType.Bar : ChartType.Area;
                return true;
            }
        }
        const current = this.chartType;
        this.chartType = this.getNextAvailableChartType(viewModel.isSingleValueViewModel, viewModel.isSingleSeriesViewModel);
        return current !== this.chartType;
    }

    public setPreviousChartType(viewModel: ViewModel): boolean {
        if (this.shouldPlotStackedChart(viewModel.isMultiples)) {
            if (this.shouldPlotVerticalCharts()) {
                this.chartType = ChartType.Bar;
                return false;
            }
            else {
                this.chartType = this.chartType === ChartType.Area ? ChartType.Bar : ChartType.Area;
                return true;
            }
        }
        const currentChartType = this.chartType;
        this.chartType = this.getPreviousAvailableChartType(viewModel.isSingleValueViewModel, viewModel.isSingleSeriesViewModel);
        return currentChartType !== this.chartType;
    }

    public persistChartType() {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistMinChartHeight() {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistAxisBreak() {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistDataLabels() {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistDiffHighlightChange() {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistInvertedGroups(cd: ChartData) {
        this.invertedGroupsChange(cd);
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistHighlightedGroups(group: string) {
        this.highlightedGroupsChange(group);
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistHighlightedGroupsCustomColors(group: string, color: string) {
        this.highlightedGroupsCustomColorChange(group, color);
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistInvertedCategoriesArray(invertedCategories: string[]) {
        this.invertedCategories = invertedCategories;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistHighlightedCategoriesArray(highlightedCategories: string[]) {
        this.highlightedCategories = highlightedCategories;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistInvertedCategories(category: string) {
        this.invertedCategoriesChange(category);
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistHighlightedCategories(category: string) {
        this.highlightedCategoriesChange(category);
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistCustomHighlightColorObject() {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistCustomHighlightColor(category: string, color: string) {
        this.highlightedCategoriesCustomColorChange(category, color);
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistIsResultCategoriesObject(resultCategories: string[]) {
        this.resultCategories = resultCategories;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistIsResultCategories(category: string) {
        this.isCategoryResultChange(category);
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistTitle(value: string) {
        const properties: any = {};
        properties.text = value;
        this.titleText = value;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistGlobalCategorySettings(globalCategorySettings: GlobalCategorySettings) {
        for (const [key, value] of Object.entries(globalCategorySettings)) {
            this[key] = value;
        }
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistGlobalStackedChartSettings(globalStackedChartSettings: GlobalStackedChartSettings) {
        for (const [key, value] of Object.entries(globalStackedChartSettings)) {
            this[key] = value;
        }
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    /**
     * Saves newly renamed legend entry to its appropriate legacy setting (or scenario)
     *
     * @param legendEntry is a setting key as used in capabilities file e.g "actual-previousYear"
     * @param value is new value entered by the user directly on legend entry on the visual container
     * @param visualHost
     */
    public persistLegendEntries(legendEntry: keyof ChartSettings, value: string) {
        //let properties: any = this.cleanLegendEntriesFromSettings();
        const properties: any = {};
        this[this.mapLegendEntry(legendEntry)] = value;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    /**
     * Resets empty string legend entry with its default value e.g. AC, PY etc.
     *
     * @param legendEntry is a setting key as used in capabilities file e.g "actual-previousYear"
     */
    public setEmptyLegendEntryToDefault(legendEntry: string) {
        if (!this.defaultScenarioHeaders) {
            return null;
        }
        const checkLength = Object.values(this.defaultScenarioHeaders).filter(d => d !== null);
        if (checkLength.length > 0) {
            return this.defaultScenarioHeaders[legendEntry];
        }
    }

    /**
     * Casts key string into underscored or dashed used in capabilities or ChartSettings e.g. key-text or key_text
     * probably not needed anymore
     * @param legendEntryScenario
     * @param castToDash
     * @returns string
     */
    public mapLegendEntryOlD(legendEntryScenario: string, castToDash: boolean = false): string {
        if (legendEntryScenario.indexOf("-") > -1) {
            return legendEntryScenario.replace("-", "_");
        } else if (castToDash && legendEntryScenario.indexOf("_") > -1) {
            return legendEntryScenario.replace("_", "-");
        } else {
            return legendEntryScenario;
        }
    }

    /**
     * Casts key string used in ChartSettings into dashed version used in capabilities e.g. key_text -> key-text
     *
     * @param legendEntryScenario
     * @returns string
     */
    public mapLegendEntry(legendEntryScenario: keyof ChartSettings): string {
        return legendEntryScenario.split("_").join("-");
    }

    /**
     * Sets scenario option properties from capabilities to null.
     */
    public cleanLegendEntriesFromSettings(): any {
        return {
            "actual": null,
            "previousYear": null,
            "forecast": null,
            "plan": null,
            "actual-previousYear": null,
            "actual-previousYear-percent": null,
            "actual-plan": null,
            "actual-plan-percent": null,
            "actual-forecast": null,
            "actual-forecast-percent": null,
            "previousYear-plan": null,
            "previousYear-plan-percent": null,
            "forecast-previousYear": null,
            "forecast-previousYear-percent": null,
            "forecast-plan": null,
            "forecast-plan-percent": null
        };
    }

    public invertedGroupsChange(cd: ChartData) {
        if (this.isGroupInverted(cd.group)) {
            this.invertedGroups = this.invertedGroups.filter(g => g !== cd.group);
        }
        else {
            this.invertedGroups.push(cd.group);
        }
    }

    public isGroupInverted(group: string): boolean {
        return !!group && this.invertedGroups.indexOf(group) > -1;
    }

    public getInvert(group: string): boolean {
        const isGroupInverted = this.isGroupInverted(group);
        if (this.invert) {
            return !isGroupInverted;
        }
        else {
            return isGroupInverted;
        }
    }

    public isCategoryInverted(category: string): boolean {
        return !!category && this.invertedCategories.indexOf(category) > -1;
    }

    public isCategoryHighlighted(category: string): boolean {
        return !!category && this.highlightedCategories.length && this.highlightedCategories.indexOf(category) > -1;
    }

    public isGroupHighlighted(group: string): boolean {
        return !!group && this.highlightedGroups.length && this.highlightedGroups.indexOf(group) > -1;
    }

    private invertedCategoriesChange(category: string) {
        if (this.isCategoryInverted(category)) {
            this.invertedCategories = this.invertedCategories.filter(g => g !== category);
        }
        else {
            this.invertedCategories.push(category);
        }
    }

    private highlightedCategoriesChange(category: string) {
        if (this.isCategoryHighlighted(category)) {
            this.highlightedCategories = this.highlightedCategories.filter(g => g !== category);
        }
        else {
            this.highlightedCategories.push(category);
        }
    }

    private highlightedGroupsChange(group: string) {
        if (this.isGroupHighlighted(group)) {
            this.highlightedGroups = this.highlightedGroups.filter(g => g !== group);
        }
        else {
            this.highlightedGroups.push(group);
        }
    }

    private highlightedGroupsCustomColorChange(group: string, color: string) {
        const existingCustomGroupColor = this.highlightedGroupsCustomColors.find(c => c[group]);
        if (!color) {
            if (existingCustomGroupColor) {
                this.highlightedGroupsCustomColors = this.highlightedGroupsCustomColors.filter(c => !c[group]);
            }
        }
        else {
            if (existingCustomGroupColor) {
                existingCustomGroupColor[group] = color;
            }
            else {
                this.highlightedGroupsCustomColors.push({ [group]: color });
            }
        }
    }

    private highlightedCategoriesCustomColorChange(category: string, color: string) {
        const existingCustomCategoryColor = this.highlightedCategoriesCustomColors.find(c => c[category]);
        if (!color) {
            if (existingCustomCategoryColor) {
                this.highlightedCategoriesCustomColors = this.highlightedCategoriesCustomColors.filter(c => !c[category]);
            }
        }
        else {
            if (existingCustomCategoryColor) {
                existingCustomCategoryColor[category] = color;
            }
            else {
                this.highlightedCategoriesCustomColors.push({ [category]: color });
            }
        }
    }

    /**
     * Determines whether basic chart element should be colored with highlight color.
     * As of v5.3: Not applicable in case of grand total and variance items on charts.
     *
     * @param dataPoint
     * @param group
     * @param color
     * @returns string
     */
    public getValueDataPointColor(dataPoint: DataPoint, group: string, color?: string): string {
        return this.isCategoryHighlighted(dataPoint.category) ? this.getCategoryHighlightColor(dataPoint.category) : this.getGroupDataPointColor(group, color ? color : dataPoint.color);
    }

    /**
     * Determines whether element should be colored with highlight color, based on category e.g. reference markers.
     *
     * @param category
     * @param color
     * @returns string
     */
    public getCategoryDataPointColor(category: string, color: string): string {
        return this.isCategoryHighlighted(category) ? this.getCategoryHighlightColor(category) : color;
    }

    /**
     * Determines whether element should be colored with highlight color, based on group.
     *
     * @param group
     * @param color
     * @returns string
     */
    public getGroupDataPointColor(group: string, color: string): string {
        return this.isGroupHighlighted(group) ? this.getGroupHighlightColor(group) : color;
    }

    private isCategoryResultChange(category: string) {
        if (this.isCategoryResult(category)) {
            this.resultCategories = this.resultCategories.filter(c => c !== category);
        }
        else {
            this.resultCategories.push(category);
        }
    }

    public isCategoryResult(category: string): boolean {
        return this.resultCategories.indexOf(category) > -1;
    }

    private setScenarioCategories(category: string, scenario: Scenario) {
        const existingCategories = Object.keys(Object.assign({}, ...this.scenarioCategories));

        if (existingCategories.indexOf(category) > -1) {
            this.scenarioCategories[existingCategories.indexOf(category)][category] = scenario;
        } else {
            this.scenarioCategories.push({ [category]: scenario });
        }
    }

    public shouldHideDataLabelUnits(): boolean {
        return this.displayUnits !== "Auto" && this.displayUnits !== "None" && this.showUnits !== DataLabelUnitOptions.DataLabels;
    }

    // eslint-disable-next-line max-lines-per-function
    public calculateDifferenceHighlights(viewModel: ViewModel) {
        if (!this.differenceHighlight) {
            this.differenceHighlightWidth = 0;
            return;
        }
        let maxDiffHighlightLabelWidth = 0;
        const labelsFormat = formatting.getPercentageFormatOrNull(this.isPercentageData, this.labelPercentagePointUnit, true, true);
        const hideUnits = this.shouldHideDataLabelUnits();

        viewModel.chartData.forEach(cd => {
            let valueProperty: keyof DataPoint;
            let fromDataPoint: DataPoint;
            let toDataPoint: DataPoint;
            let startValue: number = null;
            let endValue: number = null;
            const valuesDataPoints = cd.dataPoints.filter(dp => dp.value !== null);
            const hasSecondSegmentValues = this.scenarioOptions.secondValueScenario !== null;
            const secondSegmentValuesDataPoints = hasSecondSegmentValues ? cd.dataPoints.filter(dp => dp.value === null && dp.secondSegmentValue !== null) : [];
            const shouldUseSecondSegmentValueProperty = hasSecondSegmentValues && this.isSecondSegmentDiffHighlight();
            valueProperty = shouldUseSecondSegmentValueProperty ? SECOND_SEGMENT_VALUE : VALUE;

            if (this.chartType === ChartType.Waterfall) {
                if (this.differenceHighlightFromTo === DifferenceHighlightFromTo.Auto) {
                    const dhPoints = getDiffHighlightAutoDataPoints(cd.dataPoints, ChartType.Waterfall, false);
                    fromDataPoint = dhPoints.fromDataPoint;
                    toDataPoint = dhPoints.toDataPoint;
                }
                else if (shouldUseSecondSegmentValueProperty && this.differenceHighlightFromTo === DifferenceHighlightFromTo.LastACToLastFC
                    && valuesDataPoints.length !== 0 && secondSegmentValuesDataPoints.length !== 0) {
                    fromDataPoint = valuesDataPoints[valuesDataPoints.length - 1];
                    toDataPoint = secondSegmentValuesDataPoints[secondSegmentValuesDataPoints.length - 1];
                }
                else {
                    const diffHighlightDataPoints = shouldUseSecondSegmentValueProperty ?
                        cd.dataPoints.filter(p => p.secondSegmentValue !== null && p.isVariance) :
                        cd.dataPoints.filter(p => p.secondSegmentValue === null && p.isVariance);
                    const fromToDataPoints = getDiffHighlightDataPoints(diffHighlightDataPoints, this.differenceHighlightFromTo, true);
                    fromDataPoint = fromToDataPoints.fromDP;
                    toDataPoint = fromToDataPoints.toDP;
                }
                valueProperty = viewModel.isSingleSeriesViewModel || this.differenceHighlightFromTo > 0 ? START_POSITION : VALUE;
                if (fromDataPoint && toDataPoint && toDataPoint[valueProperty] !== null && fromDataPoint[valueProperty] !== null) {
                    startValue = fromDataPoint[valueProperty];
                    endValue = toDataPoint[valueProperty];

                    // calculate correct values for negative variances
                    if (!viewModel.isSingleSeriesViewModel && this.differenceHighlightFromTo > 0) {
                        if (fromDataPoint.isVariance && fromDataPoint.isNegative) {
                            startValue = startValue - fromDataPoint.value;
                        }
                        if (toDataPoint.isVariance && toDataPoint.isNegative) {
                            endValue = endValue - toDataPoint.value;
                        }
                    }

                    if (viewModel.isSingleSeriesViewModel && fromDataPoint.isNegative && fromDataPoint.startPosition + ((fromDataPoint.isVariance ? -1 : 1) * fromDataPoint.value) < 0) {
                        startValue = fromDataPoint.startPosition + ((fromDataPoint.isVariance ? -1 : 1) * fromDataPoint.value);
                    }
                    if (viewModel.isSingleSeriesViewModel && toDataPoint.isNegative && toDataPoint.startPosition + ((toDataPoint.isVariance ? -1 : 1) * toDataPoint.value) < 0) {
                        endValue = toDataPoint.startPosition + ((toDataPoint.isVariance ? -1 : 1) * toDataPoint.value);
                    }
                }
            }
            else if (this.differenceHighlightFromTo === DifferenceHighlightFromTo.LastACToLastFC && valuesDataPoints.length !== 0 && secondSegmentValuesDataPoints.length !== 0) {
                fromDataPoint = valuesDataPoints[valuesDataPoints.length - 1];
                toDataPoint = secondSegmentValuesDataPoints[secondSegmentValuesDataPoints.length - 1];
                startValue = fromDataPoint.value;
                endValue = toDataPoint.secondSegmentValue;
            }
            else {
                if (this.differenceHighlightFromTo === DifferenceHighlightFromTo.Auto) {
                    const isSingleSeries = this.chartType === ChartType.Bar
                        || (this.chartType === ChartType.Line && viewModel.isSingleSeriesViewModel)
                        // TODO: needs improvement, for the Responsive layout diff. highlight can be rendered on integrated variance or column chart, depending on visual height
                        || (this.chartType === ChartType.Variance && (this.chartLayout === RESPONSIVE && Visual.visualViewPort.height > 448 || this.chartLayout === ACTUAL));
                    const dhDataPoints = hasSecondSegmentValues && valuesDataPoints.length === 0 ? secondSegmentValuesDataPoints : valuesDataPoints;
                    const dhAutoDataPoints = getDiffHighlightAutoDataPoints(dhDataPoints, this.chartType, isSingleSeries);
                    fromDataPoint = dhAutoDataPoints.fromDataPoint;
                    toDataPoint = dhAutoDataPoints.toDataPoint;
                }
                else {
                    const diffHighlightDataPoints = shouldUseSecondSegmentValueProperty ? secondSegmentValuesDataPoints : valuesDataPoints;
                    const fromToDataPoints = getDiffHighlightDataPoints(diffHighlightDataPoints, this.differenceHighlightFromTo);
                    fromDataPoint = fromToDataPoints.fromDP;
                    toDataPoint = fromToDataPoints.toDP;
                }

                if (fromDataPoint && toDataPoint && fromDataPoint[valueProperty] !== null) {
                    if (fromDataPoint === toDataPoint && (toDataPoint.reference !== null || toDataPoint.secondSegmentValue !== null)) {
                        startValue = fromDataPoint.reference ?? toDataPoint.secondSegmentValue;
                        endValue = fromDataPoint[valueProperty];
                    }
                    else if (fromDataPoint !== toDataPoint && toDataPoint[valueProperty] !== null) {
                        startValue = fromDataPoint[valueProperty];
                        endValue = toDataPoint[valueProperty];
                    }
                }
            }

            if (startValue !== null && endValue !== null) {
                if (this.differenceLabel === DifferenceLabel.Absolute || this.differenceLabel === DifferenceLabel.RelativeAndAbsolute) {
                    cd.absDifferenceHighlight = endValue - startValue;
                    cd.absDifferenceHighlightLabel = getVarianceDataLabel(cd.absDifferenceHighlight, this.decimalPlaces, this.displayUnits,
                        this.locale, this.showNegativeValuesInParenthesis(), this.showPercentageInLabel, false, false, labelsFormat, hideUnits);
                    maxDiffHighlightLabelWidth = Math.max(maxDiffHighlightLabelWidth, drawing.measureTextWidth(cd.absDifferenceHighlightLabel, this.differenceHighlightFontSize, getFontFamily(this.differenceHighlightFontFamily), getFontWeight(this.differenceHighlightFontFamily), NORMAL));
                }
                if (this.differenceLabel === DifferenceLabel.Relative || this.differenceLabel === DifferenceLabel.RelativeAndAbsolute) {
                    cd.relDifferenceHighlight = calculateRelativeDifferencePercent(endValue, startValue);
                    cd.relDifferenceHighlightLabel = getRelativeVarianceLabel(this, cd.relDifferenceHighlight, this.differenceLabel === DifferenceLabel.RelativeAndAbsolute);
                    maxDiffHighlightLabelWidth = Math.max(maxDiffHighlightLabelWidth, drawing.measureTextWidth(cd.relDifferenceHighlightLabel, this.differenceHighlightFontSize, getFontFamily(this.differenceHighlightFontFamily), getFontWeight(this.differenceHighlightFontFamily), ITALIC));
                }
            }
        });

        //TODO improve this following whole part, maybe move to a separate function
        const isLineChart = this.chartType === ChartType.Line || this.chartType === ChartType.Area;
        this.differenceHighlightWidth = Math.ceil(maxDiffHighlightLabelWidth) + 10 + (isLineChart ? 0 : 12) + this.differenceHighlightMargin;

        if (!this.showVerticalCharts && this.differenceHighlightEllipse) {
            this.differenceHighlightWidth += Math.max(2 * (this.differenceHighlightEllipseBorderPadding - 4), 0)
                + Math.floor(this.differenceHighlightEllipseBorderWidth);
            if (this.showCommentBox && !isLineChart) {  // ellipse sometimes overlaps with comment box / comment box resize UI
                this.differenceHighlightWidth += 6; //TODO find out why 6 is added, move it to a constant to avoid magic numbers
            }
        }
    }

    public proVersionActive(): boolean {
        return this.proFeaturesEnabled && this.proFeaturesUnlocked;
    }

    public shouldPlotVerticalCharts(): boolean {
        return this.showVerticalCharts && this.chartTypeSupportsVertical(this.chartType);
    }

    public chartTypeSupportsVertical(chartType: ChartType): boolean {
        return this.chartType === ChartType.Bar || this.chartType === ChartType.Variance
            || this.chartType === ChartType.Waterfall || this.chartType === ChartType.Pin || this.chartType === ChartType.Advert;
    }

    public isSecondSegmentDiffHighlight(): boolean {
        return this.differenceHighlightFromTo !== DifferenceHighlightFromTo.MinToMax && this.differenceHighlightFromTo > 3;
    }

    public getCategoryHighlightColor(category: string): string {
        const existingCustomCategoryColor = this.highlightedCategoriesCustomColors.find(c => c[category]);
        return existingCustomCategoryColor ? existingCustomCategoryColor[category] || this.colorScheme.highlightColor : this.colorScheme.highlightColor;
    }

    public getGroupHighlightColor(group: string): string {
        const existingCustomGroupColor = this.highlightedGroupsCustomColors.find(c => c[group]);
        return existingCustomGroupColor ? existingCustomGroupColor[group] || this.colorScheme.highlightColor : this.colorScheme.highlightColor;
    }

    public getGapBetweenColumns(): number {
        return this.gapBetweenColumnsPercent / 100;
    }

    public shouldFreezeCategories(): boolean {
        return this.showCategories && !this.stackedChart && !this.showVerticalCharts &&
            (this.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.LastRow || this.multiplesAxisLabelsOptions === MultiplesAxisLabelsOptions.FirstChartLastRow) &&
            (this.multiplesLayoutType === ROWS_LAYOUT || this.multiplesLayoutType === AUTO_LAYOUT || this.multiplesLayoutType === LARGEST_FIRST_LAYOUT);
    }

    public persistTopNSettings(): void {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistTopNCategorySettings(): void {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistTopNStackedItemsSettings(): void {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public isLineChart(): boolean {
        return this.chartType === ChartType.Line || this.chartType === ChartType.Area;
    }

    public showCommentMarkers(): boolean {
        return this.scenarioOptions.commentsIndices && this.scenarioOptions.commentsIndices.length > 0;
    }

    private getChartTypeEncoding(isSingleMeasure: boolean): string {
        return (isSingleMeasure ? "1-" : "2-") + ChartType[this.chartType];
    }

    private getChartTypeFromEncoding(encodedChartType: string): ChartType {
        return encodedChartType && encodedChartType.length > 2 ? ChartType[encodedChartType.substring(2)] : null;
    }

    private lockedChartTypeChange(encodedChartType: string) {
        if (this.lockedChartTypesViewMode.indexOf(encodedChartType) === -1) {
            this.lockedChartTypesViewMode.push(encodedChartType);
        }
        else {
            this.lockedChartTypesViewMode = this.lockedChartTypesViewMode.filter(c => c !== encodedChartType);
        }
    }

    private isChartTypeLockingAllowed(isSingleMeasure: boolean): boolean {
        if (this.isChartTypeLockedInViewMode(isSingleMeasure)) {
            return true;
        }

        const plotVertical = this.shouldPlotVerticalCharts();
        if (isSingleMeasure) {
            const limit = plotVertical ? 2 : 4;
            return this.lockedChartTypesViewMode.filter(c => c.startsWith("1")).length < limit;
        }
        else {
            const limit = plotVertical ? 1 : 3;
            return this.lockedChartTypesViewMode.filter(c => c.startsWith("2")).length < limit;
        }
    }

    public persistLockedChartTypesChange(isSingleMeasure: boolean) {
        if (!this.isChartTypeLockingAllowed(isSingleMeasure)) {
            return;
        }
        this.lockedChartTypeChange(this.getChartTypeEncoding(isSingleMeasure));
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistStackedChartChanges() {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistStackedChartLabelsAsPercentage() {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistGlobalLegendSettings(globalCategorySettings: GlobalLegendSettings) {
        for (const [key, value] of Object.entries(globalCategorySettings)) {
            this[key] = value;
        }
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistDifferenceHighlightSettings(differenceHighlightSettings: DifferenceHighlightSettings) {
        for (const [key, value] of Object.entries(differenceHighlightSettings)) {
            this[key] = value;
        }
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistResetButtonLegendSettings() {
        this.useColoredLegendNames = false;
        this.useAliasesInTooltips = false;
        this.switchReferenceScenarios = false;
        this.showTopNChartsOptions = 0;
        this.topNChartsToKeep = 10;
        this.topNChartsPercentage = 80;
        this.legendItemsMargin = 0;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistCommentBoxSize(size: string) {
        this.commentBoxSize = size;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistCommentBoxTitle(commentBoxTitle: CommentBoxTitle) {
        this.commentBoxTitle = commentBoxTitle;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistCommentBoxVariance(commentBoxVariance: CommentBoxVariance) {
        this.commentBoxShowVariance = commentBoxVariance;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistCommentBoxVarianceIcon(commentBoxVarianceIcon: VarianceIcon) {
        this.commentBoxVarianceIcon = commentBoxVarianceIcon;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistCommentBoxExpandedGroupChange(group: string) {
        this.commentBoxExpandedGroupChange(group);
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public commentBoxExpandedGroupChange(group: string) {
        if (this.isCommentBoxGroupExpanded(group)) {
            this.commentBoxExpandedGroup = "";
        }
        else {
            this.commentBoxExpandedGroup = group;
        }
    }

    public persistCommentBoxSettings() {
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistTitleStyle(text: string, fontColor: string, fontFamily: string, fontSize: number) {
        this.titleFontFamily = fontFamily;
        this.titleFontSize = fontSize;
        this.titleText = text;
        this.titleFontColor = fontColor;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }


    public isCommentBoxGroupExpanded(group: string): boolean {
        return !!group && this.commentBoxExpandedGroup === group;
    }

    public isCommentBoxVertical() {
        return this.commentBoxPlacement === CommentBoxPlacement.Left || this.commentBoxPlacement === CommentBoxPlacement.Right;
    }

    public isChartTypeLockedInViewMode(isSingleMeasure: boolean): boolean {
        return this.lockedChartTypesViewMode.indexOf(this.getChartTypeEncoding(isSingleMeasure)) !== -1;
    }

    public shouldPlotStackedChart(isMultiples: boolean): boolean {
        return this.stackedChart && isMultiples;
    }

    public isChartDataToBeSorted(viewModel: ViewModel): boolean {
        const hasMultiplesSort = this.multiplesSort !== Sort.None;
        const isLargestFirstLayout = this.multiplesLayoutType === LARGEST_FIRST_LAYOUT;
        const showTopNChartsOff = this.showTopNChartsOptions === ShowTopNChartsOptions.Off;
        const isStackedChart = this.stackedChart;
        const showTopNStackedOptionsOff = this.showTopNStackedOptions === ShowTopNChartsOptions.Off;
        const isStackedChartSortNone = this.stackedChartSort === Sort.None;

        return (
            !viewModel.is2dMultiples
            && (hasMultiplesSort || isLargestFirstLayout)
            &&
            (
                (!isStackedChart && showTopNChartsOff)
                || (isStackedChart && showTopNStackedOptionsOff && !isStackedChartSortNone)
            )
        );
    }

    public shouldHideLastVariance(category?: string): boolean {
        let shouldHide = false;
        if (this.currentPeriodVarianceOptions === 1) {
            shouldHide = true;
        }
        else if (this.currentPeriodVarianceOptions === 2) {
            const now = new Date();
            if (this.currentPeriodVarianceCondition === 0) {
                shouldHide = now.getDate() < this.dayInMonthVarianceCondition;
            }
            else if (this.currentPeriodVarianceCondition === 1) {
                shouldHide = now.getDay() + 1 < this.dayInWeekVarianceCondition;
            }
            else if (this.currentPeriodVarianceCondition === 2) {
                shouldHide = (now.getMonth() % 3) + 1 < this.monthInQuarterVarianceCondition;

            }
            else if (this.currentPeriodVarianceCondition === 3) {
                shouldHide = now.getMonth() + 1 < this.monthInYearVarianceCondition;
            }
        }
        return shouldHide;
    }

    public persistScenarioCategoriesObject(scenarioCategories: object[]) {
        this.scenarioCategories = scenarioCategories;
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persistScenarioCategories(category: string, scenario: Scenario) {
        this.setScenarioCategories(category, scenario);
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }

    public persist(skipTimer = false) {
        const newSettings = { ... this };

        persistManagerInstance.update({
            objectName: CHART_SETTINGS_NAME, // IMPORTANT: make sure it's not named the same way the proxy is!!
            properties: JSON.parse(JSON.stringify(newSettings)),
        });

        if (skipTimer) {
            persistManagerInstance.flushQueue(); // override timer
        }
    }

    public setOrganizationStyleSettings(organizationStyleData: OrganizationStyleData) {
        // settings.colorScheme = { ...settings.colorScheme, ...importedSettings.colorScheme }; //possibly use this if we'd want to merge the color schemes
        this.chartStyle = ChartStyle.Company;
        this.selectedOrganizationStyleId = organizationStyleData.id;
        for (const [key, value] of Object.entries(organizationStyleData.styleJSON)) {
            const settingName = key.split("-").join("_");
            this[settingName] = value;
        }
    }

    public persistDataLabelSettings(dataLabelSettings: DataLabelSettings) {
        for (const [key, value] of Object.entries(dataLabelSettings)) {
            this[key] = value;
        }
        Visual.getInstance().constructViewModelAndVisualUpdate(this);
    }
}
