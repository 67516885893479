import { WatermarkConfig } from "../interfaces/WatermarkConfig";

export const watermarkBar = (config: WatermarkConfig) => `
    <div class="zbi-watermark" id="zbiWatermark">
        ${config.text ? `<div class="text">${config.text}</div>` : ''}
        ${config.textWithLink ? `<div class="text">${config.textWithLink.map((item) => `<span class=${item.url ? "watermark-text-url" : ""}>${item.text}</span>`).join('')}</div>` : ''}
        ${config.link ? `<div class="vertline">|</div><button class="watermark-button free-trial">${config.linktext}</button>` : ''}
        ${config.signin ? `<div class="vertline">|</div><button class="watermark-button sign-in">${config.signin}</button>` : ''}
        ${config.retry ? `<div class="vertline">|</div><button class="watermark-button retry">${config.retry}</button>` : ''}
    </div>
`;
