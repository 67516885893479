import BaseObserver from "../interface/BaseObserver";
import {ToolbarOptions} from "../interface/ToolbarOption";

export default abstract class GlobalToolbarObserver implements BaseObserver<Map<string, any>> {
    public static CLASS_NAME = "GlobalToolbarObserver";

    abstract update (message: Map<string, any>): void;
    abstract action (action: string, message: string): void;
    protected toolbarOptions: ToolbarOptions;

    getClassName(): string {
        return GlobalToolbarObserver.CLASS_NAME;
    }
}
