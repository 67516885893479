import React from "react";
import ChartChooserMain from "../ChartChooserMain/ChartChooserMain";
import ChartChooserAside from "../ChartChooserAside/ChartChooserAside";
import { ChartDimensionList } from "../../charts/dimensions/components/ChartDimensionList";
import "./index.css";
import useStore from "@zebrabi/chart-chooser/useStore";
import { Comments } from "../../settings/Comments";

export default function ChartChooser() {
  const [isVisible] = useStore<boolean>("isVisible");

  if (!isVisible) return null;

  return (
    <div className="chart-chooser-container flex items-stretch h-full">
      <ChartChooserAside>
        <ChartDimensionList />
      </ChartChooserAside>
      <ChartChooserMain />
      {/*
      <div className="settings-container">
        <h1 className="text-xs font-semibold mb-5">Settings</h1>
        <Comments />
      </div>
      */}
    </div>
  );
}
