import { Visual } from "../visual";

import {
    FILL,
    FILL_OPACITY,
    CLICK,
    STROKE_OPACITY,
    WIDTH,
    HEIGHT,
    RECT,
    X,
    Y,
    STROKE,
    STROKE_WIDTH,
    MOUSEENTER,
    MOUSELEAVE,
    CHART_LEGEND_SETTINGS,
    CHART_GLOBAL_LEGEND_MENU,
    GLOBAL_LEGEND,
    OUTLINE_COLOR,
    TRANSPARENT,
    RESET
} from "../library/constants";
import * as d3 from "../d3";
import GlobalLegendMenu from "../components/GlobalLegendMenu";


export function plotChartLegendSettings(container: d3.Selection<SVGElement, any, any, any>, x?: number, y?: number, height?: number): void {
    if (Visual.isChartFocusPopupShown) {
        return;
    }
    const chartLegendSettings = document.querySelector(`.${CHART_LEGEND_SETTINGS}`);
    if (!chartLegendSettings) {
        d3.select(`.visual`).append("div").classed(CHART_LEGEND_SETTINGS, true);
    }

    const svgContainer = (<HTMLElement>d3.select(".zebrabi-charts-visual").node())?.getBoundingClientRect();
    const title = (<HTMLElement>d3.select(".header").node())?.getBoundingClientRect();
    const globalLegenedMenu = d3.select(`.${GLOBAL_LEGEND}`)?.node();
    let multipleCharts = false;
    let [legendY, legendX, legendWidth, legendHeight] = [0, 0, 0, 0];
    let rectBB: DOMRect;
    let rect = d3.select(`.${CHART_GLOBAL_LEGEND_MENU}`);
    const categories = (<HTMLElement>d3.select(".category").node());
    const chartContainerBB = (<HTMLElement>d3.select(container.node().parentElement).node())?.getBoundingClientRect();

    if (!rect.empty()) {
        rect.lower();
        rectBB = (<HTMLElement>rect.node())?.getBoundingClientRect();
        multipleCharts = true;
    } else {
        rect = d3.select(container.node().parentElement).insert(RECT, ":first-child").classed(CHART_GLOBAL_LEGEND_MENU, true)
            .attr(FILL_OPACITY, 0)
            .attr(STROKE_OPACITY, globalLegenedMenu ? 1 : 0)
            .attr(FILL, TRANSPARENT)
            .attr(STROKE, globalLegenedMenu ? OUTLINE_COLOR : TRANSPARENT)
            .attr(STROKE_WIDTH, 1)
            .attr("rx", 2)
            .attr("ry", 2);
    }
    if (Visual.settings.showVerticalCharts) {
        legendX = x + categories?.getBoundingClientRect()?.width;
        legendY = chartContainerBB?.top - 3;
        legendHeight = document.querySelector(".chart-legend")?.getBoundingClientRect().height + 10;
        legendWidth = height - (categories?.getBoundingClientRect()?.width ?? 0) - 10;
        rect?.attr(X, legendX)
            .attr(Y, legendY)
            .attr(WIDTH, legendWidth)
            .attr(HEIGHT, legendHeight);
    } else {
        const categoriesBB = categories?.getBoundingClientRect();
        const [settingsWidth, xa] = getLegendPositionInfo(container, multipleCharts);
        legendX = xa - 4;
        // This logic is added for responsive layout. It gradually merges rect for
        // all charts so that at the end the rect is outlining all legends on the left.
        if (rectBB) {
            y = rectBB.y < y ? rectBB.y : y;
            height = height + rectBB.height;
        }
        legendY = y;
        legendWidth = settingsWidth - 10;
        const marginTop = 20;
        // this margin bottom is used to deduct the additional height that small mutiples causes
        const marginBottom = 15;
        // if small mutiples, take the height of the svg container and substract title height categories height plus some additional margin.
        if (Visual.viewModel.isMultiples) {
            const smallMutipleMargin = 5;
            legendY = svgContainer.y + marginTop + smallMutipleMargin + title.height;
            legendHeight = svgContainer.height - title.height - marginBottom - (categoriesBB?.height ?? 15);
        } else {
            // Responsive layout logic. If the first chart is being draw, add some margin at the top and substract the categories height.
            legendHeight = height - (rectBB ? 0 : (marginTop + (categoriesBB?.height ?? 0)));
            legendY = legendY + (rectBB ? 0 : marginTop);
        }
        rect?.attr(X, legendX > 0 ? legendX : 1)
            .attr(Y, legendY < 0 ? 0 : legendY)
            .attr(WIDTH, legendWidth)
            .attr(HEIGHT, legendHeight);
    }
    rect?.on(MOUSEENTER, () => {
        rect
            .attr(STROKE_OPACITY, 1)
            .attr(STROKE, OUTLINE_COLOR);
    }, true)
        .on(MOUSELEAVE, () => {
            if (d3.select(`.${GLOBAL_LEGEND}`).empty()) {
                resetLegendOutline(rect);
            }
        }, true)
        .on(CLICK, (event) => {
            toggleLegendMenu(event, rect, legendX, legendY, chartContainerBB, legendWidth, legendHeight, Visual.settings.showVerticalCharts);
        });
    if ((<HTMLElement>d3.select(`.${GLOBAL_LEGEND}.${RESET}`)?.node())) {
        reopenLegendMenu(rect);
    }
}

function toggleLegendMenu(event: MouseEvent, rect: d3.Selection<d3.BaseType, unknown, HTMLElement, any>, x: number, y: number, chartContainerBB: DOMRect, width: number, height: number, isVertical: boolean) {
    const chartLegendSettings = (<HTMLElement>d3.select(`.${CHART_LEGEND_SETTINGS}`).node());
    const globalLegenedMenu = (<HTMLElement>d3.select(`.${GLOBAL_LEGEND}`)?.node());
    if (!globalLegenedMenu) {
        const menu = new GlobalLegendMenu();
        const globalLegendMenu = menu.appendTo(chartLegendSettings);
        calculateGlobalLegendMenuPosition(globalLegendMenu, event, x, y, chartContainerBB, width, height, isVertical);
        document.addEventListener("mouseleave", () => {
            const transitionSpeed = 400;
            globalLegendMenu.style.transition = `opacity ${transitionSpeed} ms ease`;
            setTimeout(function () {
                globalLegendMenu.remove();
                resetLegendOutline(rect);
            }, transitionSpeed);
        });
    } else {
        globalLegenedMenu.remove();
    }
    closeLegendMenuOnOutsideClick();
}

function redrawGlobalLegendMenu(): HTMLElement {
    d3.select(`.${GLOBAL_LEGEND}`)?.remove();
    const main = (<HTMLElement>d3.select(`.${CHART_LEGEND_SETTINGS}`)?.node());
    const globalLegendMenu = new GlobalLegendMenu();
    return globalLegendMenu.appendTo(main);
}

function reopenLegendMenu(rect: d3.Selection<d3.BaseType, unknown, HTMLElement, any>) {
    // if settings are persisted, we reopen the legend menu with latest data.
    const globalLegendMenuBB = (<HTMLElement>d3.select(`.${GLOBAL_LEGEND}`)?.node())?.getBoundingClientRect();
    const globalLegendMenu = redrawGlobalLegendMenu();
    if (Visual.settings.showVerticalCharts) {
        globalLegendMenu.style.top = `${globalLegendMenuBB.top}px`;
        globalLegendMenu.style.left = `${globalLegendMenuBB.left}px`;
    }
    else {
        globalLegendMenu.style.top = `${globalLegendMenuBB.top}px`;
        globalLegendMenu.style.left = `${globalLegendMenuBB.left}px`;
    }
    rect
        .attr(STROKE_OPACITY, 1)
        .attr(STROKE, OUTLINE_COLOR);
}

function resetLegendOutline(rect: d3.Selection<d3.BaseType, unknown, HTMLElement, any>) {
    rect.attr(FILL_OPACITY, 0)
        .attr(STROKE_OPACITY, 0)
        .attr(FILL, TRANSPARENT)
        .attr(STROKE, TRANSPARENT);
}

function calculateGlobalLegendMenuPosition(globalLegendMenu: HTMLElement, event: MouseEvent, x: number, y: number, chartContainerBB: DOMRect, width: number, height: number, isVertical: boolean) {
    if (isVertical) {
        // Check if the open menu would overflow (X) the chart container, if so, reposition the menu inside chart container
        if (event.pageX + globalLegendMenu.clientWidth > chartContainerBB.right) {
            const diff = event.pageX + globalLegendMenu.clientWidth - chartContainerBB.right;
            globalLegendMenu.style.top = `${y + height}px`;
            globalLegendMenu.style.left = `${event.pageX - diff}px`;
        } else {
            globalLegendMenu.style.top = `${y + height}px`;
            globalLegendMenu.style.left = `${event.pageX}px`;
        }
    }
    else {
        // Check if the open menu would overflow (Y) the chart container, if so, reposition the menu inside chart container
        if (event.pageY + globalLegendMenu.clientHeight > chartContainerBB.bottom) {
            const diff = event.pageY + globalLegendMenu.clientHeight - chartContainerBB.bottom;
            globalLegendMenu.style.top = `${event.pageY - diff}px`;
            globalLegendMenu.style.left = `${x + width + 1}px`;
        } else {
            globalLegendMenu.style.top = `${event.pageY}px`;
            globalLegendMenu.style.left = `${x + width + 1}px`;
        }
    }
}

function closeLegendMenuOnOutsideClick() {
    const closeMenuOnOutsideClick = (event: MouseEvent) => {
        const legendMenu = document.querySelector(`.${CHART_LEGEND_SETTINGS}`);
        const rect = d3.select(`.${CHART_GLOBAL_LEGEND_MENU}`);
        if (legendMenu && !event.composedPath().includes((<HTMLElement>rect?.node())) && !event.composedPath().includes(legendMenu)) {
            legendMenu.querySelector(`.${GLOBAL_LEGEND}`)?.remove();
            document.removeEventListener(CLICK, closeMenuOnOutsideClick);
            resetLegendOutline(rect);
        }
    };

    document.addEventListener(CLICK, closeMenuOnOutsideClick, true);
}

function getLegendPositionInfo(container: d3.Selection<SVGElement, any, any, any>, mutiplecharts: boolean): [number, number] {
    // Find the legend with most left position. Find the widest legend so we know how width the outline should be.
    let legends;
    if (mutiplecharts) {
        legends = container.node().ownerSVGElement.querySelectorAll(".chart-legend");
    } else {
        legends = container.selectAll(".chart-legend")?.nodes();
    }

    const legendsPositions: Array<{x: number, width: number }> = [];
    [...legends].forEach((legend) => {
        const fo = (<HTMLElement>legend).querySelector("foreignobject");
        legendsPositions.push({
            "x": parseInt(fo?.getAttribute("x")),
            "width": parseInt(fo?.getAttribute("width"))
        });
    });

    legendsPositions.sort((a, b) => {
        return a.x - b.x;
    });

    const minLegendX = legendsPositions[0].x;

    legendsPositions.sort((a, b) => {
        return a.width - b.width;
    });

    const maxLegendwidth = legendsPositions[legendsPositions.length - 1].width;

    return [maxLegendwidth, minLegendX];
}