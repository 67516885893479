import { mountReactChartChooser } from "./react";
import { ChartChooserStore } from "./store";
import "./tailwind.css";
import "./index.css";

const HIDDEN_CLASS_NAME = "hidden";

function hideDOMBody() {
  const bodyElement = document.getElementsByTagName("body")[0];
  bodyElement.classList.add(HIDDEN_CLASS_NAME);
}

function showDOMBody() {
  const bodyElement = document.getElementsByTagName("body")[0];
  bodyElement.classList.remove(HIDDEN_CLASS_NAME);
}

export function mountChartChooser() {
  hideDOMBody();
  mountReactChartChooser();
  // ChartChooserToggle has been moved to GlobalToolbar
  setTimeout(() => showDOMBody(), 300);
}

export function initChartChooserStore() {
  new ChartChooserStore();
}
