import GlobalToolbarObserver from "@zebrabi/global-toolbar-old/observers/GlobalToolbarObserver";

/**
 * Type defined observer used to isolate the notifications
 */
abstract class SettingsChartsSwitcherObserver extends GlobalToolbarObserver {
    static readonly CLASS_NAME = `SettingsSwitcherObserver`;

    getClassName(): string {
        return SettingsChartsSwitcherObserver.CLASS_NAME;
    }

    /**
     * Triggered on general actions (open, close, save, etc.)
     * @param action
     * @param message
     */
    action(action: string, message: string): void {return;}
    update: (message: Map<string, any>) => void;
}

export default SettingsChartsSwitcherObserver;
