import { ChartData, DataPoint, ScenarioOptions } from "./../interfaces";
import { ChartType, LabelDensity } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { LabelProperties } from "./../library/interfaces";
import {
    BAR, CHART_AREA, ChartStyle, CIRCLE, CX, CY, D, DR_HICHERT_SQUARE_MARKER_WIDTH, END, FILL, FONT_FAMILY, FONT_SIZE,
    FONT_SIZE_UNIT, FONT_WEIGHT, G, HEIGHT, HIGHLIGHTABLE, ITALIC, MARKER, MIDDLE, PATH, PLUS_MINUS_DOT_RECT_WIDTH, R,
    RECT, TEXT, TEXT_ANCHOR, TRANSFORM, WHITE, WIDTH, X, Y
} from "./../library/constants";
import { CHART_CONTAINER, RELATIVE } from "./../consts";

import * as drawing from "./../library/drawing";
import * as charting from "./chart";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as d3 from "../d3";
import { calculateRelativeDifference } from "../viewModel/viewModelHelperFunctions";
import { mapScenarioKeyAndName } from "../helpers";
import { plotChartLegendSettings } from "../ui/drawGlobalLegendMenuOverlay";

// eslint-disable-next-line max-lines-per-function
export default function plusMinusDotChart(reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, x: number, y: number, height: number, width: number,
    chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number, shouldPlotCategories: boolean, plotTitle: boolean,
    plotLegend: boolean, useSecondReferenceVariance: boolean = false) {

    let chartWidth = width;
    let chartXPos = x;

    if (plotLegend) {
        chartWidth -= settings.getLegendWidth();
        chartXPos += settings.getLegendWidth();
    }
    const referenceScenario = useSecondReferenceVariance ? settings.scenarioOptions.secondReferenceScenario : settings.scenarioOptions.referenceScenario;

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    const shouldShowGrandTotal = settings.showGrandTotal && settings.chartType === ChartType.Variance;
    if (settings.differenceHighlight) {
        chartWidth -= settings.differenceHighlightWidth;
    }
    if (shouldShowGrandTotal) {
        chartWidth = chartWidth * chartData.dataPoints.length / (chartData.dataPoints.length + 1.5);
    }
    const getVarianceValue = (dp: DataPoint) => useSecondReferenceVariance ? dp.secondReferenceRelativeVariance : dp.relativeVariance;

    const dataPoints = chartData.dataPoints.filter(d => getVarianceValue(d) !== null);
    let useSecondSegmentForLastValueDP = false;
    let lastDP = null;
    if (settings.currentPeriodVarianceOptions !== 0) {
        const lastNonNullValueDPs = dataPoints.filter(d => d.value !== null);
        if (lastNonNullValueDPs.length > 0 && settings.shouldHideLastVariance(lastNonNullValueDPs[lastNonNullValueDPs.length - 1].category)) {
            lastDP = lastNonNullValueDPs[lastNonNullValueDPs.length - 1];
            if (useSecondReferenceVariance) {
                lastDP.secondReferenceRelativeVariance = lastDP.secondSegmentValue !== null ? calculateRelativeDifference(lastDP.secondSegmentValue, lastDP.secondReference) : null;
            }
            else {
                lastDP.relativeVariance = lastDP.secondSegmentValue !== null ? calculateRelativeDifference(lastDP.secondSegmentValue, lastDP.reference) : null;
            }
            useSecondSegmentForLastValueDP = true;
        }
    }
    const outlierDataPoints = dataPoints.filter(d => isOutlier(d, chartData, useSecondReferenceVariance));
    const nonOutlierDataPoints = dataPoints.filter(d => !isOutlier(d, chartData, useSecondReferenceVariance));

    if (bottomMargin === 0) {
        bottomMargin = settings.showDataLabels ? settings.labelFontSize + 5 : 5;
    }

    const yScale = charting.getYScale(min, max, height, topMargin, bottomMargin);
    const xScale = charting.getXScale(chartData, chartXPos, chartWidth, 0.2);
    const setBarHeightToZero = min === 0 && max === 0;

    if (plotTitle) {
        charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, chartXPos, y, charting.getChartTitleWidth(chartWidth, settings), height, max, min, topMargin, bottomMargin);
    }
    if (shouldPlotCategories) {
        charting.plotCategories(chartContainer, settings, chartData, y + height, xScale, chartWidth, y + yScale(0));
    }
    drawing.plotHorizontalAxis(chartContainer, true, chartXPos, chartWidth, y + yScale(0), settings.colorScheme.axisColor, referenceScenario);

    if (settings.scenarioOptions.secondValueScenario !== null) {
        const secondSegmentDataPoints = chartData.dataPoints.filter(dp => dp.value === null);
        if (secondSegmentDataPoints.length > 0) {
            drawing.plotAxisScenarioDelimiter(chartContainer, xScale(secondSegmentDataPoints[0].category) - xScale.bandwidth() / 8, y + yScale(0) - 25, y + height);
        }
    }

    const chartArea = drawing.createGroupElement(chartContainer, `${CHART_AREA}-${chartIndex}`);
    chartArea.selectAll(".symbol")
        .data(outlierDataPoints)
        .enter()
        .append(PATH)
        .attr(D, d3.symbol().type(d3.symbolTriangle).size(50))
        .attr(TRANSFORM, d => getTriangleAttributes(d, y, yScale, xScale, chartData))
        .style(FILL, d => styles.getVarianceColor(chartData.isInverted !== d.isCategoryInverted, getVarianceValue(d), settings.colorScheme))
        .classed(HIGHLIGHTABLE, true);

    if (settings.chartStyle === ChartStyle.DrHichert) {
        const markersClass = `${BAR}${MARKER}${chartIndex} ${HIGHLIGHTABLE}`;
        const rectangles = drawing.getShapes(chartArea, markersClass, markersClass, RECT, nonOutlierDataPoints);
        rectangles
            .attr(WIDTH, DR_HICHERT_SQUARE_MARKER_WIDTH)
            .attr(HEIGHT, DR_HICHERT_SQUARE_MARKER_WIDTH)
            .attr(X, d => xScale(d.category) + xScale.bandwidth() / 2 - DR_HICHERT_SQUARE_MARKER_WIDTH / 2)
            .attr(Y, d => Math.round(y + yScale(getVarianceValue(d))) - DR_HICHERT_SQUARE_MARKER_WIDTH / 2);
        rectangles.exit().remove();
        styles.applyToLines(rectangles, (d: DataPoint) => settings.getCategoryDataPointColor(d.category, settings.colorScheme.markerColor), settings.scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme, false);
        if (settings.scenarioOptions.secondValueScenario !== null) {
            const secondSegmentRectangles = rectangles.filter((dp) => dp.value === null || settings.currentPeriodVarianceOptions !== 0 && useSecondSegmentForLastValueDP && dp === lastDP);
            styles.applyToLines(secondSegmentRectangles, (d: DataPoint) => settings.getCategoryDataPointColor(d.category, settings.colorScheme.markerColor), settings.scenarioOptions.secondValueScenario, settings.chartStyle, false, settings.colorScheme, false);
        }
    }
    else {
        const markersClass = `${CIRCLE}${MARKER}${chartIndex} ${HIGHLIGHTABLE}`;
        const circles = drawing.getShapes(chartArea, markersClass, markersClass, CIRCLE, nonOutlierDataPoints);
        circles
            .attr(R, 4)
            .attr(CX, d => xScale(d.category) + xScale.bandwidth() / 2)
            .attr(CY, d => Math.round(y + yScale(getVarianceValue(d))));
        circles.exit().remove();
        styles.applyToLines(circles, (d: DataPoint) => settings.getCategoryDataPointColor(d.category, settings.colorScheme.markerColor), settings.scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme, false);

        if (settings.scenarioOptions.secondValueScenario !== null) {
            const secondSegmentCircles = circles.filter((dp) => dp.value === null || settings.currentPeriodVarianceOptions !== 0 && useSecondSegmentForLastValueDP && dp === lastDP);
            styles.applyToLines(secondSegmentCircles, (d: DataPoint) => settings.getCategoryDataPointColor(d.category, settings.colorScheme.markerColor), settings.scenarioOptions.secondValueScenario, settings.chartStyle, false, settings.colorScheme, false);
        }
    }

    const barsClass = `${BAR}_RELATIVE_${chartIndex} ${HIGHLIGHTABLE}`;
    const bars = drawing.getShapes(chartArea, barsClass, barsClass, RECT, nonOutlierDataPoints);
    bars
        .attr(WIDTH, PLUS_MINUS_DOT_RECT_WIDTH)
        .attr(HEIGHT, d => setBarHeightToZero ? 0 : Math.max(0, yScale(max - Math.abs(getVarianceValue(d))) - topMargin))
        .attr(X, d => xScale(d.category) + xScale.bandwidth() / 2 - PLUS_MINUS_DOT_RECT_WIDTH / 2)
        .attr(Y, d => y + yScale(Math.max(0, getVarianceValue(d))))
        .attr(FILL, d => styles.getVarianceColor(chartData.isInverted !== d.isCategoryInverted, getVarianceValue(d), settings.colorScheme));
    const outlierClass = `${BAR}_OUTLIER_${chartIndex} ${HIGHLIGHTABLE}`;
    const outlierBar = drawing.getShapes(chartArea, outlierClass, outlierClass, RECT, outlierDataPoints);
    outlierBar
        .attr(WIDTH, PLUS_MINUS_DOT_RECT_WIDTH)
        .attr(HEIGHT, d => setBarHeightToZero ? 0 : Math.max(0, yScale(max - Math.abs((getVarianceValue(d) > 0 ? chartData.maxOutlierValue : chartData.minOutlierValue))) - topMargin))
        .attr(X, d => xScale(d.category) + xScale.bandwidth() / 2 - PLUS_MINUS_DOT_RECT_WIDTH / 2)
        .attr(Y, d => y + yScale(getVarianceValue(d) > 0 ? chartData.maxOutlierValue : 0))
        .attr(FILL, d => styles.getVarianceColor(chartData.isInverted !== d.isCategoryInverted, getVarianceValue(d), settings.colorScheme));
    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    if (settings.showDataLabels) {
        const labelsProperties = getLabelProperties(dataPoints, settings, chartData, xScale, yScale, y, useSecondReferenceVariance);
        if (labelsProperties.length > 0) {
            if (outlierDataPoints.length > 0) {
                charting.plotLabelBackgrounds(chartArea, chartIndex, labelsProperties.filter(p => p.isOutlier), settings, WHITE, true, false);
            }
            charting.plotLabels(chartArea, `${chartIndex}`, labelsProperties, settings, ITALIC);
        }
    }

    if (shouldShowGrandTotal) {
        plotPlusMinusDotGrandTotalChart(chartArea, dataPoints, dataPoints.filter(p => p.value === null), chartIndex, xScale, yScale,
            min, max, topMargin, y, x, width, settings.scenarioOptions, settings, useSecondReferenceVariance, chartData.isInverted);
    }

    if (settings.showCommentMarkers() && settings.chartLayout === RELATIVE) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, y, settings);
        }
    }

    const varianceLabelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);
    // Visual.tooltipServiceWrapper.addTooltip(chartArea,
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => charting.getTooltipData(tooltipEvent.data, settings, labelsFormat, varianceLabelsFormat, chartData.isInverted, undefined, chartData),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => tooltipEvent.data ? tooltipEvent.data.selectionId : null);

    bars.exit().remove();
    if (plotLegend) {
        const legendObject = useSecondReferenceVariance
            ? mapScenarioKeyAndName("secondRelativeDifferenceHeader", settings.secondRelativeDifferenceHeader, settings.scenarioOptions)
            : mapScenarioKeyAndName("relativeDifferenceHeader", settings.relativeDifferenceHeader, settings.scenarioOptions);

        charting.plotChartLegend(chartContainer, legendObject, Math.max(x, 0), y + height / 2, settings, false, referenceScenario, END);
        plotChartLegendSettings(chartContainer, x, y, height);
    }
}

function isOutlier(d: DataPoint, viewModel: ChartData, useSecondReferenceVariance: boolean) {
    // Improve: second reference variance outliers
    return useSecondReferenceVariance ? false : d.isNegative ? d.relativeVariance < viewModel.minOutlierValue : d.relativeVariance > viewModel.maxOutlierValue;
}

function getTriangleAttributes(d: DataPoint, yPos: number, yScale: d3.ScaleLinear<number, number>, xScale: d3.ScaleBand<string>, chartData: ChartData) {
    const y = yPos + (d.isNegative ?
        yScale(Math.max(chartData.minOutlierValue, d.relativeVariance)) + 8 :
        yScale(Math.min(chartData.maxOutlierValue, d.relativeVariance)) - 15);
    const x = Math.round(xScale(d.category) + xScale.bandwidth() / 2);
    return `translate(${x},${y}) rotate(${(d.isNegative ? "180" : "0")})`;
}

function plotPlusMinusDotGrandTotalChart(container: d3.Selection<SVGElement, any, any, any>, dataPoints: DataPoint[], secondSegmentDataPoints: DataPoint[], chartIndex: number,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, min: number, max: number, topMargin: number, y: number, x: number, width: number,
    scenarioOptions: ScenarioOptions, settings: ChartSettings, useSecondReferenceVariance: boolean, isInverted: boolean) {

    if (!dataPoints || dataPoints.length === 0) {
        return;
    }
    const grandTotalDataPoint = charting.getGrandTotalDataPoint(dataPoints, secondSegmentDataPoints, settings);
    const relativeVariance = useSecondReferenceVariance ? grandTotalDataPoint.secondReferenceRelativeVariance || 0 : grandTotalDataPoint.relativeVariance || 0;
    const xPosGrandTotal = x + width - xScale.bandwidth() / 2 - 11.5;

    drawing.plotHorizontalAxis(container, true, x + width - xScale.bandwidth() - 20, xScale.bandwidth() + 20, y + yScale(0), settings.colorScheme.axisColor,
        useSecondReferenceVariance ? settings.scenarioOptions.secondReferenceScenario : settings.scenarioOptions.referenceScenario);
    const scenario = secondSegmentDataPoints.length > 0 && scenarioOptions.secondValueScenario !== null ? scenarioOptions.secondValueScenario : scenarioOptions.valueScenario;
    const plotRelativeVariance = Math.max(min, Math.min(max, relativeVariance));
    const isOutlier = relativeVariance < min || relativeVariance > max;
    if (isOutlier) {
        const getTotalTriangleAttributes = () => {
            const yPos = y + (relativeVariance < 0 ? yScale(Math.max(min, relativeVariance)) + 8 : yScale(Math.min(max, relativeVariance)) - 15);
            return `translate(${xPosGrandTotal},${yPos}) rotate(${(relativeVariance < 0 ? "180" : "0")})`;
        };
        container.selectAll(".symbol")
            .data([grandTotalDataPoint])
            .enter()
            .append(PATH)
            .attr(D, d3.symbol().type(d3.symbolTriangle).size(50))
            .attr(TRANSFORM, getTotalTriangleAttributes())
            .style(FILL, styles.getVarianceColor(isInverted, relativeVariance, settings.colorScheme));
    }
    else {
        if (settings.chartStyle === ChartStyle.DrHichert) {
            const markersClass = `${BAR}${MARKER}${chartIndex}`;
            const rectangleMarker = drawing.getShapes(container, markersClass, markersClass, RECT, [grandTotalDataPoint]);
            rectangleMarker
                .attr(WIDTH, DR_HICHERT_SQUARE_MARKER_WIDTH)
                .attr(HEIGHT, DR_HICHERT_SQUARE_MARKER_WIDTH)
                .attr(X, xPosGrandTotal - DR_HICHERT_SQUARE_MARKER_WIDTH / 2)
                .attr(Y, y + yScale(plotRelativeVariance) - DR_HICHERT_SQUARE_MARKER_WIDTH / 2);
            rectangleMarker.exit().remove();
            styles.applyToLines(rectangleMarker, (d: DataPoint) => settings.colorScheme.markerColor, scenario, settings.chartStyle, false, settings.colorScheme, false);
        }
        else {
            const markersClass = `${CIRCLE}${MARKER}${chartIndex}`;
            const circleMarker = drawing.getShapes(container, markersClass, markersClass, CIRCLE, [grandTotalDataPoint]);
            circleMarker
                .attr(R, 4)
                .attr(CX, xPosGrandTotal)
                .attr(CY, y + yScale(plotRelativeVariance));
            circleMarker.exit().remove();
            styles.applyToLines(circleMarker, (d: DataPoint) => settings.colorScheme.markerColor, scenario, settings.chartStyle, false, settings.colorScheme, false);
        }
    }

    let avgBar = container
        .selectAll(`.${BAR}_TOTAL` + chartIndex)
        .data([grandTotalDataPoint]);
    const rect = avgBar.enter()
        .append(RECT)
        .classed(BAR, true);
    avgBar = avgBar.merge(rect)
        .attr(WIDTH, PLUS_MINUS_DOT_RECT_WIDTH)
        .attr(HEIGHT, plotRelativeVariance === 0 ? 0 : Math.max(0, yScale(max - Math.abs(plotRelativeVariance)) - topMargin))
        .attr(Y, y + yScale(Math.max(0, plotRelativeVariance)))
        .attr(X, xPosGrandTotal - PLUS_MINUS_DOT_RECT_WIDTH / 2)
        .attr(FILL, styles.getVarianceColor(isInverted, relativeVariance, settings.colorScheme));
    avgBar.exit().remove();

    const labelText = charting.getRelativeVarianceLabel(settings, relativeVariance * 100, false);
    let labelYPos = y + yScale(plotRelativeVariance) + (relativeVariance < 0 ? settings.labelFontSize + 5 : - 10);
    const labelXPos = x + width - 0.5 * xScale.bandwidth() - 10.5;
    if (isOutlier) {
        labelYPos = y + yScale(relativeVariance < 0 ? min : max) + (relativeVariance < 0 ? -2 : 3);
        const lbProperty = charting.getLabelProperty(labelText, labelXPos, labelYPos, true, relativeVariance, grandTotalDataPoint, settings.labelFontSize, settings.labelFontFamily, null, true);
        charting.plotLabelBackgrounds(container, chartIndex, [lbProperty], settings, WHITE, true, false);
    }
    container.append(TEXT)
        .text(labelText)
        .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, charting.getFontFamily(settings.labelFontFamily))
        .style(FONT_WEIGHT, charting.getFontWeight(settings.labelFontFamily))
        .style(TEXT_ANCHOR, MIDDLE)
        .attr(X, labelXPos)
        .attr(Y, labelYPos)
        .attr(FILL, settings.labelFontColor);
}

function labelDensityFilterRelativeVariance(point: DataPoint, index: number, array: DataPoint[]): boolean {
    switch (this.labelDensity) {
        case LabelDensity.Full:
        case LabelDensity.Auto:
        case LabelDensity.High:
        case LabelDensity.Medium:
        case LabelDensity.Low:
            return true;
        case LabelDensity.None:
            return false;
        case LabelDensity.Last:
            return index === array.length - 1;
        case LabelDensity.FirstLast:
            return index === 0 || index === array.length - 1;
        case LabelDensity.MinMax: {
            const maxPoint = array.reduce((a, b) => a.relativeVariance > b.relativeVariance ? a : b);
            const minPoint = array.reduce((a, b) => a.relativeVariance > b.relativeVariance ? b : a);
            const maxIndex = array.indexOf(maxPoint);
            const minIndex = array.indexOf(minPoint);
            return index === maxIndex || index === minIndex;
        }
        case LabelDensity.FirstLastMinMax: {
            const maxP = array.reduce((a, b) => a.relativeVariance > b.relativeVariance ? a : b);
            const minP = array.reduce((a, b) => a.relativeVariance > b.relativeVariance ? b : a);
            const maxIndx = array.indexOf(maxP);
            const minIndx = array.indexOf(minP);
            return index === 0 || index === array.length - 1 || index === maxIndx || index === minIndx;
        }
    }
}

function getLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, chartData: ChartData,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, useSecondReferenceVariance: boolean): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(labelDensityFilterRelativeVariance, settings);
    const labelProperties = labelsDataPoints.map(d => {
        const value = useSecondReferenceVariance ? d.secondReferenceRelativeVariance : d.relativeVariance;
        const labelText = value === null ? "" : charting.getRelativeVarianceLabel(settings, 100 * value, false);
        const isOutlierDataPoint = isOutlier(d, chartData, useSecondReferenceVariance);
        const xPos = xScale(d.category) + xRangeBand / 2;
        const yPos = isOutlierDataPoint ?
            y + yScale(d.isNegative ? chartData.minOutlierValue : chartData.maxOutlierValue) + (d.isNegative ? -2 : 3) :
            y + yScale(value) + (value < 0 ? settings.labelFontSize + 5 : -9);
        return charting.getLabelProperty(labelText, xPos, yPos, isOutlierDataPoint ? true : null, value, d, settings.labelFontSize, settings.labelFontFamily, null, isOutlierDataPoint);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, settings: ChartSettings) {
    const xScaleBandWidth = xScale.bandwidth();
    const markerAttrs = charting.getCommentMarkerAttributes(xScaleBandWidth);
    const labelsMargin = settings.showDataLabels ? settings.labelFontSize + 4 : 0;
    const getMarkerVerticalPosition = (d: DataPoint): number => {
        const value = d.relativeVariance; //Improve?: check outliers
        return y + yScale(value) - (value < 0 ? -1 : 1) * (labelsMargin + markerAttrs.radius + markerAttrs.margin);
    };
    const getMarkerHorizontalPosition = (d: DataPoint): number => xScale(d.category) + xScale.bandwidth() / 2;
    charting.addCommentMarkers(container, commentDataPoints, getMarkerHorizontalPosition, getMarkerVerticalPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}
