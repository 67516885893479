import React from "react";
import useStore from "../../useStore";
import folderIcon from "../images/folder.svg";

export default function DrivesListing() {
  const [drives] = useStore("drives");
  const [, setActiveDriveId] = useStore("activeDriveId");

  return (
    <div className="drives-listing">
      <ul>
        {drives.map((drive) => (
          <li key={drive.id}>
            <button
              onClick={() => {
                setActiveDriveId(drive.id);
              }}
            >
              <img src={folderIcon} alt={drive.name} />
              {drive.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
