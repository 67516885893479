import { ColorScheme } from "@zebrabi/zebrabi-core/dist/lib/interfaces/ColorScheme";
import axios from "axios";

export const ORGANIZATION_STYLE_PREFIX = "organization-style-";

export interface OrganizationStyleSettings {
    colorScheme: ColorScheme;

    // Fonts
    labelFontFamily: string;
    labelFontSize: number;
    labelFontColor: string;

    titleFontFamily: string;
    titleFontSize: number;
    titleFontColor: string;

    groupTitleFontFamily: string;
    groupTitleFontSize: number;
    groupTitleFontColor: string;

    varianceDisplayType: number;

    previousYear: string;
    actual: string;
    plan: string;
    forecast: string;

    actual_previousYear: string;
    actual_previousYear_percent: string;
    actual_plan: string;
    actual_plan_percent: string;
    actual_forecast: string;
    actual_forecast_percent: string;
    forecast_previousYear: string;
    forecast_previousYear_percent: string;
    forecast_actual?: string;
    forecast_actual_percent?: string;
    forecast_plan: string;
    forecast_plan_percent: string;

    plan_previousYear?: string;
    plan_previousYear_percent?: string;
    plan_actual?: string;
    plan_actual_percent?: string;
    plan_forecast?: string;
    plan_forecast_percent?: string;

    previousYear_actual?: string;
    previousYear_actual_percent?: string;
    previousYear_plan: string;
    previousYear_plan_percent: string;
    previousYear_forecast?: string;
    previousYear_forecast_percent?: string;
}

export interface OrganizationStyleData {
    id: number,
    msftTenantId: string,
    name: string,
    styleJSON: OrganizationStyleSettings,
    scope: string,
    isDefault: boolean,
    msftObjectId: string
}

export async function getAvailableOrganizationStyles(organizationId: string, userId: string): Promise<OrganizationStyleData[]> {
    try {
        const requestUrl = process.env.LICENSING_SERVER_URL + `/add-in/styles/available/${organizationId}/${userId}`;
        const response = await axios.get(requestUrl);

        const availableStyles = response.data as OrganizationStyleData[];

        return availableStyles;
    } catch (e) {
        console.error(e);
    }

    return null;
}

export async function getOrganizationStyleById(styleId: number): Promise<OrganizationStyleData> {
    try {
        const requestUrl = process.env.LICENSING_SERVER_URL + `/add-in/styles/id/${styleId}`;
        const response = await axios.get(requestUrl);
        const styleData = response.data as OrganizationStyleData;

        return styleData;
    } catch (e) {
        console.error(e);
    }

    return null;
}