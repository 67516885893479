
import * as d3 from "../d3";
import { ChartSettings } from "./../settings/chartSettings";
import { ChartData, DataPoint, CategoryMenuItem, InvertResultAndHighlightMenuItem } from "./../interfaces";
import {
    CONTEXT_MENU_DIV, BODY, CLICK, DISPLAY, NONE, PX, OPACITY, LEFT, TOP, BLOCK, DIV, INPUT, TYPE, CHECKBOX, ID, CHECKED, LABEL, FOR, ELASTIC, BACKGROUND, MARGIN_LEFT, POSITION, HEIGHT, INLINE_BLOCK, NAME, EMPTY, WIDTH, STROKE, FILL, STROKE_WIDTH, RECT, X, Y, WHITE, G, MOUSEOVER, MOUSELEAVE, SCENARIO_ENUMS, ICON_MARKER_DISTANCE, SCENARIO_FULL_STRINGS, CIRCLE, HIGHLIGHT_DROPLINE, BAR, VARIANCE, FLEX
} from "./../library/constants";
import {
    CHART_CONTAINER,
} from "./../consts";
import Pickr from "@simonwep/pickr";
import * as scenarios from "./../scenarios";
import * as styles from "./../library/styles";
import { Visual } from "../visual";
import { createSvgElement, getBoundingBoxHtml } from "../library/drawing";
import { showPopupMessage } from "./ui";
import { enableToggleFloatingResult, enableToggleHighlight, enableToggleInvert, enableToggleResult, enableToggleScenarioPatterns } from "../helpers";

export function invertResultAndHighlightContextMenuWrapper(bottomEdge: number) {
    return invertResultAndHighlightContextMenu(this, bottomEdge);
}

function contextMenu(event: Event, isCategoryContextMenu: boolean): d3.Selection<any, any, any, any> {
    const contextMenu = d3.select(document.body).select("." + CONTEXT_MENU_DIV);
    contextMenu.selectAll("*").remove();
    if (!isCategoryContextMenu) {
        // this handler causes issues with context menu if color picker is used so it is not added for category menu
        d3.select(BODY).on(`${CLICK}.${CONTEXT_MENU_DIV}`, () => {
            contextMenu.style(DISPLAY, NONE);
        });
    }
    contextMenu.style(OPACITY, 0);
    event.preventDefault();
    event.stopPropagation();
    contextMenu.transition().duration(150).styleTween(OPACITY, () => { return d3.interpolateString(0, 1); });
    return contextMenu;
}

export function invertResultAndHighlightContextMenu(settings: ChartSettings, bottomEdge: number): (event: Event, cd: ChartData) => void {
    return (event: Event, cd: ChartData) => {
        const context = contextMenu(<Event>event, true);
        context.style(LEFT, Math.max((<PointerEvent>event).pageX - 5, 0) + PX)
            .style(TOP, ((<PointerEvent>event).pageY) - 3 + PX)
            .style(DISPLAY, BLOCK);
        addInvertResultAndHighlightContextMenu(cd, settings, context);
        const bb = (<any>context.nodes()[0]).getBoundingClientRect();
        const chartsVisualTop = Visual.GET_CHARTS_VISUAL_BOUNDING_CLIENT().top;
        if (bb.bottom > bottomEdge + chartsVisualTop) {
            // This block of code bumps context menu down 30px so it doesn't overlap with custom visual title or go out of bounds of custom visual
            if (bb.height > bb.top) {
                context.style(TOP, `${bb.top}${PX}`);
            } else {
                context.style(TOP, `${bottomEdge + chartsVisualTop - bb.height}${PX}`);
            }
        }
    };
}

export function stackedLegendContextMenu(settings: ChartSettings, bottomEdge: number): (event: Event, group: any) => void {
    return (event: Event, group: any) => {
        const groupName = group ? group.groupName : null;
        if (!groupName) {
            return;
        }
        const isOther = groupName === settings.topNStackedOthersLabel;
        const context = contextMenu(event, true);
        context.style(LEFT, Math.max(((<PointerEvent>event).pageX) - 25, 0) + PX)
            .style(TOP, ((<PointerEvent>event).pageY) - 3 + PX)
            .style(DISPLAY, BLOCK);
        addCategoryContextMenu(groupName, settings, context, false, isOther, false, true);

        if (!settings.shouldPlotVerticalCharts()) {
            const chartsVisualTop = Visual.GET_CHARTS_VISUAL_BOUNDING_CLIENT().top;
            const bb = (<any>context.nodes()[0]).getBoundingClientRect();
            if (bottomEdge !== null && bb.bottom > bottomEdge + chartsVisualTop) {
                context.style(TOP, `${bottomEdge + chartsVisualTop - bb.height}${PX}`);
            }
        }
    };
}

export function categoryContextMenu(settings: ChartSettings, bottomEdge: number, isSingleSeriesWaterfall: boolean, reportArea?: d3.Selection<SVGElement, any, any, any>): (event: Event, d: DataPoint) => void {
    return (event: Event, d: DataPoint) => {
        if (!isSingleSeriesWaterfall && d.isOther) {
            return;
        }
        const context = contextMenu(event, true);
        context.style(LEFT, Math.max((<MouseEvent>event).pageX - 25, 0) + PX)
            .style(TOP, ((<MouseEvent>event).pageY) - 3 + PX)
            .style(DISPLAY, BLOCK);
        addCategoryContextMenu(d.category, settings, context, isSingleSeriesWaterfall, d.isOther, d.isCumulative, false, d.category == settings.grandTotalLabel);
        const bb = (<any>context.nodes()[0]).getBoundingClientRect();
        const chartsVisualTop = Visual.GET_CHARTS_VISUAL_BOUNDING_CLIENT().top;
        if (settings.shouldFreezeCategories()) {
            context.style(TOP, ((<MouseEvent>event).pageY) - (bb.height + 5) + PX);
        }
        else if (bottomEdge !== null && bb.bottom > bottomEdge + chartsVisualTop) {
            context.style(TOP, `${bottomEdge + chartsVisualTop - bb.height}${PX}`);
        }

        const rightEdge = reportArea?.node().getBoundingClientRect().right;
        if (bb.right > rightEdge) {
            context.style(LEFT, Math.round(bb.left - (bb.right - rightEdge)) + PX);
        }
    };
}

function addCategoryContextMenu(category: string, settings: ChartSettings,
    context: d3.Selection<any, any, any, any>, isSingleSeriesWaterfall: boolean, isOther: boolean, isCumulative: boolean = false, isStackedLegendMenu: boolean = false, total: boolean = false) {
    const menuItems = isStackedLegendMenu ? getStackedLegendMenuItems(category, settings) : getCategoryMenuItems(category, settings, isSingleSeriesWaterfall, isOther, isCumulative, total);
    const menuElements = context
        .selectAll(".category-menu")
        .data(menuItems);
    const div = menuElements
        .enter()
        .append(DIV)
        .on(CLICK, (event, menuItem) => {
            event.preventDefault();
            if (!menuItem.scenarioCategories) {
                menuItem.action(category);
                context.style(DISPLAY, NONE);
            }
        })
        .classed(ELASTIC, true);
    menuElements.merge(div).filter(menuItem => !menuItem.scenarioCategories)
        .style(DISPLAY, FLEX)
        .append(INPUT)
        .attr(TYPE, CHECKBOX)
        .attr(ID, (menuItem, index) => "a" + index)
        .property(CHECKED, menuItem => menuItem.isChecked(category, settings));
    menuElements.merge(div)
        .append(LABEL)
        .attr(FOR, (menuItem, index) => "a" + index)
        .text(menuItem => menuItem.title);

    menuElements.merge(div).filter(item => !!item.customHighlightColor)
        .each(function (item: CategoryMenuItem) {
            const customColor = item.customHighlightColor(category, settings);
            const me = d3.select(this);
            const fillIcon = me.append(DIV);
            const pickerID = "color-picker-container-fill";
            fillIcon.attr(ID, pickerID)
                .style(BACKGROUND, customColor)
                .style(MARGIN_LEFT, "5px")
                .style(DISPLAY, INLINE_BLOCK)
                .style(POSITION, "relative")
                .classed("fill-rect", true)
                .on(CLICK, (event) => {
                    addCustomHighlightColorPicker(customColor, settings, category, pickerID, isStackedLegendMenu, item);
                    event.stopPropagation();
                });

        });

    const scenarioSelector = menuElements.merge(div).filter(menuItem => !!menuItem.scenarioCategories)
        .append(DIV)
        .attr(ID, "scenario-options-toggle")
        .attr(NAME, "scenario-options-toggle");

    if (scenarioSelector) {
        addScenarioOptionsMarkers(settings, category);
    }
}

function addScenarioOptionsMarkers(settings: ChartSettings, category: string) {
    const optionsDiv = document.getElementById("scenario-options-toggle");
    const svg = createSvgElement(optionsDiv, EMPTY)
        .attr(WIDTH, "96px")
        .attr(HEIGHT, "20px");
    drawMarkerIcons(svg, settings, category);
}

function drawMarkerIcons(svg: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, category: string) {
    const lastSelected = Object.assign({}, ...settings.scenarioCategories);
    const selectedMarkerScenario: number = scenarios.getScenarioEnum(scenarios.getScenarioName(lastSelected[category]) || SCENARIO_ENUMS[0]);

    addSelectedMarkerIcon(svg, selectedMarkerScenario, ICON_MARKER_DISTANCE * selectedMarkerScenario);

    SCENARIO_ENUMS.forEach((el, i) => {
        addSingleMarkerIcon(svg, settings, el, category, lastSelected, SCENARIO_FULL_STRINGS[i], i);
    });
}

function addSingleMarkerIcon(svg: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, scenario: string, category: string, lastSelected: object, text: string, xPosIndex: number) {
    const icon = svg.append(G);
    icon.append(RECT)
        .attr(X, 3 + xPosIndex * ICON_MARKER_DISTANCE)
        .attr(Y, 3)
        .attr(WIDTH, 12)
        .attr(HEIGHT, 12);

    icon.on(CLICK, () => {
        const newSelection = scenarios.getScenarioEnum(scenario);
        if (lastSelected[category] !== newSelection) {
            removePrevSelectedMarkerIcon(svg, lastSelected[category], ICON_MARKER_DISTANCE * lastSelected[category]);
            addSelectedMarkerIcon(svg, newSelection, ICON_MARKER_DISTANCE * newSelection, NONE);
        }
        lastSelected[category] = newSelection;
        settings.persistScenarioCategories(category, newSelection);
    }).on(MOUSEOVER, () => {
        const bb = getBoundingBoxHtml(<HTMLElement>(<unknown>icon.node()));
        const optionsContainer = Visual.element;
        showPopupMessage(optionsContainer, bb.right - 5, bb.top - 20, text);
    }).on(MOUSELEAVE, () => {
        d3.selectAll("#scenario-options-toggle > g").remove();
        d3.selectAll(".message-tooltip").remove();
    });

    styles.applyToElement(icon, settings.colorScheme.neutralColor, scenarios.getScenarioEnum(scenario), settings.chartStyle, settings.colorScheme);
}

function addSelectedMarkerIcon(svg: d3.Selection<SVGElement, any, any, any>, selectedMarker: number, xPos: number, fillColor: string = WHITE) {
    const currentSelected = svg.append(G);
    currentSelected.append(RECT)
        .attr(ID, `selected-${selectedMarker}`)
        .attr(X, xPos + 1)
        .attr(Y, 1)
        .attr(WIDTH, 16)
        .attr(HEIGHT, 16)
        .attr(STROKE, "blue")
        .attr(FILL, fillColor)
        .attr(STROKE_WIDTH, 2);
}

function removePrevSelectedMarkerIcon(svg: d3.Selection<SVGElement, any, any, any>, selectedMarker: number, xPos: number) {
    svg.select(`g #selected-${selectedMarker}`).remove();
}

function addInvertResultAndHighlightContextMenu(cd: ChartData, settings: ChartSettings, context: d3.Selection<any, any, any, any>) {
    const menuItems = getGroupsMenuItems(cd, settings);
    let menuElements = context
        .selectAll(".invert-result")
        .data(menuItems);
    const div = menuElements
        .enter()
        .append(DIV)
        .on(CLICK, (event, menuItem) => {
            event.preventDefault();
            menuItem.action(cd);
            context.style(DISPLAY, NONE);
        })
        .classed(ELASTIC, true);

    menuElements = menuElements.merge(div);

    menuElements
        .append(INPUT)
        .attr(TYPE, CHECKBOX)
        .attr(ID, (menuItem, index) => "a" + index)
        .property(CHECKED, menuItem => menuItem.isChecked(menuItem.title === "Invert" ? cd : cd.group, settings));
    menuElements
        .append(LABEL)
        .attr(FOR, (menuItem, index) => "a" + index)
        .text(menuItem => menuItem.title);

    menuElements
        .filter(item => !!item.customHighlightColor)
        .each(function (item: InvertResultAndHighlightMenuItem) {
            const customColor = item.customHighlightColor(cd.group, settings);
            const me = d3.select(this);
            const fillIcon = me.append(DIV);

            const pickerID = "color-picker-container-fill";
            fillIcon.attr(ID, pickerID)
                .style(BACKGROUND, customColor)
                .style(MARGIN_LEFT, "5px")
                .style(DISPLAY, "inline-block")
                .style(POSITION, "relative")
                .classed("fill-rect", true)
                .on(CLICK, (event) => {
                    addCustomHighlightColorPicker(customColor, settings, cd.group, pickerID, true, item, cd);
                    event.stopPropagation();
                });
        });
}

function getGroupsMenuItems(cd: ChartData, settings: ChartSettings): InvertResultAndHighlightMenuItem[] {
    return [
        ...(!cd.isOther
            ? [
                {
                    title: "Invert",
                    action: (cd: ChartData) => {
                        settings.persistInvertedGroups(cd);
                    },
                    isChecked: (cd: ChartData, settings: ChartSettings) => {
                        return settings.isGroupInverted(cd.group);
                    },
                }
            ]
            : []),
        {
            title: "Highlight",
            action: (cd: ChartData) => {
                settings.persistHighlightedGroups(cd.group);
            },
            isChecked: (cd: string, settings: ChartSettings) => {
                return settings.isGroupHighlighted(cd);
            },
            customHighlightColor: (group: string, settings: ChartSettings) => {
                return settings.getGroupHighlightColor(cd.group);
            },
        }
    ];
}


function getStackedLegendMenuItems(group: string, settings: ChartSettings): CategoryMenuItem[] {
    const menuItems: CategoryMenuItem[] = [];
    menuItems.push({
        title: "Highlight",
        action: (group: string) => {
            settings.persistHighlightedGroups(group);
        },
        isChecked: (group: string, settings: ChartSettings) => {
            return settings.isGroupHighlighted(group);
        },
        customHighlightColor: (group: string, settings: ChartSettings) => {
            return settings.getGroupHighlightColor(group);
        },
    });
    return menuItems;
}

function getCategoryMenuItems(category: string, settings: ChartSettings, isSingleSeriesWaterfall: boolean, isOther: boolean, isCumulative: boolean, total?: boolean): CategoryMenuItem[] {
    const menuItems: CategoryMenuItem[] = [];
    if (enableToggleInvert(isOther, isCumulative, total)) {
        menuItems.push({
            title: "Invert",
            action: (category: string) => {
                settings.persistInvertedCategories(category);
            },
            isChecked: (category: string, settings: ChartSettings) => {
                return settings.isCategoryInverted(category);
            },
        });
    }
    if (enableToggleResult(isOther, isSingleSeriesWaterfall, isCumulative, total)) {
        menuItems.push({
            title: "Result",
            action: (category: string) => {
                settings.persistIsResultCategories(category);
            },
            isChecked: (category: string, settings: ChartSettings) => {
                return settings.isCategoryResult(category);
            },
        });
        if (enableToggleScenarioPatterns(settings, category)) {
            menuItems.push({
                title: "Scenario pattern",
                scenarioCategories: true
            });
        }
    }
    if (enableToggleHighlight(settings.chartType, isSingleSeriesWaterfall, isCumulative)) {
        menuItems.push({
            title: "Highlight",
            action: (category: string) => {
                settings.persistHighlightedCategories(category);
            },
            isChecked: (category: string, settings: ChartSettings) => {
                return settings.isCategoryHighlighted(category);
            },
            customHighlightColor: (category: string, settings: ChartSettings) => {
                return settings.getCategoryHighlightColor(category);
            },
        });
    }
    return menuItems;
}

function addCustomHighlightColorPicker(customColor: string, settings: ChartSettings, category: string, pickerID: string, isStackedLegendMenu: boolean = false, menuItem?: any, cd?: ChartData) {
    const chartAreas = d3.selectAll(`${G}.${CHART_CONTAINER}`);

    const rects = chartAreas.selectAll(`${RECT}.${BAR}:not(.${VARIANCE})`).filter((d: DataPoint) => {
        return (d && d.category !== undefined && d.category === category);
    });
    const dropline = chartAreas.selectAll(`.${HIGHLIGHT_DROPLINE}`).filter((d: DataPoint) => {
        return (d && d.category !== undefined && d.category === category);
    });
    const circle = chartAreas.selectAll(`${CIRCLE}`).filter((d: DataPoint) => {
        return (d && d.category !== undefined && d.category === category);
    });

    const fillpickr = Pickr.create({
        el: "#" + pickerID,
        container: "#sandbox-host",
        theme: "monolith",
        swatches: null,
        appClass: "fillpickr",
        closeWithKey: "Escape",
        adjustableNumbers: true,
        default: customColor,
        useAsButton: true,
        autoReposition: false,
        defaultRepresentation: "RGBA",
        components: {
            preview: true,
            hue: true,
            opacity: true,
            interaction: {
                save: true,
                clear: true,
                input: true,
            },
        },
    });

    fillpickr.on("init", instance => {
        fillpickr.show().on("show", () => {
            fixPickerPosition(".fillpickr");
        });
    });
    fillpickr.on("change", (color) => {
        const hexColor = color ? color.toHEXA().toString() : null;
        rects.attr(FILL, hexColor);
        circle.attr(FILL, hexColor);
        circle.attr(STROKE, hexColor);
        dropline.attr(STROKE, hexColor);
    });
    fillpickr.on("save", (color) => {
        const hexColor = color ? color.toHEXA().toString() : null;
        if (isStackedLegendMenu) {
            if (!settings.isGroupHighlighted(cd ? cd.group : category)) {
                settings.persistHighlightedGroupsCustomColors(category, hexColor);
                menuItem.action(cd ? cd : category);
            }
            settings.persistHighlightedGroupsCustomColors(category, hexColor);
        }
        else {
            if (!settings.isCategoryHighlighted(cd ? cd.group : category)) {
                settings.persistCustomHighlightColor(category, hexColor);
                menuItem.action(category);
            }
        }
        cd ? settings.persistHighlightedGroupsCustomColors(category, hexColor) : settings.persistCustomHighlightColor(category, hexColor);
        fillpickr.hide();
    })
        .on("clear", () => {
            if (isStackedLegendMenu) {
                if (settings.isGroupHighlighted(cd ? cd.group : category)) {
                    menuItem.action(cd ? cd : category);
                    settings.persistHighlightedGroupsCustomColors(category, null);
                } else {
                    settings.persistHighlightedGroupsCustomColors(category, null);
                }
            }
            else {
                if (settings.isCategoryHighlighted(cd ? cd.group : category)) {
                    menuItem.action(category);
                    settings.persistCustomHighlightColor(category, null);
                } else {
                    settings.persistCustomHighlightColor(category, null);
                }
            }
            fillpickr.hide();
        });
}

function fixPickerPosition(classname: string) {
    const pickerSelection = d3.select(classname);
    const pickerheight = parseInt(pickerSelection.style(HEIGHT));
    const visualHeight = Visual.visualViewPort.height;
    if (visualHeight < pickerheight + parseInt(pickerSelection.style(TOP))) {
        pickerSelection.style(TOP, (visualHeight - pickerheight) / 2 - 10 + PX);
    }
}
