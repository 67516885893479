export const valuesTranslations = [
    "Values",   // english
    "Werte",    // german
    "Valores",  // spanish, portuguese
    "Valori",   // italian
    "Valeurs",  // french
    "Waarden",  // dutch
    "Vrednosti",    // slovenian
    "Vrijednosti", // croatian
    "Wartości", // polish
    "Hodnoty",  // czech
    "Värden",   // swedish
    "Вредности", // serbian (Vrednosti)
    "Стойности", // bulgarian (Stojnosti)
    "Значения", // russian (Znacheniya)
    "值",       // chinese (Zhí)
    "数值",     // chinese (Shùzhí)
    "値",       // japanese (Chi, Atai)
    "값"       // korean (Geum, Gab)
];