import { createFromTemplate } from "../../helpers/template";
import InputConfig from "../global-toolbar/InputConfig";

export const input = (inputConfig: InputConfig) => createFromTemplate(`
<div class="input input-${inputConfig.name} ${(inputConfig.hidden ? "hidden" : "")}">
    <label>
        ${(inputConfig.type !== "checkbox") ? inputConfig.label : ""}
    
        <input 
            value="${inputConfig.value}" 
            name="${inputConfig.name}" 
            id="${inputConfig.id}" 
            type="${inputConfig.type}"
            ${(inputConfig.checked ? `checked` : "")}
            ${(inputConfig.min !== undefined ? `min=${inputConfig.min}`  : "")}
            ${(inputConfig.max !== undefined ? `max=${inputConfig.max}`  : "")}>

        
        ${(inputConfig.type === "checkbox") ? inputConfig.label : ""}
    </label>
</div>
`);
