import { LinkButton } from "@zebrabi/design-library";
import ContextMenu from "../base/ContextMenu";
import {
    CLICK, CUSTOMER_STYLE_VISIBLE, DATA_LABEL_MENU, MOUSELEAVE, ZEBRABI_CHARTS_CONTAINER
} from "../../library/constants";
import { DataLabelSetting, DataLabelMenuItem } from "../../enums";
import { ChartSettings } from "../../settings/chartSettings";
import { ScenarioOptions } from "../../interfaces";
import DataLabelMenuItemFactory from "./DataLabelMenuItemFactory";

export default class DataLabelMenu extends ContextMenu {
    private menuItemFactory = new DataLabelMenuItemFactory();
    protected template: string = `<div class="context-menu2 data-label-menu">
                                    <div class="menu-title">Data Labels</div>
                                    <ul class="menu-items"></ul>
                                  </div>`;

    constructor() {
        super();
    }

    protected addMenuItems() {
        this.appendItem(DataLabelMenuItem.fontSettings);
        this.appendItem(DataLabelMenuItem.decimalPlaces);
        this.appendItem(DataLabelMenuItem.units);
        this.appendItem(DataLabelMenuItem.showPercentageInLabel);
        this.appendItem(DataLabelMenuItem.negativeValuesFormat);
        this.appendItem(DataLabelMenuItem.labelDensity);
        this.appendItem(DataLabelMenuItem.labelPercentagePointUnit);
        this.appendItem(DataLabelMenuItem.backgroundTransparency);
        if (this.chartSettings.isLineChart()) {
            this.appendItem(DataLabelMenuItem.showReferenceLabels);
        }
        this.addResetToDefault();
    }

    protected closeMenuOnOutsideClick(): void {
        const closeMenuOnOutsideClick = (event: MouseEvent) => {
            const dataLabelMenu = document.querySelector(`.${DATA_LABEL_MENU}`);
            const splitButton = document.querySelector(`.${ZEBRABI_CHARTS_CONTAINER}`).querySelector(".split-btn");

            if ((splitButton || dataLabelMenu) && !event.composedPath().includes(splitButton) && !event.composedPath().includes(dataLabelMenu)) {
                dataLabelMenu?.remove();
                splitButton?.remove();
                document.removeEventListener(CLICK, closeMenuOnOutsideClick);
            }
        };

        document.addEventListener(CLICK, closeMenuOnOutsideClick);
    }

    protected closeMenuOnDocumentMouseLeave() {
        document.addEventListener(MOUSELEAVE, () => {
            const dataLabelMenu = <HTMLElement>document.querySelector(`.${DATA_LABEL_MENU}`);
            const splitButton = document.querySelector(`.${ZEBRABI_CHARTS_CONTAINER}`).querySelector(".split-btn");

            dataLabelMenu?.remove();
            splitButton?.remove();
        });
    }

    private appendItem(menuItem: DataLabelMenuItem, id?: string): void {
        const item = this.menuItemFactory.getItem(menuItem);
        const menuItemDiv = this.createMenuItem(id);
        item.appendTo(menuItemDiv);
    }

    protected addResetToDefault() {
        const resetToDefault = new LinkButton("Reset to default");
        const menuItemDiv = this.createMenuItem();
        resetToDefault.appendTo(menuItemDiv);
        resetToDefault.containerNode().addEventListener(CLICK, () => {
            this.resetToDefault();
        });
    }

    protected resetToDefault() {
        document.querySelector(`.${DATA_LABEL_MENU}`).classList.add("reset");
        const val = {};
        // IMPORTANT: when null is persisted on string type, property is not deleted from object but is instead empty string
        const settings = new ChartSettings(CUSTOMER_STYLE_VISIBLE.toString() === "true", "en", <ScenarioOptions>{});
        for (const enumMember of Object.keys(DataLabelSetting)) {
            if (isNaN(Number(enumMember))) {
                val[enumMember] = settings[DataLabelSetting[enumMember]];
            }
        }
        this.chartSettings.persistDataLabelSettings(val);
    }
}


