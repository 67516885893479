import { createFromTemplate } from "@zebrabi/global-toolbar/dist/lib/helpers/template";
// Improve: extract common types to some common package
import { ColorScheme } from "packages/visuals/charts/library/interfaces";
import { STYLE_EDITOR_URL } from "@zebrabi/licensing/constants";
import { ProductType } from "@zebrabi/licensing/licensing.types";
import { openURL } from "@zebrabi/licensing/helpers";

const dialogID = "export-import-style-dialog-id";
const invalidLabelID = "invalid-style-label-id";

const dialog = (knowledgeBaseUrl: string) => createFromTemplate(
    `<dialog id="${dialogID}" class="corporate-style-dialog">
        <div class="style-dialog-div">
            <h2>Save theme</h2>
            <a href="#" class="close icon close-icon-base64" id="style-dialog-close-icon"></a>
            <label>
                <span id="${invalidLabelID}">[parsing error: Invalid style format]</span>
            </label>
            <div id="corp-style-div" class="corp-style">
                <textarea id="style-text-area-id" spellcheck="false" readonly></textarea>
            </div>
            <div>
                <button id="style-dialog-copy-style">Copy JSON theme</button>
            </div>
            <div>
                <a href="#" target="_blank" id="corporate-style-learn-more">To learn how you can save the style as a corporate theme, visit this link</a>
            </div>
        </div>
    </dialog>`);

// <div id="corp-style-div" class="corp-style">
//     <textarea id="style-text-area-id"></textarea>
// </div>

export function createExportImportSettingsDialog<ExportableSettings>(settings: ExportableSettings, editDataHandler: (newStyle: ExportableSettings) => void) {
    if (!settings) {
        return;
    }
    const knowledgeBaseUrl = STYLE_EDITOR_URL.replace(
        "$host",
        Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint
    ).replace("$visual", process.env.ZBI_VISUAL);
    let addEventHandlers = false;
    let styleDialog = <HTMLDialogElement>document.getElementById(dialogID);
    if (!styleDialog) {
        document.getElementsByTagName("body")[0].append(dialog(knowledgeBaseUrl));
        styleDialog = <HTMLDialogElement>document.getElementById(dialogID);
        addEventHandlers = true;
    }

    const textArea = <HTMLTextAreaElement>styleDialog.querySelector("#style-text-area-id");
    const invalidStyleLabel = <HTMLTextAreaElement>styleDialog.querySelector("#" + invalidLabelID);
    invalidStyleLabel.classList.add("hidden");

    const exportableSettings = settings; //ExportableSetting = getExportableSettings(settings);

    //exportableSettings = { ...exportableSettings, ...<ImpportableSetting>settings }
    //console.log(exportableSettings)
    textArea.value = JSON.stringify(exportableSettings, null, 3);

    if (addEventHandlers) {

        styleDialog.addEventListener("click", (e) => {
            if ((<HTMLElement>e.target).tagName === "DIALOG") {
                styleDialog.close();
                e.stopPropagation();
            }
        });

        const closeBtn = <HTMLButtonElement>styleDialog.querySelector("#style-dialog-close-icon");
        closeBtn.addEventListener("click", (e) => {
            styleDialog.close();
            e.stopPropagation();
        });

        const styleTextArea = <HTMLTextAreaElement>styleDialog.querySelector("#style-text-area-id");
        const copyBtn = <HTMLButtonElement>styleDialog.querySelector("#style-dialog-copy-style");
        copyBtn.addEventListener("click", (e) => {
            styleTextArea.select();
            // USing depricated execCommand, since navigator.clipboard.writeText throws permission error
            document.execCommand("copy");
            e.stopPropagation();
        });

        const learnMoreURL = <HTMLAnchorElement>styleDialog.querySelector("#corporate-style-learn-more");
        learnMoreURL?.addEventListener("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            openURL(knowledgeBaseUrl);
        });
    }

    styleDialog.showModal();
    textArea.scrollTop = 0;
}


// function getExportableSettings(settings: any): ExportableSetting {
//     return {
//         colorScheme: settings.colorScheme,
//         actual: settings.actual,
//         previousYear: settings.previousYear,
//         plan: settings.plan,
//         forecast: settings.forecast,
//     };
// }

function isValidStyleFormat(style: any): boolean {
    return style &&
        style.colorScheme &&
        isValidColorScheme(style.colorScheme);
}

function isValidColorScheme(colorScheme: ColorScheme): boolean {
    const hexColorRegex = /^#([0-9A-F]{3}){1,2}$/i;

    return hexColorRegex.test(colorScheme.positiveColor) &&
        hexColorRegex.test(colorScheme.negativeColor) &&
        hexColorRegex.test(colorScheme.neutralColor) &&
        hexColorRegex.test(colorScheme.markerColor) &&
        hexColorRegex.test(colorScheme.lineColor) &&
        hexColorRegex.test(colorScheme.axisColor) &&
        hexColorRegex.test(colorScheme.gridlineColor) &&
        hexColorRegex.test(colorScheme.majorGridlineColor) &&
        hexColorRegex.test(colorScheme.dotChartColor) &&
        hexColorRegex.test(colorScheme.highlightColor) &&
        (!colorScheme.useCustomScenarioColors ||
            hexColorRegex.test(colorScheme.previousYearColor) &&
            hexColorRegex.test(colorScheme.planColor) &&
            hexColorRegex.test(colorScheme.forecastColor)
        );
}

