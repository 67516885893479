import { ChartData, DataPoint } from "./../interfaces";
import { ChartType, DotChartMarkerShape, MarkerSize, LabelDensity, GridlineStyle } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { LabelProperties } from "./../library/interfaces";

import { PATH, STROKE_LINEJOIN, STROKE_LINECAP, NONE, ROUND, STROKE, STROKE_OPACITY, STROKE_WIDTH, FILL, MARKER, HIGHLIGHTABLE, D, TRANSFORM, HEIGHT, RECT, WIDTH, X, Y, BAR, TOOLTIP, DROPLINE, STROKE_DASHARRAY } from "./../library/constants";
import { SECOND_ACTUAL_VALUE } from "./../consts";

import * as drawing from "./../library/drawing";
import * as charting from "./chart";
import * as helpers from "./../library/helpers";
import * as d3 from "../d3";
import * as formatting from "./../library/formatting";
import { calculateMarkerFixedSize, calculateMarkerAutoSize } from "../helpers";

// eslint-disable-next-line max-lines-per-function
export default function dotChart(reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, x: number, y: number, height: number, width: number,
    chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number, plotLegend: boolean, isSingleSeriesViewModel: boolean) {
    let chartWidth = width;
    let chartXPos = x;
    if (plotLegend) {
        chartWidth -= settings.getLegendWidth();
        chartXPos += settings.getLegendWidth();
    }

    if (settings.differenceHighlight) {
        chartWidth -= settings.differenceHighlightWidth;
    }
    else if ((settings.chartType === ChartType.Line || settings.chartType === ChartType.Area) && charting.shouldProvideSomeSpaceForLineChartRightLegend(settings, plotLegend)) {
        chartWidth -= settings.getRightLegendWidth();
    }

    if (settings.showGrandTotal && (settings.chartType === ChartType.Variance || settings.chartType === ChartType.Bar)) {
        chartWidth = chartWidth * chartData.dataPoints.length / (chartData.dataPoints.length + 1.5);
    }
    else if (settings.showGrandTotal && settings.chartType === ChartType.Waterfall && isSingleSeriesViewModel) {
        chartWidth = chartWidth - chartWidth / chartData.dataPoints.length;
        chartData.dataPoints.pop();
    }

    const chartContainer = drawing.createGroupElement(reportArea, "dot-chart-" + chartIndex);
    const yScale = charting.getYScale(min, max, height, topMargin, bottomMargin);
    const xScale = charting.getXScale(chartData, chartXPos, chartWidth, 0);
    const valueDataPoints = chartData.dataPoints.filter(dp => dp.secondActualValue !== null);
    const yLine = yScale(0) + y;

    if (settings.dotChartLineWidth != 0) {
        const lineValueFunction = d3.line<DataPoint>()
            .x((d) => { return xScale(d.category) + xScale.bandwidth() / 2; })
            .y((d) => { return y + yScale(d.secondActualValue); });
        const valueLine = chartContainer.append(PATH)
            .attr(STROKE_LINEJOIN, ROUND)
            .attr(STROKE_LINECAP, ROUND)
            .attr(STROKE_WIDTH, settings.dotChartLineWidth)
            .attr(STROKE_DASHARRAY, () => {
                if (settings.dotChartLineStyle === GridlineStyle.Dotted)
                    return `2, ${2 * settings.dotChartLineWidth}`;
                else if (settings.dotChartLineStyle === GridlineStyle.Dashed)
                    return `${5 * settings.dotChartLineWidth}, ${5 * settings.dotChartLineWidth}`;
            })
            .attr(FILL, NONE)
            .attr(STROKE, settings.colorScheme.dotChartColor)
            .attr(STROKE_OPACITY, 1 - (settings.dotChartLineTransparency / 100));
        valueLine.attr(D, lineValueFunction(valueDataPoints));
    }

    const allDataPoints = valueDataPoints;
    const xRangeBand = xScale.bandwidth();
    const isPercentageData = settings.dotChartDisplayUnits === "P" || settings.isDotChartPercentageData;
    const labelsFormat = isPercentageData ? "0%" : null;
    let labelsProperties = getDotChartLabelProperties(allDataPoints, settings, labelsFormat, false, xScale, yScale, y, max, topMargin);
    labelsProperties = charting.checkForOverlappedLabels(labelsProperties, settings.dotChartLabelDensity, xRangeBand);
    if (labelsProperties.length > 0) {
        const labels = charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings);
        labels.attr(FILL, settings.dotChartFontColor);
    }

    let valueMarkersDataPoints = valueDataPoints.filter(dotChartMarkerDensityFilter, settings);
    if (settings.dotChartMarkerDensity === LabelDensity.Auto) {
        const filterByRemainingLabels = function (dp: DataPoint) {
            return (labelsProperties.findIndex(el => el.category === dp.category) >= 0);
        };
        valueMarkersDataPoints = valueMarkersDataPoints.filter(filterByRemainingLabels);
    }

    const markerAutoSize = calculateMarkerAutoSize(xScale, settings);
    const markerFixedSize = calculateMarkerFixedSize(settings.dotChartMarkerFixedSize);

    plotDotMarkers(chartContainer, chartIndex, valueMarkersDataPoints, xScale, y, yScale, SECOND_ACTUAL_VALUE, settings.colorScheme.dotChartColor, settings.dotChartMarkerShape, settings.dotChartMarkerSizing, markerFixedSize, markerAutoSize);

    if (settings.dotChartDroplineWidth != 0) {
        const barsClass = `${BAR}_RELATIVE_${chartIndex} ${HIGHLIGHTABLE}`;
        const bars: d3.Selection<any, DataPoint, any, any> = drawing.getShapes(chartContainer, barsClass, barsClass, RECT, valueMarkersDataPoints);
        bars
            .attr(WIDTH, d => settings.dotChartDroplineWidth)
            .attr(HEIGHT, (d, i) => {
                if (d.secondActualValue > 0) {
                    return yLine - (y + yScale(d.secondActualValue));
                }
                else {
                    return y + yScale(d.secondActualValue) - yLine;
                }
            })
            .classed(DROPLINE, true)
            .attr(X, d =>
                (xScale(d.category) + xScale.bandwidth() / 2)
                - (settings.dotChartDroplineWidth > 1 ? (settings.dotChartDroplineWidth / 2 - 0.5) : 0))
            .attr(Y, d => {
                const y_marker = y + yScale(d.secondActualValue);
                if (d.secondActualValue > 0) {
                    return y_marker;
                }
                else {
                    return yLine;
                }
            })
            .attr(FILL, settings.dotChartDroplineColor);

        // Visual.tooltipServiceWrapper.addTooltip(reportArea.selectAll(`.${DROPLINE}, .${TOOLTIP}`),
        //     (tooltipEvent: TooltipEventArgs<DataPoint>) => charting.getTooltipData(tooltipEvent.data, settings,
        //         labelsFormat, formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true), chartData.isInverted, undefined, chartData),
        //     (tooltipEvent: TooltipEventArgs<DataPoint>) => tooltipEvent.data.selectionId);
    }
}

function getDotChartLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number,
    max: number, topMargin: number): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(dotChartLabelDensityFilter, settings);
    const labelProperties = labelsDataPoints.map((d, i) => {
        const plotValue = d.secondActualValue;
        const labelText = charting.getFormattedDataLabel(plotValue, settings.dotChartDecimalPlaces, settings.dotChartDisplayUnits,
            settings.locale, settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        const xPos = xScale(d.category) + xScale.bandwidth() / 2;
        const yPos = getDotChartLabelYPosition(y, yScale, plotValue, max, topMargin, settings, d, labelsDataPoints, i, xScale);
        return charting.getLabelProperty(labelText, xPos, yPos, null, plotValue, d, settings.labelFontSize, settings.labelFontFamily);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.dotChartLabelDensity, xRangeBand);
}

function getDotChartLabelYPosition(y: number, yScale: d3.ScaleLinear<number, number>, plotValue: number, max: number, topMargin: number,
    settings: ChartSettings, d: DataPoint, labelsDataPoints: DataPoint[], i: number, xScale: d3.ScaleBand<string>) {
    let yPosition = y + yScale(plotValue);
    let shouldMoveLabelBelow = false;
    const isEnoughSpaceBelowLine = yScale(max - Math.abs(plotValue)) - topMargin > settings.labelFontSize + 7;
    if (isEnoughSpaceBelowLine) {
        if (settings.dotChartLabelDensity === LabelDensity.Full || settings.dotChartLabelDensity === LabelDensity.Auto) {
            if (labelsDataPoints.length > 1 && (i === 0 || i === labelsDataPoints.length - 1)) {
                const x0 = xScale(d.category);
                const y0 = yScale(plotValue);
                const nextOrPreviousDataPoint = i === 0 ? labelsDataPoints[1] : labelsDataPoints[labelsDataPoints.length - 2];
                const x1 = xScale(nextOrPreviousDataPoint.category);
                const y1 = yScale(nextOrPreviousDataPoint.secondActualValue);
                const angle = helpers.getLineAngle(x1, x0, y1, y0);
                if (i === 0 && angle > 35 || i !== 0 && angle < 150 && angle > 0) {
                    shouldMoveLabelBelow = true;
                }
            }
            else if (i > 0 && labelsDataPoints[i - 1].secondActualValue > d.secondActualValue && i < labelsDataPoints.length - 1
                && d.secondActualValue < labelsDataPoints[i + 1].secondActualValue) {
                shouldMoveLabelBelow = true;
            }
        }
    }
    const labelmargin = shouldMoveLabelBelow ? settings.labelFontSize + 6 : -10;
    yPosition = yPosition + labelmargin;
    return yPosition;
}

function plotDotMarkers(chartContainer: d3.Selection<SVGElement, any, any, any>, chartIndex: number, markerDataPoints: DataPoint[], xScale: d3.ScaleBand<string>,
    y: number, yScale: d3.ScaleLinear<number, number>, valueProperty: string, color: string, markerShape: DotChartMarkerShape, markerSizing: MarkerSize, markerFixedSize: number, markerAutoSize: number): d3.Selection<any, DataPoint, any, any> {
    let markerSymbolType: d3.SymbolType = undefined;
    const markerShapeString = DotChartMarkerShape[markerShape];
    let markerRotationDegrees = 0;

    switch (markerShape) {
        case DotChartMarkerShape.Circle:
            markerSymbolType = d3.symbolCircle;
            break;

        case DotChartMarkerShape.Diamond:
            markerSymbolType = d3.symbolSquare;
            markerRotationDegrees = 45;
            break;

        case DotChartMarkerShape.Square:
            markerSymbolType = d3.symbolSquare;
            break;

        case DotChartMarkerShape.Triangle:
            markerSymbolType = d3.symbolTriangle;
            break;
    }

    const markersClass = `${markerShapeString}dot${MARKER}${chartIndex} ${HIGHLIGHTABLE}`;
    const markers = drawing.getShapes(chartContainer, markersClass, markersClass, PATH, markerDataPoints);
    markers
        .attr(D, d3.symbol().type(markerSymbolType).size(markerSizing === MarkerSize.Fixed ? markerFixedSize : markerAutoSize))
        .attr(FILL, color)
        .attr(TRANSFORM, ((d) => {
            const xPos = (xScale(d.category) + xScale.bandwidth() / 2 + 0.5);
            const yPos = y + yScale(d[valueProperty]);
            return `translate(${xPos},${yPos}) rotate(${markerRotationDegrees})`;
        }));

    return markers;
}

function dotChartLabelDensityFilter(point: DataPoint, index: number, array: DataPoint[]): boolean {
    return charting.applyDensity(this.dotChartLabelDensity, index, array, SECOND_ACTUAL_VALUE);
}
function dotChartMarkerDensityFilter(point: DataPoint, index: number, array: DataPoint[]): boolean {
    if (this.dotChartMarkerDensity === LabelDensity.Auto) {
        return charting.applyDensity(this.dotChartLabelDensity, index, array, SECOND_ACTUAL_VALUE);
    }
    else {
        return charting.applyDensity(this.dotChartMarkerDensity, index, array, SECOND_ACTUAL_VALUE);
    }
}
