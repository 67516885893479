import {
  DefaultButton,
  ButtonStateTypes,
  DefaultButtonSubscriber,
  IconButton,
  SplitButton,
  SplitButtonData,
  SplitButtonActions,
  SplitButtonSubscriber,
  IconName,
} from "@zebrabi/design-library";
import { dataLinkingEventBus } from "./DataLinkingEventBus";
import { mountReactExcelDataLinking } from "./react";
import { ExcelDataLinkingStore } from "./store";
import { signInService } from "@zebrabi/licensing/components/signin/MSFTSignIn";
import { licensing } from "@zebrabi/licensing/Licensing";
import SharepointBrowserAPI from "@zebrabi/sharepoint-api-browser/SharepointBrowserAPI";
import { transformSharePointResponse } from "./helpers";

export default class ExcelDataLinkingSwitcher {
  private readonly clickOutsideOverlayElement: HTMLDivElement = document.createElement("div"); // used for click event to close on click outside of dropdown menu
  private readonly buttonsDropdownMenu: HTMLDivElement = document.createElement("div");
  private refreshData = new DefaultButton("Refresh data");
  private linkData = new DefaultButton("Link data to Excel");
  private editLinkData = new DefaultButton("Edit link", ButtonStateTypes.text, undefined, {
    iconBefore: IconName.EditLink,
  });
  private unlinkData = new DefaultButton("Remove link", ButtonStateTypes.text, undefined, {
    iconBefore: IconName.Unlink,
  });
  private splitButton = new SplitButton("Edit link", ButtonStateTypes.text); // fallback text - it will be replaced with an icon later on

  public appendElementsToHtml(node: HTMLElement) {
    this.clickOutsideOverlayElement.classList.add("excel-data-linking-dropdown-menu-backdrop", "hidden");
    document.body.appendChild(this.clickOutsideOverlayElement);

    this.buttonsDropdownMenu.classList.add("excel-data-linking-dropdown-menu", "hidden");
    document.body.appendChild(this.buttonsDropdownMenu);

    this.appendHandlerToRefreshDataButton();
    this.appendHandlerToLinkDataButton();
    this.appendHandlerToUnlinkDataButton();
    this.appendHandlerToEditLinkDataButton();
    this.appendHandlerToSplitButton();

    const div = document.createElement("div");
    div.classList.add("excel-data-linking-buttons");
    node.appendChild(div);

    this.refreshData.appendTo(div);
    this.linkData.appendTo(div);

    this.splitButton.appendTo(div);
    this.editLinkData.appendTo(this.buttonsDropdownMenu);
    this.unlinkData.appendTo(this.buttonsDropdownMenu);

    setTimeout(() => {
      this.replaceTextWithIconOnSplitButton();
    }, 10);
  }

  public updateButtonsStates() {
    const { worksheetId, workbookId } = ExcelDataLinkingStore.get().loaded;

    if (worksheetId || workbookId) {
      this.linkData.buttonNode().classList.add("hidden");

      this.refreshData.buttonNode().classList.remove("hidden");
      this.splitButton.buttonNode().classList.remove("hidden");

      return;
    }

    this.linkData.buttonNode().classList.remove("hidden");

    this.refreshData.buttonNode().classList.add("hidden");
    this.splitButton.buttonNode().classList.add("hidden");
  }

  private appendHandlerToLinkDataButton() {
    const subscriber = new DefaultButtonSubscriber();

    subscriber.update = async (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();

        await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
        mountReactExcelDataLinking(signInService.getSharepointToken());
      }
    };

    this.linkData.buttonNode().style.alignSelf = "center";

    this.linkData.subscribe(subscriber);
  }

  private appendHandlerToEditLinkDataButton() {
    const subscriber = new DefaultButtonSubscriber();

    subscriber.update = async (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();
        (message.click as PointerEvent).stopPropagation();
        this.hideDropdownMenu();

        await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
        mountReactExcelDataLinking(signInService.getSharepointToken());
      }
    };

    this.editLinkData.subscribe(subscriber);
  }

  private appendHandlerToUnlinkDataButton() {
    const subscriber = new DefaultButtonSubscriber();

    subscriber.update = (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();
        (message.click as PointerEvent).stopPropagation();
        this.hideDropdownMenu();

        ExcelDataLinkingStore.set({
          loaded: {
            siteId: "",
            driveId: "",
            fileId: "",
            worksheetId: "",
            workbookId: "",
            range: "",
          },
        });

        this.updateButtonsStates();
      }
    };

    this.unlinkData.subscribe(subscriber);
  }

  private appendHandlerToRefreshDataButton() {
    const subscriber = new DefaultButtonSubscriber();

    const loadRangeAndSetData = async (accessToken: string) => {
      const { driveId, fileId, worksheetId, workbookId, range } = ExcelDataLinkingStore.get()["loaded"];

      const browser = SharepointBrowserAPI.createInstance(accessToken);

      const response = workbookId
        ? await browser.getTableRange(driveId, fileId, workbookId)
        : await browser.getRange(driveId, fileId, worksheetId, range);

      const rows = transformSharePointResponse(response.values);
      dataLinkingEventBus.dispatch("range-selected", rows);
    };

    subscriber.update = async (message) => {
      if (message.click) {
        (message.click as PointerEvent).preventDefault();

        if (ExcelDataLinkingStore.get().accessToken) {
          await loadRangeAndSetData(ExcelDataLinkingStore.get().accessToken);
          return;
        }

        await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
        await loadRangeAndSetData(signInService.getSharepointToken());
      }
    };

    this.refreshData.buttonNode().style.alignSelf = "center";

    this.refreshData.subscribe(subscriber);
  }

  private appendHandlerToSplitButton() {
    const subscriber = new SplitButtonSubscriber();

    subscriber.update = async (message: SplitButtonData, action: SplitButtonActions) => {
      if (message.click.type !== "click") {
        return;
      }

      // directly open the link data modal
      if (action === SplitButtonActions.ButtonClick) {
        this.hideDropdownMenu();

        await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
        mountReactExcelDataLinking(signInService.getSharepointToken());
        return;
      }

      if (action === SplitButtonActions.IconClick) {
        this.showDropdownMenu();
      }
    };

    this.splitButton.subscribe(subscriber);
  }

  private replaceTextWithIconOnSplitButton() {
    this.splitButton.buttonNode().style.alignSelf = "center";

    const splitButtonTextNode: HTMLElement = this.splitButton.defaultButton.buttonNode();

    // hack to remove the text from the split button and replace it with an icon
    while (splitButtonTextNode.firstChild) {
      this.splitButton.defaultButton.buttonNode().removeChild(splitButtonTextNode.firstChild);
    }

    const icon = new IconButton(IconName.Link, false);

    icon.prependTo(splitButtonTextNode);
  }

  private hideDropdownMenu(event?: PointerEvent) {
    event?.stopPropagation();

    this.buttonsDropdownMenu.classList.add("hidden");

    this.clickOutsideOverlayElement.classList.add("hidden");
    this.clickOutsideOverlayElement.removeEventListener("click", this.hideDropdownMenu.bind(this));
  }

  private showDropdownMenu() {
    const { right, bottom } = this.splitButton.buttonNode().getBoundingClientRect();
    const rightOffset = Math.round(document.body.getBoundingClientRect().width - right);

    this.buttonsDropdownMenu.style.top = `${bottom}px`;
    this.buttonsDropdownMenu.style.right = `${rightOffset}px`;
    this.buttonsDropdownMenu.classList.remove("hidden");

    this.clickOutsideOverlayElement.classList.remove("hidden");
    this.clickOutsideOverlayElement.addEventListener("click", this.hideDropdownMenu.bind(this));
  }
}
