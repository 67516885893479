import React, { useEffect } from "react";
import useStore from "../../useStore";
import { SharePointFile, SharePointFolder } from "../../ExcelDataLinking.types";
import SharepointBrowserAPI from "@zebrabi/sharepoint-api-browser/SharepointBrowserAPI";
import folderIcon from "../images/folder.svg";
import xlsxFileIcon from "../images/xlsx.svg";

export default function FoldersAndFilesListing() {
  const [foldersAndFiles, setFoldersAndFiles] = useStore("foldersAndFiles");
  const [accessToken] = useStore("accessToken");
  const [activeSiteId] = useStore("activeSiteId");
  const [activeDriveId] = useStore("activeDriveId");
  const [foldersNavigation, setFoldersNavigation] = useStore("foldersNavigation");
  const [, setCurrentView] = useStore("currentView");
  const [, setActiveFileId] = useStore("activeFileId");

  const browser = SharepointBrowserAPI.createInstance(accessToken);

  const filterFoldersAndFiles = (foldersAndFiles: (SharePointFolder | SharePointFile)[]) => {
    return foldersAndFiles.filter((folderOrFile: SharePointFolder | SharePointFile) => {
      if ("folder" in folderOrFile) {
        return true;
      }

      if ("file" in folderOrFile) {
        return ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel.sheet.macroEnabled.12"].includes(
            folderOrFile.file.mimeType
          );
      }

      return false;
    });
  };

  const loadRootSiteItems = () => {
    browser
      .getItems(activeSiteId, activeDriveId)
      .then((response) => filterFoldersAndFiles(response.value))
      .then((foldersAndFiles) => {
        setFoldersAndFiles(foldersAndFiles);
      });
  };

  const loadRootDriveItems = () => {
    browser
      .getDriveChildren(activeDriveId)
      .then((response) => filterFoldersAndFiles(response.value))
      .then((foldersAndFiles) => {
        setFoldersAndFiles(foldersAndFiles);
      });
  };

  const loadSubFolder = (folder: SharePointFolder) => {
    browser
      .listDriveItemChildren(activeSiteId, folder.id)
      .then((response) => filterFoldersAndFiles(response.value))
      .then((foldersAndFiles) => {
        setFoldersAndFiles(foldersAndFiles);
      });
  };

  useEffect(() => {
    const folder = foldersNavigation.at(-1);

    if (folder) {
      loadSubFolder(folder);
    } else if (activeDriveId) {
      if (!activeSiteId) {
        loadRootDriveItems();
      } else {
        loadRootSiteItems();
      }
    }
  }, [foldersNavigation, activeDriveId]);

  const loadSpreadsheetData = (file: SharePointFile) => {
    setActiveFileId(file.id);
    setCurrentView("data-selection");
  };

  const select = (folderOrFile: SharePointFolder | SharePointFile) => {
    if ("folder" in folderOrFile) {
      setFoldersNavigation([...foldersNavigation, folderOrFile]);
    } else if ("file" in folderOrFile) {
      loadSpreadsheetData(folderOrFile);
    }
  };

  return (
    <div className="folders-and-files-listing">
      <ul>
        {foldersAndFiles.map((folderOrFile) => (
          <li key={folderOrFile.id}>
            <button
              onClick={() => {
                select(folderOrFile);
              }}
            >
              <img src={"folder" in folderOrFile ? folderIcon : xlsxFileIcon} alt={folderOrFile.name} />
              {folderOrFile.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
