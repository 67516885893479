import { useState, useEffect } from "react";
import { ChartChooserStore } from "./store";

export default function useStore<T>(key: string): [T, (value: T) => void] {
  const [state, setState] = useState<T>(ChartChooserStore.get()[key]);

  useEffect(() => ChartChooserStore.subscribe((value) => setState(value[key])), []);

  const setStoreState = (value: T) => ChartChooserStore.set({ [key]: value });

  return [state, setStoreState];
}
