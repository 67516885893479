import { ChartData, DataPoint, ScenarioOptions } from "./../interfaces";
import { ChartType, LabelDensity } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { LabelProperties } from "./../library/interfaces";
import { END, CHART_AREA, RECT, G, BAR, TEXT, PX, FONT_SIZE, FONT_FAMILY, MIDDLE, TEXT_ANCHOR, HIGHLIGHTABLE, WIDTH, HEIGHT, X, Y, FILL, FONT_WEIGHT, WHITE, STROKE_OPACITY, FILL_OPACITY, FONT_SIZE_UNIT } from "./../library/constants";
import { CHART_CONTAINER } from "./../consts";
import { getDataPointNonNullValue } from "./../viewModel/viewModelHelperFunctions";

import * as drawing from "./../library/drawing";
import * as charting from "./chart";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as d3 from "../d3";
import { mapScenarioKeyAndName } from "../helpers";
import { plotChartLegendSettings } from "../ui/drawGlobalLegendMenuOverlay";

// eslint-disable-next-line max-lines-per-function
export default function plusMinusChart(reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, x: number, y: number, height: number, width: number,
    chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number, shouldPlotCategories: boolean, plotTitle: boolean,
    plotLegend: boolean, useSecondReferenceVariance: boolean = false) {

    let chartWidth = width;
    let chartXPos = x;
    const referenceScenario = useSecondReferenceVariance ? settings.scenarioOptions.secondReferenceScenario : settings.scenarioOptions.referenceScenario;

    if (plotLegend) {
        chartWidth -= settings.getLegendWidth();
        chartXPos += settings.getLegendWidth();
    }

    if (settings.differenceHighlight) {
        chartWidth -= settings.differenceHighlightWidth;
    }
    const shouldShowGrandTotal = settings.showGrandTotal && settings.chartType === ChartType.Variance;
    if (shouldShowGrandTotal) {
        chartWidth = chartWidth * chartData.dataPoints.length / (chartData.dataPoints.length + 1.5);
    }
    const getReferenceValue = (dp: DataPoint) => useSecondReferenceVariance ? dp.secondReference : dp.reference;

    const valuesVarianceDataPoints = chartData.dataPoints.filter(d => d.value !== null && getReferenceValue(d) !== null);
    if (valuesVarianceDataPoints.length > 0 && settings.shouldHideLastVariance(valuesVarianceDataPoints[valuesVarianceDataPoints.length - 1].category)) {
        valuesVarianceDataPoints.pop();
    }

    const yScale = charting.getYScale(min, max, height, topMargin, bottomMargin);
    const xScale = charting.getXScale(chartData, chartXPos, chartWidth, settings.getGapBetweenColumns());

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    if (plotTitle) {
        charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, chartXPos, y, charting.getChartTitleWidth(chartWidth, settings), height, max, min, topMargin, bottomMargin);
    }
    if (shouldPlotCategories) {
        charting.plotCategories(chartContainer, settings, chartData, y + height, xScale, chartWidth, y + yScale(0));
    }
    drawing.plotHorizontalAxis(chartContainer, true, chartXPos, chartWidth, y + yScale(0), settings.colorScheme.axisColor, referenceScenario);

    const setBarHeightToZero = min === 0 && max === 0;
    const chartArea = drawing.createGroupElement(chartContainer, `${CHART_AREA}-${chartIndex}`);
    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    const varianceLabelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);

    let secondSegmentDataPoints: DataPoint[] = [];
    let secondSegmentValuesOnlyDPs: DataPoint[] = [];
    if (settings.scenarioOptions.secondValueScenario !== null) {
        secondSegmentDataPoints = settings.showAllForecastData ?
            chartData.dataPoints.filter(dp => dp.secondSegmentValue !== null && getReferenceValue(dp) !== null) :
            chartData.dataPoints.filter(dp => dp.value === null && dp.secondSegmentValue !== null && getReferenceValue(dp) !== null);
        secondSegmentValuesOnlyDPs = settings.showAllForecastData ? secondSegmentDataPoints.filter(dp => dp.value === null) : secondSegmentDataPoints;

        const secondSegmentClass = `${BAR}_SECOND_SEGMENT_${chartIndex} ${HIGHLIGHTABLE}`;
        const secondSegmentBars = drawing.getShapes(chartArea, secondSegmentClass, secondSegmentClass, RECT, secondSegmentDataPoints);
        secondSegmentBars
            .attr(WIDTH, xScale.bandwidth())
            .attr(HEIGHT, d => setBarHeightToZero ? 0 : yScale(max - Math.abs(d.secondSegmentValue - getReferenceValue(d))) - topMargin)
            .attr(Y, d => y + yScale(Math.max(0, d.secondSegmentValue - getReferenceValue(d))))
            .attr(X, d => settings.showAllForecastData && secondSegmentValuesOnlyDPs.indexOf(d) === -1 ?
                xScale(d.category) + xScale.bandwidth() * settings.getGapBetweenColumns() / 1.6 :
                xScale(d.category))
            .attr(FILL, d => styles.getVarianceColor(chartData.isInverted, d.secondSegmentValue - getReferenceValue(d), settings.colorScheme));
        const colorGetter = (d: DataPoint) => {
            return styles.getVarianceColor(chartData.isInverted, d.secondSegmentValue - getReferenceValue(d), settings.colorScheme);
        };
        styles.applyToBars(secondSegmentBars, colorGetter, settings.scenarioOptions.secondValueScenario, settings.chartStyle, true, settings.colorScheme);
        if (secondSegmentValuesOnlyDPs.length > 0) {
            drawing.plotAxisScenarioDelimiter(chartContainer, xScale(secondSegmentValuesOnlyDPs[0].category) - xScale.bandwidth() / 8, y + yScale(0) - 25, y + height);
        }
        if (settings.lightenOverlapped) {
            const lightenedBars = secondSegmentBars.filter(dp => dp.value !== null);
            lightenedBars.attr(STROKE_OPACITY, 0.5);
            lightenedBars.attr(FILL_OPACITY, 0.5);
        }
        // Visual.tooltipServiceWrapper.addTooltip(<d3.Selection<any, any, any, any>>secondSegmentBars,
        //     (tooltipEvent: TooltipEventArgs<DataPoint>) => charting.getTooltipData(tooltipEvent.data, settings, labelsFormat, varianceLabelsFormat, chartData.isInverted, undefined, chartData),
        //     (tooltipEvent: TooltipEventArgs<DataPoint>) => tooltipEvent.data.selectionId);
    }

    const barsClass = `${BAR}_VARIANCES_${chartIndex} ${HIGHLIGHTABLE}`;
    const bars = drawing.getShapes(chartArea, barsClass, barsClass, RECT, valuesVarianceDataPoints);
    bars.attr(WIDTH, xScale.bandwidth())
        .attr(HEIGHT, d => setBarHeightToZero ? 0 : Math.max(0, yScale(max - Math.abs(d.value - getReferenceValue(d))) - topMargin))
        .attr(X, d => xScale(d.category))
        .attr(Y, d => y + yScale(Math.max(0, d.value - getReferenceValue(d))))
        .attr(FILL, d => styles.getVarianceColor(chartData.isInverted !== d.isCategoryInverted, d.value - getReferenceValue(d), settings.colorScheme));
    const getVarianceColorFun = (d: DataPoint) => styles.getVarianceColor(chartData.isInverted !== d.isCategoryInverted, d.value - getReferenceValue(d), settings.colorScheme);
    styles.applyToBars(bars, getVarianceColorFun, settings.scenarioOptions.valueScenario, settings.chartStyle, true, settings.colorScheme);

    if (settings.showDataLabels) {
        const labelsDataPoints = valuesVarianceDataPoints.concat(secondSegmentValuesOnlyDPs);
        const hideUnits = settings.shouldHideDataLabelUnits();
        const labelsProperties = getLabelProperties(labelsDataPoints, settings, varianceLabelsFormat, hideUnits, xScale, yScale, y, useSecondReferenceVariance);
        if (labelsProperties.length > 0) {
            if (settings.showAllForecastData) {
                charting.plotLabelBackgrounds(chartArea, chartIndex, labelsProperties, settings, WHITE, true, false);
            }
            charting.plotLabels(chartArea, `${chartIndex}`, labelsProperties, settings);
        }
    }

    if (shouldShowGrandTotal) {
        plotPlusMinusGrandTotalChart(chartArea, chartData.dataPoints, secondSegmentValuesOnlyDPs, chartIndex, xScale, yScale, max, topMargin, y, x, width,
            settings.scenarioOptions, settings, varianceLabelsFormat, useSecondReferenceVariance, chartData.isInverted);
    }

    if (settings.showCommentMarkers() && !useSecondReferenceVariance) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, y, settings);
        }
    }

    // Visual.tooltipServiceWrapper.addTooltip(<d3.Selection<any, any, any, any>>bars,
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => charting.getTooltipData(tooltipEvent.data, settings, labelsFormat, varianceLabelsFormat, chartData.isInverted, undefined, chartData),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => tooltipEvent.data.selectionId);
    if (plotLegend) {
        const legendObject = useSecondReferenceVariance
            ? mapScenarioKeyAndName("secondAbsoluteDifferenceHeader", settings.secondAbsoluteDifferenceHeader, settings.scenarioOptions)
            : mapScenarioKeyAndName("absoluteDifferenceHeader", settings.absoluteDifferenceHeader, settings.scenarioOptions);

        charting.plotChartLegend(chartContainer, legendObject, x, y + height / 2, settings, false, referenceScenario, END);
        plotChartLegendSettings(chartContainer, x, y, height);
    }
}

function plotPlusMinusGrandTotalChart(container: d3.Selection<SVGElement, any, any, any>, dataPoints: DataPoint[], secondSegmentDataPoints: DataPoint[], chartIndex: number,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, max: number, topMargin: number, y: number, x: number, width: number,
    scenarioOptions: ScenarioOptions, settings: ChartSettings, labelsFormat: string, useSecondReferenceVariance: boolean, isInverted: boolean) {
    const locale = settings.locale;
    const totalDataPoint = charting.getGrandTotalDataPoint(dataPoints, settings.showAllForecastData ? dataPoints.filter(dp => dp.secondSegmentValue) : secondSegmentDataPoints, settings);
    let totalVariance = 0;
    let totalSecondValueVariance = 0;
    const valuesDataPoints = dataPoints.filter(dp => dp.value !== null);
    let avgValue = 0, avgSecondValue = 0;
    if (valuesDataPoints.length > 0) {
        totalVariance = d3.sum(valuesDataPoints.map(p => !settings.handleNullsAsZeros && (useSecondReferenceVariance ? p.secondReference : p.reference) === null ? 0 :
            p.value - (useSecondReferenceVariance ? p.secondReference : p.reference)));
        avgValue = totalVariance / dataPoints.length;
    }

    if (secondSegmentDataPoints.length > 0 && !settings.showAllForecastData) {
        totalSecondValueVariance = d3.sum(secondSegmentDataPoints.map(p => p.secondSegmentValue - (useSecondReferenceVariance ? p.secondReference : p.reference)));
        avgSecondValue = totalSecondValueVariance / dataPoints.length;
    }
    else if (settings.showAllForecastData && scenarioOptions.secondValueScenario !== null) {
        const totalForecastVariance = totalDataPoint.secondSegmentValue - (useSecondReferenceVariance ? totalDataPoint.secondReference : totalDataPoint.reference); //d3.sum(secondSegmentDataPoints.map(p => p.secondSegmentValue - (useSecondReferenceVariance ? p.secondReference : p.reference)));
        const avgForecastValue = totalForecastVariance / dataPoints.length;
        let avgFCVarianceBar = container
            .selectAll(`.${BAR}_TOTAL_FC` + chartIndex)
            .data([totalDataPoint]);
        const rect = avgFCVarianceBar.enter()
            .append(RECT)
            .classed(BAR, true);
        avgFCVarianceBar = avgFCVarianceBar.merge(rect)
            .attr(WIDTH, xScale.bandwidth())
            .attr(HEIGHT, totalForecastVariance === 0 ? 0 : Math.max(0, yScale(max - Math.abs(avgForecastValue)) - topMargin))
            .attr(Y, y + yScale(Math.max(0, avgForecastValue)))
            .attr(X, x + width - xScale.bandwidth() - 2)
            .attr(FILL, styles.getVarianceColor(isInverted, totalForecastVariance, settings.colorScheme));

        const colorGetter = (d: DataPoint) => {
            return styles.getVarianceColor(isInverted, totalForecastVariance, settings.colorScheme);
        };
        styles.applyToBars(avgFCVarianceBar, colorGetter, scenarioOptions.secondValueScenario, settings.chartStyle, false, settings.colorScheme);
        avgFCVarianceBar.exit().remove();
        const fcLabel = container.append(TEXT)
            .text(d => charting.getVarianceDataLabel(totalForecastVariance, settings.decimalPlaces, settings.displayUnits, locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelsFormat, settings.shouldHideDataLabelUnits()))
            .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
            .style(FONT_FAMILY, charting.getFontFamily(settings.labelFontFamily))
            .style(FONT_WEIGHT, charting.getFontWeight(settings.labelFontFamily))
            .style(TEXT_ANCHOR, MIDDLE)
            .attr(X, x + width - 0.5 * xScale.bandwidth() - 2)
            .attr(Y, y + yScale(avgForecastValue) + (avgForecastValue < 0 ? settings.labelFontSize + 2 : - 5))
            .attr(FILL, settings.labelFontColor);
    }

    drawing.plotHorizontalAxis(container, true, x + width - xScale.bandwidth() - 20, xScale.bandwidth() + 20, y + yScale(0), settings.colorScheme.axisColor,
        useSecondReferenceVariance ? settings.scenarioOptions.secondReferenceScenario : settings.scenarioOptions.referenceScenario);
    const setBarHeightToZero = max === 0 && avgSecondValue + avgValue === 0;
    let avgVarianceBar = container
        .selectAll(`.${BAR}_TOTAL` + chartIndex)
        .data([totalDataPoint]);
    const rect = avgVarianceBar.enter()
        .append(RECT)
        .classed(BAR, true);
    avgVarianceBar = avgVarianceBar.merge(rect)
        .attr(WIDTH, xScale.bandwidth())
        .attr(HEIGHT, setBarHeightToZero ? 0 : Math.max(0, yScale(max - Math.abs(avgSecondValue + avgValue)) - topMargin))
        .attr(Y, y + yScale(Math.max(0, avgSecondValue + avgValue)))
        .attr(X, x + width - xScale.bandwidth() - 10)
        .attr(FILL, styles.getVarianceColor(isInverted, totalSecondValueVariance + totalVariance, settings.colorScheme));

    const colorGetter = (d: DataPoint) => {
        return styles.getVarianceColor(isInverted, totalSecondValueVariance + totalVariance, settings.colorScheme);
    };
    const scenario = secondSegmentDataPoints.length > 0 && scenarioOptions.secondValueScenario !== null && !settings.showAllForecastData ? scenarioOptions.secondValueScenario : scenarioOptions.valueScenario;
    styles.applyToBars(avgVarianceBar, colorGetter, scenario, settings.chartStyle, false, settings.colorScheme);
    avgVarianceBar.exit().remove();

    const label = container.append(TEXT)
        .text(d => charting.getVarianceDataLabel(totalVariance + totalSecondValueVariance, settings.decimalPlaces, settings.displayUnits, locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelsFormat, settings.shouldHideDataLabelUnits()))
        .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, charting.getFontFamily(settings.labelFontFamily))
        .style(FONT_WEIGHT, charting.getFontWeight(settings.labelFontFamily))
        .style(TEXT_ANCHOR, MIDDLE)
        .attr(X, x + width - 0.5 * xScale.bandwidth() - 10.5)
        .attr(Y, y + yScale(avgValue + avgSecondValue) + (avgValue + avgSecondValue < 0 ? settings.labelFontSize + 2 : - 5))
        .attr(FILL, settings.labelFontColor);
}

function labelDensityFilterReferenceVariance(point: DataPoint, index: number, array: DataPoint[]): boolean {
    const getValue: (d: DataPoint) => number = d => getDataPointNonNullValue(d) - d.reference;
    return labelDensityFilterVariance(this.labelDensity, index, array, getValue);
}

function labelDensityFilterSecondReferenceVariance(point: DataPoint, index: number, array: DataPoint[]): boolean {
    const getValue: (d: DataPoint) => number = d => getDataPointNonNullValue(d) - d.secondReference;
    return labelDensityFilterVariance(this.labelDensity, index, array, getValue);
}

function labelDensityFilterVariance(labelDensity: LabelDensity, index: number, array: DataPoint[], getValue: (d: DataPoint) => number) {
    switch (labelDensity) {
        case LabelDensity.Full:
        case LabelDensity.Auto:
        case LabelDensity.High:
        case LabelDensity.Medium:
        case LabelDensity.Low:
            return true;
        case LabelDensity.None:
            return false;
        case LabelDensity.Last:
            return index === array.length - 1;
        case LabelDensity.FirstLast:
            return index === 0 || index === array.length - 1;
        case LabelDensity.MinMax: {
            const maxPoint = array.reduce((a, b) => getValue(a) > getValue(b) ? a : b);
            const minPoint = array.reduce((a, b) => getValue(a) > getValue(b) ? b : a);
            const maxIndex = array.indexOf(maxPoint);
            const minIndex = array.indexOf(minPoint);
            return index === maxIndex || index === minIndex;
        }
        case LabelDensity.FirstLastMinMax: {
            const maxP = array.reduce((a, b) => getValue(a) > getValue(b) ? a : b);
            const minP = array.reduce((a, b) => getValue(a) > getValue(b) ? b : a);
            const maxIndx = array.indexOf(maxP);
            const minIndx = array.indexOf(minP);
            return index === 0 || index === array.length - 1 || index === maxIndx || index === minIndx;
        }
    }
}

function getLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, useSecondReferenceVariance: boolean): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(useSecondReferenceVariance ?
        labelDensityFilterSecondReferenceVariance : labelDensityFilterReferenceVariance, settings);
    const labelProperties = labelsDataPoints.map(d => {
        const value = getDataPointNonNullValue(d) - (useSecondReferenceVariance ? d.secondReference : d.reference);
        const labelText = charting.getVarianceDataLabel(value, settings.decimalPlaces, settings.displayUnits, settings.locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelsFormat, hideUnits);
        const xPos = xScale(d.category) + xScale.bandwidth() / 2;
        const yPos = y + yScale(value) + (value < 0 ? settings.labelFontSize + 2 : - 5);
        return charting.getLabelProperty(labelText, xPos, yPos, null, value, d, settings.labelFontSize, settings.labelFontFamily);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, settings: ChartSettings) {
    const xScaleBandWidth = xScale.bandwidth();
    const markerAttrs = charting.getCommentMarkerAttributes(xScaleBandWidth);
    const labelsMargin = settings.showDataLabels ? settings.labelFontSize + 4 : 0;
    const getMarkerVerticalPosition = (d: DataPoint): number => {
        const value = getDataPointNonNullValue(d) - (d.reference);
        return y + yScale(value) - (value < 0 ? -1 : 1) * (labelsMargin + markerAttrs.radius + markerAttrs.margin);
    };
    const getMarkerHorizontalPosition = (d: DataPoint): number => xScale(d.category) + xScale.bandwidth() / 2;
    charting.addCommentMarkers(container, commentDataPoints, getMarkerHorizontalPosition, getMarkerVerticalPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}
