import {
    CATEGORY_AXIS_LABEL_DENSITY_ALL, CATEGORY_AXIS_LABEL_DENSITY_EVERY_NTH, CATEGORY_AXIS_LABEL_DENSITY_FIRST_LAST,
    CATEGORY_DISPLAY_OPTION_AUTO,
    CATEGORY_DISPLAY_OPTION_FIXED_WIDTH,
    CATEGORY_DISPLAY_OPTION_FULL, CATEGORY_LABEL_OPTION_ROTATE, CATEGORY_LABEL_OPTION_TRIM,
    CATEGORY_WIDTH_OPTION_AUTO, CATEGORY_WIDTH_OPTION_FIXED,
    CategoryDisplayOptions,
    CategoryLabelsOptions,
    CategoryWidthOptions,
    CONTEXT_MENU2_DIV,
    CUSTOMER_STYLE_VISIBLE, FONT_SIZE_UNIT,
    fontFamilies, GLOBAL_CATEGORY, RESET,
} from "../library/constants";
import {
    DropdownSubscriber,
    InlineDropdown,
    InlineToggleButton,
    ToggleButtonData,
    ToggleButtonSubscriber,
    InlineNumberInput, TextInputSubscriber,
    InlineTextInput,
    FontForm,
    NumberInputSubscriber, FontFormSubscriber, FontFormData, FontSettingsActions
} from "@zebrabi/design-library";
import { ChartSettings } from "../settings/chartSettings";
import { Visual } from "../visual";
import { v4 as UUIDv4 } from "uuid";
import { AxisLabelDensity, ChartType } from "../enums";
import { GlobalCategorySettings } from "../enums";
import { ScenarioOptions } from "../interfaces";
import { repositionElementInsideViewPort } from "../helpers";
import ContextMenu from "./base/ContextMenu";

export default class GlobalCategoryMenu extends ContextMenu {
    private everyNthInputAdded = false;
    private minWidthInputAdded = false;
    protected template: string = `<div class="context-menu2 global-category">
                                <div class="menu-title">Categories</div>
                                <ul class="menu-items"></ul>
                           </div>`;

    constructor() {
        super();
    }

    protected addMenuItems() {
        if (this.chartSettings.shouldPlotVerticalCharts()) {
            this.addVerticalChartMenuItems();
        } else {
            this.addHorizontalChartMenuItems();
        }
    }

    /**
     Contains all the categories settings for charts with regular (vertical axis):
     Gap between columns percent, text width, top/bottom N
     */
    private addVerticalChartMenuItems() {
        // Gap between columns percent
        if (this.chartSettings.chartType !== ChartType.Line && this.chartSettings.chartType !== ChartType.Area && this.chartSettings.chartType !== ChartType.Pin) {
            this.addGapBetweenColumnsPercent();
        }

        // Text width
        const textWidthDropdown = new InlineDropdown<CategoryDisplayOptions>("Text width", this.chartSettings.verticalCategoriesDisplay, {
            name: "text-width"
        }, [
            { label: CATEGORY_DISPLAY_OPTION_AUTO, value: CategoryDisplayOptions.Auto },
            { label: CATEGORY_DISPLAY_OPTION_FULL, value: CategoryDisplayOptions.Full },
            { label: CATEGORY_DISPLAY_OPTION_FIXED_WIDTH, value: CategoryDisplayOptions.FixedWidth }
        ]);

        const textWidthId = `tw-${UUIDv4()}`;
        const menuItemDiv = this.createMenuItem(textWidthId);
        textWidthDropdown.appendTo(menuItemDiv);

        this.toggleFixedTextWidthInput(this.chartSettings.verticalCategoriesDisplay, textWidthId);

        const textWidthSub = new DropdownSubscriber<CategoryDisplayOptions>();
        textWidthSub.update = (message: CategoryDisplayOptions, action?: string): void => {
            this.chartSettings.persistGlobalCategorySettings({ verticalCategoriesDisplay: message });
            this.toggleFixedTextWidthInput(message, textWidthId);
        };

        textWidthDropdown.subscribe(textWidthSub);

        // Top/Bottom N
        if (this.chartSettings.chartType === ChartType.Waterfall) {
            this.addTopBottomNToggle();
        }

        // Reset to default
        this.addResetToDefault();
    }

    private toggleFixedTextWidthInput(option: CategoryDisplayOptions, menuItemId: string) {
        if (option === CategoryDisplayOptions.FixedWidth) {
            this.addMinWidthInput(menuItemId);
        } else if (this.minWidthInputAdded) {
            this.removeNextSibling(menuItemId);
            this.minWidthInputAdded = false;
        }

        this.repositionGlobalCategoryMenu();
    }

    private addGapBetweenColumnsPercent() {
        const gapBetweenColumnsInput = new InlineNumberInput("Gap between columns (%)", this.chartSettings.gapBetweenColumnsPercent, {
            name: "gap-between-columns-percent",
            min: 0,
            max: 90
        });

        const menuItemDiv = this.createMenuItem();
        gapBetweenColumnsInput.appendTo(menuItemDiv);

        const gapBetweenColumnsSub = new NumberInputSubscriber();
        gapBetweenColumnsSub.update = (message: number, action?: string): void => {
            this.chartSettings.persistGlobalCategorySettings({ gapBetweenColumnsPercent: message });
        };

        gapBetweenColumnsInput.subscribe(gapBetweenColumnsSub);
    }

    private toggleCategoriesFontSettings(show: boolean, id: string) {
        if (show) {
            const menuItemDiv = this.insertNewMenuItem(id);
            const fontSettings = new FontForm({
                fontSize: this.chartSettings.categoriesFontSize,
                fontFamily: this.chartSettings.categoriesFontFamily,
                fontColor: this.chartSettings.categoriesFontColor
            }, {
                fontSizeUnit: FONT_SIZE_UNIT,
                fontSizeMin: 8,
                fontFamilyOptions: fontFamilies
            });

            fontSettings.appendTo(menuItemDiv);

            const fontSettingsSub = new FontFormSubscriber();
            fontSettingsSub.update = (message: FontFormData, action: FontSettingsActions) => {
                if (action === FontSettingsActions.FontFamilyChange) {
                    this.chartSettings.persistGlobalCategorySettings({ categoriesFontFamily: message.fontFamily });
                } else if (action === FontSettingsActions.FontSizeChange) {
                    this.chartSettings.persistGlobalCategorySettings({ categoriesFontSize: message.fontSize });
                } else if (action === FontSettingsActions.FontColorSave) {
                    this.chartSettings.persistGlobalCategorySettings({ categoriesFontColor: message.fontColor.hex });
                } else if (action === FontSettingsActions.FontColorClear) {
                    this.chartSettings.persistGlobalCategorySettings({ categoriesFontColor: this.chartSettings.categoriesFontColor });
                }
            };
            fontSettings.subscribe(fontSettingsSub);
        } else {
            document.querySelector(`.${GLOBAL_CATEGORY}`).querySelector(".font-settings")?.remove();
        }

        this.repositionGlobalCategoryMenu();
    }

    /**
     Contains all the categories settings for charts with regular (horizontal axis):
     Custom axis font, category width, long labels, axis label density, gap between columns percent, top/bottom N
     */
    private addHorizontalChartMenuItems() {
        // Custom axis font
        const customFontId = `cf-${UUIDv4()}`;
        let menuItemDiv = this.createMenuItem(customFontId);
        const customAxisFontToggle = new InlineToggleButton(this.chartSettings.showCategoriesFontSettings, {
            name: "custom-axis-font",
            label: "Custom axis font"
        });

        this.toggleCategoriesFontSettings(this.chartSettings.showCategoriesFontSettings, customFontId);

        const customAxisFontSub = new ToggleButtonSubscriber();
        customAxisFontSub.update = (message: ToggleButtonData, action?: string): void => {
            this.toggleCategoriesFontSettings(message.value, customFontId);
            this.chartSettings.persistGlobalCategorySettings({ showCategoriesFontSettings: message.value });
        };

        customAxisFontToggle.subscribe(customAxisFontSub);
        customAxisFontToggle.appendTo(menuItemDiv);

        // Category width
        if (Visual.viewModel.isMultiples) {
            const categoryWidthDropdown = new InlineDropdown<CategoryWidthOptions>(
                "Category Width",
                this.chartSettings.categoryWidth,
                { name: "category-width" },
                [
                    { label: CATEGORY_WIDTH_OPTION_AUTO, value: CategoryWidthOptions.Auto },
                    { label: CATEGORY_WIDTH_OPTION_FIXED, value: CategoryWidthOptions.Fixed },
                ]);

            const categoryWidthId = `cw-${UUIDv4()}`;
            menuItemDiv = this.createMenuItem(categoryWidthId);
            categoryWidthDropdown.appendTo(menuItemDiv);

            this.toggleCategoryWidthInput(this.chartSettings.categoryWidth, categoryWidthId);

            const categoryWidthSub = new DropdownSubscriber<CategoryWidthOptions>();
            categoryWidthSub.update = (message: CategoryWidthOptions, action?: string): void => {
                this.toggleCategoryWidthInput(message, categoryWidthId);
                this.chartSettings.persistGlobalCategorySettings({ categoryWidth: message });
            };

            categoryWidthDropdown.subscribe(categoryWidthSub);
        }


        // Long labels
        const longLabelsDropdown = new InlineDropdown<CategoryLabelsOptions>(
            "Long Labels",
            this.chartSettings.categoryLabelsOptions,
            { name: "long-labels" },
            [
                { label: CATEGORY_LABEL_OPTION_TRIM, value: CategoryLabelsOptions.Trim },
                { label: CATEGORY_LABEL_OPTION_ROTATE, value: CategoryLabelsOptions.Rotate },
            ]);

        menuItemDiv = this.createMenuItem();
        longLabelsDropdown.appendTo(menuItemDiv);

        const longLabelSub = new DropdownSubscriber<CategoryLabelsOptions>();
        longLabelSub.update = (message: CategoryLabelsOptions, action?: string): void => {
            this.chartSettings.persistGlobalCategorySettings({ categoryLabelsOptions: message });
        };
        longLabelsDropdown.subscribe(longLabelSub);

        // Axis label density
        const axisLabelDensityDropdown = new InlineDropdown<AxisLabelDensity>(
            "Axis label density",
            this.chartSettings.axisLabelDensity,
            { name: "axis-label-density" },
            [
                { label: CATEGORY_AXIS_LABEL_DENSITY_ALL, value: AxisLabelDensity.All },
                { label: CATEGORY_AXIS_LABEL_DENSITY_FIRST_LAST, value: AxisLabelDensity.FirstLast },
                { label: CATEGORY_AXIS_LABEL_DENSITY_EVERY_NTH, value: AxisLabelDensity.EveryNth }
            ]
        );

        const axisLabelDensityId = `ald-${UUIDv4()}`;
        menuItemDiv = this.createMenuItem(axisLabelDensityId);
        axisLabelDensityDropdown.appendTo(menuItemDiv);

        this.toggleEveryNthInput(this.chartSettings.axisLabelDensity, axisLabelDensityId);

        const axisLabelDensitySub = new DropdownSubscriber<AxisLabelDensity>();
        axisLabelDensitySub.update = (message: AxisLabelDensity, action?: string): void => {
            this.chartSettings.persistGlobalCategorySettings({ axisLabelDensity: message });
            this.toggleEveryNthInput(message, axisLabelDensityId);
        };
        axisLabelDensityDropdown.subscribe(axisLabelDensitySub);

        // Gap between columns
        if (this.chartSettings.chartType !== ChartType.Line && this.chartSettings.chartType !== ChartType.Area && this.chartSettings.chartType !== ChartType.Pin) {
            this.addGapBetweenColumnsPercent();
        }

        // Top/Bottom N
        if (this.chartSettings.chartType === ChartType.Waterfall) {
            this.addTopBottomNToggle();
        }

        // Reset to default
        this.addResetToDefault();
    }

    protected resetToDefault() {
        // add reset class for consumer to know when to redraw whole menu when visual refresh happens after persist
        document.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_CATEGORY}`).classList.add(RESET);

        const val = {};
        // IMPORTANT: when null is persisted on string type, property is not deleted from object but is instead empty string
        const settings = new ChartSettings(CUSTOMER_STYLE_VISIBLE.toString() === "true", "en", <ScenarioOptions>{});
        Object.keys(GlobalCategorySettings).filter((v) => isNaN(Number(v))).forEach(key => {
            val[key] = settings[key];
        });

        this.chartSettings.persistGlobalCategorySettings(val);
    }

    private addTopBottomNToggle() {
        // define number of options that will be added by addTopBottomN()
        const topBottomOptionsCount = 2;
        const topBottomNInlineToggle = new InlineToggleButton(this.chartSettings.showTopNCategories, {
            name: "top-bottom-n",
            label: "Top/Bottom N"
        });

        const topBottomNid = `tbn-${UUIDv4()}`;
        const menuItemDiv = this.createMenuItem(topBottomNid);
        topBottomNInlineToggle.appendTo(menuItemDiv);

        if (this.chartSettings.showTopNCategories) {
            this.addTopBottomN(topBottomNid);
        }

        const topBottomNSub = new ToggleButtonSubscriber();
        topBottomNSub.update = (message: ToggleButtonData, action?: string): void => {
            if (message.value) {
                this.addTopBottomN(topBottomNid);
            } else {
                for (let i = 0; i < topBottomOptionsCount; i++) {
                    this.removeNextSibling(topBottomNid);
                }
            }

            this.chartSettings.persistGlobalCategorySettings({ showTopNCategories: message.value });
            this.repositionGlobalCategoryMenu();
        };

        topBottomNInlineToggle.subscribe(topBottomNSub);
    }

    private addTopBottomN(id: string) {
        // Others label
        const topNOtherLabelInput = new InlineTextInput("Others label", this.chartSettings.topNOtherLabel, {
            name: "top-n-other-label",
        });

        let menuItemDiv = this.insertNewMenuItem(id);
        topNOtherLabelInput.appendTo(menuItemDiv);

        const topNOtherLabelNsub = new TextInputSubscriber();
        topNOtherLabelNsub.update = (message: string, action?: string): void => {
            this.chartSettings.persistGlobalCategorySettings({ topNOtherLabel: message });
        };

        topNOtherLabelInput.subscribe(topNOtherLabelNsub);

        // Items
        const itemsInput = new InlineNumberInput("Items", this.chartSettings.topNCategoriesToKeep, {
            name: "top-n-categories-to-keep",
            min: 1,
            max: 99
        });

        menuItemDiv = this.insertNewMenuItem(id);
        itemsInput.appendTo(menuItemDiv);

        const topBottomNsub = new NumberInputSubscriber();
        topBottomNsub.update = (message: number, action?: string): void => {
            this.chartSettings.persistGlobalCategorySettings({ topNCategoriesToKeep: message });
        };

        itemsInput.subscribe(topBottomNsub);
    }

    private toggleCategoryWidthInput(option: CategoryWidthOptions, menuItemId: string) {
        if (option === CategoryWidthOptions.Fixed) {
            this.addMinWidthInput(menuItemId);
        } else {
            this.removeNextSibling(menuItemId);
        }

        this.repositionGlobalCategoryMenu();
    }

    private repositionGlobalCategoryMenu() {
        const globalCategoryMenu = <HTMLElement>document.querySelector(`.${CONTEXT_MENU2_DIV}.${GLOBAL_CATEGORY}`);
        repositionElementInsideViewPort(globalCategoryMenu);
    }

    private toggleEveryNthInput(option: AxisLabelDensity, menuItemId: string) {
        if (option === AxisLabelDensity.EveryNth) {
            this.addEveryNthInput(menuItemId);
        } else if (this.everyNthInputAdded) {
            this.removeNextSibling(menuItemId);
            this.everyNthInputAdded = false;
        }

        this.repositionGlobalCategoryMenu();
    }

    private addMinWidthInput(id: string) {
        this.minWidthInputAdded = true;
        const categoryMinWidthInput = new InlineNumberInput("Min width", this.chartSettings.categoryMinWidth, {
            name: "category-min-width",
            min: 0
        });

        const menuItemDiv = this.insertNewMenuItem(id);
        categoryMinWidthInput.appendTo(menuItemDiv);

        const categoryMinWidthSub = new NumberInputSubscriber();
        categoryMinWidthSub.update = (message: number, action?: string): void => {
            this.chartSettings.persistGlobalCategorySettings({ categoryMinWidth: message });
        };

        categoryMinWidthInput.subscribe(categoryMinWidthSub);
    }

    private addEveryNthInput(id: string) {
        this.everyNthInputAdded = true;
        const everyNthInput = new InlineNumberInput("Show every N-th label", this.chartSettings.axisLabelDensityEveryNthLabel, {
            name: "every-nth-label",
            min: 2,
            max: 100
        });

        const menuItemDiv = this.insertNewMenuItem(id);
        everyNthInput.appendTo(menuItemDiv);

        const everyNthSub = new NumberInputSubscriber();
        everyNthSub.update = (message: number, action?: string): void => {
            this.chartSettings.persistGlobalCategorySettings({ axisLabelDensityEveryNthLabel: message });
        };

        everyNthInput.subscribe(everyNthSub);
    }
}
