import { Categories } from "../category.type";
import { ChartType } from "../../../../../../../packages/visuals/charts/enums";

import ContributionStructureBarImage from "./images/contribution-structure-bar.svg";
import ContributionTimeColumnImage from "./images/contribution-time-column.svg";
import ContributionTimeColumnSingleImage from "./images/contribution-structure-column-single.svg";
import ContributionVarianceSmallMultiplesImage from "./images/contribution-variance-small-multiples.svg";

import {
  ContributionStructureBarCommentData,
  ContributionStructureBarSampleData,
  ContributionTimeColumnCommentData,
  ContributionTimeColumnSampleData,
  ContributionTimeColumnSingleCommentData,
  ContributionTimeColumnSingleSampleData,
} from "./contributionCategories.mock";

export const contributionCategories: Categories = [
  {
    displayName: "Single Series",
    charts: [
      {
        id: "contribution-structure-column-single",
        title: "Bridge chart",
        image: ContributionTimeColumnSingleImage,
        sampleData: ContributionTimeColumnSingleSampleData,
        commentData: ContributionTimeColumnSingleCommentData,
        tooltip:
          "Assess the contribution of all parts to the subtotal and total value, display a starting and the resulting end value with the deviations in between for discrete items. Use for Contribution of Variances.",
        settings: {
          chartType: ChartType.Waterfall,
          showVerticalCharts: false,
          invertedCategories: ["Material", "Personnel", "Freight", "Advertising", "Other OpEx", "Depreciation"],
          resultCategories: ["EBIT 2018", "EBIT 2019", "EBIT FX'"],
        },
      },
    ],
  },
  {
    displayName: "Multiple Series",
    charts: [
      {
        id: "contribution-time-column",
        title: "Horizontal waterfall chart",
        image: ContributionTimeColumnImage,
        sampleData: ContributionTimeColumnSampleData,
        commentData: ContributionTimeColumnCommentData,
        tooltip:
          "Assess the contribution of all parts to the whole value with a waterfall chart, explain partial values with negative/positive columns or rows. Use for Contribution of Variances.",
        settings: {
          chartType: ChartType.Waterfall,
          showVerticalCharts: false,
        },
      },
      {
        id: "contribution-structure-bar",
        title: "Vertical waterfall chart",
        image: ContributionStructureBarImage,
        sampleData: ContributionStructureBarSampleData,
        commentData: ContributionStructureBarCommentData,
        tooltip:
          "Vertical waterfall chart – Assess the contribution of all parts to the whole value with a waterfall chart, explain partial values with negative/positive columns or rows. Use for Contribution of Variances.",
        settings: {
          chartType: ChartType.Waterfall,
          showVerticalCharts: true,
          chartLayout: "Waterfall",
        },
      },
    ],
  },
  /*
  {
    displayName: "Small multiples",
    charts: [
      {
        id: "contribution-variance-small-multiples",
        title: "Contribution variance chart",
        image: ContributionVarianceSmallMultiplesImage,
      },
    ],
  },
  */
];
