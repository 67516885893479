import { DataViewMetadataColumn } from "packages/table-data";
import { MeasureRoles, CategoricalRoles } from "./fieldAssignment";

export function getColumnRole(column: DataViewMetadataColumn): MeasureRoles | CategoricalRoles {
    if (column.roles[MeasureRoles.Values]) {
        return MeasureRoles.Values;
    } else if (column.roles[MeasureRoles.PreviousYear]) {
        return MeasureRoles.PreviousYear;
    } else if (column.roles[MeasureRoles.Plan]) {
        return MeasureRoles.Plan;
    } else if (column.roles[MeasureRoles.Forecast]) {
        return MeasureRoles.Forecast;
    } else if (column.roles[MeasureRoles.Comments]) {
        return MeasureRoles.Comments;
    } else if (column.roles[CategoricalRoles.Category]) {
        return CategoricalRoles.Category;
    } else if (column.roles[CategoricalRoles.Group]) {
        return CategoricalRoles.Group;
    }
    return null;
}

