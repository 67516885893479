import { createFromTemplate } from "@zebrabi/global-toolbar/dist/lib/helpers/template";
import { STYLE_EDITOR_URL, START_TRIAL_URL, UNLOCK_ALL_FEATURES_URL } from "@zebrabi/licensing/constants";
import { openURL } from "@zebrabi/licensing/helpers";
import { ProductType } from "@zebrabi/licensing/licensing.types";

const dialogID = "invalid-license-dialog-id";

const dialog = (upgradeButtonText: string) => createFromTemplate(
    `<dialog id="${dialogID}" class="invalid-license-dialog">
        <div class="invalid-license-div">
            <a href="#" class="close icon close-icon-base64" id="invalid-license-dialog-close-icon"></a>
            
            <span>Editing and saving the style as the corporate theme \
            is available only with the Advanced and Enterprise licenses. \
            To learn more about this feature, \
            <a href="#" target="_blank" id="invalid-license-learn-more">visit our knowledge base</a>.</span>
        
            <div>
                <button id="upgrade-license-btn">${upgradeButtonText}</button>
            </div>
        </div>
    </dialog>`);

export function createInvalidLicenseDialog(isTrialUser: boolean) {
    const knowledgeBaseUrl = STYLE_EDITOR_URL.replace(
        "$host",
        Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint
    ).replace("$visual", process.env.ZBI_VISUAL);

    let addEventHandlers = false;
    let dialogElement = <HTMLDialogElement>document.getElementById(dialogID);
    if (!dialogElement) {
        const upgradeButtonText = isTrialUser ? "Upgrade" : "Start Free Trial";
        document.getElementsByTagName("body")[0].append(dialog(upgradeButtonText));
        dialogElement = <HTMLDialogElement>document.getElementById(dialogID);
        addEventHandlers = true;
    }

    if (addEventHandlers) {

        dialogElement.addEventListener("click", (e) => {
            if ((<HTMLElement>e.target).tagName === "DIALOG") {
                dialogElement.close();
                e.stopPropagation();
            }
        });

        const closeBtn = <HTMLButtonElement>dialogElement.querySelector("#invalid-license-dialog-close-icon");
        closeBtn.addEventListener("click", (e) => {
            dialogElement.close();
            e.stopPropagation();
        });

        const copyBtn = <HTMLButtonElement>dialogElement.querySelector("#upgrade-license-btn");
        copyBtn.addEventListener("click", (e) => {
            let buyUrl = (isTrialUser ? UNLOCK_ALL_FEATURES_URL : START_TRIAL_URL).replace(
                "$host",
                Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint
            ).replace("$visual", process.env.ZBI_VISUAL);
            openURL(buyUrl);
            e.stopPropagation();
        });

        const learnMoreURL = <HTMLButtonElement>dialogElement.querySelector("#invalid-license-learn-more");
        learnMoreURL?.addEventListener("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            openURL(knowledgeBaseUrl);
        });
    }

    dialogElement.showModal();
}
