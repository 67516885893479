import {
    FILL, FILL_OPACITY,
    CLICK, STROKE_OPACITY, WIDTH, HEIGHT, RECT, X, Y, STROKE, STROKE_WIDTH,
    MOUSEENTER, MOUSELEAVE, OUTLINE_COLOR, TRANSPARENT, DIFFERENCE_HIGHLIGHT_MENU, DIFFERENCE_HIGHLIGHT, CHART_DIFFERENCE_HIGHLIGHT_OUTLINE, RX, RY, ZEBRABI_CHARTS_CONTAINER, SCROLL
} from "../library/constants";
import * as d3 from "../d3";
import { BaseType } from "d3-selection";
import DifferenceHighlightMenu from "../components/DifferenceHighlightMenu";
import {sanitizeDrawingAttribute} from "../helpers";


export function plotDifferenceHighlightSettings(container: d3.Selection<SVGElement, any, any, any>, x: number, y1: number,
    y2: number, xWithOffset: number, differenceHighlightLineWidth: number): void {
    const dhLabels = container.selectAll(`text.${DIFFERENCE_HIGHLIGHT}`).nodes();
    const dhEllipseOrCircle = container.selectAll(`ellipse.${DIFFERENCE_HIGHLIGHT}, circle.${DIFFERENCE_HIGHLIGHT}`).nodes();

    const margin = 4;
    const defaultArrowWidth = 2;
    // Get extra width of arrow (2px is default, that's why we subtract 2), so we can also take that in account when drawing outline
    const extraArrowWidth = differenceHighlightLineWidth - defaultArrowWidth;
    // Add or subtract margin values so that outline drawing starts on the right (X,Y) positions and add factor 2 of margin to height,
    // so it takes the margins in account
    const dhHeight = Math.max(y2 - y1, y1 - y2)  + 2 * margin;
    const dhYPosition = Math.min(y1, y2) - margin;
    const dhXPosition = x - margin - extraArrowWidth;
    let dhWidth = 0;
    if (dhEllipseOrCircle.length > 0) {
        dhWidth = getOverlayWidth(dhEllipseOrCircle);
    } else if (dhLabels.length > 0) {
        dhWidth = getOverlayWidth(dhLabels);
    }
    // add margin and offset which is used for drawing of labels in addDifferenceHighlight function
    dhWidth += margin + (xWithOffset-x) + extraArrowWidth;
    const differenceHighlightMenu = d3.select(`.${DIFFERENCE_HIGHLIGHT_MENU}`)?.node();
    const chartContainerBB = (<HTMLElement>d3.select(`.${ZEBRABI_CHARTS_CONTAINER}`)?.node())?.getBoundingClientRect();
    // prevent drawing of outline outside the chart container
    if (chartContainerBB && dhWidth + x > chartContainerBB.right) {
        dhWidth = chartContainerBB.right - x;
    }
    const rect = d3.select(container.node()).insert(RECT, `text.${DIFFERENCE_HIGHLIGHT}`).classed(CHART_DIFFERENCE_HIGHLIGHT_OUTLINE, true)
        .attr(FILL_OPACITY, 0)
        .attr(STROKE_OPACITY, differenceHighlightMenu ? 1 : 0)
        .attr(FILL, TRANSPARENT)
        .attr(STROKE, differenceHighlightMenu ? OUTLINE_COLOR : TRANSPARENT)
        .attr(STROKE_WIDTH, 1)
        .attr(RX, 2)
        .attr(RY, 2)
        .attr(X, sanitizeDrawingAttribute(dhXPosition))
        .attr(Y, sanitizeDrawingAttribute(dhYPosition))
        .attr(WIDTH, sanitizeDrawingAttribute(dhWidth))
        .attr(HEIGHT, sanitizeDrawingAttribute(dhHeight));

    rect?.on(MOUSEENTER, () => {
        rect
            .attr(STROKE_OPACITY, 1)
            .attr(STROKE, OUTLINE_COLOR);
    }, true)
        .on(MOUSELEAVE, () => {
            if (d3.select(`.${DIFFERENCE_HIGHLIGHT_MENU}`).empty()) {
                resetDifferenceHighlightOutline(rect);
            }
        }, true)
        .on(CLICK, (event) => {
            toggleDifferenceHighlightMenu(event, rect, chartContainerBB, dhXPosition);
        });

    if ((<HTMLElement>d3.select(`.${DIFFERENCE_HIGHLIGHT_MENU}`)?.node())) {
        reopenMenu(rect, chartContainerBB);
    }
}

function redrawGlobalLegendMenu(): HTMLElement {
    d3.select(`.${DIFFERENCE_HIGHLIGHT_MENU}`)?.remove();
    const main = (<HTMLElement>d3.select(`.${ZEBRABI_CHARTS_CONTAINER}`)?.node());
    const differenceHighlightMenu = new DifferenceHighlightMenu();
    return differenceHighlightMenu.appendTo(main);
}

/**
 If settings are persisted reopens the difference highlight menu with the latest data, positioned relative to the
 provided rectangle and chart container.
 */
function reopenMenu(rect: d3.Selection<d3.BaseType, unknown, HTMLElement, any>, chartContainerBB: DOMRect) {
    const differenceHighlightMenuBB = (<HTMLElement>d3.select(`.${DIFFERENCE_HIGHLIGHT_MENU}`)?.node())?.getBoundingClientRect();
    if (differenceHighlightMenuBB) {
        const differenceHighlightMenu = redrawGlobalLegendMenu();
        differenceHighlightMenu.style.top = `${differenceHighlightMenuBB.top}px`;
        differenceHighlightMenu.style.left = `${differenceHighlightMenuBB.left}px`;

        if (differenceHighlightMenuBB.y + differenceHighlightMenu.clientHeight > chartContainerBB.bottom) {
            const heightOverlap = differenceHighlightMenuBB.y + differenceHighlightMenu.clientHeight - chartContainerBB.bottom;
            // set the height of settings to as much space as we have till we intersect the bottom of the container.
            // Also subtract 20px, so that we have a small gap between the bottom of the container and the settings container
            differenceHighlightMenu.style.height = `${differenceHighlightMenu.clientHeight - heightOverlap - 20}px`;
            differenceHighlightMenu.style.overflowY = SCROLL;
        }
        rect.attr(STROKE_OPACITY, 1)
            .attr(STROKE, OUTLINE_COLOR);
    }
}

function toggleDifferenceHighlightMenu(event: MouseEvent, rect: d3.Selection<d3.BaseType, unknown, HTMLElement, any>, chartContainerBB: DOMRect, x:number) {
    const chartDifferenceHighlightSettings = (<HTMLElement>d3.select(`.${ZEBRABI_CHARTS_CONTAINER}`).node());
    let differenceHighlightMenu = (<HTMLElement>d3.select(`.${DIFFERENCE_HIGHLIGHT_MENU}`)?.node());
    // remove tooltips so that they don't intersect with menu
    d3.selectAll(".message-tooltip").remove();
    if (!differenceHighlightMenu) {
        differenceHighlightMenu = new DifferenceHighlightMenu().appendTo(chartDifferenceHighlightSettings);
        setDifferenceHighlightMenuPosition(differenceHighlightMenu, event, x, chartContainerBB);
        const closeMenuOnMouseLeave = () => {
            const transitionSpeed = 400;
            differenceHighlightMenu.style.transition = `opacity ${transitionSpeed} ms ease`;
            setTimeout(function () {
                differenceHighlightMenu.remove();
                resetDifferenceHighlightOutline(rect);
                document.removeEventListener(MOUSELEAVE, closeMenuOnMouseLeave);
            }, transitionSpeed);
        };
        document.addEventListener(MOUSELEAVE, closeMenuOnMouseLeave);
    } else {
        differenceHighlightMenu.remove();
    }
    closeDifferenceHighlightMenuOnOutsideClick();
}

/**
 * Sets the position of the difference highlight menu based on the provided event, coordinates, and chart container dimensions.
 * If the menu would overflow the chart container vertically, it is repositioned inside the container.
 */
function setDifferenceHighlightMenuPosition (differenceHighlightMenu: HTMLElement, event: MouseEvent, x: number, chartContainerBB: DOMRect) {
    if (event.pageY + differenceHighlightMenu.clientHeight > chartContainerBB.bottom) {
        const diff = event.pageY + differenceHighlightMenu.clientHeight - chartContainerBB.bottom;
        differenceHighlightMenu.style.top = `${event.pageY - diff}px`;
        differenceHighlightMenu.style.left = `${x - differenceHighlightMenu.clientWidth}px`;
    } else {
        differenceHighlightMenu.style.top = `${event.pageY}px`;
        differenceHighlightMenu.style.left = `${x - differenceHighlightMenu.clientWidth}px`;
    }
}

function closeDifferenceHighlightMenuOnOutsideClick() {
    const closeMenuOnOutsideClick = (event: MouseEvent) => {
        const differenceHighlightMenu = document.querySelector(`.${DIFFERENCE_HIGHLIGHT_MENU}`);
        const rect = d3.select(`.${CHART_DIFFERENCE_HIGHLIGHT_OUTLINE}`);
        // If click is not inside rect outline or difference highlight menu close it.
        if (differenceHighlightMenu && !event.composedPath().includes((<HTMLElement>rect?.node())) && !event.composedPath().includes(differenceHighlightMenu)) {
            differenceHighlightMenu?.remove();
            document.removeEventListener(CLICK, closeMenuOnOutsideClick);
            resetDifferenceHighlightOutline(rect);
        }
    };
    document.addEventListener(CLICK, closeMenuOnOutsideClick, true);
}

function getOverlayWidth(dhLabels: BaseType[]): number {
    const widths = dhLabels.map((label) => (<HTMLElement>label).getBoundingClientRect().width);
    return Math.max(...widths);
}

function resetDifferenceHighlightOutline(rect: d3.Selection<d3.BaseType, unknown, HTMLElement, any>): void {
    rect.attr(FILL_OPACITY, 0)
        .attr(STROKE_OPACITY, 0)
        .attr(FILL, TRANSPARENT)
        .attr(STROKE, TRANSPARENT);
}
