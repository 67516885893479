import * as d3 from "../d3";
import Layout from "./Layout";
import { Sort } from "./../enums";
import { AUTO_LAYOUT, LARGEST_FIRST_LAYOUT } from "./../consts";
import { ViewModel } from "./../interfaces";
import { SliderHandler } from "./../ui/sliderHandler";
import { ChartSettings } from "./../settings/chartSettings";
import LayoutAttributes from "./../multiples/LayoutAttributes";
import { SingleChartLayout } from "./singleChartLayout";
import { VerticalChartsLayout } from "./verticalChartsLayout";
import { ComboChartsLayout } from "./comboChartsLayout";
import { LargestFirstLayout } from "./largestFirstLayout";
import { TwoDimensionalLayout } from "./twoDimensionalLayout";
import { SmartRowsLayout } from "./smartRowsLayout";
import { AutoLayout2 } from "./autoLayout2";
import { StackedChartLayout } from "./stackedChartLayout";

export default class LayoutFactory {

    public getLayout(settings: ChartSettings, viewModel: ViewModel, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        slideHandler: SliderHandler, width: number, numberOfCharts: number, layoutAttributtes: LayoutAttributes): Layout {
        if (numberOfCharts === 1) {
            return new SingleChartLayout(settings, viewModel, reportArea, svg, slideHandler, width, layoutAttributtes);
        }
        else if (settings.shouldPlotStackedChart(viewModel.isMultiples)) {
            return new StackedChartLayout(settings, viewModel, reportArea, svg, slideHandler, width, layoutAttributtes);
        }
        else if (settings.shouldPlotVerticalCharts()) {
            return new VerticalChartsLayout(settings, viewModel, reportArea, svg, slideHandler, width, layoutAttributtes);
        }
        else if (settings.scenarioOptions.secondActualValueIndex !== null) {
            return new ComboChartsLayout(settings, viewModel, reportArea, svg, slideHandler, width, layoutAttributtes);
        }
        else if (viewModel.isMultiples) {
            if (viewModel.is2dMultiples) {
                return new TwoDimensionalLayout(settings, viewModel, reportArea, svg, slideHandler, width, layoutAttributtes);
            }
            else if (settings.multiplesLayoutType === LARGEST_FIRST_LAYOUT || settings.multiplesLayoutType === AUTO_LAYOUT && settings.multiplesSort === Sort.None) {
                return new LargestFirstLayout(settings, viewModel, reportArea, svg, slideHandler, width, layoutAttributtes);
            }
            else if (settings.multiplesLayoutType === AUTO_LAYOUT && settings.multiplesSort === Sort.Descending) {
                return new AutoLayout2(settings, viewModel, reportArea, svg, slideHandler, width, layoutAttributtes);
            }
            else {
                return new SmartRowsLayout(settings, viewModel, reportArea, svg, slideHandler, width, layoutAttributtes);
            }
        }
    }
}
