export enum LicenseType {
  Free = "free",
  Trial = "trial",
  Paid = "paid",
  Site = "site",
  Suspended = "suspended",
  Cancelled = "cancelled",
  SuspendedTrial = "suspended_trial",
  CancelledTrial = "cancelled_trial",
  SuspendedPaid = "suspended_paid",
  CancelledPaid = "cancelled_paid",
  SuspendedSite = "suspended_site",
  CancelledSite = "cancelled_site",
}

export enum ProductType {
  Office = "office",
  Excel = "excel",
  PowerPoint = "powerpoint",
}

export interface UserInfoResponse {
  data: {
    oid: string;
    tid: string;
    name: string;
    email: string;
    hasLicense: boolean;
    license: LicenseType;
  };
}
