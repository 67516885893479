import Layout from "./Layout";
import { ChartLayout } from "./../interfaces";

interface ChartInfo {
    // given
    min: number; max: number; range: number;    // initial range of the chart
    // set in preprocessing
    forwardMin: number; forwardMax: number;     // this is the worst case, as if all charts would be in one line
    // used in calculation
    parent: number;                             // parent chart index (the chart on the left)
    branch: boolean;                            // are there many children
    lastChild: boolean;                         // the last child in the parent's slot
    minmaxMark: boolean;                        // used when refining x-axis alignment (when min is negative)
    // set for layout
    x: number; y: number;                       // position of the chart
    width: number; height: number;              // size of the chart
    colIdx: number; rowIdx: number;             // column and row position of the chart
    layoutMin: number; layoutMax: number;       // actual range of the chart
}

export class AutoLayout2 extends Layout {

    // Entry of the layout - called from outside
    public getChartLayouts(rowCount: number, maxChartsPerRow: number, canvasHeight: number, visualTitleHeight: number, minChartHeight: number, minChartWidth: number,
        plotChartWidth: number, bottomMargin: number, topMargin: number, rowMargin: number, columnMargin: number, labelFontSize: number, xPosition: number, leftMargin: number): ChartLayout[] {
        // *** init layout-algorithm parameters
        const startX = leftMargin;
        const startY = visualTitleHeight;
        const horizSpace = columnMargin;
        const vertSpace = rowMargin;
        const legendWidth = xPosition;
        const goalWidth = plotChartWidth;
        const goalHeigth = minChartHeight;
        let canvasWidth = this.width - startX;
        if (rowCount * minChartHeight + (rowCount - 1) * rowMargin > canvasHeight) canvasWidth -= 15;   // is scrollbar show - estimation
        canvasHeight += visualTitleHeight;
        const maxColCount = maxChartsPerRow; //Math.max(1, Math.floor((canvasWidth + horizSpace) / (plotChartWidth + horizSpace)));

        // *** initial layout data is copied from chartData
        const charts: ChartInfo[] = this.viewModel.chartData.map(
            c => <ChartInfo>{ min: c.min, max: c.max, range: c.max - c.min }
        );

        // *** do the layout
        this.layout(charts, startX, startY, topMargin, bottomMargin, horizSpace, vertSpace, legendWidth,
            goalWidth, goalHeigth, canvasWidth, canvasHeight, maxColCount);

        // *** finally, translate back from ChartInfo[] to ChartLayout[]
        const chartLayouts = [];
        for (let i = 0; i < charts.length; i++) {
            chartLayouts.push({
                position: { x: charts[i].x, y: charts[i].y, },
                width: charts[i].width, height: charts[i].height,
                columnIndex: charts[i].colIdx, rowIndex: charts[i].rowIdx,
                min: charts[i].layoutMin, max: charts[i].layoutMax
            });
        }
        return chartLayouts;
    }

    private layout(charts: ChartInfo[],             // pre-initialized charts (min, max, range)
        startX: number, startY: number,             // starting (x,y) position of charts
        topMargin: number, bottomMargin: number,    // vertical margin (top+bottom) inside each chart
        horizSpace: number, vertSpace: number,      // spacing between charts
        legendWidth: number,                        // legend width (applies only to the first column)
        goalWidth: number, goalHeight: number,      // target width and height of charts
        canvasWidth: number, canvasHeight: number,  // available width and height
        maxColCount: number)                        // maximum number of columns
    {
        const vertMargin = topMargin + bottomMargin;
        // preprocess charts
        this.beginLayout(charts);
        // run the layout algorithm
        for (let i = 0; i < 4; i++) {
            this.doLayout(charts, startX, startY, vertMargin, horizSpace, vertSpace, legendWidth,
                goalWidth, goalHeight, maxColCount);
            let relayout = false;
            // horizontal rescaling: if we didn't use all the columns
            if (this.totalWidth < canvasWidth) {
                const availableWidth = canvasWidth - (this.colCount * horizSpace + startX);
                goalWidth = Math.floor(availableWidth / (this.colCount + 1));
                relayout = true;
            }
            // vertical rescaling: if we didn't use full height of viewport
            const shouldFitHeight = this.totalHeight - canvasHeight < 100;
            if (shouldFitHeight) {
                // calculate resize ratio
                const fixed = startY + (this.rowCount + 1) * (vertSpace + vertMargin) - vertSpace;
                const ratioChart = (canvasHeight - fixed) / (this.totalHeight - fixed);
                // if others are big and we have only one row
                const oneRowOthers = this.rowCount == 0 && this.othersDiff > 0;
                const height0 = charts[0].height - (oneRowOthers ? this.othersDiff : 0);
                // new height for the first chart
                goalHeight = (height0 - vertMargin) * ratioChart;
                relayout = true;
            }
            maxColCount = this.colCount + 1;    // bugfix: second layout should not go beyond colCount from the first layout
            if (!relayout) break;
        }
        // *** refine the layout
        // align axes: do the layoutMin adjustment (if there are any negative mins)
        if (charts[0].forwardMin < 0) {
            this.checkMinMax(charts, bottomMargin);
        }
    }

    private beginLayout(charts: ChartInfo[]) {
        // *** calculate forward minimums
        const last = charts.length - 1;
        let fmin = charts[last].min;
        let fmax = charts[last].max;
        for (let idx = last; idx >= 0; idx--) {
            if (charts[idx].min < fmin) fmin = charts[idx].min;
            if (charts[idx].max > fmax) fmax = charts[idx].max;
            charts[idx].forwardMin = fmin;
            charts[idx].forwardMax = fmax;
        }
    }

    // *** layout "global" variables
    totalHeight = 0;        // total height used by all charts
    totalWidth = 0;         // total width used by all charts
    colCount = 0;           // number of columns (valid at the end of loop)
    rowCount = 0;           // number of rows (valid at the end of loop)
    othersDiff = 0;         // how much more space "Others" charts needs in relation to its row

    // the layout algorithm
    // eslint-disable-next-line max-lines-per-function
    private doLayout(charts: ChartInfo[], startX: number, startY: number,
        margin: number, horizSpace: number, vertSpace: number, legendWidth: number,
        goalWidth: number, goalHeight: number, maxColCount: number) {
        // *** init layout variables
        this.totalHeight = 0;
        this.totalWidth = 0;
        this.colCount = 0;
        this.rowCount = 0;
        this.othersDiff = 0;
        // begin
        const chartCount = charts.length;             // number of charts
        let rowHeight = goalHeight + margin;        // full height of a row including margins
        this.totalHeight = startY + rowHeight;      // (current) total height of all charts
        // begin layout
        let parentSlotIdx = 0;				// slot: pointer to the reference chart for determining free space
        let x = startX, y = startY;			// current slot properties: x, y coordinates
        let slotHeight = rowHeight;			// current slot properties: current free vertical space
        let col = 0;        				// current column
        let fitCount = -1;					// the number of fitted charts in the (previous) slot
        let range_0 = charts[0].forwardMax - charts[0].forwardMin;
        if (range_0 == 0) range_0 = 1;
        for (let i = 0; i < chartCount; i++) {
            charts[i].branch = false;
            charts[i].lastChild = true;
            const ratio = (charts[i].forwardMax - charts[i].forwardMin) / range_0;  // ratio of this group to the first one
            const height = goalHeight * ratio + margin;
            // Can we fit the chart in the current slot?
            const canFit = height <= slotHeight;
            if (canFit) { // THEN: the chart can fit in the current slot, so just continue
                fitCount++;
                charts[i].parent = parentSlotIdx - 1;
                if (charts[i].parent >= 0) charts[charts[i].parent].branch = true;
                if (i > 0) charts[i - 1].lastChild = false;
            } else {  // ELSE: proceed to the next slot
                // distribute charts in the current slot
                this.distributeSpace_AllToFirst(charts, i - 1 - fitCount, i - 1, slotHeight + vertSpace, margin);
                // check column: Did we just filled the last slot in the column?
                const breakCol = y + height > this.totalHeight;
                if (breakCol) col++;
                // check rows: Should we break the row?
                let breakRow = (col % maxColCount === 0);
                // !!! two special cases !!!
                // We are just at the top of a new column && all yet unprocessed charts can fit in the next row
                breakRow = breakRow || (col == this.colCount + 1) && (chartCount - i <= col) && (i !== chartCount - 1);
                // Do we have a too large chart (such as Others), here parentSlot.height < rowHeight is true when row was already splited
                const parentSlot = charts[parentSlotIdx];
                breakRow = breakRow || (parentSlot.height < rowHeight && height > parentSlot.height);
                //  now do the slot
                if (!breakRow) { // THEN: go to the next slot
                    x = parentSlot.x + parentSlot.width + horizSpace;
                    y = parentSlot.y;
                    slotHeight = parentSlot.height;
                    charts[i].parent = parentSlotIdx;
                    fitCount = 0;
                    parentSlotIdx++;
                } else { // ELSE: go to the next row (and new column, too)
                    x = startX;
                    y = this.totalHeight + vertSpace;
                    rowHeight = height;
                    this.totalHeight += vertSpace + rowHeight;
                    slotHeight = rowHeight;
                    fitCount = 1;
                    parentSlotIdx = i;
                    charts[i].parent = -1;
                    this.rowCount++;
                    col = 0;
                }
                // determine column count
                if (col > this.colCount)this.colCount = col;
            }
            // position the current chart at x, y, ...
            charts[i].x = col === 0 ? startX : x;
            charts[i].y = y;
            charts[i].width = goalWidth + (col === 0 ? legendWidth : 0);
            charts[i].height = height;
            charts[i].colIdx = col;
            charts[i].rowIdx = this.rowCount;
            const range = (charts[i].forwardMax - charts[i].forwardMin);
            charts[i].layoutMin = charts[i].forwardMin;
            charts[i].layoutMax = charts[i].layoutMin + range;
            charts[i].minmaxMark = false;
            // calculate the next (vertical) position
            y += height + vertSpace;
            slotHeight -= vertSpace + height;
        }
        // the space of the last slot is not yet distributed
        this.distributeSpace_AllToFirst(charts, chartCount - 1 - fitCount, chartCount - 1, slotHeight + vertSpace, margin);
        // check the last row if it contains OTHERS
        this.othersDiff = this.fixBigOthers(this.totalHeight, charts, margin, vertSpace);
        // update total height & width
        this.totalHeight += this.othersDiff;
        this.totalWidth = charts[chartCount - 1].x + charts[chartCount - 1].width;
    }

    // Rescale the charts horizontally
    private rescaleHorizontally(charts: ChartInfo[], startX: number, width: number, horizSpace: number, legendWidth: number) {
        for (const ci of charts) {
            ci.width = width + (ci.colIdx === 0 ? legendWidth : 0);
            ci.x = startX + (ci.colIdx > 0 ? legendWidth : 0) + ci.colIdx * (width + horizSpace);
        }
    }

    // Set the new height to the chart, also properly set the "min&max" property
    private resizeChart(ci: ChartInfo, new_height: number, margin: number) {
        const ratio = (new_height - margin) / (ci.height - margin);
        ci.height = new_height;
        // we comment this: min&max are now handled separately after main layout
        ci.layoutMin *= ratio;
        ci.layoutMax *= ratio;
    }

    // Vertically distribute the charts in the last slot
    // the slot contains fitCount+1 charts
    // AllToFirst: the first chart takes all the space, all others are only moved down
    private distributeSpace_AllToFirst(charts: ChartInfo[], first: number, last: number, space: number, margin: number) {
        if (space <= 0) return;
        // resize the first chart
        const new_height = charts[first].height + space;
        this.resizeChart(charts[first], new_height, margin);
        // and move the rest
        for (let i = first + 1; i <= last; i++) charts[i].y += space;
    }

    // Check the last row if "Others/TopN" option was used
    // and if the last chart is bigger than all other in the last row
    private fixBigOthers(totalHeight: number, charts: ChartInfo[], margin: number, vertSpace: number): number {
        const lastChart = charts[charts.length - 1];
        const diff = lastChart.y + lastChart.height - totalHeight;
        if (diff <= 0) return 0;
        const lastRowIdx = lastChart.rowIdx;
        for (let i = charts.length - 2; i >= 0 && charts[i].rowIdx == lastRowIdx; i--) {
            const cl = charts[i];
            const new_height = cl.height + diff;
            this.resizeChart(cl, new_height, margin);
        }
        return diff;
    }

    private checkMinMax(charts: ChartInfo[], margin: number) {
        // adjust min in straight rows, from last to first
        for (let idx = charts.length - 1; idx >= 0; idx--)
            if (!charts[idx].minmaxMark) {
                let branchmin = this.findBranchMin(charts, idx);
                const range = charts[idx].layoutMax - charts[idx].layoutMin;
                const mar = range / (charts[idx].height - margin) * margin;
                branchmin -= (branchmin < 0 ? mar : 0);
                this.moveBranchMin(charts, idx, branchmin);
            }
    }

    private findBranchMin(charts: ChartInfo[], idx: number) {
        let min = charts[idx].min;
        idx = charts[idx].parent;
        while (idx >= 0) {
            min = Math.min(min, charts[idx].min);
            if (!charts[idx].lastChild) break;
            idx = charts[idx].parent;
        }
        return min;
    }

    private moveBranchMin(charts: ChartInfo[], idx: number, min: number) {
        while (idx >= 0) {
            const range = charts[idx].layoutMax - charts[idx].layoutMin;
            charts[idx].layoutMin = min;
            charts[idx].layoutMax = charts[idx].layoutMin + range;
            charts[idx].minmaxMark = true;
            if (!charts[idx].lastChild) break;
            idx = charts[idx].parent;
        }
    }
}