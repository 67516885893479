import { v4 as UUIDv4 } from "uuid";

import { ChartData, ChartLayout, DataPoint, Point, ScenarioOptions, ViewModel, Viewport } from "./../interfaces";
import { LabelProperties } from "./../library/interfaces";

import { LabelAlignment } from "../library/types";
import { labelsOverlapping } from "./../library/labelsOverlapping";
import {
    AxisLabelDensity,
    ChartType,
    ComboChartUnderlyingChart,
    DifferenceHighlightArrowStyle,
    DifferenceHighlightEllipse,
    DifferenceHighlightFromTo,
    GridlineStyle,
    LabelDensity,
    MarkerSize,
    ReferenceDisplayType,
    ShowTopNChartsOptions,
} from "./../enums";
import { invertResultAndHighlightContextMenu } from "./../ui/contextMenu";
import { ChartSettings } from "./../settings/chartSettings";
import { Visual } from "./../visual";
import { addAxisBreakPatternDefinitions, showPopupMessage } from "./../ui/ui";
import LayoutAttributes from "./../multiples/LayoutAttributes";
import { SingleChartLayout } from "./../multiples/singleChartLayout";
import { calculateMarkerFixedSize, calculateMarkerAutoSize, mapScenarioKeyAndName } from "../helpers";

import {
    ChartStyle, CategoryLabelsOptions, Scenario, MarkerShape, DifferenceLabel,
    START, END, CENTER, MIDDLE, RIGHT, DELTA, PERCENT, BOLD, NORMAL, TEXT, PX, FONT_WEIGHT, FONT_SIZE, FONT_FAMILY, FONT_STYLE, TEXT_ANCHOR, MOUSEOVER, FILL, OPACITY, FILL_OPACITY,
    CLICK, STROKE_OPACITY, CONTEXT_MENU, DIV, WIDTH, HEIGHT, DEFS, NONE, BLOCK, CATEGORY, HIGHLIGHTABLE, TSPAN, DISPLAY, RECT, CIRCLE, PATH, X, Y, DX, DY, RX, CY,
    LINE, STROKE, STROKE_WIDTH, GRAY, LIGHTGRAY, TRANSFORM, MOUSEOUT, MARKER, ITALIC, BLACK, WHITE, MOUSEMOVE, LABEL, EMPTY, POINTER_EVENTS, BACKGROUND,
    INNER_MARKER_SIZE_CIRCLE, END_MARKER_SIZE_CIRCLE, END_MARKER_SIZE_SQUARE, INNER_MARKER_SIZE_SQUARE, TOOLTIP, X1, Y1, X2, Y2, CX, R, D, POLYLINE, POINTS,
    COLLAPSE_ARROW_ICON, TOP_N_RECTANGLE, SEGOE_UI_BOLD, SEGOE_UI, MOUSEENTER, COMMENT_MARKER, STROKE_DASHARRAY, VISIBILITY, CommentBoxPlacement,
    ZEBRABI_CHART_SVG_CONTAINER, G, OVERFLOW, COLOR, SECOND_VALUE_HEADER, GRIDLINE_COLOR, TOTAL, FONT_SIZE_UNIT,
} from "./../library/constants";

import { LINE_BREAK_SEPARATOR, WATERFALL, INTEGRATED, VALUE, START_POSITION, MOUSE_OVER_DROPLINE, AXIS_BREAK_SYMBOL, HORIZONTAL_LABEL_PADDING, SECOND_REFERENCE, REFERENCE, MIN_SUBTOTALS } from "./../consts";

import * as d3 from "../d3";

import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as viewModels from "./../viewModel/viewModelHelperFunctions";
import * as scenarios from "./../scenarios";

import { LegendElements, LegendTextSubscriber } from "@zebrabi/design-library";
import { applyExcelSLicers } from "@zebrabi/data-helpers/slicers";
import { showOverlayOnCategoryHover } from "../ui/drawCategoryOverlay";
import { addHorizontalGlobalCategoryButton, addVerticalGlobalCategoryButton } from "../ui/drawGlobalCategoryOverlay";
import { plotChartLegendSettings } from "../ui/drawGlobalLegendMenuOverlay";
import { plotDifferenceHighlightSettings } from "../ui/drawDifferenceHighlightMenuOverlay";
import { CommentMarker } from "@zebrabi/legacy-library-common/interfaces";
import { showOverlayOnDataLabelHover } from "../ui/drawDataLabelMenuOverlay";


const SELECTION_DROP_LINE = "selection-drop-line";

export function getXScale(chartData: ChartData, x: number, chartWidth: number, padding: number): d3.ScaleBand<string> {
    const useRound = chartWidth / (chartData.dataPoints.length || 1) > 10;
    return useRound ?
        d3.scaleBand()
            .domain(chartData.dataPoints.map(d => d.category))
            .rangeRound([x, x + chartWidth])
            .paddingInner(padding)
            .paddingOuter(padding / 2) :
        d3.scaleBand()
            .domain(chartData.dataPoints.map(d => d.category))
            .range([x, x + chartWidth])
            .paddingInner(padding)
            .paddingOuter(padding / 2);
}

export function getOrdinalScale(chartData: ChartData, rangeStart: number, rangeWidth: number, padding: number): d3.ScaleBand<string> {
    return d3.scaleBand()
        .domain(chartData.dataPoints.map(d => d.category))
        .rangeRound([rangeStart, rangeStart + rangeWidth])
        .paddingInner(padding)
        .paddingOuter(padding / 2);
}

export function getYScale(min: number, max: number, height: number, topMargin: number, bottomMargin: number): d3.ScaleLinear<number, number> {
    if (height - bottomMargin <= topMargin) { // top margin should never be below bottomMargin since that would mean that usable height is less than 0
        topMargin -= topMargin - (height - bottomMargin + 1);
    }
    const scaleLinear = d3.scaleLinear()
        .domain([min, max])
        .range([height - bottomMargin, topMargin]);

    return <d3.ScaleLinear<number, number>><unknown>(value => scaleLinear(value ?? 0));
}

export function getLinearScale(min: number, max: number, rangeStart: number, rangeEnd: number): d3.ScaleLinear<number, number> {
    const scaleLinear = d3.scaleLinear()
        .domain([min, max])
        .range([rangeStart, rangeEnd]);

    return <d3.ScaleLinear<number, number>><unknown>(value => scaleLinear(value ?? 0));
}

export function plotChartTitle(container: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, plotTitle: boolean, chartData: ChartData,
    x: number, y: number, width: number, height: number, max: number, min: number, topMargin: number, bottomMargin: number) {
    const titleText = chartData.group;
    if (!plotTitle || !titleText) {
        return;
    }
    const fontSize = settings.groupTitleFontSize;
    let yPos = y + 12;
    if (shouldLowerChartTitle(settings, chartData, max, min)) {
        yPos += (height - bottomMargin) / 2 - fontSize;
    }

    if (Visual.isChartFocusPopupShown)
        yPos += 10;

    let anchor = START;
    if (settings.groupTitleAlignment === CENTER) {
        const chartWidth = settings.shouldPlotVerticalCharts() || !settings.differenceHighlight ? width : width - settings.differenceHighlightWidth;
        x += chartWidth / 2;
        anchor = MIDDLE;
    }
    else if (settings.groupTitleAlignment === RIGHT) {
        x += width;
        anchor = END;
    }

    let fontFamily: string, fontWeight: string;
    if (settings.groupTitleFontFamily === SEGOE_UI_BOLD) {
        fontFamily = SEGOE_UI;
        fontWeight = BOLD;
    } else {
        fontFamily = settings.groupTitleFontFamily;
        fontWeight = NORMAL;
    }

    const renderedTitleText = drawing.getTailoredText(titleText, fontSize, settings.groupTitleFontFamily, NORMAL, NORMAL, width);
    const renderedTitleWidth = drawing.measureTextWidth(renderedTitleText, fontSize, fontFamily, NORMAL, NORMAL);
    container.append(TEXT).classed("group-chart-title", true)
        .text(renderedTitleText)
        .datum(chartData)
        .style(FONT_SIZE, fontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, fontFamily)
        .style(FONT_WEIGHT, fontWeight)
        .style(TEXT_ANCHOR, anchor)
        .attr(X, x)
        .attr(Y, yPos)
        .attr(FILL, settings.groupTitleFontColor);

    let labelRectX = x - 2;
    if (settings.groupTitleAlignment === CENTER) {
        labelRectX -= renderedTitleWidth / 2;
    }
    else if (settings.groupTitleAlignment === RIGHT) {
        labelRectX -= renderedTitleWidth;
    }
    const labelRect = getMouseOverRectangle(container, null, fontSize + 2, renderedTitleWidth + 4, labelRectX, yPos - fontSize, "group-title-rect");
    labelRect.on(CLICK, (event) => {
        labelRect.attr(FILL_OPACITY, 0);
        labelRect.attr(STROKE_OPACITY, 0);
        createChartFocusPopup(settings, chartData, topMargin + 10, bottomMargin + 10, labelRect);
        event.stopPropagation();
    });
    labelRect.datum(chartData)
        .on(CONTEXT_MENU, invertResultAndHighlightContextMenu(settings, yPos + fontSize));

    if (chartData.isOther && settings.showTopNChartsOptions === ShowTopNChartsOptions.Items && !Visual.isChartFocusPopupShown) {
        drawSingleOtherArrow(container, x, yPos, renderedTitleWidth, true, settings, fontSize, Math.max(fontSize + 4, 15));
        drawSingleOtherArrow(container, x, yPos, renderedTitleWidth, false, settings, fontSize, Math.max(fontSize + 4, 15));
        Visual.element.addEventListener(MOUSEOUT, () => {
            d3.selectAll("." + TOP_N_RECTANGLE).attr(STROKE_OPACITY, 0);
            d3.selectAll("." + COLLAPSE_ARROW_ICON).attr(OPACITY, 0);
        });
        Visual.element.addEventListener(MOUSEOVER, () => {
            d3.selectAll("." + TOP_N_RECTANGLE).attr(STROKE_OPACITY, 1);
            d3.selectAll("." + COLLAPSE_ARROW_ICON).attr(OPACITY, 1);
        });
    }
}

function drawSingleNextChartFocusArrow(container: d3.Selection<SVGElement, any, any, any>, chartWidth: number, right: boolean, popupDiv: HTMLDivElement, currentIndex: number, allTitleElements: d3.Selection<d3.BaseType, unknown, HTMLElement, any>, enabled: boolean) {
    const marginLeft = (Visual.element.clientWidth - chartWidth) / 2;
    const marginTop = Visual.element.clientHeight * 0.1;
    const arrowRectSize = 20;
    const arrowWidth = 20;
    const tooltipText = right ? "Next" : "Previous";
    const xoffset = chartWidth - 76 + (right ? arrowWidth : 0);

    const rectUp = container.append(RECT)
        .attr(FILL_OPACITY, 0)
        .attr(STROKE_OPACITY, 0)
        .attr(FILL, WHITE)
        .attr(STROKE, GRAY)
        .attr(STROKE_WIDTH, 1)
        .attr(WIDTH, arrowWidth)
        .attr(HEIGHT, arrowRectSize)
        .attr(X, xoffset)
        .attr(Y, 7.5);

    if (enabled) {
        rectUp.on(MOUSEOVER, () => {
            arrowUp.attr(STROKE, BLACK);
        }).on(MOUSEENTER, () => {
            showPopupMessage(popupDiv, marginLeft + xoffset, marginTop - 15, tooltipText);
        }).on(MOUSEOUT, () => {
            arrowUp.attr(STROKE, GRAY);
        }).on(CLICK, () => {
            const newIndex = right ? currentIndex + 1 : currentIndex - 1;
            if (<Element>(allTitleElements.nodes()[newIndex])) {
                Visual.element.removeChild(popupDiv);
                (<Element>(allTitleElements.nodes()[newIndex])).dispatchEvent(new Event(CLICK));
            }
        });
    }
    const arrowUp = container
        .append("svg")
        .attr(X, xoffset + arrowWidth / 2 - 1.5)
        .attr(Y, 7.5)
        .append(PATH)
        .attr(D, right ? "M0.5,1.0,8.0,9.0,0.5,17.0" : "M8.0,1.0,0.5,9.0,8.0,17.0")
        .attr(STROKE, GRAY)
        .attr(STROKE_WIDTH, 1)
        .attr(FILL_OPACITY, 0)
        .attr(OPACITY, enabled ? 1 : 0.5)
        .attr(POINTER_EVENTS, NONE);
}

function drawSingleOtherArrow(container: d3.Selection<SVGElement, any, any, any>, x: number, yPos: number, renderedTitleWidth: number, right: boolean, settings: ChartSettings, fontSize: number, arrowSize: number, isCategory?: boolean) {
    const rectUp = container.append(RECT)
        .attr(FILL_OPACITY, 0.01)
        .attr(STROKE_OPACITY, 0)
        .attr(FILL, WHITE)
        .attr(STROKE, GRAY)
        .attr(STROKE_WIDTH, 1)
        .attr(WIDTH, arrowSize)
        .attr(HEIGHT, arrowSize)
        .attr(X, x + renderedTitleWidth + 8 + (right ? arrowSize : 0))
        .attr(Y, yPos - fontSize - 1)
        .attr(RX, 2)
        .attr(TRANSFORM, () => {
            if (isCategory && settings.showVerticalCharts) {
                if (right) {
                    return `translate(-10, 10)`;
                }
            }
        })
        .classed(TOP_N_RECTANGLE, true)
        .on(MOUSEOVER, () => {
            if ((!isCategory && settings.topNChartsToKeep === 1 && !right) || (isCategory && settings.topNCategoriesToKeep === 1 && !right)) {
                return;
            }
            arrowUp.attr(STROKE, BLACK);
            rectUp.attr(STROKE, BLACK).attr(STROKE_OPACITY, 1);
        })
        .on(MOUSEENTER, () => {
            if ((!isCategory && settings.topNChartsToKeep === 1 && !right) || (isCategory && settings.topNCategoriesToKeep === 1 && !right)) {
                return;
            }

            const slider = d3.select<HTMLElement, any>(".slide");
            const scrollTop = slider ? slider.node().scrollTop || 0 : 0;
            const tooltipText = right ? "Increase to Top " + (settings.topNChartsToKeep + 1) : "Decrease to Top " + (settings.topNChartsToKeep - 1);
            let xPos = x + renderedTitleWidth + 8 + (right ? arrowSize : 0);

            if (Visual.element.clientWidth - xPos < 150) {
                xPos = Visual.element.clientWidth - 150;
            }

            if (isCategory && settings.showTopNCategories) {
                const tooltipCategoryText = right ? "Increase to Top " + (settings.topNCategoriesToKeep + 1) : "Decrease to Top " + (settings.topNCategoriesToKeep - 1);
                showPopupMessage(Visual.element, xPos, yPos - 2 * fontSize - 20 - scrollTop, tooltipCategoryText);
            } else {
                const tooltipText = right ? "Increase to Top " + (settings.topNChartsToKeep + 1) : "Decrease to Top " + (settings.topNChartsToKeep - 1);
                showPopupMessage(Visual.element, xPos, yPos - 2 * fontSize - 20 - scrollTop, tooltipText);
            }
        })
        .on(MOUSEOUT, () => {
            arrowUp.attr(STROKE, GRAY);
            rectUp.attr(STROKE, GRAY).attr(STROKE_OPACITY, 1);
        })
        .on(CLICK, (event) => {
            // Categories calculation top N
            if (isCategory && settings.showTopNCategories) {
                settings.topNCategoriesToKeep += right ? +1 : -1;
                settings.topNCategoriesToKeep = Math.min(Math.max(1, settings.topNCategoriesToKeep), 100);
                settings.persistTopNCategorySettings();
            } else {
                settings.topNChartsToKeep += right ? +1 : -1;
                settings.topNChartsToKeep = Math.min(Math.max(1, settings.topNChartsToKeep), 100);
                settings.persistTopNSettings();
            }
            event.stopPropagation();
        });

    const arrowUp = container
        .append(POLYLINE)
        .attr(POINTS, p => {
            const y = yPos - fontSize / 2 + (arrowSize - fontSize) / 2 - 1;
            const xoffset = x + renderedTitleWidth + 8 + (right ? arrowSize : 0);
            const dy = arrowSize / 4;
            const dx = dy;
            return `${xoffset + arrowSize / 2 + (right ? -dx : dx)},${y + dy} ${xoffset + arrowSize / 2 + (right ? dx : -dx)},${y} ${xoffset + arrowSize / 2 + (right ? -dx : dx)},${y - dy}`;
        })
        .attr(STROKE, GRAY)
        .attr(TRANSFORM, () => {
            if (isCategory && settings.showVerticalCharts) {
                const xPos = parseInt(rectUp.attr("x"));
                const yPos = parseInt(rectUp.attr("y"));
                const width = parseInt(rectUp.attr("width"));
                const height = parseInt(rectUp.attr("height"));

                // get center of arrows in order to rotate around it instead of (0,0) as is default
                const xCenter = xPos + width / 2;
                const yCenter = yPos + height / 2;

                return `${right ? "translate(-10, 10)" : ""} rotate(90, ${xCenter}, ${yCenter})`;
            }
        })
        .attr(STROKE_WIDTH, 1)
        .attr(FILL_OPACITY, 0)
        .attr(OPACITY, 0)
        .attr(POINTER_EVENTS, NONE)
        .classed(COLLAPSE_ARROW_ICON, true);
}

function drawSingleOtherArrowsOnOthersCategory(category: Element, container: d3.Selection<SVGElement, any, any, any>, xOffset: number, yOffset: number) {
    // Get coordinates of passed HTML element
    const xPos = parseInt(category.getAttribute("x"));
    const yPos = parseInt(category.getAttribute("y"));

    // Draw both arrows based on coordinates and according to passed offsets
    drawSingleOtherArrow(container, xPos - xOffset, yPos - yOffset, 35, true, Visual.settings, 10, 10, true);
    drawSingleOtherArrow(container, xPos - xOffset, yPos - yOffset, 35, false, Visual.settings, 10, 10, true);

    Visual.element.addEventListener(MOUSEOUT, () => {
        d3.selectAll("." + TOP_N_RECTANGLE).attr(STROKE_OPACITY, 0);
        d3.selectAll("." + COLLAPSE_ARROW_ICON).attr(OPACITY, 0);
    });
    Visual.element.addEventListener(MOUSEOVER, () => {
        d3.selectAll("." + TOP_N_RECTANGLE).attr(STROKE_OPACITY, 1);
        d3.selectAll("." + COLLAPSE_ARROW_ICON).attr(OPACITY, 1);
    });
}

function removePopup(popup: HTMLElement, blurArea) {
    popup.style.display = NONE;
    Visual.isChartFocusPopupShown = false;
    drawing.removeBlur([], [<any>blurArea]);
    Visual.element.removeChild(popup);
}

export function createChartFocusPopupWrapper(chartData: ChartData, topMargin: number, bottomMargin: number, labelRect: d3.Selection<d3.BaseType, any, any, any>) {
    createChartFocusPopup(<ChartSettings>this, chartData, topMargin, bottomMargin, labelRect);
}

// eslint-disable-next-line max-lines-per-function
export function createChartFocusPopup(settings: ChartSettings, chartData: ChartData, topMargin: number, bottomMargin: number, labelRect: d3.Selection<d3.BaseType, any, any, any>) {
    const popupDiv = document.createElement(DIV);
    popupDiv.className = "chart-focus-popup";

    // Add blur to background on chart popup
    const areaToBlur = document.querySelector(".slide");
    drawing.applyBlur([], [<any>areaToBlur]);

    const popupInnerDiv = document.createElement(DIV);
    popupInnerDiv.className = "chart-focus-popup-inner";

    popupDiv.appendChild(popupInnerDiv);
    const svg = d3
        .select(popupInnerDiv)
        .style("background", settings?.zoomedChartBackgroundColor)
        .append("svg")
        .classed("zebrabi-chart", true);
    svg.attr(WIDTH, "100%");
    svg.attr(HEIGHT, "100%");
    const chartGroup = svg
        .append("g")
        .classed("chart-focus-group", true);
    const defs = svg.append(DEFS);
    const existsNegativeBreak = chartData.axisBreak < 0;
    addAxisBreakPatternDefinitions(defs, settings, settings.chartType === ChartType.Area, existsNegativeBreak);
    drawing.addPatternDefinitions(defs, settings.colorScheme, settings.highlightedCategoriesCustomColors);
    drawing.addBlurDefinitions(defs);
    Visual.isChartFocusPopupShown = true;

    svg.on(MOUSEOVER, (e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
    });
    svg.on(MOUSEMOVE, (e) => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
    });

    const titleHeight = 0;
    const chartHeight = Visual.element.clientHeight * 0.8;
    const chartWidth = Visual.element.clientWidth * 0.6;
    const vp: Viewport = {
        width: chartWidth,
        height: chartHeight,
    };
    const layoutAttrs = new LayoutAttributes(settings, Visual.viewModel, vp, titleHeight);
    const layout = new SingleChartLayout(settings, Visual.viewModel, chartGroup, svg, null, Visual.element.clientWidth * 0.6, layoutAttrs);
    const allTitleRectElements = d3.selectAll("svg rect.group-title-rect");
    const currentIndex = allTitleRectElements.nodes().indexOf(labelRect.nodes()[0]);
    const allTitleElementsSize = allTitleRectElements.size();
    const plotComboChart = settings.scenarioOptions.secondActualValueIndex !== null && !settings.shouldPlotVerticalCharts() && settings.showDotChart;

    drawSingleNextChartFocusArrow(svg, chartWidth, false, popupDiv, currentIndex, allTitleRectElements, currentIndex !== 0);
    drawSingleNextChartFocusArrow(svg, chartWidth, true, popupDiv, currentIndex, allTitleRectElements, currentIndex !== allTitleElementsSize - 1);

    const separateLine = drawing.drawLine(svg, chartWidth - 30, chartWidth - 30, 4, 29, 0.75, LIGHTGRAY, null);
    const closeCross = svg.append("svg")
        .attr(X, chartWidth - 21)
        .attr(Y, 10)
        .classed("close-cross", true);
    closeCross.append(PATH).attr(D, "M0.0,0.0,12.0,12.0").attr(STROKE, GRAY);
    closeCross.append(PATH).attr(D, "M0.0,12.0,12.0,0.0").attr(STROKE, GRAY);

    const closeCrossRect = svg.append(RECT)
        .attr(X, chartWidth - 21)
        .attr(Y, 5)
        .attr(WIDTH, 16)
        .attr(HEIGHT, 16)
        .attr(OPACITY, 0)
        .on(CLICK, () => {
            removePopup(popupDiv, areaToBlur);
        })
        .on(MOUSEOVER, () => {
            closeCross.selectAll(PATH).attr(STROKE, BLACK);
        })
        .on(MOUSEOUT, () => {
            closeCross.selectAll(PATH).attr(STROKE, GRAY);
        });

    if ((settings.chartType === ChartType.Variance) && (settings.chartLayout !== INTEGRATED && settings.chartLayout !== WATERFALL)) {
        layout.plotResponsiveChart(0, titleHeight, chartHeight - 3, chartWidth, chartData, topMargin, bottomMargin, 0, settings.minChartHeight, settings.showLegend);
    }
    else {
        if (plotComboChart) {
            layout.plotComboChart(chartData, chartHeight - 3, chartWidth, bottomMargin, titleHeight,
                topMargin, 0, 0, 0, true, ComboChartUnderlyingChart.plotChart, 0, settings.showLegend);
        }
        else {
            layout.plotChart(0, titleHeight, chartHeight - 3, chartWidth, chartData, topMargin, bottomMargin, 0, settings.showLegend);
        }
    }

    popupDiv.onclick = (e) => {
        if (e.target === popupDiv) {
            removePopup(popupDiv, areaToBlur);
        }
    };
    popupDiv.tabIndex = 0;
    popupDiv.onkeydown = (e) => {
        if (e.code === "Escape") {
            removePopup(popupDiv, areaToBlur);
        }
    };
    Visual.element.appendChild(popupDiv);
    popupDiv.style.display = BLOCK;
    popupDiv.focus();

    // Remove rect element that enables click event for focus popup chart - click is disabled for zoomedChart
    const zoomedChartTitle = d3.selectAll(".chart-focus-group .chart-container rect.group-title-rect");
    zoomedChartTitle.remove();
}

function shouldLowerChartTitle(settings: ChartSettings, chartData: ChartData, max: number, min: number) {
    return !settings.shouldPlotVerticalCharts() && settings.groupTitleVerticalAlignment === "Auto" && settings.scenarioOptions.secondActualValueIndex === null && chartData.max < max / 2 && min === 0;
}

export function getChartTitleWidth(chartWidth: number, settings: ChartSettings): number {
    if (!settings.shouldPlotVerticalCharts() && settings.differenceHighlight) {
        return chartWidth + settings.differenceHighlightWidth;
    }
    else {
        return chartWidth;
    }
}

export function plotFreezedCategories(chartDatas: ChartData[], chartLayouts: ChartLayout[], categoriesHeight: number, settings: ChartSettings) {
    const fixedCategoriesDiv = document.createElement(DIV);
    fixedCategoriesDiv.classList.add("fixed-categories");
    fixedCategoriesDiv.style.bottom = "0px";
    fixedCategoriesDiv.style.width = "100%";
    fixedCategoriesDiv.style.height = categoriesHeight + PX;
    const categoriesSvg = d3.select(fixedCategoriesDiv)
        .append("svg")
        .classed("freezed-axis-labels", true);
    categoriesSvg.attr(WIDTH, "100%");
    categoriesSvg.attr(HEIGHT, "100%");
    if (settings.showCommentBox && settings.commentBoxPlacement === CommentBoxPlacement.Below) {
        document.querySelector(`.${ZEBRABI_CHART_SVG_CONTAINER}`).insertAdjacentElement("afterend", fixedCategoriesDiv);
    } else {
        document.querySelector(`.${ZEBRABI_CHART_SVG_CONTAINER}`).appendChild(fixedCategoriesDiv);
        fixedCategoriesDiv.style.position = "fixed";
    }

    const plottedXCoordinates = [];
    for (let i = 0; i < chartLayouts.length; i++) {
        if (i > 0 && (chartLayouts[i].rowIndex > 0 || plottedXCoordinates.indexOf(Math.round(chartLayouts[i].position.x)) > -1)) {
            continue;
        }
        let chartWidth = chartLayouts[i].width;
        let chartXPos = chartLayouts[i].position.x;
        if (settings.showLegend && i === 0 && settings.chartType !== ChartType.Waterfall) {
            chartWidth -= settings.getLegendWidth();
            chartXPos += settings.getLegendWidth();
        }
        if (settings.differenceHighlight) {
            chartWidth -= settings.differenceHighlightWidth;
        }
        if (settings.showGrandTotal && (settings.chartType === ChartType.Variance || settings.chartType === ChartType.Bar)) {
            chartWidth = chartWidth * chartDatas[i].dataPoints.length / (chartDatas[i].dataPoints.length + 1.5);
        }

        const xScale: d3.ScaleBand<string> = getXScale(chartDatas[i], chartXPos, chartWidth, settings.getGapBetweenColumns());
        plotCategories(categoriesSvg, settings, chartDatas[i], categoriesHeight - 5, xScale, chartWidth, 0, true);

        if (settings.showGrandTotal && (settings.chartType === ChartType.Variance || settings.chartType === ChartType.Bar)) {
            const totalCategoryWidth = xScale.bandwidth();
            const totalLabelsXPosition = chartLayouts[i].position.x + chartLayouts[i].width - 0.5 * totalCategoryWidth - 10.5;
            const totalLabelTextEl = categoriesSvg
                .append(TEXT)
                .text(drawing.getTailoredText(settings.grandTotalLabel, settings.labelFontSize,
                    getFontFamily(settings.labelFontFamily), getFontWeight(settings.labelFontFamily), NORMAL, totalCategoryWidth + 20))
                .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
                .style(TEXT_ANCHOR, MIDDLE)
                .style(FONT_FAMILY, getFontFamily(settings.labelFontFamily))
                .style(FONT_WEIGHT, getFontWeight(settings.labelFontFamily))
                .attr(X, totalLabelsXPosition)
                .attr(Y, categoriesHeight - 5)
                .attr(FILL, settings.labelFontColor);
        }
        plottedXCoordinates.push(Math.round(chartLayouts[i].position.x));
    }
}

// eslint-disable-next-line max-lines-per-function
export function plotCategories(container: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, chartData: ChartData, chartBottom: number,
    xScale: d3.ScaleBand<string>, width: number, axisY: number, plottingFreezedCategories?: boolean, chartIndex?: number) {
    if (!settings.showCategories) {
        return;
    }
    if (chartData.showCategoryLabels === false && !plottingFreezedCategories) {
        return;
    }

    const categoryLabelsGroup = `gid-${UUIDv4()}`;
    const totalCategoryCount = chartData.dataPoints.length;
    const shouldDisplayCategoryLabel = (i: number) => settings.axisLabelDensity === AxisLabelDensity.All
        || settings.axisLabelDensity === AxisLabelDensity.FirstLast && (i === 0 || i === totalCategoryCount - 1)
        || settings.axisLabelDensity === AxisLabelDensity.EveryNth && (i % settings.axisLabelDensityEveryNthLabel === 0 || (settings.chartType === ChartType.Waterfall && i === totalCategoryCount - 1));

    const categoryWidth = settings.axisLabelDensity === AxisLabelDensity.All ? width / chartData.dataPoints.length : width / 2;
    const fontSize = settings.showCategoriesFontSettings ? settings.categoriesFontSize : settings.labelFontSize;
    const fontFamily = settings.showCategoriesFontSettings ? getFontFamily(settings.categoriesFontFamily) : getFontFamily(settings.labelFontFamily);
    const fontColor = settings.showCategoriesFontSettings ? settings.categoriesFontColor : settings.labelFontColor;
    const fontWeight = settings.showCategoriesFontSettings ? getFontWeight(settings.categoriesFontFamily) : getFontWeight(settings.labelFontFamily);

    let categories = container
        .selectAll(".categories")
        .data(chartData.dataPoints);

    const isOthersCategoryContainedInDatapoints = settings.chartType === ChartType.Waterfall && settings.showTopNCategories &&
        chartData.dataPoints.some(dataPoint => dataPoint.category === settings.topNOtherLabel);

    if (chartData.categoryLevelsCount > 1) {
        const texts = categories.enter()
            .append(TEXT)
            .classed(CATEGORY, true)
            .classed(HIGHLIGHTABLE, true)
            .classed(settings.topNOtherLabel, (d) => {
                // Add a postfix to "category highlightable" class with the name of the topNCategoryOtherLabel in order to aim drawSingleOtherArrow function at specific category
                return isOthersCategoryContainedInDatapoints && d.category === settings.topNOtherLabel;
            });
        categories = categories.merge(texts);
        const textEl = categories;
        const xPosFunctionCenter: ((d: DataPoint) => number) = d => xScale(d.category) + xScale.bandwidth() / 2;
        const xPosFunctionLeft: ((d: DataPoint) => number) = d => xScale(d.category);

        for (let i = chartData.categoryLevelsCount - 1; i >= 0; i--) {
            textEl.append(TSPAN)
                .classed(CATEGORY, true)
                .text((d, j) => {
                    const currentCategory = shouldDisplayCategoryLabel(j) ? d.category.split(LINE_BREAK_SEPARATOR)[i] : EMPTY;
                    let currentCategoryWidth = categoryWidth;
                    if (j < chartData.dataPoints.length - 1) {
                        const nextCategory = chartData.dataPoints[j + 1].category.split(LINE_BREAK_SEPARATOR)[i];
                        if (currentCategory === nextCategory) {
                            currentCategoryWidth = 2 * categoryWidth;
                        }
                    }
                    return drawing.getTailoredText((d.isCategoryInverted && i === 0 ? "-" : "") + (currentCategory || ""), fontSize, fontFamily, fontWeight, NORMAL, currentCategoryWidth - 5);
                })
                .attr(DY, i === chartData.categoryLevelsCount - 1 ? 0 : -(fontSize + 3))
                .attr(X, i === 0 ? xPosFunctionCenter : xPosFunctionLeft)
                .attr(DX, i !== 0 && (settings.chartType === ChartType.Area || settings.chartType === ChartType.Line) ? xScale.bandwidth() / 8 : 0)
                .style(VISIBILITY, (d, j) => {
                    if (j > 0) {
                        const currentCategory = d.category.split(LINE_BREAK_SEPARATOR)[i];
                        const previousCategory = chartData.dataPoints[j - 1].category.split(LINE_BREAK_SEPARATOR)[i];
                        return currentCategory === previousCategory ? "hidden" : "visible";
                    }
                    else {
                        return "visible";
                    }
                })
                .style(TEXT_ANCHOR, i === 0 ? MIDDLE : START);

            if (i > 0) {
                const separatorDataPoints = chartData.dataPoints.filter((dp, index) => {
                    if (settings.chartType === ChartType.Waterfall && (index === 0 || index === chartData.dataPoints.length - 1)) {
                        return false;
                    }
                    if (i < chartData.categoryLevelsCount - 1) {
                        const parentLevelCurrentCategory = dp.category.split(LINE_BREAK_SEPARATOR)[i + 1];
                        const parentLevelPreviousCategory = index > 0 ? chartData.dataPoints[index - 1].category.split(LINE_BREAK_SEPARATOR)[i + 1] : "";
                        if (parentLevelCurrentCategory !== parentLevelPreviousCategory) {
                            return false;
                        }
                    }
                    const currentCategory = dp.category.split(LINE_BREAK_SEPARATOR)[i];
                    const previousCategory = index > 0 ? chartData.dataPoints[index - 1].category.split(LINE_BREAK_SEPARATOR)[i] : "";
                    return currentCategory !== previousCategory;
                });

                const xCorrection = settings.chartType === ChartType.Line || settings.chartType === ChartType.Area || settings.chartType === ChartType.Pin ? 0 : xScale.bandwidth() / 8;
                let separatorLines = container
                    .selectAll(".axis-separator-lines")
                    .data(separatorDataPoints);
                const line = separatorLines.enter()
                    .append(LINE)
                    .attr(X1, d => xPosFunctionLeft(d) - xCorrection)
                    .attr(Y1, axisY)
                    .attr(X2, d => xPosFunctionLeft(d) - xCorrection)
                    .attr(Y2, chartBottom - (chartData.categoryLevelsCount - (i + 1)) * fontSize)
                    .attr(STROKE_WIDTH, 0.5)
                    .attr(STROKE, GRAY);
                separatorLines = separatorLines.merge(line);
            }
        }
        categories.style(FONT_SIZE, fontSize + FONT_SIZE_UNIT)
            .style(TEXT_ANCHOR, MIDDLE)
            .style(FONT_FAMILY, fontFamily)
            .style(FONT_WEIGHT, fontWeight)
            .attr(X, d => xScale(d.category) + xScale.bandwidth() / 2)
            .attr(Y, chartBottom - 3)
            .attr(FILL, fontColor);

        if (isOthersCategoryContainedInDatapoints) {
            const othersCategory = categories.filter(`.${viewModels.escapeCharacter(settings.topNOtherLabel)}`).node();
            if (othersCategory) {
                drawSingleOtherArrowsOnOthersCategory(<Element>othersCategory, container, 50, 25);
            }
        }
    }
    else {
        const text = categories.enter()
            .append(TEXT)
            .classed(CATEGORY, true)
            .classed(HIGHLIGHTABLE, true)
            .classed(`chartIndex${chartIndex || 0} ${categoryLabelsGroup}`, true)
            .classed(settings.topNOtherLabel, (d) => {
                // Add a postfix to "category highlightable" class with the name of the topNCategoryOtherLabel in order to aim drawSingleOtherArrow function at specific category
                return isOthersCategoryContainedInDatapoints && d.category === settings.topNOtherLabel;
            });
        categories = categories.merge(text);
        let textEl = categories;
        textEl.style(FONT_SIZE, fontSize + FONT_SIZE_UNIT)
            .style(TEXT_ANCHOR, MIDDLE)
            .style(FONT_FAMILY, fontFamily)
            .style(FONT_WEIGHT, fontWeight)
            .attr(X, d => xScale(d.category) + xScale.bandwidth() / 2)
            .attr(Y, chartBottom - (plottingFreezedCategories ? 1 : 1 / 2 * fontSize))
            .attr(FILL, fontColor);

        if (isOthersCategoryContainedInDatapoints) {
            const othersCategory = categories.filter(`.${viewModels.escapeCharacter(settings.topNOtherLabel)}`).node();
            if (othersCategory) {
                drawSingleOtherArrowsOnOthersCategory(<Element>othersCategory, container, 50, settings.multilineCategories ? 30 : 12);
            }
        }

        if (settings.categoryLabelsOptions === CategoryLabelsOptions.Rotate && settings.categoryRotateAngle > 0) {
            const xPositionOffset = xScale.bandwidth() / 2 + 2;
            textEl.text((d, i) => shouldDisplayCategoryLabel(i) ? (d.isCategoryInverted ? "-" : "") + d.category || "" : "");

            if (settings.chartType === ChartType.Waterfall) {
                const maxNonVarianceCategoryLength = Math.max(...chartData.dataPoints.filter(d => !d.isVariance)
                    .map(d => drawing.measureTextWidth(d.category, fontSize, fontFamily, fontWeight, NORMAL)));
                if (maxNonVarianceCategoryLength < categoryWidth - 5) {
                    const nonVarianceWFColsEl = textEl.filter(d => !d.isVariance);
                    nonVarianceWFColsEl
                        .attr(Y, chartBottom - settings.rotatedCartegoriesHeight + fontSize + 3);
                    textEl = textEl.filter(d => d.isVariance);
                }
            }

            textEl.style(TEXT_ANCHOR, END);
            textEl
                .attr(X, d => xScale(d.category) + xPositionOffset)
                .attr(Y, chartBottom - settings.rotatedCartegoriesHeight + fontSize);

            const rotateAngle = -(settings.categoryRotateAngle < settings.categoryRotateAngleLimit ? settings.categoryRotateAngle : 90);
            textEl.attr(TRANSFORM, (d) => {
                const rotateCenterX = xScale(d.category) + xPositionOffset;
                const rotateCenterY = chartBottom - settings.rotatedCartegoriesHeight + fontSize;
                return `rotate(${rotateAngle},${rotateCenterX},${rotateCenterY})`;
            });
        }
        else if (settings.multilineCategories) {
            const textEl1 = textEl.filter(d => d.category.indexOf(" ") === -1);
            textEl1.text((d, i) => shouldDisplayCategoryLabel(i) ? drawing.getTailoredText((d.isCategoryInverted ? "-" : "") + d.category || "", fontSize, fontFamily, fontWeight, NORMAL, categoryWidth - 5) : "")
                .attr(Y, chartBottom - fontSize - 3);

            const textEl2 = textEl.filter(d => d.category.indexOf(" ") !== -1);
            textEl2.append(TSPAN)
                .text((d, i) => shouldDisplayCategoryLabel(i) ? drawing.getTailoredText((d.isCategoryInverted ? "-" : "") + d.category.substring(0, d.category.indexOf(" ")) || "", fontSize, fontFamily, fontWeight, NORMAL, categoryWidth - 5) : "")
                .attr(DY, -fontSize - 3);
            textEl2.append(TSPAN)
                .text((d, i) => shouldDisplayCategoryLabel(i) ? drawing.getTailoredText(d.category.substr(d.category.indexOf(" ") + 1) || "", fontSize, fontFamily, fontWeight, NORMAL, categoryWidth - 5) : "")
                .attr(DY, fontSize + 3)
                .attr(X, d => xScale(d.category) + xScale.bandwidth() / 2)
                .attr(DX, 0);
        }
        else {
            textEl.text((d, i) => shouldDisplayCategoryLabel(i) ? drawing.getTailoredText((d.isCategoryInverted ? "- " : "") + d.category || "", fontSize, fontFamily, fontWeight, NORMAL, categoryWidth - 5) : "");
            if (settings.axisLabelDensity === AxisLabelDensity.FirstLast) {
                const maxLabelLength = Math.max(drawing.measureTextWidth(chartData.dataPoints[0].category, fontSize, fontFamily, fontWeight, NORMAL),
                    drawing.measureTextWidth(chartData.dataPoints[chartData.dataPoints.length - 1].category, fontSize, fontFamily, fontWeight, NORMAL));
                if (2 * maxLabelLength > width || maxLabelLength > 3 * width / chartData.dataPoints.length
                    || maxLabelLength < categoryWidth && maxLabelLength > 2 * xScale.bandwidth()) {
                    textEl.attr(X, (d, i) => i === 0 ? xScale(d.category) : xScale(d.category) + xScale.bandwidth());
                    textEl.style(TEXT_ANCHOR, (d, i) => i === 0 ? START : END);
                }
            }
        }
    }

    categories.attr(FONT_WEIGHT, d => d.isHighlighted ? BOLD : NORMAL);
    if (!plottingFreezedCategories) {
        addMouseHandlers(categories, container, chartData);
    }

    showOverlayOnCategoryHover(categories, false, {
        fontSize,
        fontColor,
        fontFamily
    });
    addHorizontalGlobalCategoryButton(container, chartData, plottingFreezedCategories, xScale, chartIndex, chartBottom, categoryLabelsGroup);
}

export function plotVerticalCategories(container: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, chartData: ChartData,
    xPos: number, yScale: d3.ScaleBand<string>, categoriesWidth: number, chartIndex = 0) {
    if (!settings.showCategories) {
        return;
    }

    const fontSize = settings.showCategoriesFontSettings ? settings.categoriesFontSize : settings.labelFontSize;
    const fontFamily = settings.showCategoriesFontSettings ? getFontFamily(settings.categoriesFontFamily) : getFontFamily(settings.labelFontFamily);
    const fontColor = settings.showCategoriesFontSettings ? settings.categoriesFontColor : settings.labelFontColor;
    const fontWeight = settings.showCategoriesFontSettings ? getFontWeight(settings.categoriesFontFamily) : getFontWeight(settings.labelFontFamily);

    const isOthersCategoryContainedInDatapoints = settings.chartType === ChartType.Waterfall && settings.showTopNCategories &&
        chartData.dataPoints.some(dataPoint => dataPoint.category === settings.topNOtherLabel);

    const categories = container
        .selectAll(".categories")
        .data(chartData.dataPoints);

    const text = categories.enter()
        .append(TEXT)
        .classed(CATEGORY, true)
        .classed(HIGHLIGHTABLE, true)
        .classed(settings.topNOtherLabel, (d) => {
            return isOthersCategoryContainedInDatapoints && d.category === settings.topNOtherLabel;
        });
    const textEl = categories.merge(text);
    textEl.style(FONT_SIZE, fontSize + FONT_SIZE_UNIT)
        .style(FONT_WEIGHT, fontWeight)
        .style(TEXT_ANCHOR, START)
        .style(FONT_FAMILY, fontFamily)
        .attr(X, xPos + 3)
        .attr(Y, d => yScale(d.category) + yScale.bandwidth() / 2 + 0.35 * fontSize)
        .attr(FILL, fontColor);
    textEl.text(d => {
        return drawing.getTailoredText((d.isCategoryInverted ? "- " : "") + d.category || "", fontSize, fontFamily, NORMAL, NORMAL, categoriesWidth - 5);
    });

    if (isOthersCategoryContainedInDatapoints) {
        const othersCategory = textEl.filter(`.${viewModels.escapeCharacter(settings.topNOtherLabel)}`).node();
        if (othersCategory) {
            drawSingleOtherArrowsOnOthersCategory(<Element>othersCategory, container, 0, 0);
        }
    }

    addMouseHandlers(textEl, container, chartData);
    showOverlayOnCategoryHover(textEl, true, {
        fontSize,
        fontColor,
        fontFamily
    });
    addVerticalGlobalCategoryButton(container, chartData, yScale, xPos + 1, chartIndex);
}

export function getRelativeVarianceLabel(settings: ChartSettings, difference: number, encloseInParentheses: boolean): string {
    const useParentheses = encloseInParentheses && !settings.showNegativeValuesInParenthesis();
    return getVarianceDataLabel(difference, settings.decimalPlacesPercentage, "Relative", settings.locale,
        settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, true, useParentheses);
}

export function getRelativeVarianceLabelForTooltip(settings: ChartSettings, difference: number, encloseInParentheses: boolean): string {
    const useParentheses = encloseInParentheses && !settings.showNegativeValuesInParenthesis();
    return getVarianceDataLabel(difference, settings.decimalPlacesPercentage, "Relative", settings.locale,
        settings.showNegativeValuesInParenthesis(), true, true, useParentheses);
}

export function densityFilter(point: DataPoint, index: number, array: DataPoint[]): boolean {
    return applyDensity(this.labelDensity, index, array, VALUE);
}

export function applyDensity(labelDensity: LabelDensity, index: number, array: DataPoint[], valueProperty: keyof DataPoint): boolean {
    switch (labelDensity) {
        case LabelDensity.Full:
        case LabelDensity.Auto:
        case LabelDensity.Low:
        case LabelDensity.Medium:
        case LabelDensity.High:
            return true;
        case LabelDensity.None:
            return false;
        case LabelDensity.Last:
            return index === array.length - 1;
        case LabelDensity.FirstLast:
            return index === 0 || index === array.length - 1;
        case LabelDensity.MinMax: {
            const maxPoint = array.reduce((a, b) => a[valueProperty] > b[valueProperty] ? a : b);
            const minPoint = array.reduce((a, b) => a[valueProperty] > b[valueProperty] ? b : a);
            const maxIndex = array.indexOf(maxPoint);
            const minIndex = array.indexOf(minPoint);
            return index === maxIndex || index === minIndex;
        }
        case LabelDensity.FirstLastMinMax: {
            const maxP = array.reduce((a, b) => a[valueProperty] > b[valueProperty] ? a : b);
            const minP = array.reduce((a, b) => a[valueProperty] > b[valueProperty] ? b : a);
            const maxIndx = array.indexOf(maxP);
            const minIndx = array.indexOf(minP);
            return index === 0 || index === array.length - 1 || index === maxIndx || index === minIndx;
        }
    }
}

export function getLabelBackgroundColor(settings: ChartSettings, scenarioOptions: ScenarioOptions, chartGroup: string) {
    let backgroundsColor = scenarioOptions.valueScenario === Scenario.Forecast ? WHITE : settings.colorScheme.neutralColor;
    if (settings.colorScheme.useCustomScenarioColors) {
        if (scenarioOptions.valueScenario === Scenario.PreviousYear) {
            backgroundsColor = settings.colorScheme.applyPatterns ? styles.getLighterColor(settings.colorScheme.previousYearColor) : settings.colorScheme.previousYearColor;
        }
        else if (scenarioOptions.valueScenario === Scenario.Plan) {
            backgroundsColor = settings.colorScheme.planColor;
        }
    }

    if (chartGroup && settings.isGroupHighlighted(chartGroup)) {
        backgroundsColor = settings.getGroupHighlightColor(chartGroup);
    }
    return backgroundsColor;
}

export function isPointSelectedOffice(d: DataPoint, selectedCategories: string[], isOthersSelected?: boolean): boolean {
    if (d.category === Visual.settings.topNOtherLabel && !d.selectionId) {
        return isOthersSelected;
    }
    if (!d || !d.category) {
        return false;
    }
    return selectedCategories.some(c => d.category === c);
}


// eslint-disable-next-line max-lines-per-function
export function addMouseHandlers(elements: d3.Selection<any, DataPoint, any, any>, container: d3.Selection<SVGElement, any, any, any>, cd?: ChartData) {
    // Allow selection only if the visual is rendered in a view that supports interactivity (e.g. Report)
    // eslint-disable-next-line no-constant-condition
    if (!true) {
        return;
    }
    (<any>window).highlightTimeout = null;
    // This must be an anonymous function instead of a lambda because
    // d3 uses 'this' as the reference to the element that was clicked.
    elements.on(CLICK, function (event, d) {
        const multiSelect = event.ctrlKey;
        if ((<any>window).highlightTimeout != null) {
            window.clearTimeout((<any>window).highlightTimeout);
        }
        const el = d3.select(this);
        el.on(MOUSEOUT, null);

        if (Visual.settings.enableFiltering) {
            applyExcelSLicers(d.category, Visual.dataView.categories[0]?.source?.displayName, multiSelect)
                .then((result) => {
                    Visual.selectedCategories = result;
                    syncStateOffice();
                });
        }
        event.stopPropagation();
    });

    container.on(CLICK, (event, d) => {
        if (Visual.textSettings) {
            Visual.textSettings.style(DISPLAY, NONE);
        }
        if (Visual.titleMenuSettings) {
            Visual.titleMenuSettings.remove();
            Visual.titleMenuSettings = null;
        }

        if (Visual.isFilterApplied()) {
            applyExcelSLicers("", Visual.dataView.categories[0]?.source?.displayName, false)
                .then((result) => {
                    Visual.selectedCategories = result;
                    syncStateOffice();
                });
        }
    });

    elements.on(MOUSEOVER, function (event, d) {
        if (Visual.isFilterApplied()) {
            return;
        }
        if ((<any>window).highlightTimeout != null) {
            clearTimeout((<any>window).highlightTimeout);
        }

        const shapesEls = Visual.isChartFocusPopupShown ? container.selectAll(`${RECT}.${HIGHLIGHTABLE}, ${CIRCLE}.${HIGHLIGHTABLE}, ${PATH}.${HIGHLIGHTABLE}`) :
            d3.selectAll(`${RECT}.${HIGHLIGHTABLE}, ${CIRCLE}.${HIGHLIGHTABLE}, ${PATH}.${HIGHLIGHTABLE}`);
        shapesEls.style("cursor", Visual.settings.enableFiltering ? "pointer" : "default");

        const shapes = <any[]>shapesEls.nodes();
        for (let i = 0; i < shapes.length; i++) {
            const shape = shapes[i];
            let shapeCategory = null;
            if (shape && shape.__data__ && shape.__data__.category) {
                shapeCategory = shape.__data__.category;
            }
            // else if (shape && shape.__data__ && shape.__data__.data && shape.__data__.data.category) {
            //     shapeCategory = shape.__data__.data.category;
            // }
            let dpCategory = d.category;
            if (!dpCategory && (<any>d).data) {
                dpCategory = (<any>d).data.category;
            }

            if (shapeCategory === dpCategory) {
                d3.select(shape).attr(OPACITY, 1);
            }
            else {
                d3.select(shape).attr(OPACITY, 0.6);
            }
        }
        const textShapes = Visual.isChartFocusPopupShown ? (<d3.Selection<any, DataPoint, any, any>>container.selectAll(`${TEXT}.${HIGHLIGHTABLE}`)).nodes() :
            (<d3.Selection<any, DataPoint, any, any>>d3.selectAll(`${TEXT}.${HIGHLIGHTABLE}`)).nodes();
        for (let i = 0; i < textShapes.length; i++) {
            const textShape = textShapes[i];
            if (textShape.__data__.category === d.category) {
                d3.select(textShape)
                    .attr(FONT_WEIGHT, BOLD)
                    .attr(OPACITY, 1);
            }
            else {
                (<d3.Selection<any, DataPoint, any, any>>d3.select(textShape))
                    .attr(FONT_WEIGHT, d => d.isHighlighted ? BOLD : NORMAL)
                    .attr(OPACITY, 0.6);
            }
        }
        const el = d3.select(this);
        el.on(MOUSEOUT, (event, d) => {
            el.on(MOUSEOUT, null);
            (<any>window).highlightTimeout = setTimeout(() => {
                if (!Visual.isFilterApplied()) {
                    const shapes = (Visual.isChartFocusPopupShown ? container.selectAll(`${RECT}.${HIGHLIGHTABLE}, ${CIRCLE}.${HIGHLIGHTABLE}, ${PATH}.${HIGHLIGHTABLE}`) :
                        d3.selectAll(`${RECT}.${HIGHLIGHTABLE}, ${CIRCLE}.${HIGHLIGHTABLE}, ${PATH}.${HIGHLIGHTABLE}`))
                        .attr(OPACITY, 1);
                    const texts = (Visual.isChartFocusPopupShown ? (<d3.Selection<any, DataPoint, any, any>>container.selectAll(`${TEXT}.${HIGHLIGHTABLE}`)) :
                        (<d3.Selection<any, DataPoint, any, any>>d3.selectAll(`${TEXT}.${HIGHLIGHTABLE}`)))
                        .attr(FONT_WEIGHT, d => d.isHighlighted ? BOLD : NORMAL)
                        .attr(OPACITY, 1);
                }
            }, 400);
        });
    });
}

export function plotMarkers(chartContainer: d3.Selection<SVGElement, any, any, any>, markerDataPoints: DataPoint[],
    settings: ChartSettings, xScale: d3.ScaleBand<string>, y: number, yScale: d3.ScaleLinear<number, number>,
    scenario: Scenario, valueProperty: keyof DataPoint, isReferenceScenario: boolean, addMouseEventHandlers: boolean, visibleMarkersDataPoints: DataPoint[], cd?: ChartData) {

    let markers: d3.Selection<any, DataPoint, any, any>;
    if (settings.chartStyle === ChartStyle.DrHichert) {
        markers = plotSquareMarkers(chartContainer, markerDataPoints, xScale, y, yScale, valueProperty, visibleMarkersDataPoints, (p: DataPoint) => settings.isCategoryHighlighted(p.category));
    }
    else {
        markers = plotRoundMarkers(chartContainer, markerDataPoints, xScale, y, yScale, valueProperty, visibleMarkersDataPoints, (p: DataPoint) => settings.isCategoryHighlighted(p.category));
    }

    if (valueProperty === "value" || valueProperty === "secondSegmentValue") {
        markers.classed("dropline_marker", true);
    }
    styles.applyToLines(markers, (p: DataPoint) => settings.getValueDataPointColor(p, cd.group, settings.colorScheme.markerColor), scenario, settings.chartStyle, false, settings.colorScheme, isReferenceScenario);
    if (addMouseEventHandlers) {
        addMouseHandlers(markers, chartContainer);
    }
}

function plotRoundMarkers(chartContainer: d3.Selection<SVGElement, any, any, any>, markerDataPoints: DataPoint[], xScale: d3.ScaleBand<string>, y: number,
    yScale: d3.ScaleLinear<number, number>, valueProperty: string, visibleMarkersDataPoints: DataPoint[], isHighlighted: (p: DataPoint) => boolean): d3.Selection<any, DataPoint, any, any> {

    const markers = drawing.getShapes(chartContainer, MARKER, MARKER, CIRCLE, markerDataPoints);
    markers
        .attr(CX, d => Math.round(xScale(d.category) + xScale.bandwidth() / 2))
        .attr(CY, d => Math.round(y + yScale(d[valueProperty])))
        .attr(R, (d, i) => isLastPoint(markerDataPoints, i) || isHighlighted(d) ? END_MARKER_SIZE_CIRCLE : INNER_MARKER_SIZE_CIRCLE)
        .attr(OPACITY, d => visibleMarkersDataPoints.indexOf(d) !== -1 || isHighlighted(d) ? 1 : 0);
    return markers;
}

function plotSquareMarkers(chartContainer: d3.Selection<SVGElement, any, any, any>, markerDataPoints: DataPoint[], xScale: d3.ScaleBand<string>, y: number,
    yScale: d3.ScaleLinear<number, number>, valueProperty: string, visibleMarkersDataPoints: DataPoint[], isHighlighted: (p: DataPoint) => boolean): d3.Selection<any, DataPoint, any, any> {

    const markers = drawing.getShapes(chartContainer, MARKER, MARKER, RECT, markerDataPoints);
    markers
        .attr(X, (d, i) => Math.round(xScale(d.category) + xScale.bandwidth() / 2 - getMarkerSize(markerDataPoints, i, MarkerShape.Square, d, isHighlighted) / 2))
        .attr(Y, (d, i) => Math.round(y + yScale(d[valueProperty]) - getMarkerSize(markerDataPoints, i, MarkerShape.Square, d, isHighlighted) / 2))
        .attr(WIDTH, (d, i) => getMarkerSize(markerDataPoints, i, MarkerShape.Square, d, isHighlighted))
        .attr(HEIGHT, (d, i) => getMarkerSize(markerDataPoints, i, MarkerShape.Square, d, isHighlighted))
        .attr(OPACITY, d => visibleMarkersDataPoints.indexOf(d) !== -1 || isHighlighted(d) ? 1 : 0);
    return markers;
}

function getMarkerSize(dataPoints: DataPoint[], currentIndex: number, markerShape: MarkerShape, p: DataPoint, isHighlighted: (p: DataPoint) => boolean) {
    if (isLastPoint(dataPoints, currentIndex) || isHighlighted(p)) {
        switch (markerShape) {
            case MarkerShape.Circle:
                return END_MARKER_SIZE_CIRCLE;
            case MarkerShape.Square:
                return END_MARKER_SIZE_SQUARE;
        }
    }
    else {
        switch (markerShape) {
            case MarkerShape.Circle:
                return INNER_MARKER_SIZE_CIRCLE;
            case MarkerShape.Square:
                return INNER_MARKER_SIZE_SQUARE;
        }
    }
}

function isLastPoint(dataPoints: DataPoint[], currentIndex: number): boolean {
    return dataPoints.length - 1 === currentIndex;
}

// export function shouldHideDataLabelUnits(settings: ChartSettings): boolean {
//     return settings.displayUnits !== "Auto" && settings.displayUnits !== "None" && settings.showUnits !== DataLabelUnitOptions.DataLabels;
// }

export function getDiffHighlightDataPoints(dataPoints: DataPoint[], diffHighlightType: DifferenceHighlightFromTo, isWaterfall: boolean = false): { fromDP: DataPoint, toDP: DataPoint } {
    if (!dataPoints || dataPoints.length === 0) {
        return {
            fromDP: null,
            toDP: null,
        };
    }

    if (diffHighlightType === DifferenceHighlightFromTo.FirstToLast || diffHighlightType === DifferenceHighlightFromTo.FirstToLastFC || diffHighlightType === DifferenceHighlightFromTo.FirstToLastPL) {
        return {
            fromDP: dataPoints[0],
            toDP: dataPoints[dataPoints.length - 1],
        };
    }
    else if (diffHighlightType === DifferenceHighlightFromTo.PenultimateToLast || diffHighlightType === DifferenceHighlightFromTo.PenultimateToLastFC || diffHighlightType === DifferenceHighlightFromTo.PenultimateToLastPL) {
        return {
            fromDP: dataPoints.length > 1 ? dataPoints[dataPoints.length - 2] : dataPoints[dataPoints.length - 1],
            toDP: dataPoints[dataPoints.length - 1],
        };
    }
    else if (diffHighlightType === DifferenceHighlightFromTo.LastToCorresponding || diffHighlightType === DifferenceHighlightFromTo.LastToCorrespondingFC || diffHighlightType === DifferenceHighlightFromTo.LastToCorrespondingPL) {
        return {
            fromDP: dataPoints[dataPoints.length - 1],
            toDP: dataPoints[dataPoints.length - 1],
        };
    }
    else if (diffHighlightType === DifferenceHighlightFromTo.MinToMax) {
        const getValue: (p: DataPoint) => number = (p) => {
            if (isWaterfall) {
                return viewModels.getWaterfallChartDataPointCumulativeValue(p);
            }
            else {
                return p.value;
            }
        };
        const valueProperty: keyof DataPoint = isWaterfall ? START_POSITION : VALUE;
        const valuesDataPoints = dataPoints.filter(p => p[valueProperty] !== null);
        if (valuesDataPoints.length < 2) {
            return {
                fromDP: null,
                toDP: null,
            };
        }

        const minPoint = valuesDataPoints.reduce((a, b) => getValue(a) > getValue(b) ? b : a);
        const maxPoint = valuesDataPoints.reduce((a, b) => getValue(a) > getValue(b) ? a : b);
        return {
            fromDP: minPoint,
            toDP: maxPoint,
        };
    }
    else {
        return {
            fromDP: dataPoints[0],
            toDP: dataPoints[dataPoints.length - 1],
        };
    }
}

export function getDiffHighlightAutoDataPoints(dataPoints: DataPoint[], chartType: ChartType, isSingleSeries: boolean): { fromDataPoint: DataPoint, toDataPoint: DataPoint } {
    if (!dataPoints || dataPoints.length === 0) {
        return {
            fromDataPoint: null,
            toDataPoint: null,
        };
    }

    let fromDP: DataPoint;
    const toDP: DataPoint = dataPoints[dataPoints.length - 1];
    switch (chartType) {
        case ChartType.Waterfall:
            fromDP = dataPoints.at(0);
            break;
        case ChartType.Area:
            fromDP = isSingleSeries ? dataPoints.at(0) : dataPoints.at(-1);
            break;
        case ChartType.Bar:
        case ChartType.Pin:
            fromDP = dataPoints.length > 1 ? dataPoints.at(-2) : dataPoints.at(-1);
            break;
        default:
            fromDP = isSingleSeries && dataPoints.length > 1 ? dataPoints.at(-2) : dataPoints.at(-1);
            break;
    }

    return {
        fromDataPoint: fromDP,
        toDataPoint: toDP,
    };
}

export function getDiffHighlightSubtotalsDataPoints(resultCategories: string[], dataPoints: DataPoint[]): { fromDataPoint: DataPoint, toDataPoint: DataPoint }[] {
    const subtotals = dataPoints.filter(dataPoint => resultCategories.indexOf(dataPoint.category) >= 0);
    if (subtotals.length < MIN_SUBTOTALS) { //do not draw difference marker if there is less than 3 results column (when 2 the general diff highlight covers the case)
        return [{
            fromDataPoint: null,
            toDataPoint: null,
        }];
    }

    const subtotalPairs: { fromDataPoint: DataPoint, toDataPoint: DataPoint }[] = [];
    subtotals.slice(0, -1).forEach((dataPoint, index) => {
        subtotalPairs.push({
            fromDataPoint: subtotals[index],
            toDataPoint: subtotals[index + 1]
        });
    });

    return subtotalPairs;
}

// eslint-disable-next-line max-lines-per-function
export function addVerticalDifferenceHighlight(reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement,
    endYPos: number, x1: number, x2: number, value1: number, value2: number, y1: number, y2: number, isInverted: boolean) {
    const container = reportArea;
    const difference = value1 - value2;
    const dhClass = "diffHighlight";

    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);
    const hideUnits = settings.shouldHideDataLabelUnits();
    const absDifferencelabel = getVarianceDataLabel(difference, settings.decimalPlaces, settings.displayUnits, settings.locale,
        settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelsFormat, hideUnits);
    let relDifferenceLabel = EMPTY;
    if (settings.differenceLabel === DifferenceLabel.Relative || settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute) {
        const relativeDiff = viewModels.calculateRelativeDifferencePercent(value1, value2);
        relDifferenceLabel = getRelativeVarianceLabel(settings, relativeDiff, settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute);
    }

    if (settings.differenceHighlightEllipse !== DifferenceHighlightEllipse.Off) {
        let x = ((x1 + x2) / 2) - 6;
        let y = endYPos;

        const labelWidth1 = drawing.measureTextWidth(relDifferenceLabel, settings.differenceHighlightFontSize, getFontFamily(settings.differenceHighlightFontFamily), getFontWeight(settings.differenceHighlightFontFamily), NORMAL);
        const labelWidth2 = drawing.measureTextWidth(absDifferencelabel, settings.differenceHighlightFontSize, getFontFamily(settings.differenceHighlightFontFamily), getFontWeight(settings.differenceHighlightFontFamily), ITALIC);

        if (settings.differenceLabel === DifferenceLabel.Relative) {
            x -= labelWidth1 / 2;
        }
        else if (settings.differenceLabel === DifferenceLabel.Absolute) {
            x -= labelWidth2 / 2;
        }
        else {
            x -= Math.max(labelWidth1, labelWidth2) / 2;
        }
        y += settings.differenceHighlightFontSize + 6;
        plotDifferenceHighlightEmphasis(container, settings, x, y, absDifferencelabel, relDifferenceLabel, dhClass);
    }

    const lineFunction = d3.line<Point>()
        .x(d => d.x)
        .y(d => d.y)
        .curve(d3.curveLinear);
    const dhColor = styles.getVarianceColor(isInverted, difference, settings.colorScheme);
    const arrowWidth = 1;
    const diffHighlightYStart = endYPos;
    const differenceLabelYStart = endYPos + 5;
    const dhPoints = getVerticalDiffHighlightMarkerPoints(settings.differenceHighlightArrowStyle, diffHighlightYStart, x2, x1, arrowWidth, settings.differenceHighlightLineWidth);

    container.append(PATH).classed(dhClass, true)
        .attr(D, lineFunction(dhPoints))
        .attr(STROKE, dhColor)
        .attr(STROKE_WIDTH, settings.differenceHighlightLineWidth)
        .attr(FILL, dhColor);

    if (settings.differenceHighlightArrowStyle === DifferenceHighlightArrowStyle.Dots) {
        drawing.drawCircle(container, dhClass, x1, diffHighlightYStart, 1.5, BLACK);
        drawing.drawCircle(container, dhClass, x2, diffHighlightYStart, 1.5, BLACK);
    }
    else if (settings.differenceHighlightArrowStyle === DifferenceHighlightArrowStyle.SingleDot) {
        drawing.drawCircle(container, dhClass, x1, diffHighlightYStart, 1.5, BLACK);
    }
    else if (settings.differenceHighlightArrowStyle === DifferenceHighlightArrowStyle.RoundArrow) {
        drawing.drawCircle(container, dhClass, x1, diffHighlightYStart, 1 + settings.differenceHighlightLineWidth, dhColor);
    }

    const connectingLine1 = drawing.drawLine(container, x1, x1, y1, diffHighlightYStart, 0.5, settings.differenceHighlightConnectingLineColor, dhClass);
    const connectingLine2 = drawing.drawLine(container, x2, x2, y2, diffHighlightYStart, 0.5, settings.differenceHighlightConnectingLineColor, dhClass);
    if (settings.differenceHighlightConnectingLineStyle === GridlineStyle.Dotted) {
        connectingLine1.attr(STROKE_DASHARRAY, "2,2");
        connectingLine2.attr(STROKE_DASHARRAY, "2,2");
    }
    else if (settings.differenceHighlightConnectingLineStyle === GridlineStyle.Dashed) {
        connectingLine1.attr(STROKE_DASHARRAY, "5,5");
        connectingLine2.attr(STROKE_DASHARRAY, "5,5");
    }

    const differenceLabel = container.append(TEXT).classed(dhClass, true)
        .style(FONT_SIZE, settings.differenceHighlightFontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, getFontFamily(settings.differenceHighlightFontFamily))
        .style(FONT_WEIGHT, getFontWeight(settings.differenceHighlightFontFamily))
        .style(TEXT_ANCHOR, MIDDLE);
    let differenceLabel2: d3.Selection<SVGElement, any, any, any> = null;
    if (settings.differenceLabel === DifferenceLabel.Absolute) {
        differenceLabel.text(absDifferencelabel);
    }
    else if (settings.differenceLabel === DifferenceLabel.Relative) {
        differenceLabel.text(relDifferenceLabel);
        differenceLabel.style(FONT_STYLE, ITALIC);
    }
    else {
        differenceLabel.text(absDifferencelabel);
        differenceLabel2 = container.append(TEXT).classed(dhClass, true);
        differenceLabel2.text(relDifferenceLabel)
            .style(FONT_SIZE, settings.differenceHighlightFontSize + FONT_SIZE_UNIT)
            .style(FONT_FAMILY, getFontFamily(settings.differenceHighlightFontFamily))
            .style(FONT_WEIGHT, getFontWeight(settings.differenceHighlightFontFamily))
            .style(FONT_STYLE, ITALIC)
            .style(TEXT_ANCHOR, MIDDLE)
            .attr(X, (x1 + x2) / 2)
            .attr(Y, differenceLabelYStart + 2 * settings.differenceHighlightFontSize)
            .attr(FILL, settings.labelFontColor);
    }
    differenceLabel
        .attr(X, (x1 + x2) / 2)
        .attr(Y, differenceLabelYStart + settings.differenceHighlightFontSize)
        .attr(FILL, settings.labelFontColor);

    if (Visual.animateDiffHighlightLabel) {
        animateDifferenceHighlightLabels(settings.differenceHighlightFontSize, differenceLabel, differenceLabel2);
    }

    if (settings.getRealInteractionSettingValue(settings.allowDifferenceHighlightChange)) {
        addDifferenceHighlightInteractions(differenceLabel, differenceLabel2, container, slider, dhClass, settings);
    }
}

function animateDifferenceHighlightLabels(labelFontSize: number, differenceLabel: d3.Selection<SVGElement, any, any, any>, differenceLabel2: d3.Selection<SVGElement, any, any, any>) {
    differenceLabel.style(FONT_SIZE, `${labelFontSize}${FONT_SIZE_UNIT}`)
        .transition().duration(50)
        .style(FONT_SIZE, `${labelFontSize + 4}${FONT_SIZE_UNIT}`)
        .transition().duration(400)
        .style(FONT_SIZE, `${labelFontSize}${FONT_SIZE_UNIT}`);
    if (differenceLabel2 != null) {
        differenceLabel2.style(FONT_SIZE, `${labelFontSize}${FONT_SIZE_UNIT}`)
            .transition().duration(50).delay(100)
            .style(FONT_SIZE, `${labelFontSize + 4}${FONT_SIZE_UNIT}`)
            .transition().duration(400)
            .style(FONT_SIZE, `${labelFontSize}${FONT_SIZE_UNIT}`);
    }
}


function addDifferenceHighlightInteractions(differenceLabel: d3.Selection<SVGElement, any, any, any>, differenceLabel2: d3.Selection<SVGElement, any, any, any>, container: d3.Selection<SVGElement, any, any, any>, slider: HTMLElement, dhClass: string, settings: ChartSettings) {
    differenceLabel.on(MOUSEOVER, (event) => {
        const labelBoundingClientRect = (<SVGElement>differenceLabel.node()).getBoundingClientRect();
        const chartClientRect = Visual.GET_CHARTS_VISUAL_BOUNDING_CLIENT();
        let rectWidth = labelBoundingClientRect.width, rectHeight = labelBoundingClientRect.height;
        const popupX = labelBoundingClientRect.left;
        const popupY = labelBoundingClientRect.top;

        const rectX = labelBoundingClientRect.x - chartClientRect.left;
        const rectY = labelBoundingClientRect.y - chartClientRect.top;

        if (differenceLabel2 != null) {
            const label2BoundingClientRect = (<SVGElement>differenceLabel2.node()).getBoundingClientRect();
            rectWidth = Math.max(rectWidth, label2BoundingClientRect.width);
            rectHeight += label2BoundingClientRect.height;
        }
        const labelRect = getMouseOverRectangle(container, slider, rectHeight, rectWidth, rectX, rectY, dhClass);
        labelRect.on(MOUSEENTER, (event) => {
            showPopupMessage(Visual.element, popupX - 100, popupY - 50, "Click to change calculation");
        });
        labelRect.on(CLICK, (event) => {
            if (settings.proVersionActive()) {
                settings.differenceLabel = (settings.differenceLabel + 1) % 3;
                Visual.animateDiffHighlightLabel = true;
                settings.persistDiffHighlightChange();
            }
            else {
                showPopupMessage(Visual.element, popupX, popupY - 30, "Pro license needed");
            }
        });
        event.stopPropagation();
    });
}

function getVerticalDiffHighlightMarkerPoints(arrowStyle: DifferenceHighlightArrowStyle, y: number, dhStart: number, dhEnd: number, arrowWidth: number, lineWidth: number): Point[] {
    let delta = arrowWidth * 4 * (dhStart > dhEnd ? -1 : 1);
    let deltaAbs = Math.abs(delta);
    if (arrowStyle !== DifferenceHighlightArrowStyle.ClosedArrow && arrowStyle !== DifferenceHighlightArrowStyle.OpenArrow) {
        return [
            { "x": dhStart, "y": y },
            { "x": dhEnd, "y": y },
        ];
    }
    else if (arrowStyle === DifferenceHighlightArrowStyle.OpenArrow) {
        delta = Math.max(7, Math.min(3 * lineWidth, 14)) * (dhStart > dhEnd ? -1 : 1);
        deltaAbs = Math.abs(delta);
        dhEnd = dhEnd - (lineWidth - 1) * (dhStart > dhEnd ? -1 : 1);
        return [
            { "x": dhStart, "y": y },
            { "x": dhEnd, "y": y },
            { "x": dhEnd - delta, "y": y - deltaAbs / 2 },
            { "x": dhEnd, "y": y },
            { "x": dhEnd - delta, "y": y + deltaAbs / 2 },
            { "x": dhEnd, "y": y },
        ];
    }
    else {
        dhEnd = dhEnd - (lineWidth - 1) * (dhStart > dhEnd ? -1 : 1);
        return [
            { "x": dhStart, "y": y },
            { "x": dhEnd - delta, "y": y },
            { "x": dhEnd - delta, "y": y - deltaAbs / 2 },
            { "x": dhEnd, "y": y + arrowWidth / 2 },
            { "x": dhEnd - delta, "y": y + arrowWidth + deltaAbs / 2 },
            { "x": dhEnd - delta, "y": y + arrowWidth },
            { "x": dhStart, "y": y + arrowWidth },
        ];
    }
}

export function addDifferenceHighlight(reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement,
    endXPos: number, x1: number, x2: number, value1: number, value2: number, y1: number, y2: number, isInverted: boolean) {
    const chartType = settings.chartType;
    const container = reportArea;
    const difference = value1 - value2;
    const dhClass = "diffHighlight";

    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);
    const absDifferencelabel = getVarianceDataLabel(difference, settings.decimalPlaces, settings.displayUnits, settings.locale,
        settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelsFormat, settings.shouldHideDataLabelUnits());
    let relDifferenceLabel = EMPTY;
    if (settings.differenceLabel === DifferenceLabel.Relative || settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute) {
        const relativeDiff = viewModels.calculateRelativeDifferencePercent(value1, value2);
        relDifferenceLabel = getRelativeVarianceLabel(settings, relativeDiff, settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute);
    }

    const x = endXPos + settings.differenceHighlightMargin - settings.differenceHighlightWidth + (chartType === ChartType.Area || chartType === ChartType.Line ? 0 : 12);
    const xWithOffset = x + 6;
    const yBasis = y1 + (y2 - y1) / 2;
    const yLabel1: number = yBasis + (settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute ? 0 : 3);

    const arrowWidth = 1;
    const dhPoints = getDiffHighlightMarkerPoints(settings.differenceHighlightArrowStyle, x, y2, y1, arrowWidth, settings.differenceHighlightLineWidth);
    const lineFunction = d3.line<Point>()
        .x((d) => { return d.x; })
        .y((d) => { return d.y; })
        .curve(d3.curveLinear);

    const dhColor = styles.getVarianceColor(isInverted, difference, settings.colorScheme);

    container.append(PATH).classed(dhClass, true)
        .attr(D, lineFunction(dhPoints))
        .attr(STROKE, dhColor)
        .attr(STROKE_WIDTH, settings.differenceHighlightLineWidth)
        .attr(FILL, dhColor);

    plotDifferenceHighlightArrows(container, settings.differenceHighlightArrowStyle, settings.differenceHighlightLineWidth, dhColor, dhClass, x, y1, y2);
    plotDifferenceHighlightLines(container, settings.differenceHighlightConnectingLineStyle, settings.differenceHighlightConnectingLineColor, dhClass, x, x1, x2, y1, y2);

    if (settings.differenceHighlightEllipse !== DifferenceHighlightEllipse.Off) {
        plotDifferenceHighlightEmphasis(container, settings, x, yBasis + 3, absDifferencelabel, relDifferenceLabel, dhClass);
    }

    const differenceLabel = container.append("text").classed(dhClass, true)
        .style(FONT_SIZE, settings.differenceHighlightFontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, getFontFamily(settings.differenceHighlightFontFamily))
        .style(FONT_WEIGHT, getFontWeight(settings.differenceHighlightFontFamily));
    let differenceLabel2: d3.Selection<SVGElement, any, any, any> = null;

    if (settings.differenceLabel === DifferenceLabel.Absolute) {
        differenceLabel.text(absDifferencelabel);
    } else if (settings.differenceLabel === DifferenceLabel.Relative) {
        differenceLabel.text(relDifferenceLabel);
        differenceLabel.style(FONT_STYLE, ITALIC);
    } else {
        const yLabel2: number = yBasis + settings.differenceHighlightFontSize + 1;
        differenceLabel.text(absDifferencelabel);
        differenceLabel2 = container.append("text").classed(dhClass, true);
        differenceLabel2.text(relDifferenceLabel)
            .style(FONT_SIZE, settings.differenceHighlightFontSize + FONT_SIZE_UNIT)
            .style(FONT_FAMILY, getFontFamily(settings.differenceHighlightFontFamily))
            .style(FONT_WEIGHT, getFontWeight(settings.differenceHighlightFontFamily))
            .style(FONT_STYLE, ITALIC)
            .attr(X, xWithOffset)
            .attr(Y, yLabel2)
            .attr(FILL, settings.labelFontColor);
    }

    differenceLabel
        .attr(X, xWithOffset)
        .attr(Y, yLabel1)
        .attr(FILL, settings.labelFontColor);

    if (Visual.animateDiffHighlightLabel) {
        animateDifferenceHighlightLabels(settings.differenceHighlightFontSize, differenceLabel, differenceLabel2);
    }
    if (settings.getRealInteractionSettingValue(settings.allowDifferenceHighlightChange)) {
        addDifferenceHighlightInteractions(differenceLabel, differenceLabel2, container, slider, dhClass, settings);
    }
    plotDifferenceHighlightSettings(container, x, y1, y2, xWithOffset, settings.differenceHighlightLineWidth);
}

// eslint-disable-next-line max-lines-per-function
export function addSubtotalDifferenceHighlight(reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement,
    endXPos: number, x1: number, x2: number, value1: number, value2: number, y1: number, y2: number, xAxisPosition: number, isInverted: boolean, chartIndex: number = 0, dataPoint?: DataPoint) {
    const container = reportArea;
    const difference = value1 - value2;
    const dhClass = "diffHighlightSubTotal";

    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);
    const absDifferencelabel = getVarianceDataLabel(difference, settings.decimalPlaces, settings.displayUnits, settings.locale,
        settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelsFormat, settings.shouldHideDataLabelUnits());
    let relDifferenceLabel = EMPTY;
    if (settings.differenceLabel === DifferenceLabel.Relative || settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute) {
        const relativeDiff = viewModels.calculateRelativeDifferencePercent(value1, value2);
        relDifferenceLabel = getRelativeVarianceLabel(settings, relativeDiff, settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute);
    }

    const x = x1 + 0.15 * endXPos;
    const xWithOffset = x + 6;

    const offsetY = (): number => {
        const maxLabelHeightMultiplier = settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute ? 3 : 2; // abs/rel/abs&rel + 1

        if (difference < 0) {
            // to fit dh labels in the middle of red arrow we need more space because there is also value label
            // which should not be covered by dh labels
            if (settings.differenceHighlightFontSize * (maxLabelHeightMultiplier + 2) < Math.abs(y2 - y1)) {
                // labels fit middle of arrow
                return 0;
            }
            else {
                // when arrow is not long enough to fit labels add additional offset to avoid label covering
                return settings.differenceHighlightFontSize * (maxLabelHeightMultiplier - 1 / 2);
            }
        }
        else {
            if (settings.differenceHighlightFontSize * maxLabelHeightMultiplier < Math.abs(y2 - y1)) {
                return 0;
            }
            else {
                return -settings.differenceHighlightFontSize;
            }
        }
    };

    const additionalLabelOffset = (): number => {
        return settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute || difference < 0 && Math.abs(y2 - y1) < settings.differenceHighlightFontSize ? 0 : 3;
    };

    const yBasis = getSubtotalDifferenceHighlightLabelY(y1, y2, xAxisPosition, difference) - offsetY();
    const yLabel1: number = yBasis + additionalLabelOffset();
    const yLabel2: number = settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute ? yBasis + settings.differenceHighlightFontSize + 1 : 0;
    const arrowWidth = 1;
    const dhPoints = getDiffHighlightMarkerPoints(settings.differenceHighlightArrowStyle, x, y2, y1, arrowWidth, settings.differenceHighlightLineWidth);
    const lineFunction = d3.line<Point>()
        .x((d) => { return d.x; })
        .y((d) => { return d.y; })
        .curve(d3.curveLinear);

    const dhColor = styles.getVarianceColor(isInverted, difference, settings.colorScheme);

    container.append(PATH).classed(dhClass, true)
        .attr(D, lineFunction(dhPoints))
        .attr(STROKE, dhColor)
        .attr(STROKE_WIDTH, settings.differenceHighlightLineWidth)
        .attr(FILL, dhColor);

    plotDifferenceHighlightArrows(container, settings.differenceHighlightArrowStyle, settings.differenceHighlightLineWidth, dhColor, dhClass, x, y1, y2);
    plotDifferenceHighlightLines(container, settings.differenceHighlightConnectingLineStyle, settings.differenceHighlightConnectingLineColor, dhClass, x, x1, x2, y1, y2);

    const longerLabel = getLongerSubtotalDifferenceHighlightLabel(settings.differenceLabel, absDifferencelabel, relDifferenceLabel);
    const longerLabelPosition = settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute ? yLabel2 : yLabel1;
    const isLabelWiderThanBar: boolean = endXPos * 0.6 < settings.getLegendTextWidth(longerLabel, settings.labelFontSize, settings.labelFontFamily, NORMAL);
    const isLabelDrawnOverBar: boolean = difference < 0 && yBasis > xAxisPosition || difference > 0 && yBasis < xAxisPosition;
    const subtotalDHlabelProperties: LabelProperties = getSubtotalDifferenceHighlightLabelProperties(settings, dataPoint, longerLabel, xWithOffset, longerLabelPosition);
    if (isLabelWiderThanBar && isLabelDrawnOverBar) {
        plotSubtotalDifferenceHighlightLabelBackground(reportArea, settings, dataPoint, chartIndex, subtotalDHlabelProperties);
    }

    if (settings.differenceHighlightEllipse !== DifferenceHighlightEllipse.Off) {
        plotDifferenceHighlightEmphasis(container, settings, x, yBasis + 3, absDifferencelabel, relDifferenceLabel, dhClass);
    }

    const differenceLabelColor: string = getSubtotalDifferenceHighlightLabelColor(settings.labelFontColor, difference, yBasis, xAxisPosition);
    const differenceLabel = container.append("text").classed(dhClass, true)
        .style(FONT_SIZE, settings.differenceHighlightFontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, getFontFamily(settings.differenceHighlightFontFamily))
        .style(FONT_WEIGHT, getFontWeight(settings.differenceHighlightFontFamily));
    let differenceLabel2: d3.Selection<SVGElement, any, any, any> = null;

    if (settings.differenceLabel === DifferenceLabel.Absolute) {
        differenceLabel.text(absDifferencelabel);
    } else if (settings.differenceLabel === DifferenceLabel.Relative) {
        differenceLabel.text(relDifferenceLabel);
        differenceLabel.style(FONT_STYLE, ITALIC);
    } else {
        differenceLabel.text(absDifferencelabel);
        differenceLabel2 = container.append("text").classed(dhClass, true);
        differenceLabel2.text(relDifferenceLabel)
            .style(FONT_SIZE, settings.differenceHighlightFontSize + FONT_SIZE_UNIT)
            .style(FONT_FAMILY, getFontFamily(settings.differenceHighlightFontFamily))
            .style(FONT_WEIGHT, getFontWeight(settings.differenceHighlightFontFamily))
            .style(FONT_STYLE, ITALIC)
            .attr(X, xWithOffset)
            .attr(Y, yLabel2)
            .attr(FILL, differenceLabelColor);
    }

    differenceLabel
        .attr(X, xWithOffset)
        .attr(Y, yLabel1)
        .attr(FILL, differenceLabelColor);

    if (Visual.animateDiffHighlightLabel) {
        animateDifferenceHighlightLabels(settings.differenceHighlightFontSize, differenceLabel, differenceLabel2);
    }
    if (settings.getRealInteractionSettingValue(settings.allowDifferenceHighlightChange)) {
        addDifferenceHighlightInteractions(differenceLabel, differenceLabel2, container, slider, dhClass, settings);
    }
}

function getDiffHighlightMarkerPoints(arrowStyle: DifferenceHighlightArrowStyle, x: number, dhStart: number, dhEnd: number, arrowWidth: number, lineWidth: number): Point[] {
    if (arrowStyle !== DifferenceHighlightArrowStyle.ClosedArrow && arrowStyle !== DifferenceHighlightArrowStyle.OpenArrow) {
        return [
            { "x": x, "y": dhStart },
            { "x": x, "y": dhEnd },
        ];
    }
    else if (arrowStyle === DifferenceHighlightArrowStyle.OpenArrow) {
        const deltaY = Math.max(7, Math.min(3 * lineWidth, 14)) * (dhStart > dhEnd ? -1 : 1);
        const deltaYAbs = Math.abs(deltaY);
        dhEnd = dhEnd - (lineWidth - 1) * (dhStart > dhEnd ? -1 : 1);
        return [
            { "x": x, "y": dhStart },
            { "x": x, "y": dhEnd },
            { "x": x - deltaYAbs / 2, "y": dhEnd - deltaY },
            { "x": x, "y": dhEnd },
            { "x": x + deltaYAbs / 2, "y": dhEnd - deltaY },
            { "x": x, "y": dhEnd },
        ];
    }
    else {
        const deltaY = 4 * (dhStart > dhEnd ? -1 : 1);
        const deltaYAbs = Math.abs(deltaY);
        dhEnd = dhEnd - (lineWidth - 1) * (dhStart > dhEnd ? -1 : 1);
        return [
            { "x": x, "y": dhStart },
            { "x": x, "y": dhEnd - deltaY },
            { "x": x - deltaYAbs / 2, "y": dhEnd - deltaY },
            { "x": x + arrowWidth / 2, "y": dhEnd },
            { "x": x + arrowWidth + deltaYAbs / 2, "y": dhEnd - deltaY },
            { "x": x + arrowWidth, "y": dhEnd - deltaY },
            { "x": x + arrowWidth, "y": dhStart },
        ];
    }
}

/**
 * Calculates y-coordinate of subtotals difference highlight label, including subtotal DH labels exceptions.
 *
 * @param y1
 * @param y2
 * @param xAxis
 * @param difference
 * @returns number
 */
function getSubtotalDifferenceHighlightLabelY(y1: number, y2: number, xAxis: number, difference: number): number {
    let yBasis = y1 + (y2 - y1) / 2;

    //Subtotal difference highlight: position exceptions
    if (difference < 0) {
        if (yBasis < xAxis && y2 < xAxis && y1 > xAxis) { // negative arrows |up |-y2-----yBasis-----y1-> down|
            yBasis = y2;
        }
    } else {
        if (yBasis > xAxis) { // positive arrows |up <-y1-----yBasis-----y2-| down|
            yBasis = y2;
        }
    }

    return yBasis;
}

/**
 * Returns the longer label out of absolute and relative difference highlight labels
 *
 * @param differenceLabel
 * @param absDifferencelabel
 * @param relDifferenceLabel
 * @returns string
 */
function getLongerSubtotalDifferenceHighlightLabel(differenceLabel: DifferenceLabel, absDifferencelabel: string, relDifferenceLabel: string): string {
    let longerLabel = absDifferencelabel;

    if (differenceLabel === DifferenceLabel.Relative) {
        longerLabel = relDifferenceLabel;
    } else if (differenceLabel === DifferenceLabel.RelativeAndAbsolute) {
        if (relDifferenceLabel.length > absDifferencelabel.length) {
            longerLabel = relDifferenceLabel;
        }
    }

    return longerLabel;
}

/**
 * Returns appropriate label properties for subtotal difference highlight labels.
 *
 * @param settings
 * @param dataPoint
 * @param longerLabel
 * @param xWithOffset
 * @param yLabelRows
 * @returns
 */
function getSubtotalDifferenceHighlightLabelProperties(settings: ChartSettings, dataPoint: DataPoint, longerLabel: string, xWithOffset: number, yLabelRows: number): LabelProperties {
    const labelProperties = getLabelProperty(longerLabel, xWithOffset, yLabelRows, settings.showDataLabels, dataPoint.value, dataPoint, settings.labelFontSize, settings.labelFontFamily, null, null, null, START);
    if (settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute) {
        labelProperties["height"] = labelProperties.height * 2;
        labelProperties["y"] = labelProperties.y + 2;
    }
    return labelProperties;
}

/**
 * Returns subtotal difference highlight color.
 * Rule: the diff is positive per se the arrow is green, draw white labels.
 *
 * @param labelFontColor
 * @param difference
 * @param yBasis
 * @param xAxis
 * @returns string
 */
function getSubtotalDifferenceHighlightLabelColor(labelFontColor: string, difference: number, yBasis: number, xAxis: number): string {
    if (difference < 0) {
        if (yBasis < xAxis) {
            return labelFontColor;
        } else {
            return WHITE;
        }
    } else {
        if (yBasis < xAxis) {
            return WHITE;
        } else {
            return labelFontColor;
        }
    }
}

export function getMouseOverRectangle(container: d3.Selection<SVGElement, any, any, any>, slider: HTMLElement, height: number, width: number, x: number, y: number, className: string): d3.Selection<SVGElement, any, any, any> {
    const scrollTop = slider ? slider.scrollTop : 0;
    const labelRect = container.append(RECT).classed(className, true)
        .attr(WIDTH, width + 2)
        .attr(HEIGHT, height + 2)
        .attr(X, x - 1)
        .attr(Y, y - 1 + scrollTop)
        .attr(FILL_OPACITY, 0)
        .attr(STROKE_OPACITY, 0)
        .attr(FILL, "#000")
        .attr(STROKE, "#000")
        .attr(STROKE_WIDTH, 1)
        .attr(RX, 3);

    labelRect.on(MOUSEOVER, (event) => {
        labelRect.attr(FILL_OPACITY, 0.04);
        labelRect.attr(STROKE_OPACITY, 0.4);
        event.stopPropagation();
    });
    labelRect.on(MOUSEOUT, () => {
        labelRect.attr(FILL_OPACITY, 0);
        labelRect.attr(STROKE_OPACITY, 0);
    });
    return labelRect;
}

export function getCommentMarkerAttributes(scaleBandWidth: number): CommentMarker {
    return {
        radius: Math.min(15, scaleBandWidth * 0.4),
        margin: Math.min(10, scaleBandWidth * 0.2),
        fontSize: Math.min(20, Math.round(scaleBandWidth / 2 + 2))
    };
}

/**
 * Plots backround behind subtotal difference highlight labels.
 *
 * @param reportArea
 * @param settings
 * @param dataPoint
 * @param chartIndex
 * @param xWithOffset
 */
function plotSubtotalDifferenceHighlightLabelBackground(reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, dataPoint: DataPoint, chartIndex: number, labelProperties: LabelProperties): void {
    const bgColor = getLabelBackgroundColor(settings, settings.scenarioOptions, dataPoint.category);
    plotLabelBackgrounds(reportArea, chartIndex, [labelProperties], settings, bgColor, true, true, null, true);
}

/**
 * Draws arrow ending points for difference highlight in the style set in settings.
 *
 * @param container
 * @param arrowStyle
 * @param lineWidth
 * @param dhColor
 * @param dhClass
 * @param x
 * @param y1
 * @param y2
 */
function plotDifferenceHighlightArrows(container: d3.Selection<SVGElement, any, any, any>, arrowStyle: DifferenceHighlightArrowStyle, lineWidth: number, dhColor: string, dhClass: string, x: number, y1: number, y2: number) {
    if (arrowStyle === DifferenceHighlightArrowStyle.Dots) {
        drawing.drawCircle(container, `${dhClass}-arrow`, x, y1, 1.5, BLACK);
        drawing.drawCircle(container, `${dhClass}-arrow`, x, y2, 1.5, BLACK);
    } else if (arrowStyle === DifferenceHighlightArrowStyle.SingleDot) {
        drawing.drawCircle(container, `${dhClass}-arrow`, x, y1, 1.5, BLACK);
    } else if (arrowStyle === DifferenceHighlightArrowStyle.RoundArrow) {
        drawing.drawCircle(container, `${dhClass}-arrow`, x, y1, 1 + lineWidth / 2, dhColor);
    }
}

/**
 * Draws difference highlight connecting lines.
 *
 * @param container
 * @param lineStyle
 * @param lineColor
 * @param dhClass
 * @param x
 * @param x1
 * @param x2
 * @param y1
 * @param y2
 */
function plotDifferenceHighlightLines(container: d3.Selection<SVGElement, any, any, any>, lineStyle: GridlineStyle, lineColor: string, dhClass: string, x: number, x1: number, x2: number, y1: number, y2: number) {
    const connectingLine1 = drawing.drawLine(container, x1, x, y1, y1, 0.5, lineColor, dhClass);
    const connectingLine2 = drawing.drawLine(container, x2, x, y2, y2, 0.5, lineColor, dhClass);
    if (lineStyle === GridlineStyle.Dotted) {
        connectingLine1.attr(STROKE_DASHARRAY, "2,2");
        connectingLine2.attr(STROKE_DASHARRAY, "2,2");
    } else if (lineStyle === GridlineStyle.Dashed) {
        connectingLine1.attr(STROKE_DASHARRAY, "5,5");
        connectingLine2.attr(STROKE_DASHARRAY, "5,5");
    }
}

function plotDifferenceHighlightEmphasis(container: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, x: number, y: number, absDifferencelabel: string, relDifferenceLabel: string, dhClass: string) {
    const dhColor = settings.colorScheme.highlightColor;
    const fill_color = dhColor;
    let labelWidth = 0;
    const label_x = x + 6;

    if (settings.differenceLabel === DifferenceLabel.Absolute || settings.differenceLabel === DifferenceLabel.Relative) {
        const label = settings.differenceLabel === DifferenceLabel.Absolute ? absDifferencelabel : relDifferenceLabel;
        if (label === "")
            return;

        const fontStyle = settings.differenceLabel === DifferenceLabel.Absolute ? NORMAL : ITALIC;
        labelWidth = drawing.measureTextWidth(label, settings.differenceHighlightFontSize, getFontFamily(settings.differenceHighlightFontFamily), getFontWeight(settings.differenceHighlightFontFamily), fontStyle);
        y -= Math.ceil(settings.differenceHighlightFontSize / 2);
    }
    else if (settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute) {
        const labelWidth1 = drawing.measureTextWidth(absDifferencelabel, settings.differenceHighlightFontSize, getFontFamily(settings.differenceHighlightFontFamily), getFontWeight(settings.differenceHighlightFontFamily), NORMAL);
        const labelWidth2 = drawing.measureTextWidth(relDifferenceLabel, settings.differenceHighlightFontSize, getFontFamily(settings.differenceHighlightFontFamily), getFontWeight(settings.differenceHighlightFontFamily), ITALIC);
        labelWidth = Math.max(labelWidth1, labelWidth2);
        if (absDifferencelabel === "" || relDifferenceLabel === "") {
            y -= Math.ceil(settings.differenceHighlightFontSize / 2);
        }
    }

    if (settings.differenceHighlightEllipse === DifferenceHighlightEllipse.Circle) {
        const cx = label_x + labelWidth / 2;
        const cy = y;
        const r = ((labelWidth) / 2) + settings.differenceHighlightEllipseBorderPadding;
        const circle = drawing.drawCircle(container, dhClass, cx, cy, r, fill_color);
        circle
            .attr(FILL_OPACITY, settings.differenceHighlightEllipseFillOpacity / 100)
            .attr(STROKE, dhColor)
            .attr(STROKE_WIDTH, settings.differenceHighlightEllipseBorderWidth);
    }
    else {
        const cx = label_x + labelWidth / 2;
        const cy = y;
        let rx = ((labelWidth) / 2) + settings.differenceHighlightEllipseBorderPadding;
        let ry = settings.differenceHighlightFontSize * ((settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute && (absDifferencelabel != "" && relDifferenceLabel != "")) ? 2 : 1)
            + settings.differenceHighlightEllipseBorderPadding + settings.differenceHighlightEllipseBorderWidth;

        if (ry > rx) {
            ry = rx;
            rx *= 1.5;
        }

        drawing.drawEllipse(container, dhClass, cx, cy, rx, ry, fill_color, settings.differenceHighlightEllipseFillOpacity / 100, dhColor, settings.differenceHighlightEllipseBorderWidth);
    }
}

export function addCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[],
    getMarkerHorizontalPosition: (d: DataPoint) => number, getMarkerVerticalPosition: (d: DataPoint, i: number) => number,
    markerRadius: number, markerFontSize: number, settings: ChartSettings) {
    let commentMarkers = container
        .selectAll(COMMENT_MARKER)
        .data(commentDataPoints);
    const circles = commentMarkers.enter()
        .append(CIRCLE)
        .classed(COMMENT_MARKER, true)
        .classed(HIGHLIGHTABLE, true);
    commentMarkers = commentMarkers.merge(circles)
        .attr(CX, d => getMarkerHorizontalPosition(d))
        .attr(CY, (d, i) => getMarkerVerticalPosition(d, i))
        .attr("r", markerRadius);
    commentMarkers.exit().remove();
    setCommentMarkerCircleStyle(commentMarkers, settings.colorScheme.highlightColor);

    let commentNumbers = container
        .selectAll("comment-marker-number")
        .data(commentDataPoints);
    const commentText = commentNumbers.enter()
        .append(TEXT)
        .classed(COMMENT_MARKER, true)
        .classed(HIGHLIGHTABLE, true);
    commentNumbers = commentNumbers.merge(commentText)
        .text((d, i) => getCommentMarkerNumber(d, i))
        .attr(X, d => getMarkerHorizontalPosition(d))
        .attr(Y, (d, i) => getMarkerVerticalPosition(d, i));
    commentNumbers.exit().remove();
    setCommentMarkerNumberStyle(commentNumbers, settings.colorScheme.highlightColor, markerFontSize, settings.labelFontFamily);
}

function setCommentMarkerNumberStyle(commentNumbers: d3.Selection<d3.BaseType, DataPoint, SVGElement, any>, color: string, fontSize: number, fontFamily: string) {
    commentNumbers
        .attr(STROKE_WIDTH, 2)
        .attr(FILL, color)
        .style(FONT_SIZE, fontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, getFontFamily(fontFamily))
        .style(TEXT_ANCHOR, MIDDLE)
        .attr("dominant-baseline", "central");
}

function setCommentMarkerCircleStyle(commentCircles: d3.Selection<d3.BaseType, DataPoint, SVGElement, any>, color: string) {
    commentCircles
        .attr(STROKE, color)
        .attr(STROKE_WIDTH, 2)
        .attr(FILL, WHITE)
        .attr(FILL_OPACITY, 0.1);
}

function getCommentMarkerNumber(d: DataPoint, index: number): number {
    return d.commentsMeasuresValues && d.commentsMeasuresValues.length > 0 && !isNaN(+d.commentsMeasuresValues[0]) ? +d.commentsMeasuresValues[0] : index + 1;
}

export function animateLabels(labels: d3.Selection<any, any, any, any>, additionalLabels: d3.Selection<any, any, any, any>, fontSize: number) {
    labels.style(FONT_SIZE, fontSize + 2 + FONT_SIZE_UNIT)
        .transition()
        .style(FONT_SIZE, fontSize + FONT_SIZE_UNIT);
    if (additionalLabels != null) {
        additionalLabels.style(FONT_SIZE, 1)
            .transition()
            .style(FONT_SIZE, fontSize + 2 + FONT_SIZE_UNIT)
            .transition()
            .style(FONT_SIZE, fontSize + FONT_SIZE_UNIT);
    }
}

export function addDroplineHandlers(container: d3.Selection<SVGElement, any, any, any>, y: number, height: number, bottomMargin: number, topMargin: number,
    xScale: d3.ScaleBand<string>, dataPoints: DataPoint[], viewModel: ViewModel) {
    const dropline = drawing.drawLine(container, 0, 0, 0, 0, 1, GRIDLINE_COLOR, MOUSE_OVER_DROPLINE);

    container.on(MOUSEMOVE, (event, d) => {
        const pointerXPos = (Visual.isChartFocusPopupShown || Visual.settings.showCommentBox) ? (<PointerEvent>event).offsetX : (<PointerEvent>event).x;
        const domain = xScale.domain();
        const range = domain.map(s => xScale(s));
        const categoryIndex = d3.bisect(range, pointerXPos) - 1;
        if (categoryIndex < 0 || categoryIndex > domain.length - 1) {
            return;
        }
        const category = domain[categoryIndex];
        const x = xScale(category) + xScale.bandwidth() / 2;
        const dataPoint = dataPoints.filter(d => d.category === category)[0];
        if (!dataPoint || categoryIndex === domain.length - 1 && pointerXPos > x + 3) {
            return;
        }

        const relativeXPos = x - d3.extent(xScale.range())[0];
        dropline
            .attr(X1, x)
            .attr(Y1, y + topMargin)
            .attr(X2, x)
            .attr(Y2, y + height - bottomMargin)
            .attr(OPACITY, 1);
        // if (!selectionManager.hasSelection()) {
        const textShapes = Visual.isChartFocusPopupShown ? <any[]>container.selectAll(`${TEXT}.${HIGHLIGHTABLE}`).nodes() :
            <any[]>d3.selectAll(`${TEXT}.${HIGHLIGHTABLE}`).nodes();
        for (let i = 0; i < textShapes.length; i++) {
            const textShape = textShapes[i];
            if (textShape.__data__.category === dataPoint.category) {
                d3.select(textShape)
                    .attr(FONT_WEIGHT, BOLD)
                    .attr(OPACITY, 1);
            }
            else {
                d3.select(textShape)
                    .attr(FONT_WEIGHT, NORMAL)
                    .attr(OPACITY, 0.6);
            }
        }
    });

    container.on(MOUSEOUT, () => {
        dropline.attr(OPACITY, 0);
    });
}

export function addHighlightedDataPointsDroplines(settings: ChartSettings, chartData: ChartData, xScale: d3.ScaleBand<string>, y: number, yScale: d3.ScaleLinear<number, number>, chartContainer: d3.Selection<SVGElement, any, any, any>) {
    if (settings.highlightedCategories.length) {
        const highlightedDataPoints = chartData.dataPoints.filter(dp => settings.highlightedCategories.indexOf(dp.category) >= 0);
        highlightedDataPoints.forEach((dp) => {
            const x = xScale(dp.category) + xScale.bandwidth() / 2;
            const y1 = y + yScale(Math.max(viewModels.getDataPointNonNullValue(dp), dp.reference || 0, dp.secondReference || 0));
            drawing.drawLine(chartContainer, x, x, y1, y + yScale(0), 1, settings.getCategoryHighlightColor(dp.category), "highlight-dropline", dp);
        });
    }
}

export function getGrandTotalDataPoint(dataPoints: DataPoint[], secondSegmentDataPoints: DataPoint[], settings: ChartSettings): DataPoint {
    const totalReference = dataPoints.map(p => p.reference).reduce((a, b) => a + b, 0);
    const valuesDataPoints = dataPoints.filter(dp => dp.value !== null);
    const totalValue = valuesDataPoints.length > 0 ? valuesDataPoints.map(p => p.value).reduce((a, b) => a + b) : 0;
    const totalSecondValue = secondSegmentDataPoints.length > 0 ? secondSegmentDataPoints.map(p => p.secondSegmentValue).reduce((a, b) => a + b) : 0;
    const relativeVariance = viewModels.calculateRelativeDifference(totalValue + totalSecondValue, totalReference);

    const totalDataPoint: DataPoint = {
        category: settings.grandTotalLabel ?? TOTAL,
        reference: totalReference,
        value: totalValue,
        color: settings.isCategoryHighlighted(settings.grandTotalLabel) ? settings.getCategoryHighlightColor(settings.grandTotalLabel) : dataPoints[0].color,
        isHighlighted: false,
        secondSegmentValue: totalSecondValue,
        isNegative: false,
        isVariance: true,
        relativeVariance: relativeVariance,
        secondReference: null,
    };

    if (dataPoints.some(p => p.secondReference != null)) {
        totalDataPoint.secondReference = dataPoints.map(p => p.secondReference).reduce((a, b) => a + b);
        totalDataPoint.secondReferenceRelativeVariance = viewModels.calculateRelativeDifference(totalValue + totalSecondValue, totalDataPoint.secondReference);
    }
    return totalDataPoint;
}

export function addReferenceTriangles(container: d3.Selection<SVGElement, any, any, any>, dataPoints: DataPoint[], settings: ChartSettings,
    ordinalScale: d3.ScaleBand<string>, linearScale: d3.ScaleLinear<number, number>, y: number, referenceScenario: Scenario, isSecondReference: boolean, fixedXPosition?: number, fixedYPosition?: number) {
    const dataProperty = isSecondReference ? SECOND_REFERENCE : REFERENCE;

    const strokeWidthPlan: number = 1;
    const referenceMarkerSize: number = settings.referenceMarkerSize === MarkerSize.Fixed ? calculateMarkerFixedSize(settings.referenceMarkerFixedSize) : calculateMarkerAutoSize(ordinalScale, settings);
    const referenceMarkerPlanSize: number = settings.referenceMarkerSize === MarkerSize.Fixed ? calculateMarkerFixedSize(settings.referenceMarkerFixedSize, strokeWidthPlan) : calculateMarkerAutoSize(ordinalScale, settings, strokeWidthPlan);

    const transformFn: (d: DataPoint) => string = settings.shouldPlotVerticalCharts() ?
        d => `translate(${linearScale(fixedYPosition != null ? fixedYPosition : d[dataProperty])},${fixedXPosition || ordinalScale(d.category)}) rotate(180)` :
        d => `translate(${fixedXPosition || ordinalScale(d.category)}, ${y + linearScale(fixedYPosition || d[dataProperty])}) rotate(90)`;

    if (referenceScenario == Scenario.Plan) {
        let whiteBackgroundTriangles = container.selectAll(".symbol")
            .data(dataPoints);
        const path = whiteBackgroundTriangles
            .enter()
            .append(PATH)
            .attr(D, d3.symbol().type(d3.symbolTriangle).size(Math.ceil(referenceMarkerSize * 1.05)))
            .attr(TRANSFORM, transformFn)
            .classed(TOOLTIP, true)
            .classed(HIGHLIGHTABLE, true);
        whiteBackgroundTriangles = whiteBackgroundTriangles.merge(path);
        whiteBackgroundTriangles.attr(FILL, WHITE);
    }
    let triangles = container.selectAll(".symbol")
        .data(dataPoints);
    const path = triangles
        .enter()
        .append(PATH)
        .attr(D, d3.symbol().type(d3.symbolTriangle).size(referenceScenario !== Scenario.Plan ? referenceMarkerSize : referenceMarkerPlanSize))
        .attr(TRANSFORM, transformFn)
        .classed(TOOLTIP, true)
        .classed(HIGHLIGHTABLE, true);
    triangles = triangles.merge(path);
    styles.applyToBars(triangles, (d: DataPoint) => settings.getCategoryDataPointColor(d.category, d.color), referenceScenario, settings.chartStyle, false, settings.colorScheme);
    if (referenceScenario !== Scenario.Plan) {
        triangles.attr(STROKE_WIDTH, 1.5);
        triangles.attr(STROKE, WHITE);
    }
    else {
        triangles.attr(STROKE_WIDTH, strokeWidthPlan);
    }
}

export function plotChartLegendWithNoInteraction(container: d3.Selection<SVGElement, any, any, any>, legendObject: { legendText: string, legendScenarioKey: string }, xPosition: number, y: number, settings: ChartSettings, isLegendItemColored: boolean = false, scenario: Scenario = Scenario.Actual, textAnchor: string = START): d3.Selection<any, any, any, any> {
    return container.append(TEXT).classed("chart-legend", true)
        .text(legendObject.legendText)
        .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, getFontFamily(settings.labelFontFamily))
        .style(FONT_WEIGHT, getFontWeight(settings.labelFontFamily))
        .style(TEXT_ANCHOR, textAnchor)
        .attr(X, xPosition)
        .attr(Y, y)
        .attr(FILL, settings.getLegendColor(isLegendItemColored, scenario));
}

export function plotChartLegend(container: d3.Selection<SVGElement, any, any, any>, legendObject: { legendText: string, legendScenarioKey: keyof ChartSettings }, x: number, y: number, settings: ChartSettings, isLegendItemColored: boolean = false, scenario: Scenario = Scenario.Actual, textAnchor: string = START): d3.Selection<any, any, any, any> {
    const xPosition = textAnchor === START ? x : x + settings.getLegendWidth() - settings.legendItemsMargin - (settings.chartType === ChartType.Line || settings.chartType === ChartType.Area ? 3 : 10);

    //Do the legend old way as a <text> with interaction settings turned off
    if (Visual.isChartFocusPopupShown) {
        return plotChartLegendWithNoInteraction(container, legendObject, xPosition, y, settings, isLegendItemColored, scenario, textAnchor);
    }

    const chartLegend = container.append(G).classed("chart-legend", true);

    const legendItem = new LegendElements.LegendItem(legendObject.legendText ?? settings.setEmptyLegendEntryToDefault(legendObject.legendScenarioKey),
        settings.getLegendColor(isLegendItemColored, scenario),
        settings.labelFontSize,
        getFontFamily(settings.labelFontFamily),
        getFontWeight(settings.labelFontFamily),
        textAnchor,
        document.querySelector(`.${ZEBRABI_CHART_SVG_CONTAINER}`));

    const legendItemHTML: HTMLElement = legendItem.renderLegendItem();

    legendItem.legendTextSubject.subscribe(new class extends LegendTextSubscriber {
        public update(label: string) {
            let persistedLabel = label;
            if (persistedLabel === undefined || persistedLabel === null || persistedLabel?.length === 0) {
                persistedLabel = settings.setEmptyLegendEntryToDefault(legendObject.legendScenarioKey);
                legendItem.text = persistedLabel;
            }
            settings.persistLegendEntries(legendObject.legendScenarioKey, persistedLabel);
        }
    });

    const foreignObject = chartLegend.append("foreignObject");
    foreignObject.node().append(legendItemHTML);

    const legendItemText = legendItemHTML.querySelector(".legend-item-text");
    (<HTMLElement>legendItemText).style.fontSize = settings.labelFontSize + FONT_SIZE_UNIT;
    const legendTextWidth = (<HTMLDivElement>legendItemText).offsetWidth; //same as using settings.getLegendTextWidth(legendObject.legendText)

    //Exceptions when Show vertical axis = ON
    if (settings.showVerticalCharts) {
        foreignObject.attr(X, `${xPosition}`);
        foreignObject.attr(WIDTH, `${legendTextWidth + 15}`); //15px added due to dropdown arrow showing on hover
    } else {
        // legendXPosition: 4px are added because of the onhover border and padding, -legendTextWidth to simulate SVG text-anchor:end
        const legendXPosition = xPosition + 4 - legendTextWidth;
        //  X: if x position is less then zero, fix it to zero.
        foreignObject.attr(X, `${legendXPosition > 0 ? legendXPosition : 0}`);
        foreignObject.attr(WIDTH, `${legendTextWidth + 20}`); //20px added due to dropdown arrow showing on hover
    }

    // Y: HTML is drawn from left upper corner, SVG text was drawn from text baseline aka left lower corner
    foreignObject.attr(Y, `${y - settings.labelFontSize}`);

    foreignObject.attr(HEIGHT, `${settings.labelFontSize}`);
    foreignObject.attr(OVERFLOW, "visible");

    foreignObject.attr(FILL, WHITE);

    return chartLegend;
}

export function plotAllChartLegends(container: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, x: number, y: number, chartWidth: number, yScale: d3.ScaleLinear<number, number>,
    valueDataPoints: DataPoint[], referenceDataPoints: DataPoint[], secondValueDataPoints: DataPoint[], secondReferenceDataPoints: DataPoint[], isIntegratedVariance: boolean = false, height?: number) {

    if (settings.showLegend) {
        let valuesLegendYPositions: number = null;
        let referenceLegendYPositions: number = null;
        let secondReferenceLegendYPositions: number = null;
        const offset: number = settings.labelFontSize / 3;
        const scenarioOptions = settings.scenarioOptions;

        if (valueDataPoints && valueDataPoints.length > 0) {
            valuesLegendYPositions = settings.isLineChart() ? y + yScale(valueDataPoints[0].value) + offset : y + yScale(valueDataPoints[0].value / 2) + offset;
            plotChartLegend(container, mapScenarioKeyAndName("valueHeader", settings.valueHeader, scenarioOptions), x, valuesLegendYPositions, settings, true, settings.scenarioOptions.valueScenario, END);
        }
        if (referenceDataPoints && referenceDataPoints.length > 0) {
            if (isIntegratedVariance) {
                const referenceLegendYPositionsValue = !valueDataPoints || valueDataPoints.length === 0 || referenceDataPoints[0].reference > valueDataPoints[0].value ?
                    referenceDataPoints[0].reference : valueDataPoints[0].value;
                referenceLegendYPositions = y + yScale(referenceLegendYPositionsValue);
            }
            else {
                referenceLegendYPositions = settings.isLineChart() || settings.referenceDisplayType === ReferenceDisplayType.Triangles ? y + yScale(referenceDataPoints[0].reference) + offset : y + yScale(referenceDataPoints[0].reference / 2) + offset;
            }

            if (valuesLegendYPositions && Math.abs(valuesLegendYPositions - referenceLegendYPositions) < settings.labelFontSize + 3) {
                referenceLegendYPositions = valuesLegendYPositions + (referenceLegendYPositions > valuesLegendYPositions ? 1 : -1) * settings.labelFontSize;
            }

            const legendText = isIntegratedVariance ? settings.absoluteDifferenceHeader : settings.referenceHeader;
            const legendScenarioKey = isIntegratedVariance ? "absoluteDifferenceHeader" : "referenceHeader";
            const referenceLegend = plotChartLegend(container, mapScenarioKeyAndName(legendScenarioKey, legendText, scenarioOptions), x, referenceLegendYPositions, settings, true, settings.scenarioOptions.referenceScenario, END);

            if (settings.scenarioOptions.referenceScenario === Scenario.PreviousYear && !isIntegratedVariance) {
                referenceLegend.select(".legend-item-text").style(COLOR, settings.useColoredLegendNames ? settings.getLegendColor(true, settings.scenarioOptions.referenceScenario) : styles.getLighterColor(settings.legendFontColor));
            }
        }
        if (secondReferenceDataPoints && secondReferenceDataPoints.length > 0) {
            secondReferenceLegendYPositions = y + yScale(secondReferenceDataPoints[0].secondReference);
            if (valuesLegendYPositions && Math.abs(valuesLegendYPositions - secondReferenceLegendYPositions) < settings.labelFontSize + 3) {
                secondReferenceLegendYPositions = valuesLegendYPositions + (secondReferenceLegendYPositions > valuesLegendYPositions ? 1 : -1) * settings.labelFontSize;
            }
            if (referenceLegendYPositions && Math.abs(referenceLegendYPositions - secondReferenceLegendYPositions) < settings.labelFontSize + 3) {
                secondReferenceLegendYPositions = referenceLegendYPositions + (secondReferenceLegendYPositions > referenceLegendYPositions ? 1 : -1) * settings.labelFontSize;
                if (valuesLegendYPositions && Math.abs(valuesLegendYPositions - secondReferenceLegendYPositions) < settings.labelFontSize + 3) {
                    secondReferenceLegendYPositions = (referenceLegendYPositions > valuesLegendYPositions ? referenceLegendYPositions : valuesLegendYPositions) + settings.labelFontSize;
                }
            }
            plotChartLegend(container, mapScenarioKeyAndName("secondReferenceHeader", settings.secondReferenceHeader, scenarioOptions), x, secondReferenceLegendYPositions, settings, true, settings.scenarioOptions.secondReferenceScenario, END);
        }

        if (secondValueDataPoints && secondValueDataPoints.length > 0) {
            const secondValueLegendYPosition = y + yScale(secondValueDataPoints[secondValueDataPoints.length - 1].secondSegmentValue);
            plotChartLegend(container, mapScenarioKeyAndName(SECOND_VALUE_HEADER, settings.secondValueHeader, scenarioOptions), x + chartWidth + settings.getLegendWidth(), secondValueLegendYPosition, settings, true, settings.scenarioOptions.secondValueScenario);
        }
        // Don't draw legend settings if we have vertical small multiples
        if (!(settings.showVerticalCharts && Visual.viewModel.isMultiples)) {
            plotChartLegendSettings(container, x, y, height);
        }
    }
}

export function shouldProvideSomeSpaceForLineChartRightLegend(settings: ChartSettings, plotLegend: boolean): boolean {
    return plotLegend && !settings.differenceHighlight && settings.scenarioOptions.secondValueScenario !== null;
}

export function getLegendText(scenario: Scenario, isVariance: boolean, isRelativeVariance: boolean): string {
    let legentText = scenarios.getScenarioName(scenario);
    if (isVariance) {
        legentText = "Δ" + legentText;
    }
    if (isRelativeVariance) {
        legentText += "%";
    }
    return legentText;
}

export function syncStateOffice(isOthersSelected?: boolean) {
    const shapes = <d3.Selection<any, DataPoint, any, any>>d3.selectAll(`${RECT}.${HIGHLIGHTABLE}, ${CIRCLE}.${HIGHLIGHTABLE}, ${PATH}.${HIGHLIGHTABLE}`);
    const texts = <d3.Selection<any, DataPoint, any, any>>d3.selectAll(`${TEXT}.${HIGHLIGHTABLE}`);
    if (Visual.isFilterApplied()) {
        shapes
            .attr(OPACITY, d => isPointSelectedOffice(d, Visual.selectedCategories, isOthersSelected) ? 1 : 0.3);
        texts
            .attr(FONT_WEIGHT, d => isPointSelectedOffice(d, Visual.selectedCategories, isOthersSelected) || d.isHighlighted ? BOLD : NORMAL)
            .attr(OPACITY, d => isPointSelectedOffice(d, Visual.selectedCategories, isOthersSelected) ? 1 : 0.3);
    }
    else {
        shapes.attr(OPACITY, 1);
        texts.attr(FONT_WEIGHT, d => d.isHighlighted ? BOLD : NORMAL);
        texts.attr(OPACITY, 1);
        d3.selectAll(`${LINE}.${SELECTION_DROP_LINE}`).remove();
    }

    //window.dispatchEvent(syncEvent);
}

export function plotAxisBreakSymbol(container: d3.Selection<SVGElement, any, any, any>, bar: d3.Selection<any, DataPoint, any, any>, settings: ChartSettings, slider: HTMLElement, isLineChart: boolean): d3.Selection<SVGElement, any, any, any> {
    const labelBoundingClientRect = (<SVGAElement>bar.node()).getBBox();
    const rectWidth = labelBoundingClientRect.width,
        rectHeight = labelBoundingClientRect.height,
        rectX = labelBoundingClientRect.x,
        rectY = labelBoundingClientRect.y;
    const breakSymbolLinesColor = settings.hasAxisBreak ? "red" : BLACK;
    const lineFunction = d3.line<Point>()
        .x(d => d.x)
        .y(d => d.y)
        .curve(d3.curveLinear);

    const yStart = rectY + (isLineChart ? 0.75 : 0.5) * rectHeight;
    const axisBreakEl = drawing.createGroupElement(container, AXIS_BREAK_SYMBOL);
    axisBreakEl.append(PATH).classed(AXIS_BREAK_SYMBOL, true)
        .attr(D, lineFunction([
            { x: rectX - 2, y: yStart },
            { x: rectX + rectWidth + 2, y: yStart - 5 },
            { x: rectX + rectWidth + 2, y: yStart },
            { x: rectX - 2, y: yStart + 5 },
        ]))
        .attr(STROKE, WHITE)
        .attr(STROKE_WIDTH, 1)
        .attr(FILL, WHITE);

    const y1End = settings.hasAxisBreak ? yStart : yStart - 6;
    const y2End = settings.hasAxisBreak ? yStart - 6 : yStart;
    drawing.drawLine(axisBreakEl, rectX - 10, rectX + rectWidth + 10, yStart, y1End, 1, breakSymbolLinesColor, AXIS_BREAK_SYMBOL);
    drawing.drawLine(axisBreakEl, rectX - 10, rectX + rectWidth + 10, yStart + 6, y2End, 1, breakSymbolLinesColor, AXIS_BREAK_SYMBOL);
    return axisBreakEl;
}

export function plotVerticalAxisBreakSymbol(container: d3.Selection<SVGElement, any, any, any>, bar: d3.Selection<any, DataPoint, any, any>, settings: ChartSettings, slider: HTMLElement, isLineChart: boolean): d3.Selection<SVGElement, any, any, any> {
    const labelBoundingClientRect = (<SVGAElement>bar.node()).getBBox();
    const rectWidth = labelBoundingClientRect.width,
        rectHeight = labelBoundingClientRect.height,
        rectX = labelBoundingClientRect.x,
        rectY = labelBoundingClientRect.y;
    const breakSymbolLinesColor = settings.hasAxisBreak ? "red" : BLACK;
    const lineFunction = d3.line<Point>()
        .x(d => d.x)
        .y(d => d.y)
        .curve(d3.curveLinear);

    const xStart = rectX + 0.5 * rectWidth;
    const axisBreakEl = drawing.createGroupElement(container, AXIS_BREAK_SYMBOL);
    axisBreakEl.append(PATH).classed(AXIS_BREAK_SYMBOL, true)
        .attr(D, lineFunction([
            { x: xStart, y: rectY - 2 },
            { x: xStart - 5, y: rectY + rectHeight + 2 },
            { x: xStart, y: rectY + rectHeight + 2 },
            { x: xStart + 5, y: rectY - 2 },
        ]))
        .attr(STROKE, WHITE)
        .attr(STROKE_WIDTH, 1)
        .attr(FILL, WHITE);

    const x1End = settings.hasAxisBreak ? xStart : xStart - 6;
    const x2End = settings.hasAxisBreak ? xStart - 6 : xStart;
    drawing.drawLine(axisBreakEl, xStart, x1End, rectY - 10, rectY + rectHeight + 10, 1, breakSymbolLinesColor, AXIS_BREAK_SYMBOL);
    drawing.drawLine(axisBreakEl, xStart + 6, x2End, rectY - 10, rectY + rectHeight + 10, 1, breakSymbolLinesColor, AXIS_BREAK_SYMBOL);
    return axisBreakEl;
}

export function addAxisBreakLineChartsUIHandlers(container: d3.Selection<SVGElement, any, any, any>, x: number, y1: number, y2: number, settings: ChartSettings, slider: HTMLElement) {
    if (!settings.getRealInteractionSettingValue(settings.allowAxisBreakChange) || Visual.isChartFocusPopupShown) {
        return;
    }

    const axisBreakLineBackground = drawing.drawLine(container, x, x, y1, y2, 30, WHITE, "axis-break-background");
    axisBreakLineBackground.attr(OPACITY, 0.01);
    const axisBreakLine = drawing.drawLine(container, x, x, y1, y2, 1, GRAY, "axis-break-vertical-line");
    axisBreakLine.attr(OPACITY, 0);
    axisBreakLine.attr(POINTER_EVENTS, NONE);

    axisBreakLineBackground.on(MOUSEOVER, (e) => {
        axisBreakLine.attr(OPACITY, 1);
        const axisBreakEl = plotAxisBreakSymbol(container, <any>axisBreakLineBackground, settings, slider, true);
        axisBreakEl.attr(POINTER_EVENTS, NONE);
        showPopupMessage(Visual.element, x + Visual.GET_CHARTS_VISUAL_BOUNDING_CLIENT().left - 130, e.y - 30, settings.hasAxisBreak ? "Click to un-break the axis" : "Click to break the axis");
        if (e.stopPropagation) {
            e.stopPropagation();
        }
    });

    axisBreakLineBackground.on(MOUSEOUT, (event, i) => {
        axisBreakLine.attr(OPACITY, 0);
        container.selectAll("*." + AXIS_BREAK_SYMBOL).remove();
    });

    axisBreakLineBackground.on(CLICK, (event) => {
        if (settings.proVersionActive()) {
            settings.hasAxisBreak = !settings.hasAxisBreak;
            settings.persistAxisBreak();
            event.stopPropagation();
        }
        else {
            showPopupMessage(Visual.element, x - 120, y1 + (y2 - y1) / 2 - 30, "Axis break available in Pro version");
        }
    });
}

export function getAxisBreakUIxPosition(chartXPos: number, chartWidth: number, viewModel: ViewModel, settings: ChartSettings) {
    let axisBreakXPos = chartXPos + chartWidth - (!viewModel.isMultiples && !settings.differenceHighlight ? 10 : 0);
    axisBreakXPos += settings.differenceHighlight ? 10 : 0;
    return axisBreakXPos;
}

export function plotLabels(container: d3.Selection<SVGElement, any, any, any>, className: string, labelsProperties: LabelProperties[], settings: ChartSettings, fontStyle: string = NORMAL, varianceInteraction: boolean = false) {
    let labels = container
        .selectAll(`.${LABEL}${className}`)
        .data(labelsProperties);
    const text = labels.enter()
        .append(TEXT)
        .text(d => d.text)
        .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, getFontFamily(settings.labelFontFamily))
        .style(FONT_WEIGHT, getFontWeight(settings.labelFontFamily))
        .style(TEXT_ANCHOR, d => d.alignment ? d.alignment : MIDDLE)
        .style(FONT_STYLE, d => d.isHighlighted ? BOLD : fontStyle)
        .attr(X, d => d.x)
        .attr(Y, d => d.y)
        .attr(FILL, settings.labelFontColor)
        .classed(`${HIGHLIGHTABLE} ${LABEL}`, true);
    labels = labels.merge(text);
    labels.exit().remove();

    showOverlayOnDataLabelHover(
        labels,
        {
            fontSize: settings.labelFontSize,
            fontFamily: getFontFamily(settings.labelFontFamily),
            fontColor: settings.labelFontColor
        },
        varianceInteraction);

    return labels;
}

export function plotLabelBackgrounds(container: d3.Selection<SVGElement, any, any, any>, chartIndex: number, labelProperties: LabelProperties[], settings: ChartSettings,
    fillColor: string, isHighlightable: boolean, useHighlightColor: boolean, opacity?: number, alignLeft?: boolean) {
    const fillOpacity = opacity ? opacity : 1 - (settings.labelBackgroundTransparency / 100);
    let backgrounds = container
        .selectAll(`${BACKGROUND}${chartIndex}`)
        .data(labelProperties);
    const rect = backgrounds.enter()
        .append(RECT)
        .classed(`${BACKGROUND}${chartIndex}`, true)
        .classed(HIGHLIGHTABLE, isHighlightable)
        .attr(X, d => d.x - HORIZONTAL_LABEL_PADDING - (alignLeft === true ? 0 : d.width / 2))
        .attr(Y, d => d.y - d.height + 3)
        .attr(WIDTH, d => d.width + 2 * HORIZONTAL_LABEL_PADDING)
        .attr(HEIGHT, d => d.height)
        .attr(RX, 3)
        .attr(FILL, d => useHighlightColor && settings.highlightedCategories.indexOf(d.category) >= 0 ? settings.getCategoryHighlightColor(d.category) : fillColor)
        .attr(FILL_OPACITY, fillOpacity);
    backgrounds = backgrounds.merge(rect);
    backgrounds.exit().remove();
}

export function getFontFamily(fontFamily: string): string {
    if (fontFamily === SEGOE_UI_BOLD) {
        return SEGOE_UI;
    }
    else return fontFamily;
}

export function getFontWeight(fontFamily: string): string {
    if (fontFamily === SEGOE_UI_BOLD) {
        return BOLD;
    }
    else return NORMAL;
}

export function getLabelProperty(labelText: string, xPos: number, yPos: number, visibility: boolean, value: number, d: DataPoint, labelFontSize: number, labelFontFamily: string,
    isVariance: boolean = null, isOutlier: boolean = null, isForecast: boolean = null, alignment: LabelAlignment = null, isItalic = false,
    chartXPosStart: number = null, chartXPosEnd: number = null): LabelProperties {
    const fontStyle = isItalic ? ITALIC : NORMAL;
    const width = drawing.measureTextWidth(labelText, labelFontSize, getFontFamily(labelFontFamily), getFontWeight(labelFontFamily), fontStyle) + 2 * HORIZONTAL_LABEL_PADDING;
    const halfWidth = width / 2;
    const height = drawing.measureTextHeight(labelText, labelFontSize, getFontFamily(labelFontFamily), getFontWeight(labelFontFamily), fontStyle);

    if (chartXPosStart !== null && xPos - halfWidth < chartXPosStart) {
        xPos = chartXPosStart + halfWidth;
    }
    else if (chartXPosStart === null && alignment !== START && xPos - width / 2 < 0) {
        xPos = width / 2;
    }

    if (chartXPosEnd !== null && xPos + width / 2 > chartXPosEnd) {
        xPos = chartXPosEnd - halfWidth;
    }
    else if (!Visual.viewModel.is2dMultiples && alignment !== END && xPos + width / 2 > Visual.visualViewPort.width) {
        xPos = Visual.visualViewPort.width - width / 2;
    }

    return {
        text: labelText,
        value: value,
        width: width,
        height: height,
        x: xPos,
        y: yPos,
        visibility: visibility,
        category: d.category,
        selectionId: d.selectionId,
        isVariance: isVariance,
        isOutlier: isOutlier,
        isForecast: isForecast,
        alignment: alignment,
        isHighlighted: d.isHighlighted,
    };
}

export function checkForOverlappedLabels(labelProperties: LabelProperties[], labelDensity: LabelDensity, xRangeBand: number): LabelProperties[] {
    if (labelDensity === LabelDensity.Auto) {
        labelsOverlapping(labelProperties, 0, xRangeBand);
        labelProperties = labelProperties.filter(p => p.visibility);
    } else if (labelDensity === LabelDensity.High) {
        labelsOverlapping(labelProperties, 0.05, xRangeBand);
        labelProperties = labelProperties.filter(p => p.visibility);
    } else if (labelDensity === LabelDensity.Medium) {
        labelsOverlapping(labelProperties, 0.15, xRangeBand);
        labelProperties = labelProperties.filter(p => p.visibility);
    } else if (labelDensity === LabelDensity.Low) {
        labelsOverlapping(labelProperties, 0.5, xRangeBand);
        labelProperties = labelProperties.filter(p => p.visibility);
    }
    return labelProperties;
}

export function getFormattedDataLabel(value: number, decimalPlaces: number, displayUnits: string, locale: string,
    encloseNegativeValuesInParentheses: boolean, showPercentageSymbol: boolean, isVariance: boolean, isRelativeVariance: boolean, encloseRelativeVarianceInParentheses: boolean,
    percentageFormat?: string, hideUnits: boolean = false): string {
    const encloseInParenthesis = encloseNegativeValuesInParentheses && value < 0 || isRelativeVariance && encloseRelativeVarianceInParentheses;
    const labelValue = encloseNegativeValuesInParentheses && value < 0 ? Math.abs(value) : value;
    let label = formatting.getDataLabel(labelValue, decimalPlaces, displayUnits, locale, percentageFormat, hideUnits);
    if (isVariance && value > 0) {
        label = "+" + label;
    }
    if (isRelativeVariance && showPercentageSymbol) {
        label += "%";
    }
    if (encloseInParenthesis) {
        label = "(" + label + ")";
    }
    return label;
}

export function getVarianceDataLabel(value: number, decimalPlaces: number, displayUnits: string, locale: string, encloseNegativeValuesInParentheses: boolean, showPercentageSymbol: boolean,
    isRelativeVariance: boolean, encloseRelativeVarianceInParentheses: boolean, percentageFormat?: string, hideUnits: boolean = false): string {
    if (value == null) {
        return "";
    }
    return getFormattedDataLabel(value, decimalPlaces, displayUnits, locale, encloseNegativeValuesInParentheses, showPercentageSymbol, true, isRelativeVariance,
        encloseRelativeVarianceInParentheses, percentageFormat, hideUnits);
}

