import { SheetData } from "x-data-spreadsheet";

export const StructureComparisonBarSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Fruit" }, 1: { text: "AC" } } },
    1: { cells: { 0: { text: "Oranges" }, 1: { text: "170" } } },
    2: { cells: { 0: { text: "Lemons" }, 1: { text: "168" } } },
    3: { cells: { 0: { text: "Bananas" }, 1: { text: "150" } } },
    4: { cells: { 0: { text: "Kiwi" }, 1: { text: "140" } } },
    5: { cells: { 0: { text: "Apples" }, 1: { text: "100" } } },
    6: { cells: { 0: { text: "Melons" }, 1: { text: "120" } } },
    7: { cells: { 0: { text: "Pears" }, 1: { text: "130" } } },
    8: { cells: { 0: { text: "Grapes" }, 1: { text: "140" } } },
  },
};

export const StructureComparisonBarCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Oranges volume will be increased in the next quarter. Contracts signed with new suppliers in Southern Europe to meet the increased demand.",
        },
      },
    },
    3: {
      cells: {
        0: {
          text: "South American supply has dropped by 10% due to a temporary export ban.",
        },
      },
    },
    8: {
      cells: {
        0: { text: "The demand is gradually increasing just before spring/summer season. Evaluate new suppliers." },
      },
    },
  },
};

export const StructureComparisonDotSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Fruit" }, 1: { text: "AC" } } },
    1: { cells: { 0: { text: "Oranges" }, 1: { text: "170" } } },
    2: { cells: { 0: { text: "Lemons" }, 1: { text: "168" } } },
    3: { cells: { 0: { text: "Bananas" }, 1: { text: "150" } } },
    4: { cells: { 0: { text: "Kiwi" }, 1: { text: "140" } } },
    5: { cells: { 0: { text: "Apples" }, 1: { text: "100" } } },
    6: { cells: { 0: { text: "Melons" }, 1: { text: "120" } } },
    7: { cells: { 0: { text: "Pears" }, 1: { text: "130" } } },
    8: { cells: { 0: { text: "Grapes" }, 1: { text: "140" } } },
  },
};

export const StructureComparisonDotCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Oranges volume will be increased in the next quarter. Contracts signed with new suppliers in Southern Europe to meet the increased demand.",
        },
      },
    },
    3: {
      cells: {
        0: {
          text: "South American supply has dropped by 10% due to a temporary export ban.",
        },
      },
    },
    8: {
      cells: {
        0: { text: "The demand is gradually increasing just before spring/summer season. Evaluate new suppliers." },
      },
    },
  },
};

export const StructureSeriesComparisonBarSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Fruit" }, 1: { text: "AC" }, 2: { text: "PY" } } },
    1: { cells: { 0: { text: "Oranges" }, 1: { text: "170" }, 2: { text: "150" } } },
    2: { cells: { 0: { text: "Lemons" }, 1: { text: "168" }, 2: { text: "160" } } },
    3: { cells: { 0: { text: "Bananas" }, 1: { text: "150" }, 2: { text: "160" } } },
    4: { cells: { 0: { text: "Kiwi" }, 1: { text: "140" }, 2: { text: "170" } } },
    5: { cells: { 0: { text: "Apples" }, 1: { text: "138" }, 2: { text: "155" } } },
    6: { cells: { 0: { text: "Melons" }, 1: { text: "145" }, 2: { text: "160" } } },
    7: { cells: { 0: { text: "Pears" }, 1: { text: "120" }, 2: { text: "110" } } },
    8: { cells: { 0: { text: "Grapes" }, 1: { text: "110" }, 2: { text: "105" } } },
  },
};

export const StructureSeriesComparisonBarCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Oranges volume will be increased in the next quarter. Contracts signed with new suppliers in Southern Europe to meet the increased demand.",
        },
      },
    },
    3: {
      cells: {
        0: {
          text: "South American supply has dropped by 10% due to a temporary export ban.",
        },
      },
    },
    8: {
      cells: {
        0: { text: "The demand is gradually increasing just before spring/summer season. Evaluate new suppliers." },
      },
    },
  },
};
