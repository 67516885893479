export const WEB_APPLICATION_ID = "e27fca25-dafb-444c-af7c-180cdec6aba8";
export const LICENSING_SERVICE_URL = process.env.LICESING_SERVICE_URL;
export const UNLOCK_ALL_FEATURES_URL = "https://zebrabi.com/office-$host-$visual-unlock-features/"; // redirecta na /pricing/
export const LEARN_MORE_URL = "https://zebrabi.com/office-$host-$visual-learn-more/";
export const KNOWLEDGE_BASE_URL = "https://zebrabi.com/office-$host-$visual-kb/";
export const LICENSING_URL = "https://zebrabi.com/office-$host-$visual-licensing/";
export const TEMPLATES_URL = "https://zebrabi.com/office-$host-$visual-templates/";
export const STYLE_EDITOR_URL = "https://zebrabi.com/office-$host-$visual-style-editor/";
export const SETTING_GROUP_LICENSE = "ZBILicenseSettings";
export const ZEBRA_URL = `https://zebrabi.com/office-$host-$visual-zebrabi/`;
export const START_TRIAL_URL = "https://zebrabi.com/office-$host-$visual-start-trial/"

export enum PlanType {
    Null = "null",
    Free = "free",
    Trial = "trial",
    Pro = "pro",
    Template = "template"
}

