import { licensing } from "@zebrabi/licensing/Licensing";
import * as d3 from "d3";
import {ToolbarOptions} from "@zebrabi/global-toolbar/dist/lib/interface/ToolbarOption";
import { ChartSettings } from "../../settings/chartSettings";
import AboutSwitcherObserver from "../observers/AboutSwitcherObserver";
import { A, CLICK, D, DIV, LINE_BREAK, NONE, P, PATH } from "../../library/constants";
import { PRODUCT, VERSION } from "../../initialDefinitions";
import BaseSwitcherWithHeader from "./BaseSwitcherWithHeader";
import { KNOWLEDGE_BASE_URL, ZEBRA_URL } from "@zebrabi/licensing/constants";
import { Visual } from "../../visual";
import { flagHandler } from "@zebrabi/zebrabi-core";


class AboutSwitcher extends BaseSwitcherWithHeader {
    public static CLASS_NAME = "AboutSwitcher";

    toolbarOptions: ToolbarOptions = {
        actions: [],
        elementName: "About",
        icon: "about-icon-base64",
        type: "button"
    };

    private createdWithElement: d3.Selection<HTMLDivElement, any, any, any>;
    private authorElement: d3.Selection<HTMLDivElement, any, any, any>;

    constructor(private settings?: ChartSettings, private host?) {
        super();
    }

    public getClassName(): string {
        return AboutSwitcher.CLASS_NAME;
    }

    buttonAction(action: string, message: string): void {
        this.notifyAction("click", "", AboutSwitcherObserver.CLASS_NAME);
    }

    update(message: Map<string, any>): void {
        if (message.has("aboutinfo") && this.authorElement) {
            this.updateMenuItems();
        }
        return;
    }

    updateMenuItems() {
        let licenseHolderInfo = licensing.getOwnerUser() ?? licensing.getCurrentUser();

        if (licenseHolderInfo) { // Sanity check
            this.createdWithElement.text(licenseHolderInfo.getLicense()?.hasLicense ? "Pro license": "Free license");
            this.authorElement.text(licenseHolderInfo.getName());
        }
    }

    createMenuItems(switcherMenuContainer: HTMLElement, active: string = "") {
        super.createMenuItems(switcherMenuContainer, active);

        const body = switcherMenuContainer.querySelector(".body");
        const licenseDiv = document.createElement(DIV);
        const licenseDivSelection = d3.select(licenseDiv).classed("license-div", true);
        body.appendChild(licenseDiv);

        const innerLicenseDivSelection = licenseDivSelection.append(DIV).classed("inner-license", true);
        const iconDiv = innerLicenseDivSelection.append(DIV).classed("license-info-block", true);
        iconDiv.append(DIV).classed("about-logo", true);
        iconDiv.append(DIV).text("Zebra BI Charts for Office").classed("license-visual-text", true);

        const licenseInfoDiv = innerLicenseDivSelection.append(DIV).classed("license-info-block", true);
        licenseInfoDiv.append(DIV).text("Version:").classed("license-type-text-bold", true);
        licenseInfoDiv.append(DIV).text(VERSION).classed("license-type-text", true);

        licenseInfoDiv.append(DIV).text("Created with:").classed("license-type-text-bold", true);
        this.createdWithElement = licenseInfoDiv.append(DIV).classed("license-type-text", true);

        licenseInfoDiv.append(DIV).text("Author:").classed("license-type-text-bold", true);
        this.authorElement = licenseInfoDiv.append(DIV).classed("license-type-text", true);

        this.updateMenuItems();

        const url = ZEBRA_URL.replace("$host", Office.context.host === Office.HostType.Excel ? "excel" : "powerpoint").replace("$visual", "charts");
        const footer = licenseDivSelection.append(DIV).classed("license-footer", true);
        const zebraAnchor = footer.append(A)
            .attr("href", url)
            .attr("target", "_blank");

        zebraAnchor.append(DIV).classed("copyright", true);
        zebraAnchor.append(P).text("2023 Zebra BI").classed("help-text", true);
        footer.append(DIV).text("|").classed("separator", true);

        const diagnosticInfo = footer.append(DIV);
        diagnosticInfo.text("Diagnostics");
        diagnosticInfo.classed("diagnostics-text", true);
        diagnosticInfo.on(CLICK, () => {
            const diagnosticsDiv = this.createDiagnosticsDiv();
            Visual.element.appendChild(diagnosticsDiv);
            diagnosticsDiv.focus();
        });
        footer.append(DIV).text("|").classed("separator", true);

        const kbUrl = KNOWLEDGE_BASE_URL.replace("$host", Office.context.host === Office.HostType.Excel ? "excel" : "powerpoint").replace("$visual", "charts");
        const helpAnchor = footer.append(A).attr("href", kbUrl).attr("target", "_blank");
        helpAnchor.append(DIV).classed("question-mark", true);
        helpAnchor.append(P).text("Help").classed("help-text", true);
    }

    createDiagnosticsDiv(): HTMLElement {
        const diagnosticsDiv = document.createElement(DIV);
        const diagnosticsDivSelection = d3.select(diagnosticsDiv).classed("diagnostics-div", true);
        const diagnosticInfoTitle = diagnosticsDivSelection.append("span");
        diagnosticInfoTitle.text("Diagnostic info");
        const diagnosticInfoTextArea = diagnosticsDivSelection.append("textarea");
        diagnosticInfoTextArea.attr("readonly", true);
        const diagnosticInfoText = this.getDebuggingInfo();
        diagnosticInfoTextArea.text(diagnosticInfoText);
        const closeCross = diagnosticsDivSelection.append("svg").classed("close-cross", true);

        closeCross.append(PATH).attr(D, "M0.0,0.0,12.0,12.0");
        closeCross.append(PATH).attr(D, "M0.0,12.0,12.0,0.0");
        closeCross.on(CLICK, () => {
            diagnosticsDiv.style.display = NONE; // TODO make this a close method in general for the diag modal
            if (Visual.element.contains(diagnosticsDiv)) {
                Visual.element.removeChild(diagnosticsDiv);
            }
        });

        return diagnosticsDiv;
    }

    getDebuggingInfo(): string {
        const doubleLineBreak = LINE_BREAK + LINE_BREAK;
        let debugInfo = PRODUCT + " " + VERSION;

        debugInfo += doubleLineBreak + "Host properties: " + JSON.stringify({
            host: Office.context.diagnostics.host,
            platform: Office.context.diagnostics.platform,
            version: Office.context.diagnostics.version,
        }, null, 3);
        if (Visual.dataView) {
            const dataView = Visual.dataView;
            const columnsData = "Columns: " + JSON.stringify(
                dataView.metadata.columns.map(col => {
                    return {
                        displayName: col.displayName,
                        roles: col.roles,
                        isMeasure: col.isMeasure,
                        format: col.format,
                    };
                }), null, 3);
            debugInfo += doubleLineBreak + columnsData;
        }
        else {
            debugInfo += doubleLineBreak + "No dataView";
        }

        const flagInfo = "Flags: " + JSON.stringify(flagHandler.getAllFlags(), null, 3);
        debugInfo += doubleLineBreak + flagInfo;

        const currentUser = licensing.getCurrentUser();
        const currentUserInfoString = "Current user info: " + JSON.stringify({
            isKnown: currentUser.isKnown(),
            name: currentUser.getName(),
            email: currentUser.getEmail(),
            id: currentUser.getUserID(),
            orgId: currentUser.getOrganizationID(),
            hasLicense: currentUser.getLicense()?.hasLicense,
            validFrom: currentUser.getValidFrom()?.toDateString(),
            validTo: currentUser.getValidTo()?.toDateString(),
        }, null, 3);
        debugInfo += doubleLineBreak + currentUserInfoString;

        const ownerUser = licensing.getOwnerUser();
        const creatorUserInfoString = "Creator user info: " + JSON.stringify({
            isKnown: ownerUser.isKnown(),
            name: ownerUser.getName(),
            email: ownerUser.getEmail(),
            hasLicense: ownerUser?.getLicense()?.hasLicense,
        }, null, 3);
        debugInfo += doubleLineBreak + creatorUserInfoString;

        const settings = Office.context.document.settings.get("ChartSettings");
        const settingsString = "Settings: " + JSON.stringify({ ...settings }, null, 3);
        debugInfo += doubleLineBreak + settingsString;

        debugInfo += LINE_BREAK;
        return debugInfo;
    }

    getScrollPosition(): number {
        return 0;
    }
}

export default AboutSwitcher;
