import { ActionType } from "../ActionType";
import { OverlayConfig } from "../interfaces/OverlayConfig";
import ActionObserver from "../observers/ActionObserver";
import ActionSubject from "../subject/ActionSubject";
import { signInOverlay } from "../template/overlaySignIn";

class Overlay extends HTMLElement {
  protected template = signInOverlay;
  public actionSubject: ActionSubject = new ActionSubject();

  private _overlayConfig: OverlayConfig = {
    confirm: "",
    buy: "",
    copy: "",
    footer: { link: "", text: "" },
    signIn: "",
    trial: "",
  };

  get overlayConfig() {
    return this._overlayConfig;
  }

  set overlayConfig(overlayConfig: OverlayConfig) {
    this._overlayConfig = overlayConfig;
    this.render(overlayConfig);
  }

  protected render(config: OverlayConfig) {
    this.close();
    this.textContent = "";

    const range = document.createRange();
    const overlayTemplate = range.createContextualFragment(this.template(config));

    this.append(overlayTemplate);

    this.querySelector(".buy")?.addEventListener("click", () => {
      this.actionSubject.notify(ActionType.Buy);
    });

    this.querySelector(".admin")?.addEventListener("click", () => {
      this.close();
      this.actionSubject.notify(ActionType.Admin);
    });

    this.querySelector(".trial")?.addEventListener("click", () => {
      this.close();
      this.actionSubject.notify(ActionType.Trial);
    });
  }

  public close() {
    this.parentNode?.removeChild(this);
  }

  public attach(observer: ActionObserver) {
    this.actionSubject.attach(observer);
  }
}

if (customElements.get("zebra-overlay") === undefined) {
  customElements.define("zebra-overlay", Overlay);
}

export default Overlay;
