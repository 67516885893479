import React from "react";
import ReactDOM from "react-dom/client";
import { ChartChooserScreen } from "./screen";

const ROOT_ELEMENT_ID = "chart-chooser-react";

function addRootDOMElement() {
  const tableDataEditorRoot = document.createElement("div");
  tableDataEditorRoot.setAttribute("id", ROOT_ELEMENT_ID);
  document.body.prepend(tableDataEditorRoot);
}

export function mountReactChartChooser() {
  addRootDOMElement();
  const reactRoot = ReactDOM.createRoot(document.getElementById(ROOT_ELEMENT_ID) as HTMLElement);
  reactRoot.render(
    <React.StrictMode>
      <ChartChooserScreen />
    </React.StrictMode>
  );
}
