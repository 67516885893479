import { quantile } from "d3-array";

export function outliersCalculation(values: number[]): number[] {
    const sortedValues = values.filter(v => v).map(v => Math.abs(v)).sort((a, b) => a - b);
    if (sortedValues.length < 4) {
        return sortedValues.length ? [-sortedValues[sortedValues.length - 1], sortedValues[sortedValues.length - 1]] : [0, 0];
    }
    const factor = 1.5;
    const q1 = quantile(sortedValues, 0.25);
    const q3 = quantile(sortedValues, 0.75);
    const iqr = q3 - q1;
    let maxValue = q3 + iqr * factor;
    for (let i = 0; i < sortedValues.length; i++) {
        if (sortedValues[i] > maxValue && i > 0) {
            maxValue = sortedValues[i - 1];
            break;
        }
        else if (i === sortedValues.length - 1) {
            maxValue = sortedValues[i];
        }
    }
    return [-maxValue, maxValue];
}
