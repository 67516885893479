import { LabelAlignment } from "../library/types";
import { ChartData, DataPoint } from "./../interfaces";
import { LabelProperties } from "./../library/interfaces";
import { ChartType, DifferenceHighlightFromTo } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { Visual } from "./../visual";
import { addWaterfallFixedColumnsMouseHandlers, labelDensityFilterWaterfall } from "./waterfallChart";

import {
    Scenario, DifferenceLabel, FILL, HIGHLIGHTABLE, RECT, G, BAR, ITALIC, FONT_STYLE, PATTERNED, STROKE, LINE, NONE,
    AXIS_SCENARIO_DELIMITER, GRAY, NORMAL, START, END, AXIS, HEIGHT, WIDTH, X, Y, X1, X2, Y1, Y2, STROKE_WIDTH, POINTER_EVENTS
} from "./../library/constants";
import {
    CHART_CONTAINER, VALUE, START_POSITION, VALUE_GRADIENT, VALUE_GRADIENT_NEGATIVE, REFERENCE_GRADIENT, REFERENCE_GRADIENT_NEGATIVE,
    WHITE_REVERSE_GRADIENT, WHITE_REVERSE_GRADIENT_NEGATIVE, SECOND_VALUE_GRADIENT, SECOND_VALUE_GRADIENT_NEGATIVE,
} from "./../consts";

import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as viewModels from "./../viewModel/viewModelHelperFunctions";
import * as charting from "./chart";
import * as d3 from "../d3";
import { mapAdditionalLabelsWithOriginal } from "../helpers";

// eslint-disable-next-line max-lines-per-function
export default function verticalWaterfallChart(reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement, x: number, y: number, height: number, width: number,
    chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number, isSingleSeries: boolean,
    plotTitle: boolean, plotLegend: boolean, plotCategories: boolean, leftMargin: number, rightMargin: number, leftMarginCategories: number) {
    const scenarioOptions = settings.scenarioOptions;
    const chartXPos = x;
    const chartYPos = y + topMargin;
    const chartHeight = height - topMargin - bottomMargin;
    const dataLabelsMargin = settings.showDataLabels ? rightMargin : 0;
    const categoriesMargin = plotCategories ? leftMargin : 1;

    const yScale = charting.getOrdinalScale(chartData, chartYPos, chartHeight, settings.getGapBetweenColumns());
    const yScaleRangeBand = yScale.bandwidth();
    const yScaleLines = charting.getOrdinalScale(chartData, chartYPos, chartHeight, 0);
    const xScale = charting.getLinearScale(min, max, x + categoriesMargin, x + width - (dataLabelsMargin));

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, chartXPos + categoriesMargin, y,
        charting.getChartTitleWidth(width - categoriesMargin, settings), height, max, min, topMargin, bottomMargin);
    if (settings.showCategories && plotCategories) {
        charting.plotVerticalCategories(chartContainer, settings, chartData, x, yScale, leftMarginCategories, chartIndex);

        if (settings.showTopNCategories) {
            const othersCategoryDOMElements = chartContainer.selectAll(`.${viewModels.escapeCharacter(settings.topNOtherLabel)}`);
            const selectionIds = [];
            chartData.otherCategoryDataPoints.forEach(category => {
                selectionIds.push(category.selectionId);
            });
        }
    }

    if (!settings.hasAxisBreak || chartData.axisBreak === 0) {
        drawing.drawLine(chartContainer, xScale(0), xScale(0), chartYPos, chartYPos + chartHeight, 1, settings.colorScheme.axisColor, AXIS);
    }

    let bars = chartContainer
        .selectAll(`.${BAR}` + chartIndex)
        .data(chartData.dataPoints);
    const rect = bars.enter()
        .append(RECT)
        .classed(BAR, true)
        .classed(HIGHLIGHTABLE, dp => dp.isVariance)
        // Add a postfix to "category highlightable" class with the name of the topNCategoryOtherLabel in order to aim drawSingleOtherArrow function at specific category
        .classed(settings.topNOtherLabel, (d) => d.category === settings.topNOtherLabel && settings.showTopNCategories);
    bars = bars.merge(rect)
        .attr(HEIGHT, yScaleRangeBand)
        .attr(WIDTH, d => Math.abs(xScale(d.value) - xScale(0)))
        .attr(X, d => d.isVariance ?
            (xScale(d.startPosition - d.value)) :
            (d.isNegative ? xScale(d.value) : xScale(0)))
        .attr(Y, d => yScale(d.category));
    styles.applyToBars(bars.filter(b => !b.isVariance), (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme);

    styles.applyToBars(bars.filter(b => b.isVariance), (d: DataPoint) => d.color, scenarioOptions.valueScenario, settings.chartStyle, true, settings.colorScheme);

    const secondSegmentValueBars = bars.filter((p) => p.secondSegmentValue !== null);
    const secondSegmentDataPoints = chartData.dataPoints.filter(dp => dp.secondSegmentValue !== null && dp.isVariance);
    if (secondSegmentDataPoints.length > 0) {
        styles.applyToBars(secondSegmentValueBars.filter(b => !b.isVariance), (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenarioOptions.secondValueScenario, settings.chartStyle, false, settings.colorScheme);
        styles.applyToBars(secondSegmentValueBars.filter(b => b.isVariance), (d: DataPoint) => d.color, scenarioOptions.secondValueScenario, settings.chartStyle, true, settings.colorScheme);
        const yAxisDelimiter = yScale(secondSegmentDataPoints[0].category) - yScale.bandwidth() / 8;
        drawing.drawLine(chartContainer, chartXPos, xScale(0) + 20, yAxisDelimiter, yAxisDelimiter, 1, GRAY, AXIS_SCENARIO_DELIMITER);
    }

    const referenceSumBar = bars.filter((dp, i) => !dp.isVariance && i === 0);
    referenceSumBar.classed(PATTERNED, false);
    styles.applyToBars(referenceSumBar, (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenarioOptions.referenceScenario, settings.chartStyle, false, settings.colorScheme);

    // Custom scenario notation
    const existingScenarioCategories = Object.keys(Object.assign({}, ...settings.scenarioCategories));
    existingScenarioCategories.map(c => {
        const bar = bars.filter(b => !b.isVariance && b.isCategoryResult && b.category === c);
        const scenario = settings.scenarioCategories ? settings.scenarioCategories[existingScenarioCategories.indexOf(c)][c] : scenarioOptions.valueScenario;
        styles.applyToBars(bar, (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenario, settings.chartStyle, false, settings.colorScheme);
    });

    if (settings.hasAxisBreak && chartData.axisBreak !== 0) {
        const isBreakNegative = chartData.axisBreak < 0;
        let valueGradient = isBreakNegative ? VALUE_GRADIENT_NEGATIVE : VALUE_GRADIENT;
        const referenceGradient = isBreakNegative ? REFERENCE_GRADIENT_NEGATIVE : REFERENCE_GRADIENT;
        const whiteOverlayGradient = isBreakNegative ? WHITE_REVERSE_GRADIENT_NEGATIVE : WHITE_REVERSE_GRADIENT;
        const valueSumBar = bars.filter((dp, i) => !dp.isVariance && i !== 0);

        if (scenarioOptions.referenceScenario === Scenario.Forecast) {
            const dataPoints = chartData.dataPoints.filter((dp, i) => i === 0);
            addForecastOverlayBar(chartContainer, dataPoints, "bar-over-fc-reference", yScale, xScale, whiteOverlayGradient);
        }
        else {
            referenceSumBar.attr(FILL, `url(#${referenceGradient})`);
        }

        if (scenarioOptions.valueScenario === Scenario.Forecast || scenarioOptions.secondValueScenario === Scenario.Forecast) {
            const dataPoints = chartData.dataPoints.filter((dp, i) => i === chartData.dataPoints.length - 1);
            addForecastOverlayBar(chartContainer, dataPoints, "bar-over-fc-value", yScale, xScale, whiteOverlayGradient);
        }
        else {
            const isCategoryHighlighted: boolean = settings.highlightedCategories.some(category => category === settings.actual || category === settings.previousYear);
            chartData.isGroupHighlighted ? valueSumBar.attr(FILL, `url(#${chartData.highlightGroupColor})`) : valueSumBar.attr(FILL, `url(#${valueGradient})`);
            settings.highlightedCategoriesCustomColors.find(category => {
                if (isCategoryHighlighted) {
                    if (scenarioOptions.valueScenario === Scenario.Actual) {
                        valueSumBar.attr(FILL, `url(#${category[settings.actual] ?? "#0070C0"}-AC)`);
                    }
                    if (scenarioOptions.valueScenario === Scenario.PreviousYear) {
                        valueSumBar.attr(FILL, `url(#${category[settings.previousYear] ?? "#0070C0"}-PY)`);
                    }
                }
            });
        }

        if (scenarioOptions.valueScenario === Scenario.Plan || scenarioOptions.valueScenario === Scenario.Forecast || scenarioOptions.secondValueScenario === Scenario.Forecast) {
            if (scenarioOptions.secondValueScenario === Scenario.Forecast) {
                valueGradient = isBreakNegative ? SECOND_VALUE_GRADIENT_NEGATIVE : SECOND_VALUE_GRADIENT;
            }
            valueSumBar.attr(STROKE, `url(#${valueGradient})`);
        }

        if (scenarioOptions.referenceScenario === Scenario.Plan || scenarioOptions.referenceScenario === Scenario.Forecast) {
            referenceSumBar.attr(STROKE, `url(#${referenceGradient})`);
        }
    }

    let linesData = chartData.dataPoints.slice(0, chartData.dataPoints.length - 1);
    if (isSingleSeries) {
        linesData = linesData.filter((d, i) => !d.isVariance && chartData.dataPoints[i + 1].isVariance || chartData.dataPoints[i + 1].isVariance
            || Math.round(d.startPosition - (d.isNegative ? d.value : 0)) === Math.round(chartData.dataPoints[i + 1].startPosition));
    }
    else {
        linesData = linesData.filter((dp, i, arr) => { return i === arr.length - 1 || arr[i + 1].value !== null; });
    }
    let lines = chartContainer
        .selectAll(".line" + chartIndex)
        .data(linesData);
    const line = lines.enter()
        .append(LINE)
        .classed(LINE, true);
    lines = lines.merge(line)
        .attr(X1, d => d.isNegative ? xScale(d.isVariance ? d.startPosition - d.value : d.value) : xScale(d.startPosition))
        .attr(X2, d => d.isNegative ? xScale(d.isVariance ? d.startPosition - d.value : d.value) : xScale(d.startPosition))
        .attr(Y1, d => yScale(d.category))
        .attr(Y2, d => yScale(d.category) + yScaleLines.bandwidth() + yScaleRangeBand)
        .attr(STROKE_WIDTH, 1)
        .attr(STROKE, "grey");
    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    const varianceLabelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);
    const hideUnits = settings.shouldHideDataLabelUnits();

    if (settings.showDataLabels) {
        const labelsProperties = getWaterfallLabelProperties(chartData.dataPoints, settings, labelsFormat, varianceLabelsFormat, hideUnits,
            yScale, xScale, chartData.axisBreak);

        if (labelsProperties.length > 0) {
            const varianceCalculationChange = !isSingleSeries && settings.getRealInteractionSettingValue(settings.allowVarianceCalculationChange);
            const varianceLabels = charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings, NORMAL, varianceCalculationChange).filter(d => d.isVariance);
            let additionalLabels: d3.Selection<any, LabelProperties, any, any> = null;

            if (settings.varianceLabel === DifferenceLabel.Relative) {
                varianceLabels.style(FONT_STYLE, ITALIC);
            }
            else if (settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute) {
                const additionalVarianceLabelsProperties = getAdditionalLabelProperties(chartData.dataPoints, settings, yScale, xScale, y);
                mapAdditionalLabelsWithOriginal(additionalVarianceLabelsProperties, varianceLabels, settings.labelFontSize);
                additionalLabels = charting.plotLabels(chartContainer, `${chartIndex}_2`, additionalVarianceLabelsProperties, settings, ITALIC, varianceCalculationChange);
            }

            if (varianceCalculationChange && Visual.animateVarianceLabels) {
                charting.animateLabels(varianceLabels, additionalLabels, settings.labelFontSize);
            }
        }
    }

    if (settings.differenceHighlight && chartData.dataPoints.length > 0) {
        let { fromDataPoint, toDataPoint } = charting.getDiffHighlightAutoDataPoints(chartData.dataPoints, ChartType.Waterfall, false);
        if (settings.differenceHighlightFromTo !== DifferenceHighlightFromTo.Auto) {
            if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.LastACToLastFC && secondSegmentDataPoints.length !== 0) {
                fromDataPoint = chartData.dataPoints[chartData.dataPoints.length - secondSegmentDataPoints.length - 2];
                toDataPoint = secondSegmentDataPoints[secondSegmentDataPoints.length - 1];
            }
            else {
                const diffHighlightDataPoints = settings.isSecondSegmentDiffHighlight() ? secondSegmentDataPoints : chartData.dataPoints.filter((p) => p.secondSegmentValue === null && p.isVariance);
                const fromToDataPoints = charting.getDiffHighlightDataPoints(diffHighlightDataPoints, settings.differenceHighlightFromTo, true);
                fromDataPoint = fromToDataPoints.fromDP;
                toDataPoint = fromToDataPoints.toDP;
            }
        }

        const valueProperty: keyof DataPoint = isSingleSeries || settings.differenceHighlightFromTo > 0 ? START_POSITION : VALUE;
        if (fromDataPoint && toDataPoint && toDataPoint[valueProperty] !== null && fromDataPoint[valueProperty] !== null) {
            const endY =
                yScale(chartData.dataPoints[chartData.dataPoints.length - 1].category) +
                yScale.bandwidth() / 2 +
                yScale.step();

            let startValue = fromDataPoint[valueProperty];
            let endValue = toDataPoint[valueProperty];
            if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.MinToMax && fromDataPoint.isVariance) {
                startValue -= fromDataPoint.value;
            }
            const dhX1 = xScale(endValue);
            const dhX2 = xScale(startValue);
            if (settings.hasAxisBreak) {
                startValue += chartData.axisBreak;
                endValue += chartData.axisBreak;
            }
            const dhY1 = yScale(toDataPoint.category);
            const dhY2 = yScale(fromDataPoint.category);
            charting.addVerticalDifferenceHighlight(chartContainer, settings, slider, endY, dhX1, dhX2, endValue, startValue, dhY1, dhY2, chartData.isInverted);
        }
    }

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, settings);
        }
    }

    charting.addMouseHandlers(bars.filter(dp => dp.isVariance), svg);
    addWaterfallFixedColumnsMouseHandlers(bars.filter(dp => !dp.isVariance), chartContainer, settings, slider);

    let tooltipDataPoints = chartData.dataPoints;
    const effectiveAxisBreak = settings.hasAxisBreak && chartData.axisBreak !== null ? chartData.axisBreak : 0;
    if (settings.hasAxisBreak) {
        tooltipDataPoints = JSON.parse(JSON.stringify(chartData.dataPoints));
        tooltipDataPoints.filter(p => !p.isVariance).forEach(dp => dp.value = dp.value + chartData.axisBreak);
    }

    bars.exit().remove();
    lines.exit().remove();
}

function getWaterfallLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, varianceLabelsFormat: string, hideUnits: boolean,
    ordinalScale: d3.ScaleBand<string>, linearScale: d3.ScaleLinear<number, number>, axisBreak: number): LabelProperties[] {
    const categoryRangeBand = ordinalScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(d => d.value !== null).filter(labelDensityFilterWaterfall, settings);
    const locale = Office.context.contentLanguage || "en-US";
    const labelProperties = labelsDataPoints.map(d => {
        let labelValue = settings.hasAxisBreak && !d.isVariance ? d.value + axisBreak : d.value;
        const labelFormat = d.isVariance ? varianceLabelsFormat : labelsFormat;
        let labelText = "";
        if (!d.isVariance) {
            labelText = charting.getFormattedDataLabel(labelValue, settings.decimalPlaces, settings.displayUnits, settings.locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelFormat, hideUnits);
        }
        else {
            if (settings.varianceLabel === DifferenceLabel.Relative) {
                const orgValue = d.originalValue !== null ? d.reference + d.value * (d.isNegative ? -1 : 1) : null;
                const relDiffPercent = viewModels.calculateRelativeDifferencePercent(orgValue, d.reference);
                labelValue = relDiffPercent === null ? 0 : (d.isCategoryInverted ? -1 : 1) * relDiffPercent;
                labelText = relDiffPercent === null ? "" : charting.getRelativeVarianceLabel(settings, labelValue, false);
            }
            else {
                const isNegative = d.isNegative !== d.isCategoryInverted;
                labelValue = (isNegative ? -1 : 1) * labelValue;
                labelText = charting.getVarianceDataLabel(labelValue, settings.decimalPlaces, settings.displayUnits,
                    locale, settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelFormat, hideUnits);
            }
        }

        const yPos = ordinalScale(d.category) + categoryRangeBand * 0.5 +
            (d.isVariance && settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute ? -5 : settings.labelFontSize / 2 - 1);
        const plotLabelLeft = d.isNegative;
        const xPos = plotLabelLeft ? linearScale(d.isVariance ? d.startPosition - d.value : d.value) - 5 :
            linearScale(d.isVariance ? d.startPosition : d.value) + 5;
        const alignment: LabelAlignment = plotLabelLeft ? END : START;
        return charting.getLabelProperty(labelText, xPos, yPos, d.isVariance ? null : true, labelValue, d, settings.labelFontSize, settings.labelFontFamily, d.isVariance,
            null, null, alignment, settings.varianceLabel === DifferenceLabel.Relative);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, categoryRangeBand);
}

function getAdditionalLabelProperties(dataPoints: DataPoint[], settings: ChartSettings,
    ordinalScale: d3.ScaleBand<string>, linearScale: d3.ScaleLinear<number, number>, y: number): LabelProperties[] {
    const rangeBand = ordinalScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(d => d.value !== null)
        .filter(labelDensityFilterWaterfall, settings)
        .filter(d => d.isVariance);
    const labelProperties = labelsDataPoints.map(d => {
        const orgValue = d.originalValue !== null ? d.reference + d.value * (d.isNegative ? -1 : 1) : null;
        const relDiffPercent = viewModels.calculateRelativeDifferencePercent(orgValue, d.reference);
        const labelValue = relDiffPercent === null ? 0 : (d.isCategoryInverted ? -1 : 1) * relDiffPercent;
        const labelText = relDiffPercent === null ? "" : charting.getRelativeVarianceLabel(settings, labelValue, true);
        // let yPos = ordinalScale(d.category) + rangeBand - settings.labelFontSize / 2 + 3;
        const yPos = ordinalScale(d.category) + rangeBand * 0.5 + settings.labelFontSize / 2 + 3;
        const plotLabelLeft = d.isNegative;
        const xPos = plotLabelLeft ? linearScale(d.isVariance ? d.startPosition - d.value : d.value) - 5 :
            linearScale(d.isVariance ? d.startPosition : d.value) + 5;
        const alignment: LabelAlignment = plotLabelLeft ? END : START;

        return charting.getLabelProperty(labelText, xPos, yPos, null, labelValue, d, settings.labelFontSize, settings.labelFontFamily, d.isVariance, null, null, alignment, true);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, rangeBand);
}

function addForecastOverlayBar(reportArea: d3.Selection<SVGElement, any, any, any>, dataPoints: DataPoint[], selector: string,
    ordinalScale: d3.ScaleBand<string>, linearScale: d3.ScaleLinear<number, number>, gradient: string) {
    let barOverFC = reportArea
        .selectAll(selector)
        .data(dataPoints);
    const rect = barOverFC.enter()
        .append(RECT)
        .classed(BAR, true);

    barOverFC = barOverFC.merge(rect)
        .attr(HEIGHT, ordinalScale.bandwidth())
        .attr(WIDTH, d => Math.abs(linearScale(d.value) - linearScale(0)))
        .attr(X, d => linearScale(d.isNegative ? d.value : 0))
        .attr(Y, d => ordinalScale(d.category));
    barOverFC.attr(FILL, `url(#${gradient})`);
    barOverFC.attr(POINTER_EVENTS, NONE);
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>, settings: ChartSettings) {
    const scaleBandWidth = yScale.bandwidth();
    const markerAttrs = charting.getCommentMarkerAttributes(scaleBandWidth);
    const getMarkerYPosition = (d: DataPoint): number => yScale(d.category) + scaleBandWidth / 2;
    const getMarkerXPosition = (d: DataPoint): number => d.isNegative ? xScale(d.startPosition) + (markerAttrs.radius + markerAttrs.margin) :
        xScale(d.startPosition - d.value) - (markerAttrs.radius + markerAttrs.margin);
    charting.addCommentMarkers(container, commentDataPoints, getMarkerXPosition, getMarkerYPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}
