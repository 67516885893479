export interface DataLinkListener {
  event(event: string, data: any);
}

class DataLinkingEventBus {
  private listeners: DataLinkListener[] = [];
  private static _instance: DataLinkingEventBus;

  public static createInstance(): DataLinkingEventBus {
    if (!DataLinkingEventBus._instance) {
      DataLinkingEventBus._instance = new DataLinkingEventBus();
    }
    return DataLinkingEventBus._instance;
  }

  public subscribe(listener: DataLinkListener) {
    this.listeners.push(listener);
  }

  public unsubscribe(listener: DataLinkListener) {
    if (this.listeners.includes(listener)) {
      this.listeners.splice(this.listeners.indexOf(listener), 1);
    }
  }

  public dispatch = (event, data) => {
    for (const listener of this.listeners) {
      listener.event(event, data);
    }
  };
}

export const dataLinkingEventBus = DataLinkingEventBus.createInstance();
