export const globalToolbar = "<header class=\"global-toolbar\">\n" +
    "    <div class=\"activator\">\n" +
    "        <div class=\"icon chevron-icon-base64\"></div>" +
    "        <div class=\"logo zebra-logo-icon-base64\"></div>" +
    "    </div>\n" +
    "    <div class=\"global-controls-container\">\n" +
    "        <div class=\"controls\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</header>\n";
