import React, { useEffect } from "react";
import useStore from "../useStore";
import SelectionToggler from "./components/SelectionToggler";
import FullScreenLoader from "./components/FullScreenLoader";

export default function ExcelDataLinkingScreen(props) {
  const [, setAccessToken] = useStore("accessToken");

  useEffect(() => {
    setAccessToken(props.accessToken);
  }, []); // Run once when the component mounts

  return (
    <div className={`excel-data-linking-wrapper`}>
      <FullScreenLoader />

      <SelectionToggler />
    </div>
  );
}
