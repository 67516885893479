import React from "react";
import useStore from "../../useStore";
import arrowLeftIcon from "../images/arrow-left.svg";

export default function BackToFileSelection() {
  const [, setCurrentView] = useStore("currentView");

  return (
    <button
      className="back-to-file-selection"
      onClick={() => {
        setCurrentView("file-selection");
      }}
    >
      <img src={arrowLeftIcon} alt="Back" />
    </button>
  );
}
