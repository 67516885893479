// Chart layout options
export const ABSOLUTE = "Absolute";
export const RELATIVE = "Relative";
export const INTEGRATED = "Integrated";
export const RESPONSIVE = "Responsive";
export const WATERFALL = "Waterfall";
export const ACTUAL = "Actual";
export const ACTUAL_ABSOLUTE = "Actual / Absolute";
export const ACTUAL_RELATIVE = "Actual / Relative";
export const ABSOLUTE_RELATIVE = "Absolute / Relative";

export const MULTIPLE_LAYOUT = "multipleLayout";
export const MULTIPLE_MARGIN = 10;
// multiples layout options
export const AUTO_LAYOUT = "Auto";
export const ROWS_LAYOUT = "Rows";
export const LARGEST_FIRST_LAYOUT = "LargestFirst";

// these are not used anymore, here only for migration purpose
export const AUTO_ASCENDING_LAYOUT = "AutoAscending";
export const ORIGINAL_ORDER_LAYOUT = "OriginalOrder";
export const ROWS_ASCENDING_LAYOUT = "RowsAscending";

// Data properties
export const VALUE = "value";
export const REFERENCE = "reference";
export const SECOND_SEGMENT_VALUE = "secondSegmentValue";
export const SECOND_REFERENCE = "secondReference";
export const START_POSITION = "startPosition";
export const SECOND_ACTUAL_VALUE = "secondActualValue";

// Difference highlights
export const MIN_SUBTOTALS = 3;

// Waterfall gradients
export const VALUE_GRADIENT = "value-gradient";
export const VALUE_GRADIENT_NEGATIVE = "value-gradient_negative";
export const WHITE_REVERSE_GRADIENT = "white-reverse-gradient";
export const WHITE_REVERSE_GRADIENT_NEGATIVE = "white-reverse-gradient_negative";
export const REFERENCE_GRADIENT = "reference-gradient";
export const REFERENCE_GRADIENT_NEGATIVE = "reference-gradient_negative";
export const SECOND_VALUE_GRADIENT = "socond-value-gradient";
export const SECOND_VALUE_GRADIENT_NEGATIVE = "second-value-gradient_negative";
export const AREA_VALUE_GRADIENT = "area-value-gradient";
export const AREA_VALUE_GRADIENT_NEGATIVE = "area-value-gradient_negative";

// Other
export const CHART_CONTAINER = "chart-container";
export const CHART_CONTAINER_RECT = "chart-container-rect";
export const MOUSE_OVER_DROPLINE = "mouse-over-drop-line";
export const SELECTION_DROPLINE = "selection-drop-line";

export const LINE_BREAK_SEPARATOR = "\n";
export const AXIS_BREAK_SYMBOL = "axis-break-symbol";
export const USEDEBUGGING = false;
export const SHOW_WATERMARK = false;
export const HORIZONTAL_LABEL_PADDING: number = 1;

export const STACKED_CHART_SETTINGS = "stackedChartSettings";

export const INFO_BOX_CONTAINER = "zebrabi-info-container";
