import React from "react";
import { MainDimension } from "../../types";

type Props = {
  dimension: MainDimension;
  isActive?: boolean;
  onActive: (dimension: MainDimension) => void;
};

export default function ChartDimensionItem({ dimension, isActive = false, onActive }: Props) {
  const { title, image } = dimension;

  const handleActive = () => onActive(dimension);

  return (
    <div
      className={`dimension-item cursor-pointer rounded-sm mx-3.5 relative ${isActive ? "active-card" : ""}`}
      onClick={handleActive}
    >
      {isActive && <div className="active-blue-line"></div>}
      <img className="h-5 p" src={image} alt={title} />
      <h1 className="text-xs whitespace-nowrap">{title}</h1>
    </div>
  );
}
