import {
    DropdownSubscriber,
    NumberInputSubscriber, TextInputSubscriber,
    ToggleButtonData,
    ToggleButtonSubscriber
} from "@zebrabi/design-library";
import Subscriber from "@zebrabi/design-library/dist/lib/events/Subscriber";
import { GlobalStackedChartSettings, ShowTopNChartsOptions, Sort } from "../../enums";
import { Visual } from "../../visual";

export default class GlobalStackedChartMenuItemSubscriberFactory {
    private chartSettings = Visual.settings;

    public getSubscriber(menuItem: GlobalStackedChartSettings): Subscriber<any> {
        switch (menuItem) {
            case GlobalStackedChartSettings.showStackedLabelsAsPercentage: {
                const labelsAsPercentageSub = new ToggleButtonSubscriber();
                labelsAsPercentageSub.update = (message: ToggleButtonData): void => {
                    this.chartSettings.persistGlobalStackedChartSettings({ showStackedLabelsAsPercentage: message.value });
                };
                return labelsAsPercentageSub;
            }
            case GlobalStackedChartSettings.showTopNStackedOptions: {
                const topNSub = new DropdownSubscriber<ShowTopNChartsOptions>();
                topNSub.update = (message: ShowTopNChartsOptions, action?: string): void => {
                    this.chartSettings.persistGlobalStackedChartSettings({ showTopNStackedOptions: message });
                };
                return topNSub;
            }
            case GlobalStackedChartSettings.topNStackedToKeep: {
                const topNtoKeepSub = new NumberInputSubscriber();
                topNtoKeepSub.update = (message: number, action?: string): void => {
                    this.chartSettings.persistGlobalStackedChartSettings({ topNStackedToKeep: message });
                };
                return topNtoKeepSub;
            }
            case GlobalStackedChartSettings.topNStackedPercentage: {
                const topNtoKeepPercentageSub = new NumberInputSubscriber();
                topNtoKeepPercentageSub.update = (message: number, action?: string): void => {
                    this.chartSettings.persistGlobalStackedChartSettings({ topNStackedPercentage: message });
                };
                return topNtoKeepPercentageSub;
            }
            case GlobalStackedChartSettings.topNStackedOthersLabel: {
                const topNOtherLabelNsub = new TextInputSubscriber();
                topNOtherLabelNsub.update = (message: string, action?: string): void => {
                    this.chartSettings.persistGlobalStackedChartSettings({ topNStackedOthersLabel: message });
                };
                return topNOtherLabelNsub;
            }
            case GlobalStackedChartSettings.stackedAreaOpacity: {
                const areaOpacitySub = new NumberInputSubscriber();
                areaOpacitySub.update = (message: number, action?: string): void => {
                    this.chartSettings.persistGlobalStackedChartSettings({ stackedAreaOpacity: message });
                };
                return areaOpacitySub;
            }
            case GlobalStackedChartSettings.stackedChartSort: {
                const sortSubscriber = new DropdownSubscriber();
                sortSubscriber.update = (message: Sort): void => {
                    this.chartSettings.persistGlobalStackedChartSettings({ stackedChartSort: message });
                };
                return sortSubscriber;
            }
        }
    }
}
