import { ChartData, DataPoint, LegendEntry, ScenarioOptions, ViewModel } from "../interfaces";
import {
    BLACK, BOLD, ChartStyle, CLICK, DifferenceLabel, DISPLAY, END, FILL, FILL_OPACITY, FONT_FAMILY, FONT_SIZE, FONT_WEIGHT,
    GRAY, HEIGHT, HIGHLIGHTABLE, ITALIC, MOUSEENTER, MOUSEOUT, MOUSEOVER, NONE, NORMAL, OPACITY, POINTER_EVENTS, POINTS, POLYLINE, PX,
    RECT, RX, Scenario, STROKE, STROKE_OPACITY, STROKE_WIDTH, TEXT, TEXT_ANCHOR, TOP_N_ARROW, TOP_N_RECTANGLE, WHITE, WIDTH, X, Y, FONT_SIZE_UNIT
} from "../library/constants";

import * as d3 from "../d3";
import { LabelAlignment } from "../library/types";
import { showGlobalOverlayOnStackedChartLegendHover } from "../ui/drawGlobalStackedChartLegendOverlay";
import { showOverlayOnStackedChartLegendHover } from "../ui/drawStackedChartLegendOverlay";

import {
    getLabelProperty, addCommentMarkers, checkForOverlappedLabels, getCommentMarkerAttributes, getFontFamily, getFontWeight,
    getFormattedDataLabel, getRelativeVarianceLabel, getVarianceDataLabel, getMouseOverRectangle
} from "./chart";
import * as drawing from "./../library/drawing";
import * as styles from "./../library/styles";
import * as formatting from "./../library/formatting";

import { ColorScheme, LabelProperties } from "../library/interfaces";
import { ChartSettings } from "../settings/chartSettings";
import { ChartType, DifferenceHighlightFromTo, LabelDensity, ShowTopNChartsOptions } from "../enums";
import { Visual } from "../visual";
import { HORIZONTAL_LABEL_PADDING } from "../consts";
import { calculateRelativeDifferencePercent } from "../viewModel/viewModelHelperFunctions";
import { showPopupMessage } from "../ui/ui";

export interface StackedDataPoint extends DataPoint {
    group: string;
    start?: number;
    end?: number;
    referenceStart?: number;
    referenceEnd?: number;
    scenario: Scenario;
}

export interface StackedLabelProperties extends LabelProperties {
    group: string;
    scenario: Scenario;
    color: string;
}

export interface DiffHighlightAttrs {
    startValue: number;
    endValue: number;
    startCategory: string;
    endCategory: string;
    absDifference: number;
    relDifference: number
    absoluteLabel: string;
    relativeLabel: string;
    width: number;
}

export function getStackedData(viewModel: ViewModel, groups: string[], plotOverlappedReference: boolean) {
    const categories = viewModel.chartData[0].dataPoints.map(p => p.category);
    const scenarioOptions = viewModel.settings.scenarioOptions;

    const stackedDataPoints: StackedDataPoint[] = [];
    const getStackedDpFromDp = (p: DataPoint, group: string): StackedDataPoint => {
        return {
            group: group,
            ...p,
            scenario: viewModel.isSingleSeriesViewModel ? viewModel.settings.scenarioOptions.valueScenario : getNonNullValueScenario(p, viewModel.settings.scenarioOptions),
        };
    };

    const stackedDataSource = categories.map((category, i) => {
        const data: any = {
            category: category,
        };
        viewModel.chartData.forEach(c => {
            const stackedDp = getStackedDpFromDp(c.dataPoints[i], c.group);
            data[c.group] = stackedDp;
            stackedDataPoints.push(stackedDp);
        });
        return data;
    });

    const stackGenerator = d3.stack<any, string>();
    stackGenerator.keys(groups);
    stackGenerator.value((d, group) => getStackedDataPointScenarioValue(<StackedDataPoint>d[group], scenarioOptions));
    let isDiverging = false;
    if (viewModel.chartData.some(c => c.min < 0)) {
        isDiverging = true;
        stackGenerator.offset(d3.stackOffsetDiverging); //Check: use for negative data only?
    }
    const stackedDataValues = stackGenerator(stackedDataSource);

    let referenceStackedData: d3.Series<any, string>[] = null;
    if (plotOverlappedReference) {
        stackGenerator.value((d, group) => d[group].reference);
        referenceStackedData = stackGenerator(stackedDataSource);
    }

    let min = 0;
    let max = 0;
    stackedDataPoints.forEach(p => {
        const stackedDataSeries = stackedDataValues.find(d => d.key === p.group);
        const stackedData = stackedDataSeries.find(d => d.data.category === p.category);
        if (stackedData) {
            p.start = stackedData[0];
            p.end = stackedData[1];
            min = Math.min(min, p.start, p.end);
            max = Math.max(max, p.start, p.end);
        }

        if (plotOverlappedReference && referenceStackedData) {
            const refStackedDataSeries = referenceStackedData.find(d => d.key === p.group);
            const refStackedData = refStackedDataSeries.find(d => d.data.category === p.category);
            if (refStackedData) {
                p.referenceStart = refStackedData[0];
                p.referenceEnd = refStackedData[1];
                min = Math.min(min, p.referenceStart, p.referenceEnd);
                max = Math.max(max, p.referenceStart, p.referenceEnd);
            }
        }
    });

    return {
        min: min,
        max: max,
        dataPoints: stackedDataPoints,
        stackedDataValues: stackedDataValues,
        stackedDataReference: referenceStackedData,
        isDiverging: isDiverging,
    };
}

function getStackedDataPointScenarioValue(dp: StackedDataPoint, scenarioOptions: ScenarioOptions): number {
    if (dp.scenario === scenarioOptions.valueScenario) {
        return dp.value;
    }
    else if (dp.scenario === scenarioOptions.referenceScenario) {
        return dp.reference;
    }
    else if (dp.scenario === scenarioOptions.secondValueScenario) {
        return dp.secondSegmentValue;
    }
    else if (dp.scenario === scenarioOptions.secondReferenceScenario) {
        return dp.secondReference;
    }
    else {
        return 0;
    }
}

function getNonNullValueScenario(p: DataPoint, scenarioOptions: ScenarioOptions): Scenario {
    if (p.value !== null) {
        return scenarioOptions.valueScenario;
    }
    else if (p.reference !== null) {
        return scenarioOptions.referenceScenario;
    }
    else if (p.secondSegmentValue !== null) {
        return scenarioOptions.secondValueScenario;
    }
    else if (p.secondReference !== null) {
        return scenarioOptions.secondReferenceScenario;
    }
    else {
        return null;
    }
}

export function getTotalsLabelsData(stackedDataValues: d3.Series<any, string>[], isDiverging: boolean): { category: string, value: number, displayValue: number }[] {
    const totalsData = stackedDataValues[stackedDataValues.length - 1].map(d => {
        return { category: <any>d.data.category, value: d[1], displayValue: d[1] };
    });
    if (isDiverging) {
        stackedDataValues[0].forEach((d, i) => {
            let total = 0;
            Object.keys(d.data).forEach(k => {
                if (k && d.data[k] && d.data[k].value) {
                    total += d.data[k].value;
                }
            });
            totalsData[i].displayValue = total;
        });

        stackedDataValues.forEach(stackedData => {
            stackedData.forEach((d, i) => {
                if (Math.abs(totalsData[i].value) < Math.abs(d[1])) {
                    totalsData[i].value = d[1];
                }
                if (Math.abs(totalsData[i].value) < Math.abs(d[0])) {
                    totalsData[i].value = d[0];
                }
            });
        });
    }
    return totalsData;
}

export function getStackedLabelProperty(labelValue: number, labelText: string, xPos: number, yPos: number, visibility: boolean,
    d: DataPoint, labelFontSize: number, labelFontFamily: string, category?: string, alignment?: LabelAlignment): LabelProperties {

    const fontStyle = NORMAL;
    const width = drawing.measureTextWidth(labelText, labelFontSize, getFontFamily(labelFontFamily), getFontWeight(labelFontFamily), fontStyle) + 2 * HORIZONTAL_LABEL_PADDING;
    const height = drawing.measureTextHeight(labelText, labelFontSize, getFontFamily(labelFontFamily), getFontWeight(labelFontFamily), fontStyle);

    return {
        text: labelText,
        value: labelValue,
        width: width,
        height: height,
        x: xPos,
        y: yPos + height / 3,
        visibility: visibility,
        category: d ? d.category : category,
        selectionId: d ? d.selectionId : null,
        isHighlighted: d ? d.isHighlighted : false,
        alignment: alignment ? alignment : null,
    };
}

export function getTotalLabelProperties(totalsStackedData: { category: string, value: number, displayValue: number }[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = totalsStackedData.filter(stackedLabelDensityFilter, settings);
    const labelProperties = labelsDataPoints.map((d, i) => {
        const value = d.value;
        const labelText = getFormattedDataLabel(d.displayValue, settings.decimalPlaces, settings.displayUnits, settings.locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        const category = d.category;
        const xPos = xScale(category) + xRangeBand / 2;
        const yPos = yScale(value) + (value < 0 ? settings.labelFontSize : -(settings.isLineChart() ? 1.5 : 1) * settings.labelFontSize);

        return getStackedLabelProperty(d.displayValue, labelText, xPos, yPos, null, null, settings.labelFontSize, settings.labelFontFamily, category);
    });
    return checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

export function stackedLabelDensityFilter(point: any, index: number, array: any[]): boolean {
    const isLastIndex = index === array.length - 1;
    const getValue: (d: any) => number = d => d.value;
    switch (this.labelDensity) {
        case LabelDensity.Full:
        case LabelDensity.Auto:
        case LabelDensity.High:
        case LabelDensity.Medium:
        case LabelDensity.Low:
            return true;
        case LabelDensity.None:
            return false;
        case LabelDensity.Last:
            return isLastIndex;
        case LabelDensity.FirstLast:
            return index === 0 || isLastIndex;
        case LabelDensity.MinMax: {
            const maxPoint = array.reduce((a, b) => getValue(a) > getValue(b) ? a : b);
            const minPoint = array.reduce((a, b) => getValue(a) > getValue(b) ? b : a);
            const maxIndex = array.indexOf(maxPoint);
            const minIndex = array.indexOf(minPoint);
            return index === maxIndex || index === minIndex;
        }
        case LabelDensity.FirstLastMinMax: {
            const maxP = array.reduce((a, b) => getValue(a) > getValue(b) ? a : b);
            const minP = array.reduce((a, b) => getValue(a) > getValue(b) ? b : a);
            const maxIndx = array.indexOf(maxP);
            const minIndx = array.indexOf(minP);
            return index === 0 || isLastIndex || index === maxIndx || index === minIndx;
        }
    }
}

export function getStackedLabelProperties(dataPoints: StackedDataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>,
    totalsLabelsData: { category: string, value: number }[], plotPercentage: boolean): StackedLabelProperties[] {

    const xRangeBand = xScale.bandwidth();

    const getValue: (d: { category: string, value: number }) => number = d => d.value;
    const maxPoint = totalsLabelsData.reduce((a, b) => getValue(a) > getValue(b) ? a : b);
    const minPoint = totalsLabelsData.reduce((a, b) => getValue(a) > getValue(b) ? b : a);
    const maxIndex = totalsLabelsData.indexOf(maxPoint);
    const minIndex = totalsLabelsData.indexOf(minPoint);
    const labelsDataPoints = dataPoints.filter((p, i, arr) => {
        const pointCategoryIndex = totalsLabelsData.findIndex(c => c.category === p.category);
        const isLastIndex = pointCategoryIndex === totalsLabelsData.length - 1;
        switch (settings.labelDensity) {
            case LabelDensity.Full:
            case LabelDensity.Auto:
            case LabelDensity.High:
            case LabelDensity.Medium:
            case LabelDensity.Low:
                return true;
            case LabelDensity.None:
                return false;
            case LabelDensity.Last:
                return isLastIndex;
            case LabelDensity.FirstLast:
                return pointCategoryIndex === 0 || isLastIndex;
            case LabelDensity.MinMax:
                return pointCategoryIndex === maxIndex || pointCategoryIndex === minIndex;
            case LabelDensity.FirstLastMinMax:
                return pointCategoryIndex === 0 || isLastIndex || pointCategoryIndex === maxIndex || pointCategoryIndex === minIndex;
        }
    });

    const labelProperties: StackedLabelProperties[] = labelsDataPoints.map(d => {
        let value = getNonNullStackedDataPointValue(d);
        const isEnoughVerticalSpace = yScale(d.start) - yScale(d.end) > settings.labelFontSize + 1;

        let labelText = "";
        if (isEnoughVerticalSpace) {
            if (plotPercentage) {
                const totalValue = totalsLabelsData.find(t => t.category === d.category)?.value;
                if (totalValue) {
                    value = value / totalValue;
                    labelText = getFormattedDataLabel(value, settings.decimalPlaces, "None", settings.locale,
                        settings.showNegativeValuesInParenthesis(), true, false, false, false, "0%", false);
                }
            }
            else {
                labelText = getFormattedDataLabel(value, settings.decimalPlaces, settings.displayUnits, settings.locale,
                    settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
            }
        }
        const isForecast = d.value !== null && settings.scenarioOptions.valueScenario === Scenario.Forecast
            || d.value === null && d.secondSegmentValue !== null && settings.scenarioOptions.secondValueScenario === Scenario.Forecast;
        const xPos = xScale(d.category) + xRangeBand * 0.5;
        const yPos = yScale((d.end + d.start) / 2) + 5;

        const property = getLabelProperty(labelText, xPos, yPos, null, value, d, settings.labelFontSize, settings.labelFontFamily, null, null, isForecast);
        return {
            group: d.group,
            ...property,
            scenario: d.scenario,
            color: d.color
        };
    });
    return labelProperties ? <StackedLabelProperties[]>checkForOverlappedLabels(<LabelProperties[]>labelProperties, settings.labelDensity, xRangeBand) : [];
}

export function getNonNullStackedDataPointValue(dp: StackedDataPoint): number {
    if (dp.value !== null) {
        return dp.value;
    }
    else if (dp.reference !== null) {
        return dp.reference;
    }
    else if (dp.secondSegmentValue !== null) {
        return dp.secondSegmentValue;
    }
    else if (dp.secondReference !== null) {
        return dp.secondReference;
    }
    else {
        return null;
    }
}

export function plotStackedCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: StackedDataPoint[], xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, settings: ChartSettings) {
    const xScaleBandWidth = xScale.bandwidth();
    const markerAttrs = getCommentMarkerAttributes(xScaleBandWidth);
    const getMarkerVerticalPosition = (d: StackedDataPoint): number =>
        yScale(d.end) + (markerAttrs.radius + markerAttrs.margin);
    const getMarkerHorizontalPosition = (d: StackedDataPoint): number => xScale(d.category) + xScaleBandWidth / 2;
    addCommentMarkers(container, commentDataPoints, getMarkerHorizontalPosition, getMarkerVerticalPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}

// eslint-disable-next-line max-lines-per-function
export function getDifferenceHighlightAttributes(stackedData: d3.Series<{ [key: string]: number; }, string>[], referenceStackedData: d3.Series<{ [key: string]: number; }, string>[],
    settings: ChartSettings, plotOverlapped: boolean, isDiverging: boolean): DiffHighlightAttrs {
    const stackedDataTopSeries = stackedData[stackedData.length - 1];

    const dhAttrs: DiffHighlightAttrs = {
        startValue: 0,
        endValue: 0,
        startCategory: "",
        endCategory: "",
        absDifference: 0,
        relDifference: 0,
        absoluteLabel: "",
        relativeLabel: "",
        width: 0
    };

    let startIndex = stackedDataTopSeries.length > 1 ? stackedDataTopSeries.length - 2 : 0;
    let endIndex = stackedDataTopSeries.length - 1;
    switch (settings.differenceHighlightFromTo) {
        case DifferenceHighlightFromTo.FirstToLast:
            startIndex = 0;
            break;
        case DifferenceHighlightFromTo.MinToMax:
            if (isDiverging) {
                const totalsData = getTotalsLabelsData(stackedData, true);
                let currentMin = totalsData[0].value;
                let currentMax = totalsData[0].value;
                startIndex = 0;
                endIndex = 0;
                totalsData.forEach((total, i) => {
                    if (total.value < currentMin) {
                        startIndex = i;
                        currentMin = total.value;
                    }
                    if (total.value > currentMax) {
                        endIndex = i;
                        currentMax = total.value;
                    }
                });
            }
            else {
                stackedDataTopSeries.forEach((d, i) => {
                    if (d[1] < stackedDataTopSeries[startIndex][1]) {
                        startIndex = i;
                    }
                    if (d[1] > stackedDataTopSeries[endIndex][1]) {
                        endIndex = i;
                    }
                });
            }
            break;
        case DifferenceHighlightFromTo.PenultimateToLast:
            startIndex = stackedDataTopSeries.length > 1 ? stackedDataTopSeries.length - 2 : 0;
            break;
        case DifferenceHighlightFromTo.LastToCorresponding:
            startIndex = endIndex;
            break;
    }

    dhAttrs.startCategory = stackedDataTopSeries[startIndex].data.category.toString();
    dhAttrs.endCategory = stackedDataTopSeries[endIndex].data.category.toString();

    dhAttrs.startValue = stackedDataTopSeries[startIndex][1];
    dhAttrs.endValue = stackedDataTopSeries[endIndex][1];
    if (isDiverging) {
        stackedData.forEach(stackedDataEntry => {
            const d = stackedDataEntry[startIndex];
            if (Math.abs(dhAttrs.startValue) < Math.abs(d[1])) {
                dhAttrs.startValue = d[1];
            }
            if (Math.abs(dhAttrs.startValue) < Math.abs(d[0])) {
                dhAttrs.startValue = d[0];
            }
        });
        stackedData.forEach(stackedDataEntry => {
            const d = stackedDataEntry[endIndex];
            if (Math.abs(dhAttrs.endValue) < Math.abs(d[1])) {
                dhAttrs.endValue = d[1];
            }
            if (Math.abs(dhAttrs.endValue) < Math.abs(d[0])) {
                dhAttrs.endValue = d[0];
            }
        });
    }

    if (startIndex === endIndex) {
        if (plotOverlapped && referenceStackedData !== null) {
            const refDataTopSeries = referenceStackedData[referenceStackedData.length - 1];
            dhAttrs.startValue = refDataTopSeries[startIndex][1];
        }
        else {
            dhAttrs.startValue = stackedDataTopSeries[startIndex][0];
        }
    }

    if (dhAttrs.startValue !== null && dhAttrs.startValue !== null) {
        if (settings.differenceLabel === DifferenceLabel.Absolute || settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute) {
            const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);
            const hideUnits = settings.shouldHideDataLabelUnits();
            dhAttrs.absDifference = dhAttrs.endValue - dhAttrs.startValue;
            dhAttrs.absoluteLabel = getVarianceDataLabel(dhAttrs.absDifference, settings.decimalPlaces, settings.displayUnits,
                settings.locale, settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelsFormat, hideUnits);
            dhAttrs.width = Math.max(dhAttrs.width, drawing.measureTextWidth(dhAttrs.absoluteLabel, settings.differenceHighlightFontSize,
                getFontFamily(settings.differenceHighlightFontFamily), getFontWeight(settings.differenceHighlightFontFamily), NORMAL));
        }
        if (settings.differenceLabel === DifferenceLabel.Relative || settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute) {
            dhAttrs.relDifference = calculateRelativeDifferencePercent(dhAttrs.endValue, dhAttrs.startValue);
            dhAttrs.relativeLabel = getRelativeVarianceLabel(settings, dhAttrs.relDifference, settings.differenceLabel === DifferenceLabel.RelativeAndAbsolute);
            dhAttrs.width = Math.max(dhAttrs.width, drawing.measureTextWidth(dhAttrs.relativeLabel, settings.differenceHighlightFontSize,
                getFontFamily(settings.differenceHighlightFontFamily), getFontWeight(settings.differenceHighlightFontFamily), ITALIC));
        }

        dhAttrs.width += 10 + (settings.isLineChart() ? 0 : 12);
        if (settings.differenceHighlightEllipse) {
            dhAttrs.width += Math.max(settings.differenceHighlightEllipseBorderPadding - 4, 0)
                + Math.floor(settings.differenceHighlightEllipseBorderWidth / 2);
        }
        dhAttrs.width += settings.differenceHighlightMargin;
    }

    return dhAttrs;
}

export function getColorScale(settings: ChartSettings, groups: string[], scenario: Scenario): string[] {
    if (settings.stackedChartColors === 0) {
        const colorScheme = settings.colorScheme;
        let baseColor = colorScheme.neutralColor;

        if (scenario === Scenario.PreviousYear) {
            if (settings.chartStyle === ChartStyle.Custom && colorScheme.useCustomScenarioColors) {
                baseColor = colorScheme.applyPatterns ? styles.getLighterColor(colorScheme.previousYearColor) : colorScheme.previousYearColor;
            }
            else {
                baseColor = styles.getLighterColor(baseColor);
            }
        }
        else if (scenario === Scenario.Plan && settings.chartStyle === ChartStyle.Custom && colorScheme.useCustomScenarioColors) {
            baseColor = colorScheme.planColor;
        }
        else if (scenario === Scenario.Forecast && settings.chartStyle === ChartStyle.Custom && colorScheme.useCustomScenarioColors) {
            baseColor = colorScheme.forecastColor;
        }

        const lighterBaseColor = styles.getLighterColor(baseColor);
        return [baseColor, lighterBaseColor, styles.getLighterColor(lighterBaseColor)];
    }
    // else if (settings.stackedChartColors === 3) {
    //     let numberOfColors = Math.min(9, Math.max(3, groups.length + 1));
    //     let d3ColorScheme = getd3ColorScheme(settings.d3ColorScheme, numberOfColors);
    //     let colorScale = d3ColorScheme
    //         .slice(1)
    //         .reverse();
    //     return colorScale.length > 0 ? colorScale : [...d3ColorScheme];
    // }
    else {
        // let pbiColorPalette = Visual.visualHost.colorPalette;
        // //     let colorScale = [hostColorPalette.foregroundDark.value, hostColorPalette.foreground.value, hostColorPalette.foregroundLight.value];
        // return [pbiColorPalette.foregroundNeutralDark.value, //pbiColorPalette.foregroundNeutralLight.value,
        // pbiColorPalette.foregroundNeutralSecondary.value, pbiColorPalette.foregroundNeutralSecondaryAlt.value, pbiColorPalette.foregroundNeutralSecondaryAlt2.value,
        // pbiColorPalette.foregroundNeutralTertiary.value, pbiColorPalette.foregroundNeutralTertiaryAlt.value];
    }
}

function getd3ColorScheme(stackedChartColors: number, numberOfColors: number): readonly string[] {
    switch (stackedChartColors) {
        case 1: return d3.schemeGreys[numberOfColors];
        case 2: return d3.schemeAccent;
        case 3: return d3.schemeBlues[numberOfColors];
        case 4: return d3.schemeBrBG[numberOfColors];
        case 5: return d3.schemeBuGn[numberOfColors];
        case 6: return d3.schemeBuPu[numberOfColors];
        case 7: return d3.schemeCategory10;
        case 8: return d3.schemeDark2;
        case 9: return d3.schemeGnBu[numberOfColors];
        case 10: return d3.schemeGreens[numberOfColors];
        case 11: return d3.schemeOrRd[numberOfColors];
        case 12: return d3.schemeOranges[numberOfColors];
        case 13: return d3.schemePRGn[numberOfColors];
        case 14: return d3.schemePaired;
        case 15: return d3.schemePastel1;
        case 16: return d3.schemePastel2;
        case 17: return d3.schemePiYG[numberOfColors];
        case 18: return d3.schemePuBu[numberOfColors];
        case 19: return d3.schemePuBuGn[numberOfColors];
        case 20: return d3.schemePuOr[numberOfColors];
        case 21: return d3.schemePuRd[numberOfColors];
        case 22: return d3.schemePurples[numberOfColors];
        case 23: return d3.schemeRdBu[numberOfColors];
        case 24: return d3.schemeRdGy[numberOfColors];
        case 25: return d3.schemeRdPu[numberOfColors];
        case 26: return d3.schemeRdPu[numberOfColors];
        case 27: return d3.schemeRdYlBu[numberOfColors];
        case 28: return d3.schemeRdYlGn[numberOfColors];
        case 29: return d3.schemeReds[numberOfColors];
        case 30: return d3.schemeSet1;
        case 31: return d3.schemeSet2;
        case 32: return d3.schemeSet3;
        case 33: return d3.schemeSpectral[numberOfColors];
        case 34: return d3.schemeTableau10;
        case 35: return d3.schemeYlGn[numberOfColors];
        case 36: return d3.schemeYlGnBu[numberOfColors];
        case 37: return d3.schemeYlOrBr[numberOfColors];
        case 38: return d3.schemeYlOrRd[numberOfColors];
    }
}

/**
 * Returns values for min and max in 2-dimensional array.
 * Finds first nonzero max value and according to its index assigns min value.
 *
 * @param array
 * @returns { [key: string]: number; }
 */
function findFirstNonZeroCategory(array: d3.Series<{ [key: string]: number; }, string>): { [key: string]: number; } {
    let categoryIndex: number = 0;

    let min: number = 0;
    let max: number = 0;

    const minIndex: number = 0;
    const maxIndex: number = 1;

    categoryIndex = array.findIndex(c => c[maxIndex] !== 0 || c[maxIndex] > c[minIndex]); //second part covers negatives e.g. min = -80 and max = 0

    if (categoryIndex > -1) {
        min = array[categoryIndex][minIndex];
        max = array[categoryIndex][maxIndex];
    }

    return { min: min, max: max };
}

export function plotStackedChartLegend(stackedData: d3.Series<{ [key: string]: number; }, string>[],
    container: d3.Selection<SVGElement, any, any, any>, chartData: ChartData[],
    settings: ChartSettings, x: number, legendWidth: number, yScale: d3.ScaleLinear<number, number>, isDiverging: boolean, colorScale: string[], dataArray: any[]) {
    const legendData: LegendEntry[] = stackedData.map(d => {
        const minMax = findFirstNonZeroCategory(d);

        return {
            groupName: d.key ? d.key : "[empty]",
            min: minMax.min,
            max: minMax.max,
            selectionId: chartData.find(c => c.group === d.key).selectionId
        };
    });

    const plotAllNegative = isDiverging && legendData[0].max === 0;
    let lastYPos: number = null;

    let groupLegends = container
        .selectAll("chart-legend")
        .data(legendData);
    const legendTexts = groupLegends
        .enter()
        .append(TEXT)
        .classed("chart-legend", true)
        .classed(HIGHLIGHTABLE, true)
        .text(d => d.groupName)
        .style(FONT_SIZE, settings.labelFontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, getFontFamily(settings.labelFontFamily))
        .style(FONT_WEIGHT, getFontWeight(settings.labelFontFamily))
        .style(TEXT_ANCHOR, END)
        .attr(X, x + legendWidth)
        .attr(Y, (d, i) => {
            let yPos = yScale(d.max) + (yScale(d.min) - yScale(d.max)) / 2 + settings.labelFontSize / 3;
            if (i > 0 && lastYPos !== null) {
                const diff = Math.abs(lastYPos - yPos);
                if (diff < settings.labelFontSize + 2 || yPos >= lastYPos && !plotAllNegative && d.min >= 0 || yPos < lastYPos && plotAllNegative && d.min < 0) {
                    yPos = lastYPos + (plotAllNegative ? 1 : -1) * (settings.labelFontSize + 2);
                }
            }
            lastYPos = yPos;
            return yPos;
        })
        .attr(FILL, (d, i) => getStackedChartLegendColor(dataArray[i], settings));

    groupLegends = groupLegends.merge(legendTexts);

    showOverlayOnStackedChartLegendHover(groupLegends, false, {
        fontSize: settings.labelFontSize,
        fontFamily: getFontFamily(settings.labelFontFamily),
        fontColor: settings.labelFontColor
    });

    showGlobalOverlayOnStackedChartLegendHover(container, false);

    if (settings.showTopNStackedOptions === ShowTopNChartsOptions.Items) {
        const otherLabel = legendTexts.filter(d => d.groupName === settings.topNStackedOthersLabel);
        if (!otherLabel.empty()) {
            const othersLabelXPos = +otherLabel.attr(X);
            const othersLabelYPos = +otherLabel.attr(Y);
            const fontSize = settings.labelFontSize;
            const arrowSize = Math.max(fontSize + 7, 16);
            drawSingleOtherArrow(container, othersLabelXPos, othersLabelYPos, true, settings, false, arrowSize);
            drawSingleOtherArrow(container, othersLabelXPos, othersLabelYPos, false, settings, false, arrowSize);
            Visual.element.addEventListener(MOUSEOUT, () => {
                d3.selectAll("." + TOP_N_RECTANGLE).attr(STROKE_OPACITY, 0);
                d3.selectAll("." + TOP_N_ARROW).attr(OPACITY, 0);
            });
            Visual.element.addEventListener(MOUSEOVER, () => {
                d3.selectAll("." + TOP_N_RECTANGLE).attr(STROKE_OPACITY, 1);
                d3.selectAll("." + TOP_N_ARROW).attr(OPACITY, 1);
            });
        }
    }
}

/**
 * Returns appropriate legend item text color based on highlight, chart type and user setting Use colored legend names.
 *
 * @param d - object with generated colors for each layer
 * @param settings
 * @returns - color string in HEX
 */
export function getStackedChartLegendColor(d, settings: ChartSettings): string {

    const baseObject = (settings.chartType == ChartType.Line || settings.chartType == ChartType.Area) ? d[0] : d;

    if (settings.isGroupHighlighted(baseObject.group)) {
        return baseObject.color;
    }

    if (!settings.useColoredLegendNames) {
        return settings.legendFontColor;
    }

    return baseObject.color;
}

export function drawSingleOtherArrow(container: d3.Selection<SVGElement, any, any, any>, xPos: number, yPos: number,
    isUp: boolean, settings: ChartSettings, isVerticalAxis: boolean, arrowSize: number) {
    const topNChartsToKeep = settings.topNStackedToKeep;

    const btnRect = container.append(RECT)
        .attr(FILL_OPACITY, 0.01)
        .attr(STROKE_OPACITY, 0)
        .attr(FILL, WHITE)
        .attr(STROKE, GRAY)
        .attr(STROKE_WIDTH, 1)
        .attr(WIDTH, arrowSize)
        .attr(HEIGHT, arrowSize)
        .attr(X, isVerticalAxis ? xPos + 6 + (isUp ? arrowSize : 0) : xPos - arrowSize)
        .attr(Y, isVerticalAxis ? yPos - 0.7 * arrowSize : yPos + 2 - (isUp ? arrowSize * 3 : arrowSize * 2))
        .attr(RX, 2)
        .classed(TOP_N_RECTANGLE, true)
        .on(MOUSEOVER, () => {
            if (topNChartsToKeep === 1 && !isUp) {
                return;
            }
            topNArrow.attr(STROKE, BLACK);
            btnRect.attr(STROKE, BLACK).attr(STROKE_OPACITY, 1);
        })
        .on(MOUSEENTER, () => {
            if (topNChartsToKeep === 1 && !isUp) {
                return;
            }
            const slider = d3.select<HTMLElement, any>(".slide");
            const scrollTop = slider ? slider.node().scrollTop || 0 : 0;
            const tooltipText = isUp ? "Increase to Top " + (topNChartsToKeep + 1) : "Decrease to Top " + (topNChartsToKeep - 1);
            const tooltipXPos = xPos + 4;
            const tooltipYPos = isVerticalAxis ? yPos + 0.6 * arrowSize : yPos - arrowSize * (isUp ? 3 : 2) - scrollTop;
            showPopupMessage(Visual.element, tooltipXPos, tooltipYPos, tooltipText);
        })
        .on(MOUSEOUT, () => {
            topNArrow.attr(STROKE, GRAY);
            btnRect.attr(STROKE, GRAY).attr(STROKE_OPACITY, 1);
        })
        .on(CLICK, (event) => {
            settings.topNStackedToKeep += isUp ? +1 : -1;
            settings.topNStackedToKeep = Math.min(Math.max(1, settings.topNStackedToKeep), 99);
            settings.persistTopNStackedItemsSettings();
            (<Event>event).stopPropagation();
        });

    const topNArrow = container
        .append(POLYLINE)
        .attr(POINTS, () => {
            if (isVerticalAxis) {
                const x = xPos + arrowSize * (isUp ? 1 : 0) + 6;
                const y = yPos - 0.7 * arrowSize;
                const dx = arrowSize / 4;
                const dy = dx;
                return `${x + (isUp ? dx : arrowSize - dx)},${y + dy}
                ${x + (isUp ? arrowSize - dx : dx)},${y + arrowSize / 2}
                ${x + (isUp ? dx : arrowSize - dx)},${y + arrowSize - dy}`;
            }
            else {
                const y = yPos - arrowSize * (isUp ? 3 : 2) + 2;
                const x = xPos - arrowSize;
                const dy = arrowSize / 4;
                const dx = dy;
                return `${x + dx},${y + (isUp ? arrowSize - dy : dy)} 
                ${x + arrowSize / 2},${y + (isUp ? dy : arrowSize - dy)} 
                ${x + arrowSize - dx},${y + (isUp ? arrowSize - dy : dy)}`;
            }
        })
        .attr(STROKE, GRAY)
        .attr(STROKE_WIDTH, 1)
        .attr(FILL_OPACITY, 0)
        .attr(OPACITY, 0)
        .attr(POINTER_EVENTS, NONE)
        .classed(TOP_N_ARROW, true);
}

export function getValueDataPointColor(dataPoint: StackedDataPoint, settings: ChartSettings, groups: string[], colorScale: string[]): string {
    if (settings.isGroupHighlighted(dataPoint.group)) {
        return settings.getGroupHighlightColor(dataPoint.group);
    }

    if (settings.isCategoryHighlighted(dataPoint.category)) {
        return settings.getCategoryHighlightColor(dataPoint.category);
    }

    // Improve?: custom scenario colors?
    const groupIndex = groups.indexOf(dataPoint.group);
    const colorIndex = groupIndex % colorScale.length;
    return colorScale[colorIndex];
}

export function applyStylesToStackedBars(element: d3.Selection<any, StackedDataPoint, any, any>,
    colorScale: string[], settings: ChartSettings, groups: string[],
    scenario: Scenario, style: ChartStyle, colorScheme: ColorScheme, useDataPointScenario: boolean) {

    let applyPatterns = true;
    if (settings.chartStyle === ChartStyle.Custom && colorScheme.useCustomScenarioColors) {
        applyPatterns = colorScheme.applyPatterns;
    }

    if (useDataPointScenario) {
        element.attr(STROKE_WIDTH, d => (d.scenario === Scenario.Forecast || d.scenario === Scenario.Plan) && applyPatterns ? 1 : 0);
        element.attr(STROKE, d => d.color);
        element.attr(FILL, d => d.scenario === Scenario.Forecast && applyPatterns ?
            `url(#diagonal-stripe-${styles.getPatternFromColor(d.color, colorScheme)})` : d.color);
        element.attr(FILL_OPACITY, d => {
            if (d.scenario === Scenario.Plan) {
                if (colorScheme.useCustomScenarioColors && !colorScheme.applyPatterns) {
                    return 1;
                }
                else {
                    return style === ChartStyle.DrHichert ? 0 : 0.1;
                }
            }
            else {
                return 1;
            }
        });
    }
    else {
        element.attr(STROKE_WIDTH, (scenario === Scenario.Forecast || scenario === Scenario.Plan) && applyPatterns ? 1 : 0);
        element.attr(STROKE, d => d.color);
        element.attr(FILL, d => scenario === Scenario.Forecast && applyPatterns ?
            `url(#diagonal-stripe-${styles.getPatternFromColor(d.color, colorScheme)})` : d.color);
        element.attr(FILL_OPACITY, () => {
            if (scenario === Scenario.Plan) {
                if (colorScheme.useCustomScenarioColors && !colorScheme.applyPatterns) {
                    return 1;
                }
                else {
                    return style === ChartStyle.DrHichert ? 0 : 0.1;
                }
            }
            else {
                return 1;
            }
        });
    }
}

export function addColorsCustomHighlightColorsPatternDefinitions(svg: d3.Selection<SVGElement, any, any, any>, groups: string[], settings: ChartSettings) {
    const customHighlightColors = [];
    // add custom highlight colors
    groups.forEach(group => {
        if (settings.isGroupHighlighted(group) && //note: "settings.highlightedGroups.length > 0" instead of "settings.highlightedGroups.length" was used
            settings.highlightedGroupsCustomColors.find(c => c[group])) {
            customHighlightColors.push(settings.getGroupHighlightColor(group));
        }
    });
    if (customHighlightColors.length > 0) {
        drawing.addColorsArrayPatternDefinitions(svg, customHighlightColors);
    }
}

export function addStackedChartMouseHandlers(elements: d3.Selection<any, StackedDataPoint, any, any>, container: d3.Selection<any, any, any, any>) {
    // Allow selection only if the visual is rendered in a view that supports interactivity (e.g. Report)
    // if (!Visual.visualHost.hostCapabilities.allowInteractions) {
    //     return;
    // }

    //let selectionManager = Visual.selectionManager;
    (<any>window).highlightTimeout = null;

    // This must be an anonymous function instead of a lambda because
    // d3 uses 'this' as the reference to the element that was clicked.
    elements.on(CLICK, function (event, d) {
        const multiSelect = (<PointerEvent>event).ctrlKey;
        if ((<any>window).highlightTimeout != null) {
            window.clearTimeout((<any>window).highlightTimeout);
        }
        const el = d3.select(this);
        el.on(MOUSEOUT, null);
        // selectionManager.select(d.selectionId, multiSelect).then((ids: ISelectionId[]) => {
        //     syncState(selectionManager);
        // });
        (<Event>event).stopPropagation();
    });

    container.on(CLICK, () => {
        if (Visual.textSettings) {
            Visual.textSettings.style(DISPLAY, NONE);
        }
        // if (selectionManager.hasSelection()) {
        //     selectionManager.clear();
        //     let shapes = d3.selectAll<any, StackedDataPoint>(`${RECT}.${HIGHLIGHTABLE}`);
        //     let textShapes = d3.selectAll<any, StackedDataPoint>(`${TEXT}.${HIGHLIGHTABLE}`);
        //     shapes.attr(OPACITY, 1);
        //     textShapes.attr(FONT_WEIGHT, d => d.isHighlighted ? BOLD : NORMAL);
        //     textShapes.attr(OPACITY, 1);
        //     //charting.syncState(selectionManager);
        // }
    });

    elements.on(MOUSEOVER, function (event, d) {
        // if (selectionManager.hasSelection()) {
        //     return;
        // }
        if ((<any>window).highlightTimeout != null) {
            clearTimeout((<any>window).highlightTimeout);
        }

        const shapes = d3.selectAll<any, StackedDataPoint>(`${RECT}.${HIGHLIGHTABLE}`);
        shapes.attr(OPACITY, dp => dp.category === d.category && dp.group === d.group ? 1 : 0.5);
        const textShapes = d3.selectAll<any, StackedDataPoint>(`${TEXT}.${HIGHLIGHTABLE}`);
        textShapes.attr(FONT_WEIGHT, dp => dp.category === d.category && dp.group === d.group || dp.isHighlighted ? BOLD : NORMAL);
        textShapes.attr(OPACITY, dp => dp.category === d.category && dp.group === d.group ? 1 : 0.5);

        const el = d3.select(this);
        el.on(MOUSEOUT, (event, d) => {
            el.on(MOUSEOUT, null);
            (<any>window).highlightTimeout = setTimeout(() => {
                //if (!selectionManager.hasSelection()) {
                shapes.attr(OPACITY, 1);
                textShapes.attr(FONT_WEIGHT, d => d.isHighlighted ? BOLD : NORMAL)
                    .attr(OPACITY, 1);
                //}
            }, 300);
        });
    });
}

export function addValuesLabelsInteractions(labels: d3.Selection<any, any, any, any>, reportArea: d3.Selection<SVGElement, any, any, any>,
    settings: ChartSettings, slider: HTMLElement) {
    labels.on(MOUSEOVER, function () {
        const label = d3.select(this);
        if (label.empty()) {
            return;
        }
        const labelBBox = (<SVGTextElement>label.node()).getBBox();
        const labelRect = getMouseOverRectangle(reportArea, slider, labelBBox.height, labelBBox.width, labelBBox.x, labelBBox.y, "stacked-value-label-rect");
        labelRect.on(CLICK, () => {
            settings.showStackedLabelsAsPercentage = !settings.showStackedLabelsAsPercentage;
            Visual.animateVarianceLabels = true;
            settings.persistStackedChartLabelsAsPercentage();
        });
    });
}
