import * as d3 from "../d3";
import { ViewModel } from "./../interfaces";
import { ColorScheme } from "./../library/interfaces";
import { ChartSettings } from "./../settings/chartSettings";
import { Visual } from "./../visual";

import {
    Scenario, NORMAL, TEXT, FILL, FILL_OPACITY, HIGHLIGHTABLE, WHITE, G, OPACITY, FONT_WEIGHT, STROKE, STROKE_WIDTH, CLICK, MOUSEOUT, NONE,
    BOLD, ChartStyle, PATH, ROUND, STROKE_LINEJOIN, STROKE_LINECAP, MOUSEMOVE, LINE, X1, Y1, X2, Y2
} from "./../library/constants";
import { CHART_CONTAINER, MOUSE_OVER_DROPLINE, SELECTION_DROPLINE } from "./../consts";
import * as drawing from "./../library/drawing";
import { getPercentageFormatOrNull } from "./../library/formatting";
import { getPatternFromColor } from "./../library/styles";
import * as charting from "./chart";
import { StackedDataPoint, DiffHighlightAttrs, getStackedData, addValuesLabelsInteractions } from "./stackedChart";
import {
    addColorsCustomHighlightColorsPatternDefinitions, getColorScale, getDifferenceHighlightAttributes, getStackedLabelProperties,
    getTotalLabelProperties, getTotalsLabelsData, plotStackedChartLegend, plotStackedCommentMarkers
} from "./stackedChart";

// eslint-disable-next-line max-lines-per-function
export default function stackedAreaChart(viewModel: ViewModel, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
    slider: HTMLElement, x: number, y: number, height: number, width: number, topMargin: number, bottomMargin: number) {

    if (!viewModel.chartData || viewModel.chartData.length === 0) {
        return;
    }
    const settings = viewModel.settings;
    const scenarioOptions = settings.scenarioOptions;
    const colorScheme = settings.colorScheme;
    const plotLegend = settings.showLegend;
    let chartWidth = width;
    let chartXPos = x;

    const plotOverlappedReference = false;
    const groups = viewModel.chartData.map(d => d.group);

    let legendWidth = 0;
    if (plotLegend) {
        legendWidth = Math.max(...groups.map(v => drawing.measureTextWidth(v, settings.labelFontSize, settings.labelFontFamily, NORMAL, NORMAL)));
        let legendMargin = legendWidth + settings.legendItemsMargin;
        if (plotOverlappedReference) {
            legendMargin += 10;
        }
        chartWidth -= legendMargin;
        chartXPos += legendMargin;
    }

    const stackedData = getStackedData(viewModel, groups, plotOverlappedReference);
    const minStacked = stackedData.min;
    const maxStacked = stackedData.max;
    const stackedDataPoints = stackedData.dataPoints;
    const chartData0 = viewModel.chartData[0];

    let diffHighlightAttrs: DiffHighlightAttrs;
    if (settings.differenceHighlight) {
        diffHighlightAttrs = getDifferenceHighlightAttributes(stackedData.stackedDataValues, stackedData.stackedDataReference, settings, plotOverlappedReference, stackedData.isDiverging);
        chartWidth -= diffHighlightAttrs.width;
        settings.differenceHighlightWidth = diffHighlightAttrs.width;
    }

    if (height - bottomMargin <= topMargin) {
        topMargin -= topMargin - (height - bottomMargin + 1);
    }
    const xScale = charting.getXScale(chartData0, chartXPos, chartWidth, 0.0);
    const yScale = charting.getLinearScale(minStacked, maxStacked, y + height - bottomMargin, y + topMargin);

    const colorScale = getColorScale(settings, groups, scenarioOptions.valueScenario);
    if (scenarioOptions.valueScenario === Scenario.Forecast) {
        drawing.addColorsArrayPatternDefinitions(svg, colorScale);
        addColorsCustomHighlightColorsPatternDefinitions(svg, groups, settings);
    }
    stackedDataPoints.forEach(p => {
        p.color = groupColorGetter(p.group, settings, groups, colorScale);
    });

    const areaStackedDataPoints = [];
    stackedData.stackedDataValues.forEach(data => {
        const currentGroup = data.key;
        const currentGroupDataPoints = [];
        data.forEach(catData => {
            currentGroupDataPoints.push(stackedDataPoints.find(dp => dp.group === currentGroup && dp.category === catData.data.category));
        });
        areaStackedDataPoints.push(currentGroupDataPoints);
    });

    const opacity = settings.stackedAreaOpacity / 100;

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);
    charting.plotCategories(chartContainer, settings, chartData0, y + height, xScale, chartWidth, yScale(0));

    const areaValueFunction2 = d3.area<StackedDataPoint>()
        .x(d => xScale(d.category) + xScale.bandwidth() / 2)
        .y0(d => yScale(d.end))
        .y1(d => yScale(d.start));
    const lineValueFunction = d3.line<StackedDataPoint>()
        .x((d) => { return xScale(d.category) + xScale.bandwidth() / 2; })
        .y((d) => yScale(d.end));

    const stackedAreas = chartContainer
        .selectAll("stacked-area")
        .data(areaStackedDataPoints)
        .enter()
        .append(PATH)
        .classed("stack-area", true)
        .attr(STROKE_WIDTH, 0)
        .attr(FILL, d => d[0].color)
        .attr(OPACITY, opacity)
        .attr("d", areaValueFunction2);

    if (scenarioOptions.valueScenario === Scenario.Forecast) {
        stackedAreas.attr(FILL, d => `url(#diagonal-stripe-${getPatternFromColor(d[0].color, colorScheme)})`);
    }
    //styles.applyToElement(stackedAreas, color, scenario, settings.chartStyle, settings.colorScheme);
    //applyStylesToStackedAreas(stackedAreas, colorScale, settings, groups, scenarioOptions.valueScenario, settings.chartStyle, colorScheme);

    const stackedLines = chartContainer
        .selectAll("stacked-area-line")
        .data(areaStackedDataPoints)
        .enter()
        .append(PATH)
        .attr(FILL, NONE)
        .attr(STROKE_LINEJOIN, ROUND)
        .attr(STROKE_LINECAP, ROUND)
        .attr(STROKE, d => d[0].color) //colorScheme.lineColor)
        .attr(STROKE_WIDTH, 2)
        .attr("d", lineValueFunction);

    if (plotLegend) {
        plotStackedChartLegend(stackedData.stackedDataValues, chartContainer, viewModel.chartData, settings, x, legendWidth, yScale, stackedData.isDiverging, colorScale, areaStackedDataPoints);
    }
    drawing.plotHorizontalAxis(chartContainer, false, chartXPos, chartWidth, yScale(0), settings.colorScheme.axisColor, scenarioOptions.referenceScenario);

    const labelsFormat = getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    const hideUnits = settings.shouldHideDataLabelUnits();
    if (settings.showDataLabels) {
        const stackedDataValues = stackedData.stackedDataValues;
        const totalsLabelsData = getTotalsLabelsData(stackedDataValues, stackedData.isDiverging);
        const totalsLabelsProperties = getTotalLabelProperties(totalsLabelsData, settings, labelsFormat, hideUnits, xScale, yScale);
        if (totalsLabelsProperties.length > 0) {
            charting.plotLabels(chartContainer, "total-label", totalsLabelsProperties, settings);
        }
        const valuesLabelProperties = getStackedLabelProperties(stackedDataPoints, settings, labelsFormat, hideUnits, xScale, yScale, totalsLabelsData, settings.showStackedLabelsAsPercentage);
        if (valuesLabelProperties.length > 0) {
            const backgroundsColor = WHITE;
            charting.plotLabelBackgrounds(chartContainer, 0, valuesLabelProperties.filter(l => l.scenario === Scenario.Forecast), settings, backgroundsColor, true, false);
            const valueLabels = charting.plotLabels(chartContainer, "value-label", valuesLabelProperties, settings);
            // valueLabels.attr(FILL, d => (<StackedLabelProperties>d).scenario === Scenario.Plan || (<StackedLabelProperties>d).scenario === Scenario.Forecast ?
            //     BLACK : styles.getLabelColor((<StackedLabelProperties>d).color));
            //if (Visual.visualHost.hostCapabilities.allowInteractions) {
            addValuesLabelsInteractions(valueLabels, chartContainer, settings, slider);
            if (Visual.animateVarianceLabels) {
                charting.animateLabels(valueLabels, null, settings.labelFontSize);
            }
            //}
        }
    }

    if (settings.differenceHighlight) {
        plotDifferenceHighlight(chartContainer, diffHighlightAttrs, chartXPos, chartWidth, settings, slider, xScale, yScale);
    }

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = stackedDataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotStackedCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, settings);
        }
    }

    addDroplineHandlers(chartContainer, stackedAreas, y, height, bottomMargin, settings.titleFontSize + 3, xScale, stackedDataPoints, viewModel);

    // Visual.tooltipServiceWrapper.addTooltip(chartContainer,
    //     (tooltipEvent: TooltipEventArgs<StackedDataPoint>) => charting.getTooltipData(tooltipEvent.data, settings,
    //         labelsFormat, formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true), settings.invert, 0, chartData0),
    //     (tooltipEvent: TooltipEventArgs<StackedDataPoint>) => tooltipEvent.data ? tooltipEvent.data.selectionId : null,
    //     true, xScale, stackedDataPoints);

    stackedAreas.exit().remove();
}

export function groupColorGetter(group: string, settings: ChartSettings, groups: string[], colorScale: string[]): string {
    if (settings.isGroupHighlighted(group)) {
        return settings.getGroupHighlightColor(group);
    }

    const groupIndex = groups.indexOf(group);
    const colorIndex = groupIndex % colorScale.length;
    return colorScale[colorIndex];
}

function plotDifferenceHighlight(chartContainer: d3.Selection<SVGElement, any, any, any>, diffHighlightAttrs: DiffHighlightAttrs,
    chartXPos: number, chartWidth: number, settings: ChartSettings,
    slider: HTMLElement, xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>) {
    const endX = chartXPos + chartWidth + diffHighlightAttrs.width;
    const dhX1 = xScale(diffHighlightAttrs.endCategory) + xScale.bandwidth() / 2;
    const dhX2 = xScale(diffHighlightAttrs.startCategory) + xScale.bandwidth() / 2;
    const dhY1 = yScale(diffHighlightAttrs.endValue);
    const dhY2 = yScale(diffHighlightAttrs.startValue);
    charting.addDifferenceHighlight(chartContainer, settings, slider, endX, dhX1, dhX2, diffHighlightAttrs.endValue, diffHighlightAttrs.startValue, dhY1, dhY2, settings.invert);
}

function applyStylesToStackedAreas(element: d3.Selection<any, StackedDataPoint, any, any>,
    colorScale: string[], settings: ChartSettings, groups: string[],
    scenario: Scenario, style: ChartStyle, colorScheme: ColorScheme) {

    let applyPatterns = true;
    if (settings.chartStyle === ChartStyle.Custom && colorScheme.useCustomScenarioColors) {
        applyPatterns = colorScheme.applyPatterns;
    }

    element.attr(STROKE_WIDTH, (scenario === Scenario.Forecast || scenario === Scenario.Plan) && applyPatterns ? 1 : 0);
    element.attr(STROKE, d => d.color);
    element.attr(FILL, d => scenario === Scenario.Forecast && applyPatterns ?
        `url(#diagonal-stripe-${getPatternFromColor(d.color, colorScheme)})` : d.color);
    element.attr(FILL_OPACITY, () => {
        if (scenario === Scenario.Plan) {
            if (colorScheme.useCustomScenarioColors && !colorScheme.applyPatterns) {
                return 1;
            }
            else {
                return style === ChartStyle.DrHichert ? 0 : 0.1;
            }
        }
        else {
            return 1;
        }
    });
}

export function addDroplineHandlers(container: d3.Selection<SVGElement, any, any, any>, stackedAreas: d3.Selection<SVGElement, StackedDataPoint[], any, any>,
    y: number, height: number, bottomMargin: number, topMargin: number,
    xScale: d3.ScaleBand<string>, dataPoints: StackedDataPoint[], viewModel: ViewModel) {
    // eslint-disable-next-line no-constant-condition
    if (!true) {
        return;
    }
    // let selectionManager = Visual.selectionManager;
    const dropline = drawing.drawLine(container, 0, 0, 0, 0, 1, "#B0B0B0", MOUSE_OVER_DROPLINE);

    container.on(MOUSEMOVE, (event) => {
        const pointerXPos = event.offsetX;
        const domain = xScale.domain();
        const range = domain.map(s => xScale(s));
        const categoryIndex = d3.bisect(range, pointerXPos) - 1;
        if (categoryIndex < 0 || categoryIndex > domain.length - 1) {
            return;
        }
        const category = domain[categoryIndex];
        const x = xScale(category) + xScale.bandwidth() / 2;
        const dataPoint = dataPoints.filter(d => d.category === category)[0];
        if (!dataPoint || categoryIndex === domain.length - 1 && pointerXPos > x + 3) {
            return;
        }
        //let relativeXPos = x - d3.extent(xScale.range())[0];

        dropline
            .attr(X1, x)
            .attr(Y1, y + topMargin)
            .attr(X2, x)
            .attr(Y2, y + height - bottomMargin)
            .attr(OPACITY, 1);
        // if (!selectionManager.hasSelection()) {
        //     let textShapes = d3.selectAll<any, StackedDataPoint>(`${TEXT}.${HIGHLIGHTABLE}`);
        //     textShapes.attr(FONT_WEIGHT, dp => dp.category === category || dp.isHighlighted ? BOLD : NORMAL);
        //     textShapes.attr(OPACITY, dp => dp.category === category ? 1 : 0.5);
        // }

        stackedAreas.on(CLICK, (event, p) => {
            const group = p && p[0] ? p[0].group : null;
            if (!group) {
                return;
            }
            const stackedDataPoint = dataPoints.find(d => d.category === category && d.group === group);
            if (!stackedDataPoint) {
                return;
            }
            const multiSelect = event.ctrlKey;
            // selectionManager.select(stackedDataPoint.selectionId, multiSelect).then((ids: ISelectionId[]) => {
            //     if (!multiSelect || ids.length === 0) {
            //         d3.selectAll(LINE + "." + SELECTION_DROPLINE).remove();
            //     }
            //     let texts = <d3.Selection<any, StackedDataPoint, any, any>>d3.selectAll(TEXT + "." + HIGHLIGHTABLE);
            //     texts.attr(FONT_WEIGHT, d => ids.length > 0 && isSelected(d, ids) ? BOLD : NORMAL)
            //         .attr(OPACITY, d => ids.length > 0 && isSelected(d, ids) ? 1 : 0.6);
            //     if (ids.length > 0 && isSelected(stackedDataPoint, ids)) {
            //         //addSelectedCategoryDroplines(container, relativeXPos, bottomMargin, topMargin, categoryIndex, viewModel);
            //     }
            //     else if (!isSelected(stackedDataPoint, ids)) {
            //         d3.selectAll(LINE + "." + "catIndex_" + categoryIndex).remove();
            //     }
            // });
            event.stopPropagation();
        });
    });

    // container.on(CLICK, () => {
    //     if (selectionManager.hasSelection()) {
    //         selectionManager.clear();
    //         charting.syncState(selectionManager);
    //     }
    // });

    container.on(MOUSEOUT, () => {
        dropline.attr(OPACITY, 0);
        // let ids = selectionManager.getSelectionIds();
        // let texts = d3.selectAll<any, StackedDataPoint>(`${TEXT}.${HIGHLIGHTABLE}`);
        //let texts = <d3.Selection<any, DataPoint, any, any>>d3.selectAll(`${TEXT}.${HIGHLIGHTABLE}`);
        // texts.attr(FONT_WEIGHT, d => ids.length > 0 && isSelected(d, ids) ? BOLD : NORMAL)
        //     .attr(OPACITY, d => ids.length > 0 && isSelected(d, ids) ? 1 : 0.6);
    });
}
