import InputConfig from "../global-toolbar/InputConfig";
import { createFromTemplate } from "../../helpers/template";


export const select = (inputConfig: InputConfig) => createFromTemplate(`
<div class="select select-${inputConfig.name} ${(inputConfig.hidden ? "hidden" : "")}">
    <label>
        ${inputConfig.label}
        <select name="${inputConfig.name}" id="${inputConfig.id}" data-type="${inputConfig.type}">
        ${(() => inputConfig.options.map(item => 
                `<option value="${item.value}" ${(item.selected || !inputConfig.value && item.default || inputConfig.value == item.value ? "selected" : "")}>${item.title}</option>`
        ))()}
        </select>
    </label>
</div>
`);

export const selectNoLabel = (inputConfig: InputConfig) => createFromTemplate(`
    <select name="${inputConfig.name}" id="${inputConfig.id}" data-type="${inputConfig.type}">
        ${(() => inputConfig.options.map(item =>
    `<option value="${item.value}" ${(item.selected || !inputConfig.value && item.default || inputConfig.value == item.value ? "selected" : "")}>${item.title}</option>`
))()}
    </select>
`);
