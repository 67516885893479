import Layout from "./Layout";
import { ACTUAL_ABSOLUTE, ACTUAL_RELATIVE, ABSOLUTE_RELATIVE } from "./../consts";
import { ChartData, ChartLayout } from "./../interfaces";

export class ComboChartsLayout extends Layout {

    public getChartLayouts(rowCount: number, maxChartsPerRow: number, height: number, visualTitleHeight: number, minChartHeight: number,
        minChartWidth: number, plotChartWidth: number, bottomMargin: number, topMargin: number, rowMargin: number, columnMargin: number,
        labelFontSize: number, xPosition: number, leftMargin: number): ChartLayout[] {

        let chartsHeight = minChartHeight;
        if (chartsHeight * rowCount + (rowCount - 1) * rowMargin < height) {
            chartsHeight = (height - rowCount * rowMargin) / rowCount;
        }
        const chartLayouts: ChartLayout[] = [];
        const plotExtendedCharts = this.settings.shouldPlotExtendedChartsMultiples();
        const extendedRelativeChartSpan = 0;
        let currentPositionX = leftMargin + xPosition;
        let currentPositionY = visualTitleHeight;
        let currentRowIndex = 0;
        let currentColIndex = 0;
        const dataSpan = this.getMultipleDataSpan(this.viewModel.chartData);

        const globalMin = dataSpan[0];
        const globalMax = dataSpan[1];

        for (let i = 0, len = this.viewModel.chartData.length; i < len; i++) {
            if (rowCount > 0 && i > 0 && i % maxChartsPerRow === 0) {
                currentRowIndex++;
                currentColIndex = 0;
                currentPositionX = leftMargin + xPosition;
                currentPositionY += chartsHeight + rowMargin;
            }
            else {
                currentColIndex = i % maxChartsPerRow;
            }

            chartLayouts.push({
                height: chartsHeight,
                width: currentColIndex === 0 ? plotChartWidth + xPosition : plotChartWidth,
                position: {
                    x: currentColIndex === 0 ? leftMargin : currentPositionX,
                    y: currentPositionY,
                },
                columnIndex: currentColIndex,
                rowIndex: currentRowIndex,
                min: globalMin,
                max: globalMax,
                extendedChartMin: plotExtendedCharts ? this.getExtendedChartMin(this.viewModel.chartData, dataSpan) : null,
                extendedChartMax: plotExtendedCharts ? this.getExtendedChartMax(this.viewModel.chartData, dataSpan) : null,
                extendedRelativeChartHeight: plotExtendedCharts && this.settings.chartLayout !== ACTUAL_ABSOLUTE ? 0.33 * chartsHeight : null,
                bottomMargin: bottomMargin,
            });
            currentPositionX += plotChartWidth + columnMargin;
        }
        return chartLayouts;
    }

    private getMultipleDataSpan(chartData: ChartData[]): number[] {
        let min = 0;
        let max = 0;
        let extendedChartMin = 0;
        let extendedChartMax = 0;

        for (let i = 0; i < chartData.length; i++) {
            const chartDataMin = chartData[i].min;
            const chartDataMax = chartData[i].max;

            if (this.settings.chartLayout === ACTUAL_ABSOLUTE) {
                extendedChartMin = Math.min(extendedChartMin, chartData[i].minVariance);
                extendedChartMax = Math.max(extendedChartMax, chartData[i].maxVariance);
            }
            else if (this.settings.chartLayout === ACTUAL_RELATIVE) {
                extendedChartMin = Math.min(extendedChartMin, chartData[i].minOutlierValue);
                extendedChartMax = Math.max(extendedChartMax, chartData[i].maxOutlierValue);
            }
            else if (this.settings.chartLayout === ABSOLUTE_RELATIVE) {
                extendedChartMin = Math.min(extendedChartMin, chartData[i].minOutlierValue);
                extendedChartMax = Math.max(extendedChartMax, chartData[i].maxOutlierValue);
            }

            if (chartDataMin < min) {
                min = chartDataMin;
            }
            if (chartDataMax > max) {
                max = chartDataMax;
            }
        }

        return [min, max, extendedChartMin, extendedChartMax];
    }

    private getExtendedChartMin(chartData: ChartData[], rowDataSpans: number[]): number {
        if (this.settings.chartLayout === ACTUAL_ABSOLUTE) {
            return rowDataSpans[2];
        }
        else {
            return Math.min(0, ...chartData.map(cd => cd.minOutlierValue));
        }
    }

    private getExtendedChartMax(chartData: ChartData[], rowDataSpans: number[]): number {
        if (this.settings.chartLayout === ACTUAL_ABSOLUTE) {
            return rowDataSpans[3];
        }
        else {
            return Math.max(0, ...chartData.map(cd => cd.maxOutlierValue));
        }
    }
}