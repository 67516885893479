import { licensing } from "@zebrabi/licensing/Licensing";
import { CurrentUserInfo } from "@zebrabi/licensing/components/UserInfo";
import { LicenseType } from "@zebrabi/licensing/licensing.types";
import { AnalyticsHandler, AnalyticsInitProperties, flagHandler } from "@zebrabi/zebrabi-core";
import { getOfficeSettings } from "../office-settings";
import * as chartsDataHelper from "../visuals/charts/dataHelper";
import * as tablesDataHelper from "../visuals/tables/dataHelper";
import { InsertType } from "./definitions";
import GlobalToolbar from "@zebrabi/global-toolbar/dist/lib/components/GlobalToolbar";
import GlobalToolbarOld from "@zebrabi/global-toolbar-old/components/GlobalToolbar";
import { DATA_SOURCE } from "@zebrabi/data-helpers/editData";

// Existing analytics properties in quickstart files
const EXISTING_USER_PROPERTY_KEY = "existingPosthogId";
const FILE_TYPE_PROPERTY_KEY = "firstExampleFileType";
const FILE_NAME_PROPERTY_KEY = "firstExampleFileName";
const DOWNLOAD_DATE_PROPERTY_KEY = "firstExampleFileDownloadDate";

export function getInsertType() {
    const storedDataSource = getOfficeSettings(DATA_SOURCE);
    if (!storedDataSource) {
        return InsertType.Null;
    }

    if (storedDataSource.pivotTable) {
        return InsertType.PivotTable;
    } else if (storedDataSource.range) {
        return InsertType.Range;
    } else if (storedDataSource.table) {
        return InsertType.Table;
    } else {
        return InsertType.Null;
    }
}

export function initializeAnalyticsReporting(visual: string, analyticsHandler: AnalyticsHandler, isFirstTimeInsert: boolean, dataHelper: chartsDataHelper.DataHelper | tablesDataHelper.DataHelper) {

    const analyticsProperties: AnalyticsInitProperties = {
        visual: visual,
        apiKey: process.env.POSTHOG_API_KEY,
        apiHost: process.env.POSTHOG_HOST,
        product: Office.context.host.toString(),
        productVersion: Office.context.diagnostics.version,
        plan: licensing.getPlanString()
    };

    if (!licensing.getCurrentUser()?.isKnown()) {
        analyticsHandler.init(analyticsProperties);
    } else {
        if (!flagHandler.has("office-pro-user-tracking")) {
            // Do not track pro users, if the feature flag is turned off
            if (licensing.isPaidUser()) {
                analyticsHandler.optOut = true;
            }
        }

        analyticsHandler.initWithUserData(analyticsProperties, {
            userId: licensing.getCurrentUser().getUserID(),
            organizationId: licensing.getCurrentUser().getOrganizationID(),
            plan: licensing.getPlanString()
        });
    }
    if (isFirstTimeInsert) {
        if (Office.context.host === Office.HostType.Excel) {
            dataHelper.checkForEmptyWorksheet().then((isChooser) => {
                if (!isChooser) {
                    const insertType = getInsertType();
                    analyticsHandler.report(`insert/${insertType}`);
                } else {
                    analyticsHandler.report(`insert/chooser`);
                }
                isFirstTimeInsert = false;
            });
        } else {
            analyticsHandler.report("insert/chooser");
            isFirstTimeInsert = false;
        }
    } else {
        // Note: timeout is needed to ensure that the report is sent after the analytics instance is initialized
        window.setTimeout(() => {
            analyticsHandler.report("view");
        }, 1000);
    }
    return isFirstTimeInsert;
}

async function extractExcelAnalyticsData(analyticsHandler: AnalyticsHandler) {
    // Extract existing analytics data from the current excel file
    let existingPosthogId: null | string = null;
    const setOnceProperties: { [key: string]: string; } = {};
    await Excel.run(async (context) => {
        const posthogIdProperty = context.workbook.properties.custom.getItemOrNullObject(EXISTING_USER_PROPERTY_KEY);
        posthogIdProperty.load();
        const firstExampleFileType = context.workbook.properties.custom.getItemOrNullObject(FILE_TYPE_PROPERTY_KEY);
        firstExampleFileType.load();
        const firstExampleFileName = context.workbook.properties.custom.getItemOrNullObject(FILE_NAME_PROPERTY_KEY);
        firstExampleFileName.load();
        const firstExampleFileDownloadDate = context.workbook.properties.custom.getItemOrNullObject(DOWNLOAD_DATE_PROPERTY_KEY);
        firstExampleFileDownloadDate.load();

        await context.sync();

        if (!posthogIdProperty.isNullObject) {
            existingPosthogId = posthogIdProperty.value;
        }

        if (!firstExampleFileType.isNullObject) {
            setOnceProperties[FILE_TYPE_PROPERTY_KEY] = firstExampleFileType.value;
        }
        if (!firstExampleFileName.isNullObject) {
            setOnceProperties[FILE_NAME_PROPERTY_KEY] = firstExampleFileName.value;
        }
        if (!firstExampleFileDownloadDate.isNullObject) {
            setOnceProperties[DOWNLOAD_DATE_PROPERTY_KEY] = firstExampleFileDownloadDate.value;
        }

    });

    sendExistingAnalytics(analyticsHandler, existingPosthogId, setOnceProperties);
}


async function extractPowerPointAnalyticsData(analyticsHandler: AnalyticsHandler) {
    // Extract existing analytics data from the current powerpoint file
    let existingPosthogId: null | string = null;
    const setOnceProperties: { [key: string]: string; } = {};
    await PowerPoint.run(async function (context) {
        const presentation = context.presentation;
        presentation.load("tags/key, tags/value");
        await context.sync();

        const setOncePropertyKeys = [
            FILE_TYPE_PROPERTY_KEY,
            FILE_NAME_PROPERTY_KEY,
            DOWNLOAD_DATE_PROPERTY_KEY
        ];
        for (const tag of presentation.tags.items) {
            // PPT tag keys are always stored as uppercase letters, so we compare to uppercase
            if (tag.key === EXISTING_USER_PROPERTY_KEY.toUpperCase()) {
                existingPosthogId = tag.value;
            } else {
                for (const propertyKey of setOncePropertyKeys) {
                    if (tag.key === propertyKey.toUpperCase()) {
                        setOnceProperties[propertyKey] = tag.value;
                    }
                }
            }
        }
    });

    sendExistingAnalytics(analyticsHandler, existingPosthogId, setOnceProperties);
}

function sendExistingAnalytics(analyticsHandler: AnalyticsHandler, existingPosthogId: null | string, setOnceProperties: { [key: string]: string; }) {
    // Merge users if existing id was found
    if (existingPosthogId !== null) {
        const currentUserId = licensing.getCurrentUser()?.userId;
        if (currentUserId) {
            analyticsHandler.alias(existingPosthogId, currentUserId);
        }
    }

    // Send event to set user properties, if any were found
    if (Object.keys(setOnceProperties).length > 0) {
        analyticsHandler.report("set_example_file_properties", {
            $set_once: setOnceProperties
        });
    }
}

export function extractExistingAnalyticsId(analyticsHandler: AnalyticsHandler) {
    // Check if file has existing posthog id and merge it with the current user
    // Also set any additional user properties from the file
    if (Office.context.host === Office.HostType.Excel) {
        extractExcelAnalyticsData(analyticsHandler);
    } else if (Office.context.host === Office.HostType.PowerPoint) {
        extractPowerPointAnalyticsData(analyticsHandler);
    }
}

export async function initializeAnalyticsOptOut(analyticsHandler: AnalyticsHandler, currentUser: CurrentUserInfo, globalToolbar: GlobalToolbar | GlobalToolbarOld) {
    if (!flagHandler.has("office-pro-user-tracking")) {
        return;
    }

    if (currentUser.getLicense().licenseType !== LicenseType.Paid) {
        // Leave default opt out status for non-paid users
        return;
    }

    // Make a request to the licensing service to check if the user has disabled usage tracking
    const usageTrackingApiPath = `/add-in/usage-tracking/${currentUser.userId}`;
    const usageTrackingUrl = process.env.LICENSING_SERVER_URL + usageTrackingApiPath;
    fetch(usageTrackingUrl).then(response => {
        if (!response.ok) {
            throw new Error(`Bad usage tacking response: ${response.status}`);
        }
        return response.json();
    }).then(async jsonData => {
        // Update opt out information and notify global toolbar to update button in AccountSwitcher
        analyticsHandler.optOut = jsonData["usageTracking"] !== true;
        globalToolbar?.notify(new Map([["userinfo", true]]));
    }).catch(error => {
        console.error("Error getting usage tracking:", error);
    });
}

export async function persistAnalyticsOptOut(analyticsHandler: AnalyticsHandler, currentUserInfo: CurrentUserInfo) {
    // Send and store opt out status of the current user to the licensing server
    // The database tracks if user has usage tracking enabled, which is the opposite of being opted out
    const usageTrackingEnabled = !analyticsHandler.optOut;
    const usageTrackingApiPath = `/add-in/usage-tracking/${currentUserInfo.userId}/${usageTrackingEnabled}`;
    const usageTrackingUrl = process.env.LICENSING_SERVER_URL + usageTrackingApiPath;
    fetch(usageTrackingUrl, {
        method: "POST",
    }).then(response => {
        if (!response.ok) {
            console.error(`Error setting usage tracking, response status: ${response.status}`);
        }
    }).catch(error => {
        console.error("Error setting usage tracking:", error);
    });
}