import {
    ColorPickerData,
    ColorPickerSubscriber,
    InlineToggleButton,
    ScenarioPatternValue,
    SwitchPicker,
    SwitchScenarioPattern,
    SwitchScenarioPatternSubscriber,
    ToggleButtonData,
    ToggleButtonSubscriber
} from "@zebrabi/design-library";
import { Visual } from "../visual";
import * as scenarios from "../scenarios";
import {
    BAR, CHANGE_COLOR,
    CIRCLE, CLEAR_COLOR,
    FILL,
    G, HIGHLIGHT_COLOR,
    HIGHLIGHT_DROPLINE,
    LI, RADIO_CHANGE,
    RECT, SAVE_COLOR,
    SCENARIO_ENUMS,
    STROKE, SWITCH_CHANGE,
    VARIANCE
} from "../library/constants";
import { ChartType } from "../enums";
import * as d3 from "../d3";
import { CHART_CONTAINER } from "../consts";
import { DataPoint } from "../interfaces";
import { enableToggleHighlight, enableToggleInvert, enableToggleResult } from "../helpers";
import ContextMenu from "./base/ContextMenu";

export default class CategoryMenu extends ContextMenu {
    protected template: string = `<div class="context-menu2 category">
                                <ul class="menu-items"></ul>
                           </div>`;

    private dataPoint: DataPoint;
    private total: boolean;
    private isOther: boolean;
    private isSingleSeriesWaterfall: boolean;
    private host = null;

    constructor(dataPoint: DataPoint) {
        super();
        this.dataPoint = dataPoint;
        this.total = dataPoint.category === this.chartSettings.grandTotalLabel;
        this.isOther = dataPoint.isOther;
        this.isSingleSeriesWaterfall = Visual.viewModel.isSingleSeriesViewModel && this.chartSettings.chartType === ChartType.Waterfall;
    }

    protected addMenuItems(): void {
        const dataPoint = this.dataPoint;
        const total = this.total;
        const isOther = this.isOther;
        const isSingleSeriesWaterfall = this.isSingleSeriesWaterfall;

        // Invert
        if (enableToggleInvert(isOther, dataPoint.isCumulative, total)) {
            const invertSwitch = new InlineToggleButton(dataPoint.isCategoryInverted, {
                name: "invert",
                label: "Invert"
            });

            const invertSwitchSubscriber = new ToggleButtonSubscriber();
            invertSwitchSubscriber.update = (message: ToggleButtonData, action?: string): void => {
                this.chartSettings.persistInvertedCategories(dataPoint.category);
            };

            invertSwitch.subscribe(invertSwitchSubscriber);
            const menuItemDiv = this.createMenuItem();
            invertSwitch.appendTo(menuItemDiv);
        }

        // Result
        if (enableToggleResult(isOther, isSingleSeriesWaterfall, dataPoint.isCumulative, total)) {
            const lastSelected = Object.assign({}, ...this.chartSettings.scenarioCategories);
            const currentScenarioPattern = scenarios.getScenarioName(lastSelected[dataPoint.category]) || SCENARIO_ENUMS[0];
            const resultSwitch = new SwitchScenarioPattern(dataPoint.isCategoryResult, "Result", ScenarioPatternValue[currentScenarioPattern]);
            const menuItemDiv = this.createMenuItem();
            resultSwitch.appendTo(menuItemDiv);

            const resultSwitchSubscriber = new SwitchScenarioPatternSubscriber();
            resultSwitchSubscriber.update = (message: any, action?: string): void => {
                if (action === SWITCH_CHANGE) {
                    this.chartSettings.persistIsResultCategories(dataPoint.category);
                } else if (action === RADIO_CHANGE) {
                    this.chartSettings.persistScenarioCategories(dataPoint.category, scenarios.getScenarioEnum(message.value));
                }
            };

            resultSwitch.subscribe(resultSwitchSubscriber);
        }

        // Highlight
        if (enableToggleHighlight(this.chartSettings.chartType, isSingleSeriesWaterfall, dataPoint.isCumulative)) {
            const hlComp = new SwitchPicker(this.chartSettings.isCategoryHighlighted(dataPoint.category), { selectedColor: this.chartSettings.getCategoryHighlightColor(dataPoint.category), label: "Highlight", pickerLabel: "Color", defaultColor: HIGHLIGHT_COLOR });
            const menuItemDiv = this.createMenuItem();
            hlComp.appendTo(menuItemDiv);

            const highlightSub = new ColorPickerSubscriber();
            highlightSub.update = (message: ColorPickerData | undefined, action?: string): void => {
                if (action === SWITCH_CHANGE) {
                    this.chartSettings.persistHighlightedCategories(dataPoint.category);
                } else if (action === SAVE_COLOR) {
                    this.chartSettings.persistCustomHighlightColor(dataPoint.category, message?.hex);
                } else if (action === CLEAR_COLOR) {
                    this.chartSettings.persistCustomHighlightColor(dataPoint.category, null);
                } else if (action === CHANGE_COLOR) {
                    const chartAreas = d3.selectAll(`${G}.${CHART_CONTAINER}`);
                    const rects = chartAreas.selectAll(`${RECT}.${BAR}:not(.${VARIANCE})`).filter((d: DataPoint) => {
                        return (d && d.category !== undefined && d.category === dataPoint.category);
                    });
                    const dropline = chartAreas.selectAll(`.${HIGHLIGHT_DROPLINE}`).filter((d: DataPoint) => {
                        return (d && d.category !== undefined && d.category === dataPoint.category);
                    });
                    const circle = chartAreas.selectAll(`${CIRCLE}`).filter((d: DataPoint) => {
                        return (d && d.category !== undefined && d.category === dataPoint.category);
                    });

                    rects.attr(FILL, message?.hex);
                    circle.attr(FILL, message?.hex);
                    circle.attr(STROKE, message?.hex);
                    dropline.attr(STROKE, message?.hex);
                }
            };

            hlComp.subscribe(highlightSub);
        }
    }
}
