import * as d3 from "../d3";
import { LabelAlignment } from "../library/types";
import { ChartData, DataPoint } from "./../interfaces";
import { LabelProperties } from "./../library/interfaces";
import { ChartType, DifferenceHighlightFromTo, ReferenceDisplayType, } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";

import {
    Scenario,
    END, NORMAL, FILL_OPACITY, STROKE_OPACITY, HIGHLIGHTABLE, RECT, TOOLTIP, G, BAR, GRAY, AXIS_SCENARIO_DELIMITER, START, AXIS, WIDTH, HEIGHT, Y, X, OPACITY
} from "./../library/constants";
import {
    CHART_CONTAINER, VALUE, SECOND_SEGMENT_VALUE
} from "./../consts";

import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as viewModels from "./../viewModel/viewModelHelperFunctions";
import * as charting from "./chart";
import { mapScenarioKeyAndName } from "../helpers";
import { plotChartLegendSettings } from "../ui/drawGlobalLegendMenuOverlay";

// eslint-disable-next-line max-lines-per-function
export default function verticalBarChart(reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement, x: number, y: number, height: number, width: number,
    chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number,
    plotTitle: boolean, plotOverlappedReference: boolean, plotLegend: boolean, plotCategories: boolean, leftMargin: number, rightMargin: number, leftMarginCategories: number) {

    const scenarioOptions = settings.scenarioOptions;
    const dataLabelsMargin = settings.showDataLabels || settings.showCommentMarkers() ? rightMargin : 0;
    const categoriesMargin = plotCategories ? leftMargin : 1;
    if (plotLegend) {
        topMargin += settings.labelFontSize + 20;
    }
    if (bottomMargin + topMargin > height) {
        topMargin = 0;
    }
    const chartXPos = x;
    const chartYPos = y + topMargin;
    const chartHeight = height - topMargin - bottomMargin;

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    const yScale = charting.getOrdinalScale(chartData, chartYPos, chartHeight, settings.getGapBetweenColumns());
    const xScale = charting.getLinearScale(min, max, x + categoriesMargin, x + width - (dataLabelsMargin));

    charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, chartXPos + categoriesMargin, y,
        charting.getChartTitleWidth(width - categoriesMargin, settings), height, max, min, topMargin, bottomMargin);
    if (settings.showCategories && plotCategories) {
        charting.plotVerticalCategories(chartContainer, settings, chartData, x, yScale, leftMarginCategories, chartIndex);
    }
    const setBarHeightToZero = min === 0 && max === 0;
    const referenceDataPoints = chartData.dataPoints.filter(d => d.reference !== null);
    if (plotOverlappedReference && referenceDataPoints.length > 0 && settings.referenceDisplayType === ReferenceDisplayType.OverlappedColumn) {
        let referenceBars = chartContainer
            .selectAll(`.${BAR}_REFERENCE` + chartIndex)
            .data(referenceDataPoints);
        const rect = referenceBars.enter()
            .append(RECT)
            .classed(BAR, true)
            .classed(HIGHLIGHTABLE, true);
        referenceBars = referenceBars.merge(rect)
            .attr(WIDTH, d => setBarHeightToZero ? 0 : Math.abs(xScale(d.reference) - xScale(0)))
            .attr(HEIGHT, yScale.bandwidth())
            .attr(Y, d => yScale(d.category) - yScale.bandwidth() * settings.getGapBetweenColumns() / 1.6)
            .attr(X, d => xScale(d.reference > 0 ? 0 : d.reference));
        styles.applyToBars(referenceBars, (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenarioOptions.referenceScenario, settings.chartStyle, false, settings.colorScheme);
        if (settings.lightenOverlapped) {
            referenceBars.attr(STROKE_OPACITY, 0.5);
            const overlappedFillOpacity = scenarioOptions.referenceScenario === Scenario.Plan && !settings.colorScheme.useCustomScenarioColors ? +referenceBars.attr(FILL_OPACITY) * 0.5 : 0.5;
            referenceBars.attr(FILL_OPACITY, overlappedFillOpacity);
        }
        referenceBars.exit().remove();
    }

    const valuesDataPoints = chartData.dataPoints.filter(dp => dp.value !== null);
    let bars = chartContainer
        .selectAll(`.${BAR}` + chartIndex)
        .data(valuesDataPoints);
    const rect = bars.enter()
        .append(RECT)
        .classed(BAR, true)
        .classed(HIGHLIGHTABLE, true);
    bars = bars.merge(rect)
        .attr(WIDTH, d => setBarHeightToZero ? 0 : Math.abs(xScale(d.value) - xScale(0)))
        .attr(HEIGHT, yScale.bandwidth())
        .attr(Y, d => yScale(d.category))
        .attr(X, d => xScale(d.value > 0 ? 0 : d.value));
    styles.applyToBars(bars, (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme);
    // plot axis
    drawing.drawLine(chartContainer, xScale(0), xScale(0), chartYPos, chartYPos + chartHeight, 1, settings.colorScheme.axisColor, AXIS);

    let secondSegmentDataPoints: DataPoint[] = [];
    if (scenarioOptions.secondValueScenario !== null) {
        secondSegmentDataPoints = chartData.dataPoints.filter(dp => dp.value === null && dp.secondSegmentValue !== null);
        let secondSegmentBars = chartContainer
            .selectAll(`.${BAR}_SECOND_SEGMENT` + chartIndex)
            .data(secondSegmentDataPoints);
        const rect = secondSegmentBars.enter()
            .append(RECT)
            .classed(BAR, true)
            .classed(HIGHLIGHTABLE, true);

        secondSegmentBars = secondSegmentBars.merge(rect)
            .attr(WIDTH, d => setBarHeightToZero ? 0 : Math.abs(xScale(d.secondSegmentValue) - xScale(0)))
            .attr(HEIGHT, yScale.bandwidth())
            .attr(Y, d => yScale(d.category))
            .attr(X, d => xScale(d.secondSegmentValue > 0 ? 0 : d.secondSegmentValue));
        styles.applyToBars(secondSegmentBars, (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenarioOptions.secondValueScenario, settings.chartStyle, false, settings.colorScheme);
        if (secondSegmentDataPoints.length > 0) {
            const yAxisDelimiter = yScale(secondSegmentDataPoints[0].category) - yScale.bandwidth() / 8;
            drawing.drawLine(chartContainer, chartXPos, xScale(0) + 20, yAxisDelimiter, yAxisDelimiter, 1, GRAY, AXIS_SCENARIO_DELIMITER);
        }

        charting.addMouseHandlers(secondSegmentBars, svg);
        secondSegmentBars.exit().remove();
    }

    if (settings.scenarioOptions.secondReferenceScenario) {
        charting.addReferenceTriangles(chartContainer, chartData.dataPoints.filter(dp => dp.secondReference !== null), settings, yScale, xScale, y, scenarioOptions.secondReferenceScenario, true);
    }
    if (plotOverlappedReference && referenceDataPoints.length > 0 && settings.referenceDisplayType === ReferenceDisplayType.Triangles) {
        charting.addReferenceTriangles(chartContainer, referenceDataPoints, settings, yScale, xScale, y, scenarioOptions.referenceScenario, false);
    }

    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    if (settings.showDataLabels) {
        const hideUnits = settings.shouldHideDataLabelUnits();
        const labelsProperties = getLabelProperties(chartData.dataPoints, settings, labelsFormat, hideUnits, yScale, xScale, false);
        if (labelsProperties.length > 0) {
            charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings, NORMAL);
        }

        if (plotOverlappedReference && valuesDataPoints.length < chartData.dataPoints.length) {
            const referenceOnlyDataPoints = chartData.dataPoints.filter((p, i) => i >= valuesDataPoints.length && p.reference !== null && p.secondSegmentValue === null);
            const referenceLabelProperties = getLabelProperties(referenceOnlyDataPoints, settings, labelsFormat, hideUnits, yScale, xScale, true);
            if (referenceLabelProperties.length > 0) {
                charting.plotLabels(chartContainer, `${chartIndex}`, referenceLabelProperties, settings);
            }
        }
    }

    if (settings.differenceHighlight && (chartData.dataPoints.length > 1 || scenarioOptions.referenceScenario !== null && settings.plotOverlappedReference)) {
        const dhDataPoints = valuesDataPoints.length > 0 ? valuesDataPoints : secondSegmentDataPoints;
        let { fromDataPoint, toDataPoint } = charting.getDiffHighlightAutoDataPoints(dhDataPoints, ChartType.Bar, true);
        let isLastAcToLastFc = false;
        if (settings.differenceHighlightFromTo !== DifferenceHighlightFromTo.Auto) {
            if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.LastACToLastFC && secondSegmentDataPoints.length !== 0 && valuesDataPoints.length !== 0) {
                isLastAcToLastFc = true;
                fromDataPoint = valuesDataPoints[valuesDataPoints.length - 1];
                toDataPoint = secondSegmentDataPoints[secondSegmentDataPoints.length - 1];
            }
            else {
                const diffHighlightDataPoints = settings.isSecondSegmentDiffHighlight() ? secondSegmentDataPoints : chartData.dataPoints.filter(p => p.value !== null);
                const fromToDataPoints = charting.getDiffHighlightDataPoints(diffHighlightDataPoints, settings.differenceHighlightFromTo);
                fromDataPoint = fromToDataPoints.fromDP;
                toDataPoint = fromToDataPoints.toDP;
            }
        }

        if (fromDataPoint && toDataPoint) {
            const valueProperty: keyof DataPoint = settings.isSecondSegmentDiffHighlight() && scenarioOptions.secondValueScenario !== null || valuesDataPoints.length === 0 ? SECOND_SEGMENT_VALUE : VALUE;
            const endY =
                yScale(chartData.dataPoints[chartData.dataPoints.length - 1].category) +
                yScale.bandwidth() / 2 +
                yScale.step();

            if (fromDataPoint === toDataPoint && fromDataPoint[valueProperty] !== null && toDataPoint.reference !== null) {
                const dhX1 = xScale(fromDataPoint[valueProperty]);
                const dhX2 = xScale(fromDataPoint.reference);
                const dhY = yScale(fromDataPoint.category);
                charting.addVerticalDifferenceHighlight(chartContainer, settings, slider, endY, dhX1, dhX2, fromDataPoint[valueProperty], fromDataPoint.reference,
                    dhY, dhY, chartData.isInverted);
            }
            else if (fromDataPoint !== toDataPoint && (toDataPoint[valueProperty] !== null && fromDataPoint[valueProperty] !== null
                || isLastAcToLastFc && fromDataPoint.value !== null && toDataPoint.secondSegmentValue !== null)) {
                const startValue = isLastAcToLastFc ? fromDataPoint.value : fromDataPoint[valueProperty];
                const endValue = isLastAcToLastFc ? toDataPoint.secondSegmentValue : toDataPoint[valueProperty];
                const dhX1 = xScale(endValue);
                const dhX2 = xScale(startValue);
                const dhY1 = yScale(toDataPoint.category);
                const dhY2 = yScale(fromDataPoint.category);
                charting.addVerticalDifferenceHighlight(chartContainer, settings, slider, endY, dhX1, dhX2, endValue, startValue, dhY1, dhY2, chartData.isInverted);
            }
        }
    }

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, settings, rightMargin);
        }
    }

    charting.addMouseHandlers(bars, svg);

    // Visual.tooltipServiceWrapper.addTooltip(chartContainer.selectAll(`.${BAR}, .${TOOLTIP}`),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => charting.getTooltipData(tooltipEvent.data, settings,
    //         labelsFormat, formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true), chartData.isInverted, undefined, chartData),
    //     (tooltipEvent: TooltipEventArgs<DataPoint>) => tooltipEvent.data.selectionId);

    bars.exit().remove();

    if (plotLegend && valuesDataPoints.length > 0) {
        const firstDP = valuesDataPoints[0];
        const legendYPosition = chartYPos;
        let legendXPosition = xScale(firstDP.value > 0 ? 0 : firstDP.value) + Math.abs(xScale(firstDP.value / 2) - xScale(0));

        if (settings.scenarioOptions.secondReferenceScenario) {
            const legendXSecondReferencePosition = xScale(firstDP.secondReference) - 5;
            charting.plotChartLegend(chartContainer, mapScenarioKeyAndName("secondReferenceHeader", settings.secondReferenceHeader, scenarioOptions), legendXSecondReferencePosition, legendYPosition, settings);
        }
        if (plotOverlappedReference && referenceDataPoints.length > 0) {
            const legendXReferencePosition = xScale(firstDP.reference > 0 ? 0 : firstDP.reference) + Math.abs(xScale((firstDP.reference || 0) * (settings.referenceDisplayType === ReferenceDisplayType.Triangles ? 1 : 0.5)) - xScale(0)) - 5;
            charting.plotChartLegend(chartContainer, mapScenarioKeyAndName("referenceHeader", settings.referenceHeader, scenarioOptions), legendXReferencePosition, legendYPosition, settings);
            const lastDP = valuesDataPoints[valuesDataPoints.length - 1];
            legendXPosition = xScale(lastDP.value > 0 ? 0 : lastDP.value) + Math.abs(xScale(lastDP.value / 2) - xScale(0)) - 5;
            charting.plotChartLegend(chartContainer, mapScenarioKeyAndName("valueHeader", settings.valueHeader, scenarioOptions), legendXPosition, chartYPos + chartHeight + settings.labelFontSize / 2, settings);
        }
        else {
            charting.plotChartLegend(chartContainer, mapScenarioKeyAndName("valueHeader", settings.valueHeader, scenarioOptions), legendXPosition, legendYPosition, settings);
        }
        plotChartLegendSettings(chartContainer, x, y, width);
    }
}

function getLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, hideUnits: boolean,
    ordinalScale: d3.ScaleBand<string>, linearScale: d3.ScaleLinear<number, number>, useReferenceValue: boolean): LabelProperties[] {
    const categoryRangeBand = ordinalScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(charting.densityFilter, settings);
    const labelProperties = labelsDataPoints.map(d => {
        const value = useReferenceValue ? d.reference : viewModels.getDataPointNonNullValue(d);
        const labelText = charting.getFormattedDataLabel(value, settings.decimalPlaces, settings.displayUnits, settings.locale,
            settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelsFormat, hideUnits);
        const xPos = linearScale(value) + (value > 0 ? 5 : -5);
        let yPos = ordinalScale(d.category) + ordinalScale.bandwidth() / 2 + settings.labelFontSize * 0.35;
        if (useReferenceValue) {
            yPos -= ordinalScale.bandwidth() * settings.getGapBetweenColumns() / 1.6;
        }
        const alignment: LabelAlignment = value > 0 ? START : END;
        return charting.getLabelProperty(labelText, xPos, yPos, null, value, d, settings.labelFontSize, settings.labelFontFamily, null, null, null, alignment);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, categoryRangeBand);
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], linearScale: d3.ScaleLinear<number, number>, ordinalScale: d3.ScaleBand<string>, settings: ChartSettings, rightMargin: number) {
    let labelsMargin = rightMargin;
    const scaleBandWidth = ordinalScale.bandwidth();
    const markerAttrs = charting.getCommentMarkerAttributes(scaleBandWidth);
    labelsMargin = labelsMargin - 2 * markerAttrs.radius - markerAttrs.margin;
    const getMarkerYPosition = (d: DataPoint): number => ordinalScale(d.category) + scaleBandWidth / 2;
    const getMarkerXPosition = (d: DataPoint): number => linearScale(d.value) + labelsMargin + markerAttrs.radius + markerAttrs.margin;
    charting.addCommentMarkers(container, commentDataPoints, getMarkerXPosition, getMarkerYPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}
