import React from "react";
import FileSelection from "../FileSelection";
import DataSelection from "../DataSelection";
import useStore from "../../useStore";

export default function SelectionToggler() {
  const [currentView] = useStore("currentView");
  const [accessToken] = useStore("accessToken");

  if (currentView === "data-selection") {
    return <DataSelection />;
  }

  return <FileSelection accessToken={accessToken} />;
}
