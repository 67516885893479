import React from "react";
import { ChartOption } from "../../dimensions/category.type";
import ReactTooltip from "react-tooltip";

type ChartOptionCardProps = {
  chart: ChartOption;
  onSelect: (id: string) => void;
  isSelected: boolean;
  key: string;
};

export default function ChartOptionCard({ chart, onSelect, isSelected }: ChartOptionCardProps) {
  const handleClick = () => onSelect(chart.id);

  return (
    <div
      onClick={handleClick}
      className={`chart-option-card flex flex-col rounded-sm w-28 border p-2 mr-4 mt-4 cursor-pointer ${isSelected ? "active-chart-card" : ""
        }`}
      data-tip={
        chart.tooltip ||
        "Compare two or more values, rank data categories, look up specific values, and recognize patterns in data. Use when you have discrete items like products or customers."
      }
    >
      <img src={chart.image} alt="" className="mb-2" />
      <h1 className="font-semibold">{chart.title}</h1>
      <ReactTooltip place="bottom" padding="12px" effect="solid" className="chart-tooltip" />
    </div>
  );
}
